import Api from './consts';
import fetch from './commonFetch';

const { categories } = Api.companies;

export const getAll = companyId => {
  const { url, method } = categories.getAll;
  return fetch(url(companyId), { method }, 'categories');
};

export const get = (companyId, id) => {
  const { url, method } = categories.get;
  return fetch(url(companyId, id), { method }, 'category');
};

export const insertCategory = (companyId, parentId, data) => {
  const { url, method, headers } = categories.add;
  const body = JSON.stringify(data);
  return fetch(url(companyId, parentId), { method, headers, body }, 'category');
};

export const insertItem = (companyId, parentId, data) => {
  const { url, method, headers } = categories.addItem;
  const body = JSON.stringify(data);
  return fetch(url(companyId, parentId), { method, headers, body }, 'category');
};

export const modifyCategory = (companyId, id, data) => {
  const { url, method, headers } = categories.update;
  const body = JSON.stringify(data);

  return fetch(url(companyId, id), { method, headers, body }, 'category');
};

export const deleteCategory = (companyId, id) => {
  const { url, method, headers } = categories.delete;
  return fetch(url(companyId, id), { method, headers }, 'category');
};

export const deleteItem = (companyId, id, data) => {
  const { url, method, headers } = categories.deleteItem;
  const body = JSON.stringify(data);

  return fetch(url(companyId, id), { method, headers, body }, 'category');
};
