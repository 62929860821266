import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router/es/withRouter';
import { getAuthenticity, getCompanyById } from '../../reducers';
import { verifyContactEmailHash } from '../../actions/Customers';
import { Spin } from 'antd';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/all';
import './contactVerificationStyles.css';

class ContactEmailVerification extends Component {
    state = {
      verifying: false,
      verificationStatus: undefined,
      verifiedEmail: undefined
    }

    componentDidMount () {
      const { hash, verifyContactEmailHash } = this.props;
      if (hash && hash.length > 0) {
        this.setState({ verifying: true });
        verifyContactEmailHash('CUSTOMER_CONTACT_EMAIL', hash).then(res => {
          if (res.customer) {
            this.setState({ verificationStatus: true, verifying: false, verifiedEmail: res.customer.contactEmail });
          } else {
            this.setState({ verificationStatus: false, verifying: false });
          }
        });
      }
    }

    renderStatus = () => {
      const { verificationStatus } = this.state;
      setTimeout(this.redirectOnResponse, 5000);
      if (verificationStatus) return <div className='emailVerification'><AiOutlineCheckCircle style={{ color: 'green' }} /> <p>Verification Successful!</p></div>;
      else return <div className='emailVerification'><AiOutlineCloseCircle style={{ color: 'red' }} /><p>Verification failed!</p></div>;
    }

    redirectOnResponse = () => this.props.history.replace('/');

    render () {
      const { verifying } = this.state;
      return (
        <div>
          <div style={{ height: 100, backgroundColor: '#f0f2f5', position: 'fixed', width: '100vw', zIndex: 1000, top: 0 }} />
          <Spin spinning={verifying} tip={verifying && 'Verifying Email'} style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {verifying === false && this.renderStatus()}
          </Spin>
        </div>
      );
    }
}

const mapStateToProps = (state, props) => {
  const { hash } = props.match.params;

  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return ({ companyId, company: getCompanyById(state, companyId), hash });
};

export default withRouter(connect(mapStateToProps, { verifyContactEmailHash })(ContactEmailVerification));
