import './index.css'

import { Button, Popconfirm, Row, Spin, Table, Tag, Tooltip } from "antd";
import React, { Component } from 'react'

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { currencyFormat } from "../../helpers/common";
import { format } from "currency-formatter";
import { getCompanyById, getAuthenticity, getInvnentoryItems, getFilteredItemTotalCount, getFilteredItemQHITotal } from '../../reducers';
import { showLoadingMessage, hideMessage } from '../../components/Message';
import moment, { weekdaysShort } from 'moment';

const dateFormat = 'YYYY-MM-DD';
const recordCountStyle = {
  fontWeight: 500,
  opacity: 0.5,
  float: 'right',
  paddingTop: 5,
}

class InventoryListTable extends Component {
  state = { invoices: [], loading: false }

  componentDidMount = () => {

  }

  // componentWillReceiveProps = (nextProps) => {
  //   const { query } = nextProps
  //   if (nextProps.query !== this.props.query)
  //     this.setState({ loading: true })
  //   this.props.fetchCompanies().then(() => {
  //     this.setState({ loading: false })
  //   });

  // }

  componentWillUnmount() {
    hideMessage()
  }

  showLoadingMessage = () => {
    showLoadingMessage('Refreshing Subscriptions.');
  }

  getColumn = () => {
    const { onEditInventoryItem } = this.props;
    const columns = [{
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'left',
      render: text => <p>{text}</p>,
    },
    {
      title: 'Desc.',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      align: 'left',
      width: 300,
      render: text => <p>{text}</p>,
    },
    {
      title: 'QIH',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      render: text => <p>{text}</p>,
    },
    {
      title: 'ROL',
      dataIndex: 'reorderLevel',
      key: 'reorderLevel',
      align: 'right',
      render: text => <p>{text}</p>,
    },
    {
      title: 'ROQ',
      dataIndex: 'reorderQuantity',
      key: 'reorderQuantity',
      align: 'right',
      render: text => <p>{text}</p>,
    },
    {
      title: 'RES. Q',
      dataIndex: 'reservedQuantity',
      key: 'reservedQuantity',
      align: 'right',
      width: 100,
      // render: text => <Tooltip title={getStatusTooltip(text)}><Tag color="#108ee9">{text}</Tag></Tooltip>,
    },

    {
      title: 'Balance Q',
      dataIndex: 'reservedQuantity',
      key: 'reservedQuantity',
      align: 'reservedQuantity',
      render: (text, record) => <p>{record.quantity - record.reservedQuantity}</p>,
    },

    {
      title: 'Action',
      dataIndex: 'reservedQuantity',
      key: 'reservedQuantity',
      align: 'reservedQuantity',
      render: (text, record) => <Button type={'primary'} size='small' icon={'edit'} onClick={() => onEditInventoryItem(record)} />,
    },

    ]

    return columns;
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.props.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        const inventoryItemIds = selectedRowKeys;
        this.props.onSelectInventoryItem(inventoryItemIds, selectedRowKeys, selectedRows)
      }
    }
  }

  getInvoiceTotals = invoiceList => {
    let totalAmount = 0;
    let totalOutstanding = 0;

    invoiceList.forEach(invoice => {
      if (invoice) {
        totalAmount = totalAmount + invoice.netamount;
        totalOutstanding = totalOutstanding + invoice.balancetosettle;
      }
    })
    return { totalAmount, totalOutstanding, count: invoiceList.length }
  }

  generateTableFooter = (totals) => {
    const amountelements = document.getElementsByClassName('amountClass');
    const outstandingElements = document.getElementsByClassName('outstandingClass');
    const amountElement = amountelements.item(0);
    const outstandingElement = outstandingElements.item(0);
    const { totalAmount, totalOutstanding, count } = totals
    return window.innerWidth > 865 ? <div>
      <p style={{ position: 'absolute', padding: 2 }}><b style={{ paddingLeft: 10 }}>Total of {count} Records</b></p>
      <div style={{ marginLeft: amountElement && amountElement.offsetLeft, width: amountElement && outstandingElement && outstandingElement.offsetWidth + amountElement.offsetWidth, display: 'flex' }}>
        <p style={{ width: outstandingElement && outstandingElement.offsetWidth, textAlign: 'right', paddingRight: 30 }}>{format(totalAmount, currencyFormat)}</p>
        <p style={{ width: amountElement && amountElement.offsetWidth, textAlign: 'right', paddingRight: 30 }}>{format(totalOutstanding, currencyFormat)}</p>
      </div>
      {/*<p style={{marginLeft: amountElement && amountElement.offsetLeft, width: amountElement && amountElement.offsetWidth, textAlign: 'right', padding: '5px 15px'}}>{format(totalAmount, currencyFormat)}</p>*/}
    </div> :
      <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
        <p>No of Records : {count}</p>
        <p>Total Amount : <b>{format(totalAmount, currencyFormat)}</b></p>
        <p>Total Outstanding : <b>{format(totalOutstanding, currencyFormat)}</b></p>
      </div>
  }

  generateTableFooter = (invoiceList, totals, columns) => {
    let totalAlignment = 0;
    for (let i = 0; i <= 6; i++) {
      totalAlignment = totalAlignment + columns[i].width
    }

    if (columns.find(column => column.title === 'Actions')) {
      return window.innerWidth > 360 ? <p><b>Total of {invoiceList.length} Records</b><span style={{ position: 'absolute', left: totalAlignment - 120, width: 'fit-content' }}><b>{format(totals.totalAmount, currencyFormat)}</b></span><span style={{ position: 'absolute', left: totalAlignment - 10, width: 'fit-content' }}><b>{format(totals.totalOutstanding, currencyFormat)}</b></span></p> : <p><b><span>Total Amount : {format(totals.totalAmount, currencyFormat)}</span><br /><span>Total Outstanding : {format(totals.totalOutstanding, currencyFormat)}</span></b></p>
    } else {
      return <p><b>Total of {invoiceList.length} Records</b><span style={{ position: 'absolute', left: totalAlignment - 120, width: 'fit-content' }}><b>{format(totals.totalAmount, currencyFormat)}</b></span><span style={{ position: 'absolute', left: totalAlignment - 10, width: 'fit-content' }}><b>{format(totals.totalOutstanding, currencyFormat)}</b></span></p>
    }
  }

  getExpiredSubscriptions = () => {
    const now = moment();
    return this.props.subscriptions.filter(subscription => moment(subscription.expiryDate).isBefore(now));
  }

  getExtendedSubscritions = () => {
    const { subscriptions, masterGraceDays } = this.props;
    console.log("subscriptions", subscriptions)
    return subscriptions.filter(subscription => parseInt(subscription.graceDays) !== masterGraceDays);
  }

  getFilteredSubscriptions = () => {
    const { query, subscriptions } = this.props;
    let filteredOrderList = subscriptions;

    if (query && query.text) {
      filteredOrderList = subscriptions.filter(subscription => {

        let searchTerm = query.text;
        searchTerm = searchTerm.replace(/-/g, "");

        return subscription.businessName.toLowerCase().includes(searchTerm.toLowerCase())
          || subscription.type.toLowerCase().includes((query.text).toLowerCase())
          || subscription.businessEmail.toLowerCase().includes((query.text).toLowerCase())
      });
    }

    if (query.status && query.status.length > 0) {
      filteredOrderList = filteredOrderList.filter(subscription => {
        return query.status.includes(subscription.status.toUpperCase());
      });
    }

    if (query.from) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (order.expiryDate && moment(order.expiryDate).valueOf() > query.from.valueOf())
      });
    }

    if (query.to) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (order.expiryDate && moment(order.expiryDate).valueOf() < query.to.valueOf())
      });
    }


    return filteredOrderList;
  }

  getRowClassName = subscription => {
    const now = moment();
    const expiry = moment(subscription.expiryDate);
    const graceEnd = moment(subscription.expiryDate);
    graceEnd.add(subscription.graceDays, 'days');

    if (graceEnd.isBefore(now)) return 'table-row-critical';
    else if (expiry.isBefore(now)) return 'table-row-warning';
    else return '';
  }

  handleTableChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      sortedInfo: sorter,
      currentPage: pagination.current,
      expandedRows: []
    });
  };

  getPaginationConfig = () => {
    const { PAGE_SIZE, totalResults } = this.props;
    return {
      pageSize: PAGE_SIZE,
      onChange: this.props.handlePaginationChange,
      total: totalResults
    };
  }


  render() {
    const { enableRowSelection, inventoryItems, selectedRowKeys, showOutstandingInvoices = false, showTotals = false, query, invoicesLoading, totalResults, QHITotal, onClickItemDelete } = this.props;
    //const invoiceList = showOutstandingInvoices ? invoices.filter(invoice => invoice.balancetosettle > 0) : invoices
    //const totals = this.getInvoiceTotals(invoiceList);
    const columns = this.getColumn();
    return (
      <Spin spinning={(this.state.loading && inventoryItems && inventoryItems.length === 0) || invoicesLoading} tip="Loading...">
        <div>
          <div>Total Results {totalResults}</div>
          <div>Total QHI {QHITotal}</div>

          {selectedRowKeys && selectedRowKeys.length > 0 && <div>
            <span>{selectedRowKeys.length} {selectedRowKeys.length === 1 ? 'item selected.' : 'items selected.'}</span>
            <Button type={'danger'} style={{ marginRight: 5 }} onClick={onClickItemDelete}> Delete</Button>
          </div>}
        </div>
        <Table
          rowClassName={this.getRowClassName}
          columns={columns}
          dataSource={inventoryItems}
          rowKey={"id"}
          onChange={this.handleTableChange}
          rowSelection={enableRowSelection && this.getRowSelection()}
          pagination={this.getPaginationConfig()}
          style={{ backgroundColor: 'white', overflowX: "scroll" }} />
      </Spin>
    )
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  console.log("---", getInvnentoryItems(state));
  const { user: { userId: companyId } } = authData;
  return ({
    companyId: companyId,
    company: getCompanyById(state, companyId),
    authData: getAuthenticity(state),
    inventoryItems: getInvnentoryItems(state),
    totalResults: getFilteredItemTotalCount(state),
    QHITotal: getFilteredItemQHITotal(state)
  });
};

export default connect(mapStateToProps, {})(InventoryListTable);
