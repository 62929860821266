import React, { Component } from 'react';
import { Row, Popconfirm, Spin, Button, Table } from 'antd';
import { connect } from 'react-redux';
import { fetchCompanies } from '../../actions/companies';
import { getCompanies } from '../../reducers';
import { Link } from 'react-router-dom';
import { showLoadingMessage } from '../../components/Message';

class CompanyList extends Component {
  state = {
    loading: false
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.fetchCompanies().then(() => {
      this.setState({ loading: false });
    });
  }

  getColumns = () => {
    return [
    //   {
    //   title: 'ID',
    //   dataIndex: 'userId',
    //   key: 'userId',
    //   render: text => <p>{text}</p>,
    // },
      {
        title: 'Name',
        dataIndex: 'email',
        key: 'email',
        render: text => <p>{text}</p>
      },
      {
        title: 'Actions',
        dataIndex: 'userIdentity',
        key: 'userIdentity',
        align: 'right',
        render: (userIdentity) => (
          <Row>
            <Link to={'/users/' + userIdentity + '/viewCompanyInfo'}><Button className='tableButtonView' size='small' icon='eye' /></Link>
            <Link to='#'><Button className='tableButton' type='primary' size='small' icon='edit' /></Link>
            <Popconfirm
              title='Are you sure ?'
              okText='DELETE'
              cancelText='NO'
              onConfirm={() => { }}
            >
              <Button className='tableButton' type='danger' size='small' icon='delete' />
            </Popconfirm>
          </Row>
        )
      }
    ];
  }

  onDeleteCell = () => {
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Customers.');
  }

  getFilteredUsers = () => {
    const { textFilter, users } = this.props;
    let filteredUserList = users;

    if (textFilter) {
      const filterText = textFilter.toLowerCase();
      filteredUserList = users && users.length > 0 && users.filter(user => this.matchStrings(user.email, filterText));
    }

    return filteredUserList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  render () {
    const { loading } = this.state;

    return (
      <Spin spinning={loading && this.props.users && this.props.users.length === 0}>
        <Table columns={this.getColumns()} dataSource={this.getFilteredUsers()} />
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  return ({ users: getCompanies(state) });
};

export default connect(mapStateToProps, { fetchCompanies })(CompanyList);
