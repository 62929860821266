import { combineReducers } from 'redux';
import authData, * as fromAuth from './auth';
import companies, * as fromCompanies from './companies';
import devices, * as fromDevices from './devices';
import subscriptions, * as fromSubscriptions from './subscriptions';
import categories, * as fromCategories from './categories';
import printerLocations, * as fromPrinterLocations from './printerLocations';
import priceLists, * as fromPriceLists from './priceLists';
import orders, * as fromOrders from './orders';
import items, * as fromItems from './items';
import customers, * as fromCustomers from './customers';
import invoices, * as fromInvoices from './invoices';
import receipts, * as fromReceipts from './receipts';
import agent, * as fromAgent from './agent';
import transferNotes, * as fromTransferNotes from './transferNotes';
import cashiers, * as fromCashiers from './cashiers';
import inventoryItems, * as fromInventoryItems from './inventory';
import warehouses, * as fromWarehouses from './warehouses';
import orderItems, * as fromOrderItems from './orderItems';
import receiptsTransactions, * as fromReceiptsTransactions from './receiptsTransactions';
import analytics, * as fromAnalytics from './analytics';
import personAddresses, * as fromPersonAddress from './personAddress';
import personFeedback, * as fromPersonFeedback from './personFeedback';
import { filter } from 'lodash';

const incyPosReducers = combineReducers({
  authData,
  companies,
  devices,
  subscriptions,
  categories,
  items,
  printerLocations,
  priceLists,
  orders,
  customers,
  invoices,
  receipts,
  agent,
  transferNotes,
  cashiers,
  inventoryItems,
  warehouses,
  orderItems,
  receiptsTransactions,
  analytics,
  personAddresses,
  personFeedback
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return incyPosReducers(state, action);
};

export default rootReducer;

export const getAuthenticity = state => fromAuth.getAuthenticity(state.authData);
export const getOTP = state => fromAuth.getOTP(state.authData);
export const getAuthData = state => fromAuth.getAuthData(state.authData);

export const getCompanies = state => fromCompanies.getCompanies(state.companies);
export const getCompanyById = (state, companyId) => fromCompanies.getCompanyById(state.companies, companyId);

export const getDevices = state => fromDevices.getDevices(state.devices);
export const getDevicesByCompanyId = (state, companyId) => fromDevices.getDevicesByCompanyId(state.devices, companyId);

export const getSubscriptions = state => fromSubscriptions.getSubscriptions(state.subscriptions);
export const getSubscriptionsByCompanyId = (state, companyId) => fromSubscriptions.getSubscriptionsByCompanyId(state.subscriptions, companyId);

export const getCategories = state => fromCategories.getCategories(state.categories);
export const getCategoriesByParent = (state, parentId) => fromCategories.getCategoriesByParent(state.categories, parentId);
export const getCategoryTree = (state, categoryId) => fromCategories.getCategoryTree(state.categories, categoryId);
export const getCategory = (state, categoryId) => fromCategories.getCategory(state.categories, categoryId);
export const getFamilyIdList = (state, categoryId, familyIdList) => fromCategories.getFamilyIdList(state.categories, categoryId, familyIdList);
export const getCategoryData = (state, categoryId) => fromCategories.getCategoryData(state.categories, categoryId);

export const getItems = state => fromItems.getItems(state.items);
export const getItem = (state, itemId) => fromItems.getItem(state.items, itemId);
export const getItemsByCategory = (state, parentId) => fromItems.getItemsByCategory(state.items, parentId);
export const getItemsOnlyByCategory = (state, parentId) => fromItems.getItemsOnlyByCategory(state.items, parentId);
export const getItemsByIsIngredient = state => fromItems.getItemsByIsIngredient(state.items);
export const getItemsByIsModifier = state => fromItems.getItemsByIsModifier(state.items);
export const isItemsFetching = state => fromItems.isFetching(state.items);
export const getItemsByVariantGroupId = (state, groupId) => fromItems.getItemsByVariantGroupId(state.items, groupId);

export const getPrinterLocations = state => fromPrinterLocations.getPrinterLocations(state.printerLocations);
export const getPriceLists = state => fromPriceLists.getPriceLists(state.priceLists);
export const getOrders = state => fromOrders.getOrders(state.orders);
export const getFirstOrder = state => fromOrders.getFirstOrder(state.orders);
export const getCustomers = state => fromCustomers.getCustomers(state.customers);

export const getInvoices = state => fromInvoices.getInvoices(state.invoices);
export const getInvoicesForCustomer = (state, customerId) => fromInvoices.getInvoicesForCustomer(state.invoices, customerId);

export const getReceipts = state => fromReceipts.getReceipts(state.receipts);
export const getReceiptById = (state, id) => fromReceipts.getReceiptById(state.receipts, id);
export const getFilteredReceipts = state => fromReceipts.getFilteredReceipts(state.receipts);

export const getInvoiceForPrint = (state, invoiceId) => fromInvoices.getPrintInvoice(state.invoices, invoiceId);
export const getAgentDetails = (state) => fromAgent.getAgent(state.agent);

export const getTransferNotes = state => fromTransferNotes.getTransferNotes(state.transferNotes);
export const getTransferNoteById = (state, id) => fromTransferNotes.getTransferNoteById(state.transferNotes, id);

export const getOrdersByTransferNoteId = (state, transferNoteId) => fromOrders.getOrdersByTransferNoteId(state.orders, transferNoteId);
export const getEligibleOrdersForTransferNote = state => fromOrders.getEligibleOrdersForTransferNote(state.orders);

export const getActiveOrderFilter = state => fromOrders.getActiveFilter(state.orders);
export const getFilteredOrders = state => fromOrders.getFilteredOrders(state.orders);
export const getFilteredOrderTotalCount = state => fromOrders.getFilteredOrderTotalCount(state.orders);

export const getActiveCustomerFilter = state => fromCustomers.getActiveFilter(state.customers);
export const getFilteredCustomers = state => fromCustomers.getFilteredCustomers(state.customers);
export const getCustomerById = (state, id) => fromCustomers.getCustomerById(state.customers, id);
export const getFilteredCustomerTotalCount = state => fromCustomers.getFilteredCustomerTotalCount(state.customers);

export const getFilteredCashiers = state => fromCashiers.getFilteredCashiers(state.cashiers);

export const getOrderAnalytics = (state, filter) => fromAnalytics.getAnalytics(state.analytics, filter)
export const getOrderItemAnalytics = (state, filter) => fromAnalytics.getAnalytics(state.analytics, filter)


export const getBusinesses = state => {
  const authenticity = getAuthenticity(state);
  if (!authenticity) return [];

  const { user: { userId: companyId } } = authenticity;
  const company = getCompanyById(state, companyId);
  if (!company) return [];

  const businesses = company.businesses;

  return businesses || [];
}

export const getBranches = state => {
  const authenticity = getAuthenticity(state);
  if (!authenticity) return [];

  const { user: { userId: companyId } } = authenticity;
  const company = getCompanyById(state, companyId);
  if (!company) return [];

  const business = company.businesses ? company.businesses[0] : undefined;
  if (!business) return [];

  return business.branches || [];
}

export const isAllowedAllBranches = state => {
  const branches = getBranches(state);
  return branches.length > 0 && branches[0].role === 'OWNER';
}

/**
 * Returns the selected branch id.
 * Before returning the selected id, it validates the available branches.
 * If the selected branch id is of an available branch, it returns that id.
 * Otherwise it will return All Branches or null according to the situation.
 */
export const getSelectedBranchId = state => {
  const selectedId = fromCompanies.getSelectedBranchId(state.companies);
  const branches = getBranches(state);
  const branchIsAvailable = branches.filter(branch => branch.id === selectedId).length > 0;

  if (branchIsAvailable) return selectedId;
  else if (isAllowedAllBranches(state)) return 0; // 0 means all
  else if (branches.length > 0) return branches[0].id;
  else return null;
}
export const getInvnentoryItems = state => fromInventoryItems.getInventoryItems(state.inventoryItems);
export const getFilteredItemTotalCount = state => fromInventoryItems.getFilteredItemTotalCount(state.inventoryItems);
export const getFilteredItemQHITotal = state => fromInventoryItems.getFilteredItemQIHTotal(state.inventoryItems);


export const getWarehouses = state => fromWarehouses.getWareshouses(state.warehouses);
export const getWarehouseByWarehouseId = (state, warehouseId) => fromWarehouses.getWareshouses(state.warehouses, warehouseId);
export const getLocationsByWarehouseId = (state, warehouseId) => {
  return fromWarehouses.getLocationsByWarehouseId(state.warehouses, warehouseId);
};

export const getFilteredOrderItems = (state, filter) => fromOrderItems.getFilteredOrderItems(state.orderItems, filter);
export const getFilteredOrderItemTotalCount = (state, filter) => fromOrderItems.getFilteredOrderItemTotalCount(state.orderItems, filter);
export const getFilteredOrderItemsByActiveFilter = (state) => fromOrderItems.getFilteredOrderItemsByActiveFilter(state.orderItems);


export const getFilteredReceiptTransactions = (state, filter) => fromReceiptsTransactions.getFilteredReceiptTransactions(state.receiptsTransactions, filter);
export const getFilteredReceiptTransactionTotalCount = (state, filter) => fromReceiptsTransactions.getFilteredReceiptTransactionTotalCount(state.receiptsTransactions, filter);

export const getFilteredPersonAddresses = (state, filter) => fromPersonAddress.getFilteredPersonAddresses(state.personAddresses, filter);
export const getFilteredPersonAddressesTotalCount = (state, filter) => fromPersonAddress.getFilteredPersonAddressesTotalCount(state.personAddresses, filter);
export const getAddressById = (state, id) => fromPersonAddress.getAddressById(state.personAddresses, id);

export const getFilteredPersonFeedback= (state, filter) => fromPersonFeedback.getFilteredPersonFeedback(state.personFeedback, filter);
export const getFilteredPersonFeedbackTotalCount = (state, filter) => fromPersonFeedback.getFilteredPersonFeedbackTotalCount(state.personFeedback, filter);