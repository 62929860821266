import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Button, Col } from 'antd';
import AdvancedSearchForm from './AdvancedSearchForm';
import TranferNoteList from './TranferNoteList';
import { orderPaymentsReconilation } from '../../../actions/Orders';
import { fetchTransferNotesFromAdvancedSearch, deleteTransferNote } from '../../../actions/transferNotes';

import { getAuthenticity, getTransferNotes, getCompanyById } from '../../../reducers';
import moment from 'moment';
import { showLoadingMessage } from '../../../components/Message';

import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import SideBar from '../../../components/SideBar';

import xlsx from 'xlsx';
import { saveAs } from 'save-as-js';

class OrdersContainer extends Component {
  constructor() {
    super();
    this.state = {
      query: {
        userId: undefined,
        transferNoteNumber: undefined,
        filterText: undefined,
        branchId: undefined,
        fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
        toDate: moment(new Date()).endOf('day'),
        page: 0,
        pageSize: 0,
        status: [],
        clientFilter: undefined,
        referenceNoFilter: undefined,
        bankAccountFilter: undefined
      },
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      orderIds: undefined,
      onlyOutstanding: false,
      actionProcessing: false
    };
  }

  componentDidMount() {
    this.handleFetchOrders();
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  handleFetchOrders = () => {
    const { authenticity: { user: { userId } }, fetchTransferNotesFromAdvancedSearch, transferNotes } = this.props;
    const { query } = this.state;

    if (transferNotes && transferNotes.length > 0) {
      this.showLoadingMessage();
      fetchTransferNotesFromAdvancedSearch({ ...query, userId }).then(response => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ loading: true });
      fetchTransferNotesFromAdvancedSearch({ ...query, userId }).then(response => {
        this.setState({ loading: false });
      });
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing TransferNotes.');
  }

  handleDateRangeChange = date => {
    const queryData = {
      ...this.state.query,
      fromDate: date[0].startOf('day'),
      toDate: date[1].endOf('day')
    };

    this.setState({ query: queryData });
  }

  handleInputTextChange = (e, type) => {
    const value = e.target.value;

    this.setState({ query: { ...this.state.query, [type]: value } });
  }

  handleStatusSelectionChange = value => {
    this.setState({ query: { ...this.state.query, status: value } });
  }

  handleOnClickSearch = () => {
    const { authenticity: { user: { userId } }, fetchTransferNotesFromAdvancedSearch } = this.props;
    const { query } = this.state;
    this.setState({ loading: true });
    fetchTransferNotesFromAdvancedSearch({ ...query, userId }).then(response => {
      this.setState({ loading: false });
    });
  }

  onChangeOutstandingFilter = (e) => {
    console.log(e.target.checked);
    this.setState({ onlyOutstanding: e.target.checked });
  }

  getFilteredTransferNotes = () => {
    const { transferNotes } = this.props;
    const { query } = this.state;
    const { transferNoteNumber, bankAccountFilter, referenceNoFilter, clientFilter, fromDate, toDate, status } = query;

    return transferNotes.filter(transferNote => {
      return (transferNoteNumber ? (transferNote.number.toLowerCase() === transferNoteNumber.toLowerCase()) : true) &&
        (referenceNoFilter
          ? (transferNote.transferReferenceNumber.toLowerCase().includes(referenceNoFilter.toLowerCase()) ||
            transferNote.number.toLowerCase().includes(referenceNoFilter.toLowerCase())) : true) &&
        (bankAccountFilter
          ? (transferNote.bank.toLowerCase().includes(bankAccountFilter.toLowerCase()) ||
            transferNote.bankBranch.toLowerCase().includes(bankAccountFilter.toLowerCase()) ||
            transferNote.bankAccountNumber.toLowerCase().includes(bankAccountFilter.toLowerCase()) ||
            transferNote.bankCode.toLowerCase().includes(bankAccountFilter.toLowerCase()) ||
            transferNote.bankBranchCode.toLowerCase().includes(bankAccountFilter.toLowerCase()) ||
            transferNote.bankAccountHolderName.toLowerCase().includes(bankAccountFilter.toLowerCase())) : true) &&
        (clientFilter
          ? (transferNote.client.name.toLowerCase().includes(clientFilter.toLowerCase()) ||
            transferNote.client.email.toLowerCase().includes(clientFilter.toLowerCase())) : true) &&
        transferNote && (moment(transferNote.transferDate).isAfter(moment(fromDate)) && transferNote && moment(transferNote.transferDate).isBefore(moment(toDate))) &&
        // (!status || status.length === 0 || status.includes(order.status.toUpperCase()))
        (!status || status.length === 0 || status.includes(transferNote.status.toUpperCase()));
    });
  }

  handleRowSelection = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  }

  handleReconciledOrders = () => {
    const { selectedRows } = this.state;

    const groupedOrdersByCustomer = this.getOrdersGroupedByCustomer(selectedRows);

    this.setState({ actionProcessing: true });
    this.props.orderPaymentsReconilation(groupedOrdersByCustomer).then((response) => {
      const { error } = response;
      this.setState({ actionProcessing: false });
      if (error) {
        showErrorNotification('Failed', "Something wen't wrong. Try again later.");
      } else {
        showSuccessNotification('Successful', 'Successfully reconciled.');
      }
    });
  }

  getOrdersGroupedByCustomer(orders) {
    const groupedOrdersByCustomer = orders.reduce((groupedOrdersList, order) => {
      const groupData = groupedOrdersList[order.client.id] || {};
      groupData.clientId = groupData.clientId || order.client.id;
      const orderIds = groupData.orderIds || [];

      if (!orderIds.includes(order.id)) orderIds.push(order.id);
      groupData.orderIds = orderIds;

      groupedOrdersList[order.client.id] = groupData;
      return groupedOrdersList;
    }, {});

    return Object.values(groupedOrdersByCustomer);
  }

  handleClickDelete = (dataRow) => {
    if (!dataRow) { return; }

    this.setState({ loading: true });
    this.props.deleteTransferNote(dataRow.client.id, dataRow.id).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed deleting transfer note', error);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
        showSuccessNotification('Deleted', 'Successfully deleted.');
      }
    });
  }

  handleDownloadReportClick = transferNote => {
    console.log(transferNote);
    transferNote && this.prepareReportData(transferNote);
  }

  prepareReportData = transferNote => {
    const orders = transferNote.orders ? transferNote.orders : [];
    const remarks = transferNote.remarksList ? transferNote.remarksList : [];
    const data = [['Transfer No:', transferNote.number],
    ['Transfer Amount:', transferNote.amount],
    ['Transfer Date:', moment(transferNote.transferDate).format('YY-MM-DD hh:mm')],
    ['Transfered By:', transferNote.transferredBy],
    ['Transfer Reference No', transferNote.transferReferenceNumber],
    [],
    ['Client Details'],
    ['Client Id', transferNote.client.id],
    ['Client Name', transferNote.client.name],
    ['Client Email', transferNote.client.email],
    [],
    ['Bank Details'],
    ['Account Number', transferNote.bankAccountNumber],
    ['Bank Account HolderName', transferNote.bankAccountHolderName],
    ['Bank', transferNote.bank],
    ['Branch', transferNote.bankBranch],
    [],
    ['Other Details'],
    ['Created At', transferNote.createdTime && moment(transferNote.createdTime).format('YY-MM-DD hh:mm')],
    ['Modified At', transferNote.modifiedTime && moment(transferNote.modifiedTime).format('YY-MM-DD hh:mm')],
    ['Confirmed At', transferNote.modifiedTime && moment(transferNote.confirmedTime).format('YY-MM-DD hh:mm')],
    ['Verified At', moment(transferNote.verifiedTime).format('YY-MM-DD hh:mm')],
    ['completed At', moment(transferNote.completedTime).format('YY-MM-DD hh:mm')],
    [],
    ['Order Details', transferNote.orders && transferNote.orders.length + ' Orders'],
    ['Order Date', 'Order Number', 'Customer Name', 'Status', 'Order Total', 'Commission', 'Total Receivable Amt']];
    let datarow = [];
    for (var i = 0; i < orders.length; i++) {
      const { date, orderNumber, customer, total, status, commission } = orders[i];
      const payableAmount = commission ? total - commission.value : 0;
      const commissionValue = commission ? commission.value : 0;
      datarow = [moment(date).format('YYYY-MM-DD'), orderNumber, customer.name, status, total, commissionValue, payableAmount];
      data.push(datarow);
    }

    data.push([], ['Remarks:']);
    let datarowRemarks = [];
    for (var x = 0; x < remarks.length; x++) {
      const { type, message } = remarks[x];
      datarowRemarks = [type, message];
      data.push(datarowRemarks);
    }

    const filename = 'Transfer Note Order Details - ' + transferNote.number + '.xlsx';
    const ws = data && xlsx.utils.aoa_to_sheet(data);
    const wb = { SheetNames: ['Sheet1'], Sheets: { Sheet1: ws } };
    var wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
    var wbout = xlsx.write(wb, wopts);
    saveAs(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }), filename);
  }

  s2ab = s => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  render() {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { query, loading, selectedRowKeys, selectedRows } = this.state;
    const filteredTransferNotes = this.getFilteredTransferNotes();
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Card
              title={<h4 style={{ fontWeight: 300 }}>Transfer Notes</h4>} style={{ margin: 5 }}
              extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
            >
              <AdvancedSearchForm
                onClickSearch={this.handleOnClickSearch}
                onInputTextChange={this.handleInputTextChange}
                onDateRangeChange={this.handleDateRangeChange}
                onChangeOutstandingFilter={this.onChangeOutstandingFilter}
                onStatusSelectionChange={this.handleStatusSelectionChange}
                query={query}
              />
            </Card>
            <Card style={{ margin: 5 }}>
              <TranferNoteList
                business={business}
                transferNotes={filteredTransferNotes.length > 0 ? filteredTransferNotes : []}
                loading={loading}
                selectedRowKeys={selectedRowKeys}
                selectedRows={selectedRows}
                handleDownloadReportClick={this.handleDownloadReportClick}
                onRowSelection={this.handleRowSelection}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return {
    company: getCompanyById(state, companyId),
    authenticity: getAuthenticity(state),
    transferNotes: getTransferNotes(state)
  };
};
export default connect(mapStateToProps, { fetchTransferNotesFromAdvancedSearch, orderPaymentsReconilation, deleteTransferNote })(OrdersContainer);
