import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, Button, Input } from 'antd';
import CompanyList from './CompanyList';
import SideBar from '../../components/SideBar';
import { getAuthenticity, getCompanyById } from '../../reducers';

class StudentList extends Component {
  state = {
    idFilter: undefined,
    textFilter: undefined,
    fullIdFilter: undefined
  }

  onClickSearch = () => {
    this.setState({ fullIdFilter: this.state.idFilter });
  }

  render () {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { idFilter, textFilter, fullIdFilter } = this.state;

    return (
      <div className='container'>
        {business && !business.active && <p style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, color: 'red' }}>This business is no longer active.</p>}
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={12} xs={12}>
            <Card style={{ margin: 5 }} title={<h1 style={{ fontWeight: 300 }}>Users</h1>}>
              <Row gutter={10}>
                <Col lg={20}>
                  <Input style={{ marginBottom: 10 }} value={textFilter} onChange={(event) => this.setState({ textFilter: event.target.value, idFilter: undefined })} />
                </Col>
                <Col lg={4}>
                  <Button type='primary' block icon='searchs'>Search</Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 50 }}>
                <CompanyList idFilter={idFilter} fullIdFilter={fullIdFilter} textFilter={textFilter} />
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return ({
    companyId: companyId,
    company: getCompanyById(state, companyId)
  });
};

export default connect(mapStateToProps, null)(StudentList);
