import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, Divider, Icon, Form, Spin } from 'antd';
import SideBar from '../../../components/SideBar';
import { fetchCompany, verifyClient } from '../../../actions/companies';
import { getAuthenticity, getCompanyById } from '../../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import { showLoadingMessage } from '../../../helpers/Message';

class AgentDetails extends Component {
  state = {
    loading: false,
    textFilter: '',
    code: '',
    codeStatus: '',
    loadingCode: '',
    changingAgent: false
  }

  componentDidMount () {
    const { fetchCompany, authenticity } = this.props;
    console.log(':AUTHENTICITY', authenticity);
    const { user: { userId } } = authenticity;

    if (this.props.company) {
      this.showLoadingMessage();
      fetchCompany(userId).then(res => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ loading: true });
      fetchCompany(userId).then(res => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount () {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Agent details.');
  }

  onUpdateVerificationCode = () => {
    const { user: { userId: companyId, username } } = this.props.authenticity;

    const { code } = this.state;
    if (code.replace(' ', '').length >= 0) {
      this.setState({ loadingCode: true });
      this.props.verifyClient(companyId, { code: code, username: username }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loadingCode: false });
        } else {
          if (response.company.code === 400) {
            showErrorNotification('Failed.', response.company.message);
          } else {
            showSuccessNotification('Updated.', 'Agent added successfully.');
            this.setState({ changingAgent: false });
          }
          this.setState({ loadingCode: false });
        }
      });
    } else {
      this.setState({ codeStatus: 'error' });
    }
  }

  render () {
    const { company } = this.props;
    const { code, codeStatus, loading, changingAgent } = this.state;
    const agent = company && company.agent;
    const addressDetails = agent && agent.addressDetails && Object.keys(agent.addressDetails).length > 0 && agent.addressDetails['0'];
    const contactNumberDetails = agent && agent.contactNumberDetails && Object.keys(agent.addressDetails).length > 0 && Object.values(agent.contactNumberDetails).filter(detail => detail.type === 'BUSINESS');
    const contactDetails = contactNumberDetails && contactNumberDetails.length > 0 && contactNumberDetails[0].contactDetails && Object.keys(contactNumberDetails[0].contactDetails).length > 0 && contactNumberDetails[0].contactDetails['0'];
    // const business = company && company.businesses[0] && company.businesses[0];
    // const generalSettings = business && business.generalSettings;
    // const commissionPercentageDiscount = generalSettings && generalSettings.commissionPercentageDiscount;
    // const agentSettings = agent && agent.settings;
    // const commissionPercentage = agentSettings && agentSettings.commissionPercentage
    // console.log(commissionPercentageDiscount)
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Spin spinning={loading}>
              <Row>
                <Card
                  title={<h4 style={{ fontWeight: 300 }}>Agent Details</h4>}
                  style={{ margin: 5 }}
                  extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
                >

                  {agent &&
                    <Row gutter={10}>
                      <Col lg={15} md={15} sm={24} xs={24}>
                        <table>
                          <tbody>
                            {contactDetails && contactDetails.number && <tr style={{ height: 24 }}>
                              <td style={{ width: 25 }}><Icon type='phone' /></td>
                              <td>Contact No</td>
                              <td style={{ width: 25, textAlign: 'center' }}>:</td>
                              <td>{contactDetails.number}</td>
                            </tr>}
                            {agent && agent.email && <tr style={{ height: 24 }}>
                              <td style={{ width: 25 }}><Icon type='mail' /></td>
                              <td>Email</td>
                              <td style={{ width: 25, textAlign: 'center' }}>:</td>
                              <td>{agent.email}</td>
                            </tr>}
                            {addressDetails && addressDetails.address && <tr style={{ height: 24 }}>
                              <td style={{ width: 25 }}><Icon type='pushpin' /></td>
                              <td>Address</td>
                              <td style={{ width: 25, textAlign: 'center' }}>:</td>
                              <td>{addressDetails.address}</td>
                            </tr>}
                          </tbody>
                        </table>
                      </Col>
                      <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
                      <Col lg={12} md={12} sm={24} xs={24}>
                        <Col lg={9} md={9} sm={9} xs={9}>
                          <Form.Item>
                            <Button className='primary-button' loading={this.state.loadingCode} style={{ marginTop: 20 }} block type='primary' onClick={() => this.setState({ changingAgent: true })}>Change Agent</Button>
                          </Form.Item>
                        </Col>
                      </Col>

                    </Row>}

                  {(!agent || changingAgent) && <Row gutter={10}>
                    {changingAgent && <Col lg={24} md={24} sm={24} xs={24}><Divider /></Col>}
                    <Col lg={24} md={24} sm={24} xs={24}>
                      <p>Agent varification code</p>
                      <Col lg={4}>
                        <Form.Item required validateStatus={codeStatus}>
                          <div>
                            Verification Code
                          </div>
                        </Form.Item>
                      </Col>
                      <Col lg={5}>
                        <Form.Item required validateStatus={codeStatus}>
                          <div>
                            <input
                              className='ant-input'
                              value={code}
                              onChange={(event) => this.setState({ code: event.target.value, codeStatus: '' })}
                              onFocus={(event) => event.target.select()}
                            />

                          </div>
                        </Form.Item>
                      </Col>
                      <Col lg={4}>
                        <Form.Item>
                          <Button className='primary-button' loading={this.state.loadingCode} block type='primary' onClick={() => this.onUpdateVerificationCode()}>Save</Button>
                        </Form.Item>

                      </Col>
                    </Col>
                  </Row>}
                </Card>
              </Row>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const authenticity = getAuthenticity(state);
  const { user: { userId: companyId } } = authenticity;

  return ({
    authenticity: authenticity,
    companyId: companyId,
    company: getCompanyById(state, companyId)
  });
};

export default connect(mapStateToProps, { fetchCompany, verifyClient })(AgentDetails);
