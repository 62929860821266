import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Table, Input, Avatar, Spin, Button } from 'antd';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import { getItems, isItemsFetching, getItemsByIsIngredient } from '../../reducers';
import { fetchItems } from '../../actions/items';

class ItemList extends Component {
  state = {
    loading: false,
    textFilter: undefined,
    optionValue: 1
  }

  componentDidMount = () => {
    // const { companyId } = this.props;
    // this.props.fetchItems(companyId);
    window.addEventListener('focus', this.handleFocus);
  }

  componentWillUnmount = () => {
    window.removeEventListener('focus', this.handleFocus);
  }

  handleFocus = () => {
    // this.props.fetchItems(this.props.companyId);
  }

  setLoading = loading => {
    this.setState({ loading: loading });
  }

  onItemSearch = value => this.setState({ textFilter: value })

  getItems = () => {
    const { items } = this.props;
    const { textFilter } = this.state;

    return textFilter ? items.filter(item => {
      return (item && item.name.toLowerCase().includes(textFilter.toLowerCase())) ||
        (item && item.code.toLowerCase().includes(textFilter.toLowerCase()));
    }) : items;
  }

  getColumns = () => {
    const { business } = this.props;
    console.log("---------------", business)
    const columns = [
      {
        title: 'Image',
        dataIndex: 'imageSrc',
        width: '15%',
        render: (imageSrc, dataRow) => {
          const imgUrl = imageSrc != null ? imageSrc : '/images/empty-image.png';
          return <Avatar src={imgUrl} size='large' />;
        }
      }, {
        title: 'Code',
        dataIndex: 'code',
        editable: false
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        editable: false
      },
      {
        title: 'Price',
        dataIndex: 'itemPrices',
        align: 'right',
        editable: false,
        render: (itemPrices, dataRow) => <div>{format(this.getItemPrice(itemPrices), currencyFormat(business))}</div>
      }
    ];

    return columns;
  }

  getItemPrice = itemPricesMap => {
    const { priceListId } = this.props;
    console.log(priceListId);
    const itemPrices = itemPricesMap && Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let price = 0;

    itemPrices && itemPrices.length > 0 && itemPrices.forEach(itemPrice => {
      if (itemPrice.priceList && (itemPrice.priceList.id === priceListId)) price = itemPrice.price;
    });

    return price;
  }

  onOptionChange = e => this.setState({ optionValue: e.target.value });

  render() {
    const { onClose, visible, onSelectItem, fetching, type } = this.props;
    const { loading, textFilter } = this.state;

    const columns = this.getColumns();
    const items = this.getItems();

    return (
      <div>
        <Drawer
          placement='right'
          closable={false}
          onClose={onClose}
          visible={visible}
          width='50%'
        >
          <div>
            <h4>Add variant</h4>
            <Button type='link' style={{ fontWeight: 400, padding: 0 }} onClick={() => onSelectItem(type, undefined)}>Create a new item</Button>
            <div style={{ fontWeight: 600 }}>Select an existing item</div>
          </div>

          <div style={{ marginTop: 10 }}>
            <Spin spinning={(loading && items && items.length === 0) || (fetching && items && items.length === 0)}>
              <div style={{ marginBottom: 10 }}>
                <Input placeholder='Search Item' value={textFilter} allowClear onChange={(event) => this.onItemSearch(event.target.value)} />
              </div>
              <div>
                <Table
                  scroll={{ y: window.innerHeight - 361 }}
                  style={{ minHeight: window.innerHeight - 260, borderBottom: '1px solid #f1f1f1' }}
                  columns={columns}
                  className='itemList'
                  dataSource={this.getItems()}
                  showHeader
                  pagination
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => onSelectItem(type, record.id)
                    };
                  }}
                />
              </div>
            </Spin>
          </div>
        </Drawer>
      </div>
    );
  }
}

const filterIngredients = (ingredients, itemId) => {
  var filteredIngredients = [];
  if (itemId) {
    filteredIngredients = ingredients.filter(ingredient => ingredient.id !== itemId);
  } else {
    filteredIngredients = ingredients;
  }
  return filteredIngredients;
};

const mapStateToProps = (state, props) => {
  const { itemId } = props;
  if (props.type === 'ALL') {
    return {
      items: getItems(state),
      fetching: isItemsFetching(state)
    };
  } else if (props.type === 'MODIFIER' || props.type === 'GROUP_MODIFIRE_INGREDIENT') {
    return {
      items: filterIngredients(getItemsByIsIngredient(state), itemId),
      fetching: isItemsFetching(state)
    };
  }
};

export default connect(mapStateToProps, { fetchItems })(ItemList);
