import { combineReducers } from 'redux';
import moment from 'moment';

export const defaultFilter = {
  text: undefined,
  userId: undefined,
  cashierId: undefined,
  fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
  toDate: moment(new Date()).endOf('day'),
  page: 1,
  pageSize: 20,
  status: [],
  method: ''
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CASHIERS': {
      const newState = {};
      action.cashiers && action.cashiers.length > 0 && action.cashiers.forEach(cashier => { newState[cashier.id] = cashier; });
      return newState;
    }
    case 'FETCH_CASHIER': {
      return { ...state, [action.cashier.id]: action.cashier };
    }
    case 'PARTIALLY_FETCH_CASHIERS': {
      const newState = { ...state };
      action.cashiers && action.cashiers.forEach(cashier => { newState[cashier.id] = cashier; });
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CAHSIERS': {
      const newState = [];
      action.cashiers && action.cashiers.forEach(cashier => {
        if (!newState.includes(cashier.id)) {
          newState.push(cashier.id);
        }
      });
      return newState;
    }
    case 'FETCH_CASHIER': {
      if (state.includes(action.cashier.id)) {
        return state;
      } else {
        return [...state, action.cashier.id];
      }
    }
    case 'PARTIALLY_FETCH_CASHIERS': {
      const newState = [];
      action.cashiers && action.cashiers.forEach(cashier => {
        if (!newState.includes(cashier.id)) {
          newState.push(cashier.id);
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'PARTIALLY_FETCH_CASHIERS': {
      const newState = { ...state };
      const key = Object.values(action.query).join('');
      const idsForFilter = [];
      action.cashiers && action.cashiers.forEach(cashier => {
        idsForFilter.push(cashier.id);
      });
      newState[key] = { ids: idsForFilter, totalResults: action.totalResults };
      return newState;
    }
    default:
      return state;
  }
};

export const activeFilter = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_CASHIER':
      return {
        ...state,
        [action.filterType]: action.filter
      };

    case 'UPDATE_ACTIVE_FILTER_CASHIER':
      return {
        ...action.filter
      };

    default:
      return state;
  }
};

const cashiers = combineReducers({
  byId,
  ids,
  byFilter,
  activeFilter
});

export default cashiers;

export const getActiveFilter = state => {
  return state.activeFilter;
};

export const getFilteredCashiers = state => {
  const key = Object.values(getActiveFilter(state)).join('');
  const filterdCashierIds = state.byFilter[key] ? state.byFilter[key].ids : [];

  return filterdCashierIds.map(id => state.byId[id]).filter(cashier => !!cashier);
};