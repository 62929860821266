import React, { Component } from 'react';
import CategoryEditableForm from './CategoryEditableForm';
import { getCategory } from '../../reducers';
import { fetchCategory, updateCategory, addCategory, fetchCategories } from '../../actions/categories';
import { connect } from 'react-redux';
import { fetchCompany } from '../../actions/companies';
import { Spin, List, Form, Input } from 'antd';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import emptyImage from '../../image/empty-image.png';

const defaultCategory = {
  name: '',
  code: '',
  hideFromUsers: false
};

class CategoryFormContainer extends Component {
  state = {
    category: {
      ...defaultCategory
    },
    nameStatus: '',
    codeStatus: '',
    loading: false,
    imageSrc: undefined
  }

  componentDidMount = () => {
    const { companyId, categoryId } = this.props;
    if (categoryId) {
      this.setState({ loading: true });
      this.props.fetchCompany(companyId);
      this.props.fetchCategory(companyId, categoryId).then(response => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    this.setCategoryDetails(nextProps);
  }

  setCategoryDetails = nextProps => {
    const { category } = nextProps;

    category && this.setState({
      category: category
    });
  }

  handleOnChange = (field, value) => {
    switch (field) {
      case 'hideFromUsers':
        this.setState({ category: { ...this.state.category, hideFromUsers: value } });
        break;
      case 'name':
        this.setState({ category: { ...this.state.category, name: value }, nameStatus: '' });
        break;
      case 'code':
        this.setState({ category: { ...this.state.category, code: value }, codeStatus: '' });
        break;
      default:
    }
  }

  handleSubmit = () => {
    if (!this.validateForm()) return;

    const { category } = this.state;
    var obj = {
      name: category.name,
      code: category.code,
      hideFromUsers: category.hideFromUsers

    };

    if (this.state.imageSrc !== undefined) {
      obj = {
        ...obj,
        imageSrc: this.state.imageSrc
      };
    } else {
      obj = {
        name: category.name,
        code: category.code,
        hideFromUsers: category.hideFromUsers,
        imageSrc: ''
      };
    }

    const { categoryId } = this.props;
    if (categoryId) {
      this.handleUpdateCategory(obj);
    } else {
      this.handleAddCategory(obj);
    }
  }

  handleUpdateCategory = (obj) => {
    const { companyId, categoryId } = this.props;

    this.setState({ submitting: true, loading: true });
    this.props.updateCategory(companyId, categoryId, obj).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategory(companyId, categoryId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ loading: false });
        showSuccessNotification('Category updated.', 'Category updated successfully.');
      }
    });
  }

  handleAddCategory = (obj) => {
    const { companyId, parentCategoryId, company, onClose } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;

    const newCategory = {
      ...obj,
      businessId: businessId,
      hideFromUsers: false
    };

    this.setState({ submitting: true, loading: true });
    this.props.addCategory(companyId, parentCategoryId, newCategory).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed adding category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategories(companyId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ submitting: false, loading: false });
        showSuccessNotification('Category added', 'Category added successfully.');
        onClose();
      }
    });
  }

  convertDataURIToBinary = (data) => {
    var BASE64_MARKER = 'data:image/jpeg;base64,';
    // var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = data.replace(BASE64_MARKER, '');
    var raw = atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  validateForm = () => {
    const { category } = this.state;
    const { name, code } = category;
    const validForm = this.isValidName(name) &&
      this.isValidCode(code);

    if (!validForm) {
      this.setState({
        nameStatus: this.getValidationStatus('name', name),
        codeStatus: this.getValidationStatus('code', code)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      case 'code':
        return this.isValidCode(value) ? 'success' : 'error';

      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;
  isValidCode = code => code && code.length > 0;

  handleImageChange = src => {
    // console.log(src)
    this.setState({ imageSrc: src });
  }

  renderCategoryView = () => {
    const { nameStatus, codeStatus, category, loading, imageSrc } = this.state;
    const { editable = false, companyId, editButtonComponent } = this.props;
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
    const imagePath = category.imageSrc ? category.imageSrc : emptyImage;

    return <List.Item key={category.id} style={{ paddingTop: 0 }}>
      <List.Item.Meta
        avatar={
          editable
            ? <div>
              <Form
                style={{ width: '100%' }}
                {...layout}
              >
                <Form.Item label='Name'>
                  <Input value={category.name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                </Form.Item>
              </Form>

              <ImageUploadContainer
                folder={'/' + companyId + '/categories/' + category.id}
                src={imageSrc}
                onImageChange={this.handleImageChange}
              />
              </div>
            : <div>
              <h1 style={{ fontWeight: 'bold', fontSize: 'x-large' }}>{category.name}</h1>
              <img
                width={200}
                alt='logo'
                src={imagePath}
              />
              </div>
        }
      />
      <div style={{ width: '100%' }}>
        {editButtonComponent}
        <CategoryEditableForm
          editable={editable}
          submitting={loading}
          category={category}
          handleOnChange={this.handleOnChange}
          handleSubmit={this.handleSubmit}
          nameStatus={nameStatus}
          codeStatus={codeStatus}
          newImageSrc={imageSrc}
        />
      </div>
           </List.Item>;
  }

  render () {
    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        {this.renderCategoryView()}
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { categoryId } = props;

  return { category: getCategory(state, categoryId) };
};

export default connect(mapStateToProps, { fetchCategory, fetchCompany, updateCategory, addCategory, fetchCategories })(CategoryFormContainer);
