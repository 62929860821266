import React, { Component } from 'react';
import { Col, Row } from 'antd';

class ItemListHeader extends Component {
  render () {
    return (
      <Row className='modifier-item-container' style={{ fontWeight: 600, marginBottom: 15 }}>
        <Col span={2}>
          Image
        </Col>
        <Col span={8}>
          Name
        </Col>
        <Col span={4} className='text-right'>
          Selling Price
        </Col>
        <Col span={4} className='text-center'>
          Stock
        </Col>
        <Col span={4} className='text-center'>
          Available Online
        </Col>
        <Col span={2} className='text-right'>
          Actions
        </Col>
      </Row>
    );
  }
}
export default ItemListHeader;
