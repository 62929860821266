import React, {Component} from 'react';
import { connect } from 'react-redux';
import SendOtp from './components/SendOtp';
import VerifyOtp from './components/VerifyOtp';
import PasswordReset from './components/PasswordReset';
import '../../App.css';
import background from '../../image/work.jpg';
import { getAuthenticity } from '../../reducers';


class ForgotPassword extends Component {
    state = {
        sendVerificationForm: true,
        verifyOtpForm: false,
        resetPasswordForm: false,
        userId: undefined
    }

    handleSubmit = (callBackScreen, callBackData) => {
        switch (callBackScreen){
            case 'Send OTP':
                console.log('Send OTP User Id ', callBackData.userId);
                if(callBackData && callBackData){
                    this.setState({ 
                        sendVerificationForm: false, 
                        verifyOtpForm: true, 
                        resetPasswordForm: false,
                        userId: callBackData.userId
                     });
                }
                break;

            case 'Verify OTP':
                if(callBackData && callBackData.verified){
                    console.log('Verify OTP User Id ', callBackData.userId);
                    this.setState({ 
                        sendVerificationForm: false, 
                        verifyOtpForm: false, 
                        resetPasswordForm: true,
                        userId: callBackData.userId
                     });
                }
                break;
            
            case 'Reset Password':
                if(callBackData && callBackData.resetPassword){
                    this.setState({ 
                        sendVerificationForm: false, 
                        verifyOtpForm: false, 
                        resetPasswordForm: false,
                        userId: callBackData.userId
                    });

                    this.props.history.replace('/');
                }
                break;

            default:
                return null;
        }
    }

    handleBackPress = (callbackScreen) => {
        switch (callbackScreen){
            case 'Send OTP':
                this.props.history.replace('/');
                break;
            
            case 'Verify OTP':
                this.setState({
                    sendVerificationForm: true, 
                    verifyOtpForm: false, 
                    resetPasswordForm: false });
                break;

            case 'Reset Password':
                this.setState({
                    sendVerificationForm: true, 
                    verifyOtpForm: false, 
                    resetPasswordForm: false });
                break;

            default:
                return null;
        }
    }

    render (){
        const { sendVerificationForm, verifyOtpForm, resetPasswordForm, userId } = this.state;
        return(
            <div className='sign-up-container' style={{ backgroundImage: 'url(' + background + ')' }}>
                <div className='incySignUpFormWrapper'>
                    {sendVerificationForm && 
                        <SendOtp
                            handleSubmit = {this.handleSubmit}
                            handleBackPress = {this.handleBackPress}
                        />}
                    {verifyOtpForm && 
                        <VerifyOtp
                            handleSubmit = {this.handleSubmit}
                            handleBackPress = {this.handleBackPress}
                            userId = {userId}
                        />
                    }
                    {resetPasswordForm && 
                        <PasswordReset
                            handleSubmit = {this.handleSubmit}
                            handleBackPress = {this.handleBackPress}
                            userId = {userId}
                        />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return { authData : getAuthenticity(state) };
}

export default connect(mapStateToProps)(ForgotPassword);