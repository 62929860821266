import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemForm from './ItemForm';
import ItemList from './ItemList';
import PriceListModalForm from '../MenuPriceListComponents/PriceListFormContainer';
import PrinterLocationModalForm from '../PrinterLocationForm/PriceListFormContainer';
import ModifireGroupModalForm from '../IngredientModalForm/ModifireGroupModalForm';
import IngredientModalForm from '../IngredientModalForm/IngredientModalForm';
import { fetchItem, updateItem } from '../../actions/items';
import { getItem, getCompanyById } from '../../reducers';
import { Spin } from 'antd';
import { addPriceList } from '../../actions/priceLists';
import { addPrinterLocation } from '../../actions/printerLocations';
import { fetchCompany } from '../../actions/companies';

import { showErrorNotification, showSuccessNotification } from '../../components/Notification';

class ItemFormContainer extends Component {
  state = {
    selectedIngredientItem: undefined,
    itemFilterText: undefined,
    submitting: false,
    visibleItemList: false,
    itemListType: 'ALL',
    visiblePriceListModal: false,
    visiblePrinterLocationModal: false,
    visibleModifireGroupModal: false,
    visibleAddIngredientModal: false,
    selectedGroupModifierItems: [],
    selectedGroupModifierItemKey: [],
    newIngredient: undefined,
    newPrinterLocations: undefined,
    newPriceList: undefined,
    groupModifireIngredients: [],
    priceList: {},
    printerLocation: {},
    loading: false,
    uom: '',
    quantity: '',
    groupName: '',
    groupNameStatus: '',
    priceListName: '',
    priceListNameStatus: '',
    printerLocationName: '',
    printerLocationNameStatus: ''
  }

  componentDidMount = () => {
    const { companyId, itemId } = this.props.match.params;
    this.props.fetchItem(companyId, itemId);
  }

  onCloseItemList = () => this.setState({ visibleItemList: false });

  onItemSearch = value => this.setState({ itemFilterText: value })

  onSelectItem = (itemListType, item) => {
    this.setState({ visibleAddIngredientModal: true, visibleItemList: false, selectedIngredientItem: item });

    // this.setState({ visibleItemList: false, selectedIngredientItem: item  })
  }

  onAddToItem = (itemListType, item) => {
    this.setState({ visibleItemList: false, selectedIngredientItem: item });
  }

  showItemList = itemListType => {
    if (itemListType === 'INGREDIENT') {
      this.setState({ visibleItemList: true, itemListType });
    } else if (itemListType === 'GROUP_MODIFIER') {
      this.setState({ itemListType, visibleModifireGroupModal: true });
    } else if (itemListType === 'GROUP_MODIFIRE_INGREDIENT') {
      this.setState({ itemListType, visibleModifireGroupModal: false, visibleItemList: true });
    }
  };

  showAddPriceList = () => {
    this.setState({ visiblePriceListModal: true });
  }

  onCancelPriceList = () => this.setState({ visiblePriceListModal: false, priceListName: '', priceListNameStatus: '' })

  addPrinterLocation = () => this.setState({ visiblePrinterLocationModal: true })

  onCancelPrinterLocationModal = () => this.setState({ visiblePrinterLocationModal: false })

  onCancelModifireGroupModal = () => this.resetModifireGroupModal()

  resetModifireGroupModal = () => this.setState({ groupName: '', groupNameStatus: '', visibleModifireGroupModal: false, groupModifireIngredients: [] })

  onCancelNewIngredientModal = () => this.setState({ quantity: '', uom: '', visibleAddIngredientModal: false })

  onCreateNewIngredientModal = () => {
    this.setState({ visibleAddIngredientModal: false });
    var ItemObject = this.state.selectedIngredientItem;
    var obj = { name: ItemObject.name, item: ItemObject, uom: this.state.uom, quantity: parseInt(this.state.quantity) };

    if (this.state.itemListType === 'GROUP_MODIFIRE_INGREDIENT') {
      var list = [...this.state.groupModifireIngredients];

      list.push(obj);
      this.setState({ groupModifireIngredients: list, quantity: '', uom: '', visibleModifireGroupModal: true });
    } else if (this.state.itemListType === 'INGREDIENT') {
      this.setState({ newIngredient: obj, quantity: '', uom: '' });
    }
  }

  onCreateGroupModifireModal = () => {
    const { groupName } = this.state;
    var list = [...this.state.groupModifireIngredients];

    if (this.state.groupName.length > 0) {
      if (list.length > 0) {
        var modifireObj = { name: '(G)' + groupName + '-' + list[0].name, commonName: this.state.groupName, hasOptions: true, options: list, quantity: list[0].quantity, uom: list[0].uom };
        this.setState({ visibleModifireGroupModal: false, newIngredient: modifireObj });
        this.resetModifireGroupModal();
      }
    } else {
      this.setState({ groupNameStatus: 'error' });
    }
  }

  onAddNewPriceList = () => {
    const { companyId } = this.props;

    if (this.state.priceListName.length > 0) {
      this.setState({ loading: true });
      this.setState({ visiblePriceListModal: false });
      this.props.addPriceList(companyId, this.state.priceList).then(response => {
        this.setState({ loading: false });
        const { error, priceLists } = response;
        if (error) {
          showErrorNotification('Failed adding Price List', error);
        } else {
          showSuccessNotification('Price List added', 'Price List added successfully.');
          this.setState({ priceListName: '', priceListNameStatus: '', newPriceList: priceLists });
        }
      });
    } else {
      this.setState({ priceListNameStatus: 'error' });
    }
  }

  onAddNewPrinterLocation = () => {
    const { companyId } = this.props;

    if (this.state.printerLocationName.length > 0) {
      this.setState({ loading: true });
      this.setState({ visiblePrinterLocationModal: false });
      this.props.addPrinterLocation(companyId, this.state.printerLocation).then(response => {
        this.setState({ loading: false });
        const { error, printerLocations } = response;
        if (error) {
          showErrorNotification('Failed adding Printer locations', error);
        } else {
          showSuccessNotification('Printer Location added', 'Printer Location added successfully.');
          this.setState({ loading: false, printerLocationName: '', printerLocationNameStatus: '', newPrinterLocations: printerLocations });
        }
      });
    } else {
      this.setState({ printerLocationNameStatus: 'error' });
    }
  }

  resetFrom = () => {
    this.setState(...this.state);
  }

  handleOnChange = (field, value) => {
    const { company } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;
    // const businessId = item.businessId;
    switch (field) {
      case 'priceList': {
        const Obj = { name: value, id: value, businessId: businessId };
        this.setState({ priceList: Obj, priceListName: value, priceListNameStatus: '' });
      }
        break;
      case 'quantity':
        this.setState({ quantity: value });
        break;
      case 'uom':
        this.setState({ uom: value });
        break;
      case 'groupName':
        this.setState({ groupName: value, groupNameStatus: '' });
        break;
      case 'printerLocation': {
        const Obj = { name: value, id: value, businessId: businessId };
        this.setState({ printerLocation: Obj, printerLocationName: value, printerLocationNameStatus: '' });
      }
        break;
      default:
    }
  }

  handleEditableCellChangeSave = (record, value) => {
    if (record.newPrice) {
      // this.onUpdateItemPrice(record)
    } else {
      this.onUpdateItem(record);
    }
  }

  onUpdateItem = (updatedObject) => {
    const { companyId } = this.props.match.params;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;

    const item = {
      name: updatedObject.name,
      code: updatedObject.code,
      servings: updatedObject.servings,
      timeToServe: updatedObject.timeToServe
    };

    this.setState({ submitting: true, loading: true });
    this.props.updateItem(companyId, updatedObject.id, item).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating Item', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchItem(companyId, updatedObject.id).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ loading: false });
        showSuccessNotification('Item updated.', 'Item updated successfully.');
      }
    });
  }

  onUpdateItemPrice = (updatedObject) => {
    const { companyId } = this.props.match.params;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;
    const itemPriceId = this.getItemPriceId(updatedObject.itemPrices);

    if (itemPriceId) {
      this.setState({ submitting: true, loading: true });
      this.props.updateItemPrice(companyId, updatedObject.id, { priceId: itemPriceId, price: parseInt(updatedObject.newPrice) }).then(response => {
        const { error } = response;
        if (error) {
          showErrorNotification('Failed updating Item price', error);
          this.setState({ submitting: false, loading: false });
        } else {
          // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
          this.props.fetchCategory(companyId, updatedObject.categoryId).then(() => {
            this.setState({ loading: false });
          });
          this.setState({ loading: false });
          showSuccessNotification('Item Price updated.', 'Item price updated successfully.');
        }
      });
    } else {
      this.insertNewItemPrice(updatedObject);
    }
  }

  getItemPriceId = itemPricesMap => {
    const { selectedPriceList } = this.state;
    const itemPrices = Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let id;

    itemPrices.forEach(itemPrice => {
      if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) id = itemPrice.id;
    });

    return id;
  }

  render () {
    const {
      visibleItemList,
      visiblePriceListModal,
      itemFilterText,
      itemListType,
      visiblePrinterLocationModal,
      visibleAddIngredientModal,
      selectedIngredientItem,
      visibleModifireGroupModal, loading,
      quantity, uom,
      groupModifireIngredients,
      groupNameStatus,
      groupName
    } = this.state;
    const { companyId, itemId } = this.props;

    return (
      <Spin spinning={loading}>
        <div>
          <ModifireGroupModalForm listofIngredients={groupModifireIngredients} onItemAddClick={this.showItemList} visible={visibleModifireGroupModal} onCancel={this.onCancelModifireGroupModal} onCreate={this.onCreateGroupModifireModal} handleOnChange={this.handleOnChange} item={selectedIngredientItem} groupNameStatus={groupNameStatus} groupName={groupName} />
          <IngredientModalForm quantity={quantity} uom={uom} visible={visibleAddIngredientModal} onCancel={this.onCancelNewIngredientModal} item={selectedIngredientItem} onCreate={this.onCreateNewIngredientModal} handleOnChange={this.handleOnChange} />
          <PriceListModalForm visible={visiblePriceListModal} priceListName={this.state.priceListName} priceListNameStatus={this.state.priceListNameStatus} onCancel={this.onCancelPriceList} onCreate={this.onAddNewPriceList} handleOnChange={this.handleOnChange} />
          <PrinterLocationModalForm visible={visiblePrinterLocationModal} onCancel={this.onCancelPrinterLocationModal} printerLocationName={this.state.printerLocationName} printerLocationNameStatus={this.state.printerLocationNameStatus} onCreate={this.onAddNewPrinterLocation} handleOnChange={this.handleOnChange} />
          <ItemList
            companyId={companyId}
            type={itemListType}
            itemFilterText={itemFilterText}
            visible={visibleItemList}
            onClose={this.onCloseItemList}
            onItemSearch={this.onItemSearch}
            handleItemEditableCellChangeSave={this.handleEditableCellChangeSave}
            onSelectItem={(itemListType, object) => this.onSelectItem(itemListType, object)}
          />
          <ItemForm
            onItemAddClick={this.showItemList}
            itemId={itemId}
            companyId={companyId}
            onAddPriceList={this.showAddPriceList}
            onAddPrinterLocation={this.addPrinterLocation}
            newIngredient={this.state.newIngredient}
            newPrinterLocations={this.state.newPrinterLocations}
            newPriceList={this.state.newPriceList}
            resetForm={() => this.props.resetFrom}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { itemId, companyId } = props.match.params;
  return { itemId, companyId, company: getCompanyById(state, companyId), item: getItem(state, itemId) };
};

export default connect(mapStateToProps, { fetchItem, addPriceList, fetchCompany, addPrinterLocation, updateItem })(ItemFormContainer);
