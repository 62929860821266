import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Input, Form } from 'antd';

export default class EditableCell extends Component {
  state = {
    editing: false,
    error: undefined
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      const key = Object.keys(values)[0];

      handleSave({ ...record, [key]: e.target.value });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(<Input
          ref={node => (this.input = node)} onPressEnter={(e) => {
            this.save(e);
            this.toggleEdit();
          }} onBlur={this.toggleEdit}
        />)}
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{ paddingRight: 10 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render () {
    const {
      Context,
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <Context>{this.renderCell}</Context>
        ) : (
          children
        )}
      </td>
    );
  }
}
