import { combineReducers } from 'redux';
import { saveState, loadState } from '../localStorage';

const authenticity = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      saveState('authenticity', action.authenticity || {});
      return action.authenticity || {};

    case 'LOGOUT':
      saveState('authenticity', null);
      return null;

    case 'GENERATE_OTP':
      const newState = { ...state };
      newState.otp = action.otp;
      return newState;

    case 'SET_LOGGED_IN_CUSTOMER': {
      const { company } = action;
      const authenticity = loadState('authenticity');
      authenticity.user.customer = company;
      saveState('authenticity', authenticity);
      return { ...state, user: { ...state.user, customer: company } };
    }

    case 'MOBILE_VERIFIED': {
      const { verifiedData } = action;
      const authenticity = loadState('authenticity');
      authenticity.user.customer = { 
        ...authenticity.user.customer,
        mobileNumber: verifiedData.verifiedNo,
        mobileVerified: true
      };
        
      saveState('authenticity', authenticity);
      return { ...state, user: { ...state.user, customer: { ...authenticity.user.customer } } };
    }

    default:
      return state;
  }
};

export default combineReducers({
  authenticity
});

export const getAuthenticity = state => loadState('authenticity');

export const getAuthData = state => {
  return state.authenticity;
};

export const getOTP = state => {
  return state.authenticity && state.authenticity.otp;
};
