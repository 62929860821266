import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Row, Popconfirm, Col } from 'antd';
import AdvancedSearchForm from './AdvancedSearchForm';
import ReceiptsTable from './ReceiptsTable';
import { fetchReceiptsFromAdvancedSearch, removeReceipts } from '../../../actions/receipts';
import { getAuthenticity, getReceipts, getCompanyById } from '../../../reducers';
import moment from 'moment';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import SideBar from '../../../components/SideBar';
import { showLoadingMessage } from '../../../helpers/Message';

class ReceiptsContainer extends Component {
  constructor() {
    super();
    this.state = {
      query: {
        receiptId: undefined,
        filterText: undefined,
        branchId: undefined,
        from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
        to: moment(new Date()).endOf('day'),
        status: [],
        page: 0,
        pageSize: 0
      },
      selectedRowKeys: [],
      receiptsLoading: false,
      selectedRows: [],
      receiptIds: undefined,
      onlyOutstanding: false,
      response: undefined
    };
  }

  componentDidMount = () => {
    const { authenticity: { user: { userId } }, fetchReceiptsFromAdvancedSearch } = this.props;
    const { query } = this.state;
    if (this.props.receipts && this.props.receipts.length > 0) {
      this.showLoadingMessage();
      fetchReceiptsFromAdvancedSearch({ ...query, userId: userId }).then(response => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ receiptsLoading: true });
      fetchReceiptsFromAdvancedSearch({ ...query, userId: userId }).then(response => {
        this.setState({ receiptsLoading: false });
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Receipts.');
  }

  handleFetchReceipts = () => {
    const { authenticity: { user: { userId } }, fetchReceiptsFromAdvancedSearch } = this.props;
    const { query } = this.state;

    this.setState({ receiptsLoading: true });
    fetchReceiptsFromAdvancedSearch({ ...query, userId: userId }).then(response => {
      this.setState({ receiptsLoading: false });
    });
  }

  handleDateRangeChange = date => {
    const queryData = {
      ...this.state.query,
      from: date[0].startOf('day'),
      to: date[1].endOf('day')
    };

    this.setState({ query: queryData });
  }

  handleInputTextChange = (e, type) => {
    const value = e.target.value;

    switch (type) {
      case 'receiptId': {
        const queryData = { ...this.state.query, receiptId: value };
        this.setState({ query: queryData });
        break;
      }
      case 'filterText': {
        const queryData = { ...this.state.query, filterText: value };
        this.setState({ query: queryData });
        break;
      }
      default:
        return 'NOT_FOUND';
    }
  }

  handleOnClickSearch = () => {
    this.handleFetchReceipts();
  }

  handleOnClickSelectInvoices = (receiptIds, selectedRowKeys, selectedRows) => this.setState({ receiptIds, selectedRowKeys, selectedRows })

  onChangeOutstandingFilter = (e) => {
    console.log(e.target.checked);
    this.setState({ onlyOutstanding: e.target.checked });
  }

  handleOnClickSelectReceipts = (receiptIds, selectedRowKeys, selectedRows) => this.setState({ receiptIds, selectedRowKeys, selectedRows })

  onDeleteReceipts = (receiptId, customerId) => {
    const { authenticity, removeReceipts } = this.props;
    const deleteRequest = [{
      receiptIds: [receiptId],
      userId: authenticity.user.userId,
      customerId: customerId
    }];
    this.setState({ receiptsLoading: true });

    removeReceipts(deleteRequest).then(response => {
      if (response.error) {
        showErrorNotification('Failed', response.error);
      } else {
        showSuccessNotification('Success', 'Receipt deleted successfully.');
      }
      this.setState({ receiptsLoading: false });
    });
  }

  removeReceipt = () => {
    console.log('removeReceipt');
  }

  responseModelhandleOk = () => this.setState({ response: undefined })

  onDeleteResponse = response => this.setState({ response })

  handleStatusSelectionChange = value => {
    this.setState({ query: { ...this.state.query, status: value } });
  }

  getFilteredReceipts = () => {
    const { receipts } = this.props;
    const { query } = this.state;
    const { receiptId, filterText, from, to } = query;
    return receipts.filter(receipt => {
      return (receiptId ? receipt.number === receiptId : true) &&
        (filterText ? receipt.customerName.toLowerCase().includes(filterText.toLowerCase()) : true) &&
        receipt.status !== 'DELETED' 
    });
  }

  render() {
    const { query, selectedRowKeys, selectedRows, receiptIds, onlyOutstanding, receiptsLoading, response } = this.state;
    const filteredReceipts = this.getFilteredReceipts();
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Row>
              <Card
                title={<h4 style={{ fontWeight: 300 }}>Payments</h4>}
                style={{ margin: 5 }}
                extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
              >
                <AdvancedSearchForm
                  onClickSearch={this.handleOnClickSearch}
                  onInputTextChange={this.handleInputTextChange}
                  onDateRangeChange={this.handleDateRangeChange}
                  onChangeOutstandingFilter={this.onChangeOutstandingFilter}
                  onStatusSelectionChange={this.handleStatusSelectionChange}
                  query={query}
                />
              </Card>
              <Card
                style={{ margin: 5 }}
                extra={selectedRowKeys && selectedRowKeys.length > 1 && <Row>
                  {<Button disabled className='tableButtonSpecial' size='small'>Download as Excel sheet</Button>}
                  {<Button disabled className='tableButtonPrint' size='small' icon='printer' />}
                  {<Popconfirm
                    title='Are you sure ?'
                    okText='DELETE'
                    cancelText='NO'
                    onConfirm={() => console.log('To be implemented')}
                  >
                    <Button className='tableButton' type='danger' size='small' icon='delete' />
                  </Popconfirm>}
                </Row>}
              >
                <ReceiptsTable
                  business={business}
                  receipts={filteredReceipts.length > 0 ? filteredReceipts : []}
                  selectedIds={receiptIds}
                  selectedDataList={selectedRows}
                  showOutstandingInvoices={onlyOutstanding}
                  selectedRowKeys={selectedRowKeys}
                  onClick={this.handleOnClickSelectReceipts}
                  receiptsLoading={receiptsLoading}
                  showTotals
                  enableRowSelection={false}
                  columnsEnabled={['number', 'customerName', 'date', 'amount', 'status', 'depositId', 'depositStatus', 'paymentType']}
                  onDeleteReceipts={this.onDeleteReceipts}
                  onDeleteResponse={this.onDeleteResponse}
                  responseModelhandleOk={this.responseModelhandleOk}
                  response={response}
                  branchId={query && query.branchId}
                />
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return {
    authenticity: getAuthenticity(state),
    company: getCompanyById(state, companyId),
    receipts: getReceipts(state)
  };
};
export default connect(mapStateToProps, { fetchReceiptsFromAdvancedSearch, removeReceipts })(ReceiptsContainer);
