import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Collapse, Icon, Statistic, Button, Modal, Spin } from 'antd';
import { withRouter } from 'react-router';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
// import ReceiptTemplate from '../ReceiptView/receiptTemplate';
import moment from 'moment';

import { fetchList } from '../../../actions/generic';
import { getFilteredReceiptTransactions, getAuthenticity, getCompanyById } from '../../../reducers';
//import { fetchInvoicesForCustomer } from '../../actions/invoices';

const dateTimeFormat = 'YYYY/MM/DD';

class TransactionList extends Component {
  state = {
    loading: false,
    filter: {
      businessIds: [],
      branchId: [],
      orderId: undefined,
    }
  }

  componentDidMount() {
    const filter = {
      businessIds: this.props.businessIds,
      branchIds: this.props.branchIds,
      orderId: this.props.orderId
    }

    this.setState({ loading: true });
    this.props.fetchList('view_order_receipt_transaction', filter).then(response => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { transactions, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { loading } = this.state;
    let formattedPaymentType = '';
    let formattedPaymentDate = '';
    let formattedPaidAmount = '';
    const paymentDetailsList = [];
    if (transactions) {
      var listOftransaction = Array.isArray(transactions) ? transactions : Object.values(transactions);
      for (var i = 0; i < listOftransaction.length; i++) {
        formattedPaymentType = listOftransaction[i].receipt_type && listOftransaction[i].receipt_type !== '' ? listOftransaction[i].receipt_type : '';
        formattedPaidAmount = listOftransaction[i].paid ? ', ' + format(listOftransaction[i].paid, currencyFormat(business)) : format(0.00, currencyFormat(business));
        formattedPaymentDate = listOftransaction[i].time ? ', ' + moment(listOftransaction[i].time).format(dateTimeFormat) : '';

        paymentDetailsList.push(formattedPaymentType + formattedPaidAmount + formattedPaymentDate);
      }
    }

    return (
      <Spin spinning={loading}>
        {paymentDetailsList.length > 0 && <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Payment Details</p>}
        {paymentDetailsList.map(payment =>
          <div style={{ display: 'flex', textAlign: 'left' }}>
            <p style={{ fontSize: 'medium', marginBottom: 15 }}>{payment}</p>
          </div>
        )}
      </Spin>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  const filter = {
    businessIds: ownProps.businessIds,
    branchIds: ownProps.branchIds,
    orderId: ownProps.orderId,
  }
  return ({
    companyId,
    company: getCompanyById(state, companyId),
    transactions: getFilteredReceiptTransactions(state, filter)
  });
};

export default withRouter(connect(mapStateToProps, { fetchList })(TransactionList));

