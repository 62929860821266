import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Select, Input, Tooltip } from 'antd';
import { validateEmail, signup, generateOtpCode, verifyOtpCode, logOut } from '../actions/auth';
import { updateBusiness, updateBasicBusinessData } from '../actions/companies';
import { showErrorNotification, showSuccessNotification, showErrorHTMLNotification } from '../components/Notification';
import { getAuthenticity } from '../reducers';
import '../App.css';
import PhoneInput from 'react-phone-input-2';
import { countryList } from '../helpers/Countries';
import 'react-phone-input-2/lib/style.css';
import Api from '../api/consts';
import background from '../image/work.jpg';
// const { login } = authAction;
// const { clearMenu } = appActions;
const { Option } = Select;

class SignUp extends Component {
  state = {
    // redirectToReferrer: false,
    showEmailForm: false,
    showCredentialsForm: true,
    showVerifyAccountForm: false,
    showBasicDataFrom: false,
    ShowAddVerificationCodeForm: false,

    submitting: false,
    otpSending: false,
    user: undefined,

    email: undefined,
    customerName: undefined,
    password: undefined,
    restaurantName: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    postalCode: undefined,
    phone: undefined,
    code: undefined,
    agentCode: '',

    emailStatus: undefined,
    customerNameStatus: undefined,
    passwordStatus: undefined,
    restaurantNameStatus: undefined,
    addressStatus: undefined,
    cityStatus: undefined,
    countryStatus: undefined,
    postalCodeStatus: undefined,
    phoneStatus: undefined,
    verificationEmail: undefined,
    codeStatus: undefined,
    agentCodeStatus: undefined,

    verifyOption: 1,
    radioValue: 'phone'
  };

  componentDidMount() {
    const { location, authData } = this.props;
    if (location && location.pathname === '/signup') {
      // if (location && location.state) {
      //   const { authenticity, customerName, restaurantName } = location.state
      //   this.setState({
      //     email: authenticity.user.username,
      //     customerName: customerName,
      //     restaurantName: restaurantName
      //   })
      // }
    } else if (location && location.pathname === '/signup/details') {
      this.setState({
        showCredentialsForm: false,
        showBasicDataFrom: true
      });
      if (location && location.state) {
        const { address, country, city, postalCode } = location.state;
        this.setState({
          address: address,
          country: country,
          postalCode: postalCode,
          city: city
        });
      }
    } else if (location && location.pathname === '/signup/verify') {
      this.setState({
        showCredentialsForm: false,
        showVerifyAccountForm: true
      });
    }

    if (authData) {
      const customerName = authData.user && authData.user.customer && authData.user.customer.name;
      const restaurantName = (
        authData.user &&
        authData.user.customer &&
        authData.user.customer.businesses &&
        authData.user.customer.businesses.length > 0
      ) ? authData.user.customer.businesses[0].name : undefined;
      this.setState({ customerName, restaurantName });
    }
  }

  verifyAccount = () => {
    const { updateBusiness } = this.props;
    const { code, phone } = this.state;

    this.setState({ submitting: true });
    this.props.verifyOtpCode({ otp: code, userId: this.props.authData.user.userId, mobileverified: true }).then(response => {
      const { verifiedData, error } = response;

      if (verifiedData) {
        this.setState({ submitting: false });
        showSuccessNotification('Welcome', '');
        this.props.history.push('/dashboard');
      } else {
        this.setState({ submitting: false });
        showErrorNotification('Login Failed.', error);
      }
    });
  }

  validateEmailAddress = () => {
    const { email, restaurantName, password, customerName } = this.state;
    if (this.getValidationStatus('email', email) === 'error' | this.isEmpty('email', email) | this.isEmpty('customerName', customerName) | this.isEmpty('password', password) | this.isEmpty('restaurantName', restaurantName)) { return; }

    const { validateEmail } = this.props;
    this.setState({ submitting: true });
    validateEmail(email).then(response => {
      const { authenticity } = response;
      this.setState({ submitting: false });
      if (authenticity) {
        if (authenticity.isUserExists) {
          showErrorNotification('Failed.', 'This email alredy in used.');
        } else {
          this.registerUser();
          // this.setState({ showEmailForm: false, showCredentialsForm: true })
        }
      } else {
        showErrorNotification('Failed.', 'oops, Something went wrong.');
      }
    });
  }

  registerUser = () => {
    const { signup } = this.props;
    const { email, password, restaurantName, customerName, agentCode } = this.state;

    if (this.isEmpty('email', email) | this.getValidationStatus('email', email) === 'error' | this.isEmpty('password', password)) { return; }

    this.setState({ submitting: true });
    const credentials = {
      username: email,
      password: password,
      restaurantName,
      customerName,
      agentCode
    };
    signup(credentials).then(response => {
      const { authenticity, error, errorObject } = response;

      if (authenticity) {
        this.props.history.replace(
          {
            pathname: '/signup/details',
            search: null
          });
      } else {
        this.setState({ submitting: false });
        if (error) {
          if (errorObject && errorObject.errorCode === 4010) {
            showErrorHTMLNotification(error, <p>Please contact us if need any help.<br />
              <a href="https://www.incylabs.com/contact-us" target='_blank'>https://www.incylabs.com/contact-us</a></p>);
          } else {
            showErrorNotification('Failed.', error);
          }
        }
      }
    });
  }

  createBusiness = () => {
    const { location, authData, updateBasicBusinessData } = this.props;
    const { user } = authData;
    const { customerName, restaurantName, address, phone, city, postalCode, country } = this.state;

    if (this.isEmpty('customerName', customerName) | this.isEmpty('restaurantName', restaurantName) | this.isEmpty('address', address) | this.isEmpty('city', city) | this.isEmpty('postalCode', postalCode) | this.isEmpty('country', country)) { return; }

    this.setState({ submitting: true });
    const data = {
      customer: { name: customerName },
      business: {
        name: restaurantName,
        address: address,
        city: city,
        postalCode: postalCode,
        country: country,
        telephone: phone
      }
    };

    updateBasicBusinessData(user.userId, data).then(response => {
      const { company } = response;

      this.setState({ submitting: false });
      if (company) {
        // showSuccessNotification('Success', '');
        // this.setState({ showBasicDataFrom: false, showVerifyAccountForm: true })
        this.props.history.replace(
          {
            pathname: '/signup/verify',
            search: null,
            state: { ...location.state, address, postalCode, city, country }
          });
      } else {
        showErrorNotification('Failed.', 'Oops, Something went wrong.');
      }
    });
  }

  updateBusinessDetails = () => {
    const { location } = this.props;
    const { updateBusiness } = this.props;
    const { phone, verificationEmail } = this.state;
    const { authenticity } = location.state;

    const data = {
      telephone: phone
    };

    if (this.isEmpty('phone', phone) && this.isEmpty('verificationEmail', verificationEmail)) { return; }

    this.setState({ submitting: true });
    updateBusiness(authenticity.user.userId, data).then(response => {
      const { company } = response;
      this.setState({ submitting: false });
      if (company) {
        // showSuccessNotification('Success', '');
        this.setState({ showVerifyAccountForm: false, ShowAddVerificationCodeForm: true });
        // this.props.history.replace(
        //   {
        //     pathname: "/signup/verify",
        //     search: null,
        //     state: { authenticity: authenticity, restaurantName : restaurantName}
        //   });
      } else {
        showErrorNotification('Failed.', 'Oops, Something went wrong.');
      }
    });
  }

  isEmpty = (name, value) => {
    if (value === undefined || value.length === 0) {
      switch (name) {
        case 'email':
          this.setState({ emailStatus: 'error' });
          break;
        case 'customerName':
          this.setState({ customerNameStatus: 'error' });
          break;
        case 'password':
          this.setState({ passwordStatus: 'error' });
          break;
        case 'restaurantName':
          this.setState({ restaurantNameStatus: 'error' });
          break;
        case 'address':
          this.setState({ addressStatus: 'error' });
          break;
        case 'city':
          this.setState({ cityStatus: 'error' });
          break;
        case 'country':
          this.setState({ countryStatus: 'error' });
          break;
        case 'postalCode':
          this.setState({ postalCodeStatus: 'error' });
          break;
        case 'phone':
          this.setState({ phoneStatus: 'error' });
          break;
        case 'code':
          this.setState({ codeStatus: 'error' });
          break;
        case 'verificationEmail':
          this.setState({ codeStatus: 'error' });
          break;
        case 'agentCode':
          this.setState({ agentCodeStatus: 'error' });
          break;
        default:
      }

      return true;
    } else {
      return false;
    }
  }

  renderEmailForm = () => {
    return (
      <div className='isoSignUpForm'>
        <div className='isoInputWrapper'>
          <Input size='middle' placeholder='Email' />
        </div>
        <div className='isoInputWrapper'>
          <Button className='primary-button' type='primary' size='middle' onClick={() => this.registerUser()} loading={this.state.submitting}>
            Sign Up
          </Button>
        </div>
      </div>
    );
  }

  renderCredentialsForm = () => {
    const { email, customerName, password, restaurantName, agentCode } = this.state;
    return (
      <div className='isoSignUpForm'>
        <h2 className='signUp-form-title'>Account Information <p className='signUp-form-description'>to log you in</p></h2>
        <Form className='signUp-form'>
          <Form.Item colon={false} validateStatus={this.state.customerNameStatus} label={<span style={{ visibility: this.showLabel(customerName) }} className='incyInputLabel'>Customer Name</span>}>
            <Input size='default' autoFocus placeholder='Customer Name' value={customerName} onChange={e => this.handleOnChange('customerName', e.target.value)} />
          </Form.Item>
          <Form.Item colon={false} validateStatus={this.state.emailStatus} label={<span style={{ visibility: this.showLabel(email) }} className='incyInputLabel'>Email</span>}>
            <Input size='default' placeholder='Email' value={email} onChange={e => this.handleOnChange('email', e.target.value)} />
          </Form.Item>
          <Form.Item colon={false} validateStatus={this.state.passwordStatus} label={<span style={{ visibility: this.showLabel(password) }} className='incyInputLabel'>Password</span>}>
            <Input size='default' type='password' placeholder='Password' value={password} onChange={e => this.handleOnChange('password', e.target.value)} />
          </Form.Item>
          <Form.Item colon={false} validateStatus={this.state.restaurantNameStatus} label={<span style={{ visibility: this.showLabel(restaurantName) }} className='incyInputLabel'>Restaurant Name</span>}>
            <Input size='default' placeholder='Business Name' value={restaurantName} onChange={e => this.handleOnChange('restaurantName', e.target.value)} />
          </Form.Item>
          <Form.Item colon={false} validateStatus={this.state.agentCodeStatus} label={<span style={{ visibility: this.showLabel(agentCode) }} className='incyInputLabel'>Agent Code</span>}>
            {/* TODO : We will make agent code mandotory as a temporary solution <Tooltip placement='topRight' title='This code will provide by your agent. If you do not have this code please proceed. You can add this later.'>
              <Input size='default' placeholder='Agent Code(Optional)' value={agentCode} onChange={e => this.handleOnChange('agentCode', e.target.value)} />
            </Tooltip> */}
            <Tooltip placement='topRight' title={<p>This code will provide by your agent. Please contact us if need any help.<br />
              <a href="https://www.incylabs.com/contact-us" target='_blank'>https://www.incylabs.com/contact-us</a></p>}>
              <Input size='default' placeholder='Agent Code (Optional)' value={agentCode} onChange={e => this.handleOnChange('agentCode', e.target.value)} />
            </Tooltip>
          </Form.Item>
          <div className='isoInputWrapper' style={{ marginTop: 30 }}>
            <Button block className='actionBtn primary-button' type='primary' size='large' onClick={() => this.registerUser()} loading={this.state.submitting}>
              Register
            </Button>
          </div>
          <Form.Item colon={false} style={{ marginTop: 15 }}>
            <span>Already a member? <Button style={{ padding: 0 }} type='link' onClick={() => this.props.history.replace({ pathname: '/' })}>Log In</Button></span>
          </Form.Item>
        </Form>
      </div>
    );
  }

  renderBasicDataForm = () => {
    const { customerName, restaurantName, address, city, country, postalCode } = this.state;

    return (
      <div className='isoSignUpForm'>
        <h2 className='signUp-form-title'>Your Address<p className='signUp-form-description'>to show your customers and for your payment receipts</p></h2>
        <Form className='signUp-form'>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item colon={false} validateStatus={this.state.customerNameStatus} label={<span style={{ visibility: this.showLabel(customerName) }} className='incyInputLabel'>Customer Name</span>}>
                <Input size='default' autoFocus placeholder='Customer Name' value={customerName} onChange={e => this.handleOnChange('customerName', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item colon={false} validateStatus={this.state.restaurantNameStatus} label={<span style={{ visibility: this.showLabel(restaurantName) }} className='incyInputLabel'>Restaurant Name</span>}>
                <Input size='default' placeholder='Business Name' value={restaurantName} onChange={e => this.handleOnChange('restaurantName', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item colon={false} validateStatus={this.state.addressStatus} label={<span style={{ visibility: this.showLabel(address) }} className='incyInputLabel'>Address</span>}>
                <Input size='default' autoFocus placeholder='Address' value={address} onChange={e => this.handleOnChange('address', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item colon={false} validateStatus={this.state.cityStatus} label={<span style={{ visibility: this.showLabel(city) }} className='incyInputLabel'>City</span>}>
                <Input size='default' placeholder='City' value={city} onChange={e => this.handleOnChange('city', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item colon={false} validateStatus={this.state.countryStatus} v label={<span style={{ visibility: (this.showLabel(country) || this.showLabel(postalCode)) }} className='incyInputLabel'>Country</span>}>
                {/* <Input size="large" placeholder="Country" value={country} onChange={e => this.handleOnChange('country', e.target.value)} /> */}
                <Select
                  showSearch size='default' style={{ fontSize: 13 }} placeholder='Select Country' value={country !== undefined && country !== '' ? country : undefined} onChange={value => this.handleOnChange('country', value)}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSearch={this.onSearch}
                  showAction={['focus', 'click']}
                >
                  {countryList.map(country => <Option key={country} value={country}>{country}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item colon={false} validateStatus={this.state.postalCodeStatus} label={<span style={{ visibility: (this.showLabel(postalCode) || this.showLabel(country)) }} className='incyInputLabel'>Postal Code</span>}>
                <Input size='default' placeholder='Postal Code' value={postalCode} onChange={e => this.handleOnChange('postalCode', e.target.value)} />
              </Form.Item>
            </Col>
            <div className='isoInputWrapper'>
              <Button className='actionBtn primary-button' style={{ width: '100%' }} type='primary' size='default' onClick={() => this.createBusiness()} loading={this.state.submitting}>
                Save Restaurant
              </Button>
            </div>
            <Form.Item colon={false}>
              <span><Button style={{ padding: 0 }} type='link' onClick={() => this.props.logOut()}>Use another account</Button></span>
            </Form.Item>
          </Row>
          {/* <span className={'incySignUpBackButton'} onClick={() => this.props.history.replace({ pathname: "/" })}>Back</span> */}
          {/* <span className={'incySignUpBackButton'} onClick={() => this.props.history.replace("/signup")}>Back</span> */}
        </Form>
      </div>
    );
  }

  verifyOptionOnChange = e => {
    this.setState({
      verifyOption: e.target.value,
      phoneStatus: undefined
    });
  };

  renderVerifyAccountForm = () => {
    const { phone, phoneStatus } = this.state;
    const validateMessages = {
      // eslint-disable-next-line
      required: '${label} is required!',
      len: 12
    };

    return (
      <div className='isoSignUpForm'>
        <h2 className='signUp-form-title'>Verify Your Account<p className='signUp-form-description'>for better security</p></h2>
        <Form validateMessages={validateMessages}>
          <p style={{ textAlign: 'center', fontSize: 14, fontWeight: '600', marginBottom: 7 }}>Ex: +94 77 123 4567</p>
          <Form.Item validateStatus={this.state.phoneStatus} className='incyInputLabel' rules={[{ required: true }]}>
            <div className='isoInputWrapper isoLeftRightComponent'>
              <PhoneInput
                inputStyle={{ width: 'inherit', borderColor: phoneStatus && 'red' }}
                buttonStyle={{ borderColor: phoneStatus && 'red' }}
                country='lk'
                countryCodeEditable={false}
                value={phone}
                onChange={phone => this.handleOnChange('phone', phone)}
              />
            </div>
          </Form.Item>
          <Form.Item>
            <Button className='btn-orange primary-button' type='primary' size='large' onClick={() => this.handleVerificationCodeRequest()} loading={this.state.otpSending}>
              Verify Account
            </Button>
          </Form.Item>
          <Form.Item colon={false}>
            <span><Button style={{ padding: 0 }} type='link' onClick={() => this.props.logOut()}>Use another account</Button></span>

          </Form.Item>

        </Form>
      </div>
    );
  }

  renderAddVerificationCodeForm = () => {
    const { code, otpSending, submitting, phone = '' } = this.state;
    const hiddenNumber = phone && phone.replace(/\d(?=\d{4})/g, '*');
    return (
      <div className='isoSignUpForm'>
        <h2 className='signUp-form-title'>Verification Code<p className='signUp-form-description'>received in selected option</p></h2>
        <Form className='signUp-form'>
          <Form.Item colon={false} label={<span style={{ visibility: this.showLabel(code) }} className='incyInputLabel'>Verification Code</span>}>
            <Input size='default' autoFocus placeholder='Enter Code' value={code} onChange={e => this.handleOnChange('code', e.target.value)} />
          </Form.Item>
          <div className='isoInputWrapper' style={{ lineHeight: '40px', width: '100%' }}>
            <Button
              block
              disabled={otpSending}
              className='actionBtn primary-button'
              type='primary'
              size='default'
              loading={submitting}
              onClick={() => this.verifyAccount()}
            >Verifiy
            </Button>
          </div>
          <div style={{ marginTop: 15 }}>
            <div style={{ textAlign: 'center' }}>
              <span>Did not receive?
                <Button
                  disabled={submitting}
                  style={{ padding: 2 }}
                  type='link'
                  onClick={() => this.handleVerificationCodeRequest()}
                >Resend
                </Button>
              </span>
            </div>
            <div className='isoInputWrapper' style={{ lineHeight: '40px', textAlign: 'center' }}>
              <span>Do not have to access <span style={{ fontWeight: 'bold' }}>{hiddenNumber}</span> ?
                <br />
                <Button
                  type='link'
                  disabled={this.state.sendingOTPRequest || this.state.submitting}
                  size='default'
                  onClick={() => this.setState({ ShowAddVerificationCodeForm: false, showVerifyAccountForm: true })}
                >Change phone number
                </Button>
              </span>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  onChangeRadioButton = e => {
    this.setState({ radioValue: e.target.value });
    if (e.target.value === 'phone') {
      this.setState({ verificationEmail: '', verificationEmailStatus: '' });
    } else {
      this.setState({ phone: '', phoneStatus: '' });
    }
  };

  onSearch = (value) => {
    this.setState({ country: value, countryStatus: this.getValidationStatus('country', value) });
  };

  handleOnChange = (type, value) => {
    switch (type) {
      case 'email':
        this.setState({ email: value, emailStatus: this.getValidationStatus(type, value) });
        break;
      case 'customerName':
        this.setState({ customerName: value, customerNameStatus: this.getValidationStatus(type, value) });
        break;
      case 'password':
        this.setState({ password: value, passwordStatus: this.getValidationStatus(type, value) });
        break;
      case 'restaurantName':
        this.setState({ restaurantName: value, restaurantNameStatus: this.getValidationStatus(type, value) });
        break;
      case 'address':
        this.setState({ address: value, addressStatus: this.getValidationStatus(type, value) });
        break;
      case 'city':
        this.setState({ city: value, cityStatus: this.getValidationStatus(type, value) });
        break;
      case 'country':
        this.setState({ country: value, countryStatus: this.getValidationStatus(type, value) });
        break;
      case 'postalCode':
        this.setState({ postalCode: value, postalCodeStatus: this.getValidationStatus(type, value) });
        break;
      case 'phone':
        this.setState({ phone: value, phoneStatus: undefined });
        break;
      case 'verificationEmail':
        this.setState({ verificationEmail: value, verificationEmailStatus: this.getValidationStatus(type, value) });
        break;
      case 'code':
        this.setState({ code: value, codeStatus: this.getValidationStatus(type, value) });
        break;
      case 'agentCode':
        this.setState({ agentCode: value, agentCodeStatus: undefined });
        break;
      default:
    }
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'email':
        return this.isValidEmail(value) ? 'success' : 'error';
      case 'customerName':
        return this.isValidText(value) ? 'success' : 'error';
      case 'password':
        return this.isValidText(value) ? 'success' : 'error';
      case 'restaurantName':
        return this.isValidText(value) ? 'success' : 'error';
      case 'address':
        return this.isValidText(value) ? 'success' : 'error';
      case 'city':
        return this.isValidText(value) ? 'success' : 'error';
      case 'country':
        return this.isValidText(value) ? 'success' : 'error';
      case 'postalCode':
        return this.isValidText(value) ? 'success' : 'error';
      case 'phone':
        return this.isValidPhone(value) ? 'success' : 'error';
      case 'verificationEmail':
        return this.isValidEmail(value) ? 'success' : 'error';
      case 'code':
        return this.isValidText(value) ? 'success' : 'error';
      case 'agentCode':
        return this.isValidText(value) ? 'success' : 'error';

      default:
        return null;
    }
  }

  showLabel = value => (value && value.length) > 0 ? 'visible' : 'hidden';

  isValidText = text => text && text.length > 0;
  isValidPhone = text => {
    var phoneValidate = true;

    let phoneText = text && text.substring(0, 3) === '940' ? '94' + text.substring(3) : text;
    
    if (phoneText && phoneText.length > 10) {
      if (phoneText.includes('94') && phoneText.length !== 11) {
        phoneValidate = false;
      } else {
        phoneValidate = true;
      }
    } else {
      phoneValidate = false;
    }
    return phoneValidate;
  }

  isValidEmail = email => {
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && email.length > 0 && emailExpression.test(email.toLowerCase());
  }

  handleVerificationCodeRequest = () => {
    this.handleVerificationCodeSMSRequest();
  }

  handleVerificationCodeSMSRequest = () => {
    let { phone } = this.state;
    const { authData } = this.props;

    if (!this.validateVerificationCodeFormForSMS()) return;
    phone = phone.replace(/ /g, '');
    phone = phone.replace('+', '');

    this.setState({ otpSending: true });
    this.props.generateOtpCode({ username: authData.user.username, phoneNumber: phone, customerId: authData.user.userId }).then(response => {
      if (response.error) {
        this.setState({ otpSending: false });
        showErrorNotification('Request failed.', 'Something went wrong when sending sms. Please try again later or contact fooder support center.');
      } else {
        this.setState({ otpSending: false, ShowAddVerificationCodeForm: true, showVerifyAccountForm: false });
        showSuccessNotification('Success', 'Verification code sent.');
      }
    }).catch(error => {
      this.setState({ otpSending: false });
      return { error };
    });
  }

  sendRequest = (requestData) => {
    const { url, method, headers } = Api.notification;
    const data = { method, headers, body: requestData };

    return fetch(url(), data)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'ok') {
          this.setState({ submitting: false, ShowAddVerificationCodeForm: true, showVerifyAccountForm: false });
          showSuccessNotification('Success', 'Verification code sent.');
        } else {
          this.setState({ submitting: false });
          showErrorNotification('Request failed.', responseJson.body);
        }
      }).catch(error => {
        this.setState({ submitting: false });
        return { error };
      });
  }

  validateVerificationCodeFormForSMS = () => {
    const { phone } = this.state;
    const validForm = this.isValidPhone(phone);
    if (!validForm) {
      this.setState({
        phoneStatus: this.getValidationStatus('phone', phone)
      });
    }
    return validForm;
  }

  handleVerificationCodeEmailRequest = () => {
    const { authData: { user } } = this.props;

    if (!this.validateVerificationCodeFormForEmail()) return;

    this.setState({ submitting: true });
    this.props.generateOtpCode({ username: user.username }).then(response => {
      if (response.otp) {
        const data = { otp: response.otp.newOtp.code, email: user.username };

        this.sendEmail(data);
      } else {
        this.setState({ submitting: false });
        showErrorNotification('Request failed.', response.error);
      }
    }).catch(error => {
      this.setState({ submitting: false });
      return { error };
    });
  }

  validateVerificationCodeFormForEmail = () => {
    const { authData: { user } } = this.props;

    const validForm = this.isValidEmail(user.username);
    if (!validForm) {
      this.setState({
        verificationEmailStatus: this.getValidationStatus('verificationEmail', user.username)
      });
    }
    return validForm;
  }

  sendEmail = data => {
    const url = 'https://incylabs.com/api/admin/signup';
    // const url = 'http://localhost:3002/api/incytute/idm/forgotPassword123';
    const method = 'POST';
    const headers = {};
    const body = JSON.stringify(data);
    const emailData = { method, headers, body };

    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    headers.origin = 'https://admin.incypos.com';
    emailData.headers = headers;

    fetch(url, emailData)
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ loading: false });
        if (responseJson && responseJson.accepted && responseJson.accepted.length > 0) {
          this.setState({ submitting: false, ShowAddVerificationCodeForm: true, showVerifyAccountForm: false });
          showSuccessNotification('Success', 'Verification code sent.');
        } else {
          this.setState({ submitting: false });
          showErrorNotification('Request failed.', responseJson.body);
        }
      }).catch(error => {
        this.setState({ submitting: false });
        return { error };
      });
  }

  render() {
    const { showEmailForm, showCredentialsForm, showBasicDataFrom, showVerifyAccountForm, ShowAddVerificationCodeForm } = this.state;
    return (
      <div className='sign-up-container' style={{ backgroundImage: 'url(' + background + ')' }}>
        <div className='incySignUpFormWrapper'>
          <div className='text-center'>
            {showEmailForm && this.renderEmailForm()}
            {showCredentialsForm && this.renderCredentialsForm()}
            {showBasicDataFrom && this.renderBasicDataForm()}
            {showVerifyAccountForm && this.renderVerifyAccountForm()}
            {ShowAddVerificationCodeForm && this.renderAddVerificationCodeForm()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  // const { categoryId } = props

  return { authData: getAuthenticity(state) };
};
export default connect(mapStateToProps, { validateEmail, signup, updateBusiness, generateOtpCode, verifyOtpCode, updateBasicBusinessData, logOut })(SignUp);
