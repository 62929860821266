import Api from './consts';
import fetch from './commonFetch';

export const getPrinterLocations = companyId => {
  const { url, method } = Api.printerLocations.getAll;
  return fetch(url(companyId), { method }, 'printerLocations');
};

export const insertPrinterLocation = (companyId, printerLocation) => {
  const { url, method } = Api.printerLocations.add;
  const body = JSON.stringify(printerLocation);
  return fetch(url(companyId), { method, body }, 'printerLocations');
};
