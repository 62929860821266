import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ANALYTICS': {
      const newState = {...state};
      action.analytics && action.analytics.length > 0 && action.analytics.forEach(analytic => { newState[analytic.id] = analytic; });
      return newState;
    }

    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ANALYTICS': {
      const newState = { ...state };
      const key = Object.values(action.query).join('');
      const idsForFilter = [];
      action.analytics && action.analytics.forEach(analytic => {
        idsForFilter.push(analytic.id);
      });
      newState[key] = { ids: idsForFilter};
      return newState;
    }
    default:
      return state;
  }
};

const analytics = combineReducers({
  byId,
  byFilter
});

export default analytics;

export const getAnalytics = ( state, filter ) => {
  // console.log("order dash board filter", filter);
  const key = Object.values(filter).join('');
  const filterdAnalyticsIds = state.byFilter[key] ? state.byFilter[key].ids : [];

  return filterdAnalyticsIds.map(id => state.byId[id]).filter(analytic => !!analytic);
};


