import React, { Component } from 'react';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';

const { TextArea } = Input;

class TransferNoteForm extends Component {
  render() {
    const {
      submitting,
      onConfirmTransferNote,
      transferNote,
      handleOnChange,
      referenceNumberStatus,
      amountStatus
    } = this.props;
    const { remarksList } = transferNote;
    const confirmedRemarks = remarksList && remarksList.length > 0 ? remarksList.filter(remarks => remarks.type === 'CONFIRMATION') : [];

    const { amount, transferReferenceNumber, status } = transferNote;
    const { business } = this.props;
    return (
      <Spin spinning={submitting}>
        <Form layout='vertical'>
          <Form.Item label='Amount' required validateStatus={amountStatus} help={amountStatus === 'error' && 'Amount must be greater than 0.'}>
            <div style={{ fontWeight: '-moz-initial', marginLeft: 10, color: 'rgba(0, 0, 0, 0.65)' }}>{format(amount, currencyFormat(business))}</div>
          </Form.Item>
          <Form.Item label='Transfer Reference No' required validateStatus={referenceNumberStatus} help={referenceNumberStatus === 'error' && 'Transfer reference number is mandatory.'}>
            <div>
              <Input value={transferReferenceNumber} onChange={(event) => handleOnChange('transferReferenceNumber', event.target.value)} />
            </div>
          </Form.Item>
          <Form.Item label='Remarks'>
            <div>
              <TextArea disabled rows={5} value={confirmedRemarks[0] && confirmedRemarks[0].message ? confirmedRemarks[0].message : ''} onChange={(event) => handleOnChange('remarks', event.target.value)} />
            </div>
          </Form.Item>
          <div>
            <Row gutter={10}>
              <Col lg={24} md={24} sm={12} xs={12} style={{ marginBottom: 10 }}>
                {status === 'PENDING' && <Button type='primary' size='small' style={{ width: '100%' }} onClick={onConfirmTransferNote}>Confirm</Button>}
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
    );
  }
}

export default TransferNoteForm;
