import React from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { jsonToFileInventory } from '../../../api/resources';
import { downloadInventoryItems } from '../../../actions/Inventory';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import { FaFileExport, FaUpload, FiDownload, FiUpload } from 'react-icons/all';

class CsvDownloaderInventoryItems extends React.Component {

  state = {
    submitting: false
  }

  handleExportData = () => {
    const { companyId, company, selectedBranchId } = this.props;
    console.log(selectedBranchId)
    const business = company && company.businesses && company.businesses['0'];
    const businessName = business && business.name ? business.name : "";
    this.setState({ submitting: true });
    const data = { branchIds: [selectedBranchId] }
    console.log(data)
    this.props.downloadInventoryItems(companyId, data).then(response => {
      const { error, records } = response;
      if (error) {
        showErrorNotification('error', 'Export Failed. ' + error);
        this.setState({ submitting: false });
      } else {
        if (records) {
          this.downloadEmployeeData(records, businessName);
        } else {
          this.setState({ submitting: false });
        }
      }
    });
  }

  downloadEmployeeData = (json, businessName) => {
    const fileName = "Inventory Items -" + businessName + ".csv";
    jsonToFileInventory(JSON.stringify(json)).then((response) => {
      console.log(response)
      this.setState({ submitting: false });
      if (response.error) {
        showErrorNotification('error', 'Export Failed. ' + response.error);
      } else {
        const { responseData } = response;
        console.log(responseData);

        const blobUrl = URL.createObjectURL(responseData);
        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.target = "_blank";
        anchor.download = fileName;
        anchor.click();
        URL.revokeObjectURL(blobUrl);
      }
    });
  }
  render() {
    return (
      <Button block loading={this.state.submitting}
        onClick={this.handleExportData}
        style={{ marginRight: 5, marginLeft: 5 }}>
        Export Excel
      </Button>

    )
  }
}

export default connect(null, { downloadInventoryItems })(CsvDownloaderInventoryItems);