import React, { useEffect, useState } from 'react'
import { Table, Spin } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import './salesReports.css';

const footerKey = '_total_sum_footer_'

const ViewTable = (props) => {

  const { tableColumns, tableData, filterGroup, title, showHeader, loading = false } = props
  const getSum = (values, isFloat) => {
    if (values.length === 0)
      return isFloat ? parseFloat(0).toFixed(2) : 0
    let sum = values.reduce((a, b) => parseFloat(a) + parseFloat(b))
    return isFloat ? parseFloat(sum).toFixed(2) : parseFloat(sum)
  }

  const getFooterRow = (cols) => {
    let emptyRowSummary = {}
    cols.forEach((col, index) => {
      if (index === 0) {
        emptyRowSummary[col.dataIndex] = col.indexKey ?
          `Total for the above ${tableData.values.length} ${col.indexKey}${tableData.values.length === 1 ? '' : 's'}`
          : 'Total'
      } else {
        emptyRowSummary[col.dataIndex] = col.summable ?
          getSum(
            tableData.values.map(d => d[col.dataIndex]),
            !col.noFloat
          ) : ''
      }
    })
    emptyRowSummary['id'] = footerKey
    emptyRowSummary['key'] = footerKey

    return emptyRowSummary
  }

  useEffect(() => {
  })

  return (
    <Spin spinning={loading} >
      <Table
        columns={tableColumns}
        dataSource={[
          ...tableData.values.map(o => ({ ...o, key: o.id })),
          { ...getFooterRow(tableColumns) }
        ]}
        title={() => title || filterGroup !== 'N/A' && `${filterGroup.charAt(0).toUpperCase() + filterGroup.slice(1)}:
        ${filterGroup === 'date' ? moment(new Date(tableData.key)).format('YYYY-MM-DD') : tableData.key ? tableData.key : 'N/A'}`
        }
        pagination={false}
        showHeader={showHeader}
        className='table_reports'
        rowClassName={(record, index) => {
          if (record.id === footerKey) {
            return 'table_footer'
          }
        }}
        style={{
          marginBottom: '1.5rem',
        }}
      // bordered
      />
    </Spin>
  )
}

export default ViewTable