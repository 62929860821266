import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import CompanyBasicData from './CompanyBasicData';
import CompanyDataTabView from './CompanyDataTabView';
import withRouter from 'react-router/es/withRouter';

// import {fetchReceiptsByCustomerId} from "../../actions/receipts";
// import {fetchWaveOffReceiptsByCustomerId} from "../../actions/waveoff";
import { getCompanyById } from '../../reducers/index';
import SideBar from '../../components/SideBar';

class ViewCompanyInfo extends Component {
  render () {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];

    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Card title={<h1 style={{ fontWeight: 300 }}>{business && business.name ? business.name : ''}</h1>} style={{ margin: 5 }}>
              <CompanyBasicData />
            </Card>
            <Card style={{ margin: 5 }}>
              {business && <CompanyDataTabView />}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { companyId } = props.match.params;
  return ({ company: getCompanyById(state, companyId) });
};

export default withRouter(connect(mapStateToProps, null)(ViewCompanyInfo));
