import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Input, Row, Spin, Checkbox, Card, Icon, List, Modal, Skeleton, ConfigProvider, PageHeader, Button } from 'antd';
import { fetchItem, fetchItems, updateItem, addItem, addItemIngredient, addItemGroupModifire, addItemPriceList, addItemPrinterLocation, removeItemPrinterLocation, removeItemPrice, removeIngredient } from '../../actions/items';
import { fetchPrinterLocations } from '../../actions/printerLocations';
import { fetchPriceLists } from '../../actions/priceLists';
import { getPrinterLocations, getPriceLists, getItem, getCompanyById } from '../../reducers';
import { fetchCompany } from '../../actions/companies';
import { format } from 'currency-formatter';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import withRouter from 'react-router/es/withRouter';
import { currencyFormat, currencyFormatWithoutSymbol } from '../../helpers/common';

const { TextArea } = Input;


const defaultItem = {
  name: undefined,
  code: undefined,
  servings: undefined,
  timeToServe: undefined,
  itemPrices: [],
  printerLocations: [],
  isIngredient: false,
  isModifier: false,
  ingredients: [],
  recipe: '',
  tips: '',
  barcode: '',
  price: 0,
  isMeal: false,
  isPriceDialogVisibile: false,
  selectedPriceObject: {},
  nameStatus: undefined,
  codeStatus: undefined,
  barcodeStatus: undefined,
  priceStatus: '',
  availableQty2: undefined,
  displayInOnlineMenu2: true
};

class ItemForm extends Component {
  state = {
    ...defaultItem,
    loading: undefined,
    submitting: false
  }

  onChange = (checkedValues) => {

  }

  componentDidMount() {
    const { companyId, itemId } = this.props;

    this.props.fetchCompany(companyId);
    if (itemId !== 'new') {
      this.setState({ loading: true });
      this.props.fetchItem(companyId, itemId).then(response => {
        this.setItemDetails(response);
        this.setState({ loading: false });
      });
    }
    this.props.fetchPrinterLocations(companyId);
    this.props.fetchPriceLists(companyId);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { companyId } = this.props;

    if (nextProps !== this.props)
    // this.setItemDetails(nextProps);

    // console.log(nextProps.newIngredient)

    {
      if (nextProps.newIngredient !== this.props.newIngredient) {
        var ingredientsList = this.state.ingredients;
        if (ingredientsList.indexOf(nextProps.newIngredient) === -1) { ingredientsList.push(nextProps.newIngredient); }

        this.setState({
          ingredients: ingredientsList
        });
      }
    }

    if (nextProps.newPrinterLocations !== this.props.newPrinterLocations) {
      this.props.fetchPrinterLocations(companyId);
      // this.props.fetchPriceLists(companyId);
    }

    if (nextProps.newPriceList !== this.props.newPriceList) {
      this.props.fetchPriceLists(companyId);
    }
  }

  setItemDetails = nextProps => {
    const { item } = nextProps;

    item && this.setState({
      name: item.name,
      code: item.code,
      servings: item.servings,
      timeToServe: item.timeToServe,
      itemPrices: item.itemPrices ? Object.values(item.itemPrices) : [],
      printerLocations: item.printerLocations ? Object.values(item.printerLocations) : [],
      isIngredient: item.isIngredient,
      isModifier: item.isModifier,
      ingredients: item.ingredients ? Object.values(item.ingredients) : [],
      recipe: item.recipe,
      tips: item.tips,
      barcode: item.barcode,
      isMeal: item.isMeal,
      imageSrc: item.imageSrc,
      availableQty2: item.availableQty2,
      displayInOnlineMenu2: item.displayInOnlineMenu2
    });
  }

  setIngredients = (ingredients, newIngredient) => {
    if (ingredients) {
      if (newIngredient) {
        var newObj = { ...ingredients, newIngredient };
        return newObj;
      } else {
        return Object.values(ingredients);
      }
    } else {
      if (newIngredient) {
        return Object.values(newIngredient);
      } else {
        return [];
      }
    }
  }

  handleOnChange = (field, value) => {
    const { itemId } = this.props;
    switch (field) {
      case 'name':
        this.setState({ name: value, nameStatus: this.getValidationStatus(field, value) });
        break;
      case 'code':
        this.setState({ code: value, codeStatus: this.getValidationStatus(field, value) });
        break;
      case 'barcode':
        this.setState({ barcode: value, barcodeStatus: this.getValidationStatus(field, value) });
        break;
      case 'locations':
        const existingLocations = [...this.state.printerLocations];
        if (value.checked) {
          existingLocations.push(value.value);
        } else {
          existingLocations.find(location => {
            if (location && location.id === value.value.id) {
              if (itemId !== 'new') { this.handleRemovePrinterLocation(value.value.id); }
              delete existingLocations[existingLocations.indexOf(location)];
            }
            return null;
          });
        }

        this.setState({ printerLocations: existingLocations });
        break;
      case 'priceList':
        const existingPriceLists = [...this.state.itemPrices];
        if (value.checked) {
          this.setState({ isPriceDialogVisibile: true, selectedPriceObject: value });
          // this.onSavePrice();
        } else {
          existingPriceLists.find((itemPices) => {
            if (itemPices && itemPices.priceList.id === value.value.id) {
              if (itemId !== 'new') { this.handleRemoveItemPrice(itemPices.id); }
              existingPriceLists.splice(existingPriceLists.indexOf(itemPices), 1);
            }
            return null;
          });
        }
        this.setState({ itemPrices: existingPriceLists });
        break;
      case 'timeToServe':
        this.setState({ timeToServe: value });
        break;
      case 'recipe':
        this.setState({ recipe: value });
        break;
      case 'servings':
        this.setState({ servings: value });
        break;
      case 'tips':
        this.setState({ tips: value });
        break;
      case 'isIngredient':
        const newState = { isIngredient: value.checked };
        if (!value.checked) newState.isModifier = false;
        this.setState(newState);
        break;
      case 'isModifier':
        this.setState({ isModifier: value.checked });
        break;
      case 'isMeal':
        this.setState({ isMeal: value.checked });
        break;
      case 'price':
        this.setState({ price: value, priceStatus: '' });
        break;
      case 'availableQty2':
        this.setState({ availableQty2: value });
        break;
      case 'displayInOnlineMenu2':
        this.setState({ displayInOnlineMenu2: value });
        break;
      default:
    }
  }

  handleSubmit = () => {
    const { itemId } = this.props;
    if (!this.validateForm()) return;
    if (itemId !== 'new') {
      this.handleUpdateItemBasicData();
      this.handleInsertAdvanceItemData(itemId);
    } else {
      this.handleInsertBasicItemData();
    }
  }

  onSavePrice = () => {
    const value = this.state.selectedPriceObject;
    const existingPriceLists = [...this.state.itemPrices];
    if (this.state.price.length > 0) {
      var varObj = { businessId: value.value.businessId, priceList: value.value, price: parseFloat(this.state.price) };
      existingPriceLists.push(varObj);
      this.setState({ isPriceDialogVisibile: false, itemPrices: existingPriceLists, price: 0 });
    } else {
      this.setState({ priceStatus: 'error' });
    }
  }

  handleUpdateItemBasicData = () => {
    const { itemId, companyId } = this.props;
    const {
      name, code, servings, timeToServe,
      isIngredient, isModifier, recipe, tips, barcode, isMeal, imageSrc, availableQty2, displayInOnlineMenu2
    } = this.state;
    const updatedItem = {};

    updatedItem.name = name;
    updatedItem.code = code;
    updatedItem.servings = servings;
    updatedItem.timeToServe = timeToServe;
    // updatedItem.itemPrices = itemPrices;
    // updatedItem.printerLocations = printerLocations;
    updatedItem.isIngredient = isIngredient;
    updatedItem.isModifier = isModifier;
    // updatedItem.ingredients = ingredients;
    updatedItem.recipe = recipe;
    updatedItem.tips = tips;
    updatedItem.barcode = barcode;
    updatedItem.isMeal = isMeal;
    updatedItem.imageSrc = imageSrc;
    updatedItem.availableQty2 = parseInt(availableQty2);
    updatedItem.displayInOnlineMenu2 = displayInOnlineMenu2;

    this.setState({ submitting: true });
    this.props.updateItem(companyId, itemId, updatedItem).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false });
      } else {
        this.forceUpdate();
        // this.setState({  ingredients :[response.item.ingredients]});
        showSuccessNotification('Updated.', 'Item updated successfully.');
        this.setState({ submitting: false });
      }
    });
  }

  insertItemIngredient = (ingredient, itemId) => {
    const { companyId } = this.props;

    this.setState({ submitting: true });
    this.props.addItemIngredient(companyId, itemId,
      { itemId: ingredient.item.id, quantity: ingredient.quantity, uom: ingredient.uom }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ submitting: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          showSuccessNotification('Added.', 'Item Ingredients Added successfully.');
          this.setState({ submitting: false });
        }
      });
  }

  insertItemGroupModifire = (ingredient, itemId) => {
    const { companyId } = this.props;

    this.setState({ submitting: true });
    this.props.addItemGroupModifire(companyId, itemId,
      { quantity: ingredient.quantity, uom: ingredient.uom, commonName: ingredient.commonName, ingredientOptions: ingredient.options }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ submitting: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          showSuccessNotification('Added.', 'Item Group Modifire Added successfully.');
          this.setState({ submitting: false });
        }
      });
  }

  insertItemPriceList = (itemPrice, itemId) => {
    const { companyId } = this.props;

    this.setState({ submitting: true });
    this.props.addItemPriceList(companyId, itemId,
      { priceListId: itemPrice.priceList.id, businessId: itemPrice.businessId, price: itemPrice.price }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ submitting: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          showSuccessNotification('Added.', 'Item Price Added successfully.');
          this.setState({ submitting: false });
        }
      });
  }

  insertItemPrinterLocations = (printerLocation, itemId) => {
    const { companyId } = this.props;

    this.setState({ submitting: true });
    this.props.addItemPrinterLocation(companyId, itemId,
      { printerLocationId: printerLocation.id }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ submitting: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          showSuccessNotification('Added.', 'Item Printer Location Added successfully.');
          this.setState({ submitting: false });
        }
      });
  }

  handleRemovePrinterLocation = (printerLocationId) => {
    const { companyId, itemId } = this.props;

    this.setState({ loading: true });
    this.props.removeItemPrinterLocation(companyId, itemId, { printerLocationId: printerLocationId }).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({  ingredients :[response.item.ingredients]});
        showSuccessNotification('Delete.', 'Item Printer Location Deleted successfully.');
        this.setState({ submitting: false, loading: false });
      }
    });
  }

  handleRemoveItemPrice = (priceListId) => {
    const { companyId, itemId } = this.props;

    this.setState({ loading: true });
    this.props.removeItemPrice(companyId, priceListId, {}).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({  ingredients :[response.item.ingredients]});
        showSuccessNotification('Delete.', 'Item Price Deleted successfully.');
        this.props.fetchItem(companyId, itemId).then(response => {
          this.setState({ loading: false });
        });
      }
    });
  }

  handleRemoveIngredient = (ingredientId) => {
    const { companyId, itemId } = this.props;

    this.setState({ loading: true });
    this.props.removeIngredient(companyId, ingredientId, {itemId : itemId}).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({  ingredients :[response.item.ingredients]});
        showSuccessNotification('Delete.', 'Ingredient Deleted successfully.');
        this.props.fetchItem(companyId, itemId).then(response => {
          this.setState({ loading: false });
        });
      }
    });
  }

  handleInsertBasicItemData = () => {
    const { companyId, company } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;
    const {
      name, code, servings, timeToServe,
      isIngredient, isModifier, recipe, tips, barcode, isMeal, imageSrc, availableQty2, displayInOnlineMenu2
    } = this.state;
    const newItem = {
      name: name,
      businessId: businessId,
      code: code,
      servings: servings === undefined ? '' : servings,
      timeToServe: timeToServe === undefined ? '' : timeToServe,
      // itemPrices: itemPrices,
      // printerLocations: printerLocations,
      isIngredient: isIngredient,
      isModifier: isModifier,
      // ingredients: ingredients,
      recipe: recipe,
      tips: tips,
      barcode: barcode,
      isMeal: isMeal,
      status: '',
      discount: 0,
      quantity: 0,
      imageSrc: imageSrc === undefined ? '' : imageSrc,
      availableQty2: parseInt(availableQty2),
      displayInOnlineMenu2: displayInOnlineMenu2
    };

    this.setState({ submitting: true });
    this.props.addItem(companyId, newItem).then(response => {
      const { error, item } = response;
      if (error) {
        showErrorNotification('Failed adding item', error);
        this.setState({ submitting: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.setState({ submitting: false });
        showSuccessNotification('Item added', 'Item added successfully.');
        this.handleInsertAdvanceItemData(item.id);
      }
    });
  }

  handleInsertAdvanceItemData = (itemId) => {
    const { ingredients, itemPrices, printerLocations } = this.state;
    ingredients.forEach(ingredient => {
      if (ingredient.hasOptions) {
        this.insertItemGroupModifire(ingredient, itemId);
      } else {
        this.insertItemIngredient(ingredient, itemId);
      }
    });
    itemPrices.forEach(itemPrice => {
      this.insertItemPriceList(itemPrice, itemId);
    });
    printerLocations.forEach(printerLocation => {
      this.insertItemPrinterLocations(printerLocation, itemId);
    });

    if (this.props.itemId === 'new') {
      this.setState({ ...defaultItem });
    }
    this.props.resetForm();
    this.props.onSaveItem(itemId);
  }

  validateForm = () => {
    const { name, code, barcode } = this.state;
    const validForm = this.isValidName(name) &&
      this.isValidCode(code) &&
      this.isValidBarcode(barcode);

    if (!validForm) {
      this.setState({
        nameStatus: this.getValidationStatus('name', name),
        codeStatus: this.getValidationStatus('code', code),
        barcodeStatus: this.getValidationStatus('barcode', barcode)

      });
    }
    return validForm;
  }

  validateNewIngredientForm = () => {
    const { quantity, uom } = this.state;
    const validForm = this.isValidQuantity(quantity) &&
      this.isValidUom(uom);

    if (!validForm) {
      this.setState({
        quantityStatus: this.getValidationStatus('quantity', quantity),
        uomStatus: this.getValidationStatus('uom', uom)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      case 'code':
        return this.isValidCode(value) ? 'success' : 'error';
      case 'barcode':
        return this.isValidBarcode(value) ? 'success' : 'error';
      case 'quantity':
        return this.isValidQuantity(value) ? 'success' : 'error';
      case 'uom':
        return this.isValidUom(value) ? 'success' : 'error';
      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;
  isValidCode = code => code && code.length > 0;
  isValidBarcode = barcode => barcode && barcode.length > 0;
  isValidQuantity = quantity => quantity && quantity.length > 0;
  isValidUom = uom => uom && uom.length > 0;

  getCountries = () => {
    const options = [];
    //   countries.map(country => options.push(<Option key={country.name} value={country.name}>{country.name}</Option>));
    return options;
  }

  getNationalities = () => {
    const options = [];
    //   nationalities.map(nationality => options.push(<Option key={nationality.name} value={nationality.name}>{nationality.name}</Option>));
    return options;
  }

  redirectOnInsertSuccess = () => {
    const { redirect, redirectDocId } = this.state;
    if (redirect) {
      this.props.history.push('/incytute/editStudent/' + redirectDocId);
    }
  }

  handleImageChange = src => {
    this.setState({ imageSrc: src });
  }

  customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }} />
  );

  generateLocations = () => {
    const { printerLocations } = this.props;
    const locationArray = [];
    printerLocations && printerLocations.forEach(location => {
      locationArray.push(
        <Col span={6} key={location.id}>
          <Checkbox checked={this.setSelectedLocations(location)} value={location} onChange={event => this.handleOnChange('locations', event.target)}>{location.name}</Checkbox>
        </Col>
      );
    });

    return locationArray;
  }

  generatePriceLists = () => {
    const { priceLists, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const priceListArray = [];
    priceLists && priceLists.forEach(priceList => {
      var priceObjectSelected = this.getSelectedPrice(priceList);
      priceListArray.push(
        <Col span={6} key={priceList.id}>
          <Row>
            <Checkbox checked={priceObjectSelected.selected} value={priceList} onChange={event => this.handleOnChange('priceList', event.target)}>{priceList.name}</Checkbox>
            <p style={{ width: '100%', marginLeft: 25 }}> {format(priceObjectSelected.price, currencyFormat(business))}</p>
          </Row>

        </Col>
      );
    });

    return priceListArray;
  }

  getSelectedPrice = list => {
    const { itemPrices } = this.state;
    var obj = { selected: false, price: 0 };
    itemPrices.find(pr => {
      const prId = pr && (pr.priceList) && pr.priceList.id;
      const listId = list && list.id;

      if (prId === listId) {
        obj.selected = true;
        obj.price = pr.price;
      }

      return null;
    });
    return obj;
  }

  setSelectedLocations = location => {
    const { printerLocations } = this.state;
    return printerLocations.find(pl => {
      const plId = pl && pl.id;
      const locId = location && location.id;
      return plId === locId;
    });
  }

  removeIngredient = (ingredient) => {
    const ingredients = [...this.state.ingredients];
    var index = ingredients.indexOf(ingredient);
    ingredients.splice(index, 1);
    this.setState({ ingredients: ingredients });
    this.handleRemoveIngredient(ingredient.id);
  }

  renderItemPriceDialog = () => {
    return (
      <Modal
        visible={this.state.isPriceDialogVisibile}
        title='Add Price'
        okText='Save'
        onCancel={() => this.setState({ isPriceDialogVisibile: false, price: 0 })}
        onOk={() => this.onSavePrice()}
      >
        <Form layout='vertical'>
          <Form.Item label='Price' validateStatus={this.state.priceStatus}>
            <Input type='number' value={this.state.price} onChange={(event) => this.handleOnChange('price', event.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  renderSideDrawerForm = () => {
    const { itemId, companyId } = this.props;

    const {
      name, code, servings, timeToServe,
      isIngredient, isModifier, ingredients, recipe, tips, barcode, isMeal, nameStatus, codeStatus, barcodeStatus, submitting, imageSrc, availableQty2, displayInOnlineMenu2
    } = this.state;

    return (
      <Spin spinning={submitting}>
        {this.renderItemPriceDialog()}
        <Row gutter={10}>
          <Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              {companyId && itemId &&
                <ImageUploadContainer
                  folder={'/' + companyId + '/items/' + itemId}
                  src={imageSrc}
                  onImageChange={this.handleImageChange}
                />}
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item required label='Name' validateStatus={nameStatus} help={nameStatus === 'error' && 'Name is mandatory'}>
                <div>
                  <Input value={name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                  {/* <p style={{ float: 'right' }}>max: {name ? name.length : 0}/15</p> */}
                </div>
              </Form.Item>
              <Form.Item required label='Short Name' validateStatus={codeStatus} help={codeStatus === 'error' && 'Code is mandatory'}>
                <div>
                  <Input value={code} onChange={(event) => this.handleOnChange('code', event.target.value)} />
                  {/* <p style={{ float: 'right' }}>max: {code ? code.length : 0}/15</p> */}
                </div>
              </Form.Item>
              <Form.Item required label='Barcode' validateStatus={barcodeStatus} help={barcodeStatus === 'error' && 'Barcode is mandatory'}>
                <Input value={barcode} onChange={(event) => this.handleOnChange('barcode', event.target.value)} />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Col span={8}>
              <Checkbox checked={isIngredient} value='Is Ingredient' onChange={event => this.handleOnChange('isIngredient', event.target)}>Is Ingredient</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox checked={isModifier} disabled={!isIngredient} value='Is Modifier' onChange={event => this.handleOnChange('isModifier', event.target)}>Is Modifier</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox checked={isMeal} value='Is Meal' onChange={event => this.handleOnChange('isMeal', event.target)}>Is Meal</Checkbox>
            </Col>
          </Col>
          <Col>
            <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 10 }}>
              <Card title={<h4 style={{ fontWeight: 300 }}>Locations</h4>} extra={<Icon onClick={this.props.onAddPrinterLocation} type='plus-circle' style={{ fontSize: 25 }} theme='filled' />}>
                {this.generateLocations()}
              </Card>
            </Col>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <Card title={<h4 style={{ fontWeight: 300 }}>Price List</h4>} extra={<Icon onClick={this.props.onAddPriceList} type='plus-circle' style={{ fontSize: 25 }} theme='filled' />}>
                {this.generatePriceLists()}
              </Card>
            </Col>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
              <Form.Item label='Time to serve'>
                <Input value={timeToServe} onChange={(event) => this.handleOnChange('timeToServe', event.target.value)} />
              </Form.Item>
              <Form.Item label='No of Heads'>
                <Input value={servings} onChange={(event) => this.handleOnChange('servings', event.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
              <Form.Item label='Item Recipe'>
                <TextArea value={recipe} onChange={(event) => this.handleOnChange('recipe', event.target.value)} />
              </Form.Item>
              <Form.Item label='Health Tips'>
                <TextArea value={tips} onChange={(event) => this.handleOnChange('tips', event.target.value)} />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                title={<h4 style={{ fontWeight: 300 }}>Ingredients</h4>}
                extra={
                  <div style={{ width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button size='small' onClick={() => this.props.onItemAddClick('INGREDIENT')}>Add Ingredient</Button>
                    <Button size='small' onClick={() => this.props.onItemAddClick('GROUP_MODIFIER')}>Add Modifier Group</Button>
                  </div>
                }
              >
                <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                  <List
                    size='small'
                    itemLayout='horizontal'
                    dataSource={Object.values(ingredients)}
                    renderItem={ingredient => {
                      var title = '';
                      if (ingredient.hasOptions && Object.values(ingredient.options).length > 0) {
                        title = '(G) ' + ingredient.commonName + ' - ' + Object.values(ingredient.options)[0].item.name;
                      } else {
                        title = ingredient.item && ingredient.item.name;
                      }
                      return (
                        <List.Item
                          actions={[<a href='/#' key='list-loadmore-edit' onClick={() => this.removeIngredient(ingredient)}>Remove</a>]}
                        >
                          <Skeleton avatar title={false} loading={false} active>
                            <List.Item.Meta
                              title={title}
                            />
                          </Skeleton>
                          <List.Item.Meta
                            title={ingredient.quantity}
                          />
                          <List.Item.Meta
                            title={ingredient.uom}
                          />
                        </List.Item>
                      );
                    }}
                  />
                </ConfigProvider>
              </Card>
            </Col>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
              <Form.Item label='Available Quantity'>
                <input
                  className='ant-input'
                  value={availableQty2}
                  type='number'
                  min='0'
                  onChange={(event) => this.handleOnChange('availableQty2', event.target.value)}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <Col lg={5} md={5} sm={5} xs={24} style={{ float: 'right', marginTop: 15 }}>
              <Button type='primary' size='small' style={{ width: '100%', marginBottom: 10 }} onClick={() => this.handleSubmit()}>{itemId !== 'new' ? 'Update' : 'Save'}</Button>
            </Col>
            <Col lg={5} md={5} sm={5} xs={24} style={{ float: 'right', marginTop: 15 }}>
              <Button type='primary' size='small' style={{ width: '100%', marginBottom: 10 }}>Reset</Button>
            </Col>
            <Col lg={10} md={10} sm={10} xs={24} style={{ float: 'right', textAlign: 'end', marginTop: 10 }}>
              <Form.Item>
                <Checkbox checked={displayInOnlineMenu2} onChange={(event) => this.handleOnChange('displayInOnlineMenu2', event.target.checked)}>Show item in online menu</Checkbox>
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Spin>
    );
  }

  renderFullScreenForm = () => {
    const { itemId, companyId } = this.props;

    const {
      name, code, servings, timeToServe,
      isIngredient, isModifier, ingredients, recipe, tips, barcode, isMeal, nameStatus, codeStatus, barcodeStatus, loading, imageSrc, availableQty2, displayInOnlineMenu2, submitting
    } = this.state;

    return (
      <PageHeader
        style={{ margin: 5 }}
        ghost={false}
        title={itemId === 'new' ? 'New Item' : 'Edit Item'}
        onBack={() => this.props.history.goBack()}
      >
        <Card>
          <Spin spinning={loading || submitting}>
            {this.renderItemPriceDialog()}
            <Row gutter={10}>
              <Form layout='vertical'>
                <Col span={6} lg={6} md={6} sm={24} xs={24}>
                  {companyId && itemId &&
                    <ImageUploadContainer
                      folder={'/' + companyId + '/items/' + itemId}
                      src={imageSrc}
                      onImageChange={this.handleImageChange}
                    />}
                </Col>
                <Col span={18} lg={18} md={18} sm={24} xs={24}>
                  <Form.Item required label='Name' validateStatus={nameStatus} help={nameStatus === 'error' && 'Name is mandatory'}>
                    <div>
                      <Input value={name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                      <p style={{ float: 'right' }}>max: {name ? name.length : 0}/15</p>
                    </div>
                  </Form.Item>
                  <Form.Item required label='Short Name' validateStatus={codeStatus} help={codeStatus === 'error' && 'Code is mandatory'}>
                    <div>
                      <Input value={code} onChange={(event) => this.handleOnChange('code', event.target.value)} />
                      <p style={{ float: 'right' }}>max: {code ? code.length : 0}/15</p>
                    </div>
                  </Form.Item>
                  <Form.Item required label='Barcode' validateStatus={barcodeStatus} help={barcodeStatus === 'error' && 'Barcode is mandatory'}>
                    <Input value={barcode} onChange={(event) => this.handleOnChange('barcode', event.target.value)} />
                  </Form.Item>
                </Col>
              </Form>
            </Row>
            <Row gutter={10}>
              <Form layout='vertical'>
                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                  <Card title={<h1 style={{ fontWeight: 300 }}>Locations</h1>} extra={<Icon onClick={this.props.onAddPrinterLocation} type='plus-circle' style={{ fontSize: 25 }} theme='filled' />}>
                    {this.generateLocations()}
                  </Card>
                </Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                  <Card title={<h1 style={{ fontWeight: 300 }}>Price List</h1>} extra={<Icon onClick={this.props.onAddPriceList} type='plus-circle' style={{ fontSize: 25 }} theme='filled' />}>
                    {this.generatePriceLists()}
                  </Card>
                </Col>
              </Form>
            </Row>
            <Row gutter={10}>
              <Form layout='vertical'>
                <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
                  <Form.Item label='Time to serve'>
                    <Input value={timeToServe} onChange={(event) => this.handleOnChange('timeToServe', event.target.value)} />
                  </Form.Item>
                  <Form.Item label='No of Heads'>
                    <Input value={servings} onChange={(event) => this.handleOnChange('servings', event.target.value)} />
                  </Form.Item>
                  <Col span={24}>
                    <Col span={6}>
                      <Checkbox checked={isIngredient} value='Is Ingredient' onChange={event => this.handleOnChange('isIngredient', event.target)}>Is Ingredient</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox checked={isModifier} disabled={!isIngredient} value='Is Modifier' onChange={event => this.handleOnChange('isModifier', event.target)}>Is Modifier</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox checked={isMeal} value='Is Meal' onChange={event => this.handleOnChange('isMeal', event.target)}>Is Meal</Checkbox>
                    </Col>
                  </Col>
                </Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
                  <Form.Item label='Item Recipe'>
                    <TextArea value={recipe} onChange={(event) => this.handleOnChange('recipe', event.target.value)} />
                  </Form.Item>
                  <Form.Item label='Health Tips'>
                    <TextArea value={tips} onChange={(event) => this.handleOnChange('tips', event.target.value)} />
                  </Form.Item>
                </Col>
              </Form>
            </Row>
            <Row gutter={10}>
              <Form layout='vertical'>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Card
                    title={<h1 style={{ fontWeight: 300 }}>Ingredients</h1>}
                    extra={
                      <div style={{ width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button size='small' onClick={() => this.props.onItemAddClick('INGREDIENT')}>Add Ingredient</Button>
                        <Button size='small' onClick={() => this.props.onItemAddClick('GROUP_MODIFIER')}>Add Modifier Group</Button>
                      </div>
                    }
                  >
                    <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
                      <List
                        size='small'
                        itemLayout='horizontal'
                        dataSource={Object.values(ingredients)}
                        renderItem={ingredient => {
                          var title = '';
                          if (ingredient.hasOptions && Object.values(ingredient.options).length > 0) {
                            title = '(G) ' + ingredient.commonName + ' - ' + Object.values(ingredient.options)[0].item.name;
                          } else {
                            title = ingredient.item && ingredient.item.name;
                          }
                          return (
                            <List.Item
                              actions={[<a href='/#' key='list-loadmore-edit' onClick={() => this.removeIngredient(ingredient)}>Remove</a>]}
                            >
                              <Skeleton avatar title={false} loading={false} active>
                                <List.Item.Meta
                                  title={title}
                                />
                              </Skeleton>
                              <List.Item.Meta
                                title={ingredient.quantity}
                              />
                              <List.Item.Meta
                                title={ingredient.uom}
                              />
                            </List.Item>
                          );
                        }}
                      />
                    </ConfigProvider>
                  </Card>
                </Col>
              </Form>
            </Row>
            <Row gutter={10}>
              <Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 15 }}>
                  <Form.Item label='Available Quantity'>
                    <input
                      className='ant-input'
                      value={availableQty2}
                      type='number'
                      min='0'
                      onChange={(event) => this.handleOnChange('availableQty2', event.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 42 }}>
                  <Row gutter={10}>
                    <Col lg={5} md={5} sm={5} xs={24} style={{ float: 'right', marginTop: 15 }}>
                      <Button type='primary' size='small' style={{ width: '100%', marginBottom: 10 }} onClick={() => this.handleSubmit()}>{itemId !== 'new' ? 'Update' : 'Save'}</Button>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={24} style={{ float: 'right', marginTop: 15 }}>
                      <Button type='primary' size='small' style={{ width: '100%', marginBottom: 10 }}>Reset</Button>
                    </Col>
                    <Col lg={14} md={14} sm={14} xs={24} style={{ float: 'right', textAlign: 'end', marginTop: 10 }}>
                      <Form.Item>
                        <Checkbox checked={displayInOnlineMenu2} onChange={(event) => this.handleOnChange('displayInOnlineMenu2', event.target.checked)}>Show item in online menu</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Spin>
        </Card>
      </PageHeader>
    );
  }

  render() {
    return this.props.screenType ? this.renderSideDrawerForm() : this.renderFullScreenForm();
  }
}

const mapStateToProps = (state, props) => {
  const { itemId, companyId } = props;
  return {
    companyId,
    item: getItem(state, itemId),
    printerLocations: getPrinterLocations(state),
    priceLists: getPriceLists(state),
    company: getCompanyById(state, companyId)
  };
};

export default withRouter(connect(mapStateToProps, { fetchItem, fetchItems, fetchPrinterLocations, fetchPriceLists, updateItem, addItemIngredient, addItemPriceList, addItem, fetchCompany, addItemPrinterLocation, removeItemPrinterLocation, removeItemPrice, addItemGroupModifire, removeIngredient })(ItemForm));
