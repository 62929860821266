import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, Spin, Modal } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router';
import { format } from 'currency-formatter';
import { currencyFormat, getItemRetailPrice } from '../../../helpers/common';
import { getFilteredOrderItems } from '../../../reducers';
import logo from '../../../image/logo.svg';

const dateTimeFormat = 'YYYY/MM/DD';
class OrderReciptPrintModal extends Component {
  state = {
    loading: false
  }

  renderInvoiceData = () => {
    const { order, receipt, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const invoiceDataList = [];
    // invoice && invoice.details && invoice.details.forEach(detail => {
    //   invoiceDataList.push(
    //     <div style={{ display: 'flex', marginTop: 5 }}>
    //       <div style={{ width: '25%' }}>
    //         <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{detail.itemName}</span>
    //       </div>
    //       <div style={{ width: '35%' }}>
    //         <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{detail.itemDescription}</span>
    //       </div>
    //       <div style={{ width: '10%', textAlign: 'center' }}>
    //         <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{detail.quantity}</span>
    //       </div>
    //       <div style={{ width: '15%', textAlign: 'right' }}>
    //         <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{format(detail.itemPrice, currencyFormat(business))}</span>
    //       </div>
    //       <div style={{ width: '15%', textAlign: 'right' }}>
    //         <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{format(detail.lineTotal, currencyFormat(business))}</span>
    //       </div>
    //     </div>
    //   );
    // });

    return invoiceDataList;
  }

  handlePrintClick = () => {
    const content = document.getElementById('invoice-print').innerHTML;
    const printWindow = window.open('', '', 'height=768, width= 1024');
    printWindow.document.write(content);
    const paras = printWindow.document.getElementsByTagName('p');
    for (const x in paras) {
      const pa = paras[x];
      if (pa.style) pa.style.margin = '0px !important';
    }
    printWindow.document.close();
    printWindow.print();
    printWindow.onafterprint = () => {
      printWindow.close();
    };
  }

  renderOrderItem = (orderItem) => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { name, quantity, modifiers = [], price } = orderItem;
    const modifierList = Array.isArray(modifiers) ? modifiers : Object.values(modifiers);
    return (
      <div style={{ display: 'flex', width: '100%', textAlign: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <span style={{ width: '40%', textAlign: 'left', fontSize: 'medium', }}>  {name}</span>
          <span style={{ width: '20%', textAlign: 'right', fontSize: 'medium', }}>{quantity}</span>
          <span style={{ width: '20%', textAlign: 'right', fontSize: 'medium', }}>{format(price, currencyFormat(business))}</span>
          <span style={{ width: '20%', textAlign: 'right', fontSize: 'medium', }}>{format((price * quantity), currencyFormat(business))}</span>
        </div>

        {modifierList.map(orderModifier => {
          const { modifiers = [] } = orderModifier;
          const optionModifiers = Array.isArray(modifiers) ? modifiers : Object.values(modifiers);
          return <div style={{ display: 'flex', width: '100%', }} key={orderModifier.id}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}  >

              <div style={{ display: 'flex', width: '100%', }}>
                <span style={{ textAlign: 'left', width: '40%', fontSize: 'small', }}> &nbsp; &nbsp; &nbsp; {orderModifier && ' ' + orderModifier.name}</span>
                <span style={{ textAlign: 'right', width: '20%', }}>{orderModifier.quantity}{quantity > 1 ? ' * ' + quantity : ''}</span>
                <span style={{ textAlign: 'right', width: '20%', }}>{format(orderModifier.price, currencyFormat(business))}</span>
                <span style={{ textAlign: 'right', width: '20%', }}>{format(orderModifier.price * orderModifier.quantity * quantity, currencyFormat(business))}</span>
              </div>

              {optionModifiers.map(orderModifier => {
                return <div style={{ display: 'flex', width: '100%', }} key={orderModifier.id}>
                  <div style={{ display: 'flex', width: '100%', }}>
                    <div style={{ display: 'flex', width: '100%' }} >
                      <span style={{ textAlign: 'left', width: '40%', fontSize: 'small', }}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {orderModifier && ' ' + orderModifier.name}</span>
                      <span style={{ textAlign: 'right', width: '20%', }}>{orderModifier.quantity}{quantity > 1 ? ' * ' + quantity : ''}</span>
                      <span style={{ textAlign: 'right', width: '20%', }}>{format(orderModifier.price, currencyFormat(business))}</span>
                      <span style={{ textAlign: 'right', width: '20%', }}>{format(orderModifier.price * orderModifier.quantity * quantity, currencyFormat(business))}</span>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
        })}
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { company, order, onCancel, visible,  items = [] } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const {customer, deliveryPerson = {}, type = '', number = '', time = '', transactions = [], status = '', balance_to_settle = 0.0 } = order || {};
    const { deliveryAddress = '', remarks = '', consumerFeedback = undefined, deliveryPersonFeedback = undefined } = order && order.data || {};
    const orderItemList = items && Array.isArray(items) ? items : Object.values(items);

    let deliveryToAddress = '';
    let formattedAddress = '';
    let formattedCity = '';
    let formattedPaymentType = '';
    let formattedPaymentDate = '';
    let formattedPaidAmount = '';
    const paymentDetailsList = [];

    let totalPaid = 0.0;
    let orderTotal = 0.0;

    orderTotal = order ? order.total : 0.0;
    totalPaid = orderTotal - balance_to_settle;


    if (deliveryAddress) {
      const { address, city } = deliveryAddress;
      formattedAddress = address !== '' ? 'Delivery address : ' + address : '';
      formattedCity = (city !== '') ? ', ' + city : '';
    }

    const orderNo = number ? number + ' / ' : '';
    const formattedDate = moment(time).format(dateTimeFormat);

    if (type === 'TO_GO') {
      deliveryToAddress = 'Pickup on store ';
    } else {
      deliveryToAddress = formattedAddress + formattedCity;
    }

    if (transactions) {
      var listOftransaction = Array.isArray(transactions) ? transactions : Object.values(transactions);
      for (var i = 0; i < listOftransaction.length; i++) {
        formattedPaymentType = listOftransaction[i].type && listOftransaction[i].type !== '' ? listOftransaction[i].type : '';
        formattedPaidAmount = listOftransaction[i].paid ? ', ' + listOftransaction[i].paid + '.00' : 0.00;
        formattedPaymentDate = listOftransaction[i].time ? ', ' + moment(listOftransaction[i].time).format(dateTimeFormat) : '';

        paymentDetailsList.push(formattedPaymentType + formattedPaidAmount + formattedPaymentDate);
      }
    }

    return (
      <Modal
        style={{ padding: 0 }}
        visible={visible}
        title=''
        okText='Save'
        onCancel={onCancel}
        onOk={this.ok}
        cancelButtonProps={{ style: { color: 'white', border: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <div >
          <Col lg={24} md={24} sm={24} xs={24}>
            <Spin spinning={loading}>
              <Card
                className='print-invoice-view' style={{ margin: 5 }}
                extra={
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: 100 }}>
                    <Button className='primary-button' size='small' type='primary' onClick={() => this.handlePrintClick()}>Print</Button>
                    {/* <Button size='small' type='primary' style={{ marginLeft: 5, marginRight: 5 }}>Send as email</Button> */}
                    <Button size='small' type='primary' onClick={onCancel}>Exit</Button>
                  </div>
                }
              >
                <div id='invoice-print' style={{ fontFamily: 'Roboto' }}>
                  <div style={{ display: 'flex', fontFamily: 'Roboto' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      {/* <h1 style={{ fontSize: 22, fotWeight: 500, fontFamily: 'Roboto' }}>RECEIPT</h1> */}

                      <Row gutter={5} style={{ marginBottom: 10 }}>
                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>
                              {/* <img alt='' width={50} height={50} src={business && business.logoImageSrc} ></img> */}
                              <h1 style={{ fontSize: 30 }}>{business && business.name}</h1>
                            </td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{orderNo}{formattedDate}</td>
                          </tr>
                        </table>
                      </Row>

                      <Row gutter={5}>
                        <table style={{ width: '100%' }}>

                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>{business && business.address}</td>
                          </tr>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>{business && business.telephone}</td>
                          </tr>
                        </table>
                      </Row>

                      <Row gutter={5}>
                        <table style={{ width: '100%', marginTop: 20 }}>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium', marginBottom: 5 }}>{customer ? 'Customer : ' + customer.name : ''}</td>
                          </tr>
                          {deliveryPerson && deliveryPerson.name && <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Delivery Person : {deliveryPerson.name}</td>
                          </tr>}
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>{deliveryToAddress}</td>
                          </tr>
                        </table>

                      </Row>
                      <Row gutter={5}>
                        <p style={{ fontWeight: 'bold', fontSize: 'medium', marginTop: 10, marginBottom: 10 }}>Order Items</p>
                        <h6 >Item count : {orderItemList.length}</h6>

                        <Col lg={24} style={{ marginBottom: 25, marginTop: 10 }}>
                          <table style={{ width: '100%', textAlign: 'center' }}>
                            <tr>
                              <td style={{ width: '40%', textAlign: 'left', fontSize: 'medium' }}>Description</td>
                              <td style={{ width: '20%', textAlign: 'right', fontSize: 'medium' }}>Qty</td>
                              <td style={{ width: '20%', textAlign: 'right', fontSize: 'medium' }}>Unit Price</td>
                              <td style={{ width: '20%', textAlign: 'right', fontSize: 'medium' }}>Total</td>
                            </tr>
                          </table>
                          <hr style={{ margin: 0 }} />
                          {orderItemList.map(orderItem => this.renderOrderItem(orderItem))}
                          <hr style={{ marginTop: 4, marginBottom: 0 }} />
                        </Col>
                      </Row>

                      {order && <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <table style={{ width: '70%', }}>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Gross Amount</td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{format(order.subtotal, currencyFormat(business))}</td>
                          </tr>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Discount</td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{!order.discount ? format(0, currencyFormat(business)) : format(order.discount, currencyFormat(business))} </td>
                          </tr>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Delivery Charge</td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{format(order.delivery_charge, currencyFormat(business))} </td>
                          </tr>
                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Net Amount</td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{format(order.total, currencyFormat(business))} </td>
                          </tr>

                        </table>
                      </Row>}


                      {order && <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>

                        <table style={{ width: '70%', }}>

                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Balance To Settle</td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{format(order.balance_to_settle, currencyFormat(business))} </td>
                          </tr>

                          <tr>
                            <td style={{ width: '50%', textAlign: 'left', fontSize: 'medium' }}>Total Paid</td>
                            <td style={{ width: '50%', textAlign: 'right', fontSize: 'medium', marginBottom: 5 }}>{format(totalPaid, currencyFormat(business))} </td>
                          </tr>
                        </table>
                      </Row>}


                      <Row gutter={5} style={{ marginTop: 10 }}>
                        <Col md={24} sm={24} xs={24} lg={24}>
                          {paymentDetailsList && paymentDetailsList.length > 0 && <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5 }}>Payment Details</p>}
                          {paymentDetailsList.map(payment =>
                            <div style={{ display: 'flex' }}>
                              <p style={{ fontSize: 'medium', marginBottom: 15 }}>{payment}</p>
                            </div>
                          )}

                          {remarks !== undefined && remarks.length > 0 && <div>
                            <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5 }}>Order Remarks</p>
                            <p style={{ fontSize: 'medium', marginBottom: 10 }}>{remarks !== undefined ? remarks : 'N/A'}</p>
                          </div>}

                          {consumerFeedback && <div>
                            <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5 }}>Consumer Feedback</p>
                            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{consumerFeedback.accepted ? 'ACCEPTED' : 'REJECTED'}</p>
                            {consumerFeedback.comment && <p style={{ fontSize: 'medium', marginBottom: 10 }}>{consumerFeedback.comment}</p>}
                          </div>}

                          {deliveryPersonFeedback && <div>
                            <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5 }}>Delivery Person Feedback</p>
                            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{deliveryPersonFeedback.status ? deliveryPersonFeedback.status : 'N/A'}</p>
                            {deliveryPersonFeedback.comment && <p style={{ fontSize: 'medium', marginBottom: 10 }}>{deliveryPersonFeedback.comment}</p>}
                          </div>}
                        </Col>
                      </Row>



                    </div>
                  </div>
                </div>
              </Card>
            </Spin>
          </Col>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const filter = {
    orderId: ownProps.order && ownProps.order.id,
    businessIds: ownProps.businessIds,
    branchIds: ownProps.branchIds
  }
  return ({
    items: getFilteredOrderItems(state, filter)
  });
};

export default withRouter(connect(mapStateToProps, null )(OrderReciptPrintModal));
