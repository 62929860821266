import { combineReducers } from 'redux';
import moment from 'moment';

export const defaultFilter = {
  text: undefined,
  userId: undefined,
  orderId: undefined,
  fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
  toDate: moment(new Date()).endOf('day'),
  page: 1,
  pageSize: 20,
  status: [],
  method: ''
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_INVENTORY_ITEMS': {
      const newState = {};
      action.inventoryItems && action.inventoryItems.forEach(inventoryItem => { newState[inventoryItem.id] = inventoryItem; });
      return newState;
    }
    // case 'DELETE_CUSTOMER': {
    //   const newState = {};
    //   action.customers && action.customers.forEach(customer => { newState[customer.id] = customer; });
    //   return newState;
    // }
    // case 'FETCH_CUSTOMER': {
    //   return { ...state, [action.customer.id]: action.customer };
    // }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INVENTORY_ITEMS': {
      const newState = [];
      action.inventoryItems && action.inventoryItems.forEach(inventoryItem => {
        if (!newState.includes(inventoryItem.id)) {
          newState.push(inventoryItem.id);
        }
      });
      return newState;
    }

    // case 'DELETE_CUSTOMER': {
    //   const newState = [];
    //   action.customers && action.customers.forEach(customer => {
    //     if (!newState.includes(customer.id)) {
    //       newState.push(customer.id);
    //     }
    //   });
    //   console.log(newState);
    //   return newState;
    // }
    // case 'FETCH_CUSTOMER': {
    //   if (state.includes(action.customer.id)) {
    //     return state;
    //   } else {
    //     return [...state, action.customer.id];
    //   }
    // }
    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_INVENTORY_ITEMS': {
      const newState = {};
      const key = Object.values(JSON.stringify(action.query));
      const idsForFilter = newState[key] ? newState[key].ids : [];
      action.inventoryItems && action.inventoryItems.forEach(inventoryItem => {
        if (!idsForFilter.includes(inventoryItem.id)) idsForFilter.push(inventoryItem.id);
      });
      newState[key] = { ids: idsForFilter, totalResults: action.totalResults, QIHTotal: action.QIHTotal, ROQTotal: action.ROQTotal };
      return newState;
    }
    default:
      return state;
  }
};

export const activeFilter = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_CUSTOMER':
      return {
        ...state,
        [action.filterType]: action.filter
      };

    case 'UPDATE_ACTIVE_FILTER_CUSTOMER':
      return {
        ...action.filter
      };

    default:
      return state;
  }
};

const inventoryItems = combineReducers({
  byId,
  ids,
  byFilter,
  activeFilter
});
export default inventoryItems;

export const getInventoryItems = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getActiveFilter = state => {
  return state.activeFilter;
};

// export const getFilteredCustomers= state => {
//   const key = Object.values(getActiveFilter(state)).join('');
//   const filterdCustomerIds = state.byFilter[key] ? state.byFilter[key].ids : [];

//   return filterdCustomerIds.map(id => state.byId[id]).filter(customer => !!customer);
// };

export const getFilteredItemTotalCount = state => {
  const byFilterArray = Object.values(state.byFilter);
  return byFilterArray.length > 0 ? byFilterArray[0].totalResults : 0
};

export const getFilteredItemQIHTotal = state => {
  const byFilterArray = Object.values(state.byFilter);
  return byFilterArray.length > 0 ? byFilterArray[0].QIHTotal : 0
};
