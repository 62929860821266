import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
import moment from 'moment';

const { TextArea } = Input;

class TransferNoteForm extends Component {
  render () {
    const {
      transferNoteId,
      onSubmitTransferNote,
      submitting,
      onUpdateBankDeposit,
      transferNote,
      handleOnChange,
      depositDateStatus,
      bankStatus,
      accountNoStatus,
      transferredByStatus,
      amountStatus,
      business
    } = this.props;

    const { transferDate, bank, bankAccountNumber, transferredBy, amount, remarksList } = transferNote;
    const creationRemarks = remarksList && remarksList.length > 0 ? remarksList.filter(remarks => remarks.type === 'CREATION') : [];

    const inputDisabled = transferNote.status !== 'PENDING';
    return (
      <Spin spinning={submitting}>
        <Form layout='vertical'>
          <Form.Item label='Transfer Date' required validateStatus={depositDateStatus} help={depositDateStatus === 'error' && 'Transfer Date is mandatory.'}>
            <DatePicker
              allowClear={false}
              placeholder='dd/mm/yyyy'
              format='DD/MM/YYYY'
              disabled={inputDisabled}
              style={{ width: '100%' }}
              disabledDate={current => current > moment().endOf('day')}
              value={moment(transferDate)}
              onChange={(moment, string) => handleOnChange('transferDate', moment)}
            />
          </Form.Item>
          <Form.Item label='Amount' required validateStatus={amountStatus} help={amountStatus === 'error' && 'Amount must be greater than 0.'}>
            <div style={{ fontWeight: '-moz-initial', marginLeft: 10, color: bank ? 'rgba(0, 0, 0, 0.65)' : '#d8d8d8' }}>{format(amount, currencyFormat(business))}</div>
          </Form.Item>
          <Form.Item label='Bank' required validateStatus={bankStatus} help={bankStatus === 'error' && 'Bank is mandatory.'}>
            <div style={{ fontWeight: '-moz-initial', marginLeft: 10, color: bank ? 'rgba(0, 0, 0, 0.65)' : '#d8d8d8' }}>{bank || 'Select client to load bank name'}</div>
          </Form.Item>
          <Form.Item label='Account No' required validateStatus={accountNoStatus} help={accountNoStatus === 'error' && 'Account number is mandatory.'}>
            <div style={{ fontWeight: '-moz-initial', marginLeft: 10, color: bankAccountNumber ? 'rgba(0, 0, 0, 0.65)' : '#d8d8d8' }}>{bankAccountNumber || 'Select client to load account no'}</div>
          </Form.Item>
          <Form.Item label='Transferred By' required validateStatus={transferredByStatus} help={transferredByStatus === 'error' && 'Transferred By is mandatory.'}>
            <div>
              <Input value={transferredBy} onChange={(event) => handleOnChange('transferredBy', event.target.value)} />
            </div>
          </Form.Item>
          <Form.Item label='Remarks'>
            <div>
              <TextArea disabled rows={5} value={creationRemarks[0] && creationRemarks[0].message ? creationRemarks[0].message : ''} onChange={(event) => handleOnChange('remarks', event.target.value)} />
            </div>
          </Form.Item>
          <div>
            {!transferNoteId || transferNoteId === 'new' ? <Col lg={24} md={24} style={{ marginBottom: 10 }}>
              <Button type='primary' size='small' style={{ width: '100%' }} onClick={onSubmitTransferNote}>Create</Button>
            </Col> : transferNote && transferNote.status === 'PENDING' && <Row gutter={10}>
                                                             <Col lg={24} md={24} sm={12} xs={12} style={{ marginBottom: 10 }}>
                <Button type='primary' size='small' style={{ width: '100%' }} onClick={onUpdateBankDeposit}>Update</Button>
              </Col>
            </Row>}
          </div>
        </Form>
      </Spin>
    );
  }
}

export default TransferNoteForm;
