import React, { Component } from 'react';
import { Modal, Form, Input } from 'antd';

class ItemEditModalForm extends Component {
  state = {
    QIHStatus: '',
    ROLStatus: '',
    ROQStatus: '',
    RESQStatus: ''
  }

  render() {
    const { visible, handleOnChange, inventoryItem = {} } = this.props;
    const { QIHStatus, ROLStatus, ROQStatus, RESQStatus } = this.state;
    const balance = inventoryItem && inventoryItem.quantity - inventoryItem.reservedQuantity + "";
    return (
      <Modal
        visible={visible}
        title='Edit Inventory Item'
        okText='Save'
        onCancel={this.cancel}
        onOk={this.ok}
      >

        <Form layout='vertical'>
          <Form.Item label='Item'>
            <p style={{ fontSize: 14 }}><b>{inventoryItem && inventoryItem.itemDescription}</b></p>
          </Form.Item>
          <Form.Item label='QIH' validateStatus={QIHStatus}>
            <Input type='number' value={inventoryItem && inventoryItem.quantity} onChange={event => { handleOnChange('QIH', event.target.value); this.resetStatus() }} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault()} />
          </Form.Item>
          <Form.Item label='ROL' validateStatus={ROLStatus}>
            <Input type='number' value={inventoryItem && inventoryItem.reorderLevel} onChange={event => { handleOnChange('ROL', event.target.value); this.resetStatus() }} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()} />
          </Form.Item>
          <Form.Item label='ROQ' validateStatus={ROQStatus}>
            <Input type='number' value={inventoryItem && inventoryItem.reorderQuantity} onChange={event => { handleOnChange('ROQ', event.target.value); this.resetStatus() }} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()} />
          </Form.Item>
          <Form.Item label='RESQ' validateStatus={RESQStatus}>
            <Input type='number' value={inventoryItem && inventoryItem.reservedQuantity} onChange={event => { handleOnChange('RESQ', event.target.value); this.resetStatus() }} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault()} />
          </Form.Item>
          <Form.Item label='Balance'  >
            <span style={{ fontSize: 16 }}><b>{balance}</b></span>
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  resetStatus = () => {
    this.setState({
      QIHStatus: '',
      ROLStatus: '',
      ROQStatus: '',
      RESQStatus: ''
    })
  }

  ok = () => {
    const { index } = this.props;
    console.log("here", this.validateForm())
    if (this.validateForm()) {
      console.log("heress")
      this.props.onCreate(index);
    } 
  }

  cancel = () => {
    this.setState({ uomStatus: '', quantityStatus: '' });
    this.props.onCancel();
  }

  validateForm = () => {
    const { inventoryItem } = this.props;
    const validForm = this.isValidQIH(inventoryItem.quantity.toString(), inventoryItem.reservedQuantity.toString())
      && this.isValidRESQ(inventoryItem.quantity.toString(), inventoryItem.reservedQuantity.toString())
      && this.isValidROL(inventoryItem.reorderLevel.toString())
      && this.isValidROQ(inventoryItem.reorderQuantity.toString());

    if (!validForm) {
      this.setState({
        QIHStatus: this.getValidationStatus('QIH', inventoryItem),
        RESQStatus: this.getValidationStatus('RESQ', inventoryItem),
        ROLStatus: this.getValidationStatus('ROL', inventoryItem),
        ROQStatus: this.getValidationStatus('ROQ', inventoryItem)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, inventoryItem) => {
    switch (name) {
      case 'QHI':
        return this.isValidQIH(inventoryItem.quantity.toString(), inventoryItem.reservedQuantity.toString()) ? 'success' : 'error';
      case 'ROL':
        return this.isValidROL(inventoryItem.reorderLevel.toString()) ? 'success' : 'error';
      case 'ROQ':
        return this.isValidROQ(inventoryItem.reorderQuantity.toString()) ? 'success' : 'error';
      case 'RESQ':
        return this.isValidRESQ(inventoryItem.quantity.toString(), inventoryItem.reservedQuantity.toString()) ? 'success' : 'error';
      default:
        return null;
    }
  }

  isValidQIH = (QIH, RESQ) => {
    const QIHVal = QIH && QIH.length > 0 ? parseFloat(QIH) : "";
    const RESQVal = RESQ && RESQ.length > 0 ? parseFloat(RESQ) : ""
    return QIHVal !== "" && QIHVal >= RESQVal;
  }

  isValidROL = (ROL) => {
    const ROLVal = ROL && ROL.length > 0 ? parseFloat(ROL) : "";
    console.log(ROLVal !== "")
    return ROLVal !== ""
  }

  isValidROQ = (ROQ) => {
    const ROQVal = ROQ && ROQ.length > 0 ? parseFloat(ROQ) : "";
    console.log(ROQVal && ROQVal !== "")
    return ROQVal !== "";
  }

  isValidRESQ = (QIH, RESQ) => {
    const QIHVal = QIH && QIH.length > 0 ? parseFloat(QIH) : "";
    const RESQVal = RESQ && RESQ.length > 0 ? parseFloat(RESQ) : ""
    return RESQVal !== "" && QIHVal >= RESQVal;
  }
}

export default ItemEditModalForm;
