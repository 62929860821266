import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Collapse, Icon, Row, Spin, Modal, PageHeader, Select, Card, Tag, Input, Form } from 'antd';
import withRouter from 'react-router/es/withRouter';
import TransferNoteForm from './TransferNoteForm';
import ConfirmTransferNoteForm from './ConfirmTransferNoteForm';
import PayoutList from './PayoutList';
import { fetchTransferNote, verifyTransferNote } from '../../../actions/transferNotes';
import { getAuthenticity, getTransferNoteById, getCompanyById } from '../../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import { showLoadingMessage } from '../../../components/Message';
import SideBar from '../../../components/SideBar';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const defaultTransferNote = {
  id: undefined,
  number: undefined,
  status: undefined,
  transferDate: new Date(),
  bank: undefined,
  bankBranch: undefined,
  bankAccountNumber: undefined,
  transferReferenceNumber: undefined,
  amount: 0,
  orders: [],
  transferredBy: undefined,
  remarks: '',
  image: undefined,
  remarksList: []
};

class TranferNoteView extends Component {
  state = {
    transferNoteId: undefined,
    view: undefined,
    expandedForm: undefined,
    loading: false,
    transferNote: {
      ...defaultTransferNote
    },
    submitting: false,
    depositDateStatus: undefined,
    bankStatus: undefined,
    accountNoStatus: undefined,
    transferredByStatus: undefined,
    amountStatus: undefined,
    referenceNumberStatus: undefined,
    cashReceiptIds: [],
    printModalVisible: false,
    verifiedClientBankDetails: true
  }

  componentDidMount() {
    console.log('dddd');
    const { authenticity: { user: { userId } }, match, fetchTransferNote } = this.props;
    const { transferNoteId } = match.params;
    console.log(transferNoteId);
    this.setState({ loading: true });
    fetchTransferNote(userId, transferNoteId).then((response) => {
      console.log(response, userId);
      this.setState({ loading: false });
    });
  }

  componentWillReceiveProps = nextProps => {
    console.log('dddd');
    // const { companyId } = this.props.match.params;
    const { transferNote } = nextProps;

    if (transferNote) {
      // let bankAccountDetail = this.getClientBankAccountDetails(companyId);

      this.setState({
        transferNote: { ...transferNote }
      });
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Orders.');
  }

  getExpandedFormKey = transferNote => {
    if (transferNote && transferNote.status === 'PENDING') return ['1'];
    else if (transferNote && transferNote.status === 'CONFIRMED') return ['2'];
    else if (transferNote && transferNote.status === 'VERIFIED') return ['3'];
    else if (transferNote && transferNote.status === 'COMPLETED') return [];
    else return ['1'];
  }

  onFormExpand = key => {
    this.setState({ expandedForm: key });
  }

  handleOnChange = (field, value) => {
    const { transferNote } = this.state;
    const { authenticity: { user: { userId } } } = this.props;
    switch (field) {
      case 'transferDate':
        this.setState({ transferNote: { ...transferNote, transferDate: value }, depositDateStatus: this.getValidationStatus('transferDate', value) });
        break;
      case 'transferredBy':
        this.setState({ transferNote: { ...transferNote, transferredBy: value }, transferredByStatus: this.getValidationStatus('transferredBy', value) });
        break;
      case 'verificationRemarks':
        let remarksList = [...this.state.transferNote.remarksList];
        const verificationRemarksObj = {
          type: 'VERIFICATION',
          title: '',
          user: userId,
          time: new Date(),
          message: value
        };
        const verificationRemakrsList = remarksList.length > 0 ? remarksList.filter(remark => (remark.type === 'VERIFICATION')) : [];
        if (verificationRemakrsList.length > 0) {
          remarksList = remarksList.map(remark => (remark.type === 'VERIFICATION' ? Object.assign({}, remark, { message: value }) : remark));
        } else {
          remarksList.push(verificationRemarksObj);
        }
        console.log(remarksList);
        this.setState({ transferNote: { ...transferNote, remarksList: remarksList } });
        break;
      case 'transferReferenceNumber':
        this.setState({ transferNote: { ...transferNote, transferReferenceNumber: value }, referenceNumberStatus: this.getValidationStatus('transferReferenceNumber', value) });
        break;
      default:
        console.log('No change');
    }
  }

  handleDateRangeFilterChange = date => {
    const queryData = {
      ...this.state.query,
      fromDate: date[0].startOf('day'),
      toDate: date[1].endOf('day')
    };

    this.setState({ query: queryData });
  }

  handleClientFilterChange = value => {
    const queryData = {
      ...this.state.query,
      clientId: value
    };

    const bankAccountDetail = this.getClientBankAccountDetails(value);

    this.setState({
      transferNote: {
        ...this.state.transferNote,
        bank: bankAccountDetail.bank,
        bankAccountNumber: bankAccountDetail.bankAccountNumber,
        bankAccountHolderName: bankAccountDetail.bankAccountHolderName,
        bankCode: bankAccountDetail.bankCode,
        bankBranch: bankAccountDetail.bankBranch,
        bankBranchCode: bankAccountDetail.bankBranchCode,
        amount: 0,
        orders: []
      },
      bankStatus: this.getValidationStatus('bank', bankAccountDetail.bank),
      accountNoStatus: this.getValidationStatus('bankAccountNumber', bankAccountDetail.bankAccountNumber),
      verifiedClientBankDetails: bankAccountDetail.verifiedClientBankDetails,
      query: queryData
    });
  }

  getClientBankAccountDetails = (clientId) => {
    const { clients } = this.props;
    const client = clients.find(client => client.userIdentity === clientId);

    let bankAccountDetail = client && client.businesses[0].bankAccountDetail;
    bankAccountDetail = bankAccountDetail != null ? bankAccountDetail : undefined;

    return {
      bank: bankAccountDetail && bankAccountDetail.bankName,
      bankAccountNumber: bankAccountDetail && bankAccountDetail.accountNumber,
      bankAccountHolderName: bankAccountDetail && bankAccountDetail.accountName,
      bankCode: bankAccountDetail && bankAccountDetail.bankCode,
      bankBranch: bankAccountDetail && bankAccountDetail.branchName,
      bankBranchCode: bankAccountDetail && bankAccountDetail.branchCode,
      verifiedClientBankDetails: bankAccountDetail && bankAccountDetail.verified
    };
  }

  onTableSelection = (orderIds, orders) => {
    let transferAmount = 0;
    orders && orders.length > 0 && orders.forEach(order => {
      transferAmount = transferAmount + (order.total - (order.commission ? order.commission.value : 0));
    });

    this.setState({
      transferNote: {
        ...this.state.transferNote,
        amount: transferAmount,
        orders
      },
      selectedRowKeys: orderIds,
      selectedRows: orders
    });
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'transferDate':
        return this.isValidDepositDate(value) ? 'success' : 'error';
      case 'bank':
        return this.isValidBank(value) ? 'success' : 'error';
      case 'bankAccountNumber':
        return this.isValidAccountNo(value) ? 'success' : 'error';
      case 'transferredBy':
        return this.isValidDepositedBy(value) ? 'success' : 'error';
      case 'transferReferenceNumber':
        return this.isValidReferenceNo(value) ? 'success' : 'error';
      default:
        return null;
    }
  }

  validateForm = () => {
    const { transferNote } = this.state;
    const { transferDate, bank, bankAccountNumber, transferredBy, transferReferenceNumber } = transferNote;
    const validForm = this.isValidAccountNo(bankAccountNumber) && this.isValidBank('' + bank) && this.isValidDepositDate(transferDate) && this.isValidDepositedBy(transferredBy);

    if (!validForm) {
      this.setState({
        bankStatus: this.getValidationStatus('bank', bank),
        depositDateStatus: this.getValidationStatus('transferDate', transferDate),
        transferredByStatus: this.getValidationStatus('transferredBy', transferredBy),
        accountNoStatus: this.getValidationStatus('bankAccountNumber', bankAccountNumber),
        referenceNumberStatus: this.getValidationStatus('transferReferenceNumber', transferReferenceNumber)
      });
    }

    return validForm;
  }

  isValidDepositDate = transferDate => transferDate && transferDate !== null;
  isValidBank = bank => bank && bank.length > 0;
  isValidAccountNo = bankAccountNumber => bankAccountNumber && bankAccountNumber.length > 0;
  isValidDepositedBy = transferredBy => transferredBy && transferredBy.length > 0;
  isValidReferenceNo = referenceNo => referenceNo && referenceNo.length > 0;

  handleSubmitTransferNote = () => {
    const { transferNote, verifiedClientBankDetails, query } = this.state;
    const { addTransferNote } = this.props;
    if (!this.validateForm()) return;

    if (!verifiedClientBankDetails) {
      Modal.error({
        title: 'Warning',
        content: 'This client bank details are not verified. Please contact and verify bank detail before do this transaction.'
      });
      return;
    }

    this.setState({ submitting: true });
    addTransferNote(query.clientId, transferNote).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false });
      } else {
        this.setState({ submitting: false });
        showSuccessNotification('Added.', 'Transfer note added successfully.');
        this.props.history.replace('/incypos/users/' + query.clientId + '/transferNotes/' + response.transferNoteData.transferNote.id);
        // this.props.history.goBack();
      }
    });
  }

  handleUpdateBankDeposit = () => {
    const { transferNote, verifiedClientBankDetails } = this.state;
    const { updateTransferNote, match } = this.props;
    const { companyId, transferNoteId } = match.params;

    if (!this.validateForm()) return;

    if (!verifiedClientBankDetails) {
      Modal.error({
        title: 'Warning',
        content: 'This client bank details are not verified. Please contact and verify bank detail before do this transaction.'
      });
      return;
    }

    this.setState({ submitting: true });
    updateTransferNote(companyId, transferNoteId, transferNote).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false });
      } else {
        this.setState({ submitting: false });
        showSuccessNotification('Updated.', 'Transfer note updated successfully.');
      }
    });
  }

  handleConfirmTransferNote = () => {
    const { transferNote, verifiedClientBankDetails } = this.state;
    const { confirmTransferNote, match } = this.props;
    const { companyId, transferNoteId } = match.params;

    if (!this.validateForm()) return;

    if (!verifiedClientBankDetails) {
      Modal.error({
        title: 'Warning',
        content: 'This client bank details are not verified. Please contact and verify bank detail before do this transaction.'
      });
      return;
    }

    this.setState({ submitting: true });
    confirmTransferNote(companyId, transferNoteId, transferNote).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false });
      } else {
        this.setState({ submitting: false });
        showSuccessNotification('Confirmed.', 'Transfer note confirmed successfully.');
      }
    });
  }

  handleVerifyTransferNote = () => {
    const { verifyTransferNote, match, authenticity: { user: { userId } } } = this.props;
    const { transferNote } = this.state;
    const { transferNoteId } = match.params;

    this.setState({ submitting: true });
    verifyTransferNote(userId, transferNoteId, transferNote).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ submitting: false });
      } else {
        this.setState({ submitting: false });
        showSuccessNotification('Verified.', 'Transfer note verified successfully.');
      }
    });
  }

  handleOk = () => {
    const { transferNoteId } = this.props.match.params;

    this.setState({ printModalVisible: false });
    this.props.history.push('/incytute/printBankDeposit/' + transferNoteId);
  }

  handleCancel = () => {
    this.setState({ printModalVisible: false });
    this.props.history.push('/incytute/bankDepositSummary');
  };

  getClientOptions = () => {
    const { clients } = this.props;
    const options = [];

    clients && clients.length > 0 && clients.forEach(client => options.push(<Option key={client.userIdentity} value={client.userIdentity}>{Object.values(client.businesses)[0].name + ' - ' + client.email}</Option>));
    return options;
  }

  getFilteredOrders = () => {
    const { orders } = this.props;
    return orders;
  }

  render() {
    const {
      expandedForm = ['2'],
      loading,
      transferNote,
      submitting,
      depositDateStatus,
      bankStatus,
      accountNoStatus,
      transferredByStatus,
      amountStatus,
      referenceNumberStatus,
      printModalVisible,
      selectedRowKeys,
      selectedRows
    } = this.state;
    const { transferNoteId } = this.props.match.params;
    const filteredOrders = this.getFilteredOrders();

    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    // const { remarksList } = transferNote

    return (

      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Col>
                <PageHeader style={{ width: '100%', margin: 5 }} title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><h3 style={{ fontWeight: 400, marginLeft: 0, marginTop: 0, marginBottom: 0, marginRight: 5 }}>Transfer Note {transferNote && transferNote.number && '(No: ' + transferNote.number + ')'}</h3> <Tag style={{ height: 25 }} color={transferNote && transferNote.status && transferNote.status !== 'PENDING' && '#25a023'}>{transferNote && transferNote.status ? transferNote.status : 'PENDING'}</Tag></div>} extra={<Button style={{ width: '100%' }} type='primary' size='small' onClick={() => this.props.history.goBack()}>Exit</Button>} />
              </Col>
              <Col>
                <Modal
                  visible={printModalVisible}
                  okText='Print'
                  cancelText='Cancel'
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  centered
                  maskClosable={false}
                >
                  <h4>Do you want to print the deposit?</h4>
                </Modal>
                <Spin spinning={false}>
                  <Col lg={18} md={18} sm={24} xs={24}>
                    <Row>
                      {/* <Card style={{ margin: 5 }}><Summary transferNote={transferNote} banks={banks} /></Card> */}
                      <Card style={{ margin: 5 }}>
                        {/* <Row gutter={10} style={{ marginBottom: 10 }}>
                   <Col span={10}>
                    <Select
                      disabled={transferNoteId && transferNoteId !== "new"}
                      showSearch
                      dropdownMatchSelectWidth={true}
                      style={{ width: '100%' }}
                      placeholder="Select Client"
                      value={query.clientId}
                      onChange={(value) => this.handleClientFilterChange(value)}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.getClientOptions()}
                    </Select>
                  </Col>
                  <Col span={10}>
                    <RangePicker
                      // onPressEnter={onClickSearch}
                      style={{ width: '100%' }}
                      allowClear={false}
                      defaultValue={query && [moment(query.fromDate), moment(query.toDate)]}
                      format="YYYY-MM-DD"
                      onChange={this.handleDateRangeFilterChange}
                    />
                  </Col>
                  <Col span={4}>
                    <Button type={'primary'} size={'small'} block icon={'search'} onClick={() => this.handleFetchOrders()}>Search</Button>
                  </Col>
                </Row> */}
                        <Col span={24}>
                          <PayoutList
                            business={business}
                            orders={filteredOrders.length > 0 ? filteredOrders : []}
                            loading={loading}
                            selectedRowKeys={selectedRowKeys}
                            selectedRows={selectedRows}
                            onRowSelection={this.onTableSelection}
                            enableRowSelection={(transferNote && transferNote.status === 'PENDING') || transferNoteId === 'new'}
                          />
                        </Col>
                      </Card>
                    </Row>
                  </Col>
                  <Col lg={6} md={6} sm={24} xs={24}>
                    <div style={{ margin: 5, border: '1px solid #e8e8e8' }}>
                      <Collapse
                        bordered={false}
                        defaultActiveKey={[2]}
                        activeKey={expandedForm}
                        onChange={(key) => this.onFormExpand(key)}
                        expandIcon={({ isActive }) => <Icon type='caret-right' rotate={isActive ? 90 : 0} />}
                      >
                        <Panel header={<h5 style={{ fontWeight: 300 }}>{(transferNote && transferNoteId !== 'new') ? (transferNote.status === 'PEDNING' ? 'Edit' : '') : 'Create'} Transfer Note</h5>} key='1'>
                          <TransferNoteForm
                            business={business}
                            transferNoteId={transferNoteId}
                            transferNote={transferNote}
                            onSubmitTransferNote={this.handleSubmitTransferNote}
                            submitting={submitting}
                            onUpdateBankDeposit={this.handleUpdateBankDeposit}
                            handleOnChange={this.handleOnChange}
                            depositDateStatus={depositDateStatus}
                            bankStatus={bankStatus}
                            accountNoStatus={accountNoStatus}
                            transferredByStatus={transferredByStatus}
                            amountStatus={amountStatus}
                          />
                        </Panel>
                        {transferNoteId && transferNoteId !== 'new' && transferNote &&
                          <Panel header={<h5 style={{ fontWeight: 300 }}>{transferNote.status === 'PEDNING' ? 'Add' : ''} Bank Deposit</h5>} key='2'>
                            <ConfirmTransferNoteForm
                              business={business}
                              transferNote={transferNote}
                              onConfirmTransferNote={this.handleConfirmTransferNote}
                              submitting={submitting}
                              handleOnChange={this.handleOnChange}
                              referenceNumberStatus={referenceNumberStatus}
                              amountStatus={amountStatus}
                            />
                          </Panel>}

                      </Collapse>
                    </div>
                    {transferNoteId && transferNoteId !== 'new' && transferNote && transferNote.status === 'CONFIRMED' &&
                      <Card title={<h6 style={{ fontWeight: 300 }}>Verify Transfer Note</h6>} style={{ marginTop: 10 }}>
                        <Form.Item label='Remarks'>
                          <div>
                            <TextArea
                              rows={5}
                              // value={completedRemarks}
                              onChange={(event) => this.handleOnChange('verificationRemarks', event.target.value)}
                            />
                          </div>
                        </Form.Item>
                        <div style={{ textAlign: 'right' }}>
                          <Button style={{ marginRight: 5 }} onClick={() => this.handleVerifyTransferNote()} size='small' type='primary'>VERIFY</Button>
                        </div>
                      </Card>}
                  </Col>
                </Spin>
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { transferNoteId } = props.match.params;
  const transferNote = getTransferNoteById(state, transferNoteId);
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  console.log(transferNote.orders);
  // const orders = [...transferNoteOrders].filter((order, index, array) => {
  //   return array.map(innerOrder => innerOrder.id).indexOf(order.id) === index;
  // })

  // console.log(orders)

  return {
    orders: transferNote.orders && transferNote.orders.length > 0 ? transferNote.orders : [],
    transferNote,
    authenticity: getAuthenticity(state),
    company: getCompanyById(state, companyId),
  };
};

export default withRouter(connect(mapStateToProps, {
  fetchTransferNote,
  verifyTransferNote
})(TranferNoteView));
