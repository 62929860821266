import React, { Component } from 'react';
import { Col, Form, Input, Button, DatePicker, Row, Select } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

class AdvancedSearchForm extends Component {
  constructor () {
    super();
    this.state = {
      formLayout: 'horizontal'
    };
  }

  render () {
    const { query, onDateRangeChange, onInputTextChange, onClickSearch, onStatusSelectionChange } = this.props;

    return (
      <Form layout='vertical'>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Input placeholder='Transfer Note No/Reference No' onPressEnter={onClickSearch} onChange={(e) => onInputTextChange(e, 'referenceNoFilter')} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Input placeholder='Search by bank details' onPressEnter={onClickSearch} onChange={(e) => onInputTextChange(e, 'bankAccountFilter')} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <RangePicker
                  allowClear={false}
                  onPressEnter={onClickSearch}
                  defaultValue={[moment(query && query.fromDate), moment(query && query.toDate)]}
                  format='YYYY-MM-DD'
                  style={{ width: '100%' }}
                  onChange={onDateRangeChange}
                />
              </Form.Item>
            </Col>
            <Col lg={16} md={16} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ width: '100%', marginBottom: 5 }} allowClear
                  onChange={onStatusSelectionChange} mode='multiple'
                  placeholder='Status'
                >
                  <Option value='PENDING'>
                    {'PENDING'}
                  </Option>
                  <Option value='CONFIRMED'>
                    {'CONFIRMED'}
                  </Option>
                  <Option value='VERIFIED'>
                    {'VERIFIED'}
                  </Option>
                  <Option value='COMPLETED'>
                    {'COMPLETED'}
                  </Option>

                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Button block className='primary-button' type='primary' onClick={() => onClickSearch()}>Search</Button>
            </Col>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default AdvancedSearchForm;
