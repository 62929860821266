import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Space, Icon } from 'antd';
import { changePassword } from '../../../actions/auth'
import { getAuthenticity } from '../../../reducers/auth';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
const sha256 = require('js-sha256');

class PasswordReset extends Component {
    state = {
        submitting: false,
        newPassword: undefined,
        newPasswordStatus: undefined,
        confirmPassword: undefined,
        confirmPasswordStatus: undefined,
    }

    showLabel = value => (value && value.length) > 0 ? 'visible' : 'hidden';

    handleOnChange = (type, value) => {
        switch (type) {
            case 'New Password':
                this.setState({ newPassword : value, newPasswordStatus : this.getValidationStatus(type, value)})
                break;

            case "Confirm Password":
                this.setState({ confirmPassword:value, confirmPasswordStatus : this.getValidationStatus(type, value)})
                break;

            default:
                return null;
        }    
    }

    getValidationStatus = (type, value) =>{
        const { newPassword } = this.state;
        switch (type) {
            case 'New Password':
                return value && value.length >= 8  ? 'success' : 'error';
                break;

            case "Confirm Password":
                console.log("verify confirm password", value && newPassword === value);
                return value && newPassword === value  ? 'success' : 'error';
                break;

            default:
                return null;
        }
        
    }

    resetPassword = () => {
        const { authData, userId } = this.props;
        const { newPassword, confirmPassword, newPasswordStatus, confirmPasswordStatus  } = this.state;

        if (newPasswordStatus && newPasswordStatus === 'error'){
            showErrorNotification('Error', 'Password length should be minimum 8 charactors.');
            return;
        }

        if (confirmPasswordStatus && confirmPasswordStatus === 'error'){
            showErrorNotification('Error', 'Confirming password should be equal to the new password.');
            return;
        }

       
        this.setState({ submitting: true });
        this.props.changePassword({ newPassword : sha256(newPassword), confirmPassword : sha256(confirmPassword), userId : userId }).then(response => {
            const { result, error } = response;
            if (result) {
                this.setState({ submitting: false });
                showSuccessNotification('Success', 'Reset password');
                this.props.handleSubmit('Reset Password', result);
            } else {
                this.setState({ submitting: false });
                showErrorNotification('Password reset Failed.', error);
            }
        });
    }

    handleBackPress = () => {
        this.props.handleBackPress('Reset Password');
    }

    render() {
        const {submitting, newPassword, confirmPassword, newPasswordStatus, confirmPasswordStatus} = this.state;

        return(
            <div className='text-center'>
                <h2 className='signUp-form-title'>Create new password </h2>
                <p className='signUp-form-description'>Enter your new password for reset password.</p>
                <br/>
                <Form className='signUp-form'>
                    <Form.Item colon={false} validateStatus={newPasswordStatus} label={<span style={{ visibility: this.showLabel(newPassword) }} className='incyInputLabel'>New Password</span>}>
                        <Input size='middle' type='password' placeholder='New password' onChange={e => this.handleOnChange('New Password', e.target.value)} />
                    </Form.Item>
                    <Form.Item colon={false} validateStatus={confirmPasswordStatus} label={<span style={{ visibility: this.showLabel(confirmPassword) }} className='incyInputLabel'>Confirm Password</span>}>
                        <Input size='middle' type='password' placeholder='Confirm Password' onChange={e => this.handleOnChange('Confirm Password', e.target.value)} />
                    </Form.Item>
                    <div className='isoInputWrapper'>
                        <Button type='primary' size='middle' onClick={() => this.resetPassword()} loading={this.state.submitting}>
                            Save
                        </Button>
                        <Button style={{ marginLeft: 10 }} size='middle' onClick={() => this.handleBackPress()} loading={this.state.submitting}>
                            Exit
                        </Button>
                    </div>  
                </Form>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return { authData: getAuthenticity};
}

export default connect(mapStateToProps, {changePassword})(PasswordReset);