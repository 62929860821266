import { getAll, get, insertCategory, modifyCategory, deleteCategory, insertItem, deleteItem } from '../api/categories';

export const fetchCategories = companyId => dispatch => {
  return getAll(companyId).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_CATEGORIES', categories: response.categories });
      dispatch({ type: 'FETCH_CATEGORY_TREE', categories: response.categories });
    }
    return response;
  });
};

export const fetchCategory = (companyId, id) => dispatch => {
  dispatch({ type: 'REQUEST_CATEGORY', categoryId: id });
  return get(companyId, id).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_CATEGORY', category: response.category });
      // dispatch({ type: 'FETCH_CATEGORIES', categories: response.category.categories });
      // dispatch({ type: 'FETCH_ITEMS_BY_CATEGORY', items: response.category.items, categoryId: response.category.id });
    //  dispatch({ type: 'SUCCEED_FETCH_ITEMS', items: response.category.items });
    }
    return response;
  });
};

export const addCategory = (companyId, parentId, data) => dispatch => {
  return insertCategory(companyId, parentId, data).then(response => {
    if (!response.error) dispatch({ type: 'INSERT_CATEGORY', category: response.category });
    return response;
  });
};

export const addItemToCategory = (companyId, parentId, data) => dispatch => {
  return insertItem(companyId, parentId, data).then(response => {
    if (!response.error) dispatch({ type: 'INSERT_CATEGORY', category: response.category });
    return response;
  });
};

export const updateCategory = (companyId, categoryId, data) => dispatch => {
  return modifyCategory(companyId, categoryId, data).then(response => {
    if (!response.error) dispatch({ type: 'INSERT_CATEGORY', category: response.category });
    return response;
  });
};

export const removeCategory = (companyId, categoryId) => dispatch => {
  return deleteCategory(companyId, categoryId).then(response => {
    if (!response.error) dispatch({ type: 'DELETE_CATEGORY', category: response.category });
    return response;
  });
};

export const removeItemFromCategory = (companyId, categoryId, data) => dispatch => {
  return deleteItem(companyId, categoryId, data).then(response => {
    return response;
  });
};
