import React, { Component } from 'react';
import { Row, Spin, Table, Input, Card, Col, DatePicker, Modal, Select, Menu, Dropdown, Tag, Checkbox } from 'antd';

import { connect } from 'react-redux';
import { fetchCompany } from '../../actions/companies';
import { getCompanyById, getOrders, getAuthenticity } from '../../reducers';
import { fetchOrders, updateStatus } from '../../actions/Orders';
import { showLoadingMessage } from '../../components/Message';
import moment from 'moment';
import withRouter from 'react-router/es/withRouter';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import Api from '../../api/consts';
import { showSuccessNotification } from '../../components/Notification';
const dateFormat = 'DD-MM-YYYY HH:mm ';
const { confirm } = Modal;
const { Option } = Select;
const { Search } = Input;

class OrderList extends Component {
  state = {
    loading: false,
    loadingCount: false,
    textFilter: undefined,
    expiryDate: undefined,
    usingKds: false,
    usingCheckoutCounter: false,
    filter: {
      text: undefined,
      method: '',
      from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
      to: moment(new Date()).endOf('day')
    },
    sortedInfo: null,
    action: undefined
  }

  componentDidMount = () => {
    const { company, companyId } = this.props;
    this.setState({ loading: true });
    this.setState({ usingKds: company.businesses[0].usingKds, usingCheckoutCounter: company.businesses[0].usingCheckoutCounter });
    this.props.fetchOrders(companyId).then(() => {
      this.setState({ loading: false });
    });
  }

  getMenu = (record) => {
    const { usingCheckoutCounter, usingKds } = this.state;
    return (
      <Menu onClick={(e) => this.handleMenuItemClick(e, record)}>
        {!usingKds && record.status !== 'READY' && <Menu.Item key='ready'>Ready</Menu.Item>}
        {!usingCheckoutCounter && record.status !== 'COMPLETED' && <Menu.Item key='dispatched'>Dispatched</Menu.Item>}
        {!usingCheckoutCounter && !usingKds && record.status !== 'CANCELLED' && <Menu.Item key='cancel'>Cancel</Menu.Item>}
      </Menu>

    );
  }

  getColumns = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    console.log(business)
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    return [
      {
        title: 'ID',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        sorter: (a, b) => a.orderNumber.length - b.orderNumber.length,
        sortOrder: sortedInfo.columnKey === 'orderNumber' && sortedInfo.order,
        render: text => <p>{text}</p>
      },
      {
        title: 'Order Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        sortOrder: sortedInfo.columnKey === 'createdDate' && sortedInfo.order,
        render: text => <p>{moment(text).format(dateFormat)}</p>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: text => <Tag color='#108ee9'>{text}</Tag>
      },
      {
        title: 'Order Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: text => <p>{text}</p>
      },
      {
        title: 'Customer Name',
        dataIndex: 'customer',
        key: 'customer',
        render: (customer) => <p>{customer && customer.name}</p>
      },
      {
        title: 'Total' + this.getTotal,
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        sorter: (a, b) => a.total - b.total,
        sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
        render: total => <p>{total && format(total, currencyFormat(business))}</p>,
        ellipsis: true
      },
      {
        title: 'Balance To Settle',
        dataIndex: 'balanceToSettle',
        key: 'balanceToSettle',
        align: 'right',
        sorter: (a, b) => a.balanceToSettle - b.balanceToSettle,
        sortOrder: sortedInfo.columnKey === 'balanceToSettle' && sortedInfo.order,
        render: balanceToSettle => <p>{balanceToSettle && format(balanceToSettle, currencyFormat(business))}</p>,
        ellipsis: true
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, record) => (
          <span className='table-operation'>
            <Dropdown overlay={this.getMenu(record)}>
              <p>
                More
              </p>
            </Dropdown>
          </span>
        )
      }
    ];
  }

  getTotal = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const filterdOrdersList = this.getFilteredOrders();
    let total = 0;
    filterdOrdersList.forEach(order => {
      total += order.total;
    });
    console.log(total);
    return format(total, currencyFormat(business));
  }

  handleTableChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };

  expandedRowRender = (order) => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const columns = [
      { title: 'Item Name', dataIndex: 'name', key: 'name' },
      { title: 'Status', dataIndex: 'status', key: 'status', align: 'center', render: text => <Tag color='#108ee9'>{text}</Tag> },
      { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: 'right' },
      { title: 'Price', dataIndex: 'price', key: 'price', align: 'right' },
      { title: 'Total', dataIndex: 'total', key: 'total', align: 'right' }
    ];

    var cardTitle = 'Item Details of Order ' + order.orderNumber;
    const items = [];
    order.items && Object.keys(order.items).forEach(key => { items.push(order.items[key]); });
    return (
      <Card style={{ margin: 5 }} title={cardTitle}>

        <Row gutter={5}>
          <Col lg={24} style={{ marginBottom: 15 }}>
            <Table columns={columns} dataSource={items} pagination={false} />
          </Col>
        </Row>
        <Row gutter={5}>
          <Col md={6} sm={6} xs={6} lg={6}>
            <h3 style={{ fontWeight: 300 }}>Balance to Settle</h3>
          </Col>
          <Col md={2} sm={2} xs={2} lg={2} style={{ textAlign: 'right' }}>
            <h3 style={{ fontWeight: 300 }}>{format(order.balanceToSettle, currencyFormat(business))}</h3>
          </Col>
          <Col md={6} sm={6} xs={6} lg={6} />
          <Col md={7} sm={7} xs={7} lg={7}>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>Subtotal</h3>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>Total Tax ({format(order.taxPercentage, currencyFormat(business))} %)</h3>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>Total Service Charge ({format(order.serviceChargePercentage, currencyFormat(business))} %)</h3>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>Total Discount</h3>
            <p style={{ fontSize: 20, fontWeight: 300, marginBottom: 5 }}><b>Total</b></p>
          </Col>
          <Col md={3} sm={3} xs={3} lg={3} style={{ textAlign: 'right' }}>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>{format(order.subtotal, currencyFormat(business))}</h3>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>{format(order.tax, currencyFormat(business))}</h3>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>{format(order.serviceCharge, currencyFormat(business))} </h3>
            <h3 style={{ fontWeight: 300, marginBottom: 5 }}>{format(order.discount, currencyFormat(business))} </h3>
            <p style={{ fontSize: 20, fontWeight: 300, marginBottom: 5 }}><b>{format(order.total, currencyFormat(business))}</b></p>
          </Col>
        </Row>
      </Card>
    );
  }

  onDateChange = (date, dateString) => this.setState({ expiryDate: moment(date).toDate() })
  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })
  handleInputCheckChange = (e) => {
    if (e.target.checked) {
      this.setState({ filter: { ...this.state.filter, method: 'WEB' } });
    } else {
      this.setState({ filter: { ...this.state.filter, method: '' } });
    }
  }

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })
  handleMenuItemClick = (e, record) => {
    var action = undefined;
    if (e.key === 'ready') {
      action = 'READY';
    } else if (e.key === 'dispatched') {
      action = 'DISPATCHED';
    } else if (e.key === 'cancel') {
      action = 'CANCELLED';
    }
    this.showConfirmUpdateOrder(record, action);
  }

  showConfirmUpdateOrder = (record, action) => {
    // if(record.status === "COMPETED" && (action === "DISPATCHED" || action === "READY" || action === "CANCELLED")){
    //   showWarningNotification("Failed", "Order cannot be Completed!");
    //   return
    // }else if(record.status === "REDAY" && action === "READY"){
    //   return
    // }else if(record.status === "CANCELLED" && (action === "CANCELLED"||action === "DISPATCHED")){
    //   return
    // }
    const THIS = this;
    confirm({
      title: 'Do you want to ' + action.toLowerCase() + ' this order?',
      content: '',
      onOk() {
        THIS.upateOrderStatus(record, action);
      },
      onCancel() { }
    });
  }

  upateOrderStatus = (record, action) => {
    const { companyId } = this.props;
    // call API
    this.setState({ loading: true });
    this.props.updateStatus(companyId, record.id, { action: action }).then(response => {
      this.setState({ loading: false });
      if (response.order) {
        if (response.order.customer) {
          const { email, telephone } = response.order.customer;
          this.handleSMS(telephone, 'Your order ' + record.orderNumber + ' has been ' + action.toLowerCase() + '.');
          this.handleEmail(email, 'Your order ' + record.orderNumber + ' has been ' + action.toLowerCase() + '.');
        }
        showSuccessNotification('Success', 'Successfully updated.');
      }
    }).catch(error => {
      this.setState({ loading: false });
    });
  }

  handleSMS = (phone, message) => {
    const { url, method, headers } = Api.notification;
    if (!phone) return;
    phone = phone.replace(/ /g, '');
    phone = phone.replace('+', '');
    const obj = JSON.stringify({
      type: 'sms',
      route: 'nexmo',
      from: 'Incy',
      to: phone,
      mi: 'text',
      message: message
    });

    const data = { method, headers, body: obj };
    return fetch(url(), data)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'ok') {
          // showSuccessNotification('Success', "SMS sent.");
        } else {
          // showErrorNotification('Failed.', "SMS failed");
        }
      }).catch(error => {
        return { error };
      });
  }

  handleEmail = (email, message) => {
    if (!email) return;
    const url = 'https://incylabs.com/api/admin/signup';
    // const url = 'http://localhost:3002/api/incytute/idm/forgotPassword123';
    const method = 'POST';
    const headers = {};
    const body = JSON.stringify({ otp: message, email: email });
    const emailData = { method, headers, body };

    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    headers.origin = 'https://admin.incypos.com';
    emailData.headers = headers;

    fetch(url, emailData)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson && responseJson.accepted && responseJson.accepted.length > 0) {
          // showSuccessNotification('Success', "EMAIL sent.");
        }
      }).catch(error => {
        return { error };
      });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Orders.');
  }

  getFilteredOrders = () => {
    const { orders } = this.props;
    const { filter } = this.state;

    let filteredOrderList = orders;

    if (filter && filter.text) {
      filteredOrderList = orders.filter(order => {
        let searchTerm = filter.text;
        searchTerm = searchTerm.replace(/-/g, '');

        return order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.type.toLowerCase().includes((filter.text).toLowerCase()) ||
          ((order.customer) && order.customer.name.toLowerCase().replace(/\W/g, '').includes((filter.text.replace(/\W/g, '')).toLowerCase())) ||
          order.status.toLowerCase().includes((filter.text).toLowerCase()) ||
          (order.method && order.method.toLowerCase().includes((filter.text).toLowerCase()));
      });
    }

    if (filter && filter.method) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (order.method && order.method.toLowerCase().match((filter.method).toLowerCase()));
      });
    }

    if (filter.from) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (order.createdDate && moment(order.createdDate).valueOf() > filter.from.valueOf());
      });
    }

    if (filter.to) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (order.createdDate && moment(order.createdDate).valueOf() < filter.to.valueOf());
      });
    }

    return filteredOrderList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  render() {
    const { loading, filter } = this.state;
    const { orders, company } = this.props;
    const filterdOrdersList = this.getFilteredOrders();
    const business = company && company.businesses && company.businesses['0'];
    console.log(business);
    return (
      <Card style={{ margin: 5 }}>

        <Row gutter={10}>

          <Col lg={5} md={5} sm={24} xs={24}>
            <Search style={{ marginBottom: 10 }} onChange={(e) => this.handleInputTextChange(e.target.value)} />
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}>
            <Select
              style={{ width: '100%', marginBottom: 5 }} placeholder='Status'
              onChange={(value) => this.handleInputTextChange(value)}
            >
              <Option value='NEW'>NEW</Option>
              <Option value='CONFIRMED'>CONFIRMED</Option>
              <Option value='READY'>READY</Option>
              <Option value='PREPARING'>PREPARING</Option>
              <Option value='COMPLETED'>COMPLETED</Option>
              <Option value='CANCELLED'>CANCELLED</Option>
            </Select>
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}>
            <DatePicker
              format={dateFormat}
              value={filter.from}
              placeholder='From'
              onChange={(date, dateString) => this.handleInputDateChange('from', date)}
              style={{ width: '100%', marginBottom: 5 }}
            />
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}>
            <DatePicker
              format={dateFormat}
              value={filter.to}
              placeholder='To'
              onChange={(date, dateString) => this.handleInputDateChange('to', date)}
              style={{ width: '100%' }}
            />
          </Col>

          <Col lg={4} md={4} sm={24} xs={24}>
            <Checkbox value='WEB' onChange={(e) => this.handleInputCheckChange(e)}>Online Orders only</Checkbox>
          </Col>
        </Row>

        <Row style={{ marginTop: 50 }}>
          <Spin spinning={loading && orders}>
            <Table
              columns={this.getColumns()}
              expandedRowRender={record => this.expandedRowRender(record)}
              dataSource={filterdOrdersList}
              onChange={this.handleTableChange}
            />

          </Spin>
        </Row>
      </Card>

    );
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return ({ company: getCompanyById(state, companyId), orders: getOrders(state), authData });
};

export default withRouter(connect(mapStateToProps, { fetchCompany, fetchOrders, updateStatus })(OrderList));
