import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_INVOICES':
    case 'GENERATE_INVOICES_FOR_SUBSCRIPTIONS': {
      const newState = {};
      action.invoices && action.invoices.length > 0 && action.invoices.forEach(invoice => { newState[invoice.id] = { ...invoice, loading: false }; });

      return { ...state, ...newState };
    }
    case 'DELETE_INVOICE': {
      const newState = { ...state };
      newState[action.deletedInvoice.id] = action.deletedInvoice;
      return newState;
    }
    case 'FETCH_INVOICE': {
      state[action.invoice.id] = action.invoice;
      return state;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_INVOICES':
    case 'GENERATE_INVOICES_FOR_SUBSCRIPTIONS': {
      const newState = [...state];
      action.invoices && action.invoices.length > 0 && action.invoices.forEach(invoice => {
        if (!newState.includes(invoice.id)) {
          newState.push(invoice.id);
        }
      });
      return newState;
    }
    case 'DELETE_RECEIPT': {
      return state;
    }
    case 'FETCH_INVOICE': {
      const newState = [...state];
      if (!newState.includes(action.invoice.id)) {
        newState.push(action.invoice.id);
      }
      return newState;
    }
    default:
      return state;
  }
};

const byCustomer = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_INVOICES':
    case 'GENERATE_INVOICES_FOR_SUBSCRIPTIONS': {
      const newState = { ...state };
      action.invoices && action.invoices.length > 0 && action.invoices.forEach(invoice => {
        const idsByCustomer = newState[invoice.customerId] || [];
        if (!idsByCustomer.includes(invoice.id)) {
          idsByCustomer.push(invoice.id);
        }
        newState[invoice.customerId] = idsByCustomer;
      });
      return newState;
    }
    default:
      return state;
  }
};

const invoices = combineReducers({
  byId,
  ids,
  byCustomer
});

export default invoices;

export const getInvoices = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getInvoicesForCustomer = (state, customerId) => {
  return state && state.byCustomer[customerId] && state.byCustomer[customerId].map(id => state.byId[id]);
};

export const getPrintInvoice = (state, id) => {
  return state && state.byId && state.byId[id];
};
