import { combineReducers } from 'redux';
import moment from 'moment';


const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_WAREHOUSES': {
      const newState = {};
      action.warehouses && action.warehouses.forEach(warehouse => { newState[warehouse.id] = warehouse; });
      return newState;
    }
    // case 'DELETE_CUSTOMER': {
    //   const newState = {};
    //   action.customers && action.customers.forEach(customer => { newState[customer.id] = customer; });
    //   return newState;
    // }
    // case 'FETCH_CUSTOMER': {
    //   return { ...state, [action.customer.id]: action.customer };
    // }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_WAREHOUSES': {
      const newState = [];
      action.warehouses && action.warehouses.forEach(warehouse => {
        if (!newState.includes(warehouse.id)) {
          newState.push(warehouse.id);
        }
      });
      return newState;
    }

    // case 'DELETE_CUSTOMER': {
    //   const newState = [];
    //   action.customers && action.customers.forEach(customer => {
    //     if (!newState.includes(customer.id)) {
    //       newState.push(customer.id);
    //     }
    //   });
    //   console.log(newState);
    //   return newState;
    // }
    // case 'FETCH_CUSTOMER': {
    //   if (state.includes(action.customer.id)) {
    //     return state;
    //   } else {
    //     return [...state, action.customer.id];
    //   }
    // }
    default:
      return state;
  }
};

 

const warehouses = combineReducers({
  byId,
  ids
});
export default warehouses;

export const getWareshouses = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getWareshouseById = (state, warehouseId)  => {
  return state && state.byId && state.byId[warehouseId];
};

export const getLocationsByWarehouseId = (state, warehouseId) => {
  const warehouse = state && state.byId && state.byId[warehouseId];
  if(warehouse) {
    return warehouse.locations
  }else {
    return []
  }
};
