export const bankList = [
  {
    BankCode: '7010',
    BankName: 'Bank of Ceylon'
  },
  {
    BankCode: '7038',
    BankName: 'Standard Chartered Bank'
  },
  {
    BankCode: '7047',
    BankName: 'Citi Bank'
  },
  {
    BankCode: '7056',
    BankName: 'Commercial Bank PLC'
  },
  {
    BankCode: '7074',
    BankName: 'Habib Bank Ltd'
  },
  {
    BankCode: '7083',
    BankName: 'Hatton National Bank PLC'
  },
  {
    BankCode: '7092',
    BankName: "Hongkong's Shanghai Bank"
  },
  {
    BankCode: '7108',
    BankName: 'Indian Bank'
  },
  {
    BankCode: '7117',
    BankName: 'Indian Overseas Bank'
  },
  {
    BankCode: '7135',
    BankName: 'Peoples Bank'
  },
  {
    BankCode: '7144',
    BankName: 'State Bank of India'
  },
  {
    BankCode: '7162',
    BankName: 'Nations Trust Bank PLC'
  },
  {
    BankCode: '7205',
    BankName: 'Deutsche Bank'
  },
  {
    BankCode: '7214',
    BankName: 'National Development Bank PLC'
  },
  {
    BankCode: '7269',
    BankName: 'MCB Bank Ltd'
  },
  {
    BankCode: '7278',
    BankName: 'Sampath Bank PLC'
  },
  {
    BankCode: '7287',
    BankName: 'Seylan Bank PLC'
  },
  {
    BankCode: '7296',
    BankName: 'Public Bank'
  },
  {
    BankCode: '7302',
    BankName: 'Union Bank of Colombo PLC'
  },
  {
    BankCode: '7311',
    BankName: 'Pan Asia Banking Corporation PLC'
  },
  {
    BankCode: '7384',
    BankName: 'ICICI Bank Ltd'
  },
  {
    BankCode: '7454',
    BankName: 'DFCC Bank PLC'
  },
  {
    BankCode: '7463',
    BankName: 'Amana Bank PLC'
  },
  {
    BankCode: '7472',
    BankName: 'Axis Bank'
  },
  {
    BankCode: '7481',
    BankName: 'Cargills Bank Limited'
  },
  {
    BankCode: '7719',
    BankName: 'National Savings Bank'
  },
  {
    BankCode: '7728',
    BankName: 'Sanasa Development Bank'
  },
  {
    BankCode: '7737',
    BankName: 'HDFC Bank'
  },
  {
    BankCode: '7746',
    BankName: 'Citizen Development Business Finance PLC'
  },
  {
    BankCode: '7755',
    BankName: 'Regional Development Bank'
  },
  {
    BankCode: '7764',
    BankName: 'State Mortgage & Investment Bank'
  },
  {
    BankCode: '7773',
    BankName: 'LB Finance PLC'
  },
  {
    BankCode: '7782',
    BankName: 'Senkadagala Finance PLC'
  },
  {
    BankCode: '7807',
    BankName: 'Commercial Leasing and Finance'
  },
  {
    BankCode: '7816',
    BankName: 'Vallibel Finance PLC'
  },
  {
    BankCode: '7825',
    BankName: 'Central Finance PLC'
  },
  {
    BankCode: '7834',
    BankName: 'Kanrich Finance Limited'
  },
  {
    BankCode: '7852',
    BankName: 'Alliance Finance Company PLC'
  },
  {
    BankCode: '7861',
    BankName: 'LOLC Finance PLC'
  },
  {
    BankCode: '7898',
    BankName: 'Merchant Bank of Sri Lanka & Finance PLC'
  },
  {
    BankCode: '7904',
    BankName: 'HNB Finance Limited'
  },
  {
    BankCode: '7913',
    BankName: 'Mercantile Investment and Finance PLC'
  },
  {
    BankCode: '7922',
    BankName: "People's Leasing & Finance PLC"
  },
  {
    BankCode: '7968',
    BankName: 'Siyapatha Finanace PLC'
  },
  {
    BankCode: '8004',
    BankName: 'Central Bank of Sri Lanka'
  }
];

export const branchList = [
  {
    BankCode: '7010',
    BranchCode: '1',
    BranchName: 'City Office'
  },
  {
    BankCode: '7010',
    BranchCode: '2',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7010',
    BranchCode: '3',
    BranchName: 'Galle Fort'
  },
  {
    BankCode: '7010',
    BranchCode: '4',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7010',
    BranchCode: '5',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7010',
    BranchCode: '6',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7010',
    BranchCode: '7',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7010',
    BranchCode: '9',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7010',
    BranchCode: '11',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7010',
    BranchCode: '12',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7010',
    BranchCode: '15',
    BranchName: 'Central Office'
  },
  {
    BankCode: '7010',
    BranchCode: '16',
    BranchName: 'Kalutara S/G'
  },
  {
    BankCode: '7010',
    BranchCode: '18',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7010',
    BranchCode: '20',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7010',
    BranchCode: '21',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7010',
    BranchCode: '22',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7010',
    BranchCode: '23',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7010',
    BranchCode: '24',
    BranchName: 'Matara'
  },
  {
    BankCode: '7010',
    BranchCode: '26',
    BranchName: 'Main Street'
  },
  {
    BankCode: '7010',
    BranchCode: '27',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7010',
    BranchCode: '28',
    BranchName: 'Point Pedro'
  },
  {
    BankCode: '7010',
    BranchCode: '29',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7010',
    BranchCode: '30',
    BranchName: 'Katubedda'
  },
  {
    BankCode: '7010',
    BranchCode: '31',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7010',
    BranchCode: '32',
    BranchName: 'Aluthkade'
  },
  {
    BankCode: '7010',
    BranchCode: '34',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '35',
    BranchName: 'Haputale'
  },
  {
    BankCode: '7010',
    BranchCode: '37',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '38',
    BranchName: 'Borella S/G'
  },
  {
    BankCode: '7010',
    BranchCode: '39',
    BranchName: 'Ja Ela'
  },
  {
    BankCode: '7010',
    BranchCode: '40',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7010',
    BranchCode: '41',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7010',
    BranchCode: '42',
    BranchName: 'Peliyagoda'
  },
  {
    BankCode: '7010',
    BranchCode: '43',
    BranchName: 'Union Place'
  },
  {
    BankCode: '7010',
    BranchCode: '44',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7010',
    BranchCode: '45',
    BranchName: 'Gampaha S/G'
  },
  {
    BankCode: '7010',
    BranchCode: '46',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7010',
    BranchCode: '47',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7010',
    BranchCode: '48',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7010',
    BranchCode: '49',
    BranchName: 'Nugegoda Supergrade'
  },
  {
    BankCode: '7010',
    BranchCode: '50',
    BranchName: 'Nattandiya'
  },
  {
    BankCode: '7010',
    BranchCode: '51',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7010',
    BranchCode: '52',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '53',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7010',
    BranchCode: '54',
    BranchName: 'Horana'
  },
  {
    BankCode: '7010',
    BranchCode: '55',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7010',
    BranchCode: '56',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7010',
    BranchCode: '57',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7010',
    BranchCode: '58',
    BranchName: 'Beruwala'
  },
  {
    BankCode: '7010',
    BranchCode: '59',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7010',
    BranchCode: '60',
    BranchName: 'Fifth City'
  },
  {
    BankCode: '7010',
    BranchCode: '61',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '63',
    BranchName: 'Velanai'
  },
  {
    BankCode: '7010',
    BranchCode: '68',
    BranchName: 'Matale'
  },
  {
    BankCode: '7010',
    BranchCode: '82',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7010',
    BranchCode: '83',
    BranchName: 'Polonnaruwa New Town'
  },
  {
    BankCode: '7010',
    BranchCode: '85',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7010',
    BranchCode: '87',
    BranchName: 'International Division'
  },
  {
    BankCode: '7010',
    BranchCode: '88',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7010',
    BranchCode: '89',
    BranchName: 'Galle Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '92',
    BranchName: 'Naula'
  },
  {
    BankCode: '7010',
    BranchCode: '93',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7010',
    BranchCode: '98',
    BranchName: 'Anuradhapura New Town'
  },
  {
    BankCode: '7010',
    BranchCode: '99',
    BranchName: 'Primary Dealer Unit'
  },
  {
    BankCode: '7010',
    BranchCode: '101',
    BranchName: 'Galaha'
  },
  {
    BankCode: '7010',
    BranchCode: '102',
    BranchName: 'Bentota'
  },
  {
    BankCode: '7010',
    BranchCode: '104',
    BranchName: 'Welpalla'
  },
  {
    BankCode: '7010',
    BranchCode: '118',
    BranchName: 'Muttur'
  },
  {
    BankCode: '7010',
    BranchCode: '122',
    BranchName: 'Galenbindunuwewa'
  },
  {
    BankCode: '7010',
    BranchCode: '127',
    BranchName: 'Padavi Parakramapura'
  },
  {
    BankCode: '7010',
    BranchCode: '135',
    BranchName: 'Imaduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '139',
    BranchName: 'Weeraketiya'
  },
  {
    BankCode: '7010',
    BranchCode: '144',
    BranchName: 'Yatawatte'
  },
  {
    BankCode: '7010',
    BranchCode: '152',
    BranchName: 'Pemaduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '157',
    BranchName: 'Tirappane'
  },
  {
    BankCode: '7010',
    BranchCode: '162',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7010',
    BranchCode: '167',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7010',
    BranchCode: '172',
    BranchName: 'Kobeigane'
  },
  {
    BankCode: '7010',
    BranchCode: '183',
    BranchName: 'Sewagama'
  },
  {
    BankCode: '7010',
    BranchCode: '217',
    BranchName: 'Horowpathana'
  },
  {
    BankCode: '7010',
    BranchCode: '236',
    BranchName: 'Ipalogama'
  },
  {
    BankCode: '7010',
    BranchCode: '238',
    BranchName: 'Medagama'
  },
  {
    BankCode: '7010',
    BranchCode: '250',
    BranchName: 'Tawalama'
  },
  {
    BankCode: '7010',
    BranchCode: '255',
    BranchName: 'Malkaduwawa'
  },
  {
    BankCode: '7010',
    BranchCode: '256',
    BranchName: 'Thanthirimale'
  },
  {
    BankCode: '7010',
    BranchCode: '257',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7010',
    BranchCode: '258',
    BranchName: 'Elakanda'
  },
  {
    BankCode: '7010',
    BranchCode: '259',
    BranchName: 'Rathgama'
  },
  {
    BankCode: '7010',
    BranchCode: '260',
    BranchName: 'Diyatalawa'
  },
  {
    BankCode: '7010',
    BranchCode: '261',
    BranchName: 'Katuwana'
  },
  {
    BankCode: '7010',
    BranchCode: '262',
    BranchName: 'Kekanadura'
  },
  {
    BankCode: '7010',
    BranchCode: '263',
    BranchName: 'Kosmodera'
  },
  {
    BankCode: '7010',
    BranchCode: '264',
    BranchName: 'Kudawella'
  },
  {
    BankCode: '7010',
    BranchCode: '265',
    BranchName: 'Lunugamvehera'
  },
  {
    BankCode: '7010',
    BranchCode: '266',
    BranchName: 'Maha-Edanda'
  },
  {
    BankCode: '7010',
    BranchCode: '267',
    BranchName: 'Makandura � Matara'
  },
  {
    BankCode: '7010',
    BranchCode: '268',
    BranchName: 'Malimbada'
  },
  {
    BankCode: '7010',
    BranchCode: '270',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7010',
    BranchCode: '271',
    BranchName: 'Pasgoda'
  },
  {
    BankCode: '7010',
    BranchCode: '272',
    BranchName: 'Pitabeddara'
  },
  {
    BankCode: '7010',
    BranchCode: '273',
    BranchName: 'Digana'
  },
  {
    BankCode: '7010',
    BranchCode: '274',
    BranchName: 'Weli-Oya'
  },
  {
    BankCode: '7010',
    BranchCode: '276',
    BranchName: 'Ahangama'
  },
  {
    BankCode: '7010',
    BranchCode: '277',
    BranchName: 'Aluthwala'
  },
  {
    BankCode: '7010',
    BranchCode: '278',
    BranchName: 'Barawakumbura'
  },
  {
    BankCode: '7010',
    BranchCode: '280',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '281',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7010',
    BranchCode: '282',
    BranchName: 'Kitulgala'
  },
  {
    BankCode: '7010',
    BranchCode: '283',
    BranchName: 'Kolonna'
  },
  {
    BankCode: '7010',
    BranchCode: '284',
    BranchName: 'Kotiyakumbura'
  },
  {
    BankCode: '7010',
    BranchCode: '285',
    BranchName: 'Morontota'
  },
  {
    BankCode: '7010',
    BranchCode: '286',
    BranchName: 'Sabaragamuwa University'
  },
  {
    BankCode: '7010',
    BranchCode: '287',
    BranchName: 'Pinnawala'
  },
  {
    BankCode: '7010',
    BranchCode: '288',
    BranchName: 'Sabaragamuwa Provincial Council'
  },
  {
    BankCode: '7010',
    BranchCode: '290',
    BranchName: 'Seethawakapura'
  },
  {
    BankCode: '7010',
    BranchCode: '291',
    BranchName: 'Udawalawe'
  },
  {
    BankCode: '7010',
    BranchCode: '292',
    BranchName: 'Weligepola'
  },
  {
    BankCode: '7010',
    BranchCode: '293',
    BranchName: 'Dodangoda'
  },
  {
    BankCode: '7010',
    BranchCode: '294',
    BranchName: 'Karawanella'
  },
  {
    BankCode: '7010',
    BranchCode: '295',
    BranchName: 'Karawita'
  },
  {
    BankCode: '7010',
    BranchCode: '297',
    BranchName: 'Kegalle Hospital'
  },
  {
    BankCode: '7010',
    BranchCode: '298',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7010',
    BranchCode: '299',
    BranchName: 'Makandura'
  },
  {
    BankCode: '7010',
    BranchCode: '300',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7010',
    BranchCode: '301',
    BranchName: 'Palaviya'
  },
  {
    BankCode: '7010',
    BranchCode: '302',
    BranchName: 'Pallama'
  },
  {
    BankCode: '7010',
    BranchCode: '303',
    BranchName: 'Paragahadeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '305',
    BranchName: 'Thoduwawa'
  },
  {
    BankCode: '7010',
    BranchCode: '306',
    BranchName: 'Udappuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '307',
    BranchName: 'Wayamba University'
  },
  {
    BankCode: '7010',
    BranchCode: '308',
    BranchName: 'Weerapokuna'
  },
  {
    BankCode: '7010',
    BranchCode: '309',
    BranchName: 'Wellawa'
  },
  {
    BankCode: '7010',
    BranchCode: '311',
    BranchName: 'Bulathkohupitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '312',
    BranchName: 'Embilipitiya City'
  },
  {
    BankCode: '7010',
    BranchCode: '313',
    BranchName: 'Endana'
  },
  {
    BankCode: '7010',
    BranchCode: '314',
    BranchName: 'Galigamuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '315',
    BranchName: 'Ratnapura Hospital'
  },
  {
    BankCode: '7010',
    BranchCode: '316',
    BranchName: 'Gonagaldeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '317',
    BranchName: 'Kiriella'
  },
  {
    BankCode: '7010',
    BranchCode: '318',
    BranchName: 'Potuvil'
  },
  {
    BankCode: '7010',
    BranchCode: '319',
    BranchName: 'Mahawewa'
  },
  {
    BankCode: '7010',
    BranchCode: '320',
    BranchName: 'Ballaketuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '322',
    BranchName: 'Thanamalwila'
  },
  {
    BankCode: '7010',
    BranchCode: '323',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7010',
    BranchCode: '324',
    BranchName: 'Kumbukgete'
  },
  {
    BankCode: '7010',
    BranchCode: '325',
    BranchName: 'Kuruwita'
  },
  {
    BankCode: '7010',
    BranchCode: '326',
    BranchName: 'Thirumurukandi'
  },
  {
    BankCode: '7010',
    BranchCode: '328',
    BranchName: 'Visuvamadu'
  },
  {
    BankCode: '7010',
    BranchCode: '329',
    BranchName: 'Ambanpola'
  },
  {
    BankCode: '7010',
    BranchCode: '330',
    BranchName: 'Anawilundawa'
  },
  {
    BankCode: '7010',
    BranchCode: '331',
    BranchName: 'Dambadeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '332',
    BranchName: 'Katuneriya'
  },
  {
    BankCode: '7010',
    BranchCode: '333',
    BranchName: 'Katupotha'
  },
  {
    BankCode: '7010',
    BranchCode: '334',
    BranchName: 'Kirimetiyana'
  },
  {
    BankCode: '7010',
    BranchCode: '335',
    BranchName: 'Mihintale'
  },
  {
    BankCode: '7010',
    BranchCode: '336',
    BranchName: 'Thalaimannar Pier'
  },
  {
    BankCode: '7010',
    BranchCode: '337',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7010',
    BranchCode: '338',
    BranchName: 'Savalkaddu'
  },
  {
    BankCode: '7010',
    BranchCode: '339',
    BranchName: 'Sirupiddy'
  },
  {
    BankCode: '7010',
    BranchCode: '340',
    BranchName: 'Wattegama'
  },
  {
    BankCode: '7010',
    BranchCode: '341',
    BranchName: 'Puthukudieruppu'
  },
  {
    BankCode: '7010',
    BranchCode: '342',
    BranchName: 'Puthukulam'
  },
  {
    BankCode: '7010',
    BranchCode: '343',
    BranchName: 'Uva Paranagama'
  },
  {
    BankCode: '7010',
    BranchCode: '344',
    BranchName: 'Pesalai'
  },
  {
    BankCode: '7010',
    BranchCode: '345',
    BranchName: 'Poonagary'
  },
  {
    BankCode: '7010',
    BranchCode: '346',
    BranchName: 'Poovarasankulam'
  },
  {
    BankCode: '7010',
    BranchCode: '347',
    BranchName: 'Punnalaikadduvan'
  },
  {
    BankCode: '7010',
    BranchCode: '348',
    BranchName: 'Padiyatalawa'
  },
  {
    BankCode: '7010',
    BranchCode: '349',
    BranchName: 'Mallavi'
  },
  {
    BankCode: '7010',
    BranchCode: '351',
    BranchName: 'Manthikai'
  },
  {
    BankCode: '7010',
    BranchCode: '352',
    BranchName: 'Maruthankerny'
  },
  {
    BankCode: '7010',
    BranchCode: '353',
    BranchName: 'Mulankavil'
  },
  {
    BankCode: '7010',
    BranchCode: '355',
    BranchName: 'Mullativu'
  },
  {
    BankCode: '7010',
    BranchCode: '356',
    BranchName: 'Murungan'
  },
  {
    BankCode: '7010',
    BranchCode: '357',
    BranchName: 'Nainativu'
  },
  {
    BankCode: '7010',
    BranchCode: '358',
    BranchName: 'Nallur'
  },
  {
    BankCode: '7010',
    BranchCode: '359',
    BranchName: 'Nanatan'
  },
  {
    BankCode: '7010',
    BranchCode: '360',
    BranchName: 'Nedunkerny'
  },
  {
    BankCode: '7010',
    BranchCode: '361',
    BranchName: 'Oddusudan'
  },
  {
    BankCode: '7010',
    BranchCode: '362',
    BranchName: 'Omanthai'
  },
  {
    BankCode: '7010',
    BranchCode: '363',
    BranchName: 'Pallai'
  },
  {
    BankCode: '7010',
    BranchCode: '364',
    BranchName: 'Paranthan'
  },
  {
    BankCode: '7010',
    BranchCode: '365',
    BranchName: 'Hasalaka'
  },
  {
    BankCode: '7010',
    BranchCode: '366',
    BranchName: 'Jaffna Bus Stand'
  },
  {
    BankCode: '7010',
    BranchCode: '368',
    BranchName: 'Jaffna Main Street'
  },
  {
    BankCode: '7010',
    BranchCode: '369',
    BranchName: 'Jaffna University'
  },
  {
    BankCode: '7010',
    BranchCode: '370',
    BranchName: 'Kaithady'
  },
  {
    BankCode: '7010',
    BranchCode: '371',
    BranchName: 'Kalviyankadu'
  },
  {
    BankCode: '7010',
    BranchCode: '372',
    BranchName: 'Karanavai'
  },
  {
    BankCode: '7010',
    BranchCode: '373',
    BranchName: 'Kayts'
  },
  {
    BankCode: '7010',
    BranchCode: '375',
    BranchName: 'Kodikamam'
  },
  {
    BankCode: '7010',
    BranchCode: '376',
    BranchName: 'Kokuvil'
  },
  {
    BankCode: '7010',
    BranchCode: '377',
    BranchName: 'Illavalai'
  },
  {
    BankCode: '7010',
    BranchCode: '378',
    BranchName: 'Madhu'
  },
  {
    BankCode: '7010',
    BranchCode: '379',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7010',
    BranchCode: '380',
    BranchName: 'Alaveddy'
  },
  {
    BankCode: '7010',
    BranchCode: '381',
    BranchName: 'Andankulam'
  },
  {
    BankCode: '7010',
    BranchCode: '382',
    BranchName: 'Cheddikulam'
  },
  {
    BankCode: '7010',
    BranchCode: '383',
    BranchName: 'Delft'
  },
  {
    BankCode: '7010',
    BranchCode: '384',
    BranchName: 'Meegahakiwula'
  },
  {
    BankCode: '7010',
    BranchCode: '385',
    BranchName: 'Vavunathivu'
  },
  {
    BankCode: '7010',
    BranchCode: '386',
    BranchName: 'Vellaveli'
  },
  {
    BankCode: '7010',
    BranchCode: '388',
    BranchName: 'Diyabeduma'
  },
  {
    BankCode: '7010',
    BranchCode: '389',
    BranchName: 'Diyasenpura'
  },
  {
    BankCode: '7010',
    BranchCode: '390',
    BranchName: 'Doramadalawa'
  },
  {
    BankCode: '7010',
    BranchCode: '391',
    BranchName: 'Galamuna'
  },
  {
    BankCode: '7010',
    BranchCode: '392',
    BranchName: 'General Hospital, A� pura'
  },
  {
    BankCode: '7010',
    BranchCode: '393',
    BranchName: 'Habarana'
  },
  {
    BankCode: '7010',
    BranchCode: '394',
    BranchName: 'Minneriya'
  },
  {
    BankCode: '7010',
    BranchCode: '395',
    BranchName: 'Padaviya'
  },
  {
    BankCode: '7010',
    BranchCode: '396',
    BranchName: 'Rajanganaya'
  },
  {
    BankCode: '7010',
    BranchCode: '397',
    BranchName: 'Rajina Junction'
  },
  {
    BankCode: '7010',
    BranchCode: '398',
    BranchName: 'Ranajayapura'
  },
  {
    BankCode: '7010',
    BranchCode: '399',
    BranchName: 'Sevanapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '400',
    BranchName: 'Thalawa'
  },
  {
    BankCode: '7010',
    BranchCode: '401',
    BranchName: 'Ayagama'
  },
  {
    BankCode: '7010',
    BranchCode: '402',
    BranchName: 'Oddamavady'
  },
  {
    BankCode: '7010',
    BranchCode: '403',
    BranchName: 'Oluwil'
  },
  {
    BankCode: '7010',
    BranchCode: '404',
    BranchName: 'Palugamam'
  },
  {
    BankCode: '7010',
    BranchCode: '405',
    BranchName: 'Polwatte'
  },
  {
    BankCode: '7010',
    BranchCode: '406',
    BranchName: 'Palmuddai'
  },
  {
    BankCode: '7010',
    BranchCode: '407',
    BranchName: 'Sainthamarathu'
  },
  {
    BankCode: '7010',
    BranchCode: '408',
    BranchName: 'Serunuwara'
  },
  {
    BankCode: '7010',
    BranchCode: '409',
    BranchName: 'Thambiluvil'
  },
  {
    BankCode: '7010',
    BranchCode: '410',
    BranchName: 'Thampalakamam'
  },
  {
    BankCode: '7010',
    BranchCode: '411',
    BranchName: 'Thoppur'
  },
  {
    BankCode: '7010',
    BranchCode: '413',
    BranchName: 'Uhana'
  },
  {
    BankCode: '7010',
    BranchCode: '414',
    BranchName: 'Uppuvely'
  },
  {
    BankCode: '7010',
    BranchCode: '415',
    BranchName: 'Vakarai'
  },
  {
    BankCode: '7010',
    BranchCode: '416',
    BranchName: 'Siyambalanduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '417',
    BranchName: 'Mollipothana'
  },
  {
    BankCode: '7010',
    BranchCode: '418',
    BranchName: 'Morawewa'
  },
  {
    BankCode: '7010',
    BranchCode: '419',
    BranchName: 'Navithanvely'
  },
  {
    BankCode: '7010',
    BranchCode: '420',
    BranchName: 'Nilavely'
  },
  {
    BankCode: '7010',
    BranchCode: '421',
    BranchName: 'Seeduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '422',
    BranchName: 'Malwatte'
  },
  {
    BankCode: '7010',
    BranchCode: '423',
    BranchName: 'Mamangama'
  },
  {
    BankCode: '7010',
    BranchCode: '424',
    BranchName: 'Maruthamunai'
  },
  {
    BankCode: '7010',
    BranchCode: '425',
    BranchName: 'Pundaluoya'
  },
  {
    BankCode: '7010',
    BranchCode: '426',
    BranchName: 'Kallady'
  },
  {
    BankCode: '7010',
    BranchCode: '427',
    BranchName: 'Kallar'
  },
  {
    BankCode: '7010',
    BranchCode: '428',
    BranchName: 'Karadiyanaru'
  },
  {
    BankCode: '7010',
    BranchCode: '429',
    BranchName: 'Karaitivu'
  },
  {
    BankCode: '7010',
    BranchCode: '430',
    BranchName: 'Kiran'
  },
  {
    BankCode: '7010',
    BranchCode: '431',
    BranchName: 'Kokkadicholai'
  },
  {
    BankCode: '7010',
    BranchCode: '432',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7010',
    BranchCode: '433',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '434',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7010',
    BranchCode: '436',
    BranchName: 'China Bay'
  },
  {
    BankCode: '7010',
    BranchCode: '437',
    BranchName: 'Eastern University'
  },
  {
    BankCode: '7010',
    BranchCode: '438',
    BranchName: 'Gonagolla'
  },
  {
    BankCode: '7010',
    BranchCode: '439',
    BranchName: 'Irakkamam'
  },
  {
    BankCode: '7010',
    BranchCode: '440',
    BranchName: 'Samanthurai'
  },
  {
    BankCode: '7010',
    BranchCode: '441',
    BranchName: 'Pujapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '442',
    BranchName: 'Ragala'
  },
  {
    BankCode: '7010',
    BranchCode: '443',
    BranchName: 'Sigiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '444',
    BranchName: 'Ukuwela'
  },
  {
    BankCode: '7010',
    BranchCode: '445',
    BranchName: 'University Of Peradeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '446',
    BranchName: 'Upcott'
  },
  {
    BankCode: '7010',
    BranchCode: '447',
    BranchName: 'Wilgamuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '448',
    BranchName: 'Addalachchenai'
  },
  {
    BankCode: '7010',
    BranchCode: '449',
    BranchName: 'Alankerny'
  },
  {
    BankCode: '7010',
    BranchCode: '451',
    BranchName: 'Araiyampathy'
  },
  {
    BankCode: '7010',
    BranchCode: '452',
    BranchName: 'Batticaloa Town'
  },
  {
    BankCode: '7010',
    BranchCode: '453',
    BranchName: 'Independent� Square'
  },
  {
    BankCode: '7010',
    BranchCode: '454',
    BranchName: 'Kandy Hospital'
  },
  {
    BankCode: '7010',
    BranchCode: '455',
    BranchName: 'Kotagala'
  },
  {
    BankCode: '7010',
    BranchCode: '456',
    BranchName: 'Marassana'
  },
  {
    BankCode: '7010',
    BranchCode: '458',
    BranchName: 'Meepilimana'
  },
  {
    BankCode: '7010',
    BranchCode: '459',
    BranchName: 'Menikhinna'
  },
  {
    BankCode: '7010',
    BranchCode: '461',
    BranchName: 'Palapathwela'
  },
  {
    BankCode: '7010',
    BranchCode: '462',
    BranchName: 'Botanical Gardens Peradeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '463',
    BranchName: 'Haldummulla'
  },
  {
    BankCode: '7010',
    BranchCode: '464',
    BranchName: 'Pallekelle'
  },
  {
    BankCode: '7010',
    BranchCode: '465',
    BranchName: 'Bokkawala'
  },
  {
    BankCode: '7010',
    BranchCode: '466',
    BranchName: 'Danture'
  },
  {
    BankCode: '7010',
    BranchCode: '467',
    BranchName: 'Daulagala'
  },
  {
    BankCode: '7010',
    BranchCode: '469',
    BranchName: 'Digana Village'
  },
  {
    BankCode: '7010',
    BranchCode: '470',
    BranchName: 'Gampola City'
  },
  {
    BankCode: '7010',
    BranchCode: '472',
    BranchName: 'Hatharaliyadda'
  },
  {
    BankCode: '7010',
    BranchCode: '471',
    BranchName: 'Ginigathhena'
  },
  {
    BankCode: '7010',
    BranchCode: '473',
    BranchName: 'Kandy City Centre'
  },
  {
    BankCode: '7010',
    BranchCode: '474',
    BranchName: 'Court Complex Kandy'
  },
  {
    BankCode: '7010',
    BranchCode: '476',
    BranchName: 'Ettampitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '477',
    BranchName: 'Yatiyantota'
  },
  {
    BankCode: '7010',
    BranchCode: '487',
    BranchName: 'Adikarigama'
  },
  {
    BankCode: '7010',
    BranchCode: '488',
    BranchName: 'Agarapathana'
  },
  {
    BankCode: '7010',
    BranchCode: '489',
    BranchName: 'Akurana'
  },
  {
    BankCode: '7010',
    BranchCode: '490',
    BranchName: 'Ankumbura'
  },
  {
    BankCode: '7010',
    BranchCode: '491',
    BranchName: 'Bogawantalawa'
  },
  {
    BankCode: '7010',
    BranchCode: '492',
    BranchName: 'Padiyapelella'
  },
  {
    BankCode: '7010',
    BranchCode: '494',
    BranchName: 'Andiambalama'
  },
  {
    BankCode: '7010',
    BranchCode: '497',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '498',
    BranchName: 'Alawwa'
  },
  {
    BankCode: '7010',
    BranchCode: '499',
    BranchName: 'Wijerama Junction'
  },
  {
    BankCode: '7010',
    BranchCode: '500',
    BranchName: 'Jaffna 2nd Branch'
  },
  {
    BankCode: '7010',
    BranchCode: '501',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7010',
    BranchCode: '502',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7010',
    BranchCode: '503',
    BranchName: 'Passara'
  },
  {
    BankCode: '7010',
    BranchCode: '504',
    BranchName: 'Devinuwara'
  },
  {
    BankCode: '7010',
    BranchCode: '505',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7010',
    BranchCode: '506',
    BranchName: 'Maskeliya'
  },
  {
    BankCode: '7010',
    BranchCode: '507',
    BranchName: 'Kahawatte'
  },
  {
    BankCode: '7010',
    BranchCode: '508',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '509',
    BranchName: 'Hingurana'
  },
  {
    BankCode: '7010',
    BranchCode: '510',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7010',
    BranchCode: '511',
    BranchName: 'Mulliyawalai'
  },
  {
    BankCode: '7010',
    BranchCode: '512',
    BranchName: 'Thimbirigasyaya'
  },
  {
    BankCode: '7010',
    BranchCode: '513',
    BranchName: 'Kurunegala Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '514',
    BranchName: 'Galnewa'
  },
  {
    BankCode: '7010',
    BranchCode: '515',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7010',
    BranchCode: '516',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7010',
    BranchCode: '517',
    BranchName: 'Walasmulla'
  },
  {
    BankCode: '7010',
    BranchCode: '518',
    BranchName: 'Middeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '520',
    BranchName: 'Sri Jayawardenapura Hospital'
  },
  {
    BankCode: '7010',
    BranchCode: '521',
    BranchName: 'Hyde Park'
  },
  {
    BankCode: '7010',
    BranchCode: '522',
    BranchName: 'Batapola'
  },
  {
    BankCode: '7010',
    BranchCode: '524',
    BranchName: 'Geli Oya'
  },
  {
    BankCode: '7010',
    BranchCode: '525',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7010',
    BranchCode: '526',
    BranchName: 'Polgahawela'
  },
  {
    BankCode: '7010',
    BranchCode: '527',
    BranchName: 'Welisara'
  },
  {
    BankCode: '7010',
    BranchCode: '528',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7010',
    BranchCode: '529',
    BranchName: 'Kamburupitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '530',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7010',
    BranchCode: '531',
    BranchName: 'Talawakelle'
  },
  {
    BankCode: '7010',
    BranchCode: '532',
    BranchName: 'Ridigama'
  },
  {
    BankCode: '7010',
    BranchCode: '533',
    BranchName: 'Pitakotte'
  },
  {
    BankCode: '7010',
    BranchCode: '534',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7010',
    BranchCode: '535',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '536',
    BranchName: 'Kegalle Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '537',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7010',
    BranchCode: '538',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7010',
    BranchCode: '539',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7010',
    BranchCode: '540',
    BranchName: 'Badalkumbura'
  },
  {
    BankCode: '7010',
    BranchCode: '541',
    BranchName: 'Pelawatte City Kalutara'
  },
  {
    BankCode: '7010',
    BranchCode: '542',
    BranchName: 'Mahiyangana'
  },
  {
    BankCode: '7010',
    BranchCode: '543',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7010',
    BranchCode: '544',
    BranchName: 'Madampe'
  },
  {
    BankCode: '7010',
    BranchCode: '545',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7010',
    BranchCode: '546',
    BranchName: 'Pannala'
  },
  {
    BankCode: '7010',
    BranchCode: '547',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7010',
    BranchCode: '548',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '549',
    BranchName: 'Galgamuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '550',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7010',
    BranchCode: '551',
    BranchName: 'Anuradhapura Bazzar'
  },
  {
    BankCode: '7010',
    BranchCode: '552',
    BranchName: 'National Institute Of Education'
  },
  {
    BankCode: '7010',
    BranchCode: '553',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7010',
    BranchCode: '554',
    BranchName: 'Bingiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '555',
    BranchName: 'Melsiripura'
  },
  {
    BankCode: '7010',
    BranchCode: '556',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7010',
    BranchCode: '557',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7010',
    BranchCode: '558',
    BranchName: 'Waikkal'
  },
  {
    BankCode: '7010',
    BranchCode: '559',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7010',
    BranchCode: '560',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7010',
    BranchCode: '561',
    BranchName: 'Dematagoda'
  },
  {
    BankCode: '7010',
    BranchCode: '562',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7010',
    BranchCode: '563',
    BranchName: 'Dharga Town'
  },
  {
    BankCode: '7010',
    BranchCode: '564',
    BranchName: 'Maho'
  },
  {
    BankCode: '7010',
    BranchCode: '565',
    BranchName: 'Madurankuliya'
  },
  {
    BankCode: '7010',
    BranchCode: '566',
    BranchName: 'Aranayake'
  },
  {
    BankCode: '7010',
    BranchCode: '567',
    BranchName: 'Dedicated Economic Centre � Meegoda'
  },
  {
    BankCode: '7010',
    BranchCode: '568',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7010',
    BranchCode: '569',
    BranchName: 'Hiripitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '570',
    BranchName: 'Hettipola'
  },
  {
    BankCode: '7010',
    BranchCode: '571',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7010',
    BranchCode: '572',
    BranchName: 'Negombo Bazzar'
  },
  {
    BankCode: '7010',
    BranchCode: '573',
    BranchName: 'Central Bus Stand'
  },
  {
    BankCode: '7010',
    BranchCode: '574',
    BranchName: 'Mankulam'
  },
  {
    BankCode: '7010',
    BranchCode: '575',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7010',
    BranchCode: '576',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7010',
    BranchCode: '577',
    BranchName: 'Lunugala'
  },
  {
    BankCode: '7010',
    BranchCode: '578',
    BranchName: 'Yakkalamulla'
  },
  {
    BankCode: '7010',
    BranchCode: '579',
    BranchName: 'Bibile'
  },
  {
    BankCode: '7010',
    BranchCode: '580',
    BranchName: 'Dummalasuriya'
  },
  {
    BankCode: '7010',
    BranchCode: '581',
    BranchName: 'Madawala'
  },
  {
    BankCode: '7010',
    BranchCode: '582',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7010',
    BranchCode: '583',
    BranchName: 'Mattegoda'
  },
  {
    BankCode: '7010',
    BranchCode: '584',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '585',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7010',
    BranchCode: '587',
    BranchName: 'Pilimatalawa'
  },
  {
    BankCode: '7010',
    BranchCode: '588',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '589',
    BranchName: 'Kalpitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '590',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7010',
    BranchCode: '591',
    BranchName: 'Nintavur'
  },
  {
    BankCode: '7010',
    BranchCode: '592',
    BranchName: 'Dikwella'
  },
  {
    BankCode: '7010',
    BranchCode: '593',
    BranchName: 'Milagiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '594',
    BranchName: 'Rakwana'
  },
  {
    BankCode: '7010',
    BranchCode: '595',
    BranchName: 'Kolonnawa'
  },
  {
    BankCode: '7010',
    BranchCode: '596',
    BranchName: 'Talgaswela'
  },
  {
    BankCode: '7010',
    BranchCode: '597',
    BranchName: 'Nivitigala'
  },
  {
    BankCode: '7010',
    BranchCode: '598',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '599',
    BranchName: 'Aralaganwila'
  },
  {
    BankCode: '7010',
    BranchCode: '600',
    BranchName: 'Jayanthipura'
  },
  {
    BankCode: '7010',
    BranchCode: '601',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7010',
    BranchCode: '602',
    BranchName: 'Kirulapana'
  },
  {
    BankCode: '7010',
    BranchCode: '603',
    BranchName: 'Lanka Hospital'
  },
  {
    BankCode: '7010',
    BranchCode: '604',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '605',
    BranchName: 'Kankesanthurai'
  },
  {
    BankCode: '7010',
    BranchCode: '606',
    BranchName: 'Uda Dumbara'
  },
  {
    BankCode: '7010',
    BranchCode: '607',
    BranchName: 'Panadura Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '608',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7010',
    BranchCode: '609',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '610',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7010',
    BranchCode: '611',
    BranchName: 'Kaluwanchikudy'
  },
  {
    BankCode: '7010',
    BranchCode: '612',
    BranchName: 'Lake View'
  },
  {
    BankCode: '7010',
    BranchCode: '613',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7010',
    BranchCode: '614',
    BranchName: 'Matara City'
  },
  {
    BankCode: '7010',
    BranchCode: '615',
    BranchName: 'Galagedera'
  },
  {
    BankCode: '7010',
    BranchCode: '616',
    BranchName: 'Kataragama'
  },
  {
    BankCode: '7010',
    BranchCode: '617',
    BranchName: 'Keselwatte'
  },
  {
    BankCode: '7010',
    BranchCode: '618',
    BranchName: 'Metropolitan'
  },
  {
    BankCode: '7010',
    BranchCode: '619',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '620',
    BranchName: 'Kesbewa'
  },
  {
    BankCode: '7010',
    BranchCode: '621',
    BranchName: 'Kebithigollawa'
  },
  {
    BankCode: '7010',
    BranchCode: '622',
    BranchName: 'Kahatagasdigiliya'
  },
  {
    BankCode: '7010',
    BranchCode: '623',
    BranchName: 'Kantale Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '624',
    BranchName: 'Trincomalee Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '625',
    BranchName: 'Katukurunda'
  },
  {
    BankCode: '7010',
    BranchCode: '626',
    BranchName: 'Valachchenai'
  },
  {
    BankCode: '7010',
    BranchCode: '627',
    BranchName: 'Regent Street'
  },
  {
    BankCode: '7010',
    BranchCode: '628',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7010',
    BranchCode: '629',
    BranchName: 'Koslanda'
  },
  {
    BankCode: '7010',
    BranchCode: '630',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7010',
    BranchCode: '631',
    BranchName: 'Katubedda Campus'
  },
  {
    BankCode: '7010',
    BranchCode: '633',
    BranchName: 'Kandapola'
  },
  {
    BankCode: '7010',
    BranchCode: '634',
    BranchName: 'Dehiowita'
  },
  {
    BankCode: '7010',
    BranchCode: '636',
    BranchName: 'Lake House'
  },
  {
    BankCode: '7010',
    BranchCode: '638',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7010',
    BranchCode: '639',
    BranchName: 'Rattota'
  },
  {
    BankCode: '7010',
    BranchCode: '640',
    BranchName: 'Pallepola'
  },
  {
    BankCode: '7010',
    BranchCode: '641',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '642',
    BranchName: 'Deraniyagala'
  },
  {
    BankCode: '7010',
    BranchCode: '643',
    BranchName: 'Gonapola'
  },
  {
    BankCode: '7010',
    BranchCode: '644',
    BranchName: 'Parliamentary Complex'
  },
  {
    BankCode: '7010',
    BranchCode: '645',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7010',
    BranchCode: '646',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '647',
    BranchName: 'Lunuwatte'
  },
  {
    BankCode: '7010',
    BranchCode: '648',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7010',
    BranchCode: '649',
    BranchName: 'Kandy 2nd'
  },
  {
    BankCode: '7010',
    BranchCode: '650',
    BranchName: 'Talatuoya'
  },
  {
    BankCode: '7010',
    BranchCode: '651',
    BranchName: 'Bombuwela'
  },
  {
    BankCode: '7010',
    BranchCode: '652',
    BranchName: 'Bakamuna'
  },
  {
    BankCode: '7010',
    BranchCode: '653',
    BranchName: 'Galkiriyagama'
  },
  {
    BankCode: '7010',
    BranchCode: '654',
    BranchName: 'Madatugama'
  },
  {
    BankCode: '7010',
    BranchCode: '655',
    BranchName: 'Tambuttegama'
  },
  {
    BankCode: '7010',
    BranchCode: '656',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7010',
    BranchCode: '657',
    BranchName: 'Agalawatta'
  },
  {
    BankCode: '7010',
    BranchCode: '658',
    BranchName: 'Katunayake I.P.Z.'
  },
  {
    BankCode: '7010',
    BranchCode: '659',
    BranchName: 'Gurugoda'
  },
  {
    BankCode: '7010',
    BranchCode: '660',
    BranchName: 'Corporate'
  },
  {
    BankCode: '7010',
    BranchCode: '662',
    BranchName: 'Baduraliya'
  },
  {
    BankCode: '7010',
    BranchCode: '663',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7010',
    BranchCode: '664',
    BranchName: 'Pothuhera'
  },
  {
    BankCode: '7010',
    BranchCode: '665',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7010',
    BranchCode: '666',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7010',
    BranchCode: '667',
    BranchName: 'Neluwa'
  },
  {
    BankCode: '7010',
    BranchCode: '668',
    BranchName: 'Borella� 2nd'
  },
  {
    BankCode: '7010',
    BranchCode: '669',
    BranchName: 'Girandurukotte'
  },
  {
    BankCode: '7010',
    BranchCode: '670',
    BranchName: 'Kollupitiya 2nd'
  },
  {
    BankCode: '7010',
    BranchCode: '671',
    BranchName: 'Isurupaya'
  },
  {
    BankCode: '7010',
    BranchCode: '672',
    BranchName: 'Central Super Market'
  },
  {
    BankCode: '7010',
    BranchCode: '673',
    BranchName: 'Bulathsinhala'
  },
  {
    BankCode: '7010',
    BranchCode: '674',
    BranchName: 'Enderamulla'
  },
  {
    BankCode: '7010',
    BranchCode: '675',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '676',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7010',
    BranchCode: '677',
    BranchName: 'Weliweriya'
  },
  {
    BankCode: '7010',
    BranchCode: '678',
    BranchName: 'Padukka'
  },
  {
    BankCode: '7010',
    BranchCode: '679',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7010',
    BranchCode: '680',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7010',
    BranchCode: '681',
    BranchName: 'Personal'
  },
  {
    BankCode: '7010',
    BranchCode: '682',
    BranchName: 'Veyangoda'
  },
  {
    BankCode: '7010',
    BranchCode: '683',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7010',
    BranchCode: '684',
    BranchName: 'Ratnapura Bazaar'
  },
  {
    BankCode: '7010',
    BranchCode: '685',
    BranchName: 'Ward Place'
  },
  {
    BankCode: '7010',
    BranchCode: '686',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7010',
    BranchCode: '687',
    BranchName: 'Raddolugama'
  },
  {
    BankCode: '7010',
    BranchCode: '688',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7010',
    BranchCode: '689',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7010',
    BranchCode: '690',
    BranchName: 'Pelawatta'
  },
  {
    BankCode: '7010',
    BranchCode: '691',
    BranchName: 'Hakmana'
  },
  {
    BankCode: '7010',
    BranchCode: '692',
    BranchName: 'Eppawala'
  },
  {
    BankCode: '7010',
    BranchCode: '693',
    BranchName: 'Ruhunu Campus'
  },
  {
    BankCode: '7010',
    BranchCode: '699',
    BranchName: 'Bogahakumbura'
  },
  {
    BankCode: '7010',
    BranchCode: '700',
    BranchName: 'Dambagalla'
  },
  {
    BankCode: '7010',
    BranchCode: '701',
    BranchName: 'Ella'
  },
  {
    BankCode: '7010',
    BranchCode: '702',
    BranchName: 'Ethiliwewa'
  },
  {
    BankCode: '7010',
    BranchCode: '703',
    BranchName: 'Keppetipola'
  },
  {
    BankCode: '7010',
    BranchCode: '705',
    BranchName: 'Moneragala Town'
  },
  {
    BankCode: '7010',
    BranchCode: '706',
    BranchName: 'Okkampitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '707',
    BranchName: 'Pelawatta'
  },
  {
    BankCode: '7010',
    BranchCode: '708',
    BranchName: 'Batuwatte'
  },
  {
    BankCode: '7010',
    BranchCode: '711',
    BranchName: 'Bopitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '713',
    BranchName: 'Asiri Central'
  },
  {
    BankCode: '7010',
    BranchCode: '714',
    BranchName: 'Katuwellegama Courtaulds Clothing Lanka (Pvt) Ltd'
  },
  {
    BankCode: '7010',
    BranchCode: '715',
    BranchName: 'Dalugama'
  },
  {
    BankCode: '7010',
    BranchCode: '716',
    BranchName: 'Delgoda'
  },
  {
    BankCode: '7010',
    BranchCode: '718',
    BranchName: 'Fish Market Peliyagoda'
  },
  {
    BankCode: '7010',
    BranchCode: '717',
    BranchName: 'Demanhandiya'
  },
  {
    BankCode: '7010',
    BranchCode: '720',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7010',
    BranchCode: '721',
    BranchName: 'Gothatuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '723',
    BranchName: 'Mulleriyawa New Town'
  },
  {
    BankCode: '7010',
    BranchCode: '722',
    BranchName: 'Katana'
  },
  {
    BankCode: '7010',
    BranchCode: '724',
    BranchName: 'Naiwala'
  },
  {
    BankCode: '7010',
    BranchCode: '728',
    BranchName: 'Meegalewa'
  },
  {
    BankCode: '7010',
    BranchCode: '729',
    BranchName: 'Badulla City'
  },
  {
    BankCode: '7010',
    BranchCode: '730',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7010',
    BranchCode: '731',
    BranchName: 'CEYBANK Credit card centre'
  },
  {
    BankCode: '7010',
    BranchCode: '732',
    BranchName: 'Biyagama'
  },
  {
    BankCode: '7010',
    BranchCode: '733',
    BranchName: 'Warapitiya'
  },
  {
    BankCode: '7010',
    BranchCode: '735',
    BranchName: 'Kinniya'
  },
  {
    BankCode: '7010',
    BranchCode: '736',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7010',
    BranchCode: '741',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7010',
    BranchCode: '743',
    BranchName: 'Walapane'
  },
  {
    BankCode: '7010',
    BranchCode: '744',
    BranchName: 'Walgama'
  },
  {
    BankCode: '7010',
    BranchCode: '746',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '747',
    BranchName: 'Taprobane'
  },
  {
    BankCode: '7010',
    BranchCode: '748',
    BranchName: 'Uragasmanhandiya'
  },
  {
    BankCode: '7010',
    BranchCode: '749',
    BranchName: 'Karainagar'
  },
  {
    BankCode: '7010',
    BranchCode: '750',
    BranchName: 'Koggala E.P.Z'
  },
  {
    BankCode: '7010',
    BranchCode: '751',
    BranchName: 'Suriyawewa'
  },
  {
    BankCode: '7010',
    BranchCode: '752',
    BranchName: 'Thihagoda'
  },
  {
    BankCode: '7010',
    BranchCode: '753',
    BranchName: 'Udugama'
  },
  {
    BankCode: '7010',
    BranchCode: '754',
    BranchName: 'Ahungalla'
  },
  {
    BankCode: '7010',
    BranchCode: '757',
    BranchName: 'Athurugiriya'
  },
  {
    BankCode: '7010',
    BranchCode: '760',
    BranchName: 'Treasury Division'
  },
  {
    BankCode: '7010',
    BranchCode: '761',
    BranchName: 'Thirunelvely'
  },
  {
    BankCode: '7010',
    BranchCode: '762',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7010',
    BranchCode: '763',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7010',
    BranchCode: '764',
    BranchName: 'Ragama'
  },
  {
    BankCode: '7010',
    BranchCode: '765',
    BranchName: 'Pugoda'
  },
  {
    BankCode: '7010',
    BranchCode: '766',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7010',
    BranchCode: '767',
    BranchName: 'Ranna'
  },
  {
    BankCode: '7010',
    BranchCode: '768',
    BranchName: 'Alawathugoda'
  },
  {
    BankCode: '7010',
    BranchCode: '769',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7010',
    BranchCode: '770',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7010',
    BranchCode: '771',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7010',
    BranchCode: '772',
    BranchName: 'Hemmathagama'
  },
  {
    BankCode: '7010',
    BranchCode: '773',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7010',
    BranchCode: '774',
    BranchName: 'Angunakolapelessa'
  },
  {
    BankCode: '7010',
    BranchCode: '775',
    BranchName: 'Visakha'
  },
  {
    BankCode: '7010',
    BranchCode: '776',
    BranchName: 'Islamic Banking Unit'
  },
  {
    BankCode: '7010',
    BranchCode: '777',
    BranchName: 'Electronic Banking Unit'
  },
  {
    BankCode: '7010',
    BranchCode: '778',
    BranchName: 'Atchuvely'
  },
  {
    BankCode: '7010',
    BranchCode: '779',
    BranchName: 'Norchcholei'
  },
  {
    BankCode: '7010',
    BranchCode: '780',
    BranchName: 'Kadawatha 2nd City'
  },
  {
    BankCode: '7010',
    BranchCode: '781',
    BranchName: 'Teldeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '782',
    BranchName: 'Rambewa'
  },
  {
    BankCode: '7010',
    BranchCode: '783',
    BranchName: 'Polpithigama'
  },
  {
    BankCode: '7010',
    BranchCode: '784',
    BranchName: 'Deiyandara'
  },
  {
    BankCode: '7010',
    BranchCode: '785',
    BranchName: 'Hali ela'
  },
  {
    BankCode: '7010',
    BranchCode: '786',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7010',
    BranchCode: '787',
    BranchName: 'Kopay'
  },
  {
    BankCode: '7010',
    BranchCode: '788',
    BranchName: 'BOC premier'
  },
  {
    BankCode: '7010',
    BranchCode: '789',
    BranchName: 'Makola'
  },
  {
    BankCode: '7010',
    BranchCode: '790',
    BranchName: 'Eravur'
  },
  {
    BankCode: '7010',
    BranchCode: '791',
    BranchName: 'Valvettithurai'
  },
  {
    BankCode: '7010',
    BranchCode: '792',
    BranchName: 'Chankanai'
  },
  {
    BankCode: '7010',
    BranchCode: '793',
    BranchName: 'Vavuniya City'
  },
  {
    BankCode: '7010',
    BranchCode: '794',
    BranchName: 'Urumpirai'
  },
  {
    BankCode: '7010',
    BranchCode: '795',
    BranchName: 'Boragas'
  },
  {
    BankCode: '7010',
    BranchCode: '796',
    BranchName: 'Mattala Airport'
  },
  {
    BankCode: '7010',
    BranchCode: '797',
    BranchName: 'Medawala'
  },
  {
    BankCode: '7010',
    BranchCode: '798',
    BranchName: 'Thalduwa'
  },
  {
    BankCode: '7010',
    BranchCode: '799',
    BranchName: 'Nelundeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '800',
    BranchName: 'Wanduramba'
  },
  {
    BankCode: '7010',
    BranchCode: '802',
    BranchName: 'Provincial Council Complex, Pallakelle'
  },
  {
    BankCode: '7010',
    BranchCode: '803',
    BranchName: 'Welioya-Sampath Nuwara'
  },
  {
    BankCode: '7010',
    BranchCode: '804',
    BranchName: 'Vaddukoddai'
  },
  {
    BankCode: '7010',
    BranchCode: '805',
    BranchName: 'Madawakkulama'
  },
  {
    BankCode: '7010',
    BranchCode: '806',
    BranchName: 'Mahaoya'
  },
  {
    BankCode: '7010',
    BranchCode: '808',
    BranchName: 'Bogaswewa'
  },
  {
    BankCode: '7010',
    BranchCode: '809',
    BranchName: 'Kurunduwatte'
  },
  {
    BankCode: '7010',
    BranchCode: '810',
    BranchName: 'Ethimale'
  },
  {
    BankCode: '7010',
    BranchCode: '811',
    BranchName: 'Central Camp'
  },
  {
    BankCode: '7010',
    BranchCode: '812',
    BranchName: 'Aladeniya'
  },
  {
    BankCode: '7010',
    BranchCode: '813',
    BranchName: 'Kothalawala Defence University'
  },
  {
    BankCode: '7010',
    BranchCode: '814',
    BranchName: 'Saliyawewa'
  },
  {
    BankCode: '7010',
    BranchCode: '815',
    BranchName: 'Wahalkada'
  },
  {
    BankCode: '7010',
    BranchCode: '816',
    BranchName: 'Pallebedda'
  },
  {
    BankCode: '7010',
    BranchCode: '817',
    BranchName: 'Welikanda'
  },
  {
    BankCode: '7010',
    BranchCode: '818',
    BranchName: 'Nagoda'
  },
  {
    BankCode: '7010',
    BranchCode: '820',
    BranchName: 'Unawatuna'
  },
  {
    BankCode: '7010',
    BranchCode: '822',
    BranchName: 'Corporate 2nd'
  },
  {
    BankCode: '7010',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7038',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7038',
    BranchCode: '2',
    BranchName: 'Colpetty'
  },
  {
    BankCode: '7038',
    BranchCode: '3',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7038',
    BranchCode: '4',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7038',
    BranchCode: '5',
    BranchName: 'Kirullapone'
  },
  {
    BankCode: '7038',
    BranchCode: '6',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7038',
    BranchCode: '7',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7038',
    BranchCode: '10',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7038',
    BranchCode: '11',
    BranchName: 'Priority Banking Centre'
  },
  {
    BankCode: '7038',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7047',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7047',
    BranchCode: '100',
    BranchName: 'Cash Mgmt'
  },
  {
    BankCode: '7047',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7056',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7056',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7056',
    BranchCode: '2',
    BranchName: 'City Office'
  },
  {
    BankCode: '7056',
    BranchCode: '3',
    BranchName: 'Foreign'
  },
  {
    BankCode: '7056',
    BranchCode: '4',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7056',
    BranchCode: '5',
    BranchName: 'Galle Fort'
  },
  {
    BankCode: '7056',
    BranchCode: '6',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7056',
    BranchCode: '7',
    BranchName: 'Matara'
  },
  {
    BankCode: '7056',
    BranchCode: '8',
    BranchName: 'Matale'
  },
  {
    BankCode: '7056',
    BranchCode: '9',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7056',
    BranchCode: '10',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7056',
    BranchCode: '11',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '12',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7056',
    BranchCode: '13',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7056',
    BranchCode: '14',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7056',
    BranchCode: '15',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7056',
    BranchCode: '16',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7056',
    BranchCode: '17',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7056',
    BranchCode: '18',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7056',
    BranchCode: '19',
    BranchName: 'Borella'
  },
  {
    BankCode: '7056',
    BranchCode: '20',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7056',
    BranchCode: '21',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7056',
    BranchCode: '22',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7056',
    BranchCode: '23',
    BranchName: 'Mutuwal'
  },
  {
    BankCode: '7056',
    BranchCode: '24',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7056',
    BranchCode: '25',
    BranchName: 'Katunayake FTZ'
  },
  {
    BankCode: '7056',
    BranchCode: '26',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7056',
    BranchCode: '27',
    BranchName: 'Galle City'
  },
  {
    BankCode: '7056',
    BranchCode: '28',
    BranchName: 'Koggala'
  },
  {
    BankCode: '7056',
    BranchCode: '29',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7056',
    BranchCode: '30',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '31',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7056',
    BranchCode: '32',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7056',
    BranchCode: '33',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7056',
    BranchCode: '34',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7056',
    BranchCode: '35',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7056',
    BranchCode: '36',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7056',
    BranchCode: '37',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7056',
    BranchCode: '38',
    BranchName: 'Panchikawatte'
  },
  {
    BankCode: '7056',
    BranchCode: '39',
    BranchName: 'Keyzer Street'
  },
  {
    BankCode: '7056',
    BranchCode: '40',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7056',
    BranchCode: '41',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7056',
    BranchCode: '42',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7056',
    BranchCode: '43',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7056',
    BranchCode: '44',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7056',
    BranchCode: '45',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7056',
    BranchCode: '46',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7056',
    BranchCode: '47',
    BranchName: 'Kirulapona'
  },
  {
    BankCode: '7056',
    BranchCode: '48',
    BranchName: 'Union Place'
  },
  {
    BankCode: '7056',
    BranchCode: '49',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7056',
    BranchCode: '50',
    BranchName: 'Colombo 07'
  },
  {
    BankCode: '7056',
    BranchCode: '51',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '52',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7056',
    BranchCode: '53',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7056',
    BranchCode: '54',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7056',
    BranchCode: '55',
    BranchName: 'Nattandiya'
  },
  {
    BankCode: '7056',
    BranchCode: '56',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7056',
    BranchCode: '57',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7056',
    BranchCode: '58',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7056',
    BranchCode: '59',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7056',
    BranchCode: '60',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7056',
    BranchCode: '61',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7056',
    BranchCode: '62',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7056',
    BranchCode: '63',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7056',
    BranchCode: '64',
    BranchName: 'Seeduwa'
  },
  {
    BankCode: '7056',
    BranchCode: '65',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7056',
    BranchCode: '66',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7056',
    BranchCode: '67',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7056',
    BranchCode: '68',
    BranchName: 'Duplication Road'
  },
  {
    BankCode: '7056',
    BranchCode: '69',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7056',
    BranchCode: '70',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7056',
    BranchCode: '71',
    BranchName: 'Ekala� (CSP)'
  },
  {
    BankCode: '7056',
    BranchCode: '72',
    BranchName: 'Pettah Main Street'
  },
  {
    BankCode: '7056',
    BranchCode: '73',
    BranchName: 'Peradeniya� (CSP)'
  },
  {
    BankCode: '7056',
    BranchCode: '74',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7056',
    BranchCode: '75',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7056',
    BranchCode: '76',
    BranchName: 'Horana'
  },
  {
    BankCode: '7056',
    BranchCode: '77',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7056',
    BranchCode: '78',
    BranchName: 'Thalawathugoda (C.S.P.)'
  },
  {
    BankCode: '7056',
    BranchCode: '79',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7056',
    BranchCode: '80',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7056',
    BranchCode: '81',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7056',
    BranchCode: '82',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7056',
    BranchCode: '83',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7056',
    BranchCode: '84',
    BranchName: 'Bandaragama (C.S.P.)'
  },
  {
    BankCode: '7056',
    BranchCode: '85',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7056',
    BranchCode: '86',
    BranchName: 'Malabe (C.S.P.)'
  },
  {
    BankCode: '7056',
    BranchCode: '87',
    BranchName: 'Kohuwala'
  },
  {
    BankCode: '7056',
    BranchCode: '88',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7056',
    BranchCode: '89',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '93',
    BranchName: 'Mount Lavinia(C.S.P.)'
  },
  {
    BankCode: '7056',
    BranchCode: '95',
    BranchName: 'Card Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '96',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7056',
    BranchCode: '97',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7056',
    BranchCode: '98',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7056',
    BranchCode: '100',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7056',
    BranchCode: '101',
    BranchName: 'Nawala� (C.S.P.)'
  },
  {
    BankCode: '7056',
    BranchCode: '102',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7056',
    BranchCode: '103',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '104',
    BranchName: 'Kamburupitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '105',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7056',
    BranchCode: '106',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '107',
    BranchName: 'Chunakkam'
  },
  {
    BankCode: '7056',
    BranchCode: '108',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7056',
    BranchCode: '109',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7056',
    BranchCode: '110',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7056',
    BranchCode: '111',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7056',
    BranchCode: '112',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7056',
    BranchCode: '113',
    BranchName: 'Baseline'
  },
  {
    BankCode: '7056',
    BranchCode: '114',
    BranchName: 'Katubedda'
  },
  {
    BankCode: '7056',
    BranchCode: '115',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7056',
    BranchCode: '116',
    BranchName: 'Reid Avenue'
  },
  {
    BankCode: '7056',
    BranchCode: '117',
    BranchName: 'Pitakotte CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '118',
    BranchName: 'Negombo Extension Office'
  },
  {
    BankCode: '7056',
    BranchCode: '119',
    BranchName: 'Kotikawatta'
  },
  {
    BankCode: '7056',
    BranchCode: '120',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7056',
    BranchCode: '121',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7056',
    BranchCode: '122',
    BranchName: 'Kurunegala City'
  },
  {
    BankCode: '7056',
    BranchCode: '123',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7056',
    BranchCode: '124',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7056',
    BranchCode: '125',
    BranchName: 'Neluwa'
  },
  {
    BankCode: '7056',
    BranchCode: '126',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7056',
    BranchCode: '127',
    BranchName: 'Stanley Road, Jaffna'
  },
  {
    BankCode: '7056',
    BranchCode: '128',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7056',
    BranchCode: '129',
    BranchName: 'Udugama'
  },
  {
    BankCode: '7056',
    BranchCode: '130',
    BranchName: 'Athurugiriya'
  },
  {
    BankCode: '7056',
    BranchCode: '131',
    BranchName: 'Raddolugama'
  },
  {
    BankCode: '7056',
    BranchCode: '132',
    BranchName: 'Boralesgamuwa CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '133',
    BranchName: 'Kahawatta'
  },
  {
    BankCode: '7056',
    BranchCode: '134',
    BranchName: 'Delkanda'
  },
  {
    BankCode: '7056',
    BranchCode: '135',
    BranchName: 'karapitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '136',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7056',
    BranchCode: '137',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7056',
    BranchCode: '138',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7056',
    BranchCode: '139',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7056',
    BranchCode: '140',
    BranchName: 'Digana'
  },
  {
    BankCode: '7056',
    BranchCode: '141',
    BranchName: 'Polgahawela'
  },
  {
    BankCode: '7056',
    BranchCode: '142',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7056',
    BranchCode: '143',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7056',
    BranchCode: '144',
    BranchName: 'Pannala'
  },
  {
    BankCode: '7056',
    BranchCode: '145',
    BranchName: 'Ward Place'
  },
  {
    BankCode: '7056',
    BranchCode: '146',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7056',
    BranchCode: '147',
    BranchName: 'Biyagama'
  },
  {
    BankCode: '7056',
    BranchCode: '148',
    BranchName: 'Puttlam'
  },
  {
    BankCode: '7056',
    BranchCode: '149',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7056',
    BranchCode: '150',
    BranchName: 'Kandy City office'
  },
  {
    BankCode: '7056',
    BranchCode: '151',
    BranchName: 'Matara City office'
  },
  {
    BankCode: '7056',
    BranchCode: '152',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7056',
    BranchCode: '153',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7056',
    BranchCode: '154',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7056',
    BranchCode: '155',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7056',
    BranchCode: '156',
    BranchName: 'Atchchuvely'
  },
  {
    BankCode: '7056',
    BranchCode: '157',
    BranchName: 'Thirunelvely'
  },
  {
    BankCode: '7056',
    BranchCode: '158',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7056',
    BranchCode: '159',
    BranchName: 'Valachchenai'
  },
  {
    BankCode: '7056',
    BranchCode: '160',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7056',
    BranchCode: '161',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7056',
    BranchCode: '162',
    BranchName: 'Thambuttegama'
  },
  {
    BankCode: '7056',
    BranchCode: '163',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7056',
    BranchCode: '164',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7056',
    BranchCode: '165',
    BranchName: 'Peliyagoda CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '166',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7056',
    BranchCode: '167',
    BranchName: 'Katubedda CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '168',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7056',
    BranchCode: '169',
    BranchName: 'Priority Banking Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '170',
    BranchName: 'Velanai'
  },
  {
    BankCode: '7056',
    BranchCode: '171',
    BranchName: 'Vavuniya� Second'
  },
  {
    BankCode: '7056',
    BranchCode: '172',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7056',
    BranchCode: '173',
    BranchName: 'Kataragama'
  },
  {
    BankCode: '7056',
    BranchCode: '174',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7056',
    BranchCode: '175',
    BranchName: 'Kuruwita'
  },
  {
    BankCode: '7056',
    BranchCode: '176',
    BranchName: 'Middeniya'
  },
  {
    BankCode: '7056',
    BranchCode: '177',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7056',
    BranchCode: '178',
    BranchName: 'World Trade Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '179',
    BranchName: 'Wellawatte 2nd'
  },
  {
    BankCode: '7056',
    BranchCode: '180',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '181',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7056',
    BranchCode: '182',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7056',
    BranchCode: '183',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7056',
    BranchCode: '184',
    BranchName: 'Thalawakelle'
  },
  {
    BankCode: '7056',
    BranchCode: '185',
    BranchName: 'Anuradhapura New Town'
  },
  {
    BankCode: '7056',
    BranchCode: '186',
    BranchName: 'Passara'
  },
  {
    BankCode: '7056',
    BranchCode: '187',
    BranchName: 'Padukka'
  },
  {
    BankCode: '7056',
    BranchCode: '188',
    BranchName: 'Alawwa'
  },
  {
    BankCode: '7056',
    BranchCode: '189',
    BranchName: 'Panadura Second'
  },
  {
    BankCode: '7056',
    BranchCode: '190',
    BranchName: 'Katunayake 24/7 Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '193',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7056',
    BranchCode: '194',
    BranchName: 'Pottuvil'
  },
  {
    BankCode: '7056',
    BranchCode: '195',
    BranchName: 'Maskeliya'
  },
  {
    BankCode: '7056',
    BranchCode: '196',
    BranchName: 'Liberty Plaza CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '197',
    BranchName: 'Godakawela CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '198',
    BranchName: 'Kodikamam'
  },
  {
    BankCode: '7056',
    BranchCode: '199',
    BranchName: 'Makola'
  },
  {
    BankCode: '7056',
    BranchCode: '200',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7056',
    BranchCode: '204',
    BranchName: 'SLIC- CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '206',
    BranchName: 'Palavi'
  },
  {
    BankCode: '7056',
    BranchCode: '208',
    BranchName: 'Nawala (Minicom)'
  },
  {
    BankCode: '7056',
    BranchCode: '209',
    BranchName: 'Maharagama Minicom'
  },
  {
    BankCode: '7056',
    BranchCode: '210',
    BranchName: 'Moratuwa Minicom'
  },
  {
    BankCode: '7056',
    BranchCode: '211',
    BranchName: 'Union Place (Keells Super) Branch'
  },
  {
    BankCode: '7056',
    BranchCode: '212',
    BranchName: 'Kurunegala Service Point'
  },
  {
    BankCode: '7056',
    BranchCode: '213',
    BranchName: 'Ratnapura (Laugfs Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '216',
    BranchName: 'Ramanayake Mawatha'
  },
  {
    BankCode: '7056',
    BranchCode: '217',
    BranchName: 'Kirulapone Minicom'
  },
  {
    BankCode: '7056',
    BranchCode: '218',
    BranchName: 'Gampaha (Minicom)'
  },
  {
    BankCode: '7056',
    BranchCode: '219',
    BranchName: 'Akurana Service Point'
  },
  {
    BankCode: '7056',
    BranchCode: '220',
    BranchName: 'Beruwala CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '221',
    BranchName: 'Ragama'
  },
  {
    BankCode: '7056',
    BranchCode: '222',
    BranchName: 'Matara Minicom Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '223',
    BranchName: 'Panadura CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '224',
    BranchName: 'Horana Service Point'
  },
  {
    BankCode: '7056',
    BranchCode: '225',
    BranchName: 'Wattala Minicom'
  },
  {
    BankCode: '7056',
    BranchCode: '226',
    BranchName: 'Bokundara CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '227',
    BranchName: 'Katukurunda CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '228',
    BranchName: 'Weliweria'
  },
  {
    BankCode: '7056',
    BranchCode: '229',
    BranchName: 'Mulliyawalai'
  },
  {
    BankCode: '7056',
    BranchCode: '230',
    BranchName: 'Ja � Ela (Minicom)'
  },
  {
    BankCode: '7056',
    BranchCode: '231',
    BranchName: 'Attidiya'
  },
  {
    BankCode: '7056',
    BranchCode: '232',
    BranchName: 'Hulftsdorp'
  },
  {
    BankCode: '7056',
    BranchCode: '233',
    BranchName: 'Rajagiriya (Keells Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '234',
    BranchName: 'Kadawatha (Arpico Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '236',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7056',
    BranchCode: '237',
    BranchName: 'Colombo Gold Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '238',
    BranchName: 'Nawinna (Arpico Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '239',
    BranchName: 'Chankanai'
  },
  {
    BankCode: '7056',
    BranchCode: '240',
    BranchName: 'Batapola'
  },
  {
    BankCode: '7056',
    BranchCode: '241',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7056',
    BranchCode: '242',
    BranchName: 'Batticaloa Second'
  },
  {
    BankCode: '7056',
    BranchCode: '244',
    BranchName: 'Badulla Second'
  },
  {
    BankCode: '7056',
    BranchCode: '245',
    BranchName: 'Katugastota (Minicom)'
  },
  {
    BankCode: '7056',
    BranchCode: '246',
    BranchName: 'Wattgama'
  },
  {
    BankCode: '7056',
    BranchCode: '247',
    BranchName: 'Bandarawatta (Laugfs Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '248',
    BranchName: 'Wennapuwa (Arpico) Super'
  },
  {
    BankCode: '7056',
    BranchCode: '249',
    BranchName: 'BIA Counter'
  },
  {
    BankCode: '7056',
    BranchCode: '250',
    BranchName: 'Veyangoda'
  },
  {
    BankCode: '7056',
    BranchCode: '251',
    BranchName: 'Dehiwala Arpico Super Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '252',
    BranchName: 'Batharamulla Arpico Super Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '253',
    BranchName: 'Hydepark Arpico Service Center'
  },
  {
    BankCode: '7056',
    BranchCode: '254',
    BranchName: 'Anniwatte CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '255',
    BranchName: 'Kundasale'
  },
  {
    BankCode: '7056',
    BranchCode: '256',
    BranchName: 'Negambo Arpico Super Centre'
  },
  {
    BankCode: '7056',
    BranchCode: '257',
    BranchName: 'Kiribathgoda Service Point'
  },
  {
    BankCode: '7056',
    BranchCode: '258',
    BranchName: 'Holiday Banking Centre � Majestic City'
  },
  {
    BankCode: '7056',
    BranchCode: '259',
    BranchName: 'Nawam Mawatha'
  },
  {
    BankCode: '7056',
    BranchCode: '260',
    BranchName: 'Wattala Arpico Super Center'
  },
  {
    BankCode: '7056',
    BranchCode: '261',
    BranchName: 'Nittambuwa CSP'
  },
  {
    BankCode: '7056',
    BranchCode: '262',
    BranchName: 'Pelawatte'
  },
  {
    BankCode: '7056',
    BranchCode: '264',
    BranchName: 'Handala (Minicom)'
  },
  {
    BankCode: '7056',
    BranchCode: '265',
    BranchName: 'Gelioya Service Point'
  },
  {
    BankCode: '7056',
    BranchCode: '266',
    BranchName: 'Kohuwala Service Point'
  },
  {
    BankCode: '7056',
    BranchCode: '267',
    BranchName: 'Kalutara (Arpico Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '268',
    BranchName: 'Mattegoda (Laugfs Super)'
  },
  {
    BankCode: '7056',
    BranchCode: '269',
    BranchName: 'Delgoda Laugfs Super'
  },
  {
    BankCode: '7056',
    BranchCode: '270',
    BranchName: 'Orion City ABC'
  },
  {
    BankCode: '7056',
    BranchCode: '271',
    BranchName: 'Galle Main Street'
  },
  {
    BankCode: '7056',
    BranchCode: '272',
    BranchName: 'Kilinochchi Second'
  },
  {
    BankCode: '7056',
    BranchCode: '274',
    BranchName: 'Point Pedro'
  },
  {
    BankCode: '7056',
    BranchCode: '275',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7056',
    BranchCode: '276',
    BranchName: 'Deiyandara'
  },
  {
    BankCode: '7056',
    BranchCode: '277',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7056',
    BranchCode: '278',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7056',
    BranchCode: '279',
    BranchName: 'Katana'
  },
  {
    BankCode: '7056',
    BranchCode: '280',
    BranchName: 'Bopitiya'
  },
  {
    BankCode: '7056',
    BranchCode: '281',
    BranchName: 'Yatiyantota'
  },
  {
    BankCode: '7056',
    BranchCode: '282',
    BranchName: 'Kolonnawa'
  },
  {
    BankCode: '7056',
    BranchCode: '283',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7056',
    BranchCode: '284',
    BranchName: 'Enderamulla'
  },
  {
    BankCode: '7056',
    BranchCode: '286',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7056',
    BranchCode: '287',
    BranchName: 'Embuldeniya'
  },
  {
    BankCode: '7056',
    BranchCode: '288',
    BranchName: 'Teldeniya'
  },
  {
    BankCode: '7056',
    BranchCode: '901',
    BranchName: 'Islamic Banking Unit'
  },
  {
    BankCode: '7056',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7074',
    BranchCode: '0',
    BranchName: 'Habib Bank Ltd'
  },
  {
    BankCode: '7074',
    BranchCode: '1',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7074',
    BranchCode: '2',
    BranchName: 'Dharmapala Mawatha'
  },
  {
    BankCode: '7074',
    BranchCode: '3',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7074',
    BranchCode: '5',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7074',
    BranchCode: '6',
    BranchName: 'Galle'
  },
  {
    BankCode: '7074',
    BranchCode: '7',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7074',
    BranchCode: '21',
    BranchName: 'Islamic Banking'
  },
  {
    BankCode: '7074',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7083',
    BranchCode: '1',
    BranchName: 'Aluthkade'
  },
  {
    BankCode: '7083',
    BranchCode: '2',
    BranchName: 'City Office'
  },
  {
    BankCode: '7083',
    BranchCode: '3',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7083',
    BranchCode: '4',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7083',
    BranchCode: '5',
    BranchName: 'Green Path'
  },
  {
    BankCode: '7083',
    BranchCode: '6',
    BranchName: 'Maligawatta'
  },
  {
    BankCode: '7083',
    BranchCode: '7',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7083',
    BranchCode: '9',
    BranchName: 'Wellawatta'
  },
  {
    BankCode: '7083',
    BranchCode: '10',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7083',
    BranchCode: '11',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7083',
    BranchCode: '12',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7083',
    BranchCode: '13',
    BranchName: 'Galle'
  },
  {
    BankCode: '7083',
    BranchCode: '14',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7083',
    BranchCode: '15',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7083',
    BranchCode: '16',
    BranchName: 'Jaffna Metro'
  },
  {
    BankCode: '7083',
    BranchCode: '17',
    BranchName: 'Kahawatte'
  },
  {
    BankCode: '7083',
    BranchCode: '18',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7083',
    BranchCode: '19',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7083',
    BranchCode: '20',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7083',
    BranchCode: '21',
    BranchName: 'Maskeliya'
  },
  {
    BankCode: '7083',
    BranchCode: '22',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '23',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '24',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7083',
    BranchCode: '25',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '26',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7083',
    BranchCode: '27',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7083',
    BranchCode: '28',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7083',
    BranchCode: '29',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7083',
    BranchCode: '30',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7083',
    BranchCode: '31',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7083',
    BranchCode: '32',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7083',
    BranchCode: '33',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7083',
    BranchCode: '34',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7083',
    BranchCode: '35',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7083',
    BranchCode: '36',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7083',
    BranchCode: '38',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7083',
    BranchCode: '39',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '40',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7083',
    BranchCode: '41',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7083',
    BranchCode: '42',
    BranchName: 'Matara'
  },
  {
    BankCode: '7083',
    BranchCode: '43',
    BranchName: 'Kirulapone'
  },
  {
    BankCode: '7083',
    BranchCode: '44',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7083',
    BranchCode: '45',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7083',
    BranchCode: '46',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7083',
    BranchCode: '47',
    BranchName: 'Biyagama'
  },
  {
    BankCode: '7083',
    BranchCode: '48',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7083',
    BranchCode: '49',
    BranchName: 'Katunayake'
  },
  {
    BankCode: '7083',
    BranchCode: '50',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '51',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7083',
    BranchCode: '52',
    BranchName: 'Horana'
  },
  {
    BankCode: '7083',
    BranchCode: '53',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7083',
    BranchCode: '54',
    BranchName: 'International Division'
  },
  {
    BankCode: '7083',
    BranchCode: '55',
    BranchName: 'Borella'
  },
  {
    BankCode: '7083',
    BranchCode: '56',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7083',
    BranchCode: '57',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7083',
    BranchCode: '58',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7083',
    BranchCode: '59',
    BranchName: 'Panchikawatta'
  },
  {
    BankCode: '7083',
    BranchCode: '60',
    BranchName: 'Bogawanthalawa'
  },
  {
    BankCode: '7083',
    BranchCode: '61',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7083',
    BranchCode: '63',
    BranchName: 'Hulftsdorp'
  },
  {
    BankCode: '7083',
    BranchCode: '64',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7083',
    BranchCode: '65',
    BranchName: 'Matale'
  },
  {
    BankCode: '7083',
    BranchCode: '66',
    BranchName: 'Pinnawala'
  },
  {
    BankCode: '7083',
    BranchCode: '67',
    BranchName: 'Suriyawewa'
  },
  {
    BankCode: '7083',
    BranchCode: '68',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7083',
    BranchCode: '69',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7083',
    BranchCode: '70',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '71',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7083',
    BranchCode: '72',
    BranchName: 'Sea Street'
  },
  {
    BankCode: '7083',
    BranchCode: '73',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7083',
    BranchCode: '74',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '75',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7083',
    BranchCode: '76',
    BranchName: 'Cinnamon Gardens'
  },
  {
    BankCode: '7083',
    BranchCode: '77',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7083',
    BranchCode: '78',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7083',
    BranchCode: '79',
    BranchName: 'Marandagahamula'
  },
  {
    BankCode: '7083',
    BranchCode: '80',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7083',
    BranchCode: '81',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7083',
    BranchCode: '82',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7083',
    BranchCode: '83',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7083',
    BranchCode: '84',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7083',
    BranchCode: '85',
    BranchName: 'Talangama'
  },
  {
    BankCode: '7083',
    BranchCode: '86',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7083',
    BranchCode: '87',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7083',
    BranchCode: '88',
    BranchName: 'Thambuttegama'
  },
  {
    BankCode: '7083',
    BranchCode: '89',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7083',
    BranchCode: '90',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7083',
    BranchCode: '91',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7083',
    BranchCode: '92',
    BranchName: 'Thimbirigasyaya'
  },
  {
    BankCode: '7083',
    BranchCode: '93',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7083',
    BranchCode: '94',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7083',
    BranchCode: '95',
    BranchName: 'Kantale'
  },
  {
    BankCode: '7083',
    BranchCode: '96',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7083',
    BranchCode: '97',
    BranchName: 'Mutwal'
  },
  {
    BankCode: '7083',
    BranchCode: '98',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7083',
    BranchCode: '99',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7083',
    BranchCode: '100',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7083',
    BranchCode: '101',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7083',
    BranchCode: '102',
    BranchName: 'Ragama'
  },
  {
    BankCode: '7083',
    BranchCode: '103',
    BranchName: 'Dematagoda'
  },
  {
    BankCode: '7083',
    BranchCode: '104',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7083',
    BranchCode: '105',
    BranchName: 'Treasury Division'
  },
  {
    BankCode: '7083',
    BranchCode: '106',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7083',
    BranchCode: '107',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '108',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7083',
    BranchCode: '109',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7083',
    BranchCode: '110',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '111',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7083',
    BranchCode: '112',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '113',
    BranchName: 'Card Centre'
  },
  {
    BankCode: '7083',
    BranchCode: '115',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '117',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7083',
    BranchCode: '118',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7083',
    BranchCode: '119',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7083',
    BranchCode: '120',
    BranchName: 'Deniyaya Customer Centre'
  },
  {
    BankCode: '7083',
    BranchCode: '121',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7083',
    BranchCode: '122',
    BranchName: 'Delgoda'
  },
  {
    BankCode: '7083',
    BranchCode: '123',
    BranchName: 'Alawwa'
  },
  {
    BankCode: '7083',
    BranchCode: '124',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7083',
    BranchCode: '125',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7083',
    BranchCode: '126',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7083',
    BranchCode: '127',
    BranchName: 'Middeniya'
  },
  {
    BankCode: '7083',
    BranchCode: '128',
    BranchName: 'Galgamuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '129',
    BranchName: 'Kohuwela'
  },
  {
    BankCode: '7083',
    BranchCode: '130',
    BranchName: 'Weliweriya'
  },
  {
    BankCode: '7083',
    BranchCode: '131',
    BranchName: 'Hendala'
  },
  {
    BankCode: '7083',
    BranchCode: '132',
    BranchName: 'Point Pedro'
  },
  {
    BankCode: '7083',
    BranchCode: '133',
    BranchName: 'Norochchole'
  },
  {
    BankCode: '7083',
    BranchCode: '134',
    BranchName: 'Thirukovil'
  },
  {
    BankCode: '7083',
    BranchCode: '135',
    BranchName: 'Eravur'
  },
  {
    BankCode: '7083',
    BranchCode: '136',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7083',
    BranchCode: '137',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7083',
    BranchCode: '138',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7083',
    BranchCode: '139',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7083',
    BranchCode: '140',
    BranchName: 'Padukka'
  },
  {
    BankCode: '7083',
    BranchCode: '141',
    BranchName: 'Pilimatalawa'
  },
  {
    BankCode: '7083',
    BranchCode: '142',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7083',
    BranchCode: '143',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7083',
    BranchCode: '144',
    BranchName: 'Thirunelvely'
  },
  {
    BankCode: '7083',
    BranchCode: '145',
    BranchName: 'Negombo Metro'
  },
  {
    BankCode: '7083',
    BranchCode: '146',
    BranchName: 'Kilinochchi South'
  },
  {
    BankCode: '7083',
    BranchCode: '147',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7083',
    BranchCode: '148',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7083',
    BranchCode: '149',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7083',
    BranchCode: '150',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7083',
    BranchCode: '151',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7083',
    BranchCode: '152',
    BranchName: 'Hettipola'
  },
  {
    BankCode: '7083',
    BranchCode: '153',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7083',
    BranchCode: '154',
    BranchName: 'Athurugiriya'
  },
  {
    BankCode: '7083',
    BranchCode: '155',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7083',
    BranchCode: '156',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7083',
    BranchCode: '157',
    BranchName: 'Chankanai'
  },
  {
    BankCode: '7083',
    BranchCode: '158',
    BranchName: 'Pottuvil'
  },
  {
    BankCode: '7083',
    BranchCode: '159',
    BranchName: 'Ninthavur'
  },
  {
    BankCode: '7083',
    BranchCode: '160',
    BranchName: 'Beruwela'
  },
  {
    BankCode: '7083',
    BranchCode: '161',
    BranchName: 'Velanai'
  },
  {
    BankCode: '7083',
    BranchCode: '162',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7083',
    BranchCode: '163',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7083',
    BranchCode: '164',
    BranchName: 'Thandenweli'
  },
  {
    BankCode: '7083',
    BranchCode: '165',
    BranchName: 'Kaluwanchikudy'
  },
  {
    BankCode: '7083',
    BranchCode: '166',
    BranchName: 'Pugoda'
  },
  {
    BankCode: '7083',
    BranchCode: '167',
    BranchName: 'Valachchenai'
  },
  {
    BankCode: '7083',
    BranchCode: '168',
    BranchName: 'Madampe'
  },
  {
    BankCode: '7083',
    BranchCode: '169',
    BranchName: 'Kinniya'
  },
  {
    BankCode: '7083',
    BranchCode: '170',
    BranchName: 'Siyabalanduwa'
  },
  {
    BankCode: '7083',
    BranchCode: '171',
    BranchName: 'Udappuwa'
  },
  {
    BankCode: '7083',
    BranchCode: '172',
    BranchName: 'Nanattan'
  },
  {
    BankCode: '7083',
    BranchCode: '173',
    BranchName: 'Ginigathhena'
  },
  {
    BankCode: '7083',
    BranchCode: '174',
    BranchName: 'Mullipothanai'
  },
  {
    BankCode: '7083',
    BranchCode: '175',
    BranchName: 'Uppuvelli'
  },
  {
    BankCode: '7083',
    BranchCode: '176',
    BranchName: 'Digana'
  },
  {
    BankCode: '7083',
    BranchCode: '177',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7083',
    BranchCode: '178',
    BranchName: 'Dikwella'
  },
  {
    BankCode: '7083',
    BranchCode: '179',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7083',
    BranchCode: '180',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7083',
    BranchCode: '181',
    BranchName: 'Padavi Parakramapura'
  },
  {
    BankCode: '7083',
    BranchCode: '182',
    BranchName: 'Uhana'
  },
  {
    BankCode: '7083',
    BranchCode: '183',
    BranchName: 'Mullativu'
  },
  {
    BankCode: '7083',
    BranchCode: '184',
    BranchName: 'Karaitive'
  },
  {
    BankCode: '7083',
    BranchCode: '185',
    BranchName: 'Maruthamunai'
  },
  {
    BankCode: '7083',
    BranchCode: '186',
    BranchName: 'Serunuwara'
  },
  {
    BankCode: '7083',
    BranchCode: '187',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7083',
    BranchCode: '188',
    BranchName: 'Kundasale'
  },
  {
    BankCode: '7083',
    BranchCode: '189',
    BranchName: 'Atchchuvely'
  },
  {
    BankCode: '7083',
    BranchCode: '190',
    BranchName: 'Kodikamam'
  },
  {
    BankCode: '7083',
    BranchCode: '191',
    BranchName: 'Muthur'
  },
  {
    BankCode: '7083',
    BranchCode: '192',
    BranchName: 'Kallady'
  },
  {
    BankCode: '7083',
    BranchCode: '193',
    BranchName: 'Aralaganwila'
  },
  {
    BankCode: '7083',
    BranchCode: '194',
    BranchName: 'Kolonnawa'
  },
  {
    BankCode: '7083',
    BranchCode: '195',
    BranchName: 'Killinochchi North'
  },
  {
    BankCode: '7083',
    BranchCode: '196',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7083',
    BranchCode: '197',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7083',
    BranchCode: '198',
    BranchName: 'Galaha'
  },
  {
    BankCode: '7083',
    BranchCode: '199',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7083',
    BranchCode: '200',
    BranchName: 'Hakmana'
  },
  {
    BankCode: '7083',
    BranchCode: '201',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7083',
    BranchCode: '202',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7083',
    BranchCode: '203',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7083',
    BranchCode: '204',
    BranchName: 'Mirihana'
  },
  {
    BankCode: '7083',
    BranchCode: '205',
    BranchName: 'Mulliyawalai'
  },
  {
    BankCode: '7083',
    BranchCode: '206',
    BranchName: 'Kurumankadu'
  },
  {
    BankCode: '7083',
    BranchCode: '207',
    BranchName: 'Jampettah Street'
  },
  {
    BankCode: '7083',
    BranchCode: '208',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7083',
    BranchCode: '209',
    BranchName: 'Seeduwa'
  },
  {
    BankCode: '7083',
    BranchCode: '210',
    BranchName: 'Pamunugama'
  },
  {
    BankCode: '7083',
    BranchCode: '211',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7083',
    BranchCode: '212',
    BranchName: 'Mallavi'
  },
  {
    BankCode: '7083',
    BranchCode: '213',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7083',
    BranchCode: '214',
    BranchName: 'Veyangoda'
  },
  {
    BankCode: '7083',
    BranchCode: '215',
    BranchName: 'Batapola'
  },
  {
    BankCode: '7083',
    BranchCode: '216',
    BranchName: 'Yakkalamulla'
  },
  {
    BankCode: '7083',
    BranchCode: '217',
    BranchName: 'Walasmulla'
  },
  {
    BankCode: '7083',
    BranchCode: '218',
    BranchName: 'Gelioya'
  },
  {
    BankCode: '7083',
    BranchCode: '219',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7083',
    BranchCode: '220',
    BranchName: 'Passara'
  },
  {
    BankCode: '7083',
    BranchCode: '221',
    BranchName: 'Pamankada'
  },
  {
    BankCode: '7083',
    BranchCode: '222',
    BranchName: 'Sammanthurai'
  },
  {
    BankCode: '7083',
    BranchCode: '223',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7083',
    BranchCode: '224',
    BranchName: 'Kurunegala Metro'
  },
  {
    BankCode: '7083',
    BranchCode: '225',
    BranchName: 'Trincomalee Metro'
  },
  {
    BankCode: '7083',
    BranchCode: '226',
    BranchName: 'Wijerama'
  },
  {
    BankCode: '7083',
    BranchCode: '227',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '228',
    BranchName: 'Peliyagoda'
  },
  {
    BankCode: '7083',
    BranchCode: '229',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7083',
    BranchCode: '230',
    BranchName: 'Ekala'
  },
  {
    BankCode: '7083',
    BranchCode: '231',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7083',
    BranchCode: '232',
    BranchName: 'Anuradhapura Metro'
  },
  {
    BankCode: '7083',
    BranchCode: '233',
    BranchName: 'Bibile'
  },
  {
    BankCode: '7083',
    BranchCode: '234',
    BranchName: 'Haputale'
  },
  {
    BankCode: '7083',
    BranchCode: '235',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7083',
    BranchCode: '236',
    BranchName: 'Akurana'
  },
  {
    BankCode: '7083',
    BranchCode: '237',
    BranchName: 'Koggala'
  },
  {
    BankCode: '7083',
    BranchCode: '238',
    BranchName: 'Devinuwara'
  },
  {
    BankCode: '7083',
    BranchCode: '239',
    BranchName: 'Angunakolapelassa'
  },
  {
    BankCode: '7083',
    BranchCode: '240',
    BranchName: 'Kataragama'
  },
  {
    BankCode: '7083',
    BranchCode: '241',
    BranchName: 'Kaithady'
  },
  {
    BankCode: '7083',
    BranchCode: '243',
    BranchName: 'Kurunduwatte'
  },
  {
    BankCode: '7083',
    BranchCode: '244',
    BranchName: 'Ragala'
  },
  {
    BankCode: '7083',
    BranchCode: '245',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7083',
    BranchCode: '246',
    BranchName: 'Thalawakele'
  },
  {
    BankCode: '7083',
    BranchCode: '247',
    BranchName: 'Pettah Metro'
  },
  {
    BankCode: '7083',
    BranchCode: '248',
    BranchName: 'Lotus Road'
  },
  {
    BankCode: '7083',
    BranchCode: '249',
    BranchName: 'Asiri Surgical Hospital'
  },
  {
    BankCode: '7083',
    BranchCode: '250',
    BranchName: 'Islamic Banking Unit'
  },
  {
    BankCode: '7083',
    BranchCode: '251',
    BranchName: 'Lanka Hospital'
  },
  {
    BankCode: '7083',
    BranchCode: '500',
    BranchName: 'Digital Branch'
  },
  {
    BankCode: '7083',
    BranchCode: '701',
    BranchName: 'Nawam Mawatha'
  },
  {
    BankCode: '7083',
    BranchCode: '703',
    BranchName: 'World Trade Centre'
  },
  {
    BankCode: '7092',
    BranchCode: '0',
    BranchName: 'Hongkong & Shanghai Bank'
  },
  {
    BankCode: '7092',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7092',
    BranchCode: '2',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7092',
    BranchCode: '3',
    BranchName: 'Colpetty'
  },
  {
    BankCode: '7092',
    BranchCode: '4',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7092',
    BranchCode: '5',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7092',
    BranchCode: '6',
    BranchName: 'World Trade Center'
  },
  {
    BankCode: '7092',
    BranchCode: '7',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7092',
    BranchCode: '8',
    BranchName: 'Pelawatte'
  },
  {
    BankCode: '7092',
    BranchCode: '12',
    BranchName: 'Union Place'
  },
  {
    BankCode: '7092',
    BranchCode: '14',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7092',
    BranchCode: '15',
    BranchName: 'Premier Centre'
  },
  {
    BankCode: '7092',
    BranchCode: '17',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7092',
    BranchCode: '18',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7092',
    BranchCode: '20',
    BranchName: 'kohuwela'
  },
  {
    BankCode: '7092',
    BranchCode: '21',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7092',
    BranchCode: '22',
    BranchName: 'Galle'
  },
  {
    BankCode: '7092',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7108',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7108',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7108',
    BranchCode: '2',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7108',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7117',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7117',
    BranchCode: '1',
    BranchName: 'Matara'
  },
  {
    BankCode: '7117',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7135',
    BranchCode: '1',
    BranchName: 'Duke Street'
  },
  {
    BankCode: '7135',
    BranchCode: '2',
    BranchName: 'Matale'
  },
  {
    BankCode: '7135',
    BranchCode: '3',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7135',
    BranchCode: '4',
    BranchName: 'International Division'
  },
  {
    BankCode: '7135',
    BranchCode: '5',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7135',
    BranchCode: '6',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7135',
    BranchCode: '7',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7135',
    BranchCode: '8',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7135',
    BranchCode: '9',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7135',
    BranchCode: '10',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7135',
    BranchCode: '11',
    BranchName: 'Bibile'
  },
  {
    BankCode: '7135',
    BranchCode: '12',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7135',
    BranchCode: '13',
    BranchName: 'Galle Fort'
  },
  {
    BankCode: '7135',
    BranchCode: '14',
    BranchName: 'Union Place'
  },
  {
    BankCode: '7135',
    BranchCode: '15',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7135',
    BranchCode: '16',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7135',
    BranchCode: '17',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7135',
    BranchCode: '18',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7135',
    BranchCode: '19',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7135',
    BranchCode: '20',
    BranchName: 'Mulativu'
  },
  {
    BankCode: '7135',
    BranchCode: '21',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7135',
    BranchCode: '22',
    BranchName: 'Hanguranketha'
  },
  {
    BankCode: '7135',
    BranchCode: '23',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7135',
    BranchCode: '24',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7135',
    BranchCode: '25',
    BranchName: 'Hyde park Corner'
  },
  {
    BankCode: '7135',
    BranchCode: '26',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7135',
    BranchCode: '27',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7135',
    BranchCode: '28',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '29',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7135',
    BranchCode: '30',
    BranchName: 'Jaffna Stanley Road'
  },
  {
    BankCode: '7135',
    BranchCode: '31',
    BranchName: 'Tellipalai'
  },
  {
    BankCode: '7135',
    BranchCode: '32',
    BranchName: 'Matara Uyanwatta'
  },
  {
    BankCode: '7135',
    BranchCode: '33',
    BranchName: 'Queens'
  },
  {
    BankCode: '7135',
    BranchCode: '34',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7135',
    BranchCode: '35',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7135',
    BranchCode: '36',
    BranchName: 'Ragala'
  },
  {
    BankCode: '7135',
    BranchCode: '37',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7135',
    BranchCode: '38',
    BranchName: 'Talawakele'
  },
  {
    BankCode: '7135',
    BranchCode: '39',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7135',
    BranchCode: '40',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7135',
    BranchCode: '41',
    BranchName: 'Horana'
  },
  {
    BankCode: '7135',
    BranchCode: '42',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7135',
    BranchCode: '43',
    BranchName: 'Padaviya'
  },
  {
    BankCode: '7135',
    BranchCode: '44',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7135',
    BranchCode: '45',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '46',
    BranchName: 'Mudalige Mawatha'
  },
  {
    BankCode: '7135',
    BranchCode: '47',
    BranchName: 'Yatiyantota'
  },
  {
    BankCode: '7135',
    BranchCode: '48',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7135',
    BranchCode: '49',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7135',
    BranchCode: '51',
    BranchName: 'Kahatagasdigiliya'
  },
  {
    BankCode: '7135',
    BranchCode: '52',
    BranchName: 'Maho'
  },
  {
    BankCode: '7135',
    BranchCode: '53',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '54',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7135',
    BranchCode: '55',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7135',
    BranchCode: '56',
    BranchName: 'Sri Sangaraja Mawatha'
  },
  {
    BankCode: '7135',
    BranchCode: '57',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7135',
    BranchCode: '58',
    BranchName: 'Mahiyangana'
  },
  {
    BankCode: '7135',
    BranchCode: '59',
    BranchName: 'Polgahawela'
  },
  {
    BankCode: '7135',
    BranchCode: '60',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7135',
    BranchCode: '61',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7135',
    BranchCode: '62',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7135',
    BranchCode: '63',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7135',
    BranchCode: '64',
    BranchName: 'Samanthurai'
  },
  {
    BankCode: '7135',
    BranchCode: '65',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7135',
    BranchCode: '66',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7135',
    BranchCode: '67',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7135',
    BranchCode: '68',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7135',
    BranchCode: '69',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7135',
    BranchCode: '70',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7135',
    BranchCode: '71',
    BranchName: 'Dematagoda'
  },
  {
    BankCode: '7135',
    BranchCode: '72',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7135',
    BranchCode: '73',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '74',
    BranchName: 'Wattegama'
  },
  {
    BankCode: '7135',
    BranchCode: '75',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7135',
    BranchCode: '76',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '77',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7135',
    BranchCode: '78',
    BranchName: 'Borella'
  },
  {
    BankCode: '7135',
    BranchCode: '79',
    BranchName: 'Veyangoda'
  },
  {
    BankCode: '7135',
    BranchCode: '80',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7135',
    BranchCode: '81',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7135',
    BranchCode: '82',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7135',
    BranchCode: '83',
    BranchName: 'Nattandiya'
  },
  {
    BankCode: '7135',
    BranchCode: '84',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7135',
    BranchCode: '85',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7135',
    BranchCode: '86',
    BranchName: 'Thimbirigasyaya'
  },
  {
    BankCode: '7135',
    BranchCode: '87',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7135',
    BranchCode: '88',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7135',
    BranchCode: '89',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7135',
    BranchCode: '90',
    BranchName: 'Kantale'
  },
  {
    BankCode: '7135',
    BranchCode: '91',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '92',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7135',
    BranchCode: '93',
    BranchName: 'Pugoda'
  },
  {
    BankCode: '7135',
    BranchCode: '94',
    BranchName: 'Kinniya'
  },
  {
    BankCode: '7135',
    BranchCode: '95',
    BranchName: 'Muttur'
  },
  {
    BankCode: '7135',
    BranchCode: '96',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7135',
    BranchCode: '97',
    BranchName: 'Gangodawila'
  },
  {
    BankCode: '7135',
    BranchCode: '98',
    BranchName: 'Kotikawatte'
  },
  {
    BankCode: '7135',
    BranchCode: '100',
    BranchName: 'Marandagahamula'
  },
  {
    BankCode: '7135',
    BranchCode: '101',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7135',
    BranchCode: '102',
    BranchName: 'Valaichechenai'
  },
  {
    BankCode: '7135',
    BranchCode: '103',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7135',
    BranchCode: '104',
    BranchName: 'Jaffna Main Street'
  },
  {
    BankCode: '7135',
    BranchCode: '105',
    BranchName: 'Kayts'
  },
  {
    BankCode: '7135',
    BranchCode: '106',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7135',
    BranchCode: '107',
    BranchName: 'Atchchuvely'
  },
  {
    BankCode: '7135',
    BranchCode: '108',
    BranchName: 'Chankanai'
  },
  {
    BankCode: '7135',
    BranchCode: '109',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7135',
    BranchCode: '110',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7135',
    BranchCode: '111',
    BranchName: 'Paranthan'
  },
  {
    BankCode: '7135',
    BranchCode: '112',
    BranchName: 'Teldeniya'
  },
  {
    BankCode: '7135',
    BranchCode: '113',
    BranchName: 'Batticaloa Town'
  },
  {
    BankCode: '7135',
    BranchCode: '114',
    BranchName: 'Galagedera'
  },
  {
    BankCode: '7135',
    BranchCode: '115',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7135',
    BranchCode: '116',
    BranchName: 'Passara'
  },
  {
    BankCode: '7135',
    BranchCode: '117',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7135',
    BranchCode: '118',
    BranchName: 'Delgoda'
  },
  {
    BankCode: '7135',
    BranchCode: '119',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7135',
    BranchCode: '120',
    BranchName: 'Walasmulla'
  },
  {
    BankCode: '7135',
    BranchCode: '121',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7135',
    BranchCode: '122',
    BranchName: 'Wilgamuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '123',
    BranchName: 'Eravur'
  },
  {
    BankCode: '7135',
    BranchCode: '124',
    BranchName: 'Nikeweratiya'
  },
  {
    BankCode: '7135',
    BranchCode: '125',
    BranchName: 'Kalpitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '126',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7135',
    BranchCode: '127',
    BranchName: 'Nildandahinna.'
  },
  {
    BankCode: '7135',
    BranchCode: '128',
    BranchName: 'Ratthota'
  },
  {
    BankCode: '7135',
    BranchCode: '129',
    BranchName: 'Rakwana'
  },
  {
    BankCode: '7135',
    BranchCode: '130',
    BranchName: 'Hakmana'
  },
  {
    BankCode: '7135',
    BranchCode: '131',
    BranchName: 'Udugama'
  },
  {
    BankCode: '7135',
    BranchCode: '132',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7135',
    BranchCode: '133',
    BranchName: 'Kamburupitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '134',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7135',
    BranchCode: '135',
    BranchName: 'Dickwella'
  },
  {
    BankCode: '7135',
    BranchCode: '136',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7135',
    BranchCode: '137',
    BranchName: 'Makandura'
  },
  {
    BankCode: '7135',
    BranchCode: '138',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7135',
    BranchCode: '139',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7135',
    BranchCode: '140',
    BranchName: 'Hasalaka'
  },
  {
    BankCode: '7135',
    BranchCode: '141',
    BranchName: 'Velvetiturai'
  },
  {
    BankCode: '7135',
    BranchCode: '142',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7135',
    BranchCode: '143',
    BranchName: 'Suduwella'
  },
  {
    BankCode: '7135',
    BranchCode: '144',
    BranchName: 'Hettipola'
  },
  {
    BankCode: '7135',
    BranchCode: '145',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7135',
    BranchCode: '146',
    BranchName: 'Naula'
  },
  {
    BankCode: '7135',
    BranchCode: '147',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7135',
    BranchCode: '148',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7135',
    BranchCode: '149',
    BranchName: 'Alawwa'
  },
  {
    BankCode: '7135',
    BranchCode: '150',
    BranchName: 'Kebithigollawa'
  },
  {
    BankCode: '7135',
    BranchCode: '151',
    BranchName: 'Diyatalawa'
  },
  {
    BankCode: '7135',
    BranchCode: '152',
    BranchName: 'Matara Dharmapala Mawatha'
  },
  {
    BankCode: '7135',
    BranchCode: '153',
    BranchName: 'Akurana'
  },
  {
    BankCode: '7135',
    BranchCode: '154',
    BranchName: 'Balapitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '155',
    BranchName: 'Kahawatte'
  },
  {
    BankCode: '7135',
    BranchCode: '156',
    BranchName: 'Uva-Paranagama'
  },
  {
    BankCode: '7135',
    BranchCode: '157',
    BranchName: 'Menikhinna'
  },
  {
    BankCode: '7135',
    BranchCode: '158',
    BranchName: 'Senkadagala'
  },
  {
    BankCode: '7135',
    BranchCode: '159',
    BranchName: 'Kadugannawa'
  },
  {
    BankCode: '7135',
    BranchCode: '160',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7135',
    BranchCode: '161',
    BranchName: 'Bulathsinhala'
  },
  {
    BankCode: '7135',
    BranchCode: '162',
    BranchName: 'Jaffna University'
  },
  {
    BankCode: '7135',
    BranchCode: '163',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7135',
    BranchCode: '164',
    BranchName: 'Potuvil'
  },
  {
    BankCode: '7135',
    BranchCode: '165',
    BranchName: 'Mankulam'
  },
  {
    BankCode: '7135',
    BranchCode: '166',
    BranchName: 'Murunkan'
  },
  {
    BankCode: '7135',
    BranchCode: '167',
    BranchName: 'Town Hall, Colombo'
  },
  {
    BankCode: '7135',
    BranchCode: '168',
    BranchName: 'Kataragama'
  },
  {
    BankCode: '7135',
    BranchCode: '169',
    BranchName: 'Galle Main Street'
  },
  {
    BankCode: '7135',
    BranchCode: '170',
    BranchName: 'Eppawela'
  },
  {
    BankCode: '7135',
    BranchCode: '171',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7135',
    BranchCode: '172',
    BranchName: 'Bingiriya'
  },
  {
    BankCode: '7135',
    BranchCode: '173',
    BranchName: 'Pundaluoya'
  },
  {
    BankCode: '7135',
    BranchCode: '174',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7135',
    BranchCode: '175',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7135',
    BranchCode: '176',
    BranchName: 'Mid City'
  },
  {
    BankCode: '7135',
    BranchCode: '177',
    BranchName: 'Galenbindunuwewa'
  },
  {
    BankCode: '7135',
    BranchCode: '178',
    BranchName: 'Maskeliya'
  },
  {
    BankCode: '7135',
    BranchCode: '179',
    BranchName: 'Galnewa'
  },
  {
    BankCode: '7135',
    BranchCode: '180',
    BranchName: 'Deraniyagala'
  },
  {
    BankCode: '7135',
    BranchCode: '181',
    BranchName: 'Maha � Oya'
  },
  {
    BankCode: '7135',
    BranchCode: '183',
    BranchName: 'Ankumbura'
  },
  {
    BankCode: '7135',
    BranchCode: '184',
    BranchName: 'Galgamuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '185',
    BranchName: 'Galigamuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '186',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7135',
    BranchCode: '188',
    BranchName: 'Ahangama'
  },
  {
    BankCode: '7135',
    BranchCode: '189',
    BranchName: 'Uhana'
  },
  {
    BankCode: '7135',
    BranchCode: '190',
    BranchName: 'Kaluwanchikudy'
  },
  {
    BankCode: '7135',
    BranchCode: '191',
    BranchName: 'Malwana'
  },
  {
    BankCode: '7135',
    BranchCode: '192',
    BranchName: 'Nivitigala'
  },
  {
    BankCode: '7135',
    BranchCode: '193',
    BranchName: 'Ridigama'
  },
  {
    BankCode: '7135',
    BranchCode: '194',
    BranchName: 'Kolonnawa'
  },
  {
    BankCode: '7135',
    BranchCode: '195',
    BranchName: 'Haldummulla'
  },
  {
    BankCode: '7135',
    BranchCode: '196',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7135',
    BranchCode: '197',
    BranchName: 'Uragasmanhandiya'
  },
  {
    BankCode: '7135',
    BranchCode: '198',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7135',
    BranchCode: '199',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7135',
    BranchCode: '200',
    BranchName: 'Majestic City'
  },
  {
    BankCode: '7135',
    BranchCode: '201',
    BranchName: 'Ukuwela'
  },
  {
    BankCode: '7135',
    BranchCode: '202',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7135',
    BranchCode: '203',
    BranchName: 'Habarana'
  },
  {
    BankCode: '7135',
    BranchCode: '204',
    BranchName: 'Head Quarters'
  },
  {
    BankCode: '7135',
    BranchCode: '205',
    BranchName: 'Angunakolapalessa'
  },
  {
    BankCode: '7135',
    BranchCode: '206',
    BranchName: 'Davulagala'
  },
  {
    BankCode: '7135',
    BranchCode: '207',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '208',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7135',
    BranchCode: '209',
    BranchName: 'Boralanda'
  },
  {
    BankCode: '7135',
    BranchCode: '210',
    BranchName: 'Kollupitiya Co-op House'
  },
  {
    BankCode: '7135',
    BranchCode: '211',
    BranchName: 'Panwila'
  },
  {
    BankCode: '7135',
    BranchCode: '214',
    BranchName: 'Mutuwal'
  },
  {
    BankCode: '7135',
    BranchCode: '215',
    BranchName: 'Madampe'
  },
  {
    BankCode: '7135',
    BranchCode: '216',
    BranchName: 'Haputale'
  },
  {
    BankCode: '7135',
    BranchCode: '217',
    BranchName: 'Mahara'
  },
  {
    BankCode: '7135',
    BranchCode: '218',
    BranchName: 'Horowpathana'
  },
  {
    BankCode: '7135',
    BranchCode: '219',
    BranchName: 'Tambuttegama'
  },
  {
    BankCode: '7135',
    BranchCode: '220',
    BranchName: 'Anuradhapura-Nuwarawewa'
  },
  {
    BankCode: '7135',
    BranchCode: '221',
    BranchName: 'Hemmathagama'
  },
  {
    BankCode: '7135',
    BranchCode: '222',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7135',
    BranchCode: '223',
    BranchName: 'Karaitivu'
  },
  {
    BankCode: '7135',
    BranchCode: '224',
    BranchName: 'Thirukkovil'
  },
  {
    BankCode: '7135',
    BranchCode: '225',
    BranchName: 'Haliela'
  },
  {
    BankCode: '7135',
    BranchCode: '226',
    BranchName: 'Kurunegala Maliyadeva'
  },
  {
    BankCode: '7135',
    BranchCode: '227',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7135',
    BranchCode: '228',
    BranchName: 'Addalachchene'
  },
  {
    BankCode: '7135',
    BranchCode: '229',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7135',
    BranchCode: '230',
    BranchName: 'Tanamalwila'
  },
  {
    BankCode: '7135',
    BranchCode: '231',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7135',
    BranchCode: '232',
    BranchName: 'Polonnaruwa Town'
  },
  {
    BankCode: '7135',
    BranchCode: '233',
    BranchName: 'Serunuwara'
  },
  {
    BankCode: '7135',
    BranchCode: '234',
    BranchName: 'Batapola'
  },
  {
    BankCode: '7135',
    BranchCode: '235',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7135',
    BranchCode: '236',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7135',
    BranchCode: '237',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7135',
    BranchCode: '238',
    BranchName: 'Gonagaldeniya'
  },
  {
    BankCode: '7135',
    BranchCode: '239',
    BranchName: 'Ja Ela'
  },
  {
    BankCode: '7135',
    BranchCode: '240',
    BranchName: 'Keppetipola'
  },
  {
    BankCode: '7135',
    BranchCode: '241',
    BranchName: 'Pallepola'
  },
  {
    BankCode: '7135',
    BranchCode: '242',
    BranchName: 'Bakamuna'
  },
  {
    BankCode: '7135',
    BranchCode: '243',
    BranchName: 'Devinuwara'
  },
  {
    BankCode: '7135',
    BranchCode: '244',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7135',
    BranchCode: '245',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7135',
    BranchCode: '246',
    BranchName: 'Meegalewa'
  },
  {
    BankCode: '7135',
    BranchCode: '247',
    BranchName: 'Imaduwa'
  },
  {
    BankCode: '7135',
    BranchCode: '248',
    BranchName: 'Aranayake'
  },
  {
    BankCode: '7135',
    BranchCode: '249',
    BranchName: 'Neboda'
  },
  {
    BankCode: '7135',
    BranchCode: '250',
    BranchName: 'Kandeketiya'
  },
  {
    BankCode: '7135',
    BranchCode: '251',
    BranchName: 'Lunugala'
  },
  {
    BankCode: '7135',
    BranchCode: '252',
    BranchName: 'Bulathkohupitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '253',
    BranchName: 'Aralaganwila'
  },
  {
    BankCode: '7135',
    BranchCode: '254',
    BranchName: 'Welikanda'
  },
  {
    BankCode: '7135',
    BranchCode: '255',
    BranchName: 'Trincomalee Town'
  },
  {
    BankCode: '7135',
    BranchCode: '256',
    BranchName: 'Pilimatalawa'
  },
  {
    BankCode: '7135',
    BranchCode: '257',
    BranchName: 'Deltota'
  },
  {
    BankCode: '7135',
    BranchCode: '258',
    BranchName: 'Medagama'
  },
  {
    BankCode: '7135',
    BranchCode: '259',
    BranchName: 'Kehelwatte'
  },
  {
    BankCode: '7135',
    BranchCode: '260',
    BranchName: 'Koslanda'
  },
  {
    BankCode: '7135',
    BranchCode: '261',
    BranchName: 'Pelawatte'
  },
  {
    BankCode: '7135',
    BranchCode: '262',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7135',
    BranchCode: '263',
    BranchName: 'Kuruwita'
  },
  {
    BankCode: '7135',
    BranchCode: '264',
    BranchName: 'Suriyawewa'
  },
  {
    BankCode: '7135',
    BranchCode: '265',
    BranchName: 'Middeniya'
  },
  {
    BankCode: '7135',
    BranchCode: '266',
    BranchName: 'Kiriella'
  },
  {
    BankCode: '7135',
    BranchCode: '267',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7135',
    BranchCode: '268',
    BranchName: 'Girandurukotte'
  },
  {
    BankCode: '7135',
    BranchCode: '269',
    BranchName: 'Badulla-Muthiyangana'
  },
  {
    BankCode: '7135',
    BranchCode: '270',
    BranchName: 'Thulhiriya'
  },
  {
    BankCode: '7135',
    BranchCode: '271',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7135',
    BranchCode: '272',
    BranchName: 'Talgaswela'
  },
  {
    BankCode: '7135',
    BranchCode: '273',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7135',
    BranchCode: '274',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7135',
    BranchCode: '275',
    BranchName: 'Olcott Mawatha'
  },
  {
    BankCode: '7135',
    BranchCode: '276',
    BranchName: 'Katunayake'
  },
  {
    BankCode: '7135',
    BranchCode: '277',
    BranchName: 'Sea Street'
  },
  {
    BankCode: '7135',
    BranchCode: '278',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '279',
    BranchName: 'Pitakotte'
  },
  {
    BankCode: '7135',
    BranchCode: '280',
    BranchName: 'Pothuhera'
  },
  {
    BankCode: '7135',
    BranchCode: '281',
    BranchName: 'Kobeigane'
  },
  {
    BankCode: '7135',
    BranchCode: '282',
    BranchName: 'Maggona'
  },
  {
    BankCode: '7135',
    BranchCode: '283',
    BranchName: 'Baduraliya'
  },
  {
    BankCode: '7135',
    BranchCode: '284',
    BranchName: 'Jaffna Kannathiddy'
  },
  {
    BankCode: '7135',
    BranchCode: '285',
    BranchName: 'Point Pedro'
  },
  {
    BankCode: '7135',
    BranchCode: '288',
    BranchName: 'Kudawella'
  },
  {
    BankCode: '7135',
    BranchCode: '289',
    BranchName: 'Kaltota'
  },
  {
    BankCode: '7135',
    BranchCode: '290',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7135',
    BranchCode: '291',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '292',
    BranchName: 'Udapussellawa'
  },
  {
    BankCode: '7135',
    BranchCode: '293',
    BranchName: 'Dehiowita'
  },
  {
    BankCode: '7135',
    BranchCode: '294',
    BranchName: 'Alawathugoda'
  },
  {
    BankCode: '7135',
    BranchCode: '295',
    BranchName: 'Udawalawe'
  },
  {
    BankCode: '7135',
    BranchCode: '296',
    BranchName: 'Nintavur'
  },
  {
    BankCode: '7135',
    BranchCode: '297',
    BranchName: 'Dam Street'
  },
  {
    BankCode: '7135',
    BranchCode: '298',
    BranchName: 'Ginthupitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '299',
    BranchName: 'Kegalle Bazaar'
  },
  {
    BankCode: '7135',
    BranchCode: '300',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7135',
    BranchCode: '301',
    BranchName: 'Galkiriyagama'
  },
  {
    BankCode: '7135',
    BranchCode: '302',
    BranchName: 'Ginigathhena'
  },
  {
    BankCode: '7135',
    BranchCode: '303',
    BranchName: 'Mahawewa'
  },
  {
    BankCode: '7135',
    BranchCode: '304',
    BranchName: 'Walasgala'
  },
  {
    BankCode: '7135',
    BranchCode: '306',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7135',
    BranchCode: '307',
    BranchName: 'Gandara'
  },
  {
    BankCode: '7135',
    BranchCode: '308',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7135',
    BranchCode: '309',
    BranchName: 'Liberty Plaza'
  },
  {
    BankCode: '7135',
    BranchCode: '310',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '311',
    BranchName: 'Beruwala'
  },
  {
    BankCode: '7135',
    BranchCode: '312',
    BranchName: 'Malwatta Road'
  },
  {
    BankCode: '7135',
    BranchCode: '313',
    BranchName: 'Katubedda'
  },
  {
    BankCode: '7135',
    BranchCode: '315',
    BranchName: 'Talawa'
  },
  {
    BankCode: '7135',
    BranchCode: '316',
    BranchName: 'Ragama'
  },
  {
    BankCode: '7135',
    BranchCode: '317',
    BranchName: 'Ratnapura Town'
  },
  {
    BankCode: '7135',
    BranchCode: '318',
    BranchName: 'Pamunugama'
  },
  {
    BankCode: '7135',
    BranchCode: '319',
    BranchName: 'Kirulapana'
  },
  {
    BankCode: '7135',
    BranchCode: '320',
    BranchName: 'Borella Town'
  },
  {
    BankCode: '7135',
    BranchCode: '321',
    BranchName: 'Panadura Town'
  },
  {
    BankCode: '7135',
    BranchCode: '322',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7135',
    BranchCode: '324',
    BranchName: 'Seeduwa'
  },
  {
    BankCode: '7135',
    BranchCode: '325',
    BranchName: 'Wanduramba'
  },
  {
    BankCode: '7135',
    BranchCode: '326',
    BranchName: 'Capricon'
  },
  {
    BankCode: '7135',
    BranchCode: '327',
    BranchName: 'Kesbewa'
  },
  {
    BankCode: '7135',
    BranchCode: '328',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7135',
    BranchCode: '329',
    BranchName: 'Koggala'
  },
  {
    BankCode: '7135',
    BranchCode: '330',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7135',
    BranchCode: '331',
    BranchName: 'Lucky Plaza'
  },
  {
    BankCode: '7135',
    BranchCode: '332',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7135',
    BranchCode: '333',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7135',
    BranchCode: '334',
    BranchName: 'Kurunegala-Ethugalpura'
  },
  {
    BankCode: '7135',
    BranchCode: '335',
    BranchName: 'Nugegoda City'
  },
  {
    BankCode: '7135',
    BranchCode: '336',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7135',
    BranchCode: '337',
    BranchName: 'Dehiwela (Galle Rd.)'
  },
  {
    BankCode: '7135',
    BranchCode: '338',
    BranchName: 'Sainthamaruthu'
  },
  {
    BankCode: '7135',
    BranchCode: '339',
    BranchName: 'Kallar'
  },
  {
    BankCode: '7135',
    BranchCode: '340',
    BranchName: 'Oddamavady'
  },
  {
    BankCode: '7135',
    BranchCode: '341',
    BranchName: 'Hataraliyadda'
  },
  {
    BankCode: '7135',
    BranchCode: '342',
    BranchName: 'Kokkaddicholai'
  },
  {
    BankCode: '7135',
    BranchCode: '343',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '344',
    BranchName: 'Melsiripura'
  },
  {
    BankCode: '7135',
    BranchCode: '345',
    BranchName: 'Ranna'
  },
  {
    BankCode: '7135',
    BranchCode: '346',
    BranchName: 'Maruthamunai'
  },
  {
    BankCode: '7135',
    BranchCode: '347',
    BranchName: 'Badalkubura'
  },
  {
    BankCode: '7135',
    BranchCode: '348',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7135',
    BranchCode: '349',
    BranchName: 'Pallebedda'
  },
  {
    BankCode: '7135',
    BranchCode: '350',
    BranchName: 'Weeraketiya'
  },
  {
    BankCode: '7135',
    BranchCode: '351',
    BranchName: 'Thambala'
  },
  {
    BankCode: '7135',
    BranchCode: '352',
    BranchName: 'Pulmudai'
  },
  {
    BankCode: '7135',
    BranchCode: '353',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7135',
    BranchCode: '354',
    BranchName: 'Bogawanthalawa'
  },
  {
    BankCode: '7135',
    BranchCode: '355',
    BranchName: 'Kotiyakumbura'
  },
  {
    BankCode: '7135',
    BranchCode: '356',
    BranchName: 'Cheddikulam'
  },
  {
    BankCode: '7135',
    BranchCode: '357',
    BranchName: 'Kandy City Centre'
  },
  {
    BankCode: '7135',
    BranchCode: '358',
    BranchName: 'Poojapitiya'
  },
  {
    BankCode: '7135',
    BranchCode: '359',
    BranchName: 'Piliyandala City'
  },
  {
    BankCode: '7135',
    BranchCode: '360',
    BranchName: 'Polpithigama'
  },
  {
    BankCode: '7135',
    BranchCode: '361',
    BranchName: 'Kodikamam'
  },
  {
    BankCode: '7135',
    BranchCode: '600',
    BranchName: 'Card Center'
  },
  {
    BankCode: '7135',
    BranchCode: '796',
    BranchName: 'Overseas Customers Unit'
  },
  {
    BankCode: '7135',
    BranchCode: '999',
    BranchName: 'CCD'
  },
  {
    BankCode: '7144',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7144',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7144',
    BranchCode: '2',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7144',
    BranchCode: '32',
    BranchName: 'Kohuwala'
  },
  {
    BankCode: '7144',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7162',
    BranchCode: '1',
    BranchName: 'NTB.Head Office,'
  },
  {
    BankCode: '7162',
    BranchCode: '2',
    BranchName: 'Colpetty'
  },
  {
    BankCode: '7162',
    BranchCode: '3',
    BranchName: 'Sri Sangharaja Mw.'
  },
  {
    BankCode: '7162',
    BranchCode: '4',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7162',
    BranchCode: '5',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7162',
    BranchCode: '6',
    BranchName: 'Corporate'
  },
  {
    BankCode: '7162',
    BranchCode: '7',
    BranchName: 'Negombo(C.S.P.)'
  },
  {
    BankCode: '7162',
    BranchCode: '8',
    BranchName: 'Pettah-Main Street'
  },
  {
    BankCode: '7162',
    BranchCode: '9',
    BranchName: 'Mahabage'
  },
  {
    BankCode: '7162',
    BranchCode: '10',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7162',
    BranchCode: '11',
    BranchName: 'Dharmapala Mawatha'
  },
  {
    BankCode: '7162',
    BranchCode: '12',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7162',
    BranchCode: '13',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7162',
    BranchCode: '14',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7162',
    BranchCode: '15',
    BranchName: 'Borella'
  },
  {
    BankCode: '7162',
    BranchCode: '16',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7162',
    BranchCode: '17',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7162',
    BranchCode: '18',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7162',
    BranchCode: '19',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7162',
    BranchCode: '20',
    BranchName: 'Ward Place'
  },
  {
    BankCode: '7162',
    BranchCode: '21',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7162',
    BranchCode: '22',
    BranchName: 'Crescat'
  },
  {
    BankCode: '7162',
    BranchCode: '23',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7162',
    BranchCode: '24',
    BranchName: 'Nawam Mawatha'
  },
  {
    BankCode: '7162',
    BranchCode: '25',
    BranchName: 'Havelock Town'
  },
  {
    BankCode: '7162',
    BranchCode: '26',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7162',
    BranchCode: '27',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7162',
    BranchCode: '28',
    BranchName: 'Matara'
  },
  {
    BankCode: '7162',
    BranchCode: '29',
    BranchName: 'Galle'
  },
  {
    BankCode: '7162',
    BranchCode: '30',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7162',
    BranchCode: '31',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7162',
    BranchCode: '32',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7162',
    BranchCode: '33',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7162',
    BranchCode: '34',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7162',
    BranchCode: '35',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7162',
    BranchCode: '36',
    BranchName: 'Horana'
  },
  {
    BankCode: '7162',
    BranchCode: '37',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7162',
    BranchCode: '38',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7162',
    BranchCode: '39',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7162',
    BranchCode: '40',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7162',
    BranchCode: '41',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7162',
    BranchCode: '42',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7162',
    BranchCode: '43',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7162',
    BranchCode: '44',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7162',
    BranchCode: '45',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7162',
    BranchCode: '46',
    BranchName: 'Pettah-Bankshall Street'
  },
  {
    BankCode: '7162',
    BranchCode: '47',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7162',
    BranchCode: '48',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7162',
    BranchCode: '49',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7162',
    BranchCode: '50',
    BranchName: 'World Trade Centre'
  },
  {
    BankCode: '7162',
    BranchCode: '51',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7162',
    BranchCode: '52',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7162',
    BranchCode: '53',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7162',
    BranchCode: '54',
    BranchName: 'Kohuwala'
  },
  {
    BankCode: '7162',
    BranchCode: '55',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7162',
    BranchCode: '56',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7162',
    BranchCode: '57',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7162',
    BranchCode: '58',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7162',
    BranchCode: '59',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7162',
    BranchCode: '60',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7162',
    BranchCode: '61',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7162',
    BranchCode: '62',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7162',
    BranchCode: '63',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7162',
    BranchCode: '64',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7162',
    BranchCode: '65',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7162',
    BranchCode: '66',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7162',
    BranchCode: '67',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7162',
    BranchCode: '68',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7162',
    BranchCode: '69',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7162',
    BranchCode: '70',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7162',
    BranchCode: '71',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7162',
    BranchCode: '72',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7162',
    BranchCode: '73',
    BranchName: 'Digana'
  },
  {
    BankCode: '7162',
    BranchCode: '74',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7162',
    BranchCode: '75',
    BranchName: 'Boralasgamuwa'
  },
  {
    BankCode: '7162',
    BranchCode: '76',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7162',
    BranchCode: '77',
    BranchName: 'Gothatuwa'
  },
  {
    BankCode: '7162',
    BranchCode: '78',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7162',
    BranchCode: '79',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7162',
    BranchCode: '80',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7162',
    BranchCode: '81',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7162',
    BranchCode: '82',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7162',
    BranchCode: '83',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7162',
    BranchCode: '84',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7162',
    BranchCode: '85',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7162',
    BranchCode: '86',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7162',
    BranchCode: '87',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7162',
    BranchCode: '88',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7162',
    BranchCode: '89',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7162',
    BranchCode: '90',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7162',
    BranchCode: '91',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7162',
    BranchCode: '92',
    BranchName: 'Matale'
  },
  {
    BankCode: '7162',
    BranchCode: '93',
    BranchName: 'Kandy City Center'
  },
  {
    BankCode: '7162',
    BranchCode: '94',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7162',
    BranchCode: '400',
    BranchName: 'Card Center'
  },
  {
    BankCode: '7162',
    BranchCode: '500',
    BranchName: 'Liberty Plaza'
  },
  {
    BankCode: '7162',
    BranchCode: '501',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7162',
    BranchCode: '502',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7162',
    BranchCode: '503',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7162',
    BranchCode: '504',
    BranchName: 'Kohuwala'
  },
  {
    BankCode: '7162',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7205',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7205',
    BranchCode: '1',
    BranchName: 'Main Branch'
  },
  {
    BankCode: '7205',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7214',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7214',
    BranchCode: '1',
    BranchName: 'Navam Mawatha'
  },
  {
    BankCode: '7214',
    BranchCode: '2',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7214',
    BranchCode: '3',
    BranchName: 'Jawatta'
  },
  {
    BankCode: '7214',
    BranchCode: '4',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7214',
    BranchCode: '5',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7214',
    BranchCode: '6',
    BranchName: 'Matara'
  },
  {
    BankCode: '7214',
    BranchCode: '7',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7214',
    BranchCode: '8',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7214',
    BranchCode: '9',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7214',
    BranchCode: '10',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7214',
    BranchCode: '11',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7214',
    BranchCode: '12',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7214',
    BranchCode: '13',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7214',
    BranchCode: '14',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7214',
    BranchCode: '15',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7214',
    BranchCode: '16',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7214',
    BranchCode: '17',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7214',
    BranchCode: '18',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7214',
    BranchCode: '19',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7214',
    BranchCode: '20',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7214',
    BranchCode: '21',
    BranchName: 'Galle'
  },
  {
    BankCode: '7214',
    BranchCode: '22',
    BranchName: 'Pelawatte'
  },
  {
    BankCode: '7214',
    BranchCode: '23',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7214',
    BranchCode: '24',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7214',
    BranchCode: '25',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7214',
    BranchCode: '26',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7214',
    BranchCode: '27',
    BranchName: 'Horana'
  },
  {
    BankCode: '7214',
    BranchCode: '28',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7214',
    BranchCode: '29',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7214',
    BranchCode: '30',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7214',
    BranchCode: '31',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7214',
    BranchCode: '32',
    BranchName: 'Kohuwala'
  },
  {
    BankCode: '7214',
    BranchCode: '33',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7214',
    BranchCode: '34',
    BranchName: 'Awissawella'
  },
  {
    BankCode: '7214',
    BranchCode: '35',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7214',
    BranchCode: '36',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7214',
    BranchCode: '37',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7214',
    BranchCode: '38',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7214',
    BranchCode: '39',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7214',
    BranchCode: '40',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7214',
    BranchCode: '41',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7214',
    BranchCode: '42',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7214',
    BranchCode: '43',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7214',
    BranchCode: '44',
    BranchName: 'Ja Ela'
  },
  {
    BankCode: '7214',
    BranchCode: '45',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7214',
    BranchCode: '46',
    BranchName: 'Matale'
  },
  {
    BankCode: '7214',
    BranchCode: '47',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7214',
    BranchCode: '48',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7214',
    BranchCode: '49',
    BranchName: 'Pilimatalawa'
  },
  {
    BankCode: '7214',
    BranchCode: '50',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7214',
    BranchCode: '51',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7214',
    BranchCode: '52',
    BranchName: 'Borella'
  },
  {
    BankCode: '7214',
    BranchCode: '53',
    BranchName: 'kalmunai'
  },
  {
    BankCode: '7214',
    BranchCode: '54',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7214',
    BranchCode: '55',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7214',
    BranchCode: '56',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7214',
    BranchCode: '57',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7214',
    BranchCode: '58',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7214',
    BranchCode: '59',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7214',
    BranchCode: '60',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7214',
    BranchCode: '61',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7214',
    BranchCode: '62',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7214',
    BranchCode: '63',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7214',
    BranchCode: '64',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7214',
    BranchCode: '65',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7214',
    BranchCode: '66',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7214',
    BranchCode: '67',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7214',
    BranchCode: '68',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7214',
    BranchCode: '69',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7214',
    BranchCode: '70',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7214',
    BranchCode: '71',
    BranchName: 'Athurugiriya'
  },
  {
    BankCode: '7214',
    BranchCode: '72',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7214',
    BranchCode: '73',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7214',
    BranchCode: '74',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7214',
    BranchCode: '75',
    BranchName: 'Mahiyangana'
  },
  {
    BankCode: '7214',
    BranchCode: '76',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7214',
    BranchCode: '77',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7214',
    BranchCode: '78',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7214',
    BranchCode: '79',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7214',
    BranchCode: '80',
    BranchName: 'Kandy City Centre'
  },
  {
    BankCode: '7214',
    BranchCode: '81',
    BranchName: 'Uragasmanhandiya'
  },
  {
    BankCode: '7214',
    BranchCode: '82',
    BranchName: 'Katunayake'
  },
  {
    BankCode: '7214',
    BranchCode: '83',
    BranchName: 'Kakirawa'
  },
  {
    BankCode: '7214',
    BranchCode: '84',
    BranchName: 'Kahawatta'
  },
  {
    BankCode: '7214',
    BranchCode: '86',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7214',
    BranchCode: '87',
    BranchName: 'Pitakotte'
  },
  {
    BankCode: '7214',
    BranchCode: '88',
    BranchName: 'Handala'
  },
  {
    BankCode: '7214',
    BranchCode: '89',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7214',
    BranchCode: '90',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7214',
    BranchCode: '91',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7214',
    BranchCode: '92',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7214',
    BranchCode: '93',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7214',
    BranchCode: '94',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7214',
    BranchCode: '96',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7214',
    BranchCode: '97',
    BranchName: 'Manipai'
  },
  {
    BankCode: '7214',
    BranchCode: '98',
    BranchName: 'Thirunelvely'
  },
  {
    BankCode: '7214',
    BranchCode: '99',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7214',
    BranchCode: '95',
    BranchName: 'Natthandiya'
  },
  {
    BankCode: '7214',
    BranchCode: '100',
    BranchName: 'Head Office(Retail)'
  },
  {
    BankCode: '7214',
    BranchCode: '101',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7214',
    BranchCode: '102',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7214',
    BranchCode: '103',
    BranchName: 'Marine Drive'
  },
  {
    BankCode: '7214',
    BranchCode: '104',
    BranchName: 'Thambuttegama'
  },
  {
    BankCode: '7214',
    BranchCode: '105',
    BranchName: 'Mahawewa'
  },
  {
    BankCode: '7214',
    BranchCode: '109',
    BranchName: 'Katana'
  },
  {
    BankCode: '7214',
    BranchCode: '110',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7214',
    BranchCode: '111',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7214',
    BranchCode: '113',
    BranchName: 'Colombo Fort'
  },
  {
    BankCode: '7214',
    BranchCode: '601',
    BranchName: 'Islamic Banking Unit'
  },
  {
    BankCode: '7214',
    BranchCode: '900',
    BranchName: 'Head Office(Corporare)'
  },
  {
    BankCode: '7214',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7269',
    BranchCode: '0',
    BranchName: 'Middle East Bank'
  },
  {
    BankCode: '7269',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7269',
    BranchCode: '2',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7269',
    BranchCode: '3',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7269',
    BranchCode: '4',
    BranchName: 'Islamic Banking Unit'
  },
  {
    BankCode: '7269',
    BranchCode: '5',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7269',
    BranchCode: '6',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7269',
    BranchCode: '7',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7269',
    BranchCode: '8',
    BranchName: 'Galle'
  },
  {
    BankCode: '7269',
    BranchCode: '9',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7269',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7278',
    BranchCode: '1',
    BranchName: 'City Office'
  },
  {
    BankCode: '7278',
    BranchCode: '2',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7278',
    BranchCode: '3',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7278',
    BranchCode: '4',
    BranchName: 'Borella'
  },
  {
    BankCode: '7278',
    BranchCode: '5',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7278',
    BranchCode: '6',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7278',
    BranchCode: '7',
    BranchName: 'Kandy Super'
  },
  {
    BankCode: '7278',
    BranchCode: '8',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7278',
    BranchCode: '9',
    BranchName: 'Nawam Mawatha'
  },
  {
    BankCode: '7278',
    BranchCode: '10',
    BranchName: 'Matara'
  },
  {
    BankCode: '7278',
    BranchCode: '11',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '12',
    BranchName: 'Fort'
  },
  {
    BankCode: '7278',
    BranchCode: '13',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7278',
    BranchCode: '14',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7278',
    BranchCode: '15',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7278',
    BranchCode: '16',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7278',
    BranchCode: '17',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7278',
    BranchCode: '18',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7278',
    BranchCode: '19',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7278',
    BranchCode: '20',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7278',
    BranchCode: '21',
    BranchName: 'Anuradhapura Super Branch'
  },
  {
    BankCode: '7278',
    BranchCode: '22',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7278',
    BranchCode: '23',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '24',
    BranchName: 'Negombo Super'
  },
  {
    BankCode: '7278',
    BranchCode: '25',
    BranchName: 'Matale'
  },
  {
    BankCode: '7278',
    BranchCode: '26',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7278',
    BranchCode: '27',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7278',
    BranchCode: '28',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7278',
    BranchCode: '29',
    BranchName: 'Headquarters'
  },
  {
    BankCode: '7278',
    BranchCode: '30',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7278',
    BranchCode: '31',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7278',
    BranchCode: '32',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7278',
    BranchCode: '33',
    BranchName: 'Rathnapura'
  },
  {
    BankCode: '7278',
    BranchCode: '34',
    BranchName: 'Thimbirigasyaya'
  },
  {
    BankCode: '7278',
    BranchCode: '35',
    BranchName: 'Galle'
  },
  {
    BankCode: '7278',
    BranchCode: '36',
    BranchName: 'Wellawatte Super'
  },
  {
    BankCode: '7278',
    BranchCode: '37',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7278',
    BranchCode: '38',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7278',
    BranchCode: '39',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7278',
    BranchCode: '40',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7278',
    BranchCode: '41',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7278',
    BranchCode: '42',
    BranchName: 'Main Street'
  },
  {
    BankCode: '7278',
    BranchCode: '43',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '44',
    BranchName: 'Wariyapola (PBC)'
  },
  {
    BankCode: '7278',
    BranchCode: '45',
    BranchName: 'Wellampitiya (PBC)'
  },
  {
    BankCode: '7278',
    BranchCode: '46',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7278',
    BranchCode: '47',
    BranchName: 'Panadura Wekada'
  },
  {
    BankCode: '7278',
    BranchCode: '48',
    BranchName: 'Thambuttegama (PBC)'
  },
  {
    BankCode: '7278',
    BranchCode: '49',
    BranchName: 'Deraniyagala PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '50',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7278',
    BranchCode: '51',
    BranchName: 'Peradeniya PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '52',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7278',
    BranchCode: '53',
    BranchName: 'Alawwa PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '54',
    BranchName: 'Neluwa PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '55',
    BranchName: 'Vavunia'
  },
  {
    BankCode: '7278',
    BranchCode: '56',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7278',
    BranchCode: '57',
    BranchName: 'Horana'
  },
  {
    BankCode: '7278',
    BranchCode: '58',
    BranchName: 'Harbour-View PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '59',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7278',
    BranchCode: '60',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7278',
    BranchCode: '61',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7278',
    BranchCode: '62',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7278',
    BranchCode: '63',
    BranchName: 'Pelawatte PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '64',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7278',
    BranchCode: '65',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7278',
    BranchCode: '66',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7278',
    BranchCode: '67',
    BranchName: 'Athurugiriya PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '68',
    BranchName: 'Yakkala PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '69',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7278',
    BranchCode: '70',
    BranchName: 'Gregorys Road PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '71',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7278',
    BranchCode: '72',
    BranchName: 'Ambalongoda'
  },
  {
    BankCode: '7278',
    BranchCode: '73',
    BranchName: 'Ragama PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '74',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7278',
    BranchCode: '75',
    BranchName: 'Wadduwa PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '76',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7278',
    BranchCode: '77',
    BranchName: 'veyangoda PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '78',
    BranchName: 'Ganemulla PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '79',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7278',
    BranchCode: '80',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7278',
    BranchCode: '81',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7278',
    BranchCode: '82',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7278',
    BranchCode: '83',
    BranchName: 'Kirindiwela PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '84',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7278',
    BranchCode: '85',
    BranchName: 'Digana PBC'
  },
  {
    BankCode: '7278',
    BranchCode: '86',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7278',
    BranchCode: '87',
    BranchName: 'Pannipitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '88',
    BranchName: 'Negombo 2nd'
  },
  {
    BankCode: '7278',
    BranchCode: '89',
    BranchName: 'Attidiya'
  },
  {
    BankCode: '7278',
    BranchCode: '90',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7278',
    BranchCode: '91',
    BranchName: 'Pitakotte'
  },
  {
    BankCode: '7278',
    BranchCode: '92',
    BranchName: 'Maharagama Super'
  },
  {
    BankCode: '7278',
    BranchCode: '93',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7278',
    BranchCode: '94',
    BranchName: 'Kohuwela'
  },
  {
    BankCode: '7278',
    BranchCode: '95',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7278',
    BranchCode: '96',
    BranchName: 'Hendala'
  },
  {
    BankCode: '7278',
    BranchCode: '97',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7278',
    BranchCode: '98',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7278',
    BranchCode: '99',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7278',
    BranchCode: '100',
    BranchName: 'Kandy Metro'
  },
  {
    BankCode: '7278',
    BranchCode: '101',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7278',
    BranchCode: '102',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7278',
    BranchCode: '103',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7278',
    BranchCode: '104',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7278',
    BranchCode: '105',
    BranchName: 'Wattegama'
  },
  {
    BankCode: '7278',
    BranchCode: '106',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7278',
    BranchCode: '107',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7278',
    BranchCode: '108',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7278',
    BranchCode: '109',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7278',
    BranchCode: '110',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7278',
    BranchCode: '111',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7278',
    BranchCode: '112',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7278',
    BranchCode: '113',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7278',
    BranchCode: '114',
    BranchName: 'Embuldeniya'
  },
  {
    BankCode: '7278',
    BranchCode: '115',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7278',
    BranchCode: '116',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7278',
    BranchCode: '117',
    BranchName: 'Kirulapone'
  },
  {
    BankCode: '7278',
    BranchCode: '118',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7278',
    BranchCode: '119',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7278',
    BranchCode: '120',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7278',
    BranchCode: '121',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7278',
    BranchCode: '122',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7278',
    BranchCode: '123',
    BranchName: 'Kandy City Centre'
  },
  {
    BankCode: '7278',
    BranchCode: '124',
    BranchName: 'Oddamavady'
  },
  {
    BankCode: '7278',
    BranchCode: '125',
    BranchName: 'Kaluwanchikudy'
  },
  {
    BankCode: '7278',
    BranchCode: '126',
    BranchName: 'Sainthamaruthu'
  },
  {
    BankCode: '7278',
    BranchCode: '127',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7278',
    BranchCode: '128',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7278',
    BranchCode: '129',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7278',
    BranchCode: '130',
    BranchName: 'Pottuvil'
  },
  {
    BankCode: '7278',
    BranchCode: '131',
    BranchName: 'Platinum Plus'
  },
  {
    BankCode: '7278',
    BranchCode: '132',
    BranchName: 'Nattandiya'
  },
  {
    BankCode: '7278',
    BranchCode: '133',
    BranchName: 'Kundasale'
  },
  {
    BankCode: '7278',
    BranchCode: '134',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '135',
    BranchName: 'Gangodawila'
  },
  {
    BankCode: '7278',
    BranchCode: '136',
    BranchName: 'Peliyagoda'
  },
  {
    BankCode: '7278',
    BranchCode: '137',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7278',
    BranchCode: '138',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7278',
    BranchCode: '139',
    BranchName: '2nd Branch Batticaloa'
  },
  {
    BankCode: '7278',
    BranchCode: '140',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7278',
    BranchCode: '141',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '142',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7278',
    BranchCode: '143',
    BranchName: 'Anamabuwa'
  },
  {
    BankCode: '7278',
    BranchCode: '144',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7278',
    BranchCode: '145',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7278',
    BranchCode: '146',
    BranchName: 'Passara'
  },
  {
    BankCode: '7278',
    BranchCode: '147',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7278',
    BranchCode: '148',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7278',
    BranchCode: '149',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7278',
    BranchCode: '150',
    BranchName: 'Pilimatalawa'
  },
  {
    BankCode: '7278',
    BranchCode: '151',
    BranchName: 'Keselwatta'
  },
  {
    BankCode: '7278',
    BranchCode: '152',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7278',
    BranchCode: '153',
    BranchName: 'Matara Bazaar'
  },
  {
    BankCode: '7278',
    BranchCode: '154',
    BranchName: 'Aralangawila'
  },
  {
    BankCode: '7278',
    BranchCode: '155',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7278',
    BranchCode: '156',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7278',
    BranchCode: '157',
    BranchName: 'Sooriyawewa'
  },
  {
    BankCode: '7278',
    BranchCode: '158',
    BranchName: 'Middeniya'
  },
  {
    BankCode: '7278',
    BranchCode: '159',
    BranchName: 'Galle Bazaar'
  },
  {
    BankCode: '7278',
    BranchCode: '160',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7278',
    BranchCode: '161',
    BranchName: 'Bibile'
  },
  {
    BankCode: '7278',
    BranchCode: '162',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7278',
    BranchCode: '163',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7278',
    BranchCode: '164',
    BranchName: 'Chankanai'
  },
  {
    BankCode: '7278',
    BranchCode: '165',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7278',
    BranchCode: '166',
    BranchName: 'Pannala'
  },
  {
    BankCode: '7278',
    BranchCode: '167',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7278',
    BranchCode: '168',
    BranchName: 'Anuradhapura New Town'
  },
  {
    BankCode: '7278',
    BranchCode: '169',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7278',
    BranchCode: '170',
    BranchName: 'Vavuniya Metro'
  },
  {
    BankCode: '7278',
    BranchCode: '171',
    BranchName: 'Kayts'
  },
  {
    BankCode: '7278',
    BranchCode: '172',
    BranchName: 'Kantale'
  },
  {
    BankCode: '7278',
    BranchCode: '173',
    BranchName: 'Gothatuwa New Town'
  },
  {
    BankCode: '7278',
    BranchCode: '174',
    BranchName: 'Mallavi'
  },
  {
    BankCode: '7278',
    BranchCode: '175',
    BranchName: 'Colombo Super'
  },
  {
    BankCode: '7278',
    BranchCode: '176',
    BranchName: 'Mattegoda'
  },
  {
    BankCode: '7278',
    BranchCode: '177',
    BranchName: 'Kinniya'
  },
  {
    BankCode: '7278',
    BranchCode: '178',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7278',
    BranchCode: '179',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7278',
    BranchCode: '180',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7278',
    BranchCode: '181',
    BranchName: 'Habaraduwa'
  },
  {
    BankCode: '7278',
    BranchCode: '182',
    BranchName: 'Ahangama'
  },
  {
    BankCode: '7278',
    BranchCode: '183',
    BranchName: 'Marandagahamula'
  },
  {
    BankCode: '7278',
    BranchCode: '184',
    BranchName: 'Menikhinna'
  },
  {
    BankCode: '7278',
    BranchCode: '185',
    BranchName: 'Ninthavur'
  },
  {
    BankCode: '7278',
    BranchCode: '186',
    BranchName: 'Thirunelvely'
  },
  {
    BankCode: '7278',
    BranchCode: '187',
    BranchName: 'Hettipola'
  },
  {
    BankCode: '7278',
    BranchCode: '188',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7278',
    BranchCode: '189',
    BranchName: 'Madampe'
  },
  {
    BankCode: '7278',
    BranchCode: '190',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7278',
    BranchCode: '191',
    BranchName: 'Panchikawatte'
  },
  {
    BankCode: '7278',
    BranchCode: '192',
    BranchName: 'Padukka'
  },
  {
    BankCode: '7278',
    BranchCode: '193',
    BranchName: 'Mutwal'
  },
  {
    BankCode: '7278',
    BranchCode: '194',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7278',
    BranchCode: '195',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '196',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7278',
    BranchCode: '197',
    BranchName: 'Maho'
  },
  {
    BankCode: '7278',
    BranchCode: '198',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '199',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7278',
    BranchCode: '200',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7278',
    BranchCode: '201',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7278',
    BranchCode: '202',
    BranchName: 'Delgoda'
  },
  {
    BankCode: '7278',
    BranchCode: '203',
    BranchName: 'Kahatagasdigiliya'
  },
  {
    BankCode: '7278',
    BranchCode: '204',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '205',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7278',
    BranchCode: '206',
    BranchName: 'Kaburupitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '207',
    BranchName: 'Makola'
  },
  {
    BankCode: '7278',
    BranchCode: '208',
    BranchName: 'Muttur'
  },
  {
    BankCode: '7278',
    BranchCode: '209',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7278',
    BranchCode: '210',
    BranchName: 'Karagampitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '211',
    BranchName: 'Talahena'
  },
  {
    BankCode: '7278',
    BranchCode: '212',
    BranchName: 'Gampaha Super'
  },
  {
    BankCode: '7278',
    BranchCode: '213',
    BranchName: 'Seeduwa'
  },
  {
    BankCode: '7278',
    BranchCode: '214',
    BranchName: 'Orugodawatte'
  },
  {
    BankCode: '7278',
    BranchCode: '215',
    BranchName: 'Kurunegala Metro'
  },
  {
    BankCode: '7278',
    BranchCode: '216',
    BranchName: 'Dickwella'
  },
  {
    BankCode: '7278',
    BranchCode: '217',
    BranchName: 'Prince Street'
  },
  {
    BankCode: '7278',
    BranchCode: '218',
    BranchName: 'Borella Super'
  },
  {
    BankCode: '7278',
    BranchCode: '219',
    BranchName: 'World Trade Center'
  },
  {
    BankCode: '7278',
    BranchCode: '220',
    BranchName: 'Heerassagala'
  },
  {
    BankCode: '7278',
    BranchCode: '221',
    BranchName: 'Poojapitiya'
  },
  {
    BankCode: '7278',
    BranchCode: '222',
    BranchName: 'Thalawakele'
  },
  {
    BankCode: '7278',
    BranchCode: '223',
    BranchName: 'Nawala Koswatta'
  },
  {
    BankCode: '7278',
    BranchCode: '224',
    BranchName: 'Kesbewa'
  },
  {
    BankCode: '7278',
    BranchCode: '225',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7278',
    BranchCode: '226',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7278',
    BranchCode: '227',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7278',
    BranchCode: '228',
    BranchName: 'Polgahawela'
  },
  {
    BankCode: '7278',
    BranchCode: '229',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7278',
    BranchCode: '920',
    BranchName: 'Imports'
  },
  {
    BankCode: '7278',
    BranchCode: '929',
    BranchName: 'Sampath Viswa'
  },
  {
    BankCode: '7278',
    BranchCode: '993',
    BranchName: 'Central Clearing Department'
  },
  {
    BankCode: '7278',
    BranchCode: '996',
    BranchName: 'Card Centre'
  },
  {
    BankCode: '7278',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7287',
    BranchCode: '0',
    BranchName: 'Seylan Bank Head Office'
  },
  {
    BankCode: '7287',
    BranchCode: '1',
    BranchName: 'City Office'
  },
  {
    BankCode: '7287',
    BranchCode: '2',
    BranchName: 'Matara'
  },
  {
    BankCode: '7287',
    BranchCode: '3',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7287',
    BranchCode: '4',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7287',
    BranchCode: '5',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7287',
    BranchCode: '6',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7287',
    BranchCode: '7',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7287',
    BranchCode: '8',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '9',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7287',
    BranchCode: '10',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7287',
    BranchCode: '11',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7287',
    BranchCode: '12',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7287',
    BranchCode: '13',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7287',
    BranchCode: '14',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7287',
    BranchCode: '15',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7287',
    BranchCode: '16',
    BranchName: 'Galle'
  },
  {
    BankCode: '7287',
    BranchCode: '17',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7287',
    BranchCode: '18',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7287',
    BranchCode: '19',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7287',
    BranchCode: '20',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7287',
    BranchCode: '21',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7287',
    BranchCode: '22',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7287',
    BranchCode: '23',
    BranchName: 'Horana'
  },
  {
    BankCode: '7287',
    BranchCode: '24',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7287',
    BranchCode: '25',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7287',
    BranchCode: '26',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7287',
    BranchCode: '27',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7287',
    BranchCode: '28',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7287',
    BranchCode: '29',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7287',
    BranchCode: '30',
    BranchName: 'Colombo Fort'
  },
  {
    BankCode: '7287',
    BranchCode: '31',
    BranchName: 'Katunayaka'
  },
  {
    BankCode: '7287',
    BranchCode: '32',
    BranchName: 'Cinnamon Gardens'
  },
  {
    BankCode: '7287',
    BranchCode: '33',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7287',
    BranchCode: '34',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7287',
    BranchCode: '35',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7287',
    BranchCode: '36',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7287',
    BranchCode: '37',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7287',
    BranchCode: '38',
    BranchName: 'Matale'
  },
  {
    BankCode: '7287',
    BranchCode: '39',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7287',
    BranchCode: '40',
    BranchName: 'Sarikkamulla'
  },
  {
    BankCode: '7287',
    BranchCode: '41',
    BranchName: 'Attidiya'
  },
  {
    BankCode: '7287',
    BranchCode: '42',
    BranchName: 'Kalubowila'
  },
  {
    BankCode: '7287',
    BranchCode: '43',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7287',
    BranchCode: '44',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '45',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '46',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7287',
    BranchCode: '47',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7287',
    BranchCode: '48',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7287',
    BranchCode: '49',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7287',
    BranchCode: '50',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7287',
    BranchCode: '51',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7287',
    BranchCode: '52',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7287',
    BranchCode: '53',
    BranchName: 'Manampitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '54',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7287',
    BranchCode: '55',
    BranchName: 'Katuneriya'
  },
  {
    BankCode: '7287',
    BranchCode: '56',
    BranchName: 'Koggala'
  },
  {
    BankCode: '7287',
    BranchCode: '57',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7287',
    BranchCode: '58',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7287',
    BranchCode: '59',
    BranchName: 'Bogawanthalawa'
  },
  {
    BankCode: '7287',
    BranchCode: '60',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7287',
    BranchCode: '61',
    BranchName: 'Talawakale'
  },
  {
    BankCode: '7287',
    BranchCode: '62',
    BranchName: 'Raddolugama'
  },
  {
    BankCode: '7287',
    BranchCode: '63',
    BranchName: 'Weliveriya'
  },
  {
    BankCode: '7287',
    BranchCode: '64',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7287',
    BranchCode: '65',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7287',
    BranchCode: '66',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7287',
    BranchCode: '67',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7287',
    BranchCode: '68',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7287',
    BranchCode: '69',
    BranchName: 'Dam Street'
  },
  {
    BankCode: '7287',
    BranchCode: '70',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7287',
    BranchCode: '71',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7287',
    BranchCode: '72',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7287',
    BranchCode: '73',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7287',
    BranchCode: '74',
    BranchName: 'Kaththankudy'
  },
  {
    BankCode: '7287',
    BranchCode: '75',
    BranchName: 'Awissawella'
  },
  {
    BankCode: '7287',
    BranchCode: '76',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '77',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7287',
    BranchCode: '78',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7287',
    BranchCode: '79',
    BranchName: 'Soysapura'
  },
  {
    BankCode: '7287',
    BranchCode: '80',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7287',
    BranchCode: '81',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7287',
    BranchCode: '82',
    BranchName: 'Borella'
  },
  {
    BankCode: '7287',
    BranchCode: '83',
    BranchName: 'Havelock Town'
  },
  {
    BankCode: '7287',
    BranchCode: '84',
    BranchName: 'Marandagahamula'
  },
  {
    BankCode: '7287',
    BranchCode: '85',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7287',
    BranchCode: '86',
    BranchName: 'Millenium'
  },
  {
    BankCode: '7287',
    BranchCode: '87',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7287',
    BranchCode: '88',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7287',
    BranchCode: '89',
    BranchName: 'Meegoda'
  },
  {
    BankCode: '7287',
    BranchCode: '90',
    BranchName: 'Pelmadulla.'
  },
  {
    BankCode: '7287',
    BranchCode: '91',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7287',
    BranchCode: '92',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7287',
    BranchCode: '93',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7287',
    BranchCode: '94',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7287',
    BranchCode: '95',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7287',
    BranchCode: '96',
    BranchName: 'Mullativu'
  },
  {
    BankCode: '7287',
    BranchCode: '97',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7287',
    BranchCode: '98',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7287',
    BranchCode: '99',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7287',
    BranchCode: '100',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7287',
    BranchCode: '101',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7287',
    BranchCode: '102',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7287',
    BranchCode: '103',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7287',
    BranchCode: '104',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7287',
    BranchCode: '105',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7287',
    BranchCode: '106',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7287',
    BranchCode: '107',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7287',
    BranchCode: '108',
    BranchName: 'Veyangoda'
  },
  {
    BankCode: '7287',
    BranchCode: '109',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7287',
    BranchCode: '110',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7287',
    BranchCode: '111',
    BranchName: 'Dummalasooriya'
  },
  {
    BankCode: '7287',
    BranchCode: '112',
    BranchName: 'Godagama'
  },
  {
    BankCode: '7287',
    BranchCode: '113',
    BranchName: 'Galenbidunuwewa'
  },
  {
    BankCode: '7287',
    BranchCode: '114',
    BranchName: 'Pitakotte'
  },
  {
    BankCode: '7287',
    BranchCode: '115',
    BranchName: 'Kanthale'
  },
  {
    BankCode: '7287',
    BranchCode: '116',
    BranchName: 'Akkaraipatthu'
  },
  {
    BankCode: '7287',
    BranchCode: '117',
    BranchName: 'Chankanai'
  },
  {
    BankCode: '7287',
    BranchCode: '118',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7287',
    BranchCode: '119',
    BranchName: 'Udappu'
  },
  {
    BankCode: '7287',
    BranchCode: '120',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7287',
    BranchCode: '121',
    BranchName: 'Beruwala'
  },
  {
    BankCode: '7287',
    BranchCode: '122',
    BranchName: 'Kirulapone'
  },
  {
    BankCode: '7287',
    BranchCode: '123',
    BranchName: 'Gothatuwa'
  },
  {
    BankCode: '7287',
    BranchCode: '124',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7287',
    BranchCode: '125',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7287',
    BranchCode: '126',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7287',
    BranchCode: '127',
    BranchName: 'Yatiyantota'
  },
  {
    BankCode: '7287',
    BranchCode: '128',
    BranchName: 'Ranpokunugama'
  },
  {
    BankCode: '7287',
    BranchCode: '129',
    BranchName: 'Aralaganwila'
  },
  {
    BankCode: '7287',
    BranchCode: '130',
    BranchName: 'Kalawanchikudy'
  },
  {
    BankCode: '7287',
    BranchCode: '131',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7287',
    BranchCode: '132',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7287',
    BranchCode: '133',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7287',
    BranchCode: '134',
    BranchName: 'Pallekelle'
  },
  {
    BankCode: '7287',
    BranchCode: '135',
    BranchName: 'Wijerama'
  },
  {
    BankCode: '7287',
    BranchCode: '136',
    BranchName: 'Kotiyakumbura'
  },
  {
    BankCode: '7287',
    BranchCode: '137',
    BranchName: 'Bowatta'
  },
  {
    BankCode: '7287',
    BranchCode: '138',
    BranchName: 'Eppawala'
  },
  {
    BankCode: '7287',
    BranchCode: '139',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '140',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7287',
    BranchCode: '141',
    BranchName: 'Rideegama'
  },
  {
    BankCode: '7287',
    BranchCode: '142',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7287',
    BranchCode: '143',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7287',
    BranchCode: '144',
    BranchName: 'Colombo Gold Centre'
  },
  {
    BankCode: '7287',
    BranchCode: '145',
    BranchName: 'Baduraliya'
  },
  {
    BankCode: '7287',
    BranchCode: '146',
    BranchName: 'Kamburupitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '147',
    BranchName: 'Kalpitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '148',
    BranchName: 'Kataragama'
  },
  {
    BankCode: '7287',
    BranchCode: '149',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7287',
    BranchCode: '150',
    BranchName: 'Islamic Banking Unit'
  },
  {
    BankCode: '7287',
    BranchCode: '151',
    BranchName: 'Pothuvil'
  },
  {
    BankCode: '7287',
    BranchCode: '152',
    BranchName: 'Samanthurai'
  },
  {
    BankCode: '7287',
    BranchCode: '153',
    BranchName: 'Siyambalanduwa'
  },
  {
    BankCode: '7287',
    BranchCode: '154',
    BranchName: 'Padaviya'
  },
  {
    BankCode: '7287',
    BranchCode: '155',
    BranchName: 'Hasalaka'
  },
  {
    BankCode: '7287',
    BranchCode: '157',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7287',
    BranchCode: '158',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7287',
    BranchCode: '159',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7287',
    BranchCode: '160',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7287',
    BranchCode: '161',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7287',
    BranchCode: '162',
    BranchName: 'Pannala'
  },
  {
    BankCode: '7287',
    BranchCode: '163',
    BranchName: 'Athurugiriya'
  },
  {
    BankCode: '7287',
    BranchCode: '164',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7287',
    BranchCode: '165',
    BranchName: 'Matara Bazzar'
  },
  {
    BankCode: '7287',
    BranchCode: '166',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7287',
    BranchCode: '167',
    BranchName: 'Mutwal'
  },
  {
    BankCode: '7287',
    BranchCode: '168',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7287',
    BranchCode: '169',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7287',
    BranchCode: '170',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7287',
    BranchCode: '171',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7287',
    BranchCode: '172',
    BranchName: 'Hettipola'
  },
  {
    BankCode: '7287',
    BranchCode: '173',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7287',
    BranchCode: '174',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7287',
    BranchCode: '992',
    BranchName: 'Central Clearing Department'
  },
  {
    BankCode: '7287',
    BranchCode: '996',
    BranchName: 'Central Processing Unit'
  },
  {
    BankCode: '7287',
    BranchCode: '997',
    BranchName: 'Seylan Card Centre (SCC)'
  },
  {
    BankCode: '7287',
    BranchCode: '998',
    BranchName: 'Retail Remittance Center'
  },
  {
    BankCode: '7296',
    BranchCode: '1',
    BranchName: 'All Branches'
  },
  {
    BankCode: '7302',
    BranchCode: '1',
    BranchName: 'UB Main Office'
  },
  {
    BankCode: '7302',
    BranchCode: '2',
    BranchName: 'Colpetty'
  },
  {
    BankCode: '7302',
    BranchCode: '3',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7302',
    BranchCode: '4',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7302',
    BranchCode: '5',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7302',
    BranchCode: '6',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7302',
    BranchCode: '7',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7302',
    BranchCode: '8',
    BranchName: 'Head Office Branch'
  },
  {
    BankCode: '7302',
    BranchCode: '9',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7302',
    BranchCode: '10',
    BranchName: 'Negambo'
  },
  {
    BankCode: '7302',
    BranchCode: '11',
    BranchName: 'Matara'
  },
  {
    BankCode: '7302',
    BranchCode: '12',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7302',
    BranchCode: '14',
    BranchName: 'Ja ela'
  },
  {
    BankCode: '7302',
    BranchCode: '15',
    BranchName: 'Neugegoda'
  },
  {
    BankCode: '7302',
    BranchCode: '16',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7302',
    BranchCode: '17',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7302',
    BranchCode: '18',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7302',
    BranchCode: '19',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7302',
    BranchCode: '20',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7302',
    BranchCode: '21',
    BranchName: 'Chunnakkam'
  },
  {
    BankCode: '7302',
    BranchCode: '22',
    BranchName: 'Atchuvely'
  },
  {
    BankCode: '7302',
    BranchCode: '23',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7302',
    BranchCode: '24',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7302',
    BranchCode: '25',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7302',
    BranchCode: '26',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7302',
    BranchCode: '27',
    BranchName: 'Galle'
  },
  {
    BankCode: '7302',
    BranchCode: '28',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7302',
    BranchCode: '29',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7302',
    BranchCode: '30',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7302',
    BranchCode: '31',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7302',
    BranchCode: '32',
    BranchName: 'Dhambulla'
  },
  {
    BankCode: '7302',
    BranchCode: '33',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7302',
    BranchCode: '34',
    BranchName: 'Horana'
  },
  {
    BankCode: '7302',
    BranchCode: '35',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7302',
    BranchCode: '36',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7302',
    BranchCode: '37',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7302',
    BranchCode: '38',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7302',
    BranchCode: '39',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7302',
    BranchCode: '40',
    BranchName: 'Pelawatte'
  },
  {
    BankCode: '7302',
    BranchCode: '41',
    BranchName: 'Angunakolapelessa'
  },
  {
    BankCode: '7302',
    BranchCode: '42',
    BranchName: 'Kebithigollewa'
  },
  {
    BankCode: '7302',
    BranchCode: '43',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7302',
    BranchCode: '44',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7302',
    BranchCode: '45',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7302',
    BranchCode: '46',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7302',
    BranchCode: '47',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7302',
    BranchCode: '48',
    BranchName: 'Horowpathana'
  },
  {
    BankCode: '7302',
    BranchCode: '49',
    BranchName: 'Ambalanthota'
  },
  {
    BankCode: '7302',
    BranchCode: '50',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7302',
    BranchCode: '51',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7302',
    BranchCode: '52',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7302',
    BranchCode: '53',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7302',
    BranchCode: '54',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7302',
    BranchCode: '55',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7302',
    BranchCode: '56',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7302',
    BranchCode: '57',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7302',
    BranchCode: '58',
    BranchName: 'Moneragala'
  },
  {
    BankCode: '7302',
    BranchCode: '59',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7302',
    BranchCode: '60',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7302',
    BranchCode: '61',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7302',
    BranchCode: '62',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7302',
    BranchCode: '63',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7302',
    BranchCode: '64',
    BranchName: 'Rajagiriaya'
  },
  {
    BankCode: '7302',
    BranchCode: '65',
    BranchName: 'Attidiya'
  },
  {
    BankCode: '7302',
    BranchCode: '66',
    BranchName: 'Kohuwala'
  },
  {
    BankCode: '7302',
    BranchCode: '67',
    BranchName: 'New Borella'
  },
  {
    BankCode: '7302',
    BranchCode: '68',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7302',
    BranchCode: '69',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7302',
    BranchCode: '997',
    BranchName: 'UB Corporate'
  },
  {
    BankCode: '7311',
    BranchCode: '1',
    BranchName: 'Metro'
  },
  {
    BankCode: '7311',
    BranchCode: '2',
    BranchName: 'Panchikawatte'
  },
  {
    BankCode: '7311',
    BranchCode: '3',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7311',
    BranchCode: '4',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7311',
    BranchCode: '5',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7311',
    BranchCode: '6',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7311',
    BranchCode: '7',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7311',
    BranchCode: '8',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7311',
    BranchCode: '9',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7311',
    BranchCode: '10',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7311',
    BranchCode: '11',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7311',
    BranchCode: '12',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7311',
    BranchCode: '13',
    BranchName: 'Matara'
  },
  {
    BankCode: '7311',
    BranchCode: '14',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7311',
    BranchCode: '15',
    BranchName: 'Dehiwela'
  },
  {
    BankCode: '7311',
    BranchCode: '16',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7311',
    BranchCode: '17',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7311',
    BranchCode: '18',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7311',
    BranchCode: '19',
    BranchName: 'Dam Street'
  },
  {
    BankCode: '7311',
    BranchCode: '20',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7311',
    BranchCode: '21',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7311',
    BranchCode: '22',
    BranchName: 'Kirulapana'
  },
  {
    BankCode: '7311',
    BranchCode: '23',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7311',
    BranchCode: '24',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7311',
    BranchCode: '25',
    BranchName: 'Galle'
  },
  {
    BankCode: '7311',
    BranchCode: '26',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7311',
    BranchCode: '27',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7311',
    BranchCode: '28',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7311',
    BranchCode: '29',
    BranchName: 'Wellawatta'
  },
  {
    BankCode: '7311',
    BranchCode: '30',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7311',
    BranchCode: '31',
    BranchName: 'Borella'
  },
  {
    BankCode: '7311',
    BranchCode: '32',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7311',
    BranchCode: '33',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7311',
    BranchCode: '34',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7311',
    BranchCode: '35',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7311',
    BranchCode: '36',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7311',
    BranchCode: '37',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7311',
    BranchCode: '38',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7311',
    BranchCode: '39',
    BranchName: 'Matale'
  },
  {
    BankCode: '7311',
    BranchCode: '40',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7311',
    BranchCode: '41',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7311',
    BranchCode: '42',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7311',
    BranchCode: '43',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7311',
    BranchCode: '44',
    BranchName: 'Kandy City Centre'
  },
  {
    BankCode: '7311',
    BranchCode: '45',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7311',
    BranchCode: '46',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7311',
    BranchCode: '47',
    BranchName: 'Kalubowila'
  },
  {
    BankCode: '7311',
    BranchCode: '48',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7311',
    BranchCode: '49',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7311',
    BranchCode: '50',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7311',
    BranchCode: '51',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7311',
    BranchCode: '52',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7311',
    BranchCode: '53',
    BranchName: 'Ambalanthota'
  },
  {
    BankCode: '7311',
    BranchCode: '54',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7311',
    BranchCode: '55',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7311',
    BranchCode: '56',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7311',
    BranchCode: '57',
    BranchName: 'Kanthankudy'
  },
  {
    BankCode: '7311',
    BranchCode: '58',
    BranchName: 'Kundasale'
  },
  {
    BankCode: '7311',
    BranchCode: '59',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7311',
    BranchCode: '60',
    BranchName: 'Akkaraipaththu'
  },
  {
    BankCode: '7311',
    BranchCode: '61',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7311',
    BranchCode: '62',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7311',
    BranchCode: '63',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7311',
    BranchCode: '64',
    BranchName: 'Puttalama'
  },
  {
    BankCode: '7311',
    BranchCode: '65',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7311',
    BranchCode: '66',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7311',
    BranchCode: '67',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7311',
    BranchCode: '68',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7311',
    BranchCode: '69',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7311',
    BranchCode: '70',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7311',
    BranchCode: '71',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7311',
    BranchCode: '72',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7311',
    BranchCode: '73',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7311',
    BranchCode: '74',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7311',
    BranchCode: '75',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7311',
    BranchCode: '76',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7311',
    BranchCode: '77',
    BranchName: 'Horana'
  },
  {
    BankCode: '7311',
    BranchCode: '78',
    BranchName: 'Colombo Gold Centre'
  },
  {
    BankCode: '7311',
    BranchCode: '79',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7311',
    BranchCode: '80',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7311',
    BranchCode: '81',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7311',
    BranchCode: '82',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7311',
    BranchCode: '83',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7311',
    BranchCode: '84',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7311',
    BranchCode: '85',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7311',
    BranchCode: '814',
    BranchName: 'Internationl Remittance'
  },
  {
    BankCode: '7311',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7384',
    BranchCode: '1',
    BranchName: 'Sri Lanka Branch'
  },
  {
    BankCode: '7454',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7454',
    BranchCode: '2',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7454',
    BranchCode: '3',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7454',
    BranchCode: '4',
    BranchName: 'Matara'
  },
  {
    BankCode: '7454',
    BranchCode: '5',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7454',
    BranchCode: '6',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7454',
    BranchCode: '7',
    BranchName: 'City Office'
  },
  {
    BankCode: '7454',
    BranchCode: '8',
    BranchName: 'Rathnapura'
  },
  {
    BankCode: '7454',
    BranchCode: '9',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7454',
    BranchCode: '10',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7454',
    BranchCode: '11',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7454',
    BranchCode: '12',
    BranchName: 'Borella'
  },
  {
    BankCode: '7454',
    BranchCode: '14',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7454',
    BranchCode: '15',
    BranchName: 'Bandaranayake Mawatha'
  },
  {
    BankCode: '7454',
    BranchCode: '16',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7454',
    BranchCode: '17',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7454',
    BranchCode: '18',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7454',
    BranchCode: '19',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7454',
    BranchCode: '20',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7454',
    BranchCode: '21',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7454',
    BranchCode: '22',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7454',
    BranchCode: '23',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7454',
    BranchCode: '24',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7454',
    BranchCode: '25',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7454',
    BranchCode: '26',
    BranchName: 'Kaluthara'
  },
  {
    BankCode: '7454',
    BranchCode: '27',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7454',
    BranchCode: '28',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7454',
    BranchCode: '29',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7454',
    BranchCode: '30',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7454',
    BranchCode: '31',
    BranchName: 'Matale'
  },
  {
    BankCode: '7454',
    BranchCode: '32',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7454',
    BranchCode: '33',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7454',
    BranchCode: '34',
    BranchName: 'Horana'
  },
  {
    BankCode: '7454',
    BranchCode: '35',
    BranchName: 'Galle'
  },
  {
    BankCode: '7454',
    BranchCode: '36',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7454',
    BranchCode: '37',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7454',
    BranchCode: '38',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7454',
    BranchCode: '39',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7454',
    BranchCode: '40',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7454',
    BranchCode: '41',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7454',
    BranchCode: '42',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7454',
    BranchCode: '43',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7454',
    BranchCode: '44',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7454',
    BranchCode: '45',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7454',
    BranchCode: '46',
    BranchName: 'Pettah Peoples Park'
  },
  {
    BankCode: '7454',
    BranchCode: '47',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7454',
    BranchCode: '48',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7454',
    BranchCode: '49',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7454',
    BranchCode: '50',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7454',
    BranchCode: '51',
    BranchName: 'Sainthamaruthu'
  },
  {
    BankCode: '7454',
    BranchCode: '52',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7454',
    BranchCode: '53',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7454',
    BranchCode: '54',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7454',
    BranchCode: '55',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7454',
    BranchCode: '56',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7454',
    BranchCode: '57',
    BranchName: 'Oddamavadi'
  },
  {
    BankCode: '7454',
    BranchCode: '58',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7454',
    BranchCode: '59',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7454',
    BranchCode: '60',
    BranchName: 'Manipai'
  },
  {
    BankCode: '7454',
    BranchCode: '61',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7454',
    BranchCode: '62',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7454',
    BranchCode: '63',
    BranchName: 'Ja Ela'
  },
  {
    BankCode: '7454',
    BranchCode: '64',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7454',
    BranchCode: '65',
    BranchName: 'Digana'
  },
  {
    BankCode: '7454',
    BranchCode: '66',
    BranchName: 'Thambuttegama'
  },
  {
    BankCode: '7454',
    BranchCode: '67',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7454',
    BranchCode: '68',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7454',
    BranchCode: '69',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7454',
    BranchCode: '70',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7454',
    BranchCode: '71',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7454',
    BranchCode: '72',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7454',
    BranchCode: '73',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7454',
    BranchCode: '74',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7454',
    BranchCode: '75',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7454',
    BranchCode: '76',
    BranchName: 'Gangodawila'
  },
  {
    BankCode: '7454',
    BranchCode: '77',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7454',
    BranchCode: '78',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7454',
    BranchCode: '79',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7454',
    BranchCode: '80',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7454',
    BranchCode: '81',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7454',
    BranchCode: '82',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7454',
    BranchCode: '83',
    BranchName: 'Kandy City Centre'
  },
  {
    BankCode: '7454',
    BranchCode: '84',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7454',
    BranchCode: '85',
    BranchName: 'Aralaganwila'
  },
  {
    BankCode: '7454',
    BranchCode: '86',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7454',
    BranchCode: '87',
    BranchName: 'Pettah-Main Street'
  },
  {
    BankCode: '7454',
    BranchCode: '88',
    BranchName: 'Kahawatta'
  },
  {
    BankCode: '7454',
    BranchCode: '89',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7454',
    BranchCode: '90',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7454',
    BranchCode: '91',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7454',
    BranchCode: '92',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7454',
    BranchCode: '93',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7454',
    BranchCode: '94',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7454',
    BranchCode: '95',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7454',
    BranchCode: '96',
    BranchName: 'Wattegama'
  },
  {
    BankCode: '7454',
    BranchCode: '97',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7454',
    BranchCode: '98',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7454',
    BranchCode: '99',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7454',
    BranchCode: '100',
    BranchName: 'Matara City'
  },
  {
    BankCode: '7454',
    BranchCode: '101',
    BranchName: 'Nivithigala'
  },
  {
    BankCode: '7454',
    BranchCode: '102',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7454',
    BranchCode: '103',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7454',
    BranchCode: '104',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7454',
    BranchCode: '105',
    BranchName: 'Eppawala'
  },
  {
    BankCode: '7454',
    BranchCode: '106',
    BranchName: 'Marawilla'
  },
  {
    BankCode: '7454',
    BranchCode: '107',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7454',
    BranchCode: '108',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7454',
    BranchCode: '109',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7454',
    BranchCode: '110',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7454',
    BranchCode: '501',
    BranchName: 'Southern Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '511',
    BranchName: 'Western� Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '521',
    BranchName: 'North Western Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '531',
    BranchName: 'Central Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '541',
    BranchName: 'Sabaragamuwa Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '551',
    BranchName: 'North Central� Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '561',
    BranchName: 'Eastern� Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '571',
    BranchName: 'Uva Province SLP Units'
  },
  {
    BankCode: '7454',
    BranchCode: '700',
    BranchName: 'Premier Banking Centre'
  },
  {
    BankCode: '7454',
    BranchCode: '800',
    BranchName: 'Central Operations'
  },
  {
    BankCode: '7454',
    BranchCode: '999',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7463',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7463',
    BranchCode: '2',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7463',
    BranchCode: '3',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7463',
    BranchCode: '4',
    BranchName: 'Kattankudy'
  },
  {
    BankCode: '7463',
    BranchCode: '5',
    BranchName: 'Ladies'
  },
  {
    BankCode: '7463',
    BranchCode: '6',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7463',
    BranchCode: '8',
    BranchName: 'Galle'
  },
  {
    BankCode: '7463',
    BranchCode: '9',
    BranchName: 'Oddamavadi'
  },
  {
    BankCode: '7463',
    BranchCode: '10',
    BranchName: 'Akurana'
  },
  {
    BankCode: '7463',
    BranchCode: '11',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7463',
    BranchCode: '12',
    BranchName: 'Sammanthurai'
  },
  {
    BankCode: '7463',
    BranchCode: '13',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7463',
    BranchCode: '14',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7463',
    BranchCode: '15',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7463',
    BranchCode: '16',
    BranchName: 'Dehiwela'
  },
  {
    BankCode: '7463',
    BranchCode: '17',
    BranchName: 'Nintavur'
  },
  {
    BankCode: '7463',
    BranchCode: '18',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7463',
    BranchCode: '19',
    BranchName: 'Eravur'
  },
  {
    BankCode: '7463',
    BranchCode: '20',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7463',
    BranchCode: '21',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7463',
    BranchCode: '22',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7463',
    BranchCode: '23',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7463',
    BranchCode: '24',
    BranchName: 'Kinniya'
  },
  {
    BankCode: '7463',
    BranchCode: '25',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7463',
    BranchCode: '26',
    BranchName: 'Kalmunai Unity Square'
  },
  {
    BankCode: '7463',
    BranchCode: '27',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7463',
    BranchCode: '28',
    BranchName: 'Dematagoda'
  },
  {
    BankCode: '7463',
    BranchCode: '29',
    BranchName: 'Kirulapana'
  },
  {
    BankCode: '7463',
    BranchCode: '30',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7472',
    BranchCode: '2',
    BranchName: 'Colombo'
  },
  {
    BankCode: '7481',
    BranchCode: '1',
    BranchName: 'Corporate'
  },
  {
    BankCode: '7481',
    BranchCode: '2',
    BranchName: 'Maitland Crescent'
  },
  {
    BankCode: '7481',
    BranchCode: '3',
    BranchName: 'Old Moor Street'
  },
  {
    BankCode: '7481',
    BranchCode: '4',
    BranchName: 'Matara'
  },
  {
    BankCode: '7481',
    BranchCode: '5',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7481',
    BranchCode: '6',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7481',
    BranchCode: '7',
    BranchName: 'Hawaeliya'
  },
  {
    BankCode: '7481',
    BranchCode: '8',
    BranchName: 'Thanamalwila'
  },
  {
    BankCode: '7481',
    BranchCode: '9',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7481',
    BranchCode: '10',
    BranchName: 'Jaffna City Center'
  },
  {
    BankCode: '7481',
    BranchCode: '11',
    BranchName: 'Chunnakkam'
  },
  {
    BankCode: '7481',
    BranchCode: '12',
    BranchName: 'Galle'
  },
  {
    BankCode: '7481',
    BranchCode: '13',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7481',
    BranchCode: '14',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7481',
    BranchCode: '15',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7481',
    BranchCode: '16',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7481',
    BranchCode: '17',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7481',
    BranchCode: '18',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7481',
    BranchCode: '19',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7481',
    BranchCode: '20',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7481',
    BranchCode: '925',
    BranchName: 'Central Processing Office'
  },
  {
    BankCode: '7719',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7719',
    BranchCode: '2',
    BranchName: 'City'
  },
  {
    BankCode: '7719',
    BranchCode: '3',
    BranchName: 'Galle'
  },
  {
    BankCode: '7719',
    BranchCode: '4',
    BranchName: 'Matara'
  },
  {
    BankCode: '7719',
    BranchCode: '5',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7719',
    BranchCode: '6',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7719',
    BranchCode: '7',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7719',
    BranchCode: '8',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '9',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7719',
    BranchCode: '10',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7719',
    BranchCode: '11',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7719',
    BranchCode: '12',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7719',
    BranchCode: '13',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '14',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7719',
    BranchCode: '15',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7719',
    BranchCode: '16',
    BranchName: 'Matale'
  },
  {
    BankCode: '7719',
    BranchCode: '17',
    BranchName: 'kurunegala'
  },
  {
    BankCode: '7719',
    BranchCode: '18',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7719',
    BranchCode: '19',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7719',
    BranchCode: '20',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '21',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7719',
    BranchCode: '22',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7719',
    BranchCode: '23',
    BranchName: 'Bambalapitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '24',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7719',
    BranchCode: '25',
    BranchName: 'Peliyagoda'
  },
  {
    BankCode: '7719',
    BranchCode: '26',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7719',
    BranchCode: '27',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7719',
    BranchCode: '28',
    BranchName: 'Beruwala'
  },
  {
    BankCode: '7719',
    BranchCode: '29',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '30',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7719',
    BranchCode: '31',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7719',
    BranchCode: '32',
    BranchName: 'Point Pedro'
  },
  {
    BankCode: '7719',
    BranchCode: '33',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7719',
    BranchCode: '34',
    BranchName: 'Naththandiya'
  },
  {
    BankCode: '7719',
    BranchCode: '35',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7719',
    BranchCode: '36',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7719',
    BranchCode: '37',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7719',
    BranchCode: '39',
    BranchName: 'Devinuwara'
  },
  {
    BankCode: '7719',
    BranchCode: '40',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7719',
    BranchCode: '41',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7719',
    BranchCode: '42',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7719',
    BranchCode: '43',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7719',
    BranchCode: '44',
    BranchName: 'Puttalama'
  },
  {
    BankCode: '7719',
    BranchCode: '45',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7719',
    BranchCode: '46',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7719',
    BranchCode: '47',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7719',
    BranchCode: '48',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7719',
    BranchCode: '49',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7719',
    BranchCode: '50',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '51',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7719',
    BranchCode: '52',
    BranchName: 'Katunayake'
  },
  {
    BankCode: '7719',
    BranchCode: '53',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7719',
    BranchCode: '54',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7719',
    BranchCode: '55',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7719',
    BranchCode: '56',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7719',
    BranchCode: '57',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7719',
    BranchCode: '58',
    BranchName: 'Galnewa'
  },
  {
    BankCode: '7719',
    BranchCode: '59',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7719',
    BranchCode: '60',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7719',
    BranchCode: '61',
    BranchName: 'Borella'
  },
  {
    BankCode: '7719',
    BranchCode: '62',
    BranchName: 'Hakmana'
  },
  {
    BankCode: '7719',
    BranchCode: '63',
    BranchName: 'Horana'
  },
  {
    BankCode: '7719',
    BranchCode: '64',
    BranchName: 'Narahenpita'
  },
  {
    BankCode: '7719',
    BranchCode: '65',
    BranchName: 'Kollupitiya 2nd'
  },
  {
    BankCode: '7719',
    BranchCode: '66',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7719',
    BranchCode: '67',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7719',
    BranchCode: '68',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7719',
    BranchCode: '69',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7719',
    BranchCode: '70',
    BranchName: 'Wellawatta'
  },
  {
    BankCode: '7719',
    BranchCode: '71',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7719',
    BranchCode: '72',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7719',
    BranchCode: '73',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7719',
    BranchCode: '74',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7719',
    BranchCode: '75',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7719',
    BranchCode: '76',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7719',
    BranchCode: '77',
    BranchName: 'Maligawatta'
  },
  {
    BankCode: '7719',
    BranchCode: '78',
    BranchName: 'Thalawakele'
  },
  {
    BankCode: '7719',
    BranchCode: '79',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7719',
    BranchCode: '80',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7719',
    BranchCode: '81',
    BranchName: 'Kandy 2nd'
  },
  {
    BankCode: '7719',
    BranchCode: '82',
    BranchName: 'Dickwella'
  },
  {
    BankCode: '7719',
    BranchCode: '83',
    BranchName: 'Mahabage'
  },
  {
    BankCode: '7719',
    BranchCode: '84',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7719',
    BranchCode: '85',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7719',
    BranchCode: '86',
    BranchName: 'Kamburupitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '87',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7719',
    BranchCode: '88',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7719',
    BranchCode: '89',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7719',
    BranchCode: '90',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7719',
    BranchCode: '91',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7719',
    BranchCode: '92',
    BranchName: 'Veyangoda'
  },
  {
    BankCode: '7719',
    BranchCode: '93',
    BranchName: 'Katubedda'
  },
  {
    BankCode: '7719',
    BranchCode: '94',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '95',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7719',
    BranchCode: '96',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '97',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7719',
    BranchCode: '98',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7719',
    BranchCode: '99',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7719',
    BranchCode: '100',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '101',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7719',
    BranchCode: '102',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7719',
    BranchCode: '103',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7719',
    BranchCode: '104',
    BranchName: 'Kahathuduwa'
  },
  {
    BankCode: '7719',
    BranchCode: '105',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7719',
    BranchCode: '106',
    BranchName: 'Meegoda'
  },
  {
    BankCode: '7719',
    BranchCode: '107',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7719',
    BranchCode: '108',
    BranchName: 'Thirunelvely'
  },
  {
    BankCode: '7719',
    BranchCode: '109',
    BranchName: 'Chenkaladi'
  },
  {
    BankCode: '7719',
    BranchCode: '110',
    BranchName: 'Uragasmanhandiya'
  },
  {
    BankCode: '7719',
    BranchCode: '111',
    BranchName: 'Nawala'
  },
  {
    BankCode: '7719',
    BranchCode: '112',
    BranchName: 'Deraniyagala'
  },
  {
    BankCode: '7719',
    BranchCode: '113',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7719',
    BranchCode: '114',
    BranchName: 'Kalawanchikudy'
  },
  {
    BankCode: '7719',
    BranchCode: '115',
    BranchName: 'Kalubowila'
  },
  {
    BankCode: '7719',
    BranchCode: '116',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7719',
    BranchCode: '117',
    BranchName: 'Welikada'
  },
  {
    BankCode: '7719',
    BranchCode: '118',
    BranchName: 'Samanthurai'
  },
  {
    BankCode: '7719',
    BranchCode: '119',
    BranchName: 'Delkanda'
  },
  {
    BankCode: '7719',
    BranchCode: '120',
    BranchName: 'Yakkala'
  },
  {
    BankCode: '7719',
    BranchCode: '121',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '122',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7719',
    BranchCode: '123',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7719',
    BranchCode: '124',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '125',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7719',
    BranchCode: '126',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7719',
    BranchCode: '127',
    BranchName: 'Mulgampola'
  },
  {
    BankCode: '7719',
    BranchCode: '128',
    BranchName: 'Badalkumbura'
  },
  {
    BankCode: '7719',
    BranchCode: '129',
    BranchName: 'Dankotuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '130',
    BranchName: 'Mullative'
  },
  {
    BankCode: '7719',
    BranchCode: '131',
    BranchName: 'Kodikamam'
  },
  {
    BankCode: '7719',
    BranchCode: '132',
    BranchName: 'Alawathugoda'
  },
  {
    BankCode: '7719',
    BranchCode: '133',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7719',
    BranchCode: '134',
    BranchName: 'Periyakallar'
  },
  {
    BankCode: '7719',
    BranchCode: '135',
    BranchName: 'Thamabiluvil'
  },
  {
    BankCode: '7719',
    BranchCode: '136',
    BranchName: 'Horawpathana'
  },
  {
    BankCode: '7719',
    BranchCode: '137',
    BranchName: 'Galenbindunuwewa'
  },
  {
    BankCode: '7719',
    BranchCode: '138',
    BranchName: 'Pitakatte'
  },
  {
    BankCode: '7719',
    BranchCode: '139',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7719',
    BranchCode: '140',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7719',
    BranchCode: '141',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7719',
    BranchCode: '142',
    BranchName: 'Maho'
  },
  {
    BankCode: '7719',
    BranchCode: '143',
    BranchName: 'Udugama'
  },
  {
    BankCode: '7719',
    BranchCode: '144',
    BranchName: 'Uhana'
  },
  {
    BankCode: '7719',
    BranchCode: '145',
    BranchName: 'Galagedara'
  },
  {
    BankCode: '7719',
    BranchCode: '146',
    BranchName: 'Siyambalanduwa'
  },
  {
    BankCode: '7719',
    BranchCode: '147',
    BranchName: 'Kopay'
  },
  {
    BankCode: '7719',
    BranchCode: '148',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7719',
    BranchCode: '149',
    BranchName: 'City Plus'
  },
  {
    BankCode: '7719',
    BranchCode: '150',
    BranchName: 'Piliyandala 2nd'
  },
  {
    BankCode: '7719',
    BranchCode: '151',
    BranchName: 'Deiyandara'
  },
  {
    BankCode: '7719',
    BranchCode: '152',
    BranchName: 'Pannala'
  },
  {
    BankCode: '7719',
    BranchCode: '701',
    BranchName: 'Ragama Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '702',
    BranchName: 'WTC Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '703',
    BranchName: 'Athurugiriya Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '704',
    BranchName: 'Neluwa Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '705',
    BranchName: 'Ganemulla Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '706',
    BranchName: 'Wellampitiya Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '707',
    BranchName: 'Narammala Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '708',
    BranchName: 'Bibile'
  },
  {
    BankCode: '7719',
    BranchCode: '709',
    BranchName: 'Hettipola'
  },
  {
    BankCode: '7719',
    BranchCode: '710',
    BranchName: 'Bulathkohupitiya'
  },
  {
    BankCode: '7719',
    BranchCode: '711',
    BranchName: 'Kandy City Center'
  },
  {
    BankCode: '7719',
    BranchCode: '712',
    BranchName: 'Weeraketiya NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '713',
    BranchName: 'Tangalle NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '714',
    BranchName: 'Walasmulla NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '715',
    BranchName: 'Wadduwa NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '716',
    BranchName: 'Kelaniya NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '717',
    BranchName: 'Nelliady NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '718',
    BranchName: 'Atchuvely NSB Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '719',
    BranchName: 'Puwakaramba Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '720',
    BranchName: 'Valaichenai Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '721',
    BranchName: 'Aranayake'
  },
  {
    BankCode: '7719',
    BranchCode: '722',
    BranchName: 'Rikillagaskada Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '723',
    BranchName: 'Katugastota Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '724',
    BranchName: 'Middeniya Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '725',
    BranchName: 'Chankanai Piyasa'
  },
  {
    BankCode: '7719',
    BranchCode: '726',
    BranchName: 'Polgahawela'
  },
  {
    BankCode: '7719',
    BranchCode: '727',
    BranchName: 'Arayampathy'
  },
  {
    BankCode: '7719',
    BranchCode: '728',
    BranchName: 'Raddolugama'
  },
  {
    BankCode: '7719',
    BranchCode: '729',
    BranchName: 'Galgamuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '730',
    BranchName: 'Kayts'
  },
  {
    BankCode: '7719',
    BranchCode: '731',
    BranchName: 'Karainagar'
  },
  {
    BankCode: '7719',
    BranchCode: '732',
    BranchName: 'Mutwal'
  },
  {
    BankCode: '7719',
    BranchCode: '733',
    BranchName: 'Mulleriyawa'
  },
  {
    BankCode: '7719',
    BranchCode: '734',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7719',
    BranchCode: '735',
    BranchName: 'Habaraduwa'
  },
  {
    BankCode: '7719',
    BranchCode: '736',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7719',
    BranchCode: '737',
    BranchName: 'Kahawatte'
  },
  {
    BankCode: '7719',
    BranchCode: '738',
    BranchName: 'Bulathsinhala'
  },
  {
    BankCode: '7719',
    BranchCode: '739',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7719',
    BranchCode: '740',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7719',
    BranchCode: '741',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7719',
    BranchCode: '742',
    BranchName: 'Alawwa'
  },
  {
    BankCode: '7719',
    BranchCode: '743',
    BranchName: 'Yatiyantota'
  },
  {
    BankCode: '7719',
    BranchCode: '744',
    BranchName: 'Pundaluoya'
  },
  {
    BankCode: '7719',
    BranchCode: '745',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7719',
    BranchCode: '746',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7719',
    BranchCode: '747',
    BranchName: 'Delgoda'
  },
  {
    BankCode: '7719',
    BranchCode: '748',
    BranchName: 'Digana'
  },
  {
    BankCode: '7719',
    BranchCode: '749',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7719',
    BranchCode: '750',
    BranchName: 'Hanwella'
  },
  {
    BankCode: '7719',
    BranchCode: '751',
    BranchName: 'Hali Ela'
  },
  {
    BankCode: '7719',
    BranchCode: '752',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7719',
    BranchCode: '753',
    BranchName: 'Palugamam'
  },
  {
    BankCode: '7719',
    BranchCode: '754',
    BranchName: 'Sooriyawewa'
  },
  {
    BankCode: '7719',
    BranchCode: '755',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7719',
    BranchCode: '756',
    BranchName: 'Tambuttegama'
  },
  {
    BankCode: '7719',
    BranchCode: '757',
    BranchName: 'Imaduwa'
  },
  {
    BankCode: '7719',
    BranchCode: '758',
    BranchName: 'Passara'
  },
  {
    BankCode: '7719',
    BranchCode: '759',
    BranchName: 'Kahatagasdigiliya'
  },
  {
    BankCode: '7719',
    BranchCode: '760',
    BranchName: 'Angunakolapelessa'
  },
  {
    BankCode: '7719',
    BranchCode: '761',
    BranchName: 'Madampe'
  },
  {
    BankCode: '7719',
    BranchCode: '762',
    BranchName: 'Kantale'
  },
  {
    BankCode: '7719',
    BranchCode: '763',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7719',
    BranchCode: '764',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7719',
    BranchCode: '765',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7719',
    BranchCode: '766',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7719',
    BranchCode: '767',
    BranchName: 'Kebithigollawa'
  },
  {
    BankCode: '7719',
    BranchCode: '768',
    BranchName: 'Wattegama'
  },
  {
    BankCode: '7719',
    BranchCode: '769',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7719',
    BranchCode: '770',
    BranchName: 'Pothuvil'
  },
  {
    BankCode: '7719',
    BranchCode: '771',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7719',
    BranchCode: '772',
    BranchName: 'Kuruwita'
  },
  {
    BankCode: '7719',
    BranchCode: '773',
    BranchName: 'Vankalai'
  },
  {
    BankCode: '7719',
    BranchCode: '774',
    BranchName: 'Mallavi'
  },
  {
    BankCode: '7719',
    BranchCode: '775',
    BranchName: 'Kekanadura'
  },
  {
    BankCode: '7719',
    BranchCode: '776',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7719',
    BranchCode: '777',
    BranchName: 'Nivithigala'
  },
  {
    BankCode: '7719',
    BranchCode: '778',
    BranchName: 'Kiriella'
  },
  {
    BankCode: '7719',
    BranchCode: '779',
    BranchName: 'Naula'
  },
  {
    BankCode: '7719',
    BranchCode: '780',
    BranchName: 'Melsiripura'
  },
  {
    BankCode: '7719',
    BranchCode: '781',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7719',
    BranchCode: '782',
    BranchName: 'Rideegama'
  },
  {
    BankCode: '7719',
    BranchCode: '783',
    BranchName: 'Deltota'
  },
  {
    BankCode: '7719',
    BranchCode: '784',
    BranchName: 'Ginigathhena'
  },
  {
    BankCode: '7719',
    BranchCode: '785',
    BranchName: 'Bingiriya'
  },
  {
    BankCode: '7719',
    BranchCode: '786',
    BranchName: 'Baduraliya'
  },
  {
    BankCode: '7719',
    BranchCode: '787',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7719',
    BranchCode: '788',
    BranchName: 'Eppawala'
  },
  {
    BankCode: '7719',
    BranchCode: '789',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7719',
    BranchCode: '790',
    BranchName: 'Pugoda'
  },
  {
    BankCode: '7719',
    BranchCode: '791',
    BranchName: 'Gelioya'
  },
  {
    BankCode: '7719',
    BranchCode: '792',
    BranchName: 'Ragala'
  },
  {
    BankCode: '7719',
    BranchCode: '901',
    BranchName: 'PBU � Head Office'
  },
  {
    BankCode: '7719',
    BranchCode: '902',
    BranchName: 'PBU � Kandy'
  },
  {
    BankCode: '7719',
    BranchCode: '903',
    BranchName: 'PBU � Galle'
  },
  {
    BankCode: '7719',
    BranchCode: '904',
    BranchName: 'PBU � Matara'
  },
  {
    BankCode: '7719',
    BranchCode: '905',
    BranchName: 'PBU � Kegalle'
  },
  {
    BankCode: '7719',
    BranchCode: '906',
    BranchName: 'PBU � Anuradhapura'
  },
  {
    BankCode: '7719',
    BranchCode: '907',
    BranchName: 'PBU � Kalutara'
  },
  {
    BankCode: '7719',
    BranchCode: '908',
    BranchName: 'PBU � Gampaha'
  },
  {
    BankCode: '7719',
    BranchCode: '909',
    BranchName: 'PBU � Ampara'
  },
  {
    BankCode: '7719',
    BranchCode: '910',
    BranchName: 'Credit Division, (H.L Division)'
  },
  {
    BankCode: '7719',
    BranchCode: '911',
    BranchName: 'IBU (NRFC)'
  },
  {
    BankCode: '7719',
    BranchCode: '912',
    BranchName: 'Br. Management Division'
  },
  {
    BankCode: '7719',
    BranchCode: '915',
    BranchName: 'PBU � Kurunegala'
  },
  {
    BankCode: '7719',
    BranchCode: '916',
    BranchName: 'PBU � Jaffna'
  },
  {
    BankCode: '7719',
    BranchCode: '917',
    BranchName: 'PBU � Eheliyagoda'
  },
  {
    BankCode: '7728',
    BranchCode: '1',
    BranchName: '1st Colombo City'
  },
  {
    BankCode: '7728',
    BranchCode: '2',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7728',
    BranchCode: '3',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7728',
    BranchCode: '4',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7728',
    BranchCode: '5',
    BranchName: 'Horana'
  },
  {
    BankCode: '7728',
    BranchCode: '6',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7728',
    BranchCode: '7',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7728',
    BranchCode: '8',
    BranchName: 'Akurassa'
  },
  {
    BankCode: '7728',
    BranchCode: '9',
    BranchName: 'Matale'
  },
  {
    BankCode: '7728',
    BranchCode: '10',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7728',
    BranchCode: '11',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7728',
    BranchCode: '12',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7728',
    BranchCode: '13',
    BranchName: 'Manaragala'
  },
  {
    BankCode: '7728',
    BranchCode: '14',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7728',
    BranchCode: '15',
    BranchName: 'Rathnapura'
  },
  {
    BankCode: '7728',
    BranchCode: '16',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7728',
    BranchCode: '17',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7728',
    BranchCode: '18',
    BranchName: 'Sahasapura'
  },
  {
    BankCode: '7728',
    BranchCode: '19',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7728',
    BranchCode: '20',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7728',
    BranchCode: '21',
    BranchName: 'Trincomalle'
  },
  {
    BankCode: '7728',
    BranchCode: '22',
    BranchName: 'Kalmuani'
  },
  {
    BankCode: '7728',
    BranchCode: '23',
    BranchName: 'Ambalanthota'
  },
  {
    BankCode: '7728',
    BranchCode: '25',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7728',
    BranchCode: '26',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7728',
    BranchCode: '27',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7728',
    BranchCode: '28',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7728',
    BranchCode: '29',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7728',
    BranchCode: '30',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7728',
    BranchCode: '31',
    BranchName: 'Matara'
  },
  {
    BankCode: '7728',
    BranchCode: '32',
    BranchName: 'Galle'
  },
  {
    BankCode: '7728',
    BranchCode: '33',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7728',
    BranchCode: '34',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7728',
    BranchCode: '35',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7728',
    BranchCode: '36',
    BranchName: 'Thambuththegama'
  },
  {
    BankCode: '7728',
    BranchCode: '37',
    BranchName: 'Maho'
  },
  {
    BankCode: '7728',
    BranchCode: '38',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7728',
    BranchCode: '39',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7728',
    BranchCode: '40',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7728',
    BranchCode: '41',
    BranchName: 'Muthur'
  },
  {
    BankCode: '7728',
    BranchCode: '42',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7728',
    BranchCode: '43',
    BranchName: 'Nanatan'
  },
  {
    BankCode: '7728',
    BranchCode: '44',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7728',
    BranchCode: '45',
    BranchName: 'Pottuvil'
  },
  {
    BankCode: '7728',
    BranchCode: '46',
    BranchName: 'Mapalagama'
  },
  {
    BankCode: '7728',
    BranchCode: '47',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7728',
    BranchCode: '48',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7728',
    BranchCode: '49',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7728',
    BranchCode: '50',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7728',
    BranchCode: '51',
    BranchName: 'Angunakolapalassa'
  },
  {
    BankCode: '7728',
    BranchCode: '52',
    BranchName: 'Aralaganvila'
  },
  {
    BankCode: '7728',
    BranchCode: '53',
    BranchName: 'Galenbidunuwewa'
  },
  {
    BankCode: '7728',
    BranchCode: '54',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7728',
    BranchCode: '55',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7728',
    BranchCode: '56',
    BranchName: 'Kirulapona'
  },
  {
    BankCode: '7728',
    BranchCode: '57',
    BranchName: 'Deraniyagala'
  },
  {
    BankCode: '7728',
    BranchCode: '58',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7728',
    BranchCode: '59',
    BranchName: 'Kalawanchikudi'
  },
  {
    BankCode: '7728',
    BranchCode: '60',
    BranchName: 'Samanthurei'
  },
  {
    BankCode: '7728',
    BranchCode: '61',
    BranchName: 'Siyabalanduwa'
  },
  {
    BankCode: '7728',
    BranchCode: '62',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7728',
    BranchCode: '63',
    BranchName: 'Valachchena'
  },
  {
    BankCode: '7728',
    BranchCode: '64',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7728',
    BranchCode: '65',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7728',
    BranchCode: '66',
    BranchName: 'Yakkalamulla'
  },
  {
    BankCode: '7728',
    BranchCode: '67',
    BranchName: 'Katuwana'
  },
  {
    BankCode: '7728',
    BranchCode: '68',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7728',
    BranchCode: '69',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7728',
    BranchCode: '70',
    BranchName: 'Padavi Parakramapura'
  },
  {
    BankCode: '7728',
    BranchCode: '71',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7728',
    BranchCode: '72',
    BranchName: 'Uhana'
  },
  {
    BankCode: '7728',
    BranchCode: '73',
    BranchName: 'Kanthale'
  },
  {
    BankCode: '7728',
    BranchCode: '74',
    BranchName: 'Akkeripattu'
  },
  {
    BankCode: '7728',
    BranchCode: '75',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7728',
    BranchCode: '76',
    BranchName: 'Hatharaliyadda'
  },
  {
    BankCode: '7728',
    BranchCode: '77',
    BranchName: 'Hingurna'
  },
  {
    BankCode: '7728',
    BranchCode: '78',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7728',
    BranchCode: '79',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7728',
    BranchCode: '80',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7728',
    BranchCode: '81',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7728',
    BranchCode: '82',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7728',
    BranchCode: '83',
    BranchName: 'Thalawa'
  },
  {
    BankCode: '7728',
    BranchCode: '84',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7728',
    BranchCode: '85',
    BranchName: 'Mahabage'
  },
  {
    BankCode: '7728',
    BranchCode: '86',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7728',
    BranchCode: '87',
    BranchName: 'Ekala'
  },
  {
    BankCode: '7728',
    BranchCode: '88',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7728',
    BranchCode: '89',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7728',
    BranchCode: '90',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7728',
    BranchCode: '91',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7728',
    BranchCode: '92',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7728',
    BranchCode: '93',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7728',
    BranchCode: '94',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7728',
    BranchCode: '95',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7728',
    BranchCode: '999',
    BranchName: 'Sanasa Development Bank Head Office'
  },
  {
    BankCode: '7737',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7746',
    BranchCode: '2',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7746',
    BranchCode: '3',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7746',
    BranchCode: '4',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7746',
    BranchCode: '5',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7746',
    BranchCode: '6',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7746',
    BranchCode: '7',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7746',
    BranchCode: '8',
    BranchName: 'Rathnapura'
  },
  {
    BankCode: '7746',
    BranchCode: '9',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7746',
    BranchCode: '10',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7746',
    BranchCode: '11',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7746',
    BranchCode: '12',
    BranchName: 'Wellawatta'
  },
  {
    BankCode: '7746',
    BranchCode: '13',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7746',
    BranchCode: '14',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7746',
    BranchCode: '15',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7746',
    BranchCode: '16',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7746',
    BranchCode: '17',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7746',
    BranchCode: '18',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7746',
    BranchCode: '19',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7746',
    BranchCode: '20',
    BranchName: 'Kaluthara'
  },
  {
    BankCode: '7746',
    BranchCode: '21',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7746',
    BranchCode: '22',
    BranchName: 'Matara'
  },
  {
    BankCode: '7746',
    BranchCode: '23',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7746',
    BranchCode: '24',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7746',
    BranchCode: '25',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7746',
    BranchCode: '26',
    BranchName: 'Mahara'
  },
  {
    BankCode: '7746',
    BranchCode: '27',
    BranchName: 'Galle'
  },
  {
    BankCode: '7746',
    BranchCode: '28',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7746',
    BranchCode: '29',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7746',
    BranchCode: '30',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7746',
    BranchCode: '31',
    BranchName: 'Colombo Office'
  },
  {
    BankCode: '7746',
    BranchCode: '32',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7746',
    BranchCode: '33',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7746',
    BranchCode: '34',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7746',
    BranchCode: '35',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7746',
    BranchCode: '36',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7746',
    BranchCode: '38',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7746',
    BranchCode: '39',
    BranchName: 'Kadana'
  },
  {
    BankCode: '7746',
    BranchCode: '40',
    BranchName: 'Ragama'
  },
  {
    BankCode: '7746',
    BranchCode: '41',
    BranchName: 'Ela Kanda'
  },
  {
    BankCode: '7746',
    BranchCode: '42',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7746',
    BranchCode: '43',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7746',
    BranchCode: '44',
    BranchName: 'Marawila'
  },
  {
    BankCode: '7746',
    BranchCode: '45',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7746',
    BranchCode: '46',
    BranchName: 'Ratmalana'
  },
  {
    BankCode: '7746',
    BranchCode: '47',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7746',
    BranchCode: '48',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7746',
    BranchCode: '49',
    BranchName: 'Premier Center'
  },
  {
    BankCode: '7746',
    BranchCode: '50',
    BranchName: 'Horana'
  },
  {
    BankCode: '7746',
    BranchCode: '51',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7746',
    BranchCode: '52',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7746',
    BranchCode: '53',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7746',
    BranchCode: '54',
    BranchName: 'Katugasthota'
  },
  {
    BankCode: '7746',
    BranchCode: '55',
    BranchName: 'Matale'
  },
  {
    BankCode: '7746',
    BranchCode: '56',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7746',
    BranchCode: '57',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7746',
    BranchCode: '58',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7746',
    BranchCode: '59',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7746',
    BranchCode: '60',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7746',
    BranchCode: '61',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7746',
    BranchCode: '62',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7746',
    BranchCode: '63',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7746',
    BranchCode: '64',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7746',
    BranchCode: '65',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7746',
    BranchCode: '66',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7746',
    BranchCode: '67',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7746',
    BranchCode: '68',
    BranchName: 'Kuruvita'
  },
  {
    BankCode: '7746',
    BranchCode: '69',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7746',
    BranchCode: '70',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7746',
    BranchCode: '71',
    BranchName: 'Thalawathugoda'
  },
  {
    BankCode: '7746',
    BranchCode: '72',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7746',
    BranchCode: '104',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7746',
    BranchCode: '106',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7755',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7755',
    BranchCode: '3',
    BranchName: 'Provincial Office � Central'
  },
  {
    BankCode: '7755',
    BranchCode: '100',
    BranchName: 'Provincial Office � Western'
  },
  {
    BankCode: '7755',
    BranchCode: '101',
    BranchName: 'Bulathsinghala'
  },
  {
    BankCode: '7755',
    BranchCode: '102',
    BranchName: 'Walagedara'
  },
  {
    BankCode: '7755',
    BranchCode: '103',
    BranchName: 'Agalawatte'
  },
  {
    BankCode: '7755',
    BranchCode: '104',
    BranchName: 'Millaniya'
  },
  {
    BankCode: '7755',
    BranchCode: '105',
    BranchName: 'Goonapola'
  },
  {
    BankCode: '7755',
    BranchCode: '106',
    BranchName: 'Moranthuduwa'
  },
  {
    BankCode: '7755',
    BranchCode: '107',
    BranchName: 'Beruwala'
  },
  {
    BankCode: '7755',
    BranchCode: '108',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7755',
    BranchCode: '109',
    BranchName: 'Horana'
  },
  {
    BankCode: '7755',
    BranchCode: '110',
    BranchName: 'Warakagoda'
  },
  {
    BankCode: '7755',
    BranchCode: '111',
    BranchName: 'Ingiriya'
  },
  {
    BankCode: '7755',
    BranchCode: '112',
    BranchName: 'Dodangoda'
  },
  {
    BankCode: '7755',
    BranchCode: '113',
    BranchName: 'Meegahathenna'
  },
  {
    BankCode: '7755',
    BranchCode: '114',
    BranchName: 'Baduraliya'
  },
  {
    BankCode: '7755',
    BranchCode: '115',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7755',
    BranchCode: '116',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7755',
    BranchCode: '117',
    BranchName: 'Mawaramandiya'
  },
  {
    BankCode: '7755',
    BranchCode: '118',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7755',
    BranchCode: '119',
    BranchName: 'Meerigama'
  },
  {
    BankCode: '7755',
    BranchCode: '120',
    BranchName: 'Moragahahena'
  },
  {
    BankCode: '7755',
    BranchCode: '121',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7755',
    BranchCode: '122',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7755',
    BranchCode: '123',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '124',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7755',
    BranchCode: '125',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7755',
    BranchCode: '126',
    BranchName: 'Kolonnawa'
  },
  {
    BankCode: '7755',
    BranchCode: '127',
    BranchName: 'Awissawella'
  },
  {
    BankCode: '7755',
    BranchCode: '128',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7755',
    BranchCode: '129',
    BranchName: 'Ragama'
  },
  {
    BankCode: '7755',
    BranchCode: '130',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7755',
    BranchCode: '131',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7755',
    BranchCode: '132',
    BranchName: 'J-Ela'
  },
  {
    BankCode: '7755',
    BranchCode: '133',
    BranchName: 'Miriswaththa'
  },
  {
    BankCode: '7755',
    BranchCode: '134',
    BranchName: 'Kelaniya � Head Quarter'
  },
  {
    BankCode: '7755',
    BranchCode: '198',
    BranchName: 'Gampaha District Office'
  },
  {
    BankCode: '7755',
    BranchCode: '199',
    BranchName: 'Kalutara District Office'
  },
  {
    BankCode: '7755',
    BranchCode: '200',
    BranchName: 'Provincial Office � Southern'
  },
  {
    BankCode: '7755',
    BranchCode: '201',
    BranchName: 'Hakmana'
  },
  {
    BankCode: '7755',
    BranchCode: '202',
    BranchName: 'Urubokka'
  },
  {
    BankCode: '7755',
    BranchCode: '203',
    BranchName: 'Deiyandara'
  },
  {
    BankCode: '7755',
    BranchCode: '204',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7755',
    BranchCode: '205',
    BranchName: 'Weligama'
  },
  {
    BankCode: '7755',
    BranchCode: '206',
    BranchName: 'Gandara'
  },
  {
    BankCode: '7755',
    BranchCode: '207',
    BranchName: 'Kekanadura'
  },
  {
    BankCode: '7755',
    BranchCode: '208',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7755',
    BranchCode: '209',
    BranchName: 'Angunukolapalassa'
  },
  {
    BankCode: '7755',
    BranchCode: '210',
    BranchName: 'Katuwana'
  },
  {
    BankCode: '7755',
    BranchCode: '211',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7755',
    BranchCode: '212',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '213',
    BranchName: 'Batapola'
  },
  {
    BankCode: '7755',
    BranchCode: '214',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7755',
    BranchCode: '215',
    BranchName: 'Gonagalapura'
  },
  {
    BankCode: '7755',
    BranchCode: '216',
    BranchName: 'Imaduwa'
  },
  {
    BankCode: '7755',
    BranchCode: '217',
    BranchName: 'Baddegama'
  },
  {
    BankCode: '7755',
    BranchCode: '218',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7755',
    BranchCode: '219',
    BranchName: 'Lunugamwehera'
  },
  {
    BankCode: '7755',
    BranchCode: '220',
    BranchName: 'Pitabaddera'
  },
  {
    BankCode: '7755',
    BranchCode: '221',
    BranchName: 'Thalgaswala'
  },
  {
    BankCode: '7755',
    BranchCode: '222',
    BranchName: 'Akmeemana'
  },
  {
    BankCode: '7755',
    BranchCode: '223',
    BranchName: 'Karandeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '224',
    BranchName: 'Sooriyawewa'
  },
  {
    BankCode: '7755',
    BranchCode: '225',
    BranchName: 'Kamburugamuwa'
  },
  {
    BankCode: '7755',
    BranchCode: '226',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7755',
    BranchCode: '227',
    BranchName: 'Kamburupitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '228',
    BranchName: 'Galle'
  },
  {
    BankCode: '7755',
    BranchCode: '229',
    BranchName: 'Uragasmanhandiya'
  },
  {
    BankCode: '7755',
    BranchCode: '230',
    BranchName: 'Yakkalamulla'
  },
  {
    BankCode: '7755',
    BranchCode: '231',
    BranchName: 'Weerakatiya'
  },
  {
    BankCode: '7755',
    BranchCode: '232',
    BranchName: 'Thihagoda'
  },
  {
    BankCode: '7755',
    BranchCode: '233',
    BranchName: 'City � Matara'
  },
  {
    BankCode: '7755',
    BranchCode: '234',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7755',
    BranchCode: '235',
    BranchName: 'Neluwa'
  },
  {
    BankCode: '7755',
    BranchCode: '236',
    BranchName: 'Mawarala'
  },
  {
    BankCode: '7755',
    BranchCode: '237',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7755',
    BranchCode: '238',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7755',
    BranchCode: '239',
    BranchName: 'Walasmulla'
  },
  {
    BankCode: '7755',
    BranchCode: '240',
    BranchName: 'Barawakumbuka'
  },
  {
    BankCode: '7755',
    BranchCode: '241',
    BranchName: 'Udugama'
  },
  {
    BankCode: '7755',
    BranchCode: '242',
    BranchName: 'Ranna'
  },
  {
    BankCode: '7755',
    BranchCode: '243',
    BranchName: 'Ahangama'
  },
  {
    BankCode: '7755',
    BranchCode: '244',
    BranchName: 'Hikkaduwa'
  },
  {
    BankCode: '7755',
    BranchCode: '245',
    BranchName: 'Kirinda'
  },
  {
    BankCode: '7755',
    BranchCode: '246',
    BranchName: 'Middeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '247',
    BranchName: 'Dikwella'
  },
  {
    BankCode: '7755',
    BranchCode: '248',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '249',
    BranchName: 'Balapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '250',
    BranchName: 'Pamburana'
  },
  {
    BankCode: '7755',
    BranchCode: '251',
    BranchName: 'Mirissa'
  },
  {
    BankCode: '7755',
    BranchCode: '252',
    BranchName: 'Kaluwella'
  },
  {
    BankCode: '7755',
    BranchCode: '253',
    BranchName: 'Warapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '254',
    BranchName: 'Devinuwara'
  },
  {
    BankCode: '7755',
    BranchCode: '297',
    BranchName: 'District Office � Hambantota'
  },
  {
    BankCode: '7755',
    BranchCode: '298',
    BranchName: 'District Office � Galle'
  },
  {
    BankCode: '7755',
    BranchCode: '299',
    BranchName: 'District Office � Matara'
  },
  {
    BankCode: '7755',
    BranchCode: '300',
    BranchName: 'Wayamba Provincial Office'
  },
  {
    BankCode: '7755',
    BranchCode: '301',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '302',
    BranchName: 'Pannala'
  },
  {
    BankCode: '7755',
    BranchCode: '303',
    BranchName: 'Panduwasnuwara'
  },
  {
    BankCode: '7755',
    BranchCode: '304',
    BranchName: 'Alawwa'
  },
  {
    BankCode: '7755',
    BranchCode: '305',
    BranchName: 'Dummalasooriya'
  },
  {
    BankCode: '7755',
    BranchCode: '306',
    BranchName: 'Pothuhera'
  },
  {
    BankCode: '7755',
    BranchCode: '307',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7755',
    BranchCode: '308',
    BranchName: 'Rideegama'
  },
  {
    BankCode: '7755',
    BranchCode: '309',
    BranchName: 'Mawathagama'
  },
  {
    BankCode: '7755',
    BranchCode: '310',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7755',
    BranchCode: '311',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7755',
    BranchCode: '312',
    BranchName: 'Galgamuwa'
  },
  {
    BankCode: '7755',
    BranchCode: '313',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7755',
    BranchCode: '314',
    BranchName: 'Palakuda'
  },
  {
    BankCode: '7755',
    BranchCode: '315',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7755',
    BranchCode: '316',
    BranchName: 'Nattandiya'
  },
  {
    BankCode: '7755',
    BranchCode: '317',
    BranchName: 'Kirimetiyana'
  },
  {
    BankCode: '7755',
    BranchCode: '318',
    BranchName: 'Puttlam'
  },
  {
    BankCode: '7755',
    BranchCode: '319',
    BranchName: 'Maho'
  },
  {
    BankCode: '7755',
    BranchCode: '320',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7755',
    BranchCode: '321',
    BranchName: 'Ibbagamuwa'
  },
  {
    BankCode: '7755',
    BranchCode: '322',
    BranchName: 'Mundel'
  },
  {
    BankCode: '7755',
    BranchCode: '323',
    BranchName: 'Nawagattegama'
  },
  {
    BankCode: '7755',
    BranchCode: '324',
    BranchName: 'Mampuri'
  },
  {
    BankCode: '7755',
    BranchCode: '325',
    BranchName: 'Mahawewa'
  },
  {
    BankCode: '7755',
    BranchCode: '326',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7755',
    BranchCode: '327',
    BranchName: 'Polpithigama'
  },
  {
    BankCode: '7755',
    BranchCode: '328',
    BranchName: 'Bowatte'
  },
  {
    BankCode: '7755',
    BranchCode: '329',
    BranchName: 'HQB'
  },
  {
    BankCode: '7755',
    BranchCode: '330',
    BranchName: 'Melsiripura'
  },
  {
    BankCode: '7755',
    BranchCode: '331',
    BranchName: 'Ambanpola'
  },
  {
    BankCode: '7755',
    BranchCode: '332',
    BranchName: 'Santha Anapura'
  },
  {
    BankCode: '7755',
    BranchCode: '398',
    BranchName: 'Puttlam District Office'
  },
  {
    BankCode: '7755',
    BranchCode: '399',
    BranchName: 'Kurunegala District Office'
  },
  {
    BankCode: '7755',
    BranchCode: '400',
    BranchName: 'Provincial Office � North Central'
  },
  {
    BankCode: '7755',
    BranchCode: '401',
    BranchName: 'Mihinthale'
  },
  {
    BankCode: '7755',
    BranchCode: '402',
    BranchName: 'Town Branch � Anuradhapura'
  },
  {
    BankCode: '7755',
    BranchCode: '403',
    BranchName: 'Thambuththegama'
  },
  {
    BankCode: '7755',
    BranchCode: '404',
    BranchName: 'Kahatagasdigiliya'
  },
  {
    BankCode: '7755',
    BranchCode: '405',
    BranchName: 'Galnewa'
  },
  {
    BankCode: '7755',
    BranchCode: '406',
    BranchName: 'Thalawa'
  },
  {
    BankCode: '7755',
    BranchCode: '407',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7755',
    BranchCode: '408',
    BranchName: 'Thirappane'
  },
  {
    BankCode: '7755',
    BranchCode: '409',
    BranchName: 'Rambewa'
  },
  {
    BankCode: '7755',
    BranchCode: '410',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7755',
    BranchCode: '411',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7755',
    BranchCode: '412',
    BranchName: 'Pulasthigama'
  },
  {
    BankCode: '7755',
    BranchCode: '413',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7755',
    BranchCode: '414',
    BranchName: 'Bakamoona'
  },
  {
    BankCode: '7755',
    BranchCode: '415',
    BranchName: 'Galenbindunuwewa'
  },
  {
    BankCode: '7755',
    BranchCode: '416',
    BranchName: 'Gonapathirawa'
  },
  {
    BankCode: '7755',
    BranchCode: '417',
    BranchName: 'Manampitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '418',
    BranchName: 'Galamuna'
  },
  {
    BankCode: '7755',
    BranchCode: '419',
    BranchName: 'New Town � Anuradhapura'
  },
  {
    BankCode: '7755',
    BranchCode: '420',
    BranchName: 'Siripura'
  },
  {
    BankCode: '7755',
    BranchCode: '421',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7755',
    BranchCode: '422',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7755',
    BranchCode: '423',
    BranchName: 'Aralaganwila'
  },
  {
    BankCode: '7755',
    BranchCode: '424',
    BranchName: 'Economic Centre'
  },
  {
    BankCode: '7755',
    BranchCode: '425',
    BranchName: 'Sevanapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '498',
    BranchName: 'Dritrict Office Polonnaruwa'
  },
  {
    BankCode: '7755',
    BranchCode: '500',
    BranchName: 'Provincial Office � Sabaragamuwa'
  },
  {
    BankCode: '7755',
    BranchCode: '501',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7755',
    BranchCode: '502',
    BranchName: 'Pitagaldeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '503',
    BranchName: 'Rambukkana'
  },
  {
    BankCode: '7755',
    BranchCode: '504',
    BranchName: 'Mawanella'
  },
  {
    BankCode: '7755',
    BranchCode: '505',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7755',
    BranchCode: '506',
    BranchName: 'Aranayaka'
  },
  {
    BankCode: '7755',
    BranchCode: '507',
    BranchName: 'Kithulgala'
  },
  {
    BankCode: '7755',
    BranchCode: '508',
    BranchName: 'Ruwanwella'
  },
  {
    BankCode: '7755',
    BranchCode: '509',
    BranchName: 'Dewalegama'
  },
  {
    BankCode: '7755',
    BranchCode: '510',
    BranchName: 'Bulathkohupitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '511',
    BranchName: 'Deraniyagala'
  },
  {
    BankCode: '7755',
    BranchCode: '512',
    BranchName: 'Rathnapura'
  },
  {
    BankCode: '7755',
    BranchCode: '513',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7755',
    BranchCode: '514',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7755',
    BranchCode: '515',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '516',
    BranchName: 'Hemmathagama'
  },
  {
    BankCode: '7755',
    BranchCode: '517',
    BranchName: 'Kolonna'
  },
  {
    BankCode: '7755',
    BranchCode: '518',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7755',
    BranchCode: '519',
    BranchName: 'Nelumdeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '520',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7755',
    BranchCode: '521',
    BranchName: 'Yatiyantota'
  },
  {
    BankCode: '7755',
    BranchCode: '522',
    BranchName: 'Godakawela'
  },
  {
    BankCode: '7755',
    BranchCode: '523',
    BranchName: 'Erathna'
  },
  {
    BankCode: '7755',
    BranchCode: '524',
    BranchName: 'Kuruwita'
  },
  {
    BankCode: '7755',
    BranchCode: '525',
    BranchName: 'Nivithigala'
  },
  {
    BankCode: '7755',
    BranchCode: '526',
    BranchName: 'Kahawatta'
  },
  {
    BankCode: '7755',
    BranchCode: '527',
    BranchName: 'Kotiyakumbura'
  },
  {
    BankCode: '7755',
    BranchCode: '528',
    BranchName: 'Rakwana'
  },
  {
    BankCode: '7755',
    BranchCode: '529',
    BranchName: 'Dehiovita'
  },
  {
    BankCode: '7755',
    BranchCode: '530',
    BranchName: 'Kiriella'
  },
  {
    BankCode: '7755',
    BranchCode: '531',
    BranchName: 'Pothupitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '532',
    BranchName: 'Weligepola'
  },
  {
    BankCode: '7755',
    BranchCode: '533',
    BranchName: 'Sri Palabaddala'
  },
  {
    BankCode: '7755',
    BranchCode: '534',
    BranchName: 'Pulungupitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '599',
    BranchName: 'Kegalle District Office'
  },
  {
    BankCode: '7755',
    BranchCode: '600',
    BranchName: 'Provincial Office � Central'
  },
  {
    BankCode: '7755',
    BranchCode: '601',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7755',
    BranchCode: '602',
    BranchName: 'Udawela'
  },
  {
    BankCode: '7755',
    BranchCode: '603',
    BranchName: 'Hataraliyadda'
  },
  {
    BankCode: '7755',
    BranchCode: '604',
    BranchName: 'Marassana'
  },
  {
    BankCode: '7755',
    BranchCode: '605',
    BranchName: 'Danture'
  },
  {
    BankCode: '7755',
    BranchCode: '606',
    BranchName: 'Wattegama'
  },
  {
    BankCode: '7755',
    BranchCode: '607',
    BranchName: 'Morayaya'
  },
  {
    BankCode: '7755',
    BranchCode: '608',
    BranchName: 'Teldeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '609',
    BranchName: 'Pujapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '610',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7755',
    BranchCode: '611',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7755',
    BranchCode: '612',
    BranchName: 'Kandy Marketing Centre'
  },
  {
    BankCode: '7755',
    BranchCode: '613',
    BranchName: 'Ginigathena'
  },
  {
    BankCode: '7755',
    BranchCode: '614',
    BranchName: 'Poondaluoya'
  },
  {
    BankCode: '7755',
    BranchCode: '615',
    BranchName: 'Katugastota'
  },
  {
    BankCode: '7755',
    BranchCode: '616',
    BranchName: 'Nildandahinna'
  },
  {
    BankCode: '7755',
    BranchCode: '617',
    BranchName: 'Agarapathana'
  },
  {
    BankCode: '7755',
    BranchCode: '618',
    BranchName: 'Ududumbara'
  },
  {
    BankCode: '7755',
    BranchCode: '619',
    BranchName: 'Matale'
  },
  {
    BankCode: '7755',
    BranchCode: '620',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7755',
    BranchCode: '621',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7755',
    BranchCode: '622',
    BranchName: 'Laggala'
  },
  {
    BankCode: '7755',
    BranchCode: '623',
    BranchName: 'Rattota'
  },
  {
    BankCode: '7755',
    BranchCode: '624',
    BranchName: 'Kotagala'
  },
  {
    BankCode: '7755',
    BranchCode: '625',
    BranchName: 'Menikhinna'
  },
  {
    BankCode: '7755',
    BranchCode: '626',
    BranchName: 'Hanguranketha'
  },
  {
    BankCode: '7755',
    BranchCode: '627',
    BranchName: 'Daulagala'
  },
  {
    BankCode: '7755',
    BranchCode: '628',
    BranchName: 'Naula'
  },
  {
    BankCode: '7755',
    BranchCode: '629',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7755',
    BranchCode: '630',
    BranchName: 'Hedeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '631',
    BranchName: 'Wilgamuwa'
  },
  {
    BankCode: '7755',
    BranchCode: '632',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7755',
    BranchCode: '633',
    BranchName: 'Peradeniya'
  },
  {
    BankCode: '7755',
    BranchCode: '700',
    BranchName: 'Provincial Office � Uva'
  },
  {
    BankCode: '7755',
    BranchCode: '701',
    BranchName: 'Buttala'
  },
  {
    BankCode: '7755',
    BranchCode: '702',
    BranchName: 'Medagama'
  },
  {
    BankCode: '7755',
    BranchCode: '703',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7755',
    BranchCode: '704',
    BranchName: 'Thanamalvila'
  },
  {
    BankCode: '7755',
    BranchCode: '705',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7755',
    BranchCode: '706',
    BranchName: 'Passara'
  },
  {
    BankCode: '7755',
    BranchCode: '707',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7755',
    BranchCode: '708',
    BranchName: 'Kandaketiya'
  },
  {
    BankCode: '7755',
    BranchCode: '709',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7755',
    BranchCode: '710',
    BranchName: 'wellawaya'
  },
  {
    BankCode: '7755',
    BranchCode: '711',
    BranchName: 'Badalkumbura'
  },
  {
    BankCode: '7755',
    BranchCode: '712',
    BranchName: 'Haputhale'
  },
  {
    BankCode: '7755',
    BranchCode: '713',
    BranchName: 'Rideemaliyadda'
  },
  {
    BankCode: '7755',
    BranchCode: '714',
    BranchName: 'Uvaparanagama'
  },
  {
    BankCode: '7755',
    BranchCode: '715',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7755',
    BranchCode: '716',
    BranchName: 'Meegahakiula'
  },
  {
    BankCode: '7755',
    BranchCode: '717',
    BranchName: 'Lunugala'
  },
  {
    BankCode: '7755',
    BranchCode: '718',
    BranchName: 'Haldummulla'
  },
  {
    BankCode: '7755',
    BranchCode: '719',
    BranchName: 'Girandurukotte'
  },
  {
    BankCode: '7755',
    BranchCode: '720',
    BranchName: 'Bogahakumbura'
  },
  {
    BankCode: '7755',
    BranchCode: '721',
    BranchName: 'Bibile'
  },
  {
    BankCode: '7755',
    BranchCode: '722',
    BranchName: 'Uva Maligathenna'
  },
  {
    BankCode: '7755',
    BranchCode: '723',
    BranchName: 'Siyambalanduwa'
  },
  {
    BankCode: '7755',
    BranchCode: '724',
    BranchName: 'Diyathalawa'
  },
  {
    BankCode: '7755',
    BranchCode: '725',
    BranchName: 'Sewanagala'
  },
  {
    BankCode: '7755',
    BranchCode: '726',
    BranchName: 'Madulla'
  },
  {
    BankCode: '7755',
    BranchCode: '801',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7755',
    BranchCode: '802',
    BranchName: 'Dehiatthakandiya'
  },
  {
    BankCode: '7755',
    BranchCode: '803',
    BranchName: 'Sammanthurai'
  },
  {
    BankCode: '7755',
    BranchCode: '804',
    BranchName: 'Hingurana'
  },
  {
    BankCode: '7755',
    BranchCode: '805',
    BranchName: 'Akkaraipaththu'
  },
  {
    BankCode: '7755',
    BranchCode: '806',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7755',
    BranchCode: '807',
    BranchName: 'Mahaoya'
  },
  {
    BankCode: '7755',
    BranchCode: '808',
    BranchName: 'Pothuvil'
  },
  {
    BankCode: '7755',
    BranchCode: '809',
    BranchName: 'Uhana'
  },
  {
    BankCode: '7755',
    BranchCode: '810',
    BranchName: 'Nintavur'
  },
  {
    BankCode: '7755',
    BranchCode: '811',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7755',
    BranchCode: '812',
    BranchName: 'Eraur'
  },
  {
    BankCode: '7755',
    BranchCode: '813',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7755',
    BranchCode: '814',
    BranchName: 'Kanthale'
  },
  {
    BankCode: '7755',
    BranchCode: '815',
    BranchName: 'Valachenai'
  },
  {
    BankCode: '7755',
    BranchCode: '816',
    BranchName: 'Kathankudi'
  },
  {
    BankCode: '7755',
    BranchCode: '817',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7755',
    BranchCode: '818',
    BranchName: 'Kalauwanchikudi'
  },
  {
    BankCode: '7755',
    BranchCode: '819',
    BranchName: 'Kokkadichcholai'
  },
  {
    BankCode: '7755',
    BranchCode: '820',
    BranchName: 'Muthtur'
  },
  {
    BankCode: '7755',
    BranchCode: '901',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7755',
    BranchCode: '902',
    BranchName: 'Kanagarayankulam'
  },
  {
    BankCode: '7755',
    BranchCode: '903',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7755',
    BranchCode: '904',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7755',
    BranchCode: '905',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7755',
    BranchCode: '906',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7755',
    BranchCode: '907',
    BranchName: 'Bogaswewa'
  },
  {
    BankCode: '7764',
    BranchCode: '1',
    BranchName: 'Head Office City'
  },
  {
    BankCode: '7764',
    BranchCode: '2',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7764',
    BranchCode: '3',
    BranchName: 'SMIB Finance Department'
  },
  {
    BankCode: '7764',
    BranchCode: '4',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7764',
    BranchCode: '5',
    BranchName: 'Galle'
  },
  {
    BankCode: '7764',
    BranchCode: '6',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7764',
    BranchCode: '7',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7764',
    BranchCode: '8',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7764',
    BranchCode: '9',
    BranchName: 'Matara'
  },
  {
    BankCode: '7764',
    BranchCode: '10',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7764',
    BranchCode: '11',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7764',
    BranchCode: '12',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7764',
    BranchCode: '13',
    BranchName: 'Horana'
  },
  {
    BankCode: '7764',
    BranchCode: '14',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7764',
    BranchCode: '15',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7764',
    BranchCode: '16',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7764',
    BranchCode: '17',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7764',
    BranchCode: '18',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7764',
    BranchCode: '19',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7764',
    BranchCode: '20',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7764',
    BranchCode: '21',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7764',
    BranchCode: '22',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7764',
    BranchCode: '23',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7764',
    BranchCode: '24',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7764',
    BranchCode: '25',
    BranchName: 'Matale'
  },
  {
    BankCode: '7764',
    BranchCode: '26',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7773',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7773',
    BranchCode: '2',
    BranchName: 'Corporate Office'
  },
  {
    BankCode: '7773',
    BranchCode: '3',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7773',
    BranchCode: '4',
    BranchName: 'Negambo'
  },
  {
    BankCode: '7773',
    BranchCode: '5',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7773',
    BranchCode: '6',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7773',
    BranchCode: '7',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7773',
    BranchCode: '8',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7773',
    BranchCode: '9',
    BranchName: 'Galle'
  },
  {
    BankCode: '7773',
    BranchCode: '10',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7773',
    BranchCode: '11',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7773',
    BranchCode: '12',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7773',
    BranchCode: '13',
    BranchName: 'Matara'
  },
  {
    BankCode: '7773',
    BranchCode: '14',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7773',
    BranchCode: '15',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7773',
    BranchCode: '16',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7773',
    BranchCode: '17',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7773',
    BranchCode: '18',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7773',
    BranchCode: '19',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7773',
    BranchCode: '20',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7773',
    BranchCode: '21',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7773',
    BranchCode: '22',
    BranchName: 'Boralesgamuwa'
  },
  {
    BankCode: '7773',
    BranchCode: '23',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7773',
    BranchCode: '24',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7773',
    BranchCode: '25',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7773',
    BranchCode: '26',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7773',
    BranchCode: '27',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7773',
    BranchCode: '28',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7773',
    BranchCode: '29',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7773',
    BranchCode: '30',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7773',
    BranchCode: '31',
    BranchName: 'Maradana'
  },
  {
    BankCode: '7773',
    BranchCode: '32',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7773',
    BranchCode: '33',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7773',
    BranchCode: '34',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7773',
    BranchCode: '35',
    BranchName: 'Matale'
  },
  {
    BankCode: '7773',
    BranchCode: '36',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7773',
    BranchCode: '37',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7773',
    BranchCode: '38',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7773',
    BranchCode: '39',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7773',
    BranchCode: '40',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7773',
    BranchCode: '41',
    BranchName: 'Horana'
  },
  {
    BankCode: '7773',
    BranchCode: '42',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7773',
    BranchCode: '43',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7773',
    BranchCode: '44',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7773',
    BranchCode: '45',
    BranchName: 'Manipay'
  },
  {
    BankCode: '7773',
    BranchCode: '46',
    BranchName: 'Chavakachcheri'
  },
  {
    BankCode: '7773',
    BranchCode: '47',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7773',
    BranchCode: '48',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7773',
    BranchCode: '49',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7773',
    BranchCode: '50',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7773',
    BranchCode: '51',
    BranchName: 'Nelliady'
  },
  {
    BankCode: '7773',
    BranchCode: '52',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7773',
    BranchCode: '53',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7773',
    BranchCode: '54',
    BranchName: 'Thambuththegama'
  },
  {
    BankCode: '7773',
    BranchCode: '55',
    BranchName: 'Akkaraipattu'
  },
  {
    BankCode: '7773',
    BranchCode: '56',
    BranchName: 'Pothuvil'
  },
  {
    BankCode: '7773',
    BranchCode: '57',
    BranchName: 'Kochchikade'
  },
  {
    BankCode: '7773',
    BranchCode: '58',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7773',
    BranchCode: '59',
    BranchName: 'Anuradhapura 02'
  },
  {
    BankCode: '7773',
    BranchCode: '60',
    BranchName: 'Rajagiriya'
  },
  {
    BankCode: '7773',
    BranchCode: '61',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7773',
    BranchCode: '62',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7773',
    BranchCode: '63',
    BranchName: 'Katugasthota'
  },
  {
    BankCode: '7773',
    BranchCode: '64',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7773',
    BranchCode: '65',
    BranchName: 'Moratumulla'
  },
  {
    BankCode: '7773',
    BranchCode: '66',
    BranchName: 'Kalawanchikudy'
  },
  {
    BankCode: '7773',
    BranchCode: '67',
    BranchName: 'Sea Street'
  },
  {
    BankCode: '7773',
    BranchCode: '68',
    BranchName: 'Matara City'
  },
  {
    BankCode: '7773',
    BranchCode: '69',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7773',
    BranchCode: '70',
    BranchName: 'Kandy City'
  },
  {
    BankCode: '7773',
    BranchCode: '71',
    BranchName: 'Warakapola'
  },
  {
    BankCode: '7773',
    BranchCode: '72',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7773',
    BranchCode: '73',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7773',
    BranchCode: '74',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7773',
    BranchCode: '75',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7773',
    BranchCode: '76',
    BranchName: 'Kurunegala City'
  },
  {
    BankCode: '7773',
    BranchCode: '77',
    BranchName: 'Maskeliya'
  },
  {
    BankCode: '7773',
    BranchCode: '78',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7773',
    BranchCode: '79',
    BranchName: 'Samanturei'
  },
  {
    BankCode: '7773',
    BranchCode: '80',
    BranchName: 'Saindamarthu'
  },
  {
    BankCode: '7773',
    BranchCode: '81',
    BranchName: 'Kotte'
  },
  {
    BankCode: '7773',
    BranchCode: '82',
    BranchName: 'Badulla City'
  },
  {
    BankCode: '7773',
    BranchCode: '83',
    BranchName: 'Ja Ela'
  },
  {
    BankCode: '7773',
    BranchCode: '84',
    BranchName: 'Delkada'
  },
  {
    BankCode: '7773',
    BranchCode: '85',
    BranchName: 'Kaththankudi'
  },
  {
    BankCode: '7773',
    BranchCode: '86',
    BranchName: 'Dam Street'
  },
  {
    BankCode: '7773',
    BranchCode: '87',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7773',
    BranchCode: '88',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7773',
    BranchCode: '89',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7773',
    BranchCode: '90',
    BranchName: 'Mount Lavinia'
  },
  {
    BankCode: '7773',
    BranchCode: '91',
    BranchName: 'Neluwa'
  },
  {
    BankCode: '7773',
    BranchCode: '92',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7773',
    BranchCode: '93',
    BranchName: 'Kurunegala Premier Centre'
  },
  {
    BankCode: '7773',
    BranchCode: '94',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7773',
    BranchCode: '95',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7773',
    BranchCode: '96',
    BranchName: 'Chenkalady'
  },
  {
    BankCode: '7773',
    BranchCode: '97',
    BranchName: 'Pussellawa'
  },
  {
    BankCode: '7773',
    BranchCode: '98',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7773',
    BranchCode: '99',
    BranchName: 'Jaffna City'
  },
  {
    BankCode: '7773',
    BranchCode: '100',
    BranchName: 'Jaffna Premier'
  },
  {
    BankCode: '7773',
    BranchCode: '101',
    BranchName: 'Valaichchenai'
  },
  {
    BankCode: '7773',
    BranchCode: '102',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7773',
    BranchCode: '104',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7773',
    BranchCode: '105',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7773',
    BranchCode: '106',
    BranchName: 'Kokkadicholai'
  },
  {
    BankCode: '7773',
    BranchCode: '107',
    BranchName: 'Gelioya'
  },
  {
    BankCode: '7773',
    BranchCode: '109',
    BranchName: 'Kinniya'
  },
  {
    BankCode: '7773',
    BranchCode: '110',
    BranchName: 'Anamaduwa'
  },
  {
    BankCode: '7773',
    BranchCode: '111',
    BranchName: 'Puthukkudiyiruppu'
  },
  {
    BankCode: '7773',
    BranchCode: '112',
    BranchName: 'Delgoda'
  },
  {
    BankCode: '7773',
    BranchCode: '113',
    BranchName: 'Eheliyagoda'
  },
  {
    BankCode: '7773',
    BranchCode: '114',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7773',
    BranchCode: '115',
    BranchName: 'Divulapitiya'
  },
  {
    BankCode: '7773',
    BranchCode: '116',
    BranchName: 'Narammala'
  },
  {
    BankCode: '7773',
    BranchCode: '117',
    BranchName: 'Medirigiriya'
  },
  {
    BankCode: '7773',
    BranchCode: '118',
    BranchName: 'Achchuveli'
  },
  {
    BankCode: '7773',
    BranchCode: '119',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7773',
    BranchCode: '120',
    BranchName: 'City office'
  },
  {
    BankCode: '7773',
    BranchCode: '121',
    BranchName: 'Wadduwa'
  },
  {
    BankCode: '7773',
    BranchCode: '122',
    BranchName: 'Dharga Town'
  },
  {
    BankCode: '7773',
    BranchCode: '123',
    BranchName: 'Karapitiya'
  },
  {
    BankCode: '7773',
    BranchCode: '124',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7773',
    BranchCode: '125',
    BranchName: 'Mullaitivu'
  },
  {
    BankCode: '7782',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7807',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7807',
    BranchCode: '2',
    BranchName: 'Virtual Branch'
  },
  {
    BankCode: '7816',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7825',
    BranchCode: '1',
    BranchName: 'Colombo'
  },
  {
    BankCode: '7825',
    BranchCode: '2',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7825',
    BranchCode: '16',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7834',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7852',
    BranchCode: '2',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7852',
    BranchCode: '3',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7852',
    BranchCode: '4',
    BranchName: 'Galle'
  },
  {
    BankCode: '7852',
    BranchCode: '5',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7852',
    BranchCode: '6',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7852',
    BranchCode: '7',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7852',
    BranchCode: '8',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7852',
    BranchCode: '9',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7852',
    BranchCode: '10',
    BranchName: 'Horana'
  },
  {
    BankCode: '7852',
    BranchCode: '11',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7852',
    BranchCode: '12',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7852',
    BranchCode: '13',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7852',
    BranchCode: '14',
    BranchName: 'Matara'
  },
  {
    BankCode: '7852',
    BranchCode: '15',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7852',
    BranchCode: '16',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7852',
    BranchCode: '17',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7852',
    BranchCode: '18',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7852',
    BranchCode: '19',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7852',
    BranchCode: '20',
    BranchName: 'Ganemulla'
  },
  {
    BankCode: '7852',
    BranchCode: '21',
    BranchName: 'Kaduruwela'
  },
  {
    BankCode: '7852',
    BranchCode: '22',
    BranchName: 'Ambalanthota'
  },
  {
    BankCode: '7852',
    BranchCode: '23',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7852',
    BranchCode: '24',
    BranchName: 'Galewela'
  },
  {
    BankCode: '7852',
    BranchCode: '25',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7852',
    BranchCode: '26',
    BranchName: 'Kalpitiya'
  },
  {
    BankCode: '7852',
    BranchCode: '27',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7852',
    BranchCode: '28',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7852',
    BranchCode: '29',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7852',
    BranchCode: '30',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7852',
    BranchCode: '31',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7852',
    BranchCode: '32',
    BranchName: 'Malambe'
  },
  {
    BankCode: '7852',
    BranchCode: '33',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7852',
    BranchCode: '34',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7852',
    BranchCode: '35',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7852',
    BranchCode: '36',
    BranchName: 'Aluthgama'
  },
  {
    BankCode: '7861',
    BranchCode: '0',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7898',
    BranchCode: '3',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7898',
    BranchCode: '100',
    BranchName: 'Union Place'
  },
  {
    BankCode: '7898',
    BranchCode: '101',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7898',
    BranchCode: '102',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7898',
    BranchCode: '103',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7898',
    BranchCode: '104',
    BranchName: 'Kottawa'
  },
  {
    BankCode: '7898',
    BranchCode: '105',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7898',
    BranchCode: '106',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7898',
    BranchCode: '107',
    BranchName: 'Galle'
  },
  {
    BankCode: '7898',
    BranchCode: '108',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7898',
    BranchCode: '109',
    BranchName: 'Kotahena'
  },
  {
    BankCode: '7898',
    BranchCode: '110',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7898',
    BranchCode: '112',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7898',
    BranchCode: '113',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7898',
    BranchCode: '114',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7898',
    BranchCode: '115',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7898',
    BranchCode: '116',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7898',
    BranchCode: '117',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7898',
    BranchCode: '118',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7898',
    BranchCode: '119',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7898',
    BranchCode: '120',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7898',
    BranchCode: '121',
    BranchName: 'City Office'
  },
  {
    BankCode: '7898',
    BranchCode: '122',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7898',
    BranchCode: '123',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7898',
    BranchCode: '124',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7898',
    BranchCode: '125',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7898',
    BranchCode: '126',
    BranchName: 'Hingurakgoda'
  },
  {
    BankCode: '7898',
    BranchCode: '127',
    BranchName: 'Horana'
  },
  {
    BankCode: '7898',
    BranchCode: '128',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7898',
    BranchCode: '129',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7898',
    BranchCode: '130',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7898',
    BranchCode: '131',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7898',
    BranchCode: '132',
    BranchName: 'Kiribathgoda'
  },
  {
    BankCode: '7898',
    BranchCode: '133',
    BranchName: 'Kandy City Office'
  },
  {
    BankCode: '7898',
    BranchCode: '134',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7898',
    BranchCode: '135',
    BranchName: 'Kakirawa'
  },
  {
    BankCode: '7898',
    BranchCode: '136',
    BranchName: 'Kandana'
  },
  {
    BankCode: '7898',
    BranchCode: '137',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7898',
    BranchCode: '138',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7898',
    BranchCode: '140',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7898',
    BranchCode: '141',
    BranchName: 'Malabe'
  },
  {
    BankCode: '7898',
    BranchCode: '142',
    BranchName: 'Matara'
  },
  {
    BankCode: '7898',
    BranchCode: '143',
    BranchName: 'Matugama'
  },
  {
    BankCode: '7898',
    BranchCode: '144',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7898',
    BranchCode: '145',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7898',
    BranchCode: '146',
    BranchName: 'Puttlam'
  },
  {
    BankCode: '7898',
    BranchCode: '147',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7898',
    BranchCode: '148',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7898',
    BranchCode: '149',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7898',
    BranchCode: '150',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7898',
    BranchCode: '151',
    BranchName: 'Head Office'
  },
  {
    BankCode: '',
    BranchCode: '',
    BranchName: ''
  },
  {
    BankCode: 'HNB Finance Limited',
    BranchCode: '',
    BranchName: ''
  },
  {
    BankCode: 'BankCode',
    BranchCode: 'BranchCode',
    BranchName: 'BranchName'
  },
  {
    BankCode: '7904',
    BranchCode: '1',
    BranchName: 'City'
  },
  {
    BankCode: '7904',
    BranchCode: '2',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7904',
    BranchCode: '3',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7904',
    BranchCode: '4',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7904',
    BranchCode: '5',
    BranchName: 'Bandaragama'
  },
  {
    BankCode: '7904',
    BranchCode: '6',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7904',
    BranchCode: '7',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7904',
    BranchCode: '8',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7904',
    BranchCode: '9',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7904',
    BranchCode: '10',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7904',
    BranchCode: '11',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7904',
    BranchCode: '12',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7904',
    BranchCode: '13',
    BranchName: 'Matale'
  },
  {
    BankCode: '7904',
    BranchCode: '14',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7904',
    BranchCode: '15',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7904',
    BranchCode: '16',
    BranchName: 'Puttalam'
  },
  {
    BankCode: '7904',
    BranchCode: '17',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7904',
    BranchCode: '18',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7904',
    BranchCode: '19',
    BranchName: 'Batticoloa'
  },
  {
    BankCode: '7904',
    BranchCode: '20',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7904',
    BranchCode: '21',
    BranchName: 'Matara'
  },
  {
    BankCode: '7904',
    BranchCode: '22',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7904',
    BranchCode: '23',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7904',
    BranchCode: '24',
    BranchName: 'Kantale'
  },
  {
    BankCode: '7904',
    BranchCode: '25',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7904',
    BranchCode: '26',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7904',
    BranchCode: '27',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7904',
    BranchCode: '28',
    BranchName: 'Beliatta'
  },
  {
    BankCode: '7904',
    BranchCode: '29',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7904',
    BranchCode: '30',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7904',
    BranchCode: '31',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7904',
    BranchCode: '32',
    BranchName: 'Moneragala'
  },
  {
    BankCode: '7904',
    BranchCode: '33',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7904',
    BranchCode: '34',
    BranchName: 'Galle'
  },
  {
    BankCode: '7904',
    BranchCode: '35',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7904',
    BranchCode: '36',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7904',
    BranchCode: '37',
    BranchName: 'Horana'
  },
  {
    BankCode: '7904',
    BranchCode: '38',
    BranchName: 'Rathnapura'
  },
  {
    BankCode: '7904',
    BranchCode: '39',
    BranchName: 'Nikaweratiya'
  },
  {
    BankCode: '7904',
    BranchCode: '40',
    BranchName: 'Galgamuwa'
  },
  {
    BankCode: '7904',
    BranchCode: '41',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7904',
    BranchCode: '42',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7904',
    BranchCode: '43',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7904',
    BranchCode: '44',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7904',
    BranchCode: '45',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7904',
    BranchCode: '46',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7904',
    BranchCode: '47',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7904',
    BranchCode: '48',
    BranchName: 'Padaviya'
  },
  {
    BankCode: '7904',
    BranchCode: '49',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7904',
    BranchCode: '50',
    BranchName: 'Kalmunai'
  },
  {
    BankCode: '7904',
    BranchCode: '51',
    BranchName: 'Nellyadi'
  },
  {
    BankCode: '7904',
    BranchCode: '52',
    BranchName: 'Mullaitivu'
  },
  {
    BankCode: '7904',
    BranchCode: '53',
    BranchName: 'Pottuvil'
  },
  {
    BankCode: '7904',
    BranchCode: '54',
    BranchName: 'Mallavi'
  },
  {
    BankCode: '7904',
    BranchCode: '55',
    BranchName: 'Norocholai'
  },
  {
    BankCode: '7904',
    BranchCode: '56',
    BranchName: 'Morawaka'
  },
  {
    BankCode: '7904',
    BranchCode: '57',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7904',
    BranchCode: '58',
    BranchName: 'Nedunkeni'
  },
  {
    BankCode: '7904',
    BranchCode: '59',
    BranchName: 'Nochchiyagama'
  },
  {
    BankCode: '7904',
    BranchCode: '60',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7904',
    BranchCode: '61',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7904',
    BranchCode: '62',
    BranchName: 'Galenbindunuwewa'
  },
  {
    BankCode: '7904',
    BranchCode: '63',
    BranchName: 'Rikillagaskada'
  },
  {
    BankCode: '7904',
    BranchCode: '64',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7904',
    BranchCode: '65',
    BranchName: 'Walapane'
  },
  {
    BankCode: '7904',
    BranchCode: '66',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7904',
    BranchCode: '67',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7904',
    BranchCode: '68',
    BranchName: 'Pitigala'
  },
  {
    BankCode: '7904',
    BranchCode: '69',
    BranchName: 'Dehiattakandiya'
  },
  {
    BankCode: '7904',
    BranchCode: '999',
    BranchName: 'Head office'
  },
  {
    BankCode: '7913',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7922',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7922',
    BranchCode: '2',
    BranchName: 'Akuressa'
  },
  {
    BankCode: '7922',
    BranchCode: '3',
    BranchName: 'Ambalangoda'
  },
  {
    BankCode: '7922',
    BranchCode: '4',
    BranchName: 'Ambalantota'
  },
  {
    BankCode: '7922',
    BranchCode: '5',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7922',
    BranchCode: '6',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7922',
    BranchCode: '7',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7922',
    BranchCode: '8',
    BranchName: 'Badulla'
  },
  {
    BankCode: '7922',
    BranchCode: '9',
    BranchName: 'Balangoda'
  },
  {
    BankCode: '7922',
    BranchCode: '10',
    BranchName: 'Bandarawela'
  },
  {
    BankCode: '7922',
    BranchCode: '11',
    BranchName: 'Battaramulla'
  },
  {
    BankCode: '7922',
    BranchCode: '12',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7922',
    BranchCode: '13',
    BranchName: 'Bentota'
  },
  {
    BankCode: '7922',
    BranchCode: '14',
    BranchName: 'Chilaw'
  },
  {
    BankCode: '7922',
    BranchCode: '15',
    BranchName: 'Chunnakam'
  },
  {
    BankCode: '7922',
    BranchCode: '16',
    BranchName: 'Kollupitiya'
  },
  {
    BankCode: '7922',
    BranchCode: '17',
    BranchName: 'Dambulla'
  },
  {
    BankCode: '7922',
    BranchCode: '18',
    BranchName: 'Dehiwala'
  },
  {
    BankCode: '7922',
    BranchCode: '19',
    BranchName: 'Deniyaya'
  },
  {
    BankCode: '7922',
    BranchCode: '20',
    BranchName: 'Digana'
  },
  {
    BankCode: '7922',
    BranchCode: '21',
    BranchName: 'Elpitiya'
  },
  {
    BankCode: '7922',
    BranchCode: '22',
    BranchName: 'Embilipitiya'
  },
  {
    BankCode: '7922',
    BranchCode: '23',
    BranchName: 'Galle'
  },
  {
    BankCode: '7922',
    BranchCode: '24',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7922',
    BranchCode: '25',
    BranchName: 'Gampola'
  },
  {
    BankCode: '7922',
    BranchCode: '26',
    BranchName: 'Giriulla'
  },
  {
    BankCode: '7922',
    BranchCode: '27',
    BranchName: 'Grandpass'
  },
  {
    BankCode: '7922',
    BranchCode: '28',
    BranchName: 'Hambantota'
  },
  {
    BankCode: '7922',
    BranchCode: '29',
    BranchName: 'Hatton'
  },
  {
    BankCode: '7922',
    BranchCode: '30',
    BranchName: 'Havelock City'
  },
  {
    BankCode: '7922',
    BranchCode: '31',
    BranchName: 'Homagama'
  },
  {
    BankCode: '7922',
    BranchCode: '32',
    BranchName: 'Horana'
  },
  {
    BankCode: '7922',
    BranchCode: '33',
    BranchName: 'Ja-Ela'
  },
  {
    BankCode: '7922',
    BranchCode: '34',
    BranchName: 'Jaffna'
  },
  {
    BankCode: '7922',
    BranchCode: '35',
    BranchName: 'Kadawatha'
  },
  {
    BankCode: '7922',
    BranchCode: '36',
    BranchName: 'Kaduwela'
  },
  {
    BankCode: '7922',
    BranchCode: '37',
    BranchName: 'Kalawana'
  },
  {
    BankCode: '7922',
    BranchCode: '38',
    BranchName: 'Kalmunai � AlSafa'
  },
  {
    BankCode: '7922',
    BranchCode: '39',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7922',
    BranchCode: '40',
    BranchName: 'Kaluwanchikudy'
  },
  {
    BankCode: '7922',
    BranchCode: '41',
    BranchName: 'Kandy'
  },
  {
    BankCode: '7922',
    BranchCode: '42',
    BranchName: 'Kandy � AlSafa'
  },
  {
    BankCode: '7922',
    BranchCode: '43',
    BranchName: 'Kanthale'
  },
  {
    BankCode: '7922',
    BranchCode: '44',
    BranchName: 'Kattankudy � AlSafa'
  },
  {
    BankCode: '7922',
    BranchCode: '45',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7922',
    BranchCode: '46',
    BranchName: 'Kekirawa'
  },
  {
    BankCode: '7922',
    BranchCode: '47',
    BranchName: 'Kelaniya'
  },
  {
    BankCode: '7922',
    BranchCode: '48',
    BranchName: 'Kilinochchi'
  },
  {
    BankCode: '7922',
    BranchCode: '49',
    BranchName: 'Kirindiwela'
  },
  {
    BankCode: '7922',
    BranchCode: '50',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7922',
    BranchCode: '51',
    BranchName: 'Kurunegala'
  },
  {
    BankCode: '7922',
    BranchCode: '52',
    BranchName: 'Mahaiyawa'
  },
  {
    BankCode: '7922',
    BranchCode: '53',
    BranchName: 'Maharagama'
  },
  {
    BankCode: '7922',
    BranchCode: '54',
    BranchName: 'Mahiyanganaya'
  },
  {
    BankCode: '7922',
    BranchCode: '55',
    BranchName: 'Mannar'
  },
  {
    BankCode: '7922',
    BranchCode: '56',
    BranchName: 'Matale'
  },
  {
    BankCode: '7922',
    BranchCode: '57',
    BranchName: 'Matara'
  },
  {
    BankCode: '7922',
    BranchCode: '58',
    BranchName: 'Mathugama'
  },
  {
    BankCode: '7922',
    BranchCode: '59',
    BranchName: 'Medawachchiya'
  },
  {
    BankCode: '7922',
    BranchCode: '60',
    BranchName: 'Melsiripura'
  },
  {
    BankCode: '7922',
    BranchCode: '61',
    BranchName: 'Metropolitan Office'
  },
  {
    BankCode: '7922',
    BranchCode: '62',
    BranchName: 'Minuwangoda'
  },
  {
    BankCode: '7922',
    BranchCode: '63',
    BranchName: 'Mirigama'
  },
  {
    BankCode: '7922',
    BranchCode: '64',
    BranchName: 'Monaragala'
  },
  {
    BankCode: '7922',
    BranchCode: '65',
    BranchName: 'Moratuwa'
  },
  {
    BankCode: '7922',
    BranchCode: '66',
    BranchName: 'Mutur � AlSafa'
  },
  {
    BankCode: '7922',
    BranchCode: '67',
    BranchName: 'Nawalapitiya'
  },
  {
    BankCode: '7922',
    BranchCode: '68',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7922',
    BranchCode: '69',
    BranchName: 'Neluwa'
  },
  {
    BankCode: '7922',
    BranchCode: '70',
    BranchName: 'Nittambuwa'
  },
  {
    BankCode: '7922',
    BranchCode: '71',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7922',
    BranchCode: '72',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7922',
    BranchCode: '73',
    BranchName: 'Panadura'
  },
  {
    BankCode: '7922',
    BranchCode: '74',
    BranchName: 'Pelmadulla'
  },
  {
    BankCode: '7922',
    BranchCode: '75',
    BranchName: 'Pettah'
  },
  {
    BankCode: '7922',
    BranchCode: '76',
    BranchName: 'Pilimathalawa'
  },
  {
    BankCode: '7922',
    BranchCode: '77',
    BranchName: 'Piliyandala'
  },
  {
    BankCode: '7922',
    BranchCode: '78',
    BranchName: 'Polonnaruwa'
  },
  {
    BankCode: '7922',
    BranchCode: '79',
    BranchName: 'Puttalam � AlSafa'
  },
  {
    BankCode: '7922',
    BranchCode: '80',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7922',
    BranchCode: '81',
    BranchName: 'Tangalle'
  },
  {
    BankCode: '7922',
    BranchCode: '82',
    BranchName: 'Thambuttegama'
  },
  {
    BankCode: '7922',
    BranchCode: '83',
    BranchName: 'Tissamaharama'
  },
  {
    BankCode: '7922',
    BranchCode: '84',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7922',
    BranchCode: '85',
    BranchName: 'Union Place � Alsafa'
  },
  {
    BankCode: '7922',
    BranchCode: '86',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7922',
    BranchCode: '87',
    BranchName: 'Ward Place'
  },
  {
    BankCode: '7922',
    BranchCode: '88',
    BranchName: 'Wariyapola'
  },
  {
    BankCode: '7922',
    BranchCode: '89',
    BranchName: 'Wattala'
  },
  {
    BankCode: '7922',
    BranchCode: '90',
    BranchName: 'Welimada'
  },
  {
    BankCode: '7922',
    BranchCode: '91',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7922',
    BranchCode: '92',
    BranchName: 'Wellawaya'
  },
  {
    BankCode: '7922',
    BranchCode: '93',
    BranchName: 'Wennappuwa'
  },
  {
    BankCode: '7968',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '7968',
    BranchCode: '2',
    BranchName: 'Kandy � 01'
  },
  {
    BankCode: '7968',
    BranchCode: '3',
    BranchName: 'Kurunegala � 01'
  },
  {
    BankCode: '7968',
    BranchCode: '4',
    BranchName: 'Kalmunai � 02'
  },
  {
    BankCode: '7968',
    BranchCode: '5',
    BranchName: 'Negombo'
  },
  {
    BankCode: '7968',
    BranchCode: '6',
    BranchName: 'Matara'
  },
  {
    BankCode: '7968',
    BranchCode: '7',
    BranchName: 'Nuwara Eliya'
  },
  {
    BankCode: '7968',
    BranchCode: '8',
    BranchName: 'Kuliyapitiya'
  },
  {
    BankCode: '7968',
    BranchCode: '9',
    BranchName: 'Anuradhapura'
  },
  {
    BankCode: '7968',
    BranchCode: '10',
    BranchName: 'Kandy � 02'
  },
  {
    BankCode: '7968',
    BranchCode: '11',
    BranchName: 'Ampara'
  },
  {
    BankCode: '7968',
    BranchCode: '12',
    BranchName: 'Peliyagoda'
  },
  {
    BankCode: '7968',
    BranchCode: '13',
    BranchName: 'Nugegoda'
  },
  {
    BankCode: '7968',
    BranchCode: '14',
    BranchName: 'Ratnapura'
  },
  {
    BankCode: '7968',
    BranchCode: '15',
    BranchName: 'Avissawella'
  },
  {
    BankCode: '7968',
    BranchCode: '16',
    BranchName: 'Kegalle'
  },
  {
    BankCode: '7968',
    BranchCode: '17',
    BranchName: 'Vavuniya'
  },
  {
    BankCode: '7968',
    BranchCode: '18',
    BranchName: 'Batticaloa'
  },
  {
    BankCode: '7968',
    BranchCode: '19',
    BranchName: 'Gampaha'
  },
  {
    BankCode: '7968',
    BranchCode: '20',
    BranchName: 'Kalutara'
  },
  {
    BankCode: '7968',
    BranchCode: '21',
    BranchName: 'Kalmunai � 01'
  },
  {
    BankCode: '7968',
    BranchCode: '22',
    BranchName: 'Wellawatte'
  },
  {
    BankCode: '7968',
    BranchCode: '23',
    BranchName: 'Kurunegala � 02'
  },
  {
    BankCode: '7968',
    BranchCode: '24',
    BranchName: 'Trincomalee'
  },
  {
    BankCode: '7968',
    BranchCode: '26',
    BranchName: 'Galle'
  },
  {
    BankCode: '7968',
    BranchCode: '27',
    BranchName: 'Panadura'
  },
  {
    BankCode: '8004',
    BranchCode: '1',
    BranchName: 'Head Office'
  },
  {
    BankCode: '8004',
    BranchCode: '998',
    BranchName: 'CBSL-VB'
  },
  {
    BankCode: '8004',
    BranchCode: '999',
    BranchName: 'Central Bank Of Sri Lanka'
  }
];
