import React, { Component } from 'react';
import { Row, Spin, Button, Table, Card, Col, Modal, DatePicker, Form } from 'antd';
import { connect } from 'react-redux';
import { fetchCompanySubscriptions, updateSubscription, fetchCompany, fetchCompanyKioskCount, updateKioskCount } from '../../actions/companies';
import { getSubscriptionsByCompanyId, getCompanyById } from '../../reducers';
import { showLoadingMessage } from '../../components/Message';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import moment from 'moment';
import withRouter from 'react-router/es/withRouter';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';

const { confirm } = Modal;

class SubscriptionList extends Component {
  state = {
    loading: false,
    loadingCount: false,
    textFilter: undefined,
    expiryDate: undefined,
    kioskCount: 0
  }

  componentDidMount = () => {
    const { company } = this.props;
    const { companyId } = this.props.match.params;
    this.setState({ kioskCount: company.businesses[0].kioskCount });
    this.setState({ loading: true });
    this.setState({ loadingCount: true });
    this.props.fetchCompanySubscriptions(companyId).then(() => {
      this.setState({ loading: false });
      this.setState({ loadingCount: false });
    });
  }

  getColumns = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return [
      {
        title: 'Subscription Code',
        dataIndex: 'subscriptionCode',
        key: 'subscriptionCode',
        render: text => <p>{text}</p>
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: text => <p>{text}</p>
      },
      {
        title: 'Expire Date',
        dataIndex: 'expiryDate',
        key: 'manufacture',
        render: text => <p>{text}</p>
      },
      {
        title: 'Special Price',
        dataIndex: 'specialPrice',
        key: 'model',
        render: price => <p>{price && format(price, currencyFormat(business))}</p>
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        align: 'right',
        render: (id) => (
          <Row>
            <Button onClick={() => this.showConfirm(id)} className='tableButton' type='primary' size='small'>Extend</Button>
          </Row>
        )
      }];
  }

  showConfirm = (id) => {
    const context = this;
    confirm({
      title: 'Do you want to extend subscription ?',
      content: <div><DatePicker onChange={this.onDateChange} placeholder='Select new expiry date' style={{ width: '100%' }} /></div>,
      onOk () {
        const { companyId } = context.props.match.params;
        const { expiryDate } = context.state;

        context.setState({ loading: true });
        context.props.updateSubscription(companyId, id, { expiryDate }).then(response => {
          if (response.subscription) {
            context.setState({ failedStatus: 'success', loading: false });
            showSuccessNotification('Updated', 'Subscription updated successfully.');
          } else {
            context.setState({ failedStatus: 'failed', loading: false });
            showErrorNotification('Failed to update', response.error);
          }
        });
      },
      onCancel () { }
    });
  }

  onUpdateKioskCount = () => {
    const { companyId } = this.props.match.params;
    const { company } = this.props;
    const { kioskCount } = this.state;
    const businessId = company && company.businesses[0] && company.businesses[0].id;

    if (kioskCount.length >= 0 && parseInt(kioskCount)) {
      this.setState({ loadingCount: true });
      this.props.updateKioskCount(companyId, businessId, { kioskCount: parseInt(kioskCount) }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loadingCount: false });
        } else {
          // console.log(response)
          if (response.company.code === 400) {
            showErrorNotification('Failed.', response.company.message);
            // this.setState({ kioskCount: response.count.data })
          } else {
            showSuccessNotification('Updated.', 'Kiosk Count updated successfully.');
          }
          // this.setState({  ingredients :[response.item.ingredients]});
          this.setState({ loadingCount: false });
        }
      });
    } else {
      this.setState({ countStatus: 'error' });
    }
  }

  onDateChange = (date, dateString) => this.setState({ expiryDate: moment(date).toDate() })

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Customers.');
  }

  getFilteredDevices = () => {
    const { subscriptions } = this.props;
    const { textFilter } = this.state;

    let filteredDevicesList = subscriptions;

    if (textFilter) {
      const filterText = textFilter.toLowerCase();
      filteredDevicesList = subscriptions && subscriptions.length > 0 && subscriptions.filter(subscription => this.matchStrings(subscription.deviceId, filterText));
    }

    return filteredDevicesList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  render () {
    const { loading, countStatus, kioskCount } = this.state;
    const { subscriptions } = this.props;

    // console.log(subscriptions);
    return (

      <Card style={{ margin: 5 }}>

        <Row gutter={10}>
          <Col lg={3}>
            <Form.Item required validateStatus={countStatus}>
              <div>
                KIOSK COUNT
              </div>
            </Form.Item>
          </Col>
          <Col lg={5}>
            <Form.Item required validateStatus={countStatus}>
              <div>
                <input
                  className='ant-input'
                  value={kioskCount}
                  type='number'
                  min='0'
                  onChange={(event) => this.setState({ kioskCount: event.target.value, countStatus: '' })}
                  onFocus={(event) => event.target.select()}
                />

              </div>
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item>
              <Button loading={this.state.loadingCount} block type='primary' onClick={() => this.onUpdateKioskCount()}>Save</Button>
            </Form.Item>

          </Col>
        </Row>

        <Row style={{ marginTop: 50 }}>
          <Spin spinning={loading && subscriptions && subscriptions.length === 0}>
            <Table columns={this.getColumns()} dataSource={this.getFilteredDevices()} />
          </Spin>
        </Row>
      </Card>

    );
  }
}

const mapStateToProps = (state, props) => {
  const { companyId } = props.match.params;
  return ({ subscriptions: getSubscriptionsByCompanyId(state, companyId), company: getCompanyById(state, companyId) });
};

export default withRouter(connect(mapStateToProps, { fetchCompanySubscriptions, updateSubscription, fetchCompany, fetchCompanyKioskCount, updateKioskCount })(SubscriptionList));
