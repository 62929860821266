import React, { Component } from 'react';
import { Row, Popconfirm, Spin, Button, Table, Input, Card, Col, Select, Modal } from 'antd';
import { connect } from 'react-redux';
import { fetchCompanyDeviceList, activeDeactiveDevice, fetchCompanySubscriptions, fetchSubscriptionDevice, updateDeviceSubscription, removeDeviceSubscription } from '../../actions/companies';
import { getDevicesByCompanyId, getSubscriptionsByCompanyId } from '../../reducers';
import { showLoadingMessage } from '../../components/Message';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import moment from 'moment';
import withRouter from 'react-router/es/withRouter';

class DeviceList extends Component {
  state = {
    loading: false,
    textFilter: undefined,
    devices: undefined,
    device: undefined,
    modalVisible: false,
    selectedSubscriptionId: undefined,
    selectedRawDeviceId: undefined
  }

  componentDidMount = () => {
    const { companyId } = this.props.match.params;
    this.setState({ loading: true });
    this.props.fetchCompanyDeviceList(companyId).then(() => {
      this.props.fetchCompanySubscriptions(companyId).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  getColumns = () => {
    return [
      {
        title: 'Device ID',
        dataIndex: 'deviceId',
        key: 'deviceId',
        render: text => <p>{text}</p>
      },
      {
        title: 'Android ID',
        dataIndex: 'androidId',
        key: 'androidId',
        render: text => <p>{text}</p>
      },
      {
        title: 'Manufacture',
        dataIndex: 'manufacture',
        key: 'manufacture',
        render: text => <p>{text}</p>
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        render: text => <p>{text}</p>
      },
      {
        title: 'Initial Activated Date',
        dataIndex: 'initiallyActivatedDate',
        key: 'initiallyActivatedDate',
        render: date => <p>{date && moment(date).format('DD-MM-YYYY')}</p>
      },
      {
        title: 'Last Activated Date',
        dataIndex: 'lastActivatedDate',
        key: 'lastActivatedDate',
        render: date => <p>{date && moment(date).format('DD-MM-YYYY')}</p>
      },
      {
        title: 'Subscription',
        dataIndex: 'subscriptions2',
        key: 'subscriptions2',
        render: (subscriptions, rawData) => this.renderSubscriptions(subscriptions, rawData)
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        render: flag => <p>{flag ? 'ACTIVE' : 'INACTIVE'}</p>
      },
      {
        title: 'Actions',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'right',
        render: (isActive, rowData) => (
          <Row>
            <Popconfirm
              title={isActive ? 'Deactivate ?' : 'Activate ?'}
              okText='YES'
              cancelText='NO'
              onConfirm={() => this.onActiveDeactiveDevice(rowData.deviceId, !isActive)}
            >
              <Button className='tableButton' type={isActive ? 'danger' : 'primary'} size='small'>{isActive ? 'Deactivate' : 'Activate'}</Button>
            </Popconfirm>
          </Row>
        )
      }];
  }

  handleChange = (value, rawData) => {
    const { companyId } = this.props.match.params;
    // console.log(`selected ${value}`, rawData);
    this.setState({ loading: true });
    this.props.fetchSubscriptionDevice(companyId, value).then(response => {
      if (response.device) {
        // console.log(response.device[0]);
        this.setState({ failedStatus: 'success', loading: false });
        if (response.device.length > 0) {
          this.setState({ modalVisible: true, device: response.device[0], selectedRawDeviceId: rawData.deviceId, selectedSubscriptionId: value });
        } else {
          this.addSubscriptionToDevice(value, rawData.deviceId);
        }
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
      }
    });
    // get the current subscription
    // show confirmation dialog with current device
  }

  addSubscriptionToDevice = (subscriptionId, deviceId) => {
    const { companyId } = this.props.match.params;
    // console.log(" data " + companyId, deviceId, subscriptionId);
    this.setState({ loading: true });
    this.props.updateDeviceSubscription(companyId, deviceId, subscriptionId).then(response => {
      if (response.device) {
        this.setState({ failedStatus: 'success', loading: false });
        showSuccessNotification('Updated', 'Device subscription updated successfully.');
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
        showErrorNotification('Failed to update', response.error);
      }
    });
  }

  handleModalOk = e => {
    const { companyId } = this.props.match.params;
    // console.log(" data " + companyId, this.state.selectedRawDeviceId, this.state.selectedSubscriptionId);
    this.setState({ loading: true, modalVisible: false });
    this.props.removeDeviceSubscription(companyId, this.state.device.deviceId).then(response => {
      if (response.device) {
        this.setState({ failedStatus: 'success', loading: false });
        this.addSubscriptionToDevice(this.state.selectedSubscriptionId, this.state.selectedRawDeviceId);
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
        showErrorNotification('Failed to update Device Subscription.', response.error);
      }
    });
  };

  handleModalCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  onActiveDeactiveDevice = (deviceId, status) => {
    const { companyId } = this.props.match.params;

    this.setState({ loading: true });
    this.props.activeDeactiveDevice(companyId, deviceId, { status }).then(response => {
      if (response.device) {
        this.setState({ failedStatus: 'success', loading: false });
        showSuccessNotification('Updated', 'Device status updated successfully.');
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
        showErrorNotification('Failed to update', response.error);
      }
    });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Customers.');
  }

  getFilteredDevices = () => {
    const { devices } = this.props;
    const { textFilter } = this.state;

    let filteredDevicesList = devices;

    if (textFilter) {
      const filterText = textFilter.toLowerCase();
      filteredDevicesList = devices && devices.length > 0 && devices.filter((device) => {
        const subscriptionObj = Object.values(device.subscriptions2)[0];
        if (this.matchStrings(device.deviceId, filterText)) {
          return true;
        } else {
          if (subscriptionObj) {
            // console.log(" subscription code 2 ",subscriptionObj.subscriptionCode)
            return (this.matchStrings(subscriptionObj.subscriptionCode, filterText));
          } else {
            return false;
          }
        }
      });
    }

    return filteredDevicesList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  renderModal = () => {
    const { device, modalVisible } = this.state;
    if (device === undefined) {
      return null;
    } else {
      return (
        <Modal
          title='Basic Modal'
          visible={modalVisible}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
        >
          <p>This subscription is already used by  Device {device.deviceId}, {device.manufacture} {device.model}. It will be deactivated if you continue. Do you want to continue?</p>
        </Modal>
      );
    }
  }

  renderSubscriptions = (currentSubscriptions, rawData) => {
    const { subscriptions } = this.props;
    var currentSubscription = {};
    currentSubscriptions && Object.keys(currentSubscriptions).map(function (key) {
      return currentSubscription = currentSubscriptions[key];
    });

    var currentId = currentSubscription.id;
    var allSubscriptions = Object.keys(subscriptions).map(key => subscriptions[key]).filter(subscription => subscription.type === 'KIOSK').map(function (subscription) {
      return (
        <option value={subscription.id}>{subscription.subscriptionCode}</option>
      );
    });

    return (
      <Select value={currentId} style={{ width: 80 }} onSelect={(value) => this.handleChange(value, rawData)}>
        {allSubscriptions}
      </Select>
    );
  }

  render () {
    const { loading, textFilter } = this.state;
    const { devices } = this.props;
    return (
      <Spin spinning={loading && devices && devices.length === 0}>
        {this.renderModal()}
        <Card style={{ margin: 5 }}>
          <Row gutter={10}>
            <Col lg={20}>
              <Input style={{ marginBottom: 10 }} value={textFilter} onChange={(event) => this.setState({ textFilter: event.target.value })} />
            </Col>
            <Col lg={4}>
              <Button type='primary' block icon='search'>Search</Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 50 }}>
            <Spin spinning={loading && devices && devices.length !== 0}>
              <Table columns={this.getColumns()} dataSource={this.getFilteredDevices()} />
            </Spin>
          </Row>
        </Card>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { companyId } = props.match.params;
  return ({ devices: getDevicesByCompanyId(state, companyId), subscriptions: getSubscriptionsByCompanyId(state, companyId) });
};

export default withRouter(connect(mapStateToProps, { fetchCompanyDeviceList, activeDeactiveDevice, fetchCompanySubscriptions, fetchSubscriptionDevice, updateDeviceSubscription, removeDeviceSubscription })(DeviceList));
