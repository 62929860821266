import React, { PureComponent } from 'react';
import { Col, Button, Row, Checkbox, TimePicker, DatePicker, Input, Popconfirm, Form } from 'antd';
import moment from 'moment';

class SpecialDateRow extends PureComponent {
  componentWillMount () {

  }

  render () {
    const {
      id, updating, dayOfWeek, description, businessOpened, delivering, businessOpenTime, businessCloseTime, deliveryOpenTime, deliveryCloseTime, onChangeValue, removeItem, date, dateStatus, businessOpeningStatus, businessClosingStatus,
      deliveryEndingStatus, deliveryStartingStatus, data
    } = this.props;
    var index = data.findIndex(x => x.id === id);
    return (
      <Row style={{ display: 'flex', flexDirection: 'column' }} className='special-opening-hour-item-row'>
        <Col span={24} style={{ marginTop: 7 }}>
          <Col span={4} style={{ marginTop: 7, paddingLeft: 5 }}>
            <p style={{ textAlign: 'left' }}><b>{(dayOfWeek !== undefined) ? dayOfWeek : ''}</b></p>
          </Col>
          <Col span={5} style={{ paddingRight: 5 }}>
            <Form.Item validateStatus={dateStatus}>
              <DatePicker style={{ width: '100%' }} value={date !== '' ? moment(date, 'YYYY/MM/DD') : ''} onChange={(date, dateString) => onChangeValue(index, 'date', dateString)} />
            </Form.Item>
          </Col>

          <Col span={15}>
            <Input value={description} style={{ width: '100%' }} placeholder='Type here' onChange={(e) => onChangeValue(index, 'description', e.target.value)} />
          </Col>

        </Col>

        <Col span={24}>
          <Col span={11}>
            {index === 0 && <p style={{ marginTop: 10, textAlign: 'center', marginBottom: 5, height: 30 }}><b>Restaurant Opening hours</b></p>}
            <Col style={{ textAlign: 'left', paddingLeft: 5 }} sx={8} sm={8} md={8} lg={6}>
              <Checkbox checked={businessOpened} onChange={(e) => onChangeValue(index, 'businessOpened', e.target.checked)}>Opened</Checkbox>
            </Col>
            <Col sx={8} sm={8} md={8} lg={9}>
              <Form.Item validateStatus={businessOpeningStatus}>
                <TimePicker style={{ marginBottom: 10 }} disabled={!businessOpened} value={businessOpenTime !== '' ? moment(businessOpenTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'businessOpenTime', timeString)} />
              </Form.Item>
            </Col>
            <Col sx={8} sm={8} md={8} lg={9}>
              <Form.Item validateStatus={businessClosingStatus}>
                <TimePicker style={{ marginBottom: 10 }} disabled={!businessOpened} value={businessCloseTime !== '' ? moment(businessCloseTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'businessCloseTime', timeString)} />
              </Form.Item>
            </Col>

          </Col>

          <Col span={11}>
            {index === 0 && <p style={{ marginTop: 10, height: 30, marginBottom: 5, textAlign: 'center' }}><b>Delivering hours</b></p>}
            <Col style={{ textAlign: 'right' }} sx={8} sm={8} md={8} lg={7}>
              <Checkbox checked={delivering} onChange={(e) => onChangeValue(index, 'delivering', e.target.checked)}>Delivering</Checkbox>
            </Col>
            <Col sx={8} sm={8} md={8} lg={9}>
              <Form.Item validateStatus={deliveryStartingStatus}>
                <TimePicker style={{ marginBottom: 10 }} disabled={!delivering} value={deliveryOpenTime !== '' ? moment(deliveryOpenTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'deliveryOpenTime', timeString)} />
              </Form.Item>
            </Col>
            <Col sx={8} sm={8} md={8} lg={8}>
              <Form.Item validateStatus={deliveryEndingStatus}>
                <TimePicker style={{ marginBottom: 10 }} disabled={!delivering} value={deliveryCloseTime !== '' ? moment(deliveryCloseTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'deliveryCloseTime', timeString)} />
              </Form.Item>
            </Col>

          </Col>

          <Col sm={2} md={2} lg={2}>
            {index === 0 && <p style={{ marginTop: 10, marginBottom: 5, height: 30 }}> </p>}
            <Col style={{ textAlign: 'left' }} sm={8} md={8} lg={7}>
              <Popconfirm title='Are you sure ?' okText='Delete' cancelText='No' onConfirm={() => removeItem(index, id)}>
                <Button style={{ width: 25, height: 25, paddingLeft: 5, marginTop: 4 }} type='danger' icon='delete'> </Button>
              </Popconfirm>
            </Col>
            <Col style={{ textAlign: 'center', paddingLeft: 1 }} sm={8} md={8} lg={8}>
              {updating && <Button style={{ width: 25, height: 25, paddingLeft: 5, marginTop: 4 }} type='primary' icon='undo' onClick={() => onChangeValue(index, 'reset', '')}> </Button>}
            </Col>
            <Col style={{ textAlign: 'right' }} sm={8} md={8} lg={8}>
              {updating && <Button className='primary-button' style={{ width: 25, height: 25, paddingLeft: 5, marginTop: 4 }} type='primary' icon='save' onClick={() => onChangeValue(index, 'save', '')}> </Button>}
            </Col>
          </Col>

        </Col>

      </Row>
    );
  }
}

export default SpecialDateRow;
