import React, { Component } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { Link } from 'react-router-dom';
class ItemOptionPopup extends Component {
  render () {
    const { optionsActive, onClose, itemId, onDelete } = this.props;
    return (
      <Modal
        visible={optionsActive}
        onOk={this.handleOk}
        className='item-option-container'
        onCancel={() => onClose('', false)}
        footer=''
        width={250}
        centered
        style={{ borderRadius: 10 }}
        closeIcon={null}
      >
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Link to={'/items/' + itemId}>
              <Button className='btn-orange primary-button' type='primary' style={{ width: '100%' }}>Edit</Button>
            </Link>
          </Col>
          <Col span={24}>
            <Button onClick={onDelete} className='button-orange' type='danger' style={{ width: '100%' }}>Delete</Button>
          </Col>
          <Col span={24}>
            <Button className='button-orange' type='default' style={{ width: '100%' }} onClick={() => onClose('', false)}>Cancel</Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}
export default ItemOptionPopup;
