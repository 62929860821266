import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import SideBar from '../../components/SideBar';
import CustomersList from './CustomersList';
import { getCompanyById, getAuthenticity } from '../../reducers';
import { fetchCompany } from '../../actions/companies';
// import { Link } from "react-router-dom";
// import { FiPlusCircle } from "react-icons/all";
class Customers extends Component {
  componentDidMount = () => {
    const { companyId } = this.props;
    this.setState({ loading: true });
    this.props.fetchCompany(companyId);
  }

  render () {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return (
      <div className='container'>
        {business && !business.active && <p style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, color: 'red' }}>This business is no longer active.</p>}
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Col lg={14} md={14} sm={24} xs={24}>
              <h3 style={{ fontWeight: 400, fontSize: 24 }}>Customers</h3>
            </Col>
            <Col lg={10} md={10} className='text-right'>
              {/* <Link>
                <Button type='primary' className='btn-orange' style={{ marginRight: 5 }}><FiPlusCircle style={{ marginRight: 5 }} /> Customer</Button>
              </Link> */}
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <CustomersList company={this.props.company} />
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return ({
    companyId: companyId,
    company: getCompanyById(state, companyId)
  });
};
export default connect(mapStateToProps, { fetchCompany })(Customers);
