import React, { PureComponent } from 'react';
import { Row, Card, Col } from 'antd';
import SpecialDatesCard from './SpecialDatesCard';
import OpeningHoursCard from './OpeningHoursCard';
import SettingDesctiption from './SettingsDescription';

class OpeningHoursContainer extends PureComponent {
  componentWillMount () {
    console.log(this.props.generalSettings);
  }

  render () {
    return (
      this.newMethod()
    );
  }

  newMethod () {
    return <div>
      <Row>
        <Col lg={20} style={{ marginBottom: 15, marginRight: 30 }}>
          <SettingDesctiption title='Opening Hours' description='' />
        </Col>

        <Col lg={24} style={{ marginBottom: 15 }}>
          <Card style={{ marginTop: 10 }} className='card-styles'>
            <OpeningHoursCard generalSettings={this.props.generalSettings} onUpdate={(data) => this.props.onUpdate(data)} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={20} style={{ marginBottom: 15, marginRight: 30 }}>
          <SettingDesctiption title='Special Dates' description='' />
        </Col>
        <Col lg={24} style={{ marginBottom: 15 }}>
          <Card style={{ marginTop: 10 }} className='card-styles'>
            <SpecialDatesCard generalSettings={this.props.generalSettings} onUpdate={(data) => this.props.onUpdate(data)} onDelete={(data) => this.props.onDelete(data)} onImportCalender={() => this.props.onImportCalender()} />
          </Card>
        </Col>
      </Row>
    </div>;
  }
}

export default OpeningHoursContainer;
