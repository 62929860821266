import React, { Component } from 'react';
import DeliveryAreaRow from './DeliveryAreaRow';
import { GeoMap } from '../../../components/common/GeoMap';
import LocationSearchInput from './LocationSearchInput';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

class DeliveryAreaCard extends Component {
  state = {
    loading: false,
    data: undefined,
    circles: [],
    center: { lat: 0, lng: 0 }
  }

  componentDidMount = () => {
    const { deliveryData } = this.props;
    this.setState({ loading: true });
    if (deliveryData != null && deliveryData.deliveryAreas != null) {
      this.setState({ data: deliveryData });
    } else {
      const obj = {
        deliveryAreas: []
      };
      this.setState({ data: obj });
    }

    this.updateCircle(deliveryData);
  }

  componentWillReceiveProps (nextProps) {
    const { deliveryData } = nextProps;
    if (deliveryData != null && deliveryData.deliveryAreas != null) {
      this.setState({ data: deliveryData });
    } else {
      const obj = {
        deliveryAreas: []
      };
      this.setState({ data: obj });
    }
    this.updateCircle(deliveryData);
  }

  updateCircle = (deliveryData) => {
    console.log(deliveryData);
    var circlesArray = [];
    deliveryData && deliveryData.deliveryAreas && deliveryData.deliveryAreas.length > 0 && deliveryData.deliveryAreas.forEach(area => {
      var circle = {
        center: area.geoLocation2,
        radius: area.radius
      };
      circlesArray.push(circle);
    });
    this.setState({ circles: circlesArray });
    this.setState({ center: deliveryData.center });
  }

  onSelectArea = (address) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const { data } = this.state;
        const { deliveryAreas } = data;
        var newArea = {
          id: 'new',
          address: address,
          radius: 0,
          geoLocation2: { lat: latLng.lat, lng: latLng.lng }
        };

        deliveryAreas.push(newArea);
        this.setState({ data: { ...this.state.data, deliveryAreas } });
        this.props.getData({ ...this.state.data, deliveryAreas: deliveryAreas, center: { lat: latLng.lat, lng: latLng.lng } });
      })
      .catch(error => console.error('Error', error));
  }

  // onClickAddNew = () => {
  //   var newRate = { id: "new", from: 0, to: 0, rate: 0 }
  //   const { data } = this.state
  //   const { deliveryRates } = data
  //   deliveryRates.push(newRate)
  //   this.setState({ data: { ...this.state.data, deliveryRates } })
  // }

  onDeleteItem = (index) => {
    const { deliveryAreas } = this.state.data;
    deliveryAreas.splice(index, 1);
    this.setState({ data: { ...this.state.data, deliveryAreas } });

    if (deliveryAreas.length > 0) {
      this.props.getData({ ...this.state.data, deliveryAreas: deliveryAreas, center: deliveryAreas[0].geoLocation2 });
    } else {
      this.props.getData({ ...this.state.data, deliveryAreas: deliveryAreas, center: { lat: 0, lng: 0 } });
    }
  }

  onLocateItem = (index) => {
    const { deliveryAreas } = this.state.data;

    if (deliveryAreas.length > 0) {
      this.props.getData({ ...this.state.data, deliveryAreas: deliveryAreas, center: deliveryAreas[index].geoLocation2 });
    } else {
      this.props.getData({ ...this.state.data, deliveryAreas: deliveryAreas, center: { lat: 0, lng: 0 } });
    }
  }

  onChangeInputValue = (index, feild, value) => {
    const { deliveryAreas } = this.state.data;
    var returnAreas = undefined;
    if (feild === 'RADIUS') {
      returnAreas = deliveryAreas.map((area, i) => (i === index ? Object.assign({}, area, { radius: parseInt(value) }) : area));
      this.setState({ data: { ...this.state.data, deliveryAreas: returnAreas } });
      this.props.getData({ ...this.state.data, deliveryAreas: returnAreas, center: returnAreas[index].geoLocation2 });
    }
  }

  render () {
    const { data } = this.state;
    return (
      <div style={{ margin: 5 }}>
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          <LocationSearchInput style={{ width: 570 }} onSelectArea={(address) => this.onSelectArea(address)} />
        </div>
        {data && data.deliveryAreas && data.deliveryAreas.length > 0 && data.deliveryAreas.map((area, index) => (
          <DeliveryAreaRow
            address={area.address}
            radius={area.radius}
            id={index}
            onDeleteItem={(index) => this.onDeleteItem(index)}
            onLocateItem={(index) => this.onLocateItem(index)}
            onChangeInputValue={(index, feild, value) => this.onChangeInputValue(index, feild, value)}
          />))}

        <div>
          {this.state.center && (this.state.center.lat !== undefined) && (this.state.center.lat !== 0) &&
            <GeoMap
              isMarkerShown
              zoom={12}
              location={this.state.center}
              height={350}
              circles={this.state.circles}
            />}
        </div>

      </div>
    );
  }
}

export default DeliveryAreaCard;
