import React, { Component } from 'react';
import { Menu, Modal } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuthenticity } from '../reducers';
import {
  AiOutlineCalculator,
  AiOutlineHome,
  AiOutlinePercentage,
  AiOutlinePieChart,
  AiOutlineWallet, GrAnalytics,
  FaRegListAlt,
  FiUsers, GiPayMoney, MdPayment, GiReceiveMoney,
  GrNotes,
  AiOutlineDashboard, AiOutlineAudit, FiSettings
} from 'react-icons/all';

const { confirm } = Modal;

class SideBar extends Component {
  rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

  state = {
    openKeys: ['sub1']
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };

  handleReportClick = () => {
    confirm({
      title: 'Opening Reports',
      content: 'Reports are currently hosted at console.incypos.com. You can use the same credentials to log in there. Please confirm that you want to leave ' + window.location.host + ' to console.incypos.com.',
      okText: 'Leave',
      cancelText: 'Stay',
      okButtonProps: { className: 'primary-button' },
      onOk: close => {
        window.open('https://console.incypos.com', '_newtab');
        close();
      }
    });
  }

  render() {
    return (
      <Menu
        mode='inline'
        className='side-bar-menu'
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
      >
        <Menu.Item className='top' key='userDashboard'>
          <Link to='/dashboard'><AiOutlineHome className='menu-icon' /> Home</Link>
        </Menu.Item>
        <Menu.Item className='top' key='orders'>
          <Link to='/orders'><AiOutlineWallet className='menu-icon' /> Orders</Link>
        </Menu.Item>
        <Menu.Item className='top' key='menu'>
          <Link to='/menuConfiguration'><FaRegListAlt className='menu-icon' /> Menu</Link>
        </Menu.Item>
        <Menu.Item className='top' key='allItems'>
          <Link to='/items'><AiOutlineWallet className='menu-icon' /> All Items</Link>
        </Menu.Item>
        <Menu.Item className='top' key='customers'>
          <Link to='/customers'><FiUsers className='menu-icon' /> Customers</Link>
        </Menu.Item>
        <Menu.Item className='top' key='inventory'>
          <Link to='/inventory'><FiUsers className='menu-icon' /> Inventory</Link>
        </Menu.Item>
        {/* <Menu.Item className='top' key='analytics' disabled>
          <Link to='/userDashboard'><AiOutlinePieChart className='menu-icon' /> Analytics</Link>
        </Menu.Item>
        <Menu.Item className='top' key='discounts' disabled>
          <Link to='/userDashboard'><AiOutlinePercentage className='menu-icon' /> Discounts</Link>
        </Menu.Item>
        <Menu.Item className='top' key='balances' disabled>
          <Link to='/userDashboard'><AiOutlineCalculator className='menu-icon' /> Balances</Link>
        </Menu.Item> 
        <Menu.Item className='top' key='payouts'>
          <Link to='/payouts'><GiPayMoney className='menu-icon' /> Payouts</Link>
        </Menu.Item>
        <Menu.Item className='top' key='transferNotes'>
          <Link to='/transferNotes'><GrNotes className='menu-icon' /> Transfer Notes</Link>
        </Menu.Item>*/}
        <Menu.Item className='top' key='billing'>
          <Link to='/subscriptions'><GiReceiveMoney className='menu-icon' /> Billing</Link>
        </Menu.Item>
        {/* <Menu.Item className='top' key='transactions' disabled>
          <Link to='/userDashboard'><MdPayment className='menu-icon' />Transactions</Link>
        </Menu.Item> */}
        <Menu.Item className='top' key='settings'>
          <Link to='/settings'><FiSettings className='menu-icon' />Settings</Link>
        </Menu.Item>
        {/* <Menu.Item className='top' key='businesses'>
          <Link to='/users'><FaUserShield className='menu-icon' /> Businesses</Link>
        </Menu.Item> */}
      <Menu.Item className='top' key='sales'>
          <Link to='/salesReports'><AiOutlineAudit className='menu-icon' />Reports</Link>
        </Menu.Item>
      </Menu>
    );
  }
}
const mapStateToProps = state => {
  return ({ authenticity: getAuthenticity(state) });
};
export default connect(mapStateToProps, {})(SideBar);
