import { getAll, modifyCustomer, removeCustomer, verificationEmail, verifyContactEmail, advancedSearch } from '../api/customers';

export const fetchCustomers = companyId => dispatch => {
  return getAll(companyId).then(response => {
    console.log('fetch', response);
    if (!response.error) dispatch({ type: 'FETCH_CUSTOMERS', customers: response.customers });
    return response;
  });
};

export const fetchCustomersFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_CUSTOMERS', customers: response.data.customerList, totalResults: response.data.totalResults, query });
    }
    return response;
  });
};

export const updateCustomer = (companyId, id, data) => dispatch => {
  return modifyCustomer(companyId, id, data).then(response => {
    //if (!response.error) dispatch({ type: 'FETCH_CUSTOMER', customer: response.customer });
    return response;
  });
};

export const deleteCustomer = (companyId, id) => dispatch => {
  return removeCustomer(companyId, id).then(response => {
    console.log(response);
    //if (!response.error) dispatch({ type: 'DELETE_CUSTOMER', customerId: id });
    return response;
  });
};

export const sendEmailVerification = (companyId, verificationRequest) => dispatch => {
  return verificationEmail(companyId, verificationRequest).then(response => {
    // if (!response.error) dispatch({ type: 'VERIFY_CONTACT_EMAIL', customer: response.customer });
    return response;
  });
};

export const verifyContactEmailHash = (type, hash) => dispatch => {
  return verifyContactEmail(type, hash).then(response => {
    console.log('RESPONSE', response);
    // if (!response.error) dispatch({ type: 'VERIFY_CONTACT_EMAIL', customer: response.customer });
    return response;
  });
};

export const updateActiveFilter = filter => dispatch => {
  dispatch({
    type: 'UPDATE_ACTIVE_FILTER_CUSTOMER',
    filter
  });
};

export const updateFilter = (type, filter) => dispatch => {
  dispatch({
    type: 'UPDATE_FILTER_CUSTOMER',
    filterType: type,
    filter
  });
};

