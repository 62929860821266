import Api from './consts';
import fetch from './commonFetch';

export const getAll = companyId => {
  const { url, method } = Api.customers.getAll;
  return fetch(url(companyId), { method }, 'customers');
};

export const advancedSearch = query => {
  const { url, method } = Api.customers.advancedSearch;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'data');
};

export const modifyCustomer = (companyId, id, data) => {
  const { url, method, headers } = Api.customers.update;
  const body = JSON.stringify(data);
  return fetch(url(companyId, id), { method, headers, body }, 'customer');
};

export const removeCustomer = (companyId, id) => {
  const { url, method, headers } = Api.customers.delete;
  return fetch(url(companyId, id), { method, headers }, 'customers');
};

export const verificationEmail = (companyId, verificationRequest) => {
  const { url, method, headers } = Api.companies.sendContactEmailVerification;
  const body = JSON.stringify(verificationRequest);
  return fetch(url(companyId), { method, headers, body }, 'customer');
};

export const verifyContactEmail = (type, hash) => {
  const { url, method, headers } = Api.companies.verifyContactEmail;
  return fetch(url(type, hash), { method, headers }, 'customer');
};
