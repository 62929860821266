import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

const options = {
  chart: {
    type: 'areaspline',
  },
  title: {
    text: null
  },
  yAxis: {
    gridLineWidth: 1,
  },
  xAxis: {
    type: 'datetime',
    gridLineColor: '#eee',
    gridLineWidth: 1,
    min: new Date(moment().subtract(1, 'months').startOf('day').toDate()).getTime(),
    max: new Date(moment().endOf('day').toDate()).getTime(),
    // min: new Date(moment().subtract(4, 'months').startOf('day').toDate()).getTime(),
    // max: new Date(moment().subtract(3, 'months').endOf('day').toDate()).getTime(),
    dateTimeLabelFormats: {
      day: '%e. %b',
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
    xDateFormat: '%A, %b %e'
  },
  colors: ['#8bc34a', '#2196f3', '#ab47bc', '#ec407a', '#7cdddd', '#a64eae', '#8399eb', '#ffaf00', '#2dcb75', '#959fb0', '#48bebc', '#fcb45c', '#4d5360', '#F57C00', '#E64A19', '#5D4037', '#90A4AE'],
  credits: {
    enabled: false
  },
}

const SalesAnalytics = (props) => {

  const { chartData } = props

  console.log("Chart Data", chartData)

  const hChart = useRef()
    
  useEffect(() => {
    if(chartData.length > 0) {
      while(hChart.current.chart.series.length>0){
        hChart.current.chart.series[0].remove(false) //false = don't redraw
      }
      chartData.forEach(d => {
        hChart.current.chart.addSeries({
          type: 'areaspline',
          name: d.status,
          data: d.countsArray,
        })
      })
      hChart.current.chart.redraw()
    }
  },[chartData])

  return (
    <div id='sales_analytics'>
      <HighchartsReact
        ref={hChart}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default SalesAnalytics