import Api from './consts';
import fetch from './commonFetch';

export const getList = (tableName, filter) => {
  // console.log("api generic", tableName, filter)
  const { url, method, headers } = Api.generic.list;
  const body = JSON.stringify(filter);
  return fetch(url(tableName), {body, method, headers }, 'result');
};

export const getProcedure = (procedureName, filter) => {
  // console.log("api generic procedure", procedureName, filter)
  const { url, method, headers } = Api.generic.procedure;
  const body = JSON.stringify(filter);
  return fetch(url(procedureName), {body, method, headers }, 'result');
};
