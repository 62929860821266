import React, { Component } from 'react';
import { Button, Card, Col, Row } from 'antd';
import SideBar from '../../../components/SideBar';
import SubscriptionList from './SubscriptionList';

class Subscriptions extends Component {
  render () {
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Row>
              <Card
                title={<h4 style={{ fontWeight: 300 }}>Subscriptions</h4>}
                style={{ margin: 5 }}
                extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
              >
                <SubscriptionList />
              </Card>
            </Row>

          </Col>
        </Row>
      </div>
    );
  }
}
export default Subscriptions;
