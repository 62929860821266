import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_NOTES': {
      const newState = {};
      action.transferNoteData && action.transferNoteData.forEach(transferNote => { newState[transferNote.id] = transferNote; });
      return newState;
    }
    case 'FETCH_TRANSFER_NOTE':
    case 'ADD_TRANSFER_NOTE': {
      const newState = { ...state };
      newState[action.transferNote.id] = action.transferNote;
      return newState;
    }
    case 'DELETE_TRANSFER_NOTE': {
      console.log(action);
      const newState = { ...state };
      delete newState[action.transferNoteData.id];
      return newState;
    }

    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_NOTES': {
      const newState = [];
      action.transferNoteData && action.transferNoteData.forEach(transferNote => {
        if (!newState.includes(transferNote.id)) {
          newState.push(transferNote.id);
        }
      });
      return newState;
    }
    case 'FETCH_TRANSFER_NOTE':
    case 'ADD_TRANSFER_NOTE': {
      const newState = [...state];
      if (!state.includes(action.transferNote.id)) {
        newState.push(action.transferNote.id);
      }
      return newState;
    }
    case 'DELETE_TRANSFER_NOTE': {
      const newState = [...state];
      return newState.filter(id => id !== action.transferNoteData.id);
    }
    default:
      return state;
  }
};

const transferNotes = combineReducers({
  byId,
  ids
});

export default transferNotes;

export const getTransferNotes = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};
export const getTransferNoteById = (state, id) => {
  return state && state.byId[id];
};
