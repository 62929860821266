import Api from './consts';
import fetch, { downalodFile, uploadFile } from './commonFetch';

// const { getAll, get, add, update, remove, tagSkills, bulkAdd } = Api.resources;

// export const getResources = () => {
//     const { url, method, headers } = getAll;

//     return fetch(url(), { method, headers })
//         .then(response => response.json())
//         .then(responseJson => {
//             return { resources: responseJson.body || [] };
//         }).catch(error => {
//             return { error };
//         });
// }

// export const getResource = id => {
//     const { url, method, headers } = get;

//     return fetch(url(id), { method, headers })
//         .then(response => response.json())
//         .then(responseJson => {
//             return { resource: responseJson.body };
//         }).catch(error => {
//             return { error };
//         });
// }

// export const addResource = (resource) => {
//     const { url, method, headers } = add;
//     const body = JSON.stringify(resource);

//     return fetch(url(), { method, headers, body })
//         .then(response => response.json()).then(responseJson => {
//             const { success, body } = responseJson;
//             if (success) {
//                 return { resource: body };
//             } else {
//                 throw body;
//             }
//         })
// }

// export const bulkResourcesAdd = (resources) => {
//     const { url, method, headers } = bulkAdd;
//     const body = JSON.stringify(resources);

//     return fetch(url(), { method, headers, body })
//     .then(response => response.json()).then(responseJson => {
//       const { success, body } = responseJson;
//       if (success) {
//           return { resources: body };
//       } else {
//           return body;
//       }
//   })
//   }

// export const updateResource = (resource, id) => {
//     const { url, method, headers } = update;
//     const body = JSON.stringify(resource);

//     return fetch(url(id), { method, headers, body })
//         .then(response => response.json()).then(responseJson => {
//             const { success, body } = responseJson;
//             if (success) {
//                 return { resource: body };
//             } else {
//                 throw body;
//             }
//         })
// }

// export const deleteResource = id => {
//     const { url, method, headers } = remove;

//     return fetch(url(id), { method, headers })
//     .then(response => response.json()).then(responseJson => {
//         const { success, body } = responseJson;
//         if (success) {
//             return { resource: body };
//         } else {
//             throw body;
//         }
//     })
// }

// export const tagResourceSkills = (resourceSkillTags) => {
//     const { url, method, headers } = tagSkills;
//     const body = JSON.stringify(resourceSkillTags);

//     return fetch(url(), { method, headers, body })
//         .then(response => response.json()).then(responseJson => {
//             const { success, body } = responseJson;
//             if (success) {
//                 return { resource: body };
//             } else {
//                 throw body;
//             }
//         })
// }

export const upload = (file, location, name) => {
  const { url, method } = Api.files.upload;

  const fd = createFormData(file, location, name);

  return uploadFile(url(), { method, body: fd }, 'responseData');
};

const createFormData = (file, location, name) => {
  const fd = new FormData();
  fd.append('file', file);
  fd.append('location', location);

  if (name) fd.append('name', name);

  return fd;
};

export const fileToJson = (file, location, name) => {
  const { url, method } = Api.files.filetojson;

  const fd = createFormData(file, location, name);

  return uploadFile(url(), { method, body: fd }, 'responseData');
};

export const jsonToFile = (json) => {
  const { url, method } = Api.files.jsontofile;
  const body = json;
  return downalodFile(url(), { body, method }, 'responseData')
};


export const jsonToFileInventory = (json) => {
  const { url, method } = Api.files.jsontofileInventory;
  const body = json;
  return downalodFile(url(), { body, method }, 'responseData')
};

