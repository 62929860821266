import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_PRINTER_LOCATIONS': {
      const newState = {};
      action.printerLocations && action.printerLocations.forEach(location => { newState[location.id] = location; });
      return newState;
    }
    case 'FETCH_PRINTER_LOCATION': {
      return { ...state, [action.printerLocation.id]: action.printerLocations };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_PRINTER_LOCATIONS': {
      const newState = [];
      action.printerLocations && action.printerLocations.forEach(location => {
        if (!newState.includes(location.id)) {
          newState.push(location.id);
        }
      });
      return newState;
    }
    case 'FETCH_PRINTER_LOCATION': {
      if (state.includes(action.printerLocation.id)) {
        return state;
      } else {
        return [...state, action.printerLocation.id];
      }
    }
    default:
      return state;
  }
};

const printerLocations = combineReducers({
  byId,
  ids
});

export default printerLocations;

export const getPrinterLocations = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};
