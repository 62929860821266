import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'antd';

class LocationSearchInput extends React.Component {
  constructor (props) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ fetchSuggestion: false });
    this.props.onSelectArea(address);
    this.setState({ address: '' });
  };

  render () {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}

      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input

              {...getInputProps({
                style: { width: '100%' },
                placeholder: 'Search Places ...',
                className: 'location-search-input'
              })}
            />

            <div style={{ zIndex: 10, position: 'absolute', left: 30, textAlign: 'left', width: 510 }} className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
