import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Checkbox, Col, Form, Input, Row, Spin, Tag } from 'antd';
import { verifyCustomUrlStatus, updateCustomUrl, fetchCompany, updateOnlineAvailability } from '../../../actions/companies';
import { getCompanyById, getAuthenticity } from '../../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/all';

class OnlineAvailability extends Component {
  state = {
    loading: false,
    allowOnlineOrders: undefined,
    customUrl: undefined,
    customUrlStatus: undefined,
    customUrlFeedback: false
  }

  componentDidMount () {
    this.setFormValues();
  }

  setFormValues = () => {
    const { company } = this.props;
    console.log('COmpanue ', company);
    const customUrlSet = (company.customUrl && company.customUrl.length > 0) ? company.customUrl : this.generateCustomUrl(company.businesses[0].name);
    this.setState({
      allowOnlineOrders: company && company.businesses && company.businesses[0].generalSettings && company.businesses[0].generalSettings.allowOnlineOrders,
      customUrl: customUrlSet,
      customUrlStatus: undefined,
      customUrlFeedback: false
    });
  }

  generateCustomUrl = text => {
    const convertedText = text.replace(/[^A-Z0-9]/ig, '').toLowerCase();
    return convertedText;
  }

  onChange = (field, value) => {
    switch (field) {
      case 'allowOnlineOrders':
        this.setState({ allowOnlineOrders: value });
        break;
      default:
        break;
    }
  }

  onChangeCustomUrl = value => {
    this.setState({ customUrl: this.generateCustomUrl(value), customUrlStatus: undefined });
  }

  onClickVerify = () => {
    const { verifyCustomUrlStatus, companyId } = this.props;
    const { customUrl } = this.state;
    this.setState({ customUrlStatus: 'validating', customUrlFeedback: true });
    const data = {
      customUrl: customUrl
    };

    verifyCustomUrlStatus(companyId, data).then(response => {
      const { customUrlStatus } = response;
      this.setState({ customUrlStatus: this.getValidationStatus(customUrlStatus, customUrl), customUrlFeedback: true });
    });
  }

  onClickSubmit = () => {
    const { companyId, updateCustomUrl, fetchCompany } = this.props;
    const { customUrl } = this.state;
    this.setState({ loading: true });
    const data = {
      customUrl: customUrl
    };
    updateCustomUrl(companyId, data).then(response => {
      const { customUrlResponse } = response;
      const { customUrl } = customUrlResponse;
      this.handleOnlineAvailabilityChange();
      if (customUrlResponse.status) {
        fetchCompany(companyId).then(res => {
          showSuccessNotification('Online Store Settings Updated', '');
          this.setState({ loading: false, customUrl: customUrl.url, customUrlStatus: undefined, customUrlFeedback: false });
        });
      } else {
        showErrorNotification(customUrlResponse.message, '');
        this.setState({ loading: false, customUrl: customUrl.url, customUrlStatus: undefined, customUrlFeedback: false });
      }
    });
  }

  handleOnlineAvailabilityChange = () => {
    const { company, companyId, updateOnlineAvailability } = this.props;
    const { allowOnlineOrders } = this.state;
    const businessId = company && company.businesses && company.businesses[0].id;
    const data = {
      allowOnlineOrders: allowOnlineOrders
    };

    updateOnlineAvailability(companyId, businessId, data).then(response => {
      console.log('Response ', response);
    });
  }

  onClickCancel = () => {
    this.setFormValues();
  }

  getValidationStatus = (status, value) => {
    return status === true ? 'success' : 'error';
  }

  renderHelpMessage = () => {
    const { customUrlStatus } = this.state;
    switch (customUrlStatus) {
      case 'validating':
        return 'Checking availability';
      case 'success':
        return 'Available.';
      case 'error':
        return 'Already taken';
      default:
        return '';
    }
  }

  renderCustomUrlStatus = () => {
    const { company } = this.props;
    const { customUrlStatus } = company;
    switch (customUrlStatus) {
      case 'PENDING':
        return <Tag style={{ backgroundColor: '#fa8c16', color: '#ffffff', marginLeft: 5 }}>{customUrlStatus}</Tag>;
      case 'ACTIVE':
        return <Tag style={{ backgroundColor: '#52c41a', color: '#ffffff', marginLeft: 5 }}>{customUrlStatus}</Tag>;
      case 'INACTIVE':
        return <Tag style={{ backgroundColor: '#f5222d', color: '#ffffff', marginLeft: 5 }}>{customUrlStatus}</Tag>;
      default:
        return '';
    }
  }

  validateCustomUrlPreConditions = (condition) => {
    const { company } = this.props;
    const { businesses, customer } = company;
    const { onlineStoreSettings, generalSettings, address, telephone, bankAccountDetail } = businesses[0];
    const { onlineStoreName, onlineStoreDescription, onlineStorePriceRange, thumbnailSrc } = onlineStoreSettings || {};
    const { businessHours } = generalSettings || {};

    switch (condition) {
      case 'Online Store Name':
        return onlineStoreName && onlineStoreName.length > 0 ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Restaurant Description':
        return onlineStoreDescription && onlineStoreDescription.length > 0 ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Price Range':
        return onlineStorePriceRange ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Business Hours':
        let isValidBusinessHours = false;
        businessHours && Object.values(businessHours).forEach(record => {
          if (record.businessOpened || record.delivering) isValidBusinessHours = true;
        });
        return (businessHours && Object.keys(businessHours).length > 0 && isValidBusinessHours) ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Business Thumbnail Image':
        return thumbnailSrc && thumbnailSrc.length > 0 ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Business Address':
        return address && address.length > 0 ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Business Contact Number':
        return telephone && telephone.length > 0 ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Bank Account Details':
        return bankAccountDetail ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      case 'Contact Email Verified':
        return customer && customer.contactEmail && customer.contactEmail.length > 0 && customer.contactEmailVerified ? <Col span={12}><span><AiFillCheckCircle className='condition-success' /> {condition}</span></Col>
          : <Col span={12}><span><AiFillCloseCircle className='condition-error' /> {condition}</span></Col>;
      default:
        return 'No condition';
    }
  }

  customUrlPreConditionsCovered = () => {
    const { company } = this.props;
    const { businesses, customer } = company;
    const { name, onlineStoreSettings, generalSettings, address, telephone, bankAccountDetail } = businesses[0];
    const { onlineStoreDescription, onlineStorePriceRange, thumbnailSrc } = onlineStoreSettings || {};
    const { businessHours } = generalSettings || {};
    console.log('CONDITIONS ', name && name.length > 0, onlineStoreDescription && onlineStoreDescription.length > 0,
      onlineStorePriceRange, businessHours && Object.keys(businessHours).length > 0,
      thumbnailSrc && thumbnailSrc.length > 0, address && address.length > 0,
      telephone && telephone.length > 0, bankAccountDetail, customer && customer.contactEmail && customer.contactEmail.length > 0,
      customer && customer.contactEmailVerified);
    return (name && name.length > 0) && (onlineStoreDescription && onlineStoreDescription.length > 0) &&
      (onlineStorePriceRange) && (businessHours && Object.keys(businessHours).length > 0) &&
      (thumbnailSrc && thumbnailSrc.length > 0) && (address && address.length > 0) &&
      (telephone && telephone.length > 0) && (bankAccountDetail) &&
      (customer && customer.contactEmail && customer.contactEmail.length > 0) && (customer && customer.contactEmailVerified);
  }

  render () {
    const { company } = this.props;
    const { loading, customUrl, customUrlStatus, allowOnlineOrders, customUrlFeedback } = this.state;
    return (
      <div>
        <Card style={{ marginTop: 10 }} className='card-styles'>
          <Spin spinning={loading}>
            <Form.Item colon={false}>
              <Checkbox checked={allowOnlineOrders} onChange={(e) => this.onChange('allowOnlineOrders', e.target.checked)}>Allow online orders</Checkbox>
            </Form.Item>

            <Form.Item className='custom-url-form-container' validateStatus={customUrlStatus} hasFeedback={customUrlFeedback} help={this.renderHelpMessage()}>
              <p style={{ marginBottom: 0 }}>Custom Url {this.renderCustomUrlStatus()}</p>
              <Row className='custom-url-condition-container'>
                <span style={{ color: '#949494', fontSize: 12 }}>* Following conditions are mandatory on requesting a custom url</span>
                {this.validateCustomUrlPreConditions('Online Store Name')}
                {this.validateCustomUrlPreConditions('Restaurant Description')}
                {this.validateCustomUrlPreConditions('Price Range')}
                {this.validateCustomUrlPreConditions('Business Hours')}
                {this.validateCustomUrlPreConditions('Business Thumbnail Image')}
                {this.validateCustomUrlPreConditions('Business Address')}
                {this.validateCustomUrlPreConditions('Business Contact Number')}
                {this.validateCustomUrlPreConditions('Bank Account Details')}
                {this.validateCustomUrlPreConditions('Contact Email Verified')}
              </Row>
              <Input disabled={company.customUrlStatus !== 'PENDING' && company.customUrl !== ''} value={customUrl} style={{ width: '100%' }} onChange={(e) => this.onChangeCustomUrl(e.target.value)} />
            </Form.Item>

            <Col lg={24}>
              {company.customUrl === customUrl || customUrlStatus === 'success' ? (<div>
                <h4>{'https://fooder.lk/' + customUrl}</h4>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    style={{ marginRight: 5 }} type='primary' onClick={() => this.onClickSubmit()}
                    className='isoDeleteBtn' disabled={!customUrl || customUrl.length === 0 || !this.customUrlPreConditionsCovered()}
                  >Update
                  </Button>
                  <Button
                    type='default' onClick={() => this.onClickCancel()}
                    className='isoDeleteBtn'
                  >Cancel
                  </Button>
                </div>
              </div>) : (
                                                                                     <div style={{ textAlign: 'right' }}>
                  <Button
                                                                                         style={{ marginRight: 5 }} type='primary' onClick={() => this.onClickVerify()}
                                                                                         className='primary-button' disabled={!customUrl || customUrl.length === 0 || !this.customUrlPreConditionsCovered()}
                                                                                       >Check Availability
                                                                                       </Button>
                </div>
              )}
            </Col>
          </Spin>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return ({ companyId, company: getCompanyById(state, companyId) });
};

export default connect(mapStateToProps, { verifyCustomUrlStatus, updateCustomUrl, fetchCompany, updateOnlineAvailability })(OnlineAvailability);
