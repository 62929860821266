import React, { Component } from 'react';
import { Avatar, Card, Checkbox, Col, Row, Spin, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { fetchCategory, removeCategory } from '../../actions/categories';
import placeholderSrc from '../../image/empty-image.png';
import { FiEdit, FiInfo } from 'react-icons/all';
import { getCategory } from '../../reducers';
import EditCategoryPopup from './EditCategoryPopup';

const { confirm } = Modal;

const defaultCategory = {
  name: '',
  code: '',
  description: '',
  imageSrc: undefined,
  isRawMaterial: false,
  displayInOnlineMenu2: false,
  hideFromUsers: false
};

class CategoryDetailView extends Component {
  state = {
    ...defaultCategory,
    loading: false,
    editModelVisible: false
  }

  componentDidMount() {
    const { categoryId, companyId, category } = this.props;
    if (categoryId) {
      this.props.fetchCategory(companyId, categoryId).then(response => {
      });
    }
    category && this.setCategoryDetails(category);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { category } = nextProps;
    this.setCategoryDetails(category);
    console.log('OYEEx ', nextProps);
  }

  onClickEditCategory = action => {
    this.setState({ editModelVisible: action });
  }


  showConfirmDelete = () => {
    const { onDeleteCategory, categoryId } = this.props;
    const THIS = this;
    confirm({
      title: 'Category Delete',
      content: 'Are you sure you want to delete this category?',
      okText: 'Yes',
      cancelText: 'No',
      icon: <FiInfo size={25} style={{ color: '#ff4d25', float: 'left', marginRight: 14 }} />,
      onOk() {
        onDeleteCategory(categoryId);
      },
      onCancel() {
      }
    });
  }

  setCategoryDetails = category => {
    category && this.setState({
      name: category.name,
      description: category.description,
      imageSrc: category.imageSrc,
      displayInOnlineMenu2: category.displayInOnlineMenu2,
      isRawMaterial: category.hideFromUsers
    });
  }

  render() {
    const { loading, imageSrc, isRawMaterial, editModelVisible, displayInOnlineMenu2 } = this.state;
    const { categoryId, companyId, category } = this.props;
    const imagePath = imageSrc || placeholderSrc;

    return (
      <Card className='category-detail-container'>
        <EditCategoryPopup modelVisible={editModelVisible}
          categoryId={categoryId}
          companyId={companyId}
          onModelActions={this.onClickEditCategory}
          onRefreshMenuList={this.props.onRefreshMenuList}
        />

        <Spin spinning={loading}>
          <Row>
            <Col lg={4} md={4}>
              <Avatar src={imagePath} size={120} />
            </Col>
            <Col lg={16} md={16} className='details'>
              <h3 className='primary-text'>{category && category.name}</h3>
              <p>{category && category.code}</p>
              <Col>
                <Checkbox className='catagory-edit-form-popup-checkbox' checked={isRawMaterial} disabled>Raw Material</Checkbox>
              </Col>
              {/* <Col>
                <Checkbox className='catagory-edit-form-popup-checkbox' checked={displayInOnlineMenu2} disabled>Visible Online</Checkbox>
              </Col> */}
            </Col>
            <Col lg={4} md={4}>
              <Button className='tableButton' style={{ position: 'absolute', marginRight: 30, right: 0 }} size='small' type={'danger'} icon='delete' onClick={() => this.showConfirmDelete()} />
              <FiEdit className='edit-icon incy-button' onClick={() => this.onClickEditCategory(true)} />
            </Col>
          </Row>
        </Spin>
      </Card>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { categoryId } = props;

  return { category: getCategory(state, categoryId) };
};
export default connect(mapStateToProps, { fetchCategory })(CategoryDetailView);
