import moment from 'moment';

export const query = (tableName, filter) => {
  let queryString = undefined;
  const where = [];

  // common wheres 
  filter.id && where.push({
    columnName: "id",
    whereOperator: "=",
    value: filter.id,
    logicalOperator: "AND"
  });

  filter.businessIds && filter.businessIds.length > 0 && where.push({
    columnName: "business_id",
    whereOperator: "IN",
    value: [filter.businessIds],
    logicalOperator: "AND"
  });

  filter.branchIds && filter.branchIds.length > 0 && where.push({
    columnName: "branch_id",
    whereOperator: "IN",
    value: [filter.branchIds],
    logicalOperator: "AND"
  });

  filter.status && filter.status.length > 0 && where.push({
    columnName: "status",
    whereOperator: "IN",
    value: [filter.status],
    logicalOperator: "AND"
  });

  filter.fromDate && where.push({
    columnName: "time",
    whereOperator: ">=",
    value: moment(filter.fromDate).format("YYYY-MM-DD"),
    logicalOperator: "AND"
  });

  filter.toDate && where.push({
    columnName: "time",
    whereOperator: "<", // do not add = just pass the selected date + 1
    value: moment(filter.toDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD"),
    logicalOperator: "AND"
  });

  if (tableName === "order") {
    filter.method && where.push({ // these  wheres are relevent for order only
      columnName: "method",
      whereOperator: "=",
      value: filter.method,
      logicalOperator: "AND"
    });

    filter.text && where.push({
      columnName: "number",
      whereOperator: "like",
      value: "%" + filter.text + "%",
      logicalOperator: "AND"
    });

    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, time, number, customer_id, customer_name, status, tax_percentage, service_charge_percentage, subtotal, tax, service_charge, delivery_charge, discount, total, balance_to_settle, cash_on_delivery, user_id, type, parent_id, split_id, delivery_person_id, device_id, modified_time, data, delivery_address_id, user_id",
      orderBy: "time desc",
      where: where,
      groupBy: [
        {
          lableName: "business_id",
          columnName: "business_id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "",
          groupBy: true
        },
        {
          lableName: "branchId",
          columnName: "branch_id",
          orderBy: true,
          orderByDesc: false,
          aggregateFunction: "",
          groupBy: true
        },
        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        },
        {
          lableName: "subTotal",
          columnName: "subtotal",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "Tax",
          columnName: "tax",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "ServiceCharge",
          columnName: "service_charge",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "DeliverCharge",
          columnName: "delivery_charge",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "Discount",
          columnName: "discount",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "Total",
          columnName: "total",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "BalanceToSettle",
          columnName: "balance_to_settle",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "total",
          columnName: "total",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        }
      ]
    }
  }
  else if (tableName === "view_user_orders") {
    filter.method && where.push({ // these  wheres are relevent for order only
      columnName: "method",
      whereOperator: "=",
      value: filter.method,
      logicalOperator: "AND"
    });

    filter.text && where.push({
      columnName: "number",
      whereOperator: "like",
      value: "%" + filter.text + "%",
      logicalOperator: "AND"
    });

    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, time, number, customer_id, customer_name, status, tax_percentage, service_charge_percentage, subtotal, tax, service_charge, delivery_charge, discount, total, balance_to_settle, cash_on_delivery, user_id, type, parent_id, split_id, delivery_person_id, device_id, modified_time, data, delivery_address_id, user_id, user_name",
      orderBy: "time desc",
      where: where,
      groupBy: [
        {
          lableName: "business_id",
          columnName: "business_id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "",
          groupBy: true
        },
        {
          lableName: "branchId",
          columnName: "branch_id",
          orderBy: true,
          orderByDesc: false,
          aggregateFunction: "",
          groupBy: true
        },
        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        },
        {
          lableName: "subTotal",
          columnName: "subtotal",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "Tax",
          columnName: "tax",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "ServiceCharge",
          columnName: "service_charge",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "DeliverCharge",
          columnName: "delivery_charge",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "Discount",
          columnName: "discount",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "Total",
          columnName: "total",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "BalanceToSettle",
          columnName: "balance_to_settle",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        },
        {
          lableName: "total",
          columnName: "total",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "SUM",
          groupBy: false
        }
      ]
    }
  }
  else if (tableName === "order_item") {
    filter.orderId && where.push({
      columnName: "order_id",
      whereOperator: "=",
      value: filter.orderId,
      logicalOperator: "AND"
    });

    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, order_id, name, code, price, quantity, discount, status, item_id, time, user_id, modified_time, parent_id, printer_location_id",
      orderBy: "time desc",
      where: where,
      groupBy: [

        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        }
      ]
    }
  } else if (tableName === "view_order_receipt_transaction") {
    filter.orderId && where.push({
      columnName: "order_id",
      whereOperator: "=",
      value: filter.orderId,
      logicalOperator: "AND"
    });
    

    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, business_id, branch_id, paid, receipt_type, order_type,receipt_id, order_id, amount, order_number, receipt_number, receipt_status,time , data",
      orderBy: "time desc",
      where: where,
      groupBy: [

        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        }
      ]
    }
  }
  else if (tableName === "view_customer_order_summary") {
    filter.text && where.push({
      columnName: "name",
      whereOperator: "like",
      value: "%" + filter.text + "%",
      logicalOperator: "AND"
    });

    filter.text && where.push({
      columnName: "email",
      whereOperator: "like",
      value: "%" + filter.text + "%",
      logicalOperator: "OR"
    });

    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, business_id, name, email, telephone, status, order_count, order_total, balance_to_settle",
      orderBy: "name",
      where: where,
      groupBy: [

        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        }
      ]
    }
  }
  else if (tableName === "person_address") {
    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, business_id, address, city, country, default_address, created_time ",
      orderBy: "created_time",
      where: where,
      groupBy: [

        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        }
      ]
    }
  }
  else if (tableName === "person_feedback") {
    filter.person_id && where.push({
      columnName: "person_id",
      whereOperator: "=",
      value:   filter.person_id,
      logicalOperator: "AND"
    });

    filter.doc_id && where.push({
      columnName: "doc_id",
      whereOperator: "=",
      value: filter.doc_id,
      logicalOperator: "AND"
    });

    filter.feedback_type && where.push({
      columnName: "feedback_type",
      whereOperator: "=",
      value: filter.feedback_type,
      logicalOperator: "AND"
    });
    
    queryString = {
      page: filter.page,
      pageSize: filter.pageSize,
      jsonColumns: [],
      columns: "id, type, comment, status, feedback_type, person_id, doc_id, created_time ",
      orderBy: "created_time",
      where: where,
      groupBy: [

        {
          lableName: "count",
          columnName: "id",
          orderBy: false,
          orderByDesc: false,
          aggregateFunction: "COUNT",
          groupBy: false
        }
      ]
    }
  }
  return queryString;
};
