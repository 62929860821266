import React, { Component } from 'react';
import { Col, Form, Input, Row, Spin, DatePicker, InputNumber, Select, Button } from 'antd';
import moment from 'moment';

const { Option } = Select;

class ChequePaymentForm extends Component {
	amountValidator = amount => {
	  const { invoiceAmount = 0 } = this.props;

	  if (!amount || amount < 0) return 'Invalid receipt amount. Amount should be greater than zero.';
	  else if (amount > invoiceAmount) return "Receipt amount can't exceed invoice amount.";
	}

	textValidator = text => {
	  if (!text || text.length === 0) return false;

	  return true;
	}

	dateValidator = date => {
	  console.log('IS AFTER ', moment(date).isAfter(moment()));
	  return moment(date).isAfter(moment());
	}

	render () {
	  const { submitting, handleOnChange, chequePayment = {}, handleConfirmPayment, banks = [{ id: 1, bankname: 'RBC Royal Bank' }, { id: 1, bankname: 'Republic Bank' }] } = this.props;
	  const invalidAmount = this.amountValidator(chequePayment.amount);
	  const validChequeNumber = this.textValidator(chequePayment.chequeNumber);
	  const validBank = this.textValidator(chequePayment.chequeBankName);
	  const validChequeDate = this.dateValidator(chequePayment.chequeDate);
	  const confirmDisable = invalidAmount || !validChequeNumber || !validBank || !validChequeDate;

	  return (
  <Row gutter={10}>
  <Spin spinning={submitting}>
  <Form>
      <Col span={24} lg={24} md={24} sm={24} xs={24}>
  <div>Receipt Amount</div>
  <Form.Item
          required validateStatus={invalidAmount ? 'error' : ''}
	              help={invalidAmount}
        >
	              <div>
      <InputNumber
    style={{ width: '100%' }} value={chequePayment.amount} autoFocus
    onChange={(value) => handleOnChange('chequePayment', 'amount', value)}
  />
    </div>
        </Form.Item>
           </Col>
	          <Col span={24} lg={24} md={24} sm={24} xs={24}>
      <div>Remarks</div>
	            <Form.Item>
	              <div>
	                <Input
    value={chequePayment.remarks}
	                  onChange={(event) => handleOnChange('chequePayment', 'remarks', event.target.value)}
  />
  </div>
  </Form.Item>
    </Col>
      <Col span={24} lg={24} md={24} sm={24} xs={24}>
  <div>Bank</div>
  <Form.Item
          required validateStatus={!validBank ? 'error' : ''}
          help={!validBank && 'Invalid bank.'}
        >
          <Select value={chequePayment.chequeBankName} showSearch placeholder='Select Bank' dropdownMatchSelectWidth={false} onChange={(value) => handleOnChange('chequePayment', 'chequeBankName', value)} style={{ width: '100%', maxWidth: '-webkit-fill-available' }}>
  {banks.map(bank => {
	                  return <Option key={bank._id} value={bank.bankname}>{bank.bankname}</Option>;
	                })}
               </Select>
        </Form.Item>
	          </Col>
	          <Col span={24} lg={24} md={24} sm={24} xs={24}>
      <div>Cheque Number</div>
	            <Form.Item
    required validateStatus={!validChequeNumber ? 'error' : ''}
    help={!validChequeNumber && 'Invalid cheque number'}
  >
    <div>
    <Input
	                  value={chequePayment.chequeNumber}
  onChange={(event) => handleOnChange('chequePayment', 'chequeNumber', event.target.value)}
	                />
  </div>
  </Form.Item>
    </Col>
      <Col span={24} lg={24} md={24} sm={24} xs={24}>
	            <div>Cheque Date</div>
  <Form.Item
  required validateStatus={!validChequeDate ? 'error' : ''}
  help={!validChequeDate && 'Invalid cheque date'}
	            >
  <div>
  <DatePicker
	                  allowClear={false}
  style={{ width: '100%' }}
  value={chequePayment.chequeDate}
  onChange={(value) => handleOnChange('chequePayment', 'chequeDate', value)}
	                />
	              </div>
	            </Form.Item>
	          </Col>
      <Col lg={24} md={24} sm={24} xs={24}>
  <Button disabled={confirmDisable} type='primary' size='small' style={{ width: '100%', marginBottom: 10 }} onClick={() => handleConfirmPayment('chequePayment')}>Submit Payment</Button>
	          </Col>
      {/* <Col style={{ float: 'right', width: 'fit-content' }} lg={12} md={24} sm={24} xs={24}>
							<h1><span style={{ margin: 5 }}>Amount:</span> {format(chequePayment.amount, currencyFormat)}</h1>
						</Col> */}
	        </Form>
	      </Spin>
	    </Row>
	  );
	}
}

export default ChequePaymentForm;
