import React from 'react';
import { Col, Button, Row, Input, Popconfirm } from 'antd';
import { FiMapPin } from 'react-icons/all';
const DeliveryAreaRow = ({
  click, address, radius, id, onDeleteItem, onChangeInputValue, onLocateItem
}) => (
  <Row onClick={click} style={{ marginTop: 15, marginBottom: 15, color: '#555652' }} gutter={5}>
    <Col md={16} sm={16} xs={16} lg={16}>
      <br />
      <br />
      <span style={{ fontSize: 'medium' }}>{address || ''}</span>
    </Col>
    <Col md={5} sm={5} xs={5} lg={5}>
      <p style={{ fontSize: 15, fontWeight: 600, color: '#555652' }}>Radius(km)</p>
      <Input type='number' min='0' value={radius} style={{ width: '100%', padding: 4 }} onChange={(e) => onChangeInputValue(id, 'RADIUS', e.target.value)} />
    </Col>
    <Col style={{ textAlign: 'right' }} md={3} sm={3} xs={3} lg={3}>
      <br />
      <br />
      <Popconfirm title='Are you sure ?' okText='Delete' cancelText='No' onConfirm={() => onDeleteItem(id)}>
        <Button style={{ width: 25, height: 25, paddingLeft: 5, marginRight: 2 }} type='danger' icon='delete'> </Button>
      </Popconfirm>
      <Button className='primary-button' style={{ width: 25, height: 25, paddingLeft: 5 }} type='primary' onClick={() => onLocateItem(id)}> <FiMapPin /> </Button>
    </Col>
  </Row>

);

export default DeliveryAreaRow;
