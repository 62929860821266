import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, Divider, Spin } from 'antd';
import SideBar from '../../../components/SideBar';
import { fetchCompany, verifyClient } from '../../../actions/companies';
import { getAuthenticity, getCompanyById } from '../../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import { showLoadingMessage } from '../../../helpers/Message';

class Commissions extends Component {
  state = {
    loading: false,
    textFilter: '',
    code: '',
    codeStatus: '',
    loadingCode: ''
  }

  componentDidMount () {
    const { fetchCompany, authenticity } = this.props;
    console.log(':AUTHENTICITY', authenticity);
    const { user: { userId } } = authenticity;

    if (this.props.company) {
      this.showLoadingMessage();
      fetchCompany(userId).then(res => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ loading: true });
      fetchCompany(userId).then(res => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount () {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing commission details.');
  }

  onUpdateVerificationCode = () => {
    const { user: { userId: companyId, username } } = this.props.authenticity;

    const { code } = this.state;
    if (code.replace(' ', '').length >= 0) {
      this.setState({ loadingCode: true });
      this.props.verifyClient(companyId, { code: code, username: username }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loadingCode: false });
        } else {
          if (response.company.code === 400) {
            showErrorNotification('Failed.', response.company.message);
          } else {
            showSuccessNotification('Updated.', 'Account verified successfully.');
          }
          this.setState({ loadingCode: false });
        }
      });
    } else {
      this.setState({ codeStatus: 'error' });
    }
  }

  render () {
    const { company } = this.props;
    const { loading } = this.state;
    const agent = company && company.agent;
    const business = company && company.businesses[0] && company.businesses[0];
    const generalSettings = business && business.generalSettings;
    const commissionPercentageDiscount = generalSettings && generalSettings.commissionPercentageDiscount;
    const agentSettings = agent && agent.settings;
    const commissionPercentage = agentSettings && agentSettings.commissionPercentage;
    console.log(commissionPercentageDiscount);
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Spin spinning={loading}>
              <Row>
                <Card
                  title={<h4 style={{ fontWeight: 300 }}>Commission Details</h4>}
                  style={{ margin: 5 }}
                  extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
                >
                  <Row gutter={10}>
                    <Col lg={15} md={15} sm={24} xs={24}>
                      <table>
                        <tbody>
                          {commissionPercentageDiscount &&
                            commissionPercentageDiscount !== 0 &&
                            commissionPercentage &&
                            commissionPercentage !== 0 &&
                              <tr style={{ height: 24 }}>
                                {/* <td style={{ width: 25 }}><b>%</b></td> */}
                                <td>Commission Percentage</td>
                                <td style={{ width: 25, textAlign: 'center' }}>:</td>

                                <td><span style={{ fontSize: 20 }}>{(commissionPercentage - commissionPercentageDiscount) + '% '}</span><span style={{ color: 'red', textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>{commissionPercentage + '%'}</span></td>
                              </tr>}
                          {(!commissionPercentageDiscount || commissionPercentageDiscount === 0) &&
                            (commissionPercentage) &&
                              <tr style={{ height: 24 }}>
                                <td style={{ width: 25 }}><b>%</b></td>
                                <td>Commission</td>
                                <td style={{ width: 25, textAlign: 'center' }}>:</td>

                                <td><span>{commissionPercentage + '%'}</span></td>
                              </tr>}

                        </tbody>
                      </table>
                    </Col>
                    <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                      <table>
                        <tbody>
                          {/* {business && business.registeredDate && <tr>
                            <td style={{ width: 25 }}><Icon type="calendar" /></td>
                            <td>Registered Date</td>
                            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                            <td>{moment(business.registeredDate).format('DD-MM-YYYY')}</td>
                          </tr>}
                          {business && business.activationCount && <tr>
                            <td style={{ width: 25 }}><Icon type="book" /></td>
                            <td><p>Device Count</p></td>
                            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                            <td><p>{business.activationCount}</p></td>
                          </tr>}
                          <tr>
                            <td style={{ width: 25 }}><Icon type="calculator" /></td>
                            <td>Invoice Total</td>
                            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                            <td>{format(0, currencyFormat)}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 25 }}><Icon type="disconnect" /></td>
                            <td>Wave Off Total</td>
                            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                            <td>{format(0, currencyFormat)}</td>
                          </tr>
                          <tr>
                            <td style={{ width: 25 }}><Icon type="minus-square" /></td>
                            <td>Total Outstanding</td>
                            <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
                            <td>{format(0, currencyFormat)}</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const authenticity = getAuthenticity(state);
  const { user: { userId: companyId } } = authenticity;

  return ({
    authenticity: authenticity,
    companyId: companyId,
    company: getCompanyById(state, companyId)
  });
};

export default connect(mapStateToProps, { fetchCompany, verifyClient })(Commissions);
