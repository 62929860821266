import React, { Component } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, TreeSelect, Icon, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import { FiPlusCircle, FiInfo } from 'react-icons/all';
import ModifierItem from './ModifierItem';
import { getCategoryTree, getCategories, getCompanyById, getPriceLists, getItem, getPrinterLocations, getAuthenticity } from '../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import PriceListModalForm from '../MenuPriceListComponents/PriceListFormContainer';
import PrinterLocationModalForm from '../PrinterLocationForm/PriceListFormContainer';
import IngredientModalForm from '../IngredientModalForm/IngredientModalForm';
import ModifireGroupModalForm from '../IngredientModalForm/ModifireGroupModalForm';
import { fetchItem, updateItem, addItem, addItemGroupModifire, addItemPriceList, addItemPrinterLocation, addItemIngredient, addVariantItem } from '../../actions/items';
import { fetchPriceLists, addPriceList } from '../../actions/priceLists';
import { addPrinterLocation, fetchPrinterLocations } from '../../actions/printerLocations';
import { fetchCompany } from '../../actions/companies';
import { fetchCategories } from '../../actions/categories';
import { format } from 'currency-formatter';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router';
import ItemList from './ItemList';
import { currencyFormat, currencyFormatWithoutSymbol } from '../../helpers/common';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const defaultItem = {
  name: '',
  code: '',
  barcode: '',
  servings: '1',
  timeToServe: '',
  displayInOnlineMenu2: false,
  onlineStockAvailable: true,
  isMeal: false,
  isIngredient: false,
  isModifier: false,
  itemPrices: [],
  printerLocations: [],
  imageSrc: undefined,
  recipe: '',
  tips: '',
  ingredients: [],
  availableQty2: 0,
  inventoryItems: [],
  description: '',
  dineIn: false,
  takeAway: false
};

class ItemForm extends Component {
  state = {
    variantCount: 1,
    loading: false,
    selectedPriceList: '',
    item: {
      ...defaultItem
    },
    nameStatus: '',
    codeStatus: '',
    descriptionStatus: '',
    parentCategories: undefined,
    defaultCategories: undefined,
    visibleItemList: false,

    uom: '',
    quantity: '',
    groupName: '',

    groupNameStatus: '',
    priceListName: '',
    priceListNameStatus: '',
    printerLocationName: '',
    printerLocationNameStatus: '',
    visiblePriceListModal: false,
    visiblePrinterLocationModal: false,
    visibleAddIngredientModal: false,

    itemPrices: [],
    selectedPrinterLocations: [],
    selectedIngredientItem: undefined,
    itemListType: 'MODIFIER',
    modifiersList: [],
    groupModifireIngredients: [],
    visibleModifireGroupModal: false,
    selectedIngredientIndex: -1,

    variantOptionTypes: [
      {
        id: '65465465',
        name: 'Size',
        options: ['Large', 'Medium', 'Small']
      },
      {
        id: 'ad65465465',
        name: 'Color',
        options: ['Black', 'White', 'Blue']
      }
    ],
    updated: false
  }

  componentDidMount = () => {
    const { companyId, itemId, company, categoryTree, printerLocations, priceLists } = this.props;

    if (!company || !company.businesses) {
      this.props.fetchCompany(companyId);
    }
    if (!categoryTree || priceLists.length === 0) {
      this.props.fetchCategories(companyId);
    }

    if (!priceLists || priceLists.length === 0) {
      this.props.fetchPriceLists(companyId);
    }

    if (!printerLocations || printerLocations.length === 0) {
      this.props.fetchPrinterLocations(companyId);
    }

    // this.setState({ loading: true })
    this.props.fetchItem(companyId, itemId).then(response => {
      this.setItemDetails(this.props);
      // this.setState({ loading: false });
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!isEqual(nextProps.item, this.props.item)) {
      this.setItemDetails(nextProps);
    }
    const { priceLists } = nextProps;
    if (priceLists.length > 0) {
      this.setState({ selectedPriceList: priceLists[0] });
    }

    // check weather current items is updated
    if (nextProps.checkForItemUpdate) {
      if (this.state.updated) {
        // show alert
        console.log(' need to update item');
        confirm({
          title: 'Do you want to save changes?',
          content: '',
          onOk: () => this.handleSubmit(),
          okText: 'Yes',
          cancelText: 'No',
          onCancel: () => {
            nextProps.onUpdateFinished(nextProps.selectedVariantId);
            this.setState({ updated: false });
          },
          icon: <FiInfo size={25} style={{ color: '#ff4d25', float: 'left', marginRight: 14 }} />,
          okButtonProps: { style: { backgroundColor: '#498d05', borderColor: '#498d05' } }
        });
      } else {
        console.log(' no need to update item');
        nextProps.onUpdateFinished(nextProps.selectedVariantId);
      }
    }
  }

  setItemDetails = props => {
    const { item } = props;
    item && this.setState({
      item: item,
      itemPrices: (item && item.itemPrices && Object.keys(item.itemPrices).length > 0) ? Object.values(item.itemPrices) : [],
      selectedPrinterLocations: (item && item.printerLocations && Object.keys(item.printerLocations).length > 0) ? Object.values(item.printerLocations) : [],
      modifiersList: this.getModifiersList(item),
      parentCategories: this.getDefaultParentCategories(item)
    });
  }

  getModifiersList = (item) => {
    var modifierList = [];
    item && item.ingredients && Object.keys(item.ingredients).forEach(key => {
      if (!item.ingredients[key].hasOptions) {
        modifierList.push(item.ingredients[key]);
      } else {
        var options = [];
        var ingredientObj = item.ingredients[key];
        var optionsObject = ingredientObj.options;
        Object.keys(optionsObject).length > 0 && Object.keys(optionsObject).forEach(key => {
          options.push(optionsObject[key]);
        });
        const object = {
          id: ingredientObj.id,
          item: ingredientObj.item,
          commonName: ingredientObj.commonName,
          hasOptions: true,
          quantity: ingredientObj.quantity,
          uom: ingredientObj.uom,
          options: options
        };
        modifierList.push(object);
      }
    });

    return modifierList;
  }

  handleOnChange = (field, value) => {
    this.setState({ updated: true });
    switch (field) {
      case 'name':
        this.setState({ item: { ...this.state.item, name: value }, nameStatus: this.getValidationStatus(field, value) });
        break;
      case 'code':
        this.setState({ item: { ...this.state.item, code: value }, codeStatus: this.getValidationStatus(field, value) });
        break;
      case 'barcode':
        this.setState({ item: { ...this.state.item, barcode: value }, barcodeStatus: this.getValidationStatus(field, value) });
        break;
      case 'locations':
        const existingLocations = [...this.state.selectedPrinterLocations];
        if (value.checked) {
          existingLocations.push(value.value);
          this.setState({ selectedPrinterLocations: existingLocations });
        } else {
          var locations = [];
          if (existingLocations.length > 0) {
            locations = existingLocations.filter(location => location.id !== value.value.id);
          }
          this.setState({ selectedPrinterLocations: locations });
        }

        break;
      case 'PriceList':
        const existingPriceLists = [...this.state.itemPrices];
        if (value.checked) {
          this.setState({ isPriceDialogVisibile: true, selectedPriceObject: value });
        } else {
          existingPriceLists.find((itemPices) => {
            if (itemPices && itemPices.priceList.id === value.value.id) {
              existingPriceLists.splice(existingPriceLists.indexOf(itemPices), 1);
            }
            return null;
          });
        }
        this.setState({ itemPrices: existingPriceLists });
        break;
      case 'timeToServe':
        this.setState({ item: { ...this.state.item, timeToServe: value } });
        break;
      case 'recipe':
        this.setState({ item: { ...this.state.item, recipe: value } });
        break;
      case 'servings':
        this.setState({ item: { ...this.state.item, servings: value } });
        break;
      case 'tips':
        this.setState({ item: { ...this.state.item, tips: value } });
        break;
      case 'isIngredient':
        if (value.checked) {
          this.setState({ item: { ...this.state.item, isIngredient: value.checked } });
        } else {
          this.setState({ item: { ...this.state.item, isIngredient: value.checked, isModifier: value.checked } });
        }
        break;
      case 'isModifier':
        if (value.checked) {
          this.setState({ item: { ...this.state.item, isModifier: value.checked, isIngredient: value.checked } });
        } else {
          this.setState({ item: { ...this.state.item, isModifier: value.checked } });
        }
        break;
      case 'isMeal':
        this.setState({ item: { ...this.state.item, isMeal: value.checked } });
        break;
      case 'price':
        this.setState({ price: value, priceStatus: '' });
        break;
      case 'itemPrice':
        this.setPrice(value);
        break;
      case 'availableQty2':
        this.setState({ item: { ...this.state.item, availableQty2: value } });
        break;
      case 'displayInOnlineMenu2':
        this.setState({ item: { ...this.state.item, displayInOnlineMenu2: value.checked } });
        break;
      case 'onlineStockAvailable':
        this.setState({ item: { ...this.state.item, onlineStockAvailable: value.checked } });
        break;
      case 'description':
        this.setState({ item: { ...this.state.item, description: value } });
        break;
      case 'takeAway':
        this.setState({ item: { ...this.state.item, takeAway: value.checked } });
        break;
      case 'dineIn':
        this.setState({ item: { ...this.state.item, dineIn: value.checked } });
        break;
      default:
    }
  }

  setPrice = value => {
    const { itemPrices, selectedPriceList } = this.state;
    const objIndex = itemPrices.findIndex(pr => {
      const prId = pr && (pr.priceList) && pr.priceList.id;
      const listId = selectedPriceList && selectedPriceList.id;

      return (prId === listId);
    });

    if (objIndex === -1) {
      var newObj = {
        id: 'new',
        businessId: '',
        price: parseInt(value),
        priceList: selectedPriceList
      };
      this.setState({ itemPrices: [...itemPrices, newObj] });
    } else {
   
      var returnPrice = itemPrices.map((itemPrice, i) => (i === objIndex ? Object.assign({}, itemPrice, { price: parseInt(value) }) : itemPrice));
      console.log(returnPrice)
      this.setState({ itemPrices: returnPrice });
    }
  }

  handleOnChangeModalInputs = (field, value) => {
    const { company } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;
    // const businessId = item.businessId;
    switch (field) {
      case 'priceList': {
        const Obj = { name: value, id: value, businessId: businessId };
        this.setState({ priceList: Obj, priceListName: value, priceListNameStatus: '' });
      }
        break;
      case 'quantity':
        this.setState({ quantity: value });
        break;
      case 'uom':
        this.setState({ uom: value });
        break;
      case 'groupName':
        this.setState({ groupName: value, groupNameStatus: '' });
        break;
      case 'printerLocation': {
        const Obj = { name: value, id: value, businessId: businessId };
        this.setState({ printerLocation: Obj, printerLocationName: value, printerLocationNameStatus: '' });
      }
        break;
      default:
    }
  }

  showItemList = (itemListType) => {
    this.setState({ visibleItemList: true });
    if (itemListType === 'MODIFIER') {
      this.setState({ visibleItemList: true, itemListType });
    } else if (itemListType === 'GROUP_MODIFIER') {
      this.setState({ itemListType, visibleModifireGroupModal: true });
    } else if (itemListType === 'GROUP_MODIFIRE_INGREDIENT') {
      this.setState({ itemListType, visibleModifireGroupModal: false, visibleItemList: true });
    }
  }

  onCloseItemList = () => this.setState({ visibleItemList: false });

  onSelectItem = (itemListType, item) => {
    this.setState({ visibleItemList: false, itemListType: itemListType });
    if (item) {
      this.setState({ visibleAddIngredientModal: true, selectedIngredientItem: item });
    }
  }

  handleSubmit = () => {
    const { newVariantObject } = this.props;

    this.setState({ updated: false });

    if (!this.validateForm()) return;
    if (!newVariantObject) {
      this.handleUpdateItemBasicData();
    } else {
      this.handleInsertBasicItemData();
    }
  }

  onSavePrice = () => {
    const value = this.state.selectedPriceObject;
    const existingPriceLists = [...this.state.itemPrices];
    if (this.state.price.length > 0) {
      var varObj = { businessId: value.value.businessId, priceList: value.value, price: parseFloat(this.state.price) };
      existingPriceLists.push(varObj);
      this.setState({ isPriceDialogVisibile: false, itemPrices: existingPriceLists, price: 0 });
    } else {
      this.setState({ priceStatus: 'error' });
    }
  }

  getFormattedItemPrices = (itemPrices) => {
    var formattedItemPrices = itemPrices.map((itemPrice, i) => (isNaN(itemPrice.price) ? Object.assign({}, itemPrice, { price: 0 }) : itemPrice));
    return formattedItemPrices;
  }

  handleUpdateItemBasicData = () => {
    const { itemId, companyId } = this.props;
    const { name, code, servings, timeToServe, isIngredient, isModifier, recipe, tips, barcode, isMeal, imageSrc, availableQty2, displayInOnlineMenu2, onlineStockAvailable, description, dineIn, takeAway, categories } = this.state.item;
    const { itemPrices, selectedPrinterLocations, modifiersList, parentCategories } = this.state;

    var removedCategories = undefined;
    var addedCategories = undefined;
    var formatedItemPrices = this.getFormattedItemPrices(itemPrices);

    if (categories && parentCategories) {
      var categoryIds = this.getCategoryIds(categories);
      removedCategories = categoryIds.filter(this.comparer(parentCategories));
      addedCategories = parentCategories.filter(this.comparer(categoryIds));
    }
    const updatedItem = {};

    updatedItem.name = name;
    updatedItem.code = code;
    updatedItem.servings = servings;
    updatedItem.timeToServe = timeToServe;
    updatedItem.itemPrices = formatedItemPrices;
    updatedItem.printerLocations = selectedPrinterLocations;
    updatedItem.isIngredient = isIngredient;
    updatedItem.isModifier = isModifier;
    updatedItem.ingredients = modifiersList;
    updatedItem.recipe = recipe;
    updatedItem.tips = tips;
    updatedItem.barcode = barcode;
    updatedItem.isMeal = isMeal;
    updatedItem.imageSrc = imageSrc;
    updatedItem.availableQty2 = parseInt(availableQty2);
    updatedItem.displayInOnlineMenu2 = displayInOnlineMenu2;
    updatedItem.onlineStockAvailable = onlineStockAvailable;
    updatedItem.description = description;
    updatedItem.takeAway = takeAway;
    updatedItem.dineIn = dineIn;
    updatedItem.addedCategories = addedCategories;
    updatedItem.removedCategories = removedCategories;

    this.setState({ loading: true });
    this.props.updateItem(companyId, itemId, updatedItem).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        this.setItemDetails(this.props);
        // this.forceUpdate();
        showSuccessNotification('Updated.', 'Item updated successfully.');
        this.setState({ loading: false });
        if (this.props.checkForItemUpdate) {
          this.props.onUpdateFinished(this.props.selectedVariantId);
        }
      }
    });
  }

  handleInsertBasicItemData = () => {
    console.log(this.state.item)
    if (!this.isValidVariantFormData()) return;

    const { companyId, itemId, company, newVariantObject, addVariantItem, variantId } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;
    const { name, code, servings, timeToServe, isIngredient, isModifier, recipe, tips, barcode, isMeal, imageSrc, availableQty2, displayInOnlineMenu2, onlineStockAvailable, description, dineIn, takeAway } = this.state.item;
    const { itemPrices, selectedPrinterLocations, modifiersList, parentCategories } = this.state;
    const formatedItemPrices = this.getFormattedItemPrices(itemPrices);

    const newItem = {
      id: variantId,
      name: name,
      businessId: businessId,
      code: code,
      servings: servings === undefined ? '' : servings,
      timeToServe: timeToServe === undefined ? '' : timeToServe,
      printerLocations: selectedPrinterLocations,
      itemPrices: formatedItemPrices,
      ingredients: modifiersList,
      isIngredient: isIngredient,
      isModifier: isModifier,
      recipe: recipe,
      tips: tips,
      barcode: barcode,
      isMeal: isMeal,
      status: '',
      discount: 0,
      quantity: 0,
      imageSrc: imageSrc === undefined ? '' : imageSrc,
      availableQty2: parseInt(availableQty2),
      displayInOnlineMenu2: displayInOnlineMenu2,
      onlineStockAvailable: onlineStockAvailable,
      description: description,
      takeAway: takeAway,
      dineIn: dineIn,
      addedCategories: parentCategories,
      variant: this.makeVariantInnerObjectFiledAsList(newVariantObject)
    };

    this.setState({ loading: true });
    addVariantItem(companyId, itemId, { newItem, newOptions: Object.values(newVariantObject.options) }).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        this.props.history.replace('/items/' + response.variantData.newVariantItemId + '/variant');
        showSuccessNotification('Updated.', 'Variant added successfully.');
        this.setState({ loading: false });
        if (this.props.checkForItemUpdate) {
          this.props.onUpdateFinished(this.props.selectedVariantId);
        }
      }
    });
  }

  makeVariantInnerObjectFiledAsList = variant => {
    const variantOptionTypes = Object.values(variant.definition.variantOptionTypes);
    variantOptionTypes.forEach(optionType => {
      optionType.options = Object.values(optionType.options);
    });

    const options = Object.values(variant.options);
    options.forEach(option => {
      option.variantOptionType.options = Object.values(option.variantOptionType.options);
    });

    return {
      definition: {
        ...variant.definition,
        variantOptionTypes
      },
      options
    };
  }

  comparer = (otherArray) => {
    return function (current) {
      return otherArray.filter(function (other) {
        return other === current;
      }).length === 0;
    };
  }

  getCategoryIds = (categories) => {
    var categeroyIds = [];
    categories && categories.length > 0 && categories.forEach(category => {
      categeroyIds.push(category.id);
    });

    return categeroyIds;
  }

  insertItemGroupModifire = (ingredient, itemId) => {
    const { companyId } = this.props;
    this.setState({ loading: true });
    this.props.addItemGroupModifire(companyId, itemId,
      { quantity: ingredient.quantity, uom: ingredient.uom, commonName: ingredient.commonName, ingredientOptions: ingredient.options }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loading: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          // showSuccessNotification('Added.', 'Item Group Modifire Added successfully.');
          this.setState({ loading: false });
        }
      });
  }

  insertItemIngredient = (ingredient, itemId) => {
    const { companyId } = this.props;

    this.setState({ loading: true });
    this.props.addItemIngredient(companyId, itemId,
      { itemId: ingredient.item.id, quantity: ingredient.quantity, uom: ingredient.uom }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loading: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          // showSuccessNotification('Added.', 'Item Ingredients Added successfully.');
          this.setState({ loading: false });
        }
      });
  }

  insertItemPriceList = (itemPrice, itemId) => {
    const { companyId } = this.props;

    this.setState({ loading: true });
    this.props.addItemPriceList(companyId, itemId,
      { priceListId: itemPrice.priceList.id, businessId: itemPrice.businessId, price: itemPrice.price }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loading: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          // showSuccessNotification('Added.', 'Item Price Added successfully.');
          this.setState({ loading: false });
        }
      });
  }

  insertItemPrinterLocations = (printerLocation, itemId) => {
    const { companyId } = this.props;

    this.setState({ loading: true });
    this.props.addItemPrinterLocation(companyId, itemId,
      { printerLocationId: printerLocation.id }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loading: false });
        } else {
          // this.setState({  ingredients :[response.item.ingredients]});
          // showSuccessNotification('Added.', 'Item Printer Location Added successfully.');
          this.setState({ loading: false });
        }
      });
  }

  handleRemoveIngredient = (ingredientId) => {
    const { companyId, itemId } = this.props;

    this.setState({ loading: true });
    this.props.removeIngredient(companyId, ingredientId, {itemId : itemId}).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        // this.setState({  ingredients :[response.item.ingredients]});
        showSuccessNotification('Delete.', 'Ingredient Deleted successfully.');
        this.props.fetchItem(companyId, itemId).then(response => {
          this.setState({ loading: false });
        });
      }
    });
  }

  handleInsertAdvanceItemData = (itemId) => {
    const { modifiersList } = this.state;
    modifiersList.forEach(ingredient => {
      if (ingredient.hasOptions) {
        this.insertItemGroupModifire(ingredient, itemId);
      } else {
        this.insertItemIngredient(ingredient, itemId);
      }
    });

    if (this.props.itemId === 'new') {
      this.setState({ ...defaultItem, selectedPrinterLocations: [], modifiersList: [], itemPrices: [] });
    }
  }

  onAddNewPriceList = () => {
    const { companyId } = this.props;

    if (this.state.priceListName.length > 0) {
      this.setState({ loading: true });
      this.setState({ visiblePriceListModal: false });
      this.props.addPriceList(companyId, this.state.priceList).then(response => {
        this.setState({ loading: false });
        const { error, priceLists } = response;
        if (error) {
          showErrorNotification('Failed adding Price List', error);
        } else {
          showSuccessNotification('Price List added', 'Price List added successfully.');
          this.setState({ priceListName: '', priceListNameStatus: '', newPriceList: priceLists });
          this.forceUpdate();
        }
      });
    } else {
      this.setState({ priceListNameStatus: 'error' });
    }
  }

  onAddNewPrinterLocation = () => {
    const { companyId } = this.props;

    if (this.state.printerLocationName.length > 0) {
      this.setState({ loading: true });
      this.setState({ visiblePrinterLocationModal: false });
      this.props.addPrinterLocation(companyId, this.state.printerLocation).then(response => {
        this.setState({ loading: false });
        const { error } = response;
        if (error) {
          showErrorNotification('Failed adding Printer locations', error);
        } else {
          this.props.fetchPrinterLocations(companyId).then(() => {
            showSuccessNotification('Printer Location added', 'Printer Location added successfully.');
            this.setState({ loading: false, printerLocationName: '', printerLocationNameStatus: '' });
          });
        }
      });
    } else {
      this.setState({ printerLocationNameStatus: 'error' });
    }
  }

  generateLocations = () => {
    const { printerLocations } = this.props;
    const locationArray = [];
    printerLocations && printerLocations.forEach(location => {
      locationArray.push(
        <Col key={location.id} lg={8} md={8} sm={12} xs={12}>
          <Checkbox style={styles.checkBox} checked={this.setSelectedLocations(location)} value={location} onChange={event => this.handleOnChange('locations', event.target)}>{location.name}</Checkbox>
        </Col>
      );
    });

    return locationArray;
  }

  generatePriceLists = () => {
    const { priceLists, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const priceListArray = [];
    priceLists && priceLists.forEach(priceList => {
      var priceObjectSelected = this.getSelectedPrice(priceList);
      priceListArray.push(
        <Col span={6} key={priceList.id}>
          <Row>
            <Checkbox checked={priceObjectSelected.selected} value={priceList} onChange={event => this.handleOnChange('priceList', event.target)}>{priceList.name}</Checkbox>
            <p style={{ width: '100%', marginLeft: 25 }}> {format(priceObjectSelected.price, currencyFormat(business))}</p>
          </Row>

        </Col>
      );
    });

    return priceListArray;
  }

  getSelectedPrice = list => {
    const { itemPrices } = this.state;
    var obj = { selected: false, price: 0 };
    itemPrices.find(pr => {
      const prId = pr && (pr.priceList) && pr.priceList.id;
      const listId = list && list.id;

      if (prId === listId) {
        obj.selected = true;
        obj.price = pr.price;
      }

      return null;
    });
    return obj;
  }

  setSelectedLocations = location => {
    const { selectedPrinterLocations } = this.state;
    return selectedPrinterLocations.find(pl => {
      const plId = pl && pl.id;
      const locId = location && location.id;
      return plId === locId;
    });
  }

  removeIngredient = (ingredient) => {
    const ingredients = [...this.state.ingredients];
    var index = ingredients.indexOf(ingredient);
    ingredients.splice(index, 1);
    this.setState({ ingredients: ingredients });
    this.handleRemoveIngredient(ingredient.id);
  }

  renderModifiers = () => {
    const { modifiersList } = this.state;
    var listOfModifiers = [];
    modifiersList && modifiersList.length > 0 && modifiersList.forEach((modifier, index) => {
      listOfModifiers.push(<ModifierItem index={index} modifier={modifier} onSelectItem={(e, modifier, index) => this.handleOnSelectModifier(e, modifier, index)} onRemove={(e, modifier) => this.handleOnRemoveModifier(e, modifier)} />);
    });
    return listOfModifiers;
  }

  renderItemPriceDialog = () => {
    return (
      <Modal
        visible={this.state.isPriceDialogVisibile}
        title='Add Price'
        okText='Save'
        onCancel={() => this.setState({ isPriceDialogVisibile: false, price: 0 })}
        onOk={() => this.onSavePrice()}
      >
        <Form layout='vertical'>
          <Form.Item label='Price' validateStatus={this.state.priceStatus}>
            <Input type='number' value={this.state.price} onChange={(event) => this.handleOnChange('price', event.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  validateForm = () => {
    const { name, code } = this.state.item;
    const validForm = this.isValidName(name) &&
      this.isValidCode(code);

    if (!validForm) {
      this.setState({
        nameStatus: this.getValidationStatus('name', name),
        codeStatus: this.getValidationStatus('code', code)
      });
    }
    return validForm;
  }

  validateNewIngredientForm = () => {
    const { quantity, uom } = this.state;
    const validForm = this.isValidQuantity(quantity) &&
      this.isValidUom(uom);

    if (!validForm) {
      this.setState({
        quantityStatus: this.getValidationStatus('quantity', quantity),
        uomStatus: this.getValidationStatus('uom', uom)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      case 'code':
        return this.isValidCode(value) ? 'success' : 'error';
      case 'barcode':
        return this.isValidBarcode(value) ? 'success' : 'error';
      case 'quantity':
        return this.isValidQuantity(value) ? 'success' : 'error';
      case 'uom':
        return this.isValidUom(value) ? 'success' : 'error';
      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;
  isValidCode = code => code && code.length > 0;
  isValidBarcode = barcode => barcode && barcode.length > 0;
  isValidQuantity = quantity => quantity && quantity.length > 0;
  isValidUom = uom => uom && uom.length > 0;

  handlePriceListChange = (value) => {
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ selectedPriceList: priceList[0] });
    }
  }

  handleCategoryChange = (value) => {
    this.setState({ parentCategories: value, parentCategoryStatus: '', updated: true });
  }

  handleOnRemoveModifier = (e, modifier) => {
    const { modifiersList } = this.state;
    var list = modifiersList.length > 0 && modifiersList.filter(item => item.id !== modifier.id);
    this.setState({ modifiersList: list, updated: true });
  }

  handleOnSelectModifier = (e, modifier, index) => {
    console.log(index);
    const { modifiersList } = this.state;
    var list = modifiersList.length > 0 && modifiersList.filter(item => item.id === modifier.id);
    if (list.length > 0) {
      if (!list[0].hasOptions) {
        // var obj = { id: ItemObject.name, item: list[0].item, uom: list[0].item, quantity: parseInt(list[0].item) };
        this.setState({ visibleAddIngredientModal: true, selectedIngredientItem: list[0].item, uom: list[0].uom, quantity: parseInt(list[0].quantity), selectedIngredientIndex: index });
      } else {
        // var modifireObj = { id: groupName + '-' + list[0].name, commonName: this.state.groupName, hasOptions: true, options: list[0].options && Object.keys(list[0].options).length > 0 ?  Object.values(list[0].options) : [], quantity: list[0].quantity, uom: list[0].uom, item: list[0].item };
        this.setState({ groupModifireIngredients: list[0].options && Object.keys(list[0].options).length > 0 ? Object.values(list[0].options) : [], groupName: list[0].commonName, selectedIngredientIndex: index });
        this.setState({ visibleModifireGroupModal: true });
      }
    }
  }

  onCloseItemList = () => this.setState({ visibleItemList: false });

  onCancelPriceList = () => this.setState({ visiblePriceListModal: false, priceListName: '', priceListNameStatus: '' })

  addPrinterLocation = () => this.setState({ visiblePrinterLocationModal: true })

  onCancelPrinterLocationModal = () => this.setState({ visiblePrinterLocationModal: false })

  onCancelModifireGroupModal = () => this.resetModifireGroupModal()

  resetModifireGroupModal = () => this.setState({ groupName: '', groupNameStatus: '', visibleModifireGroupModal: false, groupModifireIngredients: [], selectedIngredientItem: undefined, selectedIngredientIndex: -1 })

  onCancelNewIngredientModal = () => this.setState({ quantity: '', uom: '', visibleAddIngredientModal: false })

  onRemoveGroupModifierIngredient = (obj) => {
    const { groupModifireIngredients } = this.state
    var list = groupModifireIngredients.length > 0 && groupModifireIngredients.filter(ingredient => ingredient.id !== obj.id)
    this.setState({ groupModifireIngredients: list, updated: true })
  }

  // onCreateGroupModifireModal = () => {
  //   const { groupName } = this.state;
  //   var list = [...this.state.groupModifireIngredients];
  //   if (this.state.groupName.length > 0) {
  //     if (list.length > 0) {
  //       var modifireObj = { id: groupName + '-' + list[0].name, commonName: this.state.groupName, hasOptions: true, options: list, quantity: list[0].quantity, uom: list[0].uom };
  //       this.setState({ visibleModifireGroupModal: false, modifiersList: [...this.state.modifiersList, modifireObj] });
  //       this.resetModifireGroupModal();
  //     }
  //   } else {
  //     this.setState({ groupNameStatus: 'error' });
  //   }
  // }

  // onSaveNewModifier = () => {
  //   const { modifiersList } = this.state;
  //   this.setState({ visibleAddIngredientModal: false });
  //   var ItemObject = this.state.selectedIngredientItem;
  //   var obj = { id: ItemObject.name, item: ItemObject, uom: this.state.uom, quantity: parseInt(this.state.quantity) };

  //   if (this.state.itemListType === 'GROUP_MODIFIRE_INGREDIENT') {
  //     this.updateGroupModifireIngredients(obj)
  //     this.setState({ visibleModifireGroupModal: true, uom: '', quantity: '' });

  //   } else if (this.state.itemListType === 'MODIFIER') {
  //     var mList = [...modifiersList];
  //     mList.push(obj);
  //     this.setState({ modifiersList: mList, quantity: '', uom: '' });
  //   }
  // }

  onCreateGroupModifireModal = (index) => {
    console.log(index);
    const { groupName, modifiersList } = this.state;
    var list = [...this.state.groupModifireIngredients];
    if (this.state.groupName.length > 0) {
      if (list.length > 0) {
        var mList = [...modifiersList];
        var modifireObj = { id: groupName + '-' + list[0].name, commonName: this.state.groupName, hasOptions: true, options: list, quantity: list[0].quantity, uom: list[0].uom, item: list[0].item };
        const listWithItem = mList.length > 0 && mList.filter((item, i) => i === index);
        listWithItem.length > 0 ? mList.splice(index, 1, modifireObj) : mList.push(modifireObj);
        this.setState({ visibleModifireGroupModal: false, modifiersList: mList });
        this.resetModifireGroupModal();
        this.setState({ updated: true });
      }
    } else {
      this.setState({ groupNameStatus: 'error' });
    }
  }

  onSaveNewModifier = (index) => {
    const { modifiersList } = this.state;
    this.setState({ visibleAddIngredientModal: false });
    var ItemObject = this.state.selectedIngredientItem;
    var obj = { id: ItemObject.id, item: ItemObject, uom: this.state.uom, quantity: parseInt(this.state.quantity) };
    console.log(index, obj, this.state.itemListType);
    if (this.state.itemListType === 'GROUP_MODIFIRE_INGREDIENT') {
      this.updateGroupModifireIngredients(obj);
      this.setState({ visibleModifireGroupModal: true, uom: '', quantity: '' });
    } else if (this.state.itemListType === 'MODIFIER') {
      var mList = [...modifiersList];
      const listWithItem = mList.length > 0 && mList.filter((item, i) => i === index);
      listWithItem.length > 0 ? mList.splice(index, 1, obj) : mList.push(obj);
      this.setState({ modifiersList: mList, quantity: '', uom: '', selectedIngredientIndex: -1, updated: true });
    }
  }

  updateGroupModifireIngredients = (newIngredient) => {
    var listofIngredients = [...this.state.groupModifireIngredients];
    var currentIngredient = listofIngredients.filter(ingredient => ingredient.item.id === newIngredient.item.id);
    if (currentIngredient && currentIngredient.length > 0) {
      var unChangedIngredients = listofIngredients.filter(ingredient => ingredient.item.id !== newIngredient.item.id);
      var obj = { ...currentIngredient[0], uom: newIngredient.uom, quantity: parseInt(newIngredient.quantity) };
      var newArray = [...unChangedIngredients, obj];
      this.setState({ groupModifireIngredients: newArray });
    } else {
      var list = [...listofIngredients, newIngredient];
      this.setState({ groupModifireIngredients: list });
    }
  }

  handleEditableCellChangeGroupModifier = (record) => {
    this.updateGroupModifireIngredients(record);
  }

  resetForm = () => {
    this.setState({
      variantCount: 1,
      loading: false,
      selectedPriceList: '',
      item: {
        ...defaultItem
      },
      nameStatus: '',
      codeStatus: '',
      descriptionStatus: '',
      parentCategories: undefined,
      visibleItemList: false,

      uom: '',
      quantity: '',
      priceListName: '',
      priceListNameStatus: '',
      printerLocationName: '',
      printerLocationNameStatus: '',
      visiblePriceListModal: false,
      visiblePrinterLocationModal: false,
      visibleAddIngredientModal: false
    });
  }

  handleImageChange = src => {
    this.setState({ item: { ...this.state.item, imageSrc: src } });
  }

  getCategoryTreeNodes = (categoryTree, keyOfValue = 'id') => {
    const categoriesArray = [];
    if (categoryTree && categoryTree.length !== undefined) {
      categoryTree.forEach(category => {
        if (category.id !== 'defaultMenu') {
          const obj = {
            title: category.name,
            key: category.id,
            value: keyOfValue === 'name' ? category.name : category.id,
            children: category.categories && Object.keys(category.categories).length > 0 && this.getCategoryTreeNodes(category.categories, keyOfValue)
          };
          categoriesArray.push(obj);
        }
      });
    } else {
      Object.keys(categoryTree).forEach(key => {
        if (categoryTree[key].id !== 'defaultMenu') {
          const obj = {
            title: categoryTree[key].name,
            key: categoryTree[key].id,
            value: keyOfValue === 'name' ? categoryTree[key].name : categoryTree[key].id,
            children: categoryTree[key].categories && Object.keys(categoryTree[key].categories).length > 0 && this.getCategoryTreeNodes(categoryTree[key].categories, keyOfValue)
          };
          categoriesArray.push(obj);
        }
      });
    }

    return categoriesArray;
  }

  getDefaultParentCategories = (item) => {
    const defaultCategories = [];
    if (item !== undefined) {
      const { categories } = item;

      categories && categories.forEach(category => {
        defaultCategories.push(category.id);
      });
    }

    return defaultCategories;
  }

  renderPriceLists = () => {
    const { priceLists } = this.props;
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(<Option value={priceList.name} key={priceList}>{priceList.name}</Option>);
    });
    return priceListOptions;
  }

  renderPricesList = () => {
    const { priceLists, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(
        <Col span={12}>
          <span style={styles.checkBox}> <b style={{ marginRight: 10 }}>{priceList.name} :</b> {format(this.getSelectedPrice(priceList).price, currencyFormat(business))} </span>
        </Col>
      );
    });
    return priceListOptions;
  }

  isValidVariantFormData = () => {
    const { newVariantObject = {} } = this.props;

    let errorCount = newVariantObject.options && Object.values(newVariantObject.options).length;
    newVariantObject.options && Object.values(newVariantObject.options).forEach(option => {
      if (option.option && option.option.length !== 0) errorCount--;
    });

    return errorCount === 0;
  }

  getVariantOptionBreadcumbTitle = () => {
    const { item = {} } = this.state;
    const variant = item.variant;
    let breadcumbTitle = '';

    const options = variant && variant.options && Object.values(variant.options);
    options && options.forEach(option => {
      breadcumbTitle = breadcumbTitle + option.option + ' / ';
    });

    return breadcumbTitle.substr(0, breadcumbTitle.length - 3);
  }

  render() {
    const { companyId, itemId, categoryTree, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const {
      // nameStatus,
      // codeStatus,
      item,
      parentCategories,
      selectedPriceList,
      visiblePrinterLocationModal,
      visibleAddIngredientModal,
      selectedIngredientItem,
      visiblePriceListModal,
      loading,
      visibleModifireGroupModal,
      quantity, uom, groupName, groupNameStatus,
      groupModifireIngredients,
      barcodeStatus, codeStatus, nameStatus,
      visibleItemList
    } = this.state;

    const { name, imageSrc, isIngredient, isMeal, isModifier, displayInOnlineMenu2, onlineStockAvailable, code, barcode, description, availableQty2 } = item;
    const categoryTreeNodesList = this.getCategoryTreeNodes(categoryTree, 'id');

    return (
      <Spin spinning={loading}>
        <div>
          <ModifireGroupModalForm
            index={this.state.selectedIngredientIndex} listofIngredients={groupModifireIngredients} onItemAddClick={this.showItemList} visible={visibleModifireGroupModal} onCancel={this.onCancelModifireGroupModal} onCreate={(index) => this.onCreateGroupModifireModal(index)} handleOnChange={this.handleOnChangeModalInputs} item={selectedIngredientItem} groupNameStatus={groupNameStatus} groupName={groupName} onRemove={this.onRemoveGroupModifierIngredient}
            handleEditableCellChangeSave={this.handleEditableCellChangeGroupModifier}
          />
          <IngredientModalForm index={this.state.selectedIngredientIndex} quantity={quantity} uom={uom} visible={visibleAddIngredientModal} onCancel={this.onCancelNewIngredientModal} item={selectedIngredientItem} onCreate={(index) => this.onSaveNewModifier(index)} handleOnChange={this.handleOnChangeModalInputs} />

          <PriceListModalForm visible={visiblePriceListModal} priceListName={this.state.priceListName} priceListNameStatus={this.state.priceListNameStatus} onCancel={this.onCancelPriceList} onCreate={this.onAddNewPriceList} handleOnChange={this.handleOnChangeModalInputs} />
          <PrinterLocationModalForm visible={visiblePrinterLocationModal} onCancel={this.onCancelPrinterLocationModal} printerLocationName={this.state.printerLocationName} printerLocationNameStatus={this.state.printerLocationNameStatus} onCreate={this.onAddNewPrinterLocation} handleOnChange={this.handleOnChangeModalInputs} />
          <Row gutter={[10, 10]} style={{ marginTop: 0 }}>
            <ItemList
              business={business}
              companyId={companyId}
              // loading={loading}
              type={this.state.itemListType}
              visible={visibleItemList}
              onClose={this.onCloseItemList}
              onSelectItem={this.onSelectItem}
              onSaveItem={this.onSaveItem}
              itemId={itemId}
              priceListId={selectedPriceList.id}
            />
            <Col span={24}>
              <Card className='card-container'>
                <Form className='compact-form'>
                  <Col lg={8} md={8} sm={24} xs={24}>
                    <ImageUploadContainer
                      folder={'/' + companyId + '/items/' + itemId}
                      src={imageSrc}
                      size={150}
                      componentHeight='inherit'
                      onImageChange={this.handleImageChange}
                    />
                  </Col>
                  <Col lg={16} md={16} sm={24} xs={24}>
                    <div>
                      <h3 style={{ fontWeight: 400, fontSize: 24 }}>{item && this.getVariantOptionBreadcumbTitle()}</h3>
                    </div>
                    <Form.Item label='Name' validateStatus={nameStatus}>
                      <Input value={name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                    </Form.Item>
                    <Form.Item label='Description'>
                      <TextArea rows={5} value={description} onChange={(event) => this.handleOnChange('description', event.target.value)} />
                    </Form.Item>
                  </Col>
                </Form>
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card className='card-container' style={{ height: 140 }}>
                <h6>Availability</h6>
                {/* <Checkbox style={styles.checkBox} checked={displayInOnlineMenu2} onChange={event => this.handleOnChange('displayInOnlineMenu2', event.target)}>Display in Online Menu</Checkbox> */}
                <Checkbox style={styles.checkBox} checked={onlineStockAvailable} onChange={event => this.handleOnChange('onlineStockAvailable', event.target)}>Available in Stock</Checkbox>
                {/* <Checkbox style={styles.checkBox} checked={dineIn} onChange={event => this.handleOnChange('dineIn', event.target)}>Dine In</Checkbox>
                  <Checkbox style={styles.checkBox} checked={takeAway} onChange={event => this.handleOnChange('takeAway', event.target)}>Take away</Checkbox> */}
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card className='card-container' style={{ height: 140 }}>
                <h6>Options</h6>
                <Checkbox style={styles.checkBox} checked={isIngredient} onChange={event => this.handleOnChange('isIngredient', event.target)}>Is Ingredient</Checkbox>
                <Checkbox style={styles.checkBox} checked={isModifier} onChange={event => this.handleOnChange('isModifier', event.target)}>Is Modifier</Checkbox>
                <Checkbox style={styles.checkBox} checked={isMeal} onChange={event => this.handleOnChange('isMeal', event.target)}>Is Meal</Checkbox>
              </Card>
            </Col>
            <Col span={24}>
              <Card className='card-container'>
                <h6>Category</h6>
                <TreeSelect
                  dropdownMatchSelectWidth
                  style={{ width: '100%' }}
                  value={parentCategories}
                  multiple
                  placeholder='Category'
                  treeData={categoryTreeNodesList}
                  onChange={(value) => this.handleCategoryChange(value)}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card className='card-container'>
                <div style={styles.horizontalContainer}>
                  <h6>Modifiers</h6>
                  <div style={{ marginBottom: 10 }}>
                    <Button type='primary' className='btn-orange' style={{ marginRight: 5 }} onClick={() => this.setState({ visibleItemList: true, itemListType: 'MODIFIER' })}>
                      <FiPlusCircle style={{ marginRight: 5 }} /> Add Ingredient
                    </Button>

                    <Button type='primary' className='btn-orange' style={{ marginRight: 5 }} onClick={() => this.setState({ visibleModifireGroupModal: true })}>
                      <FiPlusCircle style={{ marginRight: 5 }} /> Add group modifier
                    </Button>
                  </div>
                </div>
                {this.renderModifiers()}
              </Card>
            </Col>
            <Col span={24}>
              <Card className='card-container'>
                <div style={styles.horizontalContainer}>
                  <h6>Price Lists</h6>
                  <FiPlusCircle className='incy-button' style={styles.iconButton} onClick={() => this.setState({ visiblePriceListModal: true })} />
                </div>

                <Select
                  style={styles.categorySelect}
                  value={selectedPriceList && selectedPriceList.name}
                  onChange={this.handlePriceListChange}
                >
                  {this.renderPriceLists()}
                </Select>
                <Form className='compact-form'>
                  <Form.Item label='Price'>
                    <Input type='number' value={this.getSelectedPrice(selectedPriceList).price} onChange={(event) => this.handleOnChange('itemPrice', event.target.value)} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()} />
                  </Form.Item>
                  {/* <Form.Item label='Cost per item'>
                    <Input />
                  </Form.Item> */}
                  {this.renderPricesList()}
                </Form>
              </Card>
            </Col>
            <Col span={24}>
              <Card className='card-container'>
                <div style={styles.horizontalContainer}>
                  <h6>Locations</h6>
                  <FiPlusCircle className='incy-button' style={styles.iconButton} onClick={() => this.setState({ visiblePrinterLocationModal: true })} />
                </div>
                <p>Where to send the order tickets</p>
                {this.generateLocations()}
              </Card>
            </Col>
            <Col span={24}>
              <Card className='card-container'>
                <h6>Inventory</h6>
                <Form className='compact-form'>
                  <Col span={12}>
                    <Form.Item label='Short Code (Stock Keeping Unit)' validateStatus={codeStatus}>
                      <Input style={{ width: '100%' }} value={code} onChange={(event) => this.handleOnChange('code', event.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Barcode' validateStatus={barcodeStatus}>
                      <Input style={{ width: '100%' }} value={barcode} onChange={(event) => this.handleOnChange('barcode', event.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Available Quantity'>
                      <Input style={{ width: '100%' }} value={availableQty2} onChange={(event) => this.handleOnChange('availableQty2', event.target.value)} />
                      {/* <Checkbox>Track Quantity</Checkbox> */}
                    </Form.Item>
                  </Col>
                </Form>
              </Card>
            </Col>
            <Col lg={24} md={24}>
              <div style={{
                width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'
              }}
              >
                {this.props.newVariantObject && <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={this.handleSubmit}>
                  <Icon type='save' /> Save
                </Button>}
                {!this.props.newVariantObject && <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={this.handleSubmit}>
                  Update
                </Button>}
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}
const styles = {
  checkBox: {
    width: '100%', marginLeft: 0
  },
  categorySelect: {
    width: '100%'
  },
  iconButton: {
    color: '#F95C38',
    fontSize: 20
  },
  horizontalContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  optionTitle: {
    fontWeight: 500,
    fontSize: 14,
    padding: 5,
    marginBottom: 0
  }
};

const mapStateToProps = (state, props) => {
  const { itemId } = props;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return {
    itemId: itemId,
    companyId: companyId,
    item: getItem(state, itemId),
    priceLists: getPriceLists(state),
    categoryTree: getCategoryTree(state),
    company: getCompanyById(state, companyId),
    printerLocations: getPrinterLocations(state)
  };
};

export default withRouter(connect(mapStateToProps, { fetchCompany, addItem, updateItem, fetchCategories, fetchPriceLists, fetchItem, fetchPrinterLocations, addPrinterLocation, addPriceList, addItemPriceList, addItemGroupModifire, addItemIngredient, addItemPrinterLocation, addVariantItem })(ItemForm));
