import { getPriceLists, insertPriceList } from '../api/priceLists';

export const fetchPriceLists = companyId => dispatch => {
  return getPriceLists(companyId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_PRICE_LISTS', priceLists: response.priceLists });
    return response;
  });
};

export const addPriceList = (companyId, priceListItem) => dispatch => {
  return insertPriceList(companyId, priceListItem).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_PRICE_LISTS', priceLists: response.priceLists });
    return response;
  });
};
