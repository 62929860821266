import React, { Component } from 'react';
import { Modal, Form, Icon, Upload, Button } from 'antd';
class ImportMenuModal extends Component {
  state = {
    uomStatus: '',
    quantityStatus: ''
  }

  render () {
    const { visible } = this.props;

    const config = {
      showUploadList: false,
      onRemove: this.props.handleFileChosenRemove,
      beforeUpload: file => {
        this.props.handleFileChosen(file);
        return false;
      },
      accept: '.csv'
    };

    return (
      <Modal
        visible={visible}
        title='Import menu'
        okText='Ok'
        onCancel={this.props.onCancel}
        footer={null}
      // onOk={this.ok}
      >

        <Form layout='vertical'>
          <Form.Item>
            <p style={{ fontSize: 16 }}><b>1. Download the Import template.</b></p>
            <a href='https://drive.google.com/uc?export=download&id=1oV9XaO-Kik_7VH2VXL21odNrqw7QCwE9' download>
              <Button className='btn-orange primary-button' style={{ marginRight: 5, marginLeft: 5 }}>
                <Icon type='download' />DOWNLOAD TEMPLATE
              </Button>
            </a>
          </Form.Item>
          <Form.Item>
            <p style={{ fontSize: 16 }}><b>2. Fill the downloaded template.</b></p>
          </Form.Item>
          <Form.Item>
            <p style={{ fontSize: 16 }}><b>3. Save the filled template in csv file format.</b></p>
          </Form.Item>
          <Form.Item>
            <p style={{ fontSize: 16 }}><b>4. Upload the Filled template.</b></p>
            <Upload {...config}>
              <Button type='primary' className='btn-orange primary-button' style={{ marginRight: 5, marginLeft: 5 }}>
                <Icon type='select' /> UPLOAD
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  ok = () => this.props.onOk
  cancel = () => {
    this.props.onCancel();
  }
}

export default ImportMenuModal;
