import React, { Component } from 'react';
import { Row, Spin, Table, Input, Card, Col, Form, Modal, Select, Menu, Dropdown, Tag, Button } from 'antd';
import { connect } from 'react-redux';
import { fetchCompany } from '../../actions/companies';
import { getCompanyById, getAuthenticity, getFilteredCustomers, getFilteredCustomerTotalCount, getActiveCustomerFilter, getBusinesses, getBranches } from '../../reducers';
import { updateCustomer, deleteCustomer, updateActiveFilter } from '../../actions/Customers';
import { fetchList } from '../../actions/generic';
import moment from 'moment';
import withRouter from 'react-router/es/withRouter';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import EditableCell from './EditableCell';
import { showLoadingMessage } from '../../components/Message';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
const { confirm } = Modal;
const { Option } = Select;
const { Search } = Input;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const dateTimeFormat = 'YYYY/MM/DD';
const PAGE_SIZE = 20;

const EditableFormRow = Form.create()(EditableRow);

class CustomersList extends Component {
  state = {
    loading: false,
    loadingCount: false,
    textFilter: undefined,
    expiryDate: undefined,
    usingKds: false,
    usingCheckoutCounter: false,
    currentPage: 1,
    sortedInfo: null,
    action: undefined,
    filter: {
      businessIds: [],
      text: undefined,
      page: 1,
      pageSize: 20,
      status: []
    },
    submitting: false
  }

  componentDidMount = () => {
    const { companyId, authData: { user: { userId } }, filter, updateActiveFilter, businesses, branches } = this.props;
    const businessIds = businesses.map(b => b.id);
    this.setState({ filter: { ...filter, pageSize: 20, businessIds } }, () => {
      const { filter } = this.state;

      updateActiveFilter(filter);
      this.handleSearch(filter);
    });
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Customers.');
  }

  handleSearch = filter => {
    const { fetchList } = this.props;

    this.setState({ loading: true });
    if (!this.hideLoadingMessage) this.showLoadingMessage();
    fetchList("view_customer_order_summary", filter).then(response => {
      if (this.hideLoadingMessage) this.hideLoadingMessage();
      this.setState({ loading: false });
      this.setAutoRefresher(filter);
    });
  }

  handleOnClickSearch = () => {
    const { updateActiveFilter } = this.props;
    const { filter } = this.state;

    this.setState({ filter: { ...filter, page: 1 } }, () => {
      const { filter } = this.state;

      updateActiveFilter(filter);
      this.handleSearch(filter);
    });
  }

  handleSearch = filter => {
    const { fetchList } = this.props;

    this.setState({ loading: true });
    if (!this.hideLoadingMessage) this.showLoadingMessage();
    fetchList("view_customer_order_summary", filter).then(response => {
      if (this.hideLoadingMessage) this.hideLoadingMessage();
      this.setState({ loading: false });
      //this.setAutoRefresher(filter); stop auto refersher 
    });
  }

  setAutoRefresher = filter => {
    this.stopAutoRefresher();
    this.timeout = window.setTimeout(() => {
      this.handleSearch(filter);
    }, 120000);
  }

  stopAutoRefresher = () => {
    if (this.timeout) window.clearTimeout(this.timeout);
  }


  getMenu = (record) => {
    return (
      <Menu onClick={(e) => this.handleMenuItemClick(e, record)}>
        {record.status !== 'ACTIVE' && <Menu.Item key='ACTIVE'>Active</Menu.Item>}
        {record.status !== 'INACTIVE' && <Menu.Item key='INACTIVE'>Inactive</Menu.Item>}
        {record.status !== 'BLOCKED' && <Menu.Item key='BLOCKED'>Block</Menu.Item>}
        {<Menu.Item key='DELETE'>Delete</Menu.Item>}
      </Menu>

    );
  }

  getColumns = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        align: 'left',
        // sorter: (a, b) => a.orderNumber.length - b.orderNumber.length,
        // sortOrder: sortedInfo.columnKey === 'orderNumber' && sortedInfo.order,
        render: text => <p>{text && text.length > 0 ? text : '-'}</p>,
        editable: true
      },

      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 150,
        align: 'left',
        render: text => <p>{text && text.length > 0 ? text : '-'}</p>,
        editable: true
      },

      {
        title: 'Telephone',
        dataIndex: 'telephone',
        key: 'telephone',
        align: 'left',
        width: 120,
        render: text => <p>{text && text.length > 0 ? text : '-'}</p>,
        editable: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        align: 'center',
        render: text => <Tag color='#108ee9'>{text}</Tag>
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 150,
        // sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        // sortOrder: sortedInfo.columnKey === 'createdDate' && sortedInfo.order,
        render: text => <p>{text && text.length > 0 ? text : '-'}</p>,
        editable: true
      },
      {
        title: 'Orders',
        dataIndex: 'orders',
        key: 'orders',
        width: 100,
        align: 'right',
        render: (order_count, dataRow) => <p>{order_count ? order_count : 0}</p>
      },

      {
        title: 'Total Spent',
        dataIndex: 'orders',
        key: 'totalSpent',
        align: 'right',
        width: 130,
        sorter: (a, b) => (a.order_total - b.order_total),
        sortOrder: sortedInfo.columnKey === 'order_total' && sortedInfo.order,
        render: (order_total, dataRow) => <p>{order_total ? format(order_total, currencyFormat(business)) : format(0, currencyFormat(business))}</p>
      },

      {
        title: 'Balance',
        dataIndex: 'orders',
        key: 'balanceToSettle',
        align: 'right',
        width: 130,
        sorter: (a, b) => (a.balance_to_settle - b.balance_to_settle),
        sortOrder: sortedInfo.columnKey === 'balance_to_settle' && sortedInfo.order,
        render: (balance_to_settle, dataRow) => <p>{balance_to_settle ? format(balance_to_settle, currencyFormat(business)) : format(0, currencyFormat(business))}</p>
      },

      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        fixed: 'right',
        render: (text, record) => (
          <span className='table-operation'>
            <Dropdown overlay={this.getMenu(record)}>
              <p style={{ textAlign: 'center' }}>...</p>
            </Dropdown>
          </span>
        )
      }
    ];
    return columns.map(col => {
      if (!col.editable) return col;
      return {
        ...col,
        onCell: record => (
          {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleEditableCellChangeSave,
            Context: EditableContext
          }
        )
      };
    });
  }

  getTotalSpentOfAllCustomers = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    let total = 0;
    const filterdCutomersList = this.getFilteredCustomers();
    filterdCutomersList.length > 0 && filterdCutomersList.forEach(customer => {
      customer && customer.orders && customer.orders.length > 0 && customer.orders.forEach(order => {
        total = total + order.total;
      });
    });

    total = format(total, currencyFormat(business));
    return total;
  }

  getTotalOutstandingOfAllCustomers = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    let total = 0;
    const filterdCutomersList = this.getFilteredCustomers();
    filterdCutomersList.length > 0 && filterdCutomersList.forEach(customer => {
      customer && customer.orders && customer.orders.length > 0 && customer.orders.forEach(order => {
        total = total + order.balanceToSettle;
      });
    });
    total = format(total, currencyFormat(business));
    return total;
  }

  getTotalSpent = (orders) => {
    let total = 0;
    orders && orders.length > 0 && orders.forEach(order => {
      total = total + order.total;
    });
    return total;
  }

  getTotalBalanceToSettle = (orders) => {
    let total = 0;
    orders && orders.length > 0 && orders.forEach(order => {
      total = total + order.balanceToSettle;
    });
    return total;
  }

  handleTableChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      sortedInfo: sorter,
      currentPage: pagination.current,
      expandedRows: []
    });
  };


  handleEditableCellChangeSave = (record, value) => {
    this.onUpdateCusotmer(record);
  }

  handleItemEditableCellChangeSave = (record, value) => {

  }

  expandedRowRender = (record) => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const columns = [
      { title: 'Order Number', dataIndex: 'orderNumber', key: 'orderNumber' },
      { title: 'Created Date', dataIndex: 'createdDate', key: 'createdDate', align: 'center' },
      { title: 'Status', dataIndex: 'status', key: 'status', align: 'center', render: text => <Tag color='#108ee9'>{text}</Tag> },
      { title: 'type', dataIndex: 'type', key: 'type', align: 'right' },
      {
        title: 'balanceToSettle',
        dataIndex: 'balanceToSettle',
        key: 'balanceToSettle',
        align: 'right',
        render: text => <p>  {format(text, currencyFormat(business))} </p>

      },
      { title: 'Total', dataIndex: 'total', key: 'total', align: 'right', render: text => <p> {format(text, currencyFormat(business))} </p> }
    ];

    var cardTitle = 'Orders Details';
    const items = [];
    record.orders && record.orders.forEach(order => { items.push(order); });
    return (
      <Card style={{ margin: 5 }} title={cardTitle}>

        <Row gutter={5}>
          <Col lg={24} style={{ marginBottom: 15 }}>
            <Table columns={columns} dataSource={items} pagination={false} />
          </Col>
        </Row>

      </Card>
    );
  }

  onDateChange = (date, dateString) => this.setState({ expiryDate: moment(date).toDate() })
  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })
  handleInputCheckChange = (e) => {
    if (e.target.checked) {
      this.setState({ filter: { ...this.state.filter, method: 'WEB' } });
    } else {
      this.setState({ filter: { ...this.state.filter, method: '' } });
    }
  }

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  handleStatusChange = value => {
    this.setState({ filter: { ...this.state.filter, status: value } });
  }


  handleMenuItemClick = (e, record) => {
    if (e.key === 'DELETE') {
      this.showConfirmUpdateOrder(record, e.key);
    } else {
      const newRecord = { ...record, status: e.key };
      this.showConfirmUpdateOrder(newRecord, e.key);
    }
  }

  showConfirmUpdateOrder = (record, action) => {
    const THIS = this;
    confirm({
      title: 'Do you want to ' + action.toLowerCase() + ' this customer?',
      content: '',
      okButtonProps: { className: 'primary-button' },
      onOk() {
        if (action === 'DELETE') { THIS.onDeleteCustomer(record); } else { THIS.onUpdateCusotmer(record); }
      },
      onCancel() { }
    });
  }

  onUpdateCusotmer = (updatedObject) => {
    console.log(updatedObject)
    const { companyId } = this.props;

    const customer = {
      name: updatedObject.name,
      address: updatedObject.address,
      email: updatedObject.email,
      telephone: updatedObject.telephone,
      status: updatedObject.status
    };

    this.setState({ submitting: true });
    this.props.updateCustomer(companyId, updatedObject.id, customer).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating Customer', error);
        this.setState({ submitting: false });
      } else {
        this.props.fetchList("view_customer_order_summary", { businessIds: this.state.filter.businessIds, id: updatedObject.id }).then(response => {
          this.setState({ submitting: false });
          showSuccessNotification('Customer updated.', 'Customer updated successfully.');
        })

      }
    });
  }

  onDeleteCustomer = (updatedObject) => {
    const { companyId } = this.props;

    this.setState({ submitting: true });
    this.props.deleteCustomer(companyId, updatedObject.id).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed deleting Customer', error);
        this.setState({ submitting: false });
      } else {
        this.props.fetchList("view_customer_order_summary", this.state.filter).then(response => {
          this.setState({ submitting: false });
          showSuccessNotification('Customer updated.', 'Customer deleted successfully.');
        })
      }
    });
  }

  getFilteredCustomers = () => {
    const { customers } = this.props;
    const { filter } = this.state;

    let filteredCustomerList = customers;

    if (filter && filter.text) {
      filteredCustomerList = customers.filter(customer => {
        let searchTerm = filter.text;
        searchTerm = searchTerm.replace(/-/g, '');

        return customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          customer.email.toLowerCase().includes((filter.text).toLowerCase()) ||
          customer.address.toLowerCase().includes((filter.text).toLowerCase()) ||
          customer.status.toLowerCase().includes((filter.text).toLowerCase());
      });
    }

    // if (filter && filter.method) {
    //   filteredOrderList = filteredOrderList.filter(order => {
    //     return (order.method && order.method.toLowerCase().match((filter.method).toLowerCase()))
    //   });
    // }

    // if (filter.from) {
    //   filteredOrderList = filteredOrderList.filter(order => {
    //     return (order.createdDate && moment(order.createdDate).valueOf() > filter.from.valueOf())
    //   });
    // }

    // if (filter.to) {
    //   filteredOrderList = filteredOrderList.filter(order => {
    //     return (order.createdDate && moment(order.createdDate).valueOf() < filter.to.valueOf())
    //   });
    // }

    return filteredCustomerList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  getPaginationConfig = () => {
    return {
      pageSize: PAGE_SIZE,
      onChange: this.handlePaginationChange,
      total: this.props.totalResults,
      defaultCurrent: this.props.filter.page,
      current: this.props.filter.page
    };
  }

  handlePaginationChange = (page, pageSize) => {
    const { updateActiveFilter } = this.props;

    this.setState({ filter: { ...this.state.filter, page } }, () => {
      const { filter } = this.state;

      updateActiveFilter(filter);
      this.handleSearch(filter);
    });
  }

  render() {
    const { loading, submitting } = this.state;
    const { customers } = this.props;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    return (
      <Card style={{ margin: 5 }} className='card-container'>

        <Row gutter={10}>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Search style={{ marginBottom: 10 }} placeholder='Search customers by name/email' onChange={(e) => this.handleInputTextChange(e.target.value)} onPressEnter={this.handleOnClickSearch} />
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Select
              style={{ width: '100%', marginBottom: 5 }} placeholder='Status'
              allowClear
              mode='multiple'
              onChange={(value) => this.handleStatusChange(value)}
            >
              <Option value='ACTIVE'>ACTIVE</Option>
              <Option value='INACTIVE'>INACTIVE</Option>
              <Option value='BLOCKED'>BLOCKED</Option>
            </Select>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Button block className='primary-button' type='primary' onClick={() => this.handleOnClickSearch()}>Search</Button>
          </Col>

        </Row>

        <Row style={{ marginTop: 10 }}>
          <Spin spinning={submitting || loading && customers && customers.length === 0}>
            <Table
              columns={this.getColumns()}
              // expandedRowRender={record => this.expandedRowRender(record)}
              dataSource={customers}
              onChange={this.handleTableChange}
              rowClassName={() => 'editable-row'}
              components={components}
              scroll={{ x: 1320 }}
              pagination={this.getPaginationConfig()}
            />

          </Spin>
        </Row>
      </Card>

    );
  }
}

const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  console.log("customers", getFilteredCustomers(state), getActiveCustomerFilter(state))
  return ({
    companyId,
    company: getCompanyById(state, companyId),
    totalResults: getFilteredCustomerTotalCount(state),
    filter: getActiveCustomerFilter(state),
    customers: getFilteredCustomers(state),
    authData,
    businesses: getBusinesses(state),
    branches: getBranches(state),
  });
};

export default withRouter(connect(mapStateToProps, { fetchCompany, updateCustomer, deleteCustomer, updateActiveFilter, fetchList })(CustomersList));
