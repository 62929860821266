import React, { Component } from 'react';
import { Col, List, Row, Select } from 'antd';
import EditableItemRow from './EditableItemRow';

const { Option } = Select;

class EditableVariantItemList extends Component {
  state = {
    selectedPriceList: undefined,
    selectedOptions: []
  }

  componentDidMount = () => {
    const { priceLists, variantItems = [] } = this.props;
    this.setState({
      selectedPriceList: priceLists && priceLists.length > 0 ? priceLists[0] : undefined,
      selectedOptions: variantItems.map(item => ({ key: item.key, value: 'NEW_ITEM' }))
    });
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps) {
      const { selectedOptions } = this.state;
      const newOptions = nextProps.variantItems.map(item => ({ key: item.key, value: 'NEW_ITEM' }));
      newOptions.forEach((newOption, index) => {
        const matchingOption = selectedOptions.find(option => option.key === newOption.key);
        if (!matchingOption) {
          selectedOptions.push(newOption);
        }
      });

      this.setState({ selectedOptions: selectedOptions });
    }
  }

  renderPriceLists = () => {
    const { priceLists } = this.props;
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(<Option value={priceList.name} key={priceList}>{priceList.name}</Option>);
    });
    return priceListOptions;
  }

  handlePriceListChange = (value) => {
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ selectedPriceList: priceList[0] });
    }
  }

  handleOptionChange = (value, key) => {
    const { selectedOptions } = this.state;

    selectedOptions.forEach((option) => {
      if (option.key === key) option.value = value;
    });

    this.setState({ selectedOptions: selectedOptions });
    this.props.onClickChangeVariantItem(key, value);
  }

  render () {
    const {
      variantItems = [],
      showHeader = true,
      extendedView = true,
      onUpdateVariantItem,
      companyId,
      itemPrices
    } = this.props;

    const { selectedPriceList, selectedOptions = [] } = this.state;

    return (
      <div>
        <div style={{ textAlign: 'right', marginBottom: 10 }}>
          <Select
            dropdownMatchSelectWidth
            style={{ width: '30%' }}
            placeholder='Price list'
            value={selectedPriceList && selectedPriceList.name}
            onChange={this.handlePriceListChange}
          >
            {this.renderPriceLists()}
          </Select>
        </div>

        {showHeader && <Row className='item-table-header' style={{ marginLeft: 5, marginRight: 5, marginBottom: 15 }}>
          <Col lg={6}>Name</Col>
          <Col lg={5}>Code</Col>
          <Col className='text-right' lg={5}>Price</Col>
          <Col className='text-right' lg={8}>Actions</Col>
        </Row>}
        <List
          itemLayout='horizontal'
          dataSource={variantItems}
          grid={{ gutter: 5 }}
          size='small'
          pagination={extendedView ? ({
            size: 'small',
            pageSize: 15
          }) : (null)}
          renderItem={item => (
            <List.Item rowKey={item.key}>
              <EditableItemRow
                itemDetails={item}
                selectedPriceList={selectedPriceList}
                companyId={companyId}
                items={variantItems}
                onUpdateVariantItem={onUpdateVariantItem}
                itemPrices={itemPrices}
                onOptionChange={this.handleOptionChange}
                selectedOptions={selectedOptions}
              />
            </List.Item>
          )}
        />
      </div>
    );
  }
}
export default EditableVariantItemList;
