import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Table, Input, Avatar, Spin } from 'antd';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import { getItems, getItemsByIsIngredient, isItemsFetching } from '../../reducers';
import { fetchItems } from '../../actions/items';
import { fetchPrinterLocations } from '../../actions/printerLocations';

class ItemList extends Component {
  state = {
    loading: false,
    textFilter: undefined,
    optionValue: 1
  }

  componentDidMount = () => {
    // const { companyId } = this.props;
    // this.props.fetchItems(companyId);
    // this.props.fetchPrinterLocations(companyId);

    window.addEventListener('focus', this.handleFocus);
  }

  componentWillUnmount = () => {
    window.removeEventListener('focus', this.handleFocus);
  }

  handleFocus = () => {
    // this.props.fetchItems(this.props.companyId);
  }

  setLoading = loading => {
    this.setState({ loading: loading });
  }

  onItemSearch = value => this.setState({ textFilter: value })

  getItems = () => {
    const { items } = this.props;
    const { textFilter } = this.state;

    return textFilter ? items.filter(item => {
      return item.name.toLowerCase().includes(textFilter.toLowerCase()) ||
        item.code.toLowerCase().includes(textFilter.toLowerCase());
    }) : items;
  }

  // onSelectItem = (itemListType, rawData) => {
  //   console.log(rawData)
  // }

  getColumns = () => {
    const { business } = this.props;
    return [
      {
        title: 'Image',
        dataIndex: 'imageSrc',
        width: '15%',
        render: (imageSrc, dataRow) => {
          const imgUrl = imageSrc != null ? imageSrc : '/images/empty-image.png';
          return <Avatar src={imgUrl} size='large' />;
        }
      }, {
        title: 'Code',
        dataIndex: 'code',
        editable: false
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        editable: false
      },
      {
        title: 'Price',
        dataIndex: 'itemPrices',
        align: 'right',
        editable: false,
        render: (itemPrices, dataRow) => <div>{format(this.getItemPrice(itemPrices), currencyFormat(business))}</div>
      }
      // {
      //   title: 'Actions',
      //   dataIndex: 'id',
      //   align: 'right',
      //   height: 40,
      //   render: (text, rawData) => {
      //     return (<Button type={"primary"} size={"small"} onClick={() => onSelectItem(this.props.itemListType, rawData)}>Add to Category</Button>
      //     )
      //   },
      // }
    ];
  }

  getItemPrice = itemPricesMap => {
    const { priceListId } = this.props;
    console.log(priceListId);
    const itemPrices = itemPricesMap && Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let price = 0;

    itemPrices && itemPrices.length > 0 && itemPrices.forEach(itemPrice => {
      if (itemPrice.priceList && (itemPrice.priceList.id === priceListId)) price = itemPrice.price;
    });

    return price;
  }

  render() {
    const { onClose, visible, type, onSelectItem, fetching } = this.props;
    const { loading, textFilter } = this.state;

    const columns = this.getColumns();

    // const enableRowSelection = type === 'GROUP_MODIFIER' || type === 'MODIFIER';

    return (
      <div>
        <Drawer
          title={type === 'ALL' ? 'Item List' : 'Ingredient List'}
          placement='right'
          closable={false}
          onClose={onClose}
          visible={visible}
          width='50%'
        >
          {/* <Alert
            message="Informational Notes"
            description="In order to add existing item to selected category click on a row.
            To add a new item to the list fill the form and press add new button, it'll create a new item and automatically add to the selected category."
            type="info"
            showIcon
            closable
          /> */}

          <div style={{ marginTop: 10 }}>
            <Spin spinning={loading || fetching}>
              <div style={{ marginBottom: 10 }}>
                <Input placeholder='Search Item' value={textFilter} allowClear onChange={(event) => this.onItemSearch(event.target.value)} />
              </div>
              {// enableRowSelection && <Button style={{ marginBottom: 10 }} block size='small'>Add</Button>
              }
              <div>
                <Table
                  scroll={{ y: window.innerHeight - 361 }}
                  style={{ minHeight: window.innerHeight - 260, borderBottom: '1px solid #f1f1f1' }}
                  columns={columns}
                  className='itemList'
                  dataSource={this.getItems()}
                  showHeader
                  pagination
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => onSelectItem(type, record)
                    };
                  }}
                />
              </div>
            </Spin>
          </div>

        </Drawer>
      </div>
    );
  }
}

const filterIngredients = (ingredients, itemId) => {
  var filteredIngredients = [];
  if (itemId) {
    filteredIngredients = ingredients.filter(ingredient => ingredient.id !== itemId);
  } else {
    filteredIngredients = ingredients;
  }
  return filteredIngredients;
};

const mapStateToProps = (state, props) => {
  const { itemId } = props;
  if (props.type === 'ALL') {
    return {
      items: getItems(state),
      fetching: isItemsFetching(state)
    };
  } else if (props.type === 'MODIFIER' || props.type === 'GROUP_MODIFIRE_INGREDIENT') {
    return {
      items: filterIngredients(getItemsByIsIngredient(state), itemId),
      fetching: isItemsFetching(state)
    };
  }
};

export default connect(mapStateToProps, { fetchItems, fetchPrinterLocations })(ItemList);
