import React, { Component } from 'react';
import { Col, Form, Input, Button, DatePicker, Row, Select } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

class AdvancedSearchForm extends Component {
  constructor () {
    super();
    this.state = {
      formLayout: 'horizontal'
    };
  }

  render () {
    const { query, onDateRangeChange, onInputTextChange, onClickSearch, onStatusSelectionChange } = this.props;

    return (
      <Form layout='vertical'>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {/* <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item>
                <Input placeholder="Customer Name" onPressEnter={onClickSearch} onChange={(e) => onInputTextChange(e, "filterText")} />
              </Form.Item>
            </Col> */}
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <RangePicker
                  allowClear={false}
                  onPressEnter={onClickSearch}
                  defaultValue={[moment(query && query.from), moment(query && query.to)]}
                  format='YYYY-MM-DD'
                  style={{ width: '100%' }}
                  onChange={onDateRangeChange}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Input placeholder='Payment ID' onPressEnter={onClickSearch} onChange={(e) => onInputTextChange(e, 'receiptId')} />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  showSearch
                  dropdownMatchSelectWidth
                  style={{ width: '100%' }}
                  mode='multiple'
                  placeholder='Status'
                  defaultValue='All'
                  value={query && query.status ? query.status : 'All'}
                  onChange={onStatusSelectionChange}
                >
                  <Option value='PENDING' key='PENDING'>PENDING</Option>
                  <Option value='CONFIRMED' key='CONFIRMED'>CONFIRMED</Option>
                  <Option value='COMPLETED' key='COMPLETED'>COMPLETED</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4} md={4} sm={24} xs={24}>
              <Button className='primary-button' block type='primary' onClick={() => onClickSearch()}>Search</Button>
            </Col>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default AdvancedSearchForm;
