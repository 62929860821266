import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchWarehousesByBranchIds } from '../../../actions/warehouses'
import { getAuthenticity, getWarehouses, getSelectedBranchId } from "../../../reducers";

const { Option } = Select;

class SelectWarehouse extends Component {
  componentDidMount = () => {
    const { companyId, selectedBranchId } = this.props;
    const data = { branchIds: [selectedBranchId] };
    this.props.fetchWarehousesByBranchIds(companyId, data);
  }

  componentWillReceiveProps = (nextProps) => {
    const { companyId, selectedBranchId } = nextProps;
    if(JSON.stringify(nextProps.selectedBranchId) !== JSON.stringify(this.props.selectedBranchId)){
      const data = { branchIds: [selectedBranchId] };
      this.props.fetchWarehousesByBranchIds(companyId, data);
    }
  }

  render() {
    const {
      warehouses,
      onChange,
      showSearch = false,
      placeholder = 'Select Location',
      allowClear = false,
      defaultValue,
      disabled = false,
      style = { width: '100%' }
    } = this.props

    return (
      <Select
        placeholder={placeholder}
        value={defaultValue}
        onChange={(value) => onChange('LocCode', value, 'locCodeError')}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        style={style}
      >
        {warehouses.map(warehouse => <Option value={warehouse.id}>{warehouse.name}</Option>)}
      </Select>
    )
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  console.log(getWarehouses(state))
  const { user: { userId: companyId } } = authData;
  return ({
    companyId: companyId,
    warehouses: getWarehouses(state),
    selectedBranchId: getSelectedBranchId(state) === 0 ? "0" : getSelectedBranchId(state)
  });
};

export default connect(mapStateToProps, { fetchWarehousesByBranchIds })(SelectWarehouse);