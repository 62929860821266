import React, { Component } from 'react';
import { Avatar, Button } from 'antd';
import { showErrorNotification } from '../../components/Notification';
import emptyImage from '../../image/empty-image.png';

export default class ImageUploadContainer extends Component {
  state = {
    preparing: true
  }

  constructor (props) {
    super();
    this.initCloudinaryWidget(props.folder, props.cropping);
  }

  initCloudinaryWidget = (folder = '', cropping = true) => {
    this.cloudinaryWidget = window.cloudinary.createUploadWidget({
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
      sources: ['local', 'url', 'camera'],
      cropping: cropping,
      croppingAspectRatio: 1,
      folder: 'incypos' + (folder.startsWith('/') ? folder : ('/' + folder)),
      resourceType: 'image',
      maxImageFileSize: 1500000 // 1.5MB
    }, (error, result) => {
      if (result) {
        if (result.event === 'source-changed') {
          this.setState({ preparing: false });
        } else if (result.event === 'success') {
          console.log('Done! Here is the image info: ', result.info);
          this.props.onImageChange(result.info.secure_url);
        }
      } else if (error) {
        showErrorNotification('We could not upload the image. An error occured.');
      }
    }
    );
  }

  handleUploadClick = () => {
    this.cloudinaryWidget.open();
  }

  render () {
    const { src, size, showButton = true, componentHeight = 300, showAvatar = true, buttonAlignment = 'center' } = this.props;
    const { preparing } = this.state;
    const imagePath = src || emptyImage;
    return (
      <div style={{ minHeight: componentHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {showAvatar ? (
            <Avatar
              alt='uploading or uploaded'
              className={showButton && 'incy-button'}
              src={imagePath}
              size={size}
              style={{ opacity: preparing ? 0.6 : 1, boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
              onClick={showButton && this.handleUploadClick}
            />
          ) : (
            <div>
              <img
                alt='uploading or uploaded'
                style={{ height: 150, width: 'auto', padding: 5, paddingRight: 7 }}
                src={imagePath}
              />
            </div>
          )}
        </div>
        {showButton && <div style={{ display: 'flex', justifyContent: buttonAlignment, marginTop: 20 }}>
          <Button
            className='btn-orange primary-button' loading={preparing}
            onClick={this.handleUploadClick}
          >{preparing ? 'Preparing' : 'Select Image'}
          </Button>
        </div>}
      </div>
    );
  }
}
