import {
  addBusiness,
  modifyBusiness,
  getCompanies,
  getCompanyDeviceList,
  getCompany,
  getCompanySubscriptions,
  updateDeviceStatus,
  updateSubscriptionDate,
  getSubscriptionDevice,
  addSubscriptionToDevice,
  deleteSubscriptionFromDevice,
  modifyKioskCount,
  getKioskCount,
  updateBasicBusinessProfile,
  modifyBusinessHours,
  removeBusinessHours,
  addBusinessHours,
  updateDevice,
  addVerificationCode,
  getCustomUrlStatus,
  modifyCustomUrl,
  modifyOnlineAvailability
} from '../api/companies';

export const fetchCompanies = () => dispatch => {
  return getCompanies().then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANIES', companies: response.companies });
    return response;
  });
};

export const fetchCompanyDeviceList = companyId => dispatch => {
  return getCompanyDeviceList(companyId).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_DEVICES', devices: response.devices });
      dispatch({ type: 'FETCH_COMPANY_DEVICES', devices: response.devices, companyId });
    }
    return response;
  });
};

export const fetchCompany = companyId => dispatch => {
  return getCompany(companyId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const fetchCompanySubscriptions = companyId => dispatch => {
  return getCompanySubscriptions(companyId).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_SUBSCRIPTIONS', subscriptions: response.subscriptions });
      dispatch({ type: 'FETCH_COMPANY_SUBSCRIPTIONS', subscriptions: response.subscriptions, companyId });
    }
    return response;
  });
};

export const activeDeactiveDevice = (companyId, deviceId, data) => dispatch => {
  return updateDeviceStatus(companyId, deviceId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_DEVICE', device: response.device, companyId });
    return response;
  });
};

export const updateSubscription = (companyId, subscriptionId, data) => dispatch => {
  return updateSubscriptionDate(companyId, subscriptionId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_SUBSCRIPTION', subscription: response.subscription, companyId });
    return response;
  });
};

export const fetchSubscriptionDevice = (companyId, subscriptionId) => dispatch => {
  return getSubscriptionDevice(companyId, subscriptionId).then(response => {
    return response;
  });
};

export const updateDeviceSubscription = (companyId, deviceId, subscriptionId) => dispatch => {
  return addSubscriptionToDevice(companyId, deviceId, subscriptionId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_DEVICE', device: response.device, companyId });
    return response;
  });
};

export const removeDeviceSubscription = (companyId, deviceId) => dispatch => {
  return deleteSubscriptionFromDevice(companyId, deviceId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_DEVICE', device: response.device, companyId });
    return response;
  });
};

export const fetchCompanyKioskCount = (companyId, businessId) => dispatch => {
  return getKioskCount(companyId, businessId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_KIOSK_COUNT', count: response.count, companyId });
    return response;
  });
};
export const updateKioskCount = (companyId, businessId, data) => dispatch => {
  return modifyKioskCount(companyId, businessId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const updateBusiness = (companyId, data) => dispatch => {
  return modifyBusiness(companyId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const updateBasicBusinessData = (companyId, data) => dispatch => {
  return updateBasicBusinessProfile(companyId, data).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
      dispatch({ type: 'SET_LOGGED_IN_CUSTOMER', company: response.company, companyId });
    }
    return response;
  });
};

export const updateBusinessHours = (companyId, id, data) => dispatch => {
  return modifyBusinessHours(companyId, id, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const deleteBusinessHours = (companyId, id) => dispatch => {
  return removeBusinessHours(companyId, id).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const saveBusinessHours = (companyId, data) => dispatch => {
  return addBusinessHours(companyId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const modifyDevice = (companyId, deviceId, data) => dispatch => {
  return updateDevice(companyId, deviceId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_DEVICE', device: response.device, companyId });
    return response;
  });
};

export const verifyClient = (companyId, data) => dispatch => {
  return addVerificationCode(companyId, data).then(response => {
    if (!response.error && response.company.code !== 400) dispatch({ type: 'FETCH_COMPANY', company: response.company, companyId });
    return response;
  });
};

export const verifyCustomUrlStatus = (companyId, data) => dispatch => {
  return getCustomUrlStatus(companyId, data).then(response => {
    return response;
  });
};

export const updateCustomUrl = (companyId, data) => dispatch => {
  return modifyCustomUrl(companyId, data).then(response => {
    return response;
  });
};

export const updateOnlineAvailability = (companyId, businessId, data) => dispatch => {
  return modifyOnlineAvailability(companyId, businessId, data).then(response => {
    return response;
  });
};

export const setSelectedBranch = branchId => dispatch => {
  dispatch({
    type: 'SET_SELECTED_BRANCH',
    branchId
  });
}
