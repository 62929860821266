import React, { Component } from 'react';
import { Col, Form, Row, Spin, InputNumber, Input, Button } from 'antd';

class CashPaymentForm extends Component {
	// amountValidator = amount => {
	//   const { invoiceAmount = 0 } = this.props;

	//   if (!amount || amount < 0) return 'Invalid receipt amount. Amount should be greater than zero.';
	//   else if (amount > invoiceAmount) return "Receipt amount can't exceed invoice amount.";
	// }

	render () {
	  const { submitting, handleOnChange, cashPayment = {}, handleConfirmPayment, cashAmountError } = this.props;
	  
	  return (
	    <Row gutter={10}>
    <Spin spinning={submitting}>
  <Form>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
	            <div>Receipt Amount</div>
	            <Form.Item
            required validateStatus={cashAmountError ? 'error' : ''}
            help={cashAmountError}
          >
            <div>
  <InputNumber
                style={{ width: '100%' }} value={cashPayment.amount} autoFocus
	                  onChange={(value) => handleOnChange('cashPayment', 'amount', value)}
              />
	              </div>
          </Form.Item>
	          </Col>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
  <div>Remarks</div>
	            <Form.Item>
	              <div>
      <Input
	                  value={cashPayment.remarks}
  onChange={(event) => handleOnChange('cashPayment', 'remarks', event.target.value)}
	                />
    </div>
	            </Form.Item>
	          </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
  <Button disabled={cashPayment.amount === 0 || cashAmountError} type='primary' size='small' style={{ width: '100%', marginBottom: 10 }} onClick={() => handleConfirmPayment('cashPayment')}>Submit Payment</Button>
	          </Col>
        {/* <Col style={{ float: 'right', width: 'fit-content' }} lg={12} md={24} sm={24} xs={24}>
							<h1><span style={{ margin: 5 }}>Amount:</span> {format(cashPayment.amount, currencyFormat)}</h1>
						</Col> */}
	        </Form>
	      </Spin>
  </Row>
	  );
	}
}

export default CashPaymentForm;
