import React, { Component } from 'react';
import { Row, Card, Col, Button, Spin, Input, Form, Select, Modal, Tag, Switch } from 'antd';
import { connect } from 'react-redux';
import { fetchCompany, updateBusiness, updateBusinessHours, deleteBusinessHours, saveBusinessHours } from '../../actions/companies';
import { sendEmailVerification } from '../../actions/Customers';
import { getCompanyById, getAuthenticity, getPriceLists } from '../../reducers';

import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import moment from 'moment';
import withRouter from 'react-router/es/withRouter';
import SettingDesctiption from './components/SettingsDescription';
import DeliveryRateCard from './components/DeliveryRateCard';
import DeliveryAreaCard from './components/DeliveryAreaCard';
import { bankList, branchList } from '../../bank';
import OpeningHoursContainer from './components/OpeningHoursContainer';
import { GeoMap } from '../../components/common/GeoMap';
import { GeoInputSearch } from '../../components/common/GeoInputSearch';
import { countryList, currencyList } from '../../Countries';
import { zoneList } from '../../TimeZone';
import Api from '../../api/consts';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FiInfo, BsCheckCircle, IoIosCloseCircleOutline } from 'react-icons/all';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import headerTemplate from '../../../src/image/fooder-header-template.jpg';
import templateAI from '../../../src/image/template.ai';
import templatePSD from '../../../src/image/template.psd';
import templateJPEG from '../../../src/image/template.jpg';
import templatePDF from '../../../src/image/template.pdf';
import OnlineAvailability from './OnlineAvailablitity';
import { showLoadingMessage } from '../../components/Message';
import { fetchPriceLists } from '../../actions/priceLists';
import facebookIcon from '../../../src/image/facebook.svg';
import twitterIcon from '../../../src/image/twitter.svg';
import linkdnIcon from '../../../src/image/linkdn.svg';
import instagramIcon from '../../../src/image/instagram.svg';

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

// import LocationSelecter from './components/LocationSelecter';

class Settings extends Component {
  state = {
    loading: false,
    showAll: true,
    showGeneral: false,
    showDelevery: false,
    showBankDetails: false,
    showBranding: false,
    business: {},
    onlineStoreSettings: {},
    deliverySettings: {},
    bankDetailsSettings: {},
    deliveryAreas: [],
    businessHours: undefined,
    generalSettings: {},

    accountNameStatus: '',
    accountNumberStatus: '',
    bankNameStatus: '',
    branchNameStatus: '',

    onlineStoreAddressStatus: '',
    onlineStoreTelephoneNumbersStatus: '',

    nameStatus: '',
    onlineStoreNameStatus: '',
    onlineStoreTagLineStatus: '',
    onlineStoreEmailStatus: '',
    addressStatus: '',
    cityStatus: '',
    countryStatus: '',
    timeZoneStatus: '',
    postalCodeStatus: '',
    facebookUrlStatus: '',
    twitterrUrlStatus: '',
    instagramUrl: '',
    linkdnUrlStatus: '',
    phoneStatus: '',
    currencyStatus: '',
    geoLocation: null,
    allowOnlineOrders: false,
    googleSuggestAddress: undefined,
    initialStateOfBackDetailVerified: undefined,
    isBankDetailUpdate: false,

    customerName: '',
    contactEmail: '',
    contactEmailVerified: '',
    customerNameStatus: '',
    contactEmailStatus: '',

    customUrl: undefined,
    customUrlStatus: undefined
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  componentDidMount = async () => {
    const { companyId, company } = this.props;
    if (company && company.businesses && company.businesses['0']) {
      this.showLoadingMessage();
      this.props.fetchCompany(companyId).then((response) => {
        this.props.fetchPriceLists(companyId).then((response) => {
          this.hideLoadingMessage();
        });
        this.setCompanyDetails(this.props);

      });
    } else {
      this.setState({ loading: true });
      this.props.fetchCompany(companyId).then((response) => {
        this.props.fetchPriceLists(companyId).then((response) => {
          this.setState({ loading: false });
          this.setCompanyDetails(this.props);
        });
      });
    }
    const script = document.createElement('script');

    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCd-XlL5mgW29M5ihIJ3cMXooHi6S3Qhkg&libraries=places&types=cities';
    script.async = true;

    document.body.appendChild(script);
  }

  componentWillReceiveProps = (nextProps) => {
    this.setCompanyDetails(nextProps);
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Settings.');
  }

  setCompanyDetails = (props) => {
    const { company } = props;
    const business = company && company.businesses && company.businesses['0'];
    const customer = company && company.customer;
    const onlineStoreSettings = business && business.onlineStoreSettings;
    console.log(onlineStoreSettings);
    const bankDetailsSettings = business && business.bankAccountDetail;
    const deliverySettings = business && business.deliverySettings;
    const generalSettings = business && business.generalSettings;
    var allowOnlineOrders = generalSettings && generalSettings.allowOnlineOrders != null ? generalSettings.allowOnlineOrders : this.state.allowOnlineOrders;

    this.setState({ business: business, allowOnlineOrders: allowOnlineOrders, customerName: customer && customer.name, contactEmail: customer && customer.contactEmail, contactEmailVerified: customer && customer.contactEmailVerified });
    const object = {
      onlineStoreEmail: onlineStoreSettings && onlineStoreSettings.onlineStoreEmail ? onlineStoreSettings.onlineStoreEmail : '',
      onlineStoreName: onlineStoreSettings && onlineStoreSettings.onlineStoreName ? onlineStoreSettings.onlineStoreName : '',
      onlineStoreTagLine: onlineStoreSettings && onlineStoreSettings.onlineStoreTagLine ? onlineStoreSettings.onlineStoreTagLine : '',
      onlineStoreDescription: onlineStoreSettings && onlineStoreSettings.onlineStoreDescription ? onlineStoreSettings.onlineStoreDescription : '',
      onlineStoreAddress: onlineStoreSettings && onlineStoreSettings.onlineStoreAddress ? onlineStoreSettings.onlineStoreAddress : '',
      onlineStoreFoodTypes: onlineStoreSettings && onlineStoreSettings.onlineStoreFoodTypes ? Object.values(onlineStoreSettings.onlineStoreFoodTypes) : [],
      onlineStorePriceRange: onlineStoreSettings && onlineStoreSettings.onlineStorePriceRange ? onlineStoreSettings.onlineStorePriceRange : 1,
      onlineStorePriceList: onlineStoreSettings && onlineStoreSettings.onlineStorePriceList ? onlineStoreSettings.onlineStorePriceList : undefined,
      bannerSrc: onlineStoreSettings && onlineStoreSettings.bannerSrc ? onlineStoreSettings.bannerSrc : '',
      thumbnailSrc: onlineStoreSettings && onlineStoreSettings.thumbnailSrc ? onlineStoreSettings.thumbnailSrc : '',
      onlineStoreLattitude: onlineStoreSettings && onlineStoreSettings.geoLocation2 ? onlineStoreSettings.geoLocation2.lat : 0,
      onlineStoreLongitude: onlineStoreSettings && onlineStoreSettings.geoLocation2 ? onlineStoreSettings.geoLocation2.lng : 0,
      socialLinks: onlineStoreSettings && onlineStoreSettings.socialLinks ? Object.values(onlineStoreSettings.socialLinks) : [],
      onlineStoreTelephoneNumbers: onlineStoreSettings && onlineStoreSettings.onlineStoreTelephoneNumbers ? Object.values(onlineStoreSettings.onlineStoreTelephoneNumbers) : [],
    };

    if (onlineStoreSettings && onlineStoreSettings.geoLocation2) {
      this.setState({
        geoLocation: { ...onlineStoreSettings.geoLocation2 }
      });
    }

    if (bankDetailsSettings) {
      this.setState({ initialStateOfBackDetailVerified: bankDetailsSettings ? bankDetailsSettings.verified : undefined });
      this.setState({ bankDetailsSettings: bankDetailsSettings });
    }

    this.setState({ onlineStoreSettings: object });
    this.setState({ deliverySettings: deliverySettings });
    this.setState({ generalSettings: generalSettings });

    this.initDeliverySettingsAndAreas(deliverySettings);
  }

  initDeliverySettingsAndAreas(deliverySettings) {
    var mapCenter = { lat: 0.0, lng: 0.0 };
    var areas = [];
    var rates = [];
    deliverySettings && deliverySettings.deliveryAreas && Object.keys(deliverySettings.deliveryAreas).forEach(key => {
      areas.push(deliverySettings.deliveryAreas[key]);
    });

    deliverySettings && deliverySettings.deliveryRates && Object.keys(deliverySettings.deliveryRates).forEach(key => {
      rates.push(deliverySettings.deliveryRates[key]);
    });

    if (areas.length > 0) {
      mapCenter = areas[0].geoLocation2;
    }
    var deliverySettingsObject = { ...this.state.deliverySettings, deliveryRates: rates, deliveryAreas: areas, center: mapCenter };
    this.setState({ deliverySettings: deliverySettingsObject });
  }

  onDateChange = (date, dateString) => this.setState({ expiryDate: moment(date).toDate() })
  handleInputDateChange = (field, value) => this.setState({ filter: { ...this.state.filter, [field]: value } })

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  onClickItem = (value) => {
    this.forceUpdate();
    if (value === 'General') {
      this.setState({ showAll: false, showGeneral: true, showDelevery: false, showBankDetails: false, showBranding: false });
    } else if (value === 'Delivery') {
      this.setState({ showAll: false, showDelevery: true, showGeneral: false, showBankDetails: false, showBranding: false });
    } else if (value === 'BankDetails') {
      this.setState({ showAll: false, showDelevery: false, showGeneral: false, showBankDetails: true, showBranding: false });
    } else if (value === 'Branding') {
      this.setState({ showAll: false, showDelevery: false, showGeneral: false, showBankDetails: false, showBranding: true });
    } else if (value === 'cancel') {
      this.setState({ showAll: true, showDelevery: false, showGeneral: false, showBankDetails: false, showBranding: false });
    }
  }

  onChangeInputValue = (field, value) => {
    const { business } = this.state;
    this.resetStatus();
    this.setState({ business: { ...business, [field]: value } });
  }

  onChangeCustomerDetails = (field, value) => {
    switch (field) {
      case 'customerName':
        this.setState({ customerName: value, customerNameStatus: this.getValidationStatus(field, value) });
        break;
      case 'contactEmail':
        this.setState({ contactEmail: value, contactEmailStatus: this.getValidationStatus(field, value) });
        break;
      default:
        break;
    }
  }

  onChangeInputOnlineStoreValue = (field, value) => {
    console.log(value)
    this.resetStatus();
    if (field === "onlineStoreTelephoneNumbers") {
      if (value.length === 0 || value.length > 0 && this.isValidPhoneNumber(value[value.length - 1])) {
        this.setState({ onlineStoreTelephoneNumbersStatus: "" })
        this.setState({ onlineStoreSettings: { ...this.state.onlineStoreSettings, [field]: value } });
      }
      else {
        this.setState({ onlineStoreTelephoneNumbersStatus: "error" })
      }
    } else {
      this.setState({ onlineStoreSettings: { ...this.state.onlineStoreSettings, [field]: value } });
    }
  }

  onChangeInputOnlineSocialLinksValue = (type, url) => {
    console.log(type, url)
    const { onlineStoreSettings } = this.state;
    this.resetStatus();
    let filteredSocialLinks = [];
    let socialLinks = onlineStoreSettings && onlineStoreSettings.socialLinks;
    filteredSocialLinks = socialLinks.filter(link => link.type !== type);
    filteredSocialLinks.push({ type: type, url: url });
    console.log(filteredSocialLinks)
    this.setState({ onlineStoreSettings: { ...this.state.onlineStoreSettings, socialLinks: filteredSocialLinks } });
  }

  handlePriceListChange = (value) => {
    this.resetStatus();
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ onlineStoreSettings: { ...this.state.onlineStoreSettings, onlineStorePriceList: priceList[0] } });
    }
  }

  onChangeGeneralSettings = (field, value) => {
    if (field === 'enablePickup') {
      this.setState({ generalSettings: { ...this.state.generalSettings, [field]: value } });
    } else if (field === 'allowOnlineOrders') {
      this.setState({ allowOnlineOrders: value, generalSettings: { ...this.state.generalSettings, [field]: value } });
    }
  }

  onChangeBankInputValue = (field, value, errorStatus) => {
    if (field === 'bank') {
      const bank = bankList.filter(bank => bank.BankCode === value)[0];
      this.setState({ bankDetailsSettings: { ...this.state.bankDetailsSettings, bankName: bank.BankName, bankCode: bank.BankCode, branchName: '', branchCode: '', verified: false }, [errorStatus]: '', isBankDetailUpdate: true });
    } else if (field === 'branch') {
      const branch = branchList.filter(branch => this.state.bankDetailsSettings && branch.BranchCode === value && branch.BankCode === this.state.bankDetailsSettings.bankCode)[0];
      this.setState({ bankDetailsSettings: { ...this.state.bankDetailsSettings, branchName: branch.BranchName, branchCode: branch.BranchCode, verified: false }, [errorStatus]: '', isBankDetailUpdate: true });
    } else {
      this.setState({ bankDetailsSettings: { ...this.state.bankDetailsSettings, [field]: value, verified: false }, [errorStatus]: '', isBankDetailUpdate: true });
    }
  }

  onGetUpdatedDeliveryData = (deliveryData) => {
    this.setState({ deliverySettings: deliveryData });
  }

  onGetUpdatedBusinessHourData = (businessHoursObject) => {
    this.updateBusinessHours(businessHoursObject);
  }

  onGetRemoveBusinessHourData = (businessHoursObject) => {
    this.deleteBusinessHours(businessHoursObject);
  }

  isEmpty = (name, value) => {
    if (value !== null) {
      if (value === undefined || value.length === 0) {
        switch (name) {
          case 'email':
            this.setState({ emailStatus: 'error' });
            break;
          case 'customerName':
            this.setState({ customerNameStatus: 'error' });
            break;
          case 'password':
            this.setState({ passwordStatus: 'error' });
            break;
          case 'name':
            this.setState({ nameStatus: 'error' });
            break;
          case 'address':
            this.setState({ addressStatus: 'error' });
            break;
          case 'city':
            this.setState({ cityStatus: 'error' });
            break;
          case 'country':
            this.setState({ countryStatus: 'error' });
            break;
          case 'timeZone':
            this.setState({ timeZoneStatus: 'error' });
            break;
          case 'postalCode':
            this.setState({ postalCodeStatus: 'error' });
            break;
          case 'phone':
            this.setState({ phoneStatus: 'error' });
            break;
          case 'code':
            this.setState({ codeStatus: 'error' });
            break;
          case 'verificationEmail':
            this.setState({ codeStatus: 'error' });
            break;
          case 'accountName':
            this.setState({ accountNameStatus: 'error' });
            break;
          case 'accountNumber':
            this.setState({ accountNumberStatus: 'error' });
            break;
          case 'bankName':
            this.setState({ bankNameStatus: 'error' });
            break;
          case 'branchName':
            this.setState({ branchNameStatus: 'error' });
            break;
          case 'onlineStoreAddress':
            this.setState({ onlineStoreAddressStatus: 'error' });
            break;
          case 'onlineStoreName':
            this.setState({ onlineStoreNameStatus: 'error' });
            break;
          case 'onlineStoreEmail':
            this.setState({ onlineStoreEmailStatus: 'error' });
            break;
          case 'onlineStoreTagLine':
            this.setState({ onlineStoreTagLineStatus: 'error' });
            break;
          case 'currency':
            this.setState({ currencyStatus: 'error' });
            break;
          default:
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  resetStatus = () => {
    this.setState({
      onlineStoreAddressStatus: '',
      onlineStoreTelephoneNumbersStatus: '',
      nameStatus: '',
      onlineStoreNameStatus: '',
      onlineStoreEmailStatus: '',
      onlineStoreTagLineStatus: '',
      addressStatus: '',
      cityStatus: '',
      countryStatus: '',
      timeZoneStatus: '',
      postalCodeStatus: '',
      phoneStatus: '',
      currencyStatus: '',
      instagramUrlStatus: '',
      facebookUrlStatus: '',
      linkdnUrlStatus: '',
      twitterrUrlStatus: ''
    });
  }
  isValidPhone = contactNumber => {
    if (!contactNumber || !contactNumber.match(/^\+?[0-9]{9,13}/g) || contactNumber.length < 7) {
      return false;
    } else if (contactNumber.startsWith('94')) {
      return contactNumber.length === 11;
    } else if (contactNumber.startsWith('0')) {
      return contactNumber.length === 10;
    } else {
      return contactNumber.length === 9;
    }
  }

  isValidPhoneNumber = contactNumber => {
    if (!contactNumber || !contactNumber.match(/^\+?[0-9]{9,13}/g) || contactNumber.length < 7) {
      return false;
    } else if (contactNumber.startsWith('+')) {
      return contactNumber.length === 12;
    } else if (contactNumber.startsWith('0094')) {
      return contactNumber.length === 13;
    } else if (contactNumber.startsWith('94')) {
      return contactNumber.length === 11;
    } else if (contactNumber.startsWith('0')) {
      return contactNumber.length === 10;
    } else {
      return contactNumber.length === 9;
    }
  }

  isValidEmail = email => {
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && email.length > 0 && emailExpression.test(email.toLowerCase());
  }

  isValidUrl = url => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(url);
  }
  getValidationStatus = (name, value) => {
    switch (name) {
      case 'email':
        if (this.isValidEmail(value)) {
          return 'success';
        } else {
          this.setState({ onlineStoreEmailStatus: 'error' });
          return 'error';
        }
      case 'phone':
        if (this.isValidPhone(value)) {
          return 'success';
        } else {
          this.setState({ phoneStatus: 'error' });
          return 'error';
        }
      case 'customerName':
        if (value && value.length > 0) {
          return 'success';
        } else {
          this.setState({ customerNameStatus: 'error' });
          return 'error';
        }
      case 'contactEmail':
        if (this.isValidEmail(value)) {
          return 'success';
        } else {
          this.setState({ contactEmailStatus: 'error' });
          return 'error';
        }
      case 'facebookUrlStatus':
        if (value === "" || (this.isValidUrl(value) && value.includes("facebook.com"))) {
          return 'success';
        } else {
          this.setState({ facebookUrlStatus: 'error' });
          return 'error';
        }
      case 'twitterrUrlStatus':
        if (value === "" || (this.isValidUrl(value) && value.includes("twitter.com"))) {
          return 'success';
        } else {
          this.setState({ twitterrUrlStatus: 'error' });
          return 'error';
        }
      case 'linkdnUrlStatus':
        if (value === "" || (this.isValidUrl(value) && value.includes("linkedin.com"))) {
          return 'success';
        } else {
          this.setState({ linkdnUrlStatus: 'error' });
          return 'error';
        }
      case 'instagramUrlStatus':
        if (value === "" || (this.isValidUrl(value) && value.includes("instagram.com"))) {
          return 'success';
        } else {
          this.setState({ instagramUrlStatus: 'error' });
          return 'error';
        }
      default:
        return null;
    }
  }

  updateBusinessDetails = (type) => {
    const { updateBusiness, companyId } = this.props;
    const { business, onlineStoreSettings, deliverySettings, bankDetailsSettings, generalSettings, customerName, contactEmail } = this.state;
    const isContactEmailUpdated = this.props.company.customer.contactEmail !== contactEmail;

    const facebookLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "facebook") : [];
    const twitterLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "twitter") : [];
    const instagramLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "instagram") : [];
    const linkdnLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "linkdn") : [];

    const facebookUrl = facebookLink && facebookLink.length > 0 ? facebookLink[0].url : "";
    const twitterUrl = twitterLink && twitterLink.length > 0 ? twitterLink[0].url : "";
    const instagramUrl = instagramLink && instagramLink.length > 0 ? instagramLink[0].url : "";
    const linkdnUrl = linkdnLink && linkdnLink.length > 0 ? linkdnLink[0].url : "";

    if (type === 'bank') {
      if (this.isEmpty('accountName', bankDetailsSettings.accountName) | this.isEmpty('accountNumber', bankDetailsSettings.accountNumber) | this.isEmpty('bankName', bankDetailsSettings.bankName) | this.isEmpty('branchName', bankDetailsSettings.branchName)) { return; }
    } else if (type === 'general') {
      if (
        // this.getValidationStatus('email', onlineStoreSettings.onlineStoreEmail) === 'error' |
        this.getValidationStatus('phone', business.telephone) === 'error' |
        // this.isEmpty('onlineStoreEmail', onlineStoreSettings.onlineStoreEmail) |
        // this.isEmpty('onlineStoreName', onlineStoreSettings.onlineStoreName) |
        // this.isEmpty('onlineStoreTagLine', onlineStoreSettings.onlineStoreTagLine) |
        //this.getValidationStatus('contactEmail', this.state.contactEmail) === 'error' 
        this.isEmpty('name', business.name) | this.isEmpty('address', business.address) |
        this.isEmpty('phone', business.telephone) | this.isEmpty('city', business.city) |
        this.isEmpty('postalCode', business.postalCode) | this.isEmpty('country', business.country) |
        this.isEmpty('currency', business.currency) |
        this.isEmpty('timeZone', business.timeZone) |
        this.getValidationStatus('customerName', this.state.customerName) === 'error'
      ) { return }
    } else if (type === 'delivery') {
      var deilveryRateWithErrors = deliverySettings && deliverySettings.deliveryRates && deliverySettings.deliveryRates.length > 0 && deliverySettings.deliveryRates.filter(rate => (rate.to < rate.from));
      if (deilveryRateWithErrors.length > 0) { return; }
    } else if (type === "onlineStoreSettings") {
      if (this.getValidationStatus('facebookUrlStatus', facebookUrl) === 'error' |
        this.getValidationStatus('twitterrUrlStatus', twitterUrl) === 'error' |
        this.getValidationStatus('instagramUrlStatus', instagramUrl) === 'error' |
        this.getValidationStatus('linkdnUrlStatus', linkdnUrl) === 'error') {
        return;
      }
    }

    // var deliverySettingsRequest = {...deliverySettings, deliveryAreas : deliveryAreas}
    const data = {
      name: business.name,
      address: business.address,
      city: business.city,
      postalCode: business.postalCode,
      country: business.country,
      telephone: business.telephone,
      currency: business.currency,
      logoImageSrc: business.logoImageSrc,
      onlineStoreSettings: onlineStoreSettings,
      deliverySettings: deliverySettings,
      bankAccountDetail: bankDetailsSettings,
      generalSettings: generalSettings,
      customerName: customerName,
      contactEmail: contactEmail,
      contactEmailUpdated: isContactEmailUpdated,
      timeZone: business.timeZone
    };

    this.setState({ loading: true });
    updateBusiness(companyId, data).then(response => {
      const { company } = response;
      this.setState({ loading: false });
      if (company) {
        showSuccessNotification('Success', '');
      } else {
        showErrorNotification('Failed.', 'Oops, Something went wrong.');
      }
    });
  }

  updateBusinessHours = (businessHoursObject) => {
    const { updateBusinessHours, fetchCompany } = this.props;
    const { companyId } = this.props;

    this.setState({ loading: true });
    updateBusinessHours(companyId, businessHoursObject.id, businessHoursObject).then(response => {
      const { company } = response;
      fetchCompany(companyId);
      this.setState({ loading: false });
      if (company) {
        showSuccessNotification('Success', '');
      } else {
        showErrorNotification('Failed.', 'Oops, Something went wrong.');
      }
    });
  }

  deleteBusinessHours = (businessHoursObject) => {
    const { deleteBusinessHours, fetchCompany } = this.props;
    const { companyId } = this.props;

    this.setState({ loading: true });
    deleteBusinessHours(companyId, businessHoursObject.id).then(response => {
      const { company } = response;
      fetchCompany(companyId);
      this.setState({ loading: false });
      if (company) {
        showSuccessNotification('Success', '');
      } else {
        showErrorNotification('Failed.', 'Oops, Something went wrong.');
      }
    });
  }

  importHolidayCalender = () => {
    const country = 'lk';
    this.setState({ loading: true });
    const { url, method, headers } = Api.googleCalender;
    const data = { method, headers };

    return fetch(url(country), data)
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ loading: false });
        if (responseJson.items) {
          this.showConfirm(responseJson.items);
          // showSuccessNotification('Success', "Verification code sent.");
        } else {
          showErrorNotification('Request failed.');
        }
      }).catch(error => {
        this.setState({ loading: false });
        return { error };
      });
  }

  showConfirm = (holidaysList) => {
    var THIS = this;
    confirm({
      title: 'Import holidays in Sri Lanka',
      content: 'There are ' + holidaysList.length + ' holiday in national Calender. This will take upto 5 - 10  minutes to complete. Do you want to proceed?',
      onOk() {
        THIS.saveAllHolidays(holidaysList);
      },
      onCancel() { }
    });
  }

  saveAllHolidays = (holidaysList) => {
    const { saveBusinessHours } = this.props;
    const { companyId } = this.props;

    var objList = [];
    holidaysList.forEach((holiday) => {
      var obj = {
        id: 'new',
        dayOfWeek: -1,
        businessOpeningHour: 0,
        businessClosingHour: 0,
        businessOpeningMinute: 0,
        businessClosingMinute: 0,
        deliveryStartingHour: 0,
        deliveryEndingHour: 0,
        deliveryStartingMinute: 0,
        deliveryEndingMinute: 0,
        delivering: false,
        businessOpened: false,
        date: holiday.start.date,
        description: holiday.summary
      };
      objList.push(obj);
    });
    this.setState({ loading: true });
    saveBusinessHours(companyId, objList).then(response => {
      const { company } = response;
      this.setState({ loading: false });
      if (company) {
        showSuccessNotification('Success', '');
      } else {
        showErrorNotification('Failed.', 'Oops, Something went wrong.');
      }
    });
  }

  handleSelectGoogleSuggestAddress = (address) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.onPlaceSelectedFromMap(latLng.lat, latLng.lng);
        this.setState({ googleSuggestAddress: address, geoLocation: { ...latLng } });
      })
      .catch(error => console.error('Error', error));
  }

  handleVerificationEmailSend = (companyId, contactEmail) => {
    const { sendEmailVerification, company } = this.props;
    const { customer } = company;
    const business = company && company.businesses && company.businesses['0'];

    this.setState({ loading: true });
    const verificationRequest = {
      contactEmail: contactEmail,
      userId: customer.userIdentity,
      username: customer.email,
      businessId: business.id
    };

    sendEmailVerification(companyId, verificationRequest).then(response => {
      if (response.customer) {
        showSuccessNotification('Verification Email Sent', 'Check your email to verify.');
        this.setState({ loading: false });
      } else {
        showErrorNotification('Failed to send verification email.', 'Please try again');
        this.setState({ loading: false });
      }
    });
  }

  onPlaceSelectedFromMap = (lattitude, longitude) => {
    this.setState({
      geoLocation: {
        lat: lattitude,
        lng: longitude
      },
      onlineStoreSettings: { ...this.state.onlineStoreSettings, onlineStoreLattitude: lattitude, onlineStoreLongitude: longitude }
    });
  }

  showConfirmUpdateBankDetails = () => {
    const THIS = this;
    confirm({
      title: 'Loosing verified status',
      content: 'Updating bank account details will remove verified status. Agent will need to contact you again to verify details. Do you want to proceed?',
      okText: 'Yes',
      cancelText: 'No',
      icon: <FiInfo size={25} style={{ color: '#ff4d25', float: 'left', marginRight: 14 }} />,
      onOk() {
        THIS.updateBusinessDetails('bank');
      },
      // okButtonProps: { style: { backgroundColor: '#498d05', borderColor: '#498d05' } },
      onCancel() {
      }
    });
  }

  renderAllSettings = () => {
    return (
      <Row gutter={5}>
        <Col lg={10} style={{ marginBottom: 15, marginRight: 30 }}>
          <SettingDesctiption title='General' description='Update your name, address and other public contact detils.' click={() => this.onClickItem('General')} />
          {/* <SettingDesctiption title='Branding' description='Customize how your online menu appears. Setup your logo and email message templates.' click={() => this.onClickItem('Branding')} /> */}

        </Col>
        <Col lg={10} style={{ marginBottom: 15 }}>
          {/*  <SettingDesctiption title='Bank accounts and scheduling' description='Add or edit bank accounts and manage payout schedules.' click={() => this.onClickItem('BankDetails')} />
         <SettingDesctiption title='Delivery' description='Setup and manage delivery rates, areas and times' click={() => this.onClickItem('Delivery')} />
          <SettingDesctiption title='Notifications' description='Manage notifications sent to our customers.' disabled />*/}
        </Col>
      </Row>
    );
  }

  renderPriceLists = () => {
    const { priceLists } = this.props;
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(<Option value={priceList.name} key={priceList}>{priceList.name}</Option>);
    });
    return priceListOptions;
  }

  renderGeneralSettings = () => {
    const { company, companyId, priceLists } = this.props;
    const { customerName, contactEmail = '', customerNameStatus, contactEmailStatus, contactEmailVerified, onlineStoreTelephoneNumbersStatus } = this.state;
    const business = company && company.businesses && company.businesses['0'];
    const customer = company && company.customer;
    const { name, address, telephone, city, country, postalCode, currency, logoImageSrc, timeZone } = this.state.business;
    const { onlineStoreEmail, onlineStoreName, onlineStoreTagLine, onlineStoreDescription, onlineStoreFoodTypes, onlineStorePriceRange, onlineStorePriceList, onlineStoreTelephoneNumbers } = this.state.onlineStoreSettings;
    const generalSettings = this.state.generalSettings;
    const filteredriceLists = priceLists && priceLists.length > 0 && priceLists.filter(pr => pr.name === 'Retail')
    const retailPriceList = filteredriceLists && filteredriceLists.length > 0 && filteredriceLists[0];
    const enablePickup = generalSettings && generalSettings.enablePickup ? generalSettings.enablePickup : false;
    console.log("enable Pickup", enablePickup)
    return (
      <div>
        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Business Details' description='Your customers will see this information' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card style={{ marginTop: 10 }} className='card-styles'>
              <p style={{ marginBottom: 5 }}>Online store name</p>
              <Form.Item colon={false} validateStatus={this.state.onlineStoreNameStatus}>
                <Input value={onlineStoreName !== undefined ? onlineStoreName : ''} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineStoreValue('onlineStoreName', e.target.value)} />
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Online store email</p>
              <Form.Item colon={false} validateStatus={this.state.onlineStoreEmailStatus}>
                <Input value={onlineStoreEmail !== undefined ? onlineStoreEmail : ''} style={{ width: '100%', marginBottom: 5 }} onChange={(e) => this.onChangeInputOnlineStoreValue('onlineStoreEmail', e.target.value)} />
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Tag line</p>
              <Form.Item colon={false} validateStatus={this.state.onlineStoreTagLineStatus}>
                <Input value={onlineStoreTagLine !== undefined ? onlineStoreTagLine : ''} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineStoreValue('onlineStoreTagLine', e.target.value)} />
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Description of your store</p>
              <Form.Item colon={false}>
                <TextArea rows={4} value={onlineStoreDescription !== undefined ? onlineStoreDescription : ''} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineStoreValue('onlineStoreDescription', e.target.value)} />
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Food types you offer at the online store</p>
              <Form.Item colon={false}>
                <Select
                  mode='tags'
                  value={onlineStoreFoodTypes || []}
                  placeholder='Please select'
                  onChange={value => this.onChangeInputOnlineStoreValue('onlineStoreFoodTypes', value)}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Average price (Rs.) range per head</p>
              <Form.Item colon={false}>
                <Select
                  value={onlineStorePriceRange}
                  style={{ width: '100%' }}
                  onChange={value => this.onChangeInputOnlineStoreValue('onlineStorePriceRange', value)}
                >
                  <Option value={1}>$ (Upto 250)</Option>
                  <Option value={2}>$$ (Above 250 to 750)</Option>
                  <Option value={3}>$$$ (Above 750 to 1500)</Option>
                  <Option value={4}>$$$$ (Above 1500)</Option>
                </Select>
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Online store Price List</p>
              <Form.Item colon={false}>
                <Select
                  dropdownMatchSelectWidth
                  style={{ width: '100%' }}
                  placeholder='Price list'
                  value={onlineStorePriceList ? onlineStorePriceList.name : retailPriceList.name}
                  onChange={this.handlePriceListChange}
                >
                  {this.renderPriceLists()}
                </Select>
              </Form.Item>

              <p style={{ marginBottom: 5 }}>Online store contact numbers</p>
              <p style={{ marginBottom: 5, fontWeight: '800' }}> Ex: 077xxxxxxx , 9471xxxxxxx, +9471xxxxxxx</p>
              <Form.Item colon={false} validateStatus={onlineStoreTelephoneNumbersStatus}>
                <Select
                  mode='tags'
                  value={onlineStoreTelephoneNumbers || []}
                  placeholder='Please select'
                  onChange={value => this.onChangeInputOnlineStoreValue('onlineStoreTelephoneNumbers', value)}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              {/* <div><p style={{ marginBottom: 5 }}>Enable store for pick up orders</p>
                <Switch checked={enablePickup} onChange={(checked) => this.onChangeGeneralSettings('enablePickup', checked)} />
              </div> */}

            </Card>
          </Col>

        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Logo' description='Your restaurant logo will appear online' />
            <p>Recommended size<br />(1920 x 550)px</p>
            <p>Maximum file size <br />1.43 MB</p>
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card>
              <ImageUploadContainer
                size={150}
                componentHeight={200}
                showAvatar={false}
                cropping={false}
                buttonAlignment='flex-end'
                folder={'/' + companyId + '/branding/logo'}
                src={logoImageSrc}
                onImageChange={this.handleLogoChange}
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Customer Details' description='Details about the registered customer' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            {customer && <Card style={{ marginTop: 10 }} className='card-styles'>
              <p style={{ marginBottom: 5 }}>Customer Name</p>
              <Form.Item colon={false} validateStatus={customerNameStatus}>
                <Input
                  value={customerName !== undefined ? customerName : ''} style={{ width: '100%' }}
                  onChange={(e) => this.onChangeCustomerDetails('customerName', e.target.value)}
                />
              </Form.Item>
              <p style={{ marginTop: 10, marginBottom: 5 }}>Contact Email</p>
              <Form.Item colon={false} validateStatus={contactEmailStatus}>
                <Input
                  value={contactEmail} suffix={contactEmailVerified
                    ? <span style={{ color: 'green', fontSize: 10 }}><BsCheckCircle
                      style={{ marginRight: 5 }}
                    /> Verified
                    </span>
                    : <span style={{ color: 'red', fontSize: 10 }}><IoIosCloseCircleOutline style={{ marginRight: 5 }} />Not Verified</span>}
                  style={{ width: '100%', marginBottom: 5 }}
                  onChange={(e) => this.onChangeCustomerDetails('contactEmail', e.target.value)}
                />
                {!contactEmailVerified && contactEmail === customer.contactEmail &&
                  <Button
                    type='link' style={{ padding: 0, float: 'right' }}
                    onClick={() => this.handleVerificationEmailSend(companyId, contactEmail)}
                  >Send Verification
                    Email
                  </Button>}
              </Form.Item>
            </Card>}
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption
              title='Business Address' description='This address will appear in your invoices. Delivery rates will be calculated from
            this address.'
            />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card style={{ marginTop: 10 }} className='card-styles'>
              <p style={{ marginBottom: 5 }}>Legal Name of business</p>
              <Form.Item colon={false} validateStatus={this.state.nameStatus}>
                <Input value={name !== undefined ? name : ''} style={{ width: '100%' }} onChange={(e) => this.onChangeInputValue('name', e.target.value)} />
              </Form.Item>
              <p style={{ marginTop: 10, marginBottom: 5 }}>Phone</p>
              <Form.Item colon={false} validateStatus={this.state.phoneStatus}>
                <Input value={telephone !== undefined ? telephone : ''} style={{ width: '100%', marginBottom: 5 }} onChange={(e) => this.onChangeInputValue('telephone', e.target.value)} />
              </Form.Item>
              <p style={{ marginTop: 10, marginBottom: 5 }}>Street</p>
              <Form.Item colon={false} validateStatus={this.state.addressStatus}>
                <Input value={address !== undefined ? address : ''} style={{ width: '100%', marginBottom: 5 }} onChange={(e) => this.onChangeInputValue('address', e.target.value)} />
              </Form.Item>
              <div>
                <Col lg={12} style={{ paddingRight: 10 }}>
                  <p style={{ marginTop: 10, marginBottom: 5 }}>City</p>
                  <Form.Item colon={false} validateStatus={this.state.cityStatus}>
                    <Input value={city !== undefined ? city : ''} style={{ width: '100%', marginBottom: 5 }} onChange={(e) => this.onChangeInputValue('city', e.target.value)} />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <p style={{ marginTop: 10, width: '100%', marginBottom: 5 }}>Postal Code</p>
                  <Form.Item colon={false} validateStatus={this.state.postalCodeStatus}>
                    <Input value={postalCode !== undefined ? postalCode : ''} style={{ width: '100%', marginBottom: 5 }} onChange={(e) => this.onChangeInputValue('postalCode', e.target.value)} />
                  </Form.Item>
                </Col>
              </div>
              <p style={{ marginTop: 10, marginBottom: 5 }}>Country</p>
              <Form.Item colon={false} validateStatus={this.state.countryStatus}>
                {/* <Input   value = {country} style={{ width: "100%", marginBottom: 5 }} onChange = {(e) =>this.onChangeInputValue("country" ,   e.target.value)}/> */}
                <Select
                  showSearch size='large' style={{ fontSize: 13, width: '100%' }} placeholder='Select Country' value={country !== undefined && country !== '' ? country : undefined} onChange={value => this.onChangeInputValue('country', value)}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSearch={this.onSearch}
                  showAction={['focus', 'click']}
                >
                  {countryList.map(element => <Option key={element} value={element}>{element}</Option>)}
                </Select>

              </Form.Item>
              <p style={{ marginTop: 10, marginBottom: 5 }}>Time Zone</p>
              <Form.Item colon={false} validateStatus={this.state.timeZoneStatus}>
                {/* <Input   value = {country} style={{ width: "100%", marginBottom: 5 }} onChange = {(e) =>this.onChangeInputValue("country" ,   e.target.value)}/> */}
                <Select
                  showSearch size='large' style={{ fontSize: 13, width: '100%' }} placeholder='Select Time Zone'
                  value={timeZone !== undefined && timeZone !== '' ? timeZone : undefined}
                  onChange={value => this.onChangeInputValue('timeZone', value)}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSearch={this.onSearch}
                  showAction={['focus', 'click']}
                >
                  {zoneList.map((zone, index) => <Option key={index} value={zone}>{zone}</Option>)}
                </Select>

              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Business currency' description='' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card style={{ marginTop: 10 }} className='card-styles'>
              <p style={{ marginBottom: 5 }}>Currency</p>
              {/* <Form.Item colon={false} validateStatus={this.state.currencyStatus}>
                <Input value={currency} style={{ width: '100%' }} onChange={(e) => this.onChangeInputValue('currency', e.target.value)} />
              </Form.Item> */}
              <Form.Item colon={false} validateStatus={this.state.currencyStatus}>
                <Select
                  showSearch size='large' style={{ fontSize: 13, width: '100%' }}
                  placeholder='Select Currency'
                  value={currency !== undefined && currency !== '' ? currency : undefined}
                  onChange={value => this.onChangeInputValue('currency', value)}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSearch={this.onSearch}
                  showAction={['focus', 'click']}
                >
                  {currencyList.map(element => <Option key={element} value={element}>{element}</Option>)}
                </Select>

              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }} />
          <Col lg={18} style={{ marginBottom: 15 }}>
            <div style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: 5 }} type='primary' onClick={() => this.updateBusinessDetails('general')} className='primary-button'>Update</Button>
              <Button type='default' onClick={() => this.onClickItem('cancel')} className='isoDeleteBtn'>Cancel</Button>
            </div>
          </Col>
        </Row>

        {/* {
          business.onlineStoreSettings && <Row>
            <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
              <SettingDesctiption
                title='Online Availability'
                description='Your restaurant’s online availability on fooder.lk'
              />
            </Col>
            <Col lg={18} style={{ marginBottom: 15 }}>
              <OnlineAvailability />
            </Col>
          </Row>
        } */}

        {/* <Row>
          <OpeningHoursContainer generalSettings={generalSettings} onUpdate={this.onGetUpdatedBusinessHourData} onDelete={this.onGetRemoveBusinessHourData} onImportCalender={this.importHolidayCalender} />
        </Row> */}
      </div >
    );
  }

  renderDeliverySettings = () => {
    const { geoLocation, onlineStoreSettings } = this.state;
    const { onlineStoreAddress } = onlineStoreSettings;
    return (
      <div>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Delivering From' description='Where your food will be delivering from.' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card style={{ marginTop: 10 }} className='card-styles'>
              <p style={{ marginBottom: 5 }}>Online store location (Let's put a marker to exact location)</p>
              <GeoInputSearch
                address={this.state.googleSuggestAddress}
                onChangeGoogleSuggestAddress={() => { }}
                onSelectGoogleSuggestAddress={this.handleSelectGoogleSuggestAddress}
              />
              {geoLocation && (
                <div style={{ height: 350 }}>
                  <GeoMap
                    isMarkerShown
                    zoom={15}
                    location={geoLocation}
                    height={350}
                    onMapClick={(event) => this.onPlaceSelectedFromMap(event.latLng.lat(), event.latLng.lng())}
                  />
                </div>
              )}
              <p style={{ marginBottom: 5, marginTop: 40 }}>Online store address.</p>
              {<Input style={{ width: '100%' }} value={onlineStoreAddress !== undefined ? onlineStoreAddress : ''} onChange={(e) => this.onChangeInputOnlineStoreValue('onlineStoreAddress', e.target.value)} />}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Delivery To' description='Whitch areas the business is delivering to.' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }} className='card-styles'>
            <Card style={{ marginTop: 10 }}>
              <DeliveryAreaCard deliveryData={this.state.deliverySettings} getData={(data) => this.onGetUpdatedDeliveryData(data)} />

              {/* <p style={{ marginBottom: 5 }}>Cities/Areas</p>
              <LocationSearchInput style={{ width : 570}} onSelectArea = {(address) => this.onSelectArea(address)} />

            <div>
                {deliveryAreas && deliveryAreas.length > 0 &&  deliveryAreas.map((tag, index) => {
                  const tagElem = (
                    <Tag key={tag}   visible={true} closable onClose={() =>this.onRemoveArea(index)}>
                      {tag.address}
                    </Tag>
                  );
                  return  tagElem;
                })}
            </div>
    */}
            </Card>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            {/* <tr>
                <td style={{ width: 25, height: 30 }}></td>
                <td>Warehouses</td>
                <td></td>
              </tr> */}

          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption
              title='Delivery Rates' description='Delivery fee will be automatically calculated based on the route distance to the
            destination address.'
            />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card style={{ marginTop: 10 }} className='card-styles'>
              <DeliveryRateCard business={this.state.business} deliveryData={this.state.deliverySettings} getData={(data) => this.onGetUpdatedDeliveryData(data)} />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }} />
          <Col lg={18} style={{ marginBottom: 15 }}>
            <div style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: 5 }} type='primary' onClick={() => this.updateBusinessDetails('delivery')} className='primary-button'>Update</Button>
              <Button type='default' onClick={() => this.onClickItem('cancel')} className='isoDeleteBtn'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderBankDetailsSettings = () => {
    const { accountName, accountNumber, bankCode, branchCode } = this.state.bankDetailsSettings;
    const arrayOfBranches = branchList.filter(branch => branch.BankCode === bankCode);
    return (
      <div>
        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Bank Account' description='Your income will be deposited to this bank account' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card style={{ marginTop: 10 }} className='card-styles'>
              <Col lg={24} style={{ textAlign: 'right' }}>
                {this.state.initialStateOfBackDetailVerified !== undefined && <Tag color={this.state.initialStateOfBackDetailVerified ? '#25a023' : '#ff2e2e'}>{this.state.initialStateOfBackDetailVerified ? 'Verified' : 'Unverified'}</Tag>}
              </Col>
              <p style={{ marginBottom: 5 }}>Account name</p>
              <Form.Item colon={false} validateStatus={this.state.accountNameStatus}>
                <Input value={accountName !== undefined ? accountName : ''} style={{ width: '100%' }} onChange={(e) => this.onChangeBankInputValue('accountName', e.target.value, 'accountNameStatus')} />
              </Form.Item>

              <p style={{ marginTop: 10, marginBottom: 5 }}>Account Number</p>
              <Form.Item colon={false} validateStatus={this.state.accountNumberStatus}>
                <Input value={accountNumber !== undefined ? accountNumber : ''} style={{ width: '100%', marginBottom: 5 }} onChange={(e) => this.onChangeBankInputValue('accountNumber', e.target.value, 'accountNumberStatus')} />
              </Form.Item>

              <p style={{ marginTop: 10, marginBottom: 5 }}>Bank</p>
              <Form.Item colon={false} validateStatus={this.state.bankNameStatus}>
                <Select
                  showSearch size='large' value={bankCode} style={{ fontSize: 13, width: '100%', marginBottom: 5 }} placeholder='Select bank' onChange={value => this.onChangeBankInputValue('bank', value, 'bankNameStatus')}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSearch={this.onSearch}
                >
                  {bankList.map(bank => <Option key={bank.BankCode} value={bank.BankCode}>{bank.BankName}</Option>)}
                </Select>
              </Form.Item>

              <p style={{ marginTop: 10, marginBottom: 5 }}>Branch</p>
              <Form.Item colon={false} validateStatus={this.state.branchNameStatus}>
                <Select
                  showSearch size='large' value={branchCode} style={{ fontSize: 13, width: '100%', marginBottom: 5 }} placeholder={!bankCode ? 'Select Bank' : 'Select Branch'} onChange={value => this.onChangeBankInputValue('branch', value, 'branchNameStatus')}
                  optionFilterProp='children'
                  disabled={!bankCode}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onSearch={this.onSearch}
                >
                  {arrayOfBranches.map(branch => <Option key={branch.BranchCode} value={branch.BranchCode}>{branch.BranchName}</Option>)}
                </Select>
              </Form.Item>

              {/* <input value = {branchName}  style={{ width: "100%", marginBottom: 5 }}  onChange = {(e) =>this.onChangeBankInputValue("branchName" ,   e.target.value)}/> */}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }} />
          <Col lg={18} style={{ marginBottom: 15 }}>
            <div style={{ textAlign: 'right' }}>
              {this.state.initialStateOfBackDetailVerified !== undefined && this.state.initialStateOfBackDetailVerified && <Button disabled={!this.state.isBankDetailUpdate} style={{ marginRight: 5 }} type='primary' onClick={() => this.showConfirmUpdateBankDetails()} className='primary-button'>Update</Button>}
              {!this.state.initialStateOfBackDetailVerified && <Button style={{ marginRight: 5 }} disabled={!this.state.isBankDetailUpdate} type='primary' onClick={() => this.updateBusinessDetails('bank')} className='primary-button'>Update</Button>}
              <Button type='default' onClick={() => this.onClickItem('cancel')} className='isoDeleteBtn'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  handleLogoChange = (src) => {
    this.setState({ business: { ...this.state.business, logoImageSrc: src } });
  }

  handleBannerChange = (src) => {
    this.setState({ onlineStoreSettings: { ...this.state.onlineStoreSettings, bannerSrc: src } });
  }

  handleThumbnailChange = (src) => {
    this.setState({ onlineStoreSettings: { ...this.state.onlineStoreSettings, thumbnailSrc: src } });
  }

  renderBrandingSettings = () => {
    const { companyId } = this.props;
    const { onlineStoreSettings, facebookUrlStatus, twitterrUrlStatus, linkdnUrlStatus, instagramUrlStatus } = this.state;

    const facebookLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "facebook") : [];
    const twitterLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "twitter") : [];
    const instagramLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "instagram") : [];
    const linkdnLink = onlineStoreSettings && onlineStoreSettings.socialLinks && onlineStoreSettings.socialLinks.length > 0 ? onlineStoreSettings.socialLinks.filter(link => link.type === "linkdn") : [];

    const facebookUrl = facebookLink && facebookLink.length > 0 ? facebookLink[0].url : undefined;
    const twitterUrl = twitterLink && twitterLink.length > 0 ? twitterLink[0].url : undefined;
    const instagramUrl = instagramLink && instagramLink.length > 0 ? instagramLink[0].url : undefined;
    const linkdnUrl = linkdnLink && linkdnLink.length > 0 ? linkdnLink[0].url : undefined;

    return (
      <div>
        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Thumbnail' description='Your restaurant thumbnail will appear online.' />
            <p>Recommended size <br />(536 x 262)px</p>
            <p>Maximum file size <br />1.43 MB</p>
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card>
              <ImageUploadContainer
                size={232}
                componentHeight={200}
                showAvatar={false}
                cropping={false}
                buttonAlignment='flex-end'
                folder={'/' + companyId + '/branding/thumbnail'}
                src={onlineStoreSettings && onlineStoreSettings.thumbnailSrc}
                onImageChange={this.handleThumbnailChange}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Banner' description='Your restaurant banner will appear online.' />
            <p>Recommended size <br />(1920 x 550)px</p>
            <p>Maximum file size <br />1.43 MB</p>
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card>
              <Row gutter={10} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <img src={headerTemplate} alt='header-sample' width='100%' style={{ marginBottom: 20 }} />
                  <h6 style={{ marginBottom: 20 }}>Download Templates</h6>
                </Col>
                <Col span={6}>
                  <a href={templatePSD} download='template.psd'>
                    <Button size='small' block>PSD</Button>
                  </a>
                </Col>
                <Col span={6}>
                  <a href={templateAI} download='template.ai'>
                    <Button size='small' block>AI</Button>
                  </a>
                </Col>
                <Col span={6}>
                  <a href={templatePDF} download='template.pdf'>
                    <Button size='small' block>PDF</Button>
                  </a>
                </Col>
                <Col span={6}>
                  <a href={templateJPEG} download='template.jpg'>
                    <Button size='small' block>JPEG</Button>
                  </a>
                </Col>
              </Row>

              <ImageUploadContainer
                size={150}
                componentHeight={200}
                showAvatar={false}
                cropping={false}
                buttonAlignment='flex-end'
                folder={'/' + companyId + '/branding/banner'}
                src={onlineStoreSettings && onlineStoreSettings.bannerSrc}
                onImageChange={this.handleBannerChange}
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }}>
            <SettingDesctiption title='Social Media Links' description='Social Media Marketing for Restaurant.' />
          </Col>
          <Col lg={18} style={{ marginBottom: 15 }}>
            <Card>
              <Row gutter={10} style={{ marginBottom: 20 }}>
                <Col span={24} >
                  <p>Specify your social link here</p>
                  <Col style={{ display: 'flex', marginBottom: 10 }}>
                    <img width="30" height="30" src={facebookIcon} alt="" style={{ marginRight: 30 }} />
                    <Form.Item style={{ width: '100%' }} colon={false} validateStatus={facebookUrlStatus}>
                      <Input value={facebookUrl} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineSocialLinksValue('facebook', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col style={{ display: 'flex', marginBottom: 10 }}>
                    <img width="30" height="30" src={linkdnIcon} alt="" style={{ marginRight: 30 }} />
                    <Form.Item style={{ width: '100%' }} colon={false} validateStatus={linkdnUrlStatus}>
                      <Input value={linkdnUrl} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineSocialLinksValue('linkdn', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col style={{ display: 'flex', marginBottom: 10 }}>
                    <img width="30" height="30" src={twitterIcon} alt="" style={{ marginRight: 30 }} />
                    <Form.Item style={{ width: '100%' }} colon={false} validateStatus={twitterrUrlStatus}>
                      <Input value={twitterUrl} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineSocialLinksValue('twitter', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col style={{ display: 'flex', marginBottom: 10 }}>
                    <img width="30" height="30" src={instagramIcon} alt="" style={{ marginRight: 30 }} />
                    <Form.Item style={{ width: '100%' }} colon={false} validateStatus={instagramUrlStatus}>
                      <Input value={instagramUrl} style={{ width: '100%' }} onChange={(e) => this.onChangeInputOnlineSocialLinksValue('instagram', e.target.value)} />
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5} style={{ marginBottom: 15, marginRight: 30 }} />
          <Col lg={18} style={{ marginBottom: 15 }}>
            <div style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: 5 }} type='primary' onClick={() => this.updateBusinessDetails('onlineStoreSettings')} className='primary-button'>Update</Button>
              <Button type='default' onClick={() => this.onClickItem('cancel')} className='isoDeleteBtn'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, showGeneral, showAll, showDelevery, showBankDetails, showBranding } = this.state;
    return (
      <Spin spinning={loading}>

        <div style={{ margin: 5 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {!showAll && showGeneral && <p style={{ fontSize: 20 }}>General Settings</p>}
            {!showAll && showDelevery && <p style={{ fontSize: 20 }}>Delivery Settings</p>}
            {!showAll && showBankDetails && <p style={{ fontSize: 20 }}>Bank Account Details Settings</p>}
            {!showAll && showBranding && <p style={{ fontSize: 20 }}>Branding Settings</p>}
            {!showAll && <Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.onClickItem('cancel')}>Back</Button>}
          </div>

          {showAll && this.renderAllSettings()}
          {showGeneral && this.renderGeneralSettings()}
          {showDelevery && this.renderDeliverySettings()}
          {showBankDetails && this.renderBankDetailsSettings()}
          {showBranding && this.renderBrandingSettings()}
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return ({
    companyId,
    company: getCompanyById(state, companyId),
    priceLists: getPriceLists(state),
  });
};

export default withRouter(connect(mapStateToProps, { fetchCompany, updateBusiness, updateBusinessHours, deleteBusinessHours, saveBusinessHours, sendEmailVerification, fetchPriceLists })(Settings));
