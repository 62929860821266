import React, { Component } from 'react';
import { Avatar, Col, Row } from 'antd';
import { FaTrash, FaEdit } from 'react-icons/all';

class ModifierItem extends Component {
  render () {
    const { modifier, onRemove, onSelectItem, index } = this.props;
    return (
      <Row className='modifier-item-container'>
        <Col span={3}>
          {modifier.item && <Avatar size={50} src={modifier.item.imageSrc} />}
        </Col>
        <Col span={11}>
          {
            !modifier.hasOptions && modifier.item &&
              <span style={{ fontSize: 14, fontWeight: 600 }}>{modifier.item.name}</span>
          }
          {
            modifier.options &&
            modifier.options.length > 0 &&
            modifier.options[0].item &&
              <span style={{ fontSize: 14, fontWeight: 600 }}>{'(G)' + modifier.commonName + '-' + modifier.options[0].item.name}</span>
          }<br />
          {/* <p style={{ fontSize: 12, fontWeight: 400, lineHeight: '15px' }}>{modifier.commonName}</p> */}
        </Col>
        <Col span={4} className='text-center'>
          {modifier.quantity}
        </Col>
        <Col span={3} className='text-right'>
          {modifier.uom}
        </Col>
        <Col span={3} className='text-right'>
          <FaEdit style={{ color: 'red' }} className='incy-button' onClick={(e) => onSelectItem(e, modifier, index)} />
          <FaTrash style={{ color: 'red', marginLeft: 7 }} className='incy-button' onClick={(e) => onRemove(e, modifier)} />
        </Col>

      </Row>
    );
  }
}

export default ModifierItem;
