import React from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from 'react-google-maps';
const _ = require('lodash');

const google = window.google;

export const GeoMap = compose(
  withProps(props => ({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry&key=AIzaSyCd-XlL5mgW29M5ihIJ3cMXooHi6S3Qhkg',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: props.height }} />,
    mapElement: <div style={{ height: '100%' }} />
  })),
  lifecycle({
    componentWillMount () {
      const refs = {};

      this.setState({
        bounds: null,
        center: {
          lat: 41.9, lng: -87.624
        },
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter()
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          const formattedAddress = places && places[0] && places[0].formatted_address && places[0].formatted_address;
          this.setState({
            center: nextCenter,
            markers: nextMarkers
          }, () => this.props.onUpdateLocation(this.state.markers, formattedAddress));
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap defaultZoom={6} zoom={props.zoom} center={props.location || { lat: 6.9271, lng: 79.8612 }} defaultCenter={props.location || { lat: 6.9271, lng: 79.8612 }} onClick={props.onMapClick}>
      {props.isMarkerShown && <Marker position={props.location || { lat: 6.9271, lng: 79.8612 }} />}
      {props.circles && props.circles.length > 0 && props.circles.map(circle => (<Circle center={circle.center} radius={circle.radius * 1000} options={circle.options} />))}
    </GoogleMap>
  );
});
