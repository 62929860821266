import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { isAllowedAllBranches, getBranches, getSelectedBranchId } from '../../reducers';
import { setSelectedBranch } from '../../actions/companies';

const { Option } = Select;

class BranchSelector extends Component {

  render() {
    const { showAllBranches, branches, setSelectedBranch, selectedBranchId, onBanchChange } = this.props;

    return (
      <Select
        dropdownMatchSelectWidth
        style={{ width: '100%' }}
        placeholder='Branches'
        value={selectedBranchId}
        onChange={value => {
          setSelectedBranch(value);
          onBanchChange(value)
        }}>
        {showAllBranches && <Option value={0} key={0}>All Branches</Option>}
        {branches.map((branch, index) =>
          <Option value={branch.id} key={branch.id}>{index + 1}. {branch.city}</Option>)}
      </Select>
    );
  }
}

const mapStateToProps = state => {
  return {
    showAllBranches: isAllowedAllBranches(state),
    branches: getBranches(state),
    selectedBranchId: getSelectedBranchId(state)
  };
};

export default connect(mapStateToProps, { setSelectedBranch })(BranchSelector);
