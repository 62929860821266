import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Input, Row, Select, Checkbox, TreeSelect, List, Button, Spin, Icon, Modal } from 'antd';
import SideBar from '../../components/SideBar';
import ItemRow from './ItemRow';
import { fetchItems, uploadBulkData, deleteItems } from '../../actions/items';
import { getCompanyById, getItems, getAuthenticity, getCategories, getPriceLists, getCategoryTree } from '../../reducers';
import { FiDownload, FiPlusCircle } from 'react-icons/all';
import { Link } from 'react-router-dom';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import { fileToJson } from '../../api/resources';
import DeletedInfo from './DeletedInfo';
import ImportMenuModal from './Component/ImportMenuModal';
import { showLoadingMessage } from '../../components/Message';
import CsvDownloader from './Component/CsvDownloader';
//import { FaFileImport, FaDownload } from 'react-icons/all';

const { Search } = Input;
const { Option } = Select;

class AllItems extends Component {
  state = {
    selectedPriceList: undefined,
    loading: false,
    submitting: false,
    filter: {
      text: undefined,
      categories: undefined,
      onlineAvailable: false,
      inStock: false,
    },

    itemChecked: false,
    checkedItems: [],
    deletedInfo: undefined,
    visibleConfirmationModal: false,
    deleteItemUsesAsIngredients: false,
    expandedRowKeys: [],
    visibleMenuImport: false,
    isDownlaodAvaialable: true
  }

  componentDidMount = () => {

    const { user: { userId: companyId } } = this.props.authData;
    if (this.props.items && this.props.items.length > 0) {
      this.showLoadingMessage();
      this.props.fetchItems(companyId).then((response) => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ loading: true });
      this.props.fetchItems(companyId).then((response) => {
        this.setState({ loading: false });
      });
    }
  }
  
  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { priceLists } = nextProps;
    if (priceLists.length > 0) {
      if (!this.state.selectedPriceList) { this.setState({ selectedPriceList: priceLists[0] }); }
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Items.');
  }

  renderPriceLists = () => {
    const { priceLists } = this.props;
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(<Option value={priceList.name} key={priceList}>{priceList.name}</Option>);
    });
    return priceListOptions;
  }

  handlePriceListChange = (value) => {
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ selectedPriceList: priceList[0] });
    }
  }

  getCategoryTreeNodes = (categoryTree, keyOfValue = 'id') => {
    const categoriesArray = [];
    if (categoryTree && categoryTree.length !== undefined) {
      categoryTree.forEach(category => {
        if (category.id !== 'defaultMenu') {
          const obj = {
            title: category.name,
            key: category.id,
            value: keyOfValue === 'name' ? category.name : category.id,
            children: category.categories && category.categories.length > 0 && this.getCategoryTreeNodes(category.categories, keyOfValue)
          };
          categoriesArray.push(obj);
        }
      });
    } else {
      Object.keys(categoryTree).forEach(key => {
        if (categoryTree[key].id !== 'defaultMenu') {
          const obj = {
            title: categoryTree[key].name,
            key: categoryTree[key].id,
            value: keyOfValue === 'name' ? categoryTree[key].name : categoryTree[key].id,
            children: categoryTree[key].categories && Object.keys(categoryTree[key].categories).length > 0 && this.getCategoryTreeNodes(categoryTree[key].categories, keyOfValue)
          };
          categoriesArray.push(obj);
        }
      });
    }

    return categoriesArray;
  }

  handleCategoryChange = (value) => {
    if (value === 'All') {
      this.setState({ filter: { ...this.state.filter, categories: undefined } });
    } else {
      this.setState({ filter: { ...this.state.filter, categories: value } });
    }
  }

  handleInputCheckChange = (e, field) => {
    this.setState({ filter: { ...this.state.filter, [field]: e.target.checked } });
  }

  handleDeleteItems = () => {
    const { companyId } = this.props;
    const { checkedItems, deleteItemUsesAsIngredients } = this.state;
    var checkedIds = [];
    checkedItems.forEach((item) => {
      checkedIds.push(item.id);
    });

    const request = {
      itemIds: checkedIds,
      deleteItemUsesAsIngredients: deleteItemUsesAsIngredients
    };

    this.setState({ submitting: true, visibleConfirmationModal: false });
    this.props.deleteItems(companyId, request).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed deleting items', error);
        this.setState({ submitting: false });
      } else {
        const { records } = response;
        this.props.fetchItems(companyId).then(() => {
          this.setState({ submitting: false, deletedInfo: records, checkedItems: [], itemChecked: false, deleteItemUsesAsIngredients: false });
          this.showDeleteInfoModal();
        });
      }
    });
  }

  handleCheckedAll = (e, items) => {
    if (e.target.checked) {
      this.setState({ checkedItems: items });
    } else {
      this.setState({ checkedItems: [] });
    }
    this.setState({ itemChecked: e.target.checked });
  }

  handleChangeCheckedItem = (checked, item, items) => {
    const { checkedItems } = this.state;
    var selectedtemsArray = [];

    if (checked) {
      selectedtemsArray = [...this.state.checkedItems, item];
      this.setState({ checkedItems: selectedtemsArray });
    } else {
      selectedtemsArray = checkedItems.length > 0 && checkedItems.filter(_item => _item.id !== item.id);
      this.setState({ checkedItems: selectedtemsArray });
    }

    if (selectedtemsArray.length !== items.length) {
      this.setState({ itemChecked: false });
    } else {
      this.setState({ itemChecked: true });
    }
  }

  handleFileChosen = file => {
    const { user: { userId: companyId } } = this.props.authData;
    const fileName = 'temp_' + new Date().getTime() + '_' + companyId;
    if (file.name.includes('.csv')) {
      this.setState({ submitting: true, visibleMenuImport: false });
      fileToJson(file, 'temp/', fileName).then((response) => {
        if (response.error) {
          this.setState({ submitting: false });
          showErrorNotification('error', 'Import Failed. ' + response.error);
        } else {
          const { responseData } = response;
          this.props.uploadBulkData(companyId, { data: responseData }).then(response => {
            const { error } = response;
            if (error) {
              showErrorNotification('error', 'Import Failed. ' + error);
              this.setState({ submitting: false });
            } else {
              this.props.fetchItems(companyId).then(() => {
                // this.props.fetchCategories(companyId).then(() => {
                //   this.props.fetchPriceLists(companyId).then(() => {
                this.setState({ submitting: false });
                showSuccessNotification('success', 'Items imported successfully');
              });
              //   });
              // });
            }
          });
        }
      }).catch(error => {
        showErrorNotification('error', 'Upload failed: ' + error);
        this.setState({ submitting: false });
      });
    } else {
      showErrorNotification('Error', 'Unsupported file format...');
    }
  }

  handleFileChosenRemove = () => {
    this.setState({ resources: [], invalidResources: [], readPercentage: undefined });
  }

  getFilteredItemsList = () => {
    const { items } = this.props;
    const { filter } = this.state;
    let filteredItemsList = items;

    if (filter && filter.text) {
      filteredItemsList = items.filter(item => {
        const searchTerm = filter.text;
        // searchTerm = searchTerm.replace(/-/g, "");
        return item && item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    if (filter && filter.categories) {
      filteredItemsList = filteredItemsList.filter(item => {
        return this.getFilteredItemsByCategory(item, filter.categories);
      });
    }

    if (filter && filter.onlineAvailable) {
      filteredItemsList = filteredItemsList.filter(item => {
        return (item.displayInOnlineMenu2 && item.displayInOnlineMenu2 === filter.onlineAvailable);
      });
    }

    if (filter && filter.inStock) {
      filteredItemsList = filteredItemsList.filter(item => {
        return (item.onlineStockAvailable && item.onlineStockAvailable === filter.inStock);
      });
    }

    return filteredItemsList;
  }

  getFilteredItemsByCategory = (item, categories) => {
    let categoryAvailable = false;
    item && item.allCategories && item.allCategories.length > 0 && item.allCategories.forEach(
      category => {
        // for (var i = 0; i < categories.length; i++) {
        if (category.id.toLowerCase() === (categories).toLowerCase()) {
          categoryAvailable = true;
        }
        // }
      }
    );

    return categoryAvailable;
  }

  handleInputTextChange = value => this.setState({ filter: { ...this.state.filter, text: value } })

  renderItemList = filteredItems => {
    const { selectedPriceList, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const itemList = [];
    filteredItems && filteredItems.forEach(item => {
      itemList.push(<ItemRow business={business} itemDetails={item} selectedPriceList={selectedPriceList} />);
    });
    return itemList;
  }

  hideDeleteInfoModal = () => {
    this.setState({ showDeleteInfoModal: false });
  }

  showDeleteInfoModal = () => {
    this.setState({ showDeleteInfoModal: true });
  }

  onExpandRow = (rowKey) => {
    var expandedRowKeysArray = [...this.state.expandedRowKeys];
    var index = expandedRowKeysArray.indexOf(rowKey);
    if (index === -1) expandedRowKeysArray.push(rowKey);

   
    this.setState({ expandedRowKeys: expandedRowKeysArray });
  }

  onCollapesRow = (rowKey) => {
    var expandedRowKeysArray = [...this.state.expandedRowKeys];
    var index = expandedRowKeysArray.indexOf(rowKey);
    if (index !== -1) expandedRowKeysArray.splice(index, 1);
    console.log('collapes', expandedRowKeysArray);
    this.setState({ expandedRowKeys: expandedRowKeysArray });
  }

  onCancelMenuImportModal = () => this.setState({ visibleMenuImport: false })

  renderItemPriceDialog = () => {
    return (
      <Modal
        visible={this.state.visibleConfirmationModal}
        title='Delete Items'
        okText='Yes'
        cancelText='No'
        onCancel={() => this.setState({ visibleConfirmationModal: false })}
        onOk={() => this.handleDeleteItems()}
      >
        <p>Do you want to delete selected Item(s)?</p>
      </Modal>
    );
  }

  render() {
    const { categoryTree, companyId, categories, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { selectedPriceList, filter, loading, submitting, checkedItems, deletedInfo } = this.state;
    const categoryTreeNodesList = this.getCategoryTreeNodes(categoryTree, 'id');
    const filteredItems = this.getFilteredItemsList() ? this.getFilteredItemsList() : [];
    const filterActive = selectedPriceList && filter.category && filter.text && filter.onlineAvailable && filter.inStock;

    return (
      <div className='container'>
        {business && !business.active && <p style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, color: 'red' }}>This business is no longer active.</p>}
        {this.renderItemPriceDialog()}
        {this.state.showDeleteInfoModal && <DeletedInfo records={deletedInfo} onCancel={this.hideDeleteInfoModal} visible={this.state.showDeleteInfoModal} />}
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <ImportMenuModal visible={this.state.visibleMenuImport} onCancel={this.onCancelMenuImportModal} handleFileChosen={this.handleFileChosen} handleFileChosenRemove={this.handleFileChosenRemove} />

            <Col lg={5} md={5} sm={24} xs={24}>
              <h3 style={{ fontWeight: 400, fontSize: 24 }}>All Items</h3>
            </Col>
            <Col lg={19} md={19} className='text-right'>
              <Link to='/categories/new'>
                <Button type='primary' className='btn-orange primary-button' style={{ marginRight: 5 }}><FiPlusCircle style={{ marginRight: 5 }} /> Category</Button>
              </Link>
              <Link to='/items/new'>
                <Button type='primary' className='btn-orange primary-button'><FiPlusCircle style={{ marginRight: 5 }} /> Item</Button>
              </Link>
              <Button type='primary' onClick={() => this.setState({ visibleMenuImport: true })} className='btn-orange primary-button' style={{ marginRight: 5, marginLeft: 5 }}>
                <FiDownload style={{ marginRight: 5 }} /> Import
              </Button>
              <CsvDownloader companyId={companyId} company={company} />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card className='card-styles'>
                <Col lg={8} md={8}>
                  <Search placeholder='Search by name' onChange={(e) => this.handleInputTextChange(e.target.value)} />
                </Col>
                <Col lg={6} md={6}>
                  <Select
                    dropdownMatchSelectWidth
                    style={{ width: '100%' }}
                    placeholder='Price list'
                    value={selectedPriceList && selectedPriceList.name}
                    onChange={this.handlePriceListChange}
                  >
                    {this.renderPriceLists()}
                  </Select>
                </Col>
                <Col lg={6} md={6}>
                  <TreeSelect
                    allowClear
                    dropdownMatchSelectWidth
                    style={{ width: '100%' }}
                    value={filter.categories}
                    placeholder='Category'
                    treeData={categoryTreeNodesList}
                    onChange={this.handleCategoryChange}
                  />
                </Col>
                <Col lg={4} md={4}>
                  {/* <Checkbox
                    value={filter.onlineAvailable}
                    onChange={(e) => this.handleInputCheckChange(e, "onlineAvailable")}
                  >Online Items
                  </Checkbox> */}
                  <Checkbox
                    style={{ marginLeft: 0 }}
                    value={filter.onlineStockAvailable}
                    onChange={(e) => this.handleInputCheckChange(e, "inStock")}
                  >In Stock
                  </Checkbox>
                </Col>
                <Spin spinning={(filteredItems.length === 0 && this.state.loading) || submitting}>

                  {checkedItems && checkedItems.length > 0 &&
                    <Col lg={24} md={24} className='all-item-bulk-actions'>
                      <Col lg={6}>{checkedItems.length} {checkedItems.length === 1 ? 'item selected.' : 'items selected.'} </Col>
                      <Col lg={14}>
                        {/* TODO hide this for temporary <Checkbox onChange={(e) => { this.setState({ deleteItemUsesAsIngredients: e.target.checked }); }}>Delete even if used as an ingredient</Checkbox> */}
                      </Col>
                      <Col lg={4} className='text-right'><Button type='danger' style={{ marginRight: 5 }} onClick={() => this.setState({ visibleConfirmationModal: true })}> Delete</Button></Col>
                    </Col>}
                  <Col lg={24} md={24} style={{ padding: '5px 0px' }}>
                    <Row className='item-table-header' style={{ marginLeft: 5, marginRight: 5, marginBottom: 15 }}>
                      <Col lg={1}><Checkbox checked={this.state.itemChecked} onChange={(e) => this.handleCheckedAll(e, filteredItems)} /></Col>
                      <Col lg={2}>Image</Col>
                      <Col lg={6}>Name</Col>
                      <Col className='text-right' lg={4}>Price</Col>
                      {<Col className='text-right' lg={2}>Stock</Col>}
                      {/* <Col className='text-center' lg={3}>Online</Col> */}
                      <Col className='text-left' lg={6}>Categories</Col>
                      {/* <Col className='text-center' lg={4}>Is Meal</Col> */}
                      <Col className='text-right' lg={3}>Actions</Col>
                    </Row>
                    <List
                      itemLayout='horizontal'
                      dataSource={filteredItems}
                      grid={{ gutter: 5 }}
                      loading={loading && filteredItems.length === 0 && filterActive}
                      size='small'
                      pagination={{
                        size: 'small',
                        pageSize: 15,
                        onChange: page => {
                          console.log(page);
                        }
                      }}
                      renderItem={item => (
                        <List.Item rowKey={item.id}>
                          <ItemRow
                            business={business}
                            itemDetails={item} selectedPriceList={selectedPriceList} companyId={companyId} categories={categories} categoryTree={categoryTree} expandedRowkeys={this.state.expandedRowKeys} onExpandRow={this.onExpandRow} onCollapesRow={this.onCollapesRow}
                            checkedItems={checkedItems} items={filteredItems} onChangeCheckedItem={(checked, item) => this.handleChangeCheckedItem(checked, item, filteredItems)}
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Spin>
              </Card>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return ({
    companyId: companyId,
    company: getCompanyById(state, companyId),
    items: getItems(state),
    authData: getAuthenticity(state),
    categories: getCategories(state),
    priceLists: getPriceLists(state),
    categoryTree: getCategoryTree(state)
  });
};
export default connect(mapStateToProps, { fetchItems, uploadBulkData, deleteItems })(AllItems);
