import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_PRICE_LISTS': {
      const newState = {};
      action.priceLists && action.priceLists.forEach(priceList => { newState[priceList.id] = priceList; });
      return newState;
    }
    case 'FETCH_PRICE_LIST': {
      return { ...state, [action.priceList.id]: action.priceList };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_PRICE_LISTS': {
      const newState = [];
      action.priceLists && action.priceLists.forEach(priceList => {
        if (!newState.includes(priceList.id)) {
          newState.push(priceList.id);
        }
      });
      return newState;
    }
    case 'FETCH_PRICE_LIST': {
      if (state.includes(action.priceList.id)) {
        return state;
      } else {
        return [...state, action.priceList.id];
      }
    }
    default:
      return state;
  }
};

const priceLists = combineReducers({
  byId,
  ids
});

export default priceLists;

export const getPriceLists = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};
