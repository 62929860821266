import { Button, Popconfirm, Row } from "antd";
import React, { Component } from 'react';
import ItemList from "./components/ItemList";

import InventoryList from './InventoryList';

class InventoryListContainer extends Component {

  render() {
    const {
      companyId,
      selectedRowKeys,
      selectedRowKeysInventoryItems,
      invoicesLoading = false,
      handleOnClickSelectInvoices,
      showTotals = false,
      showOutstandingInvoices = false,
      enableRowSelection = false,
      handleOnClickDelete,
      query,
      onGenerateInvoiceClick,
      handlePaginationChange,
      PAGE_SIZE,
      onCloseItemList,
      onItemSearch,
      onSelectItem,
      visibleItemList,
      onClickAdd,
      handleSelectInventoryItem,
      onClickItemDelete,
      onEditInventoryItem

    } = this.props;

    return (
      <div style={{ margin: 5 }}>
        {
          selectedRowKeys && selectedRowKeys.length > 0 &&
          <Row style={{ padding: 10, textAlign: 'end', backgroundColor: '#fafafa' }}>
            <Button className={'tableButtonSpecial'} size={'small'} onClick={onGenerateInvoiceClick}>Generate Invoices</Button>
          </Row>
        }

        <ItemList
          type={'ALL'}
          selectedRowKeys={selectedRowKeys}
          itemFilterText={'Search Items'}
          visible={visibleItemList}
          onCloseList={onCloseItemList}
          onClickAdd={onClickAdd}
          onItemSearch={onItemSearch}

          //handleItemEditableCellChangeSave={this.handleEditableCellChangeSave}
          onSelectItem={(itemListType, object) => onSelectItem(itemListType, object)}
        />

        <InventoryList
          showOutstandingInvoices={showOutstandingInvoices}
          companyId={companyId}
          selectedRowKeys={selectedRowKeysInventoryItems}
          enableRowSelection={enableRowSelection}
          invoicesLoading={invoicesLoading}
          onSelectInventoryItem={handleSelectInventoryItem}
          onClickDelete={handleOnClickDelete}
          showTotals={showTotals}
          handlePaginationChange={handlePaginationChange}
          PAGE_SIZE={PAGE_SIZE}
          onClickItemDelete = {onClickItemDelete}
          onEditInventoryItem = {onEditInventoryItem}
          query={query} />
      </div>
    )
  }
}

export default InventoryListContainer;