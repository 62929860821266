import React, { } from 'react'
import { Row, Col, Table, Spin } from 'antd'
import moment from 'moment'

import ViewTable from './ViewTable'

import './salesReports.css'

const salesSummaryTableColumns = [
  {
    title: '',
    dataIndex: 'item',
    className: 'column-left-align text-bold',
  },
  {
    title: '',
    dataIndex: 'value',
    className: 'column-right-align',
  },
]

const receiptsSummaryTableColumns = [
  {
    title: '',
    dataIndex: 'item',
    className: 'column-left-align text-bold',
  },
  {
    title: '',
    dataIndex: 'value',
    className: 'column-right-align',
    summable: true,
  },
]

const ordersSummaryTableColumns = [
  {
    title: 'Status',
    dataIndex: 'status',
    className: 'column-left-align text-bold primary-box',
  },
  {
    title: 'Count',
    dataIndex: 'count',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    className: 'column-money',
  },
]

const locationSummaryTableColumns = [
  {
    title: 'Location',
    dataIndex: 'location',
    className: 'column-left-align text-bold primary-box',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    className: 'column-money',
  },
]

const userSummaryTableColumns = [
  {
    title: 'User',
    dataIndex: 'user',
    className: 'column-left-align text-bold primary-box',
  },
  {
    title: 'Total Sales',
    dataIndex: 'totalSales',
  },
  {
    title: 'No. Of Orders',
    dataIndex: 'orderCounts',
    className: 'column-right-align',
  },
  {
    title: 'Service Charge',
    dataIndex: 'serviceCharge',
    className: 'column-money',
  },
  {
    title: 'Orders',
    children: [
      {
        title: 'New',
        dataIndex: 'new',
        className: 'column-money',
      },
      {
        title: 'Confirmed',
        dataIndex: 'confirmed',
        className: 'column-money',
      },
      {
        title: 'Completed',
        dataIndex: 'completed',
        className: 'column-money',
      },
      {
        title: 'Cancelled',
        dataIndex: 'cancelled',
        className: 'column-money',
      },
    ]
  },
]

const cashierSummaryTableColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    className: 'column-left-align',
    render: text => text ? moment(text).format('DD/MM/YYYY') : '',
  },
  {
    title: 'User Name',
    dataIndex: 'userName',
    className: 'column-left-align',
  },
  {
    title: 'Opening Balance',
    dataIndex: 'openingbalance',
    className: 'column-money',
    render: text => parseFloat(text).toFixed(2)
  },
  {
    title: 'System Closing Balance',
    dataIndex: 'systemClosingBalance',
    className: 'column-money',
    render: text => parseFloat(text).toFixed(2)
  },
  {
    title: 'Actual Balance',
    dataIndex: 'actualBalance',
    className: 'column-money',
    render: text => parseFloat(text).toFixed(2)
  },
  {
    title: 'Excess/Shortage',
    dataIndex: 'excessOrShortage',
    className: 'column-money',
    render: text => parseFloat(text).toFixed(2)
  }
]

const DailyOverViewReports = (props) => {

  const {
    salesSummany,
    ordersSummany,
    locationSummany,
    userSummany,
    cashierSummany,
    receiptsSummary,
    loadingOrders = false,
    loadingOrderItems = false,
    loadingCashiers = false,
    loadingReceipts = false
  } = props

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col lg={14}>
          <Spin spinning={loadingOrders}>
            <Table
              columns={salesSummaryTableColumns}
              dataSource={[...salesSummany.map(o => ({ ...o, key: o.id }))]}
              title={() => <div><b>Sales&nbsp;</b><span>Summary</span></div>}
              pagination={false}
              showHeader={false}
              className='table_summary'
            />
          </Spin>
        </Col>
        <Col lg={10}>
          <ViewTable
            tableColumns={receiptsSummaryTableColumns}
            tableData={receiptsSummary}
            loading={loadingReceipts}
            title={<div><b>Receipts&nbsp;</b><span>Summary</span></div>}
            pagination={false}
            showHeader={false}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={14}>
          <Spin spinning={loadingOrders}>
            <Table
              columns={ordersSummaryTableColumns}
              dataSource={[...ordersSummany.map(o => ({ ...o, key: o.id }))]}
              title={() => <div><b>Orders&nbsp;</b><span>Summary</span></div>}
              pagination={false}
              bordered
              className='table_summary'
            />
          </Spin>
        </Col>
        <Col lg={10}>
          <Spin spinning={loadingOrderItems}>
            <Table
              columns={locationSummaryTableColumns}
              dataSource={[...locationSummany.map(o => ({ ...o, key: o.id }))]}
              title={() => <div><b>Location&nbsp;</b><span>Summary</span></div>}
              pagination={false}
              bordered
              className='table_summary'
            />
          </Spin>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Spin spinning={loadingOrders}>
            <Table
              columns={userSummaryTableColumns}
              dataSource={[...userSummany.map(o => ({ ...o, key: o.id }))]}
              title={() => <div><b>User&nbsp;</b><span>Summary</span></div>}
              pagination={false}
              bordered
              className='table_summary'
            />
          </Spin>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24}>
          <Spin spinning={loadingCashiers}>
            <Table
              columns={cashierSummaryTableColumns}
              dataSource={[...cashierSummany.map(o => ({ ...o, key: o.id }))]}
              title={() => <div><b>Cashier&nbsp;</b><span>Summary</span></div>}
              pagination={false}
              bordered
              className='table_summary'
            />
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default DailyOverViewReports