import React, { Component } from 'react';
import { Avatar, Col, Row, Tag } from 'antd';
import image from '../../image/empty-image.png';
import { BsThreeDots } from 'react-icons/all';
import { currencyFormat, generateTitle } from '../../helpers/common';
import { format } from 'currency-formatter';
import ItemOptionPopup from './ItemOptionPopup';

class CategoryItemRow extends Component {
  render() {
    const { item, onClickOptions, optionsActive } = this.props;
    const imagePath = item && item.imageSrc.length > 0 ? item && item.imageSrc : image;
    return (
      <Row className='category-item-row'>
        <ItemOptionPopup optionsActive={optionsActive} onClose={onClickOptions} />
        <Col lg={4}>
          <Avatar src={imagePath} size={60} />
        </Col>
        <Col lg={7} style={{ maxHeight: 40, overflow: 'hidden' }}>
          {item && generateTitle(item.name)}
        </Col>
        <Col className='text-right' lg={4}>
          {item && item.itemPrices && this.getItemPrice(item.itemPrices)}
        </Col>

        <Col className='text-right' lg={5}>
          {item && item.availableQty2}
        </Col>
        {/* <Col className='text-center' lg={4}>
          <Tag style={{ marginRight: 0 }}>{item && item.displayInOnlineMenu2 === true ? 'Yes' : 'No'}</Tag>
        </Col> */}
        <Col className='text-right' lg={3}>
          <BsThreeDots onClick={() => item && onClickOptions(item.id, true)} className='incy-button' style={{ fontSize: 24 }} />
        </Col>
      </Row>
    );
  }

  getItemPrice = (itemPrices) => {
    const { business } = this.props;
    const itemPricesList = Array.isArray(itemPrices) ? itemPrices : Object.values(itemPrices);
    if (itemPricesList.length > 0) {
      return format(itemPricesList[0].price, currencyFormat(business));
    } else {
      return 0.00;
    }
  }
}
export default CategoryItemRow;
