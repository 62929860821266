import React, { Component } from 'react';
import { Card, Menu, Spin, Tooltip } from 'antd';
import { FiPlusCircle } from 'react-icons/all';
import './sidemenu.css';
import { generateTitle } from '../../helpers/common';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
const { SubMenu } = Menu;

class SidebarMenu extends Component {
  state = {
    collapsed: false
  }

  renderTopCategories = () => {
    const { defaultCategory, onSelectCategory } = this.props;
    const mainCategories = [];

    defaultCategory && defaultCategory.forEach(topLevel => {
      const categories = [];
      topLevel && topLevel.categories && Object.keys(topLevel.categories).forEach(key => {
        categories.push(topLevel.categories[key]);
      });
      mainCategories.push(
        categories.length > 0 ? <SubMenu
          key={topLevel.id}
          onTitleClick={() => onSelectCategory(topLevel.id)}
          title={
            <span className='incyMenuLevelOne'>
              <Tooltip placement='left' title={generateTitle(topLevel.name)}> {generateTitle(topLevel.name)} </Tooltip>
            </span>
          }
        >
          {this.renderLevelOne(topLevel)}
        </SubMenu>
          : <Menu.Item key={topLevel.id} onClick={() => onSelectCategory(topLevel.id)}>
            <span className='incyMenuLevelOne'>
              <Tooltip placement='left' title={generateTitle(topLevel.name)}>
                {generateTitle(topLevel.name)}
              </Tooltip>
            </span>
          </Menu.Item>
      );
    });
    return mainCategories;
  }

  renderLevelOne = topLevel => {
    const levelOneCategories = [];
    const categories = [];
    Object.keys(topLevel.categories).forEach(key => {
      categories.push(topLevel.categories[key]);
    });
    categories && categories.length > 0 && categories.forEach(levelOne => {
      const baseCategories = [];
      Object.keys(levelOne.categories).forEach(key => {
        baseCategories.push(levelOne.categories[key]);
      });
      levelOneCategories.push(
        baseCategories.length > 0 ? <SubMenu
          key={levelOne.id}
          onTitleClick={() => this.props.onSelectCategory(levelOne.id)}
          title={
            <span className='incyMenuLevelTwo'>
              <Tooltip placement='left' title={generateTitle(levelOne.name)}>
                {generateTitle(levelOne.name)}
              </Tooltip>
            </span>
          }
        >
          {this.renderLevelTwo(levelOne)}
        </SubMenu>
          : <Menu.Item key={levelOne.id} onClick={() => this.props.onSelectCategory(levelOne.id)}>
            <span className='incyMenuLevelTwo'>
              <Tooltip placement='left' title={generateTitle(levelOne.name)}>
                {generateTitle(levelOne.name)}
              </Tooltip>
            </span>
          </Menu.Item>
      );
    });

    return levelOneCategories;
  }

  renderLevelTwo = levelOne => {
    const levelOneCategories = [];
    const categories = [];
    Object.keys(levelOne.categories).forEach(key => {
      categories.push(levelOne.categories[key]);
    });
    categories && categories.length > 0 && categories.forEach(levelOne => {
      levelOneCategories.push(
        <Menu.Item key={levelOne.id} onClick={() => this.props.onSelectCategory(levelOne.id)}>
          <span className='incyMenuLevelThree'>{generateTitle(levelOne.name)}</span>
        </Menu.Item>
      );
    });

    return levelOneCategories;
  }

  render() {
    const { loading, onClickAddCategory, selectedIdList = [], defaultCategory } = this.props;

    return (
      <Card
        className='menuContainer'
        title='Categories'
        extra={
          <Link to={'/categories/new'}>
            <FiPlusCircle className='incy-button' onClick={() => onClickAddCategory()} style={{ color: '#F95C38', fontSize: 25 }} />
          </Link>
        }
      >
        <Spin spinning={loading && defaultCategory && defaultCategory.length === 0}>
          <Scrollbars style={{ height: 650, marginRight: 20 }}>
            <Menu
              selectedKeys={selectedIdList}
              mode='inline'
              inlineCollapsed={this.state.collapsed}
            >
              {this.renderTopCategories()}
            </Menu>
          </Scrollbars>
        </Spin>
      </Card>
    );
  }
}

export default SidebarMenu;
