import { advancedSearch } from '../api/cashiers';

export const updateActiveFilter = filter => dispatch => {
  dispatch({
    type: 'UPDATE_ACTIVE_FILTER_CASHIER',
    filter
  });
};

export const fetchCashiersFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'PARTIALLY_FETCH_CASHIERS', cashiers: response.data.cashierList, totalResults: response.data.totalResults, query });
    }
    return response;
  });
};