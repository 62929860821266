import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withRouter } from 'react-router';

import { fetchList } from '../../../actions/generic';
import { getFilteredPersonFeedback } from '../../../reducers';

class OrderFeedback extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const deliveryPersonFeedbackFilter = {
      person_id: this.props.deliveryPersonId,
      doc_id: this.props.orderId,
      feedback_type: "DELIVERY_PERSON_FEEDBACK"
    }

    this.props.deliveryPersonId && this.props.fetchList('person_feedback', deliveryPersonFeedbackFilter).then(response => {
    });
  }

  render() {
    const { consumerFeedback, deliveryPersonFeedback } = this.props;
    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        {consumerFeedback && <div>
          <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Consumer Feedback</p>
          <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>{consumerFeedback.accepted ? 'ACCEPTED' : 'REJECTED'}</p>
          {consumerFeedback.comment && <p style={{ fontSize: 'medium', marginBottom: 10, textAlign: 'left' }}>{consumerFeedback.comment}</p>}
        </div>}

        {deliveryPersonFeedback && <div>
          <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Delivery Person Feedback</p>
          <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>{deliveryPersonFeedback.status ? deliveryPersonFeedback.status : 'N/A'}</p>
          {deliveryPersonFeedback.comment && <p style={{ fontSize: 'medium', marginBottom: 10, textAlign: 'left' }}>{deliveryPersonFeedback.comment}</p>}
        </div>}
      </Spin>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const deliveryPersonFeedbackFilter = {
    person_id: ownProps.deliveryPersonId,
    doc_id: ownProps.orderId,
    feedback_type: "DELIVERY_PERSON_FEEDBACK"
  }
  const deliveryPersonFeebackByFilter = getFilteredPersonFeedback(state, deliveryPersonFeedbackFilter)
  return ({
    deliveryPersonFeedback: deliveryPersonFeebackByFilter && deliveryPersonFeebackByFilter.length > 0 && deliveryPersonFeebackByFilter[0]
  });
};

export default withRouter(connect(mapStateToProps, { fetchList })(OrderFeedback));

