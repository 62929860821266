import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignIn from './containers/SignIn';
import SignUp from './containers/SignUp';
import ForgotPassword from './containers/ForgotPassword/index';
import { connect } from 'react-redux';
import { getAuthenticity } from './reducers';
import CompanyList from './containers/CompanyList';
import NotFound from './containers/404';
import CompanyInfoView from './containers/CompanyInfoView';
import MenuConfiguration from './containers/MenuConfiguration';
import ItemFormContainer from './containers/MenuItemComponents/ItemFormContainer';
import ContactEmailVerification from './containers/ContactEmailVerification';
// import CategoryFormContainer from "./containers/MenuCategoryComponents/CategoryFormContainer"
import AllItems from './containers/AllItems';
import ItemForm from './containers/ItemForm/ItemFormContainer';
import CategoryForm from './containers/CategoryForm';
import Orders from './containers/Orders';
import Customers from './containers/Customers';
import SettingsContainer from './containers/AllSettings';
import Payments from './containers/Payments';
import Invoices from './containers/Payments/Invoices';
import Receipts from './containers/Payments/Receipts';
import InvoiceSettlement from './containers/InvoiceSettlement';
import ReceiptPrint from './containers/ReceiptPrint';
import InvoicePrint from './containers/InvoicePrint';
import Subscriptions from './containers/Billing/Subscriptions';
import Devices from './containers/Billing/Devices';
import AgentDetails from './containers/Billing/AgentDetails';
import ItemVariantForm from './containers/ItemVariants';
import EditFormContainer from './containers/ItemVariants/EditFormContainer';
import Payouts from './containers/Payouts';
import Commissions from './containers/Billing/Commissions';
import TransferNotes from './containers/TransferNotes/List';
import TransferNotesView from './containers/TransferNotes/View';
import Dashboard from './containers/Dashboard';
import Sales from './containers/SalesReports';
import AllInventory from './containers/AllInventory';

export const Root = authenticity => {
  const isLoggedIn = authenticity.authenticity && authenticity.authenticity.authToken !== undefined;
  const user = authenticity.authenticity && authenticity.authenticity.user;

  const publicRoutes = [
    <Route exact path='/' component={SignIn} />,
    <Route exact path='/forgotPassword' component={ForgotPassword} />
  ];
  const privateRoutes = [
    <Route exact path='/' component={Dashboard} />,
    <Route exact path='/users' component={CompanyList} />,
    <Route exact path='/userDashboard' component={NotFound} />,
    <Route exact path='/items' component={AllItems} />,
    <Route exact path='/inventory' component={AllInventory} />,
    <Route exact path='/orders' component={Orders} />,
    <Route exact path='/customers' component={Customers} />,
    <Route exact path='/settings' component={SettingsContainer} />,
    <Route exact path='/users/:companyId/viewCompanyInfo' component={CompanyInfoView} />,
    <Route exact path='/menuConfiguration' component={MenuConfiguration} />,
    // <Route exact path="/items/:companyId/:itemId" component={ItemFormContainer} />,
    // <Route exact path="/categories/new" component={CategoryFormContainer} />,
    <Route exact path='/items/:companyId/:itemId/edit' component={ItemFormContainer} />,
    <Route exact path='/menuConfiguration/categories/:categoryId' component={MenuConfiguration} />,
    // <Route exact path="/companies/:companyId/menuConfiguration/items/:itemId" component={MenuConfiguration} />,
    <Route exact path='/items/:itemId' component={ItemForm} />,
    <Route exact path='/items/:itemId/variant' render={() => <EditFormContainer newVariant={false} />} />,
    <Route exact path='/items/:itemId/variant/new/:variantId?' render={() => <EditFormContainer newVariant />} />,
    <Route exact path='/variant' component={ItemVariantForm} />,
    <Route exact path='/categories/:categoryId' component={CategoryForm} />,
    <Route exact path='/subscriptions' component={Payments} />,
    <Route exact path='/payments/invoices' component={Invoices} />,
    <Route exact path='/payments/receipts' component={Receipts} />,
    <Route exact path='/payments/invoiceSettlement' component={InvoiceSettlement} />,
    <Route exact path='/printReceipt/:receiptId/:customerId' component={ReceiptPrint} />,
    <Route exact path='/invoice/print/:invoiceId/:customerId' component={InvoicePrint} />,
    <Route exact path='/billing/subscriptions' component={Subscriptions} />,
    <Route exact path='/billing/devices' component={Devices} />,
    <Route exact path='/billing/agent' component={AgentDetails} />,
    <Route exact path='/billing/commissions' component={Commissions} />,
    <Route exact path='/payouts' component={Payouts} />,
    <Route exact path='/transferNotes' component={TransferNotes} />,
    <Route exact path='/transferNotes/:transferNoteId' component={TransferNotesView} />,
    <Route exact path='/verifyContactEmail/:hash' component={ContactEmailVerification} />,
    <Route exact path='/dashboard' component={Dashboard} />,
    <Route exact path='/salesReports' component={Sales} />
  ];

  const signupRoutes = [
    <Route exact path='/signup' component={SignUp} />
  ];

  const createCustomerRoutes = [
    <Route exact path='/signup/details' component={SignUp} />,
    <Route key='redirect/signup/details' render={() => <Redirect to='/signup/details' />} />
  ];

  const verificationRoutes = [
    <Route exact path='/signup/verify' component={SignUp} />,
    <Route key='redirect/signup/verify' render={() => <Redirect to='/signup/verify' />} />
  ];

  let routes = [...publicRoutes];

  if (isLoggedIn) {
    if (user.customer && !user.customer.basicBusinessProfileCompleted2) {
      routes = [...createCustomerRoutes];
    } else if (user.customer && !user.customer.mobileVerified) {
      routes = [verificationRoutes, ...routes];
    } else {
      routes = [privateRoutes];
    }
  } else {
    routes = [
      ...routes,
      ...signupRoutes,
      <Route key='/*' render={() => <Redirect to='/' />} />
    ];
  }

  return (
    <Switch>
      {routes}
    </Switch>
  );
};

const mapStateToProps = state => {
  const authenticity = getAuthenticity(state);
  return { authenticity };
};

export default connect(mapStateToProps, {})(Root);
