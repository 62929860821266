import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

export class GeoInputSearch extends Component {
  constructor (props) {
    super(props);
    this.state = { address: '' };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ address: nextProps.address });
  }

  handleChange = address => {
    this.props.onChangeGoogleSuggestAddress({ address });
    this.setState({ address });
  };

  handleSelect = address => {
    this.props.onSelectGoogleSuggestAddress(address);

    // geocodeByAddress(address)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => console.error('Success', latLng))
    //   .catch(error => console.error('Error', error));

    this.setState({ address });
  };

  render () {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Find your location on Google Maps',
                className: 'ant-input ant-input-lg'
              })}
            />
            <div style={{ zIndex: 10, position: 'absolute', left: 24, padingLeft: 5, width: 515 }} className='ant-select-item-option-content'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    key={index + suggestion.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
