import React, { Component } from 'react';
import { Row, Spin, Table, Input, Card, Col, DatePicker, Modal, Select, Menu, Dropdown, Tag, Form, Checkbox, Button } from 'antd';
import { connect } from 'react-redux';
import { fetchCompany } from '../../actions/companies';
import { getCompanyById, getFirstOrder, getAuthenticity, getFilteredOrders, getFilteredOrderTotalCount, getActiveOrderFilter, getBusinesses, getBranches } from '../../reducers';
import { updateStatus, updateActiveFilter } from '../../actions/Orders';
import { fetchList } from '../../actions/generic';
import { showLoadingMessage } from '../../components/Message';
import moment from 'moment';
import { withRouter } from 'react-router';
import { format } from 'currency-formatter';
import { currencyFormat, getItemRetailPrice } from '../../helpers/common';
import Api from '../../api/consts';
import { showSuccessNotification } from '../../components/Notification';
import OrderPaymentSettlementModal from './components/PaymentSettlementModal'
import { FiInfo } from 'react-icons/all';
import { truncate } from 'lodash';
import OrderReciptPrintModal from './components/OrderReciptPrintModal';
import OrderDetailView from './components/OrderDetailView';
import TransactionsList from './components/TransactionsList';
import OrderFeedback from './components/OrderFeedback';

const dateTimeFormat = 'YYYY/MM/DD';
const PAGE_SIZE = 20;
const statuses = [
  { name: 'New', value: 'NEW' },
  { name: 'Confirmed', value: 'CONFIRMED' },
  { name: 'Ready', value: 'READY' },
  { name: 'Dispatched', value: 'DISPATCHED' },
  { name: 'Delivery', value: 'DELIVERY' },
  { name: 'Completed', value: 'COMPLETED' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Rejected', value: 'REJECTED' },
  { name: 'Returned', value: 'RETURNED' }
];

const { confirm } = Modal;
const { Option } = Select;

const { Search } = Input;

class OrderList extends Component {
  state = {
    loading: false,
    loadingCount: false,
    textFilter: undefined,
    expiryDate: undefined,
    usingKds: false,
    usingCheckoutCounter: false,
    sortedInfo: null,
    action: undefined,
    currentPage: 1,
    expandedRows: [],

    filter: {
      businessIds: [],
      branchId: [],
      text: undefined,
      orderId: undefined,
      fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
      toDate: moment(new Date()).endOf('day'),
      page: 1,
      pageSize: 20,
      status: [],
      method: ''
    },

    visblePaymentModal: false,
    visbleReciptPrintModal: false,
    selectedOrder: undefined,
    submitting: false,
  }

  componentDidMount = () => {
    const { authData: { user: { userId } }, filter, updateActiveFilter, businesses, branches } = this.props;
    const businessIds = businesses.map(b => b.id);
    const branchIds = branches.map(br => br.id);

    this.setState({ filter: { ...filter, pageSize: 20, businessIds, branchIds } }, () => {
      const { filter } = this.state;

      updateActiveFilter(filter);
      this.handleSearch(filter);
    });
  }

  componentWillReceiveProps(nextProps) {
    const { company } = this.props;
    if (nextProps.company !== this.company) {
      if (company && company.businesses && Object.keys(company.businesses).length > 0) {
        this.setState({ usingKds: Object.values(company.businesses)[0].usingKds, usingCheckoutCounter: Object.values(company.businesses)[0].usingCheckoutCounter });
      }
    }
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
    this.stopAutoRefresher();
  }

  handleOnClickSearch = () => {
    this.autoRefresh = false;

    const { updateActiveFilter } = this.props;
    const { filter } = this.state;

    this.setState({ filter: { ...filter, page: 1 } }, () => {
      const { filter } = this.state;

      updateActiveFilter(filter);
      this.handleSearch(filter);
    });
  }

  handleSearch = filter => {
    const { fetchList } = this.props;
    this.setState({ loading: true });
    if (!this.hideLoadingMessage) this.showLoadingMessage();
    fetchList('order', filter).then(response => {
      if (this.hideLoadingMessage) this.hideLoadingMessage();
      this.setState({ loading: false });
      //this.setAutoRefresher(filter); // TODO enable this after fix api crash
    });
  }

  autoRefresh = false;
  setAutoRefresher = filter => {
    this.stopAutoRefresher();
    this.timeout = window.setTimeout(() => {
      this.autoRefresh = true;
      this.handleSearch(filter);
    }, 60000); // 60s
  }

  stopAutoRefresher = () => {
    if (this.timeout) window.clearTimeout(this.timeout);
  }

  getMenu = (record) => {
    return (
      <Menu onClick={(e) => this.handleMenuItemClick(e, record)}>
        {this.canDisplayReadyOption(record.status) && <Menu.Item key='ready'>Ready</Menu.Item>}
        {this.canDisplayDispatchedOption(record.status) && <Menu.Item key='dispatched'>Dispatched</Menu.Item>}
        {/* {this.canDisplayDeliveredOption(record.status) && <Menu.Item key='delivered'>Delivered</Menu.Item>} */}
        {this.canDisplayCompletedOption(record.status) && <Menu.Item key='completed'>Completed</Menu.Item>}
        {this.canDisplayCancelledOption(record.status) && 'CANCELLED' && <Menu.Item key='cancel'>Canceled</Menu.Item>}
        {/* {this.canDisplayReturnedOption(record.status) && <Menu.Item key='return'>Returned</Menu.Item>} */}
      </Menu>
    );
  }

  renderButtonStatus = (status) => {
    var color = '#108ee9';
    switch (status) {
      case 'NEW':
        color = '#708090';
        break;
      case 'PREPARING':
        color = '#FF9420';
        break;
      case 'READY':
        color = '#25A023';
        break;
      case 'RETURNED':
        color = '#FF2E2E';
        break;
      case 'CANCELLED':
        color = '#FF2E2E';
        break;
      default:
        color = '#108ee9'
    }

    return color;
  }

  canDisplayReadyOption = (status) => {
    var can = false;
    if (status !== 'READY' && status !== 'DISPATCHED' && status !== 'DELIVERED' && status !== 'COMPLETED' && status !== 'CANCELLED' && status !== 'RETURNED') {
      can = true;
    }
    return can;
  }

  canDisplayDispatchedOption = (status) => {
    var can = false;
    if (status !== 'DISPATCHED' && status !== 'DELIVERED' && status !== 'COMPLETED' && status !== 'CANCELLED' && status !== 'RETURNED') {
      can = true;
    }
    return can;
  }

  canDisplayDeliveredOption = (status) => {
    var can = false;
    if (status !== 'DELIVERED' && status !== 'COMPLETED' && status !== 'CANCELLED' && status !== 'RETURNED') {
      can = true;
    }
    return can;
  }

  canDisplayCompletedOption = (status) => {
    var can = false;
    if (status !== 'COMPLETED' && status !== 'CANCELLED' && status !== 'RETURNED') {
      can = true;
    }
    return can;
  }

  canDisplayCancelledOption = (status) => {
    var can = false;
    if (status !== 'COMPLETED' && status !== 'DELIVERED' && status !== 'CANCELLED' && status !== 'RETURNED') {
      can = true;
    }
    return can;
  }

  canDisplayReturnedOption = (status) => {
    var can = false;
    if (status !== 'COMPLETED' && status !== 'RETURNED' && status !== 'CANCELLED') {
      can = true;
    }
    return can;
  }

  getColumns = () => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    return [
      {
        title: 'ID',
        dataIndex: 'number',
        key: 'number',
        align: 'left',
        sorter: (a, b) => a.number.length - b.number.length,
        sortOrder: sortedInfo.columnKey === 'number' && sortedInfo.order,
        render: text => <p>{text}</p>
      },
      {
        title: 'Date',
        dataIndex: 'time',
        key: 'time',
        align: 'left',
        sorter: (a, b) => moment(a.time).unix() - moment(b.time).unix(),
        sortOrder: sortedInfo.columnKey === 'time' && sortedInfo.order,
        render: text => <p>{moment(text).format(dateTimeFormat)}</p>
      },
      // {
      //   title: 'Customer',
      //   dataIndex: 'customer',
      //   width: 80,
      //   key: 'customer',
      //   render: (customer) => <p>{customer && customer.name}</p>
      // },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: text => <Tag color={this.renderButtonStatus(text)}>{text}</Tag>
      },
      // {
      //   title: 'Order Type',
      //   dataIndex: 'type',
      //   key: 'type',
      //   width: 100,
      //   align: 'center',
      //   render: text => <p>{text}</p>
      // },

      {
        title: 'Amount',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        sorter: (a, b) => a.total - b.total,
        sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
        render: total => <p>{total && format(total, currencyFormat(business))}</p>
      },

      {
        title: 'Balance',
        dataIndex: 'balance_to_settle',
        key: 'balance_to_settle',
        align: 'right',
        sorter: (a, b) => a.balance_to_settle - b.balance_to_settle,
        sortOrder: sortedInfo.columnKey === 'balance_to_settle' && sortedInfo.order,
        render: balanceToSettle => <p>{balanceToSettle && format(balanceToSettle, currencyFormat(business))}</p>
      },
      // {
      //   title: 'Action',
      //   dataIndex: 'status',
      //   key: 'action',

      //   width: 80,
      //   render: (status, record) => status !== 'COMPLETED' && status !== 'CANCELLED' && status !== 'RETURNED' && (
      //     <span className='table-operation'>
      //       <Dropdown overlay={this.getMenu(record)}>
      //         <p style={{ textAlign: 'center' }}> ...</p>
      //       </Dropdown>
      //     </span>
      //   )
      // }
    ];
  }

  getTotal = () => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const filterdOrdersList = this.props.orders;
    let total = 0;
    filterdOrdersList && filterdOrdersList.length > 0 && filterdOrdersList.forEach(order => {
      if (order) total += order.total;
    });

    total = format(total, currencyFormat(business));
    return total;
  }

  getBalanceToSettle = () => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const filterdOrdersList = this.props.orders;
    let total = 0;
    filterdOrdersList.forEach(order => {
      if (order) total += order.balanceToSettle;
    });

    total = format(total, currencyFormat(business));
    return total;
  }

  handleTableChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      sortedInfo: sorter,
      currentPage: pagination.current,
      expandedRows: []
    });
  };

  onTableRowExpand(expanded, record) {
    const { fetchList } = this.props;
    console.log("clicked")
    var keys = [];
    if (expanded) {
      keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
      this.setState({ expanded : true})
    }else{
      this.setState({ expanded : false})
    }
    this.setState({ expandedRows: keys });
  }

  expandedRowRender = (order) => {
    const { company, companyId, businesses, branches } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const businessIds = businesses.map(b => b.id);
    const branchIds = branches.map(br => br.id);
    const { id, customer_id, delivery_person_id, type, number, time, status, balance_to_settle, delivery_address_id } = order;
    const { consumerFeedback, deliveryPersonFeedback, remarks } = order.data;

    //const orderItemList = items ? (Array.isArray(items) ? items : Object.values(items)) : [];

    const orderNo = number ? number + ' / ' : '';
    const formattedDate = moment(time).format(dateTimeFormat);

    return (
      <div>
        <OrderDetailView expanded = {this.state.expanded} orderId={id} businessIds={businessIds} branchIds={branchIds} deliveryAddressId={delivery_address_id} orderNo={orderNo} date={formattedDate}
          customerId={customer_id} deliveryPersonId={delivery_person_id} type={type} />
        {/* <Row gutter={5}>
          <h6 style={{ marginTop: 20 }}><u>Order Items</u></h6>
          <h6 >Item count : {orderItemList.length}</h6>

          <Col lg={24} style={{ marginBottom: 25, marginTop: 10 }}>
            <table style={{ width: '100%', textAlign: 'center' }}>
              <tr>
                <td style={{ width: '20%', textAlign: 'left', fontSize: 'medium' }}>Description</td>
                <td style={{ width: '20%', textAlign: 'right', fontSize: 'medium' }}>Qty</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Unit Price</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Discount</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Net Price</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Total</td>
              </tr>
            </table>
            <hr style={{ margin: 0 }} />
            {orderItemList.map(orderItem => this.renderOrderItem(orderItem))}
            <hr style={{ marginTop: 4, marginBottom: 0 }} />
          </Col>
        </Row> */}

        <Row gutter={5}>
          <Col md={9} sm={9} xs={9} lg={9}>
            <TransactionsList orderId={id} businessIds={businessIds} branchIds={branchIds} />
            {/* {<p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Payment Details</p>}
            {paymentDetailsList.map(payment =>
              <div style={{ display: 'flex', textAlign: 'left' }}>
                <p style={{ fontSize: 'medium', marginBottom: 15 }}>{payment}</p>
              </div>
            )} */}

            {balance_to_settle > 0.0 && this.renderAddPaymentToOrderOption(order, business)}

            {remarks !== undefined && remarks.length > 0 && <div>
              <p style={{ fontWeight: 'bold', fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Order Remarks</p>
              <p style={{ fontSize: 'medium', marginBottom: 10, textAlign: 'left' }}>{remarks !== undefined ? remarks : 'N/A'}</p>
            </div>}

            <OrderFeedback orderId={id} businessIds={businessIds} branchIds={branchIds} deliveryPersonId={delivery_person_id} />

          </Col>
          <Col md={4} sm={4} xs={4} lg={4} />
          <Col md={6} sm={6} xs={6} lg={6}>
            <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Gross Amount</p>
            <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}> Tax ({order.tax_percentage ? order.tax_percentage : 0}%)</p>
            <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Service charge ({order.service_charge_percentage ? order.service_charge_percentage : 0}%) </p>
            <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Delivery Charge</p>
            <p style={{ fontSize: 'medium', marginBottom: 5, textAlign: 'left' }}>Discount</p>
            <p style={{ fontSize: 'large', fontWeight: 'bold', marginBottom: 5, textAlign: 'left' }}><b>Net Amount</b></p>

          </Col>
          <Col md={5} sm={5} xs={5} lg={5} style={{ textAlign: 'right' }}>
            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{format(order.subtotal, currencyFormat(business))}</p>
            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{format(order.tax, currencyFormat(business))} </p>
            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{format(order.service_charge, currencyFormat(business))} </p>
            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{format(order.delivery_charge, currencyFormat(business))} </p>
            <p style={{ fontSize: 'medium', marginBottom: 5 }}>{!order.discount ? format(0, currencyFormat(business)) : format(order.discount, currencyFormat(business))} </p>
            <p style={{ fontSize: 'large', fontWeight: 'bold', marginBottom: 5 }}><b>{format(order.total, currencyFormat(business))}</b></p>
          </Col>
        </Row>

        <Row gutter={5} style={{ marginTop: 10 }}>
          <Col md={3} sm={3} xs={3} lg={3} style={{ textAlign: 'right' }}>
            <Button style={{ width: 85, backgroundColor: '#FF9420', color: 'white' }} size={'small'} icon="printer" onClick={() => this.setState({ visbleReciptPrintModal: true, selectedOrder: order })}>Print</Button>
          </Col>
          {order && order.balance_to_settle > 0.0 && <Col md={3} sm={3} xs={3} lg={3} style={{ textAlign: 'right' }}>
            <Button style={{ width: 85, background: '#25a023' }} type='primary' icon="dollar" danger size='small' onClick={() => this.setState({ visblePaymentModal: true, selectedOrder: order })}>Pay</Button>
          </Col>}

          <Col md={3} sm={3} xs={3} lg={3} />
          <Col md={3} sm={3} xs={3} lg={3} />

          <Col md={3} sm={3} xs={3} lg={3} style={{ textAlign: 'right' }}>
            {this.canDisplayReadyOption(status) && <Button loading={this.state.submitting} style={{ width: 85, background: '#25a023', borderColor: '#25a023' }} className='btn-green' type='primary' danger size='small' onClick={() => this.showConfirmUpdateOrder(order, 'READY')}>READY</Button>}
          </Col>
          <Col style={{ textAlign: 'center' }} md={3} sm={3} xs={3} lg={3}>
            {this.canDisplayDispatchedOption(status) && <Button loading={this.state.submitting} style={{ width: 85 }} type='primary' danger size='small' onClick={() => this.showConfirmUpdateOrder(order, 'DISPATCHED')}>DISPATCH</Button>}
          </Col>
          {/* <Col style={{ textAlign: 'center' }} md={3} sm={3} xs={3} lg={3}>
            {this.canDisplayDeliveredOption(status) && <Button style={{ width: 85 }} type='primary' danger size='small' onClick={() => this.showConfirmUpdateOrder(order, 'DELIVERED')}>DELIVERED</Button>}
          </Col> */}
          <Col style={{ textAlign: 'right' }} md={3} sm={3} xs={3} lg={3}>
            {this.canDisplayCancelledOption(status) && <Button loading={this.state.submitting} style={{ width: 85, background: '#ff2e2e', borderColor: '#ff2e2e' }} type='primary' danger size='small' onClick={() => this.showConfirmUpdateOrder(order, 'CANCELLED')}>CANCEL</Button>}
          </Col>
          {/* <Col style={{ textAlign: 'right' }} md={3} sm={3} xs={3} lg={3}>
            {this.canDisplayReturnedOption(status) && <Button style={{ width: 85, background: '#ff2e2e', borderColor: '#ff2e2e' }} type='primary' danger size='small' onClick={() => this.showConfirmUpdateOrder(order, 'RETURNED')}>RETURNED</Button>}
          </Col> */}
          <Col style={{ textAlign: 'center' }} md={3} sm={3} xs={3} lg={3}>
            {this.canDisplayCompletedOption(status) && <Button loading={this.state.submitting} style={{ width: 85, textAlign: 'center' }} type='primary' danger size='small' onClick={() => this.showConfirmUpdateOrder(order, 'COMPLETED')}>COMPLETE</Button>}
          </Col>
        </Row>
      </div>
    );
  }

  renderAddPaymentToOrderOption = (order, business) => {
    return (
      <div>
        <p style={{ color: 'red', fontWeight: 'bold', textAlign: 'left' }}>Balance to settle : {format(order.balance_to_settle, currencyFormat(business))}</p>
      </div>
    );
  }

  renderOrderItem = (orderItem) => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { name, quantity, modifiers = [], price, discount } = orderItem;
    const modifierList = Array.isArray(modifiers) ? modifiers : Object.values(modifiers);
    const netUnitPrice = (price - discount);
    return (
      <div style={{ display: 'flex', width: '100%', textAlign: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <span style={{ width: '20%', textAlign: 'left', fontSize: 'medium', }}>  {name}</span>
          <span style={{ width: '20%', textAlign: 'right', fontSize: 'medium', }}>{quantity}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format(price, currencyFormat(business))}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format(discount, currencyFormat(business))}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format(netUnitPrice, currencyFormat(business))}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format((netUnitPrice * quantity), currencyFormat(business))}</span>
        </div>

        {modifierList.map(orderModifier => {
          const { modifiers = [] } = orderModifier;
          const optionModifiers = Array.isArray(modifiers) ? modifiers : Object.values(modifiers);
          return <div style={{ display: 'flex', width: '100%', }} key={orderModifier.id}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}  >

              <div style={{ display: 'flex', width: '100%', }}>
                <span style={{ textAlign: 'left', width: '20%', fontSize: 'small' }}> &nbsp; &nbsp; &nbsp; {orderModifier && ' ' + orderModifier.name}</span>
                <span style={{ textAlign: 'right', width: '20%' }}>{orderModifier.quantity}{quantity > 1 ? ' * ' + quantity : ''}</span>
                <span style={{ textAlign: 'right', width: '15%' }}>{format(orderModifier.price, currencyFormat(business))}</span>
                <span style={{ textAlign: 'right', width: '15%' }}></span>
                <span style={{ textAlign: 'right', width: '15%' }}></span>
                <span style={{ textAlign: 'right', width: '15%', }}>{format(orderModifier.price * orderModifier.quantity * quantity, currencyFormat(business))}</span>
              </div>

              {optionModifiers.map(orderModifier => {
                return <div style={{ display: 'flex', width: '100%', }} key={orderModifier.id}>
                  <div style={{ display: 'flex', width: '100%', }}>
                    <div style={{ display: 'flex', width: '100%' }} >
                      <span style={{ textAlign: 'left', width: '20%', fontSize: 'small', }}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {orderModifier && ' ' + orderModifier.name}</span>
                      <span style={{ textAlign: 'right', width: '20%', }}>{orderModifier.quantity}{quantity > 1 ? ' * ' + quantity : ''}</span>
                      <span style={{ textAlign: 'right', width: '15%', }}>{format(orderModifier.price, currencyFormat(business))}</span>
                      <span style={{ textAlign: 'right', width: '15%' }}></span>
                      <span style={{ textAlign: 'right', width: '15%' }}></span>
                      <span style={{ textAlign: 'right', width: '15%', }}>{format(orderModifier.price * orderModifier.quantity * quantity, currencyFormat(business))}</span>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
        })}
      </div>
    );
  }

  handleInputDateChange = (field, value) => {
    this.setState({ filter: { ...this.state.filter, [field]: value } });
  }

  handleInputCheckChange = (e) => {
    if (e.target.checked) {
      this.setState({ filter: { ...this.state.filter, method: 'WEB' } });
    } else {
      this.setState({ filter: { ...this.state.filter, method: '' } });
    }
  }

  handleInputTextChange = value => {
    this.setState({ filter: { ...this.state.filter, text: value } });
  }

  handleStatusChange = value => {
    this.setState({ filter: { ...this.state.filter, status: value } });
  }

  handleMenuItemClick = (e, record) => {
    var action = undefined;
    if (e.key === 'ready') {
      action = 'READY';
    } else if (e.key === 'dispatched') {
      action = 'DISPATCHED';
    } else if (e.key === 'delivered') {
      action = 'DELIVERED';
    } else if (e.key === 'completed') {
      action = 'COMPLETED';
    } else if (e.key === 'cancel') {
      action = 'CANCELLED';
    }
    this.showConfirmUpdateOrder(record, action);
  }

  showConfirmUpdateOrder = (record, action) => {
    const actionlabel = this.getActionLabel(action);
    if (actionlabel === 'NONE') {
      return;
    }
    const THIS = this;
    confirm({
      title: 'Do you want to ' + actionlabel + ' this order?',
      content: '',
      icon: <FiInfo size={25} style={{ color: '#ff4d25', float: 'left', marginRight: 14 }} />,
      onOk() {
        THIS.upateOrderStatus(record, action);
      },
      // okButtonProps: { style: { backgroundColor: '#498d05', borderColor: '#498d05' } },
      onCancel() { }
    });
  }

  getActionLabel = (action) => {
    if (action === 'READY') {
      return 'ready';
    }
    if (action === 'DISPATCHED') {
      return 'dispatch';
    }
    if (action === 'DELIVERED') {
      return 'deliver';
    }
    if (action === 'COMPLETED') {
      return 'complete';
    }
    if (action === 'CANCELLED') {
      return 'cancel';
    }
    if (action === 'RETURNED') {
      return 'return';
    }
    return 'NONE';
  }

  upateOrderStatus = (record, action) => {
    const { companyId } = this.props;
    // call API
    this.setState({ submitting: true });
    this.props.updateStatus(companyId, record.id, { action: action }).then(response => {
      if (response.order) {
        if (response.order.customer) {
          const { email, telephone } = response.order.customer;
          console.log("customer exisits")
          // TODO this.handleSMS(telephone, 'Your order ' + record.number + ' has been ' + action.toLowerCase() + '.');
          // TODO this.handleEmail(email, 'Your order ' + record.number + ' has been ' + action.toLowerCase() + '.');
        }
        showSuccessNotification('Success', 'Successfully updated.');
      }
      this.props.fetchList("order", { businessIds: this.state.filter.businessIds, branchIds: this.state.filter.branchIds, id: record.id }).then(response => {
        this.setState({ submitting: false });
      }).catch(error => {
        this.setState({ submitting: false });
      });
    }).catch(error => {
      this.setState({ submitting: false });
    });
  }

  handleSMS = (phone, message) => {
    const { url, method, headers } = Api.notification;
    if (!phone) return;
    phone = phone.replace(/ /g, '');
    phone = phone.replace('+', '');
    const obj = JSON.stringify({
      type: 'sms',
      route: 'nexmo',
      from: 'Incy',
      to: phone,
      mi: 'text',
      message: message
    });

    const data = { method, headers, body: obj };
    return fetch(url(), data)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status === 'ok') {
          // showSuccessNotification('Success', "SMS sent.");
        } else {
          // showErrorNotification('Failed.', "SMS failed");
        }
      }).catch(error => {
        return { error };
      });
  }

  handleEmail = (email, message) => {
    if (!email) return;
    const url = 'https://incylabs.com/api/admin/signup';
    // const url = 'http://localhost:3002/api/incytute/idm/forgotPassword123';
    const method = 'POST';
    const headers = {};
    const body = JSON.stringify({ otp: message, email: email });
    const emailData = { method, headers, body };

    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    headers.origin = 'https://admin.incypos.com';
    emailData.headers = headers;

    fetch(url, emailData)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson && responseJson.accepted && responseJson.accepted.length > 0) {
          // showSuccessNotification('Success', "EMAIL sent.");
        }
      }).catch(error => {
        return { error };
      });
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Orders.');
  }

  getFilteredOrders = () => {
    const { orders, filter: query } = this.props;

    let filteredOrderList = orders;
    if (query && query.text) {
      filteredOrderList = orders.filter(order => {
        let searchTerm = query.text;
        searchTerm = searchTerm.replace(' ', '');
        return order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.type.toLowerCase().includes((searchTerm.toLowerCase()).toLowerCase()) ||
          ((order.customer) && order.customer.name.toLowerCase().replace(' ', '').includes(searchTerm.toLowerCase())) ||
          query.status.includes(order.status.toUpperCase()) ||
          (order.method && order.method.toLowerCase().includes((query.text).toLowerCase()));
      });
    }

    if (query && query.method) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (order.method && order.method.toLowerCase().match((query.method).toLowerCase()));
      });
    }

    if (query.status.length > 0) {
      filteredOrderList = filteredOrderList.filter(order => {
        return (!query.status || query.status.length === 0 || query.status.includes(order.status.toUpperCase()) || (query.status.includes('REJECTED') && order.consumerFeedback && !order.consumerFeedback.accepted));
      });
    }


    return filteredOrderList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  getPaginationConfig = () => {
    return {
      pageSize: PAGE_SIZE,
      onChange: this.handlePaginationChange,
      total: this.props.totalResults,
      defaultCurrent: this.props.filter.page,
      current: this.props.filter.page
    };
  }

  handlePaginationChange = (page, pageSize) => {
    const { updateActiveFilter } = this.props;

    this.setState({ filter: { ...this.state.filter, page } }, () => {
      const { filter } = this.state;

      updateActiveFilter(filter);
      this.handleSearch(filter);
    });
  }

  lastOrderTime = null;
  alarmForNewOrders = () => {
    const { firstOrder, orders } = this.props;

    if (orders.length === 0) return;

    const currentLatest = moment(firstOrder.time);

    if (this.autoRefresh && this.lastOrderTime && currentLatest.isAfter(this.lastOrderTime)) {
      console.log(this.autoRefresh, " / ", this.lastOrderTime, " / ", "currentLatest", currentLatest, " / ", currentLatest.isAfter(this.lastOrderTime))
      new Audio('/alarm.mp3').play();
    }

    this.lastOrderTime = currentLatest;
  }

  onCancelPaymentModal = () => {
    this.setState({ visblePaymentModal: false });
  }

  onCancelPrintReciptModal = () => {
    this.setState({ visbleReciptPrintModal: false });
  }

  handlePaymentCompleted = (query, selectedOrder) => {
    selectedOrder && this.props.fetchList("order", { businessIds: query.businessIds, branchIds: query.branchIds, id: selectedOrder.id });
    selectedOrder && this.props.fetchList("view_order_receipt_transaction", { businessIds: query.businessIds, branchIds: query.branchIds, orderId: selectedOrder.id })
  }

  render() {
    const { loading, filter: query, visblePaymentModal, selectedOrder, visbleReciptPrintModal } = this.state;
    const { orders, company, companyId, businesses, branches } = this.props;
    const filteredOrdersList = orders;
    this.alarmForNewOrders();
    const businessIds = businesses.map(b => b.id);
    const branchIds = branches.map(br => br.id);

    return (
      <Card style={{ margin: 5 }} className='card-container'>
        <OrderPaymentSettlementModal
          visible={visblePaymentModal}
          onCancel={this.onCancelPaymentModal}
          order={selectedOrder}
          companyId={companyId}
          //order={orderObj}
          company={company}
          handlePaymentDone={() => this.handlePaymentCompleted(query, selectedOrder)}
        />

        <OrderReciptPrintModal
          visible={visbleReciptPrintModal}
          onCancel={this.onCancelPrintReciptModal}
          order={selectedOrder}
          businessIds={businessIds}
          branchIds={branchIds}
          //order={orderObj}
          companyId={companyId}
          company={company}
        />
        <Row gutter={10}>

          <Col lg={5} md={5} sm={24} xs={24}>
            <Search
              value={query.text}
              placeholder='Search'
              style={{ marginBottom: 10 }}
              onChange={(e) => this.handleInputTextChange(e.target.value)}
              onPressEnter={this.handleOnClickSearch}
            />
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}>
            <DatePicker
              format={dateTimeFormat}
              value={moment(query.fromDate)}
              placeholder='From'
              onChange={(date, dateString) => this.handleInputDateChange('fromDate', date)}
              style={{ width: '100%', marginBottom: 5 }}
              allowClear={false}
            />
          </Col>
          <Col lg={5} md={5} sm={24} xs={24}>
            <DatePicker
              format={dateTimeFormat}
              value={moment(query.toDate)}
              placeholder='To'
              onChange={(date, dateString) => this.handleInputDateChange('toDate', date)}
              style={{ width: '100%' }}
              allowClear={false}
            />
          </Col>
          <Col lg={6} md={6} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
            <Form.Item style={{ marginBottom: 0, marginTop: -4 }}>
              <Select
                allowClear
                onChange={(value) => this.handleStatusChange(value)}
                mode='multiple'
                placeholder='Status'
                style={{ width: '100%', marginBottom: 0 }}
                value={query.status}
              >
                {statuses.map(status => <Option value={status.value}>{status.name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={3} md={3} sm={24} xs={24}>
            <Button loading={this.state.loading} block className='primary-button' type='primary' onClick={() => this.handleOnClickSearch()}>Search</Button>
          </Col>

          {/* <Col lg={24} md={24} sm={24} xs={24}>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Checkbox checked={query.method} style={styles.checkBox} value='WEB' onChange={(e) => this.handleInputCheckChange(e)}>Online Orders</Checkbox>
            </Col>

            <Col lg={6} md={6} sm={24} xs={24} />
            <Col lg={6} md={6} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  allowClear
                  onChange={(value) => this.handleStatusChange(value)}
                  mode='multiple'
                  placeholder='Status'
                  style={{ width: '100%', marginBottom: 5 }}
                  value={query.status}
                >
                  {statuses.map(status => <Option value={status.value}>{status.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Button block className='primary-button' type='primary' onClick={() => this.handleOnClickSearch()}>Search</Button>
            </Col>
          </Col> */}
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Spin spinning={loading && filteredOrdersList && filteredOrdersList.length === 0}>
            <Table
              rowKey='id'
              columns={this.getColumns()}
              expandedRowRender={record => this.expandedRowRender(record)}
              onExpand={(expanded, record) => this.onTableRowExpand(expanded, record)}
              dataSource={filteredOrdersList}
              onChange={this.handleTableChange}
              pagination={this.getPaginationConfig()}
            />
          </Spin>
        </Row>
      </Card>

    );
  }
}

const styles = {
  checkBox: {
    width: '100%', marginLeft: 0
  }
};

const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return ({
    companyId,
    company: getCompanyById(state, companyId),
    businesses: getBusinesses(state),
    branches: getBranches(state),
    orders: getFilteredOrders(state),
    firstOrder: getFirstOrder(state),
    totalResults: getFilteredOrderTotalCount(state),
    filter: getActiveOrderFilter(state),
    authData
  });
};

export default withRouter(connect(mapStateToProps, { fetchCompany, fetchList, updateStatus, updateActiveFilter })(OrderList));
