import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { getItem, getAuthenticity } from '../../reducers';
import { fetchItem } from '../../actions/items';
import { fetchPriceLists } from '../../actions/priceLists';
import { fetchPrinterLocations } from '../../actions/printerLocations';
import { fetchCompany } from '../../actions/companies';
import { fetchCategories } from '../../actions/categories';
import ItemForm from './index';
import ItemVariantForm from '../ItemVariants';

class ItemFormContainer extends Component {
  state = {
    loading: false
  }

  componentDidMount = () => {
    const { companyId, itemId, company, categoryTree, printerLocations, item, priceLists } = this.props;

    if (!company || !company.businesses) {
      this.props.fetchCompany(companyId);
    }
    if (!categoryTree || priceLists.length === 0) {
      this.props.fetchCategories(companyId);
    }

    if (!priceLists || priceLists.length === 0) {
      this.props.fetchPriceLists(companyId);
    }

    if (!printerLocations || printerLocations.length === 0) {
      this.props.fetchPrinterLocations(companyId);
    }
    if (itemId !== 'new') {
      if (!item) {
        this.setState({ loading: true });
        this.props.fetchItem(companyId, itemId).then(response => {
          this.setState({ loading: false });
        });
      }
    }
  }

  render () {
    const { loading } = this.state;
    const { item, match } = this.props;
    const { itemId } = match.params;

    return (
      <Spin spinning={loading && !item}>
        {item && item.variant ? <ItemVariantForm itemId={itemId} /> : <ItemForm itemId={itemId} />}
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { itemId } = props.match.params;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return {
    itemId: itemId,
    companyId: companyId,
    item: getItem(state, itemId)
  };
};

export default connect(mapStateToProps, { fetchCompany, fetchCategories, fetchPriceLists, fetchItem, fetchPrinterLocations })(ItemFormContainer);
