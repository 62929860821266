 
export const updateActiveFilter = filter => dispatch => {
  dispatch({
    type: 'UPDATE_ACTIVE_FILTER_ORDER_ITEMS',
    filter
  });
};

export const updateFilter = (type, filter) => dispatch => {
  dispatch({
    type: 'UPDATE_FILTER_ORDER_ITEMS',
    filterType: type,
    filter
  });
};

 