import Api from './consts';
import fetch from './commonFetch';

export const getPriceLists = companyId => {
  const { url, method } = Api.priceLists.getAll;
  return fetch(url(companyId), { method }, 'priceLists');
};

export const insertPriceList = (companyId, priceList) => {
  const { url, method } = Api.priceLists.add;
  const body = JSON.stringify(priceList);
  return fetch(url(companyId), { method, body }, 'priceLists');
};
