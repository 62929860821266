import React, { } from 'react'

import './salesReports.css'
import ViewTables from './ViewTables'
import ViewSummary from './ViewSummary'

const SalesReports = (props) => {

  const { filterGroup, tableColumns, tablesData, totalSum, visibleTotalSum, loading } = props
  return (
    <div>
      <ViewTables
        loading={loading}
        tableColumns={tableColumns}
        tablesData={tablesData}
        filterGroup={filterGroup}
      />
      <ViewSummary
        loading={loading}
        data={totalSum}
        visible={visibleTotalSum}
        indexKey={tableColumns[0].indexKey}
      />
    </div>
  )
}

export default SalesReports