import React from 'react';
const SettingsDescription = ({
  click, title, description, disabled
}) => {
  const color = disabled ? '#77555652' : '#555652';
  const cursor = disabled ? 'not-allowed' : 'pointer';
  const handleClick = disabled ? null : click;

  return (
    <div onClick={handleClick} style={{ marginTop: 15, marginBottom: 15, color, cursor }}>
      <span style={{ fontWeight: 600, color, fontSize: 20 }}>{title}</span>
      <br />
      <span style={{ fontSize: 15 }}>{description}</span>
    </div>
  );
};

export default SettingsDescription;
