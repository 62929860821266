import React, { Component } from 'react';
import { Modal, Form, Input } from 'antd';

class PrinterLocationModalForm extends Component {
  render () {
    const { visible, onCancel, onCreate, printerLocationName, printerLocationNameStatus, handleOnChange } = this.props;

    return (
      <Modal
        visible={visible}
        title='Add Printer Location'
        okText='Save'
        okButtonProps={{ className: 'primary-button' }}
        onCancel={onCancel}
        onOk={onCreate}
      >
        <Form layout='vertical'>
          <Form.Item label='Location Name' validateStatus={printerLocationNameStatus}>
            <Input value={printerLocationName} onChange={event => handleOnChange('printerLocation', event.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default PrinterLocationModalForm;
