import React, { Component } from 'react';
import { Row, Col } from 'antd';
import withRouter from 'react-router/es/withRouter';

class PaymentContainer extends Component {
  settingsDescription = ({
    click, title, description
  }) => (
    <Col lg={12} md={12} sm={24} xs={24} onClick={click} style={{ marginTop: 15, marginBottom: 15, color: '#555652', cursor: 'pointer' }}>
      <span style={{ fontWeight: 600, color: '#555652', fontSize: 20 }}>{title}</span>
      <br />
      <span style={{ fontSize: 15 }}>{description}</span>
    </Col>
  );

  render () {
    return (
      <Row gutter={5}>
        <Row gutter={50} style={{ marginBottom: 15, marginRight: 30 }}>
          {this.settingsDescription({
            title: 'Subscriptions',
            description: 'You can find your all subscriptions here.',
            click: () => this.props.history.push('/billing/subscriptions')
          })}
          {this.settingsDescription({
            title: 'Devices',
            description: 'You can find your all connected devices here.',
            click: () => this.props.history.push('/billing/devices')
          })}
          {this.settingsDescription({
            title: 'Invoices',
            description: 'You can find your business related invoice here.',
            click: () => this.props.history.push('/payments/invoices')
          })}
          {this.settingsDescription({
            title: 'Payments',
            description: 'To see your receipts click here.',
            click: () => this.props.history.push('/payments/receipts')
          })}
          {this.settingsDescription({
            title: 'Agent Details',
            description: 'You can find your agent details here.',
            click: () => this.props.history.push('/billing/agent')
          })}
          {/* {this.settingsDescription({
            title: 'Commissions',
            description: 'You can find comission percentage applied for your transaction.',
            click: () => this.props.history.push('/billing/commissions')
          })} */}
        </Row>
      </Row>
    );
  }
}

export default withRouter(PaymentContainer);
