import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { getAuthenticity } from '../../../reducers';
import { verifyOtpCode } from '../../../actions/auth';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';

class VerifyOtp extends Component {
    state = {
        code: undefined,
        submitting: false,
        verifyStatus: undefined
    }

    showLabel = value => (value && value.length) > 0 ? 'visible' : 'hidden';

    handleOnChange = (value) => {
            this.setState({
                code: value,
                verifyStatus: this.getValidationStatus(value)
            });
    }

    getValidationStatus = (value) =>{
        return value && value.length > 5 ? 'success' : 'error';
    }

    verifyOtp = () => {
        const { authData, userId } = this.props;
        const { code, phone } = this.state;
    
        this.setState({ submitting: true });
        this.props.verifyOtpCode({ otp: code, userId: userId, mobileverified: false }).then(response => {
            const { verifiedData, error } = response;

            if (verifiedData) {
                this.setState({ submitting: false });
                showSuccessNotification('Success', 'OTP Verification passed');
                this.props.handleSubmit('Verify OTP', verifiedData);
            } else {
                this.setState({ submitting: false });
                showErrorNotification('OTP Verification Failed.', error);
            }
        });
    }

    handleBackPress = () => {
        this.props.handleBackPress('Verify OTP');
    }

    render(){
        const {code, submitting, verifyStatus} = this.state;

        return(
            <div className='text-center'>
                <h2 className='signUp-form-title'>OTP verification </h2>
                <p className='signUp-form-description'>Enter the OTP code that receive to your email.</p>
                <Form className='signUp-form'>
                    <Form.Item colon={false} validateStatus={this.state.verifyStatus} label={<span style={{ visibility: this.showLabel(code) }} className='incyInputLabel'>OTP Code</span>}>
                        <Input size='middle' placeholder='OTP code' onChange={e => this.handleOnChange( e.target.value)} />
                    </Form.Item>
                    <div className='isoInputWrapper'>
                        <Button type='primary' size='middle' onClick={() => this.verifyOtp()} loading={this.state.submitting}>
                            Send
                        </Button>
                        <Button style={{ marginLeft: 10 }} size='middle' onClick={() => this.handleBackPress()} loading={this.state.submitting}>
                            Back
                        </Button>
                    </div>  
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return { authData : getAuthenticity(state) };
}

export default connect(mapStateToProps, {verifyOtpCode})(VerifyOtp);