import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Collapse, Icon, Statistic, Button } from 'antd';
import { withRouter } from 'react-router';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import Invoices from '../Payments/Invoices/InvoiceListContainer';
// import ReceiptTemplate from '../ReceiptView/receiptTemplate';
import moment from 'moment';
import CashPaymentForm from './cashPaymentForm';
import CardPaymentForm from './cardPaymentForm';
import ChequePaymentForm from './chequePaymentForm';
import BankDepositPaymentForm from './bankDepositPaymentForm';
import { addPayment } from '../../actions/receipts';
import { fetchInvoicesForCustomer } from '../../actions/invoices';
import { getInvoicesForCustomer, getAuthenticity, getCompanyById } from '../../reducers';
import { showSuccessNotification, showErrorNotification } from '../../components/Notification';
import SideBar from '../../components/SideBar';

const { Panel } = Collapse;

class Settlement extends Component {
  defaultReceipt = {
    paymentType: undefined,
    description: '',
    customerId: undefined,
    invoiceIds: [],
    amount: 0,
    remarks: '',
    userId: undefined
  }

  state = {
    receipt: {
      ...this.defaultReceipt
    },
    paymentDetails: {
      cashPayment: {
        paymentType: 'CASH',
        remarks: '',
        amount: 0
      },
      cardPayment: {
        paymentType: 'CARD',
        cardNumber: '',
        cardExpiry: '',
        cvCode: '',
        nameOfCard: '',
        remarks: '',
        amount: 0
      },
      chequePayment: {
        paymentType: 'CHEQUE',
        chequeBankName: undefined,
        chequeNumber: '',
        chequeDate: moment().add(1, 'days'),
        remarks: '',
        amount: 0
      },
      bankDepositPayment: {
        paymentType: 'BANK_DEPOSIT',
        bank: undefined,
        branch: undefined,
        bankAccountNumber: undefined,
        depositDate: moment(),
        remarks: '',
        amount: 0
      }
    },

    invoiceIds: undefined,
    invoicesLoading: false,
    errorReceiptAmount: undefined,
    submitting: false,
    activePanelKey: 'cashPayment'
  }

  componentDidMount = () => {
    const { authenticity: { user: { userId } } } = this.props;

    this.setState({ invoicesLoading: true });
    this.props.fetchInvoicesForCustomer(userId).then(() => {
      this.setState({ invoicesLoading: false });
    });
  }

  handleOnChange = (key, field, value) => {
    const { paymentDetails } = this.state;
    switch (key) {
      case 'cashPayment':
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            cashPayment: { ...paymentDetails.cashPayment, [field]: value }
          }
        });
        break;
      case 'cardPayment':
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            cardPayment: { ...paymentDetails.cardPayment, [field]: value }
          }
        });
        break;
      case 'chequePayment':
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            chequePayment: { ...paymentDetails.chequePayment, [field]: value }
          }
        });
        break;
      case 'bankDepositPayment':
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            bankDepositPayment: { ...paymentDetails.bankDepositPayment, [field]: value }
          }
        });
        break;
      default:
        console.log('No change');
    }
  }

  handleOnClickSelectInvoices = (invoiceIds, emailRequestList, selectedRowKeys, selectedRows) => {
    const { paymentDetails } = this.state;
    const invoiceTotal = this.getTotal(selectedRows);

    this.setState({
      invoiceIds,
      selectedRowKeys,
      selectedRows,
      invoiceSelectStatus: this.getValidationStatus('invoice', invoiceIds),
      paymentDetails: {
        ...paymentDetails,
        cashPayment: { ...paymentDetails.cashPayment, amount: invoiceTotal },
        cardPayment: { ...paymentDetails.cardPayment, amount: invoiceTotal },
        chequePayment: { ...paymentDetails.chequePayment, amount: invoiceTotal },
        bankDepositPayment: { ...paymentDetails.bankDepositPayment, amount: invoiceTotal }
      }
    });
  }

  handleConfirmPayment = type => {
    const { receipt, invoiceIds } = this.state;
    const request = this.createReceiptRequest(type);

    if (!this.validateSubmission(request)) return;

    this.setState({ submitting: true });
    this.props.addPayment(request).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({
          submitting: false,
          paymentSelectStatus: this.getValidationStatus('payment', receipt),
          invoiceSelectStatus: this.getValidationStatus('invoice', invoiceIds)
        });
      } else {
        const defaultReceipt = { ...this.defaultReceipt };
        const { settleData } = response;

        this.setState({
          receipt: defaultReceipt,
          submitting: false,
          invoiceIds: undefined,
          selectedRowKeys: [],
          paymentSelectStatus: false,
          invoiceSelectStatus: false,
          invoicesLoading: false
        });
        showSuccessNotification('Transaction completed.', 'Payment settled successfully.');
        settleData && this.redirectToPrint(settleData.receipt);
      }
    });
  }

  redirectToPrint = receipt => this.props.history.replace('/printReceipt/' + receipt.id + '/' + receipt.customerId)

  createReceiptRequest = type => {
    const { receipt, invoiceIds, paymentDetails } = this.state;
    const { authenticity } = this.props;

    return {
      ...receipt,
      ...paymentDetails[type],
      customerId: authenticity.user.userId,
      invoiceIds,
      userId: authenticity.user.userId
    };
  }

  getInvoiceTotal = () => {
    const { selectedRows = [] } = this.state;
    let invoiceTotal = 0;
    selectedRows.forEach(row => {
      invoiceTotal = invoiceTotal + row.balanceToSettle;
    });
    return invoiceTotal;
  }

  getTotal = selectedRows => {
    let invoiceTotal = 0;
    selectedRows.forEach(row => {
      invoiceTotal = invoiceTotal + row.balanceToSettle;
    });
    return invoiceTotal;
  }

  validateSubmission = request => {
    const { amount, invoiceIds } = request;
    const validForm = (amount > 0 && (invoiceIds && invoiceIds.length > 0));

    if (!validForm) {
      this.setState({
        paymentSelectStatus: amount > 0,
        invoiceSelectStatus: (invoiceIds && invoiceIds.length > 0)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'invoice':
        return !!this.isValidInvoiceSelection(value);
      case 'payment':
        return !!this.isValidPaymentSelection(value);
      case 'amount':
        return this.isValidPaymentAmountSelection(value);
      default:
        return null;
    }
  }

  isValidInvoiceSelection = invoices => invoices && invoices.length > 0;
  isValidPaymentSelection = receipt => receipt && receipt.receiptamount > 0;
  // isValidPaymentAmountSelection = receipt => {
  // 	const { selectedRows = [] } = this.state;
  // 	const receiptTotal = receipt && receipt.amount
  // 	let settleTotal = 0;
  // 	selectedRows.length > 0 && selectedRows.forEach(row => {
  // 		settleTotal = settleTotal + row.balancetosettle;
  // 	})

  // 	return receiptTotal && receiptTotal > 0 && receiptTotal <= settleTotal;
  // }

  isValidReceiptAmount = amount => {
    const { selectedRows = [] } = this.state;
    let settleTotal = 0;
    selectedRows.length > 0 && selectedRows.forEach(row => {
      settleTotal = settleTotal + row.balancetosettle;
    });

    return amount && amount > 0 && amount <= settleTotal;
  }

  renderPaymentSection = () => {
    const { invoices, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const {
      paymentDetails = {},
      activePanelKey
    } = this.state;

    const customerName = (invoices && invoices.length > 0) ? invoices[0].customerName : '';
    const invoiceTotal = this.getInvoiceTotal();
    const receiptAmount = paymentDetails[activePanelKey] && paymentDetails[activePanelKey].amount;
    const outstandingAmount = invoiceTotal - receiptAmount;

    return (
      <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 25 }}>
        <Col lg={24} md={24} sm={24} xs={24} style={{ backgroundColor: 'white' }}>
          <Statistic valueStyle={{ fontSize: 16 }} title={<span style={{ fontSize: 14, fontWeight: 500, color: 'black' }}>Customer</span>} value={customerName} />
          <Statistic valueStyle={{ fontSize: 16 }} title={<span style={{ fontSize: 14, fontWeight: 500, color: 'black' }}>Receipt Date</span>} value={moment().format('YYYY-MM-DD')} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} style={{ backgroundColor: 'white' }}>
          <Row style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 150, fontSize: 14, fontWeight: 500, color: 'black' }}>Invoice Total</div>
              <div style={{ width: 100, textAlign: 'end' }}>{format(invoiceTotal, currencyFormat(business))}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 150, fontSize: 14, fontWeight: 500, color: 'black' }}>Receipt Amount</div>
              <div style={{ width: 100, textAlign: 'end' }}>{format(receiptAmount, currencyFormat(business))}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid gainsboro' }}>
              <div style={{ width: 150, fontSize: 14, fontWeight: 500, color: 'black' }}>Outstanding</div>
              <div style={{ width: 100, textAlign: 'end' }}>{format(outstandingAmount, currencyFormat(business))}</div>
            </div>
          </Row>
        </Col>
      </Col>
    );
  }

  render() {
    const { banks, match, invoices, company } = this.props;
    const { customerId } = match.params;
    const business = company && company.businesses && company.businesses['0'];
    const {
      submitting,
      selectedRowKeys,
      selectedRows,
      invoiceSelectStatus,
      invoicesLoading,
      paymentDetails = {}
    } = this.state;

    const customerName = invoices && invoices.length > 0 && invoices[0] && invoices[0].customerName;

    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Row>
              <Col>
                <Col
                  lg={invoiceSelectStatus ? 17 : 24} md={invoiceSelectStatus ? 17 : 24} sm={24} xs={24}
                  style={{ padding: 5 }}
                >
                  <Card
                    title={customerName && <h4 style={{ fontWeight: 400 }}><span style={{ fontSize: 20, fontWeight: 300 }}>{customerName}'s Outstanding payments</span></h4>}
                    extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
                  >
                    <Invoices
                      selectedRows={selectedRows}
                      selectedRowKeys={selectedRowKeys}
                      showOutstandingInvoices={false}
                      handleOnClickSelectInvoices={this.handleOnClickSelectInvoices}
                      invoicesLoading={invoicesLoading}
                      showTotals={false}
                      enableRowSelection
                      customerId={customerId}
                      query={{ onlyOutstanding: true }}
                      enableActions={false}
                      fixOutstanding
                      fixAmount
                    />
                  </Card>
                </Col>
                {invoiceSelectStatus && <Col lg={7} md={7} sm={24} xs={24} style={{ padding: 5 }}>
                  <Collapse
                    style={{ padding: 10 }}
                    defaultActiveKey='cashPayment'
                    bordered={false}
                    accordion
                    onChange={(key) => this.setState({ activePanelKey: key })}
                  >
                    <Panel
                      showArrow={false}
                      header={<h5>Cash Payment</h5>}
                      key='cashPayment'
                      extra={<Icon className='icon-money' />}
                    >
                      {this.renderPaymentSection()}
                      <CashPaymentForm
                        cashPayment={paymentDetails.cashPayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={this.getInvoiceTotal()}
                        handleConfirmPayment={this.handleConfirmPayment}
                      />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={<h5>Card Payment</h5>}
                      key='cardPayment'
                    >
                      {this.renderPaymentSection()}
                      <CardPaymentForm
                        cardPayment={paymentDetails.cardPayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={this.getInvoiceTotal()}
                        handleConfirmPayment={this.handleConfirmPayment}
                      />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={<h5>Cheque Payment</h5>}
                      key='chequePayment'
                    >
                      {this.renderPaymentSection()}
                      <ChequePaymentForm
                        banks={banks}
                        chequePayment={paymentDetails.chequePayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={this.getInvoiceTotal()}
                        handleConfirmPayment={this.handleConfirmPayment}
                      />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={<h5>Bank Deposit</h5>}
                      key='bankDepositPayment'
                    >
                      {this.renderPaymentSection()}
                      <BankDepositPaymentForm
                        banks={banks}
                        bankDepositPayment={paymentDetails.bankDepositPayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={this.getInvoiceTotal()}
                        handleConfirmPayment={this.handleConfirmPayment}
                      />
                    </Panel>
                  </Collapse>
                </Col>}
              </Col>
              {/* {newReciptId && <Col lg={24} md={24} sm={24} xs={24} style={{ padding: 5 }}>
								<ReceiptTemplate receiptId={newReciptId} />
							</Col>} */}
              <Col lg={24} md={24} sm={24} xs={24} style={{ float: 'right', marginTop: 5, marginBottom: 5 }}>
                <Col lg={3} md={3} sm={24} xs={24} style={{ float: 'right', marginTop: 5, marginBottom: 10 }}>
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Button
                      className='primary-button' onClick={() => this.props.history.go(-1)} block type='primary'
                      size='small'
                    >Exit
  </Button>
                  </div>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const authenticity = getAuthenticity(state);
  const customerId = authenticity.user.userId;

  const { user: { userId: companyId } } = authenticity;

  return { invoices: getInvoicesForCustomer(state, customerId), authenticity, company: getCompanyById(state, companyId) };
};

export default withRouter(connect(mapStateToProps, { fetchInvoicesForCustomer, addPayment })(Settlement));
