import { add, get, update, confirm, verify, advancedSearch, removeTransferNote } from '../api/transferNotes';

export const fetchTransferNotesFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    console.log(response);
    if (!response.error) {
      dispatch({ type: 'FETCH_TRANSFER_NOTES', transferNoteData: response.transferNoteData });
    }
    return response;
  });
};

export const deleteTransferNote = (clientId, transferNoteId) => dispatch => {
  return removeTransferNote(clientId, transferNoteId).then(response => {
    console.log(response);
    if (!response.error) {
      dispatch({ type: 'DELETE_TRANSFER_NOTE', transferNoteData: response.transferNoteData });
    }
    return response;
  });
};

export const addTransferNote = (companyId, data) => dispatch => {
  return add(companyId, data).then(response => {
    if (!response.error) dispatch({ type: 'ADD_TRANSFER_NOTE', transferNote: response.transferNoteData.transferNote });
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE_ORDERS', orders: response.transferNoteData.orders, transferNoteId: response.transferNoteData.transferNote.id });
    if (!response.error) dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.transferNoteData.orders });
    return response;
  });
};

export const fetchTransferNote = (companyId, transferNoteId) => dispatch => {
  return get(companyId, transferNoteId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE', transferNote: response.transferNote });
    // if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE_ORDERS', orders: response.transferNote.orders, transferNoteId });
    // if (!response.error) dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.transferNote.orders });
    return response;
  });
};

export const updateTransferNote = (companyId, transferNoteId, data) => dispatch => {
  return update(companyId, transferNoteId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE', transferNote: response.transferNoteData.transferNote });
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE_ORDERS', orders: response.transferNoteData.orders, transferNoteId: response.transferNoteData.transferNote.id });
    // if (!response.error) dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.transferNoteData.orders });
    return response;
  });
};

export const confirmTransferNote = (companyId, transferNoteId, data) => dispatch => {
  return confirm(companyId, transferNoteId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE', transferNote: response.transferNoteData.transferNote });
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE_ORDERS', orders: response.transferNoteData.orders, transferNoteId: response.transferNoteData.transferNote.id });
    // if (!response.error) dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.transferNoteData.orders });
    return response;
  });
};

export const verifyTransferNote = (companyId, transferNoteId, data) => dispatch => {
  return verify(companyId, transferNoteId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE', transferNote: response.transferNoteData.transferNote });
    // if (!response.error) dispatch({ type: 'FETCH_TRANSFER_NOTE_ORDERS', orders: response.transferNoteData.orders, transferNoteId: response.transferNoteData.transferNote.id });
    // if (!response.error) dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.transferNoteData.orders });
    return response;
  });
};
