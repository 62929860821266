import {
  advancedSearch,
  generateForSubscriptions,
  getInvoicesForCustomer,
  removeInvoice,
  sendEmails,
  fetchPrintInvoice
} from '../api/invoices';

export const fetchInvoicesFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_INVOICES', invoices: response.invoices });
    }
    return response;
  });
};

export const fetchInvoicesForCustomer = customerId => dispatch => {
  return getInvoicesForCustomer(customerId).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_INVOICES', invoices: response.invoices });
    }
    return response;
  });
};

export const generateInvoicesForSubscriptions = subscriptions => dispatch => {
  const subscriptionMetas = subscriptions.map(subscription => ({ customerId: subscription.customerId, id: subscription.id }));
  return generateForSubscriptions(subscriptionMetas).then(response => {
    if (!response.error) {
      dispatch({ type: 'GENERATE_INVOICES_FOR_SUBSCRIPTIONS', invoices: response.invoices });
    }
    return response;
  });
};

export const removeInvoices = deleteRequest => dispatch => {
  return removeInvoice(deleteRequest).then(response => {
    const { deletedInvoiceResponse } = response;
    if (deletedInvoiceResponse.status) {
      dispatch({ type: 'DELETE_INVOICE', deletedInvoice: deletedInvoiceResponse.deletedInvoice });
    }
    return response;
  });
};

export const sendEmailsForCustomers = emailRequest => dispatch => {
  return sendEmails(emailRequest).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_INVOICES', invoices: response.invoices });
    }
    return response;
  });
};

export const fetchInvoiceForPrint = request => dispatch => {
  return fetchPrintInvoice(request).then(response => {
    console.log('RESPONSE INVOICE ', response);
    const { invoice } = response;
    if (invoice) {
      dispatch({ type: 'FETCH_INVOICE', invoice: invoice });
    }
    return response;
  });
};
