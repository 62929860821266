import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { fetchItemGroupsOfInventoryItem } from '../../../actions/Inventory'
import { getAuthenticity, getSelectedBranchId } from "../../../reducers";

const { Option } = Select;

class SelectItemGroups extends Component {
  state = {
    itemGroups: [],
    loading: false
  }

  componentDidMount = () => {
    const { companyId, selectedBranchId } = this.props;
    const data = { branchIds: [selectedBranchId] };
    this.setState({ loading: true })
    this.props.fetchItemGroupsOfInventoryItem(companyId, data).then(response => {
      const { error, itemGroups } = response;
      this.setState({ loading: false })
      if (!error) {
        this.setState({ itemGroups: itemGroups });
      }
    });
  }

  componentWillReceiveProps = (nexProps) => {
    const { companyId, selectedBranchId } = nexProps;
    if (JSON.stringify(nexProps.selectedBranchId) !== JSON.stringify(this.props.selectedBranchId)) {
      const data = { branchIds: [selectedBranchId] };
      this.setState({ loading: true })
      this.props.fetchItemGroupsOfInventoryItem(companyId, data).then(response => {
        const { error, itemGroups } = response;
        this.setState({ loading: false })
        if (!error) {
          this.setState({ itemGroups: itemGroups });
        }
      });
    }
  }

  render() {
    const {
      onChange,
      showSearch = false,
      placeholder = 'Select Location',
      allowClear = false,
      defaultValue,
      disabled = false,
      style = { width: '100%' }
    } = this.props
    const { itemGroups, loading } = this.state

    return (
      <Select
        placeholder={placeholder}
        value={defaultValue}
        onChange={(value) => onChange('LocCode', value, 'locCodeError')}
        allowClear={allowClear}
        loading={loading}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        style={style}
      >
        {itemGroups.map(category => <Option value={category}>{category}</Option>)}
      </Select>
    )
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return ({
    companyId: companyId,
    selectedBranchId: getSelectedBranchId(state) === 0 ? "0" : getSelectedBranchId(state)
  });
};

export default connect(mapStateToProps, { fetchItemGroupsOfInventoryItem })(SelectItemGroups);