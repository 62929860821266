import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Divider, Icon, Row, Spin, Button } from 'antd';
import { fetchCompany } from '../../actions/companies';
import { getCompanyById } from '../../reducers/index';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import withRouter from 'react-router/es/withRouter';
import { Link } from 'react-router-dom';

class CompanyBasicData extends Component {
	state = {
	  loading: false
	}

	componentDidMount = () => {
	  const { companyId } = this.props.match.params;
	  this.setState({ loading: true });
	  this.props.fetchCompany(companyId).then(() => {
	    this.setState({ loading: false });
	  });
	}

	render () {
	  const { loading } = this.state;
	  const { company } = this.props;
	  const { companyId } = this.props.match.params;
	  const business = company && company.businesses && company.businesses['0'];
	  return (
  <Row gutter={10}>
  <Spin spinning={loading && company && !company.userIdentity}>
  <Row gutter={10}>
      <Col lg={12} md={12} sm={24} xs={24}>
  <table>
  <tbody>
  {business && business.telephone && <tr>
	                  <td style={{ width: 25 }}><Icon type='phone' /></td>
  <td>Contact No</td>
  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
  <td>{business.telephone}</td>
	                </tr>}
	                {company && company.email && <tr>
	                  <td style={{ width: 25 }}><Icon type='mail' /></td>
      <td>Email</td>
      <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
	                  <td>{company.email}</td>
	                </tr>}
  {business && business.address && <tr>
	                  <td style={{ width: 25 }}><Icon type='pushpin' /></td>
      <td>Address</td>
      <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
      <td>{business.address}</td>
    </tr>}
</tbody>
             </table>
           </Col>
      <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
	          <Col lg={12} md={12} sm={24} xs={24}>
	            <table>
          <tbody>
  {business && business.registeredDate && <tr>
  <td style={{ width: 25 }}><Icon type='calendar' /></td>
  <td>Registered Date</td>
  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
	                  <td>{moment(business.registeredDate).format('DD-MM-YYYY')}</td>
                                                         </tr>}
  {business && business.activationCount && <tr>
  <td style={{ width: 25 }}><Icon type='book' /></td>
	                  <td><p>Device Count</p></td>
  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
  <td><p>{business.activationCount}</p></td>
                                                          </tr>}
  <tr>
              <td style={{ width: 25 }}><Icon type='calculator' /></td>
	                  <td>Invoice Total</td>
	                  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
	                  <td>{format(0, currencyFormat(business))}</td>
            </tr>
  <tr>
	                  <td style={{ width: 25 }}><Icon type='disconnect' /></td>
  <td>Wave Off Total</td>
	                  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
	                  <td>{format(0, currencyFormat(business))}</td>
	                </tr>
  <tr>
  <td style={{ width: 25 }}><Icon type='minus-square' /></td>
  <td>Total Outstanding</td>
	                  <td style={{ width: 25, textAlign: 'center' }}><p>:</p></td>
	                  <td>{format(0, currencyFormat(business))}</td>
                 </tr>
               </tbody>
        </table>
	          </Col>
	        </Row>
  <Row gutter={10}>
  <Col lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
  <Link to={'/companies/' + companyId + '/menuConfiguration/categories/defaultMenu'}><Button type='primary'>Setup Menu</Button></Link>
	          </Col>
	        </Row>
	      </Spin>
	    </Row>
	  );
	}
}

const mapStateToProps = (state, props) => {
  const { companyId } = props.match.params;
  return ({ company: getCompanyById(state, companyId) });
};

export default withRouter(connect(mapStateToProps, { fetchCompany })(CompanyBasicData));
