import React, { Component } from 'react';
import { Avatar, Col, Input, Row, Popover, Button } from 'antd';
import { connect } from 'react-redux';
import { getAuthenticity } from '../reducers';
import avatar from '../image/img-avatar.png';
import { logOut } from '../actions/auth';
import fooderLogo from '../image/fooder_logo-white.png';
import incyposLogo from '../image/incypos-logo-white.png';
import bartekaLogo from '../image/bartaka-logo-white.png';
import BranchSelector from './common/BranchSelector';

const { Search } = Input;

class TopBar extends Component {
  componentWillReceiveProps() {
    const headerLogoElement = document.getElementById('header-logo');
    if (headerLogoElement && window.location.hostname === 'mydev.fooder.lk') {
      headerLogoElement.src = fooderLogo;
    } else if (headerLogoElement && window.location.hostname === 'mydev.incypos.com') {
      headerLogoElement.src = incyposLogo;
    } else if (headerLogoElement && window.location.hostname.includes('barteka')) {
      headerLogoElement.src = bartekaLogo;
    }else if (headerLogoElement && window.location.hostname.includes('kiosk')) {
      headerLogoElement.src = bartekaLogo;
    } else if (headerLogoElement) {
      headerLogoElement.src = incyposLogo;
    }
  }

  add3Dots(string, limit) {
    let stringCopy = string;
    const dots = '...';
    if (string.length > limit) {
      stringCopy = stringCopy.substring(0, limit) + dots;
      return stringCopy;
    }

    return string;
  }

  render() {
    const { authenticity, logOut } = this.props;
    const displayTopBar = authenticity && authenticity.user && authenticity.user.customer && authenticity.user.customer.mobileVerified;
    return (
      <div>
        {displayTopBar ? (<Row className='top-bar'>
          <div className='container'>
            <Col lg={4} md={6} sm={6} xs={6}>
              <img alt='logo' id='header-logo' height={35} />
            </Col>
            <Col lg={6} md={6} sm={6} xs={6} style={{ paddingRight: 10 }}>
              <BranchSelector />
            </Col>
            <Col lg={8} md={0} sm={0} xs={0}>
              {/* <Search /> */}
            </Col>
            <Col className='text-right avatar-container' lg={6} md={10} sm={18} xs={18}>
              <Popover
                style={{ marginRight: 15 }}
                placement='bottomRight'
                content={<Button
                  type='link' onClick={() => {
                    window.location = '/';
                    logOut();
                  }}
                >Logout
                         </Button>}
                trigger='click'
              >
                <span className='username'>{this.add3Dots(authenticity.user.username, 20)}</span>
                <Avatar src={avatar} size='large' />
              </Popover>
            </Col>
          </div>
        </Row>) : (
            <div />
          )}

      </div>
    );
  }
}
const mapStateToProps = state => {
  const authenticity = getAuthenticity(state);
  return { authenticity };
};

export default connect(mapStateToProps, { logOut })(TopBar);
