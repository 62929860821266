import React, { Component } from 'react';
import { Avatar, Button, Form, Input, Modal, Popconfirm, Row, Table } from 'antd';
import EditableCell from './EditableCell.js';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class ModifireGroupModalForm extends Component {
  getColumns = () => {
    const columns = [
      {
        title: '',
        dataIndex: 'item',
        key: 'imageSrc',
        render: (item, dataRow) => <Avatar src={item.imageSrc !== undefined ? item.imageSrc : '/images/chefhat.png'} />
      },
      {
        title: 'Name',
        dataIndex: 'item',
        key: 'item',
        editable: false,
        render: (item, dataRow) => <div>{item && item.name ? item.name : dataRow.id}</div>
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        editable: true,
        render: (qty, dataRow) => qty && <div>{qty}</div>
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
        editable: true,
        render: (uom, dataRow) => uom && <div>{uom}</div>
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        align: 'right',
        render: (columnData, rowData) => {
          return <Row>
            <Popconfirm
              title='Are you sure ?'
              okText='DELETE'
              cancelText='NO'
              onConfirm={() => this.props.onRemove(rowData)}
            >
              <Button className='tableButton' type='danger' size='small' icon='delete' />
            </Popconfirm>
          </Row>;
        }
      }
    ];

    return columns.map(col => {
      if (!col.editable) return col;
      return {
        ...col,
        onCell: record => (
          {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.props.handleEditableCellChangeSave,
            Context: EditableContext
          }
        )
      };
    });
  }

  render () {
    const { visible, onCancel, onCreate, handleOnChange, groupNameStatus, groupName, index } = this.props;
    const _index = index;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.getColumns();
    return (
      <Modal
        visible={visible}
        title='Add/Update Group Modifier'
        okButtonProps={{ className: 'primary-button' }}
        okText='Save'
        onCancel={onCancel}
        onOk={() => onCreate(_index)}
      >
        <Form layout='vertical'>
          <Form.Item label='Group Name' validateStatus={groupNameStatus}>
            <Input value={groupName} onChange={event => handleOnChange('groupName', event.target.value)} />
          </Form.Item>

          <div style={{ width: '100%', marginBottom: 10 }}>
            <Button className='primary-button' type='primary' size='small' style={{ width: '100%', marginBottom: 10, padding: 5, height: 30 }} onClick={() => this.props.onItemAddClick('GROUP_MODIFIRE_INGREDIENT')}>Add Ingredient</Button>
          </div>

        </Form>

        <Table
          rowClassName={() => 'editable-row'}
          components={components}
          columns={columns}
          // dataSource={[{ name: 'kkss', qty: 5, uom: 'kg' }, { name: 'sdvs', qty: 41, uom: 'l' }]}
          dataSource={this.props.listofIngredients}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default ModifireGroupModalForm;
