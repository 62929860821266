import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Empty, Icon, Spin } from 'antd';
import withRouter from 'react-router/es/withRouter';
import ItemList from '../MenuItemComponents/ItemList';
// import MenuList from './MenuList';
import { fetchCategories, fetchCategory, addCategory, updateCategory, removeCategory, addItemToCategory, removeItemFromCategory } from '../../actions/categories';
import { fetchItems, updateItem, fetchItem, updateItemPrice, addItemPriceList, fetchItemsForCategory, uploadBulkData } from '../../actions/items';
import { fetchPriceLists, addPriceList } from '../../actions/priceLists';
import { getCompanyById, getPriceLists, getFamilyIdList, getCategories, getCategoryTree, getCategoryData, getAuthenticity } from '../../reducers';
import { fetchCompany } from '../../actions/companies';

import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
// import MenuListSearchForm from "./MenuListSearchForm";
import CategoryFormDrawer from '../MenuCategoryComponents/CategoryFormDrawer';
// import CategoryFormContainer from "../MenuCategoryComponents/CategoryFormContainer";
// import MenuBreadcrumb from './MenuBreadcrumb';
import SidebarMenu from '../SidebarMenu';
import SideBar from '../../components/SideBar';
import CategoryDetailView from '../CategoryDetailView';
import CategoryItemList from '../CategoryItemList';
import { fileToJson } from '../../api/resources';
import ImportMenuModal from '../AllItems/Component/ImportMenuModal';
import CsvDownloader from '../AllItems/Component/CsvDownloader';
import { FiDownload } from 'react-icons/all';

class MenuConfiguration extends Component {
  state = {
    loading: false,
    idFilter: undefined,
    textFilter: undefined,
    fullIdFilter: undefined,
    visibleItemList: false,
    fetching: false,
    selectedPriceList: undefined,
    visiblePriceListModal: false,
    selectedCategoryId: undefined,
    priceList: undefined,
    priceListName: '',
    priceListNameStatus: '',
    visibleCategoryFormDrawer: false,
    routes: [{ path: 'menuConfiguration', breadcrumbName: 'Menu' }],
    categoryFormEditable: false,
    onSuccessAddItem: false,
    submitting: false,
    visibleMenuImport: false
  }

  componentDidMount = () => {
    const { user: { userId: companyId } } = this.props.authData;

    this.setState({ loading: true });
    this.props.fetchCompany(companyId);
    this.props.fetchCategories(companyId).then(() => {
      this.setState({ loading: false });
    });

    this.props.fetchPriceLists(companyId);
  }

  componentWillReceiveProps(nextProps) {
    const { defaultCategory } = nextProps;
    const { priceLists } = this.props;
    if (priceLists.length > 0) { this.setState({ selectedPriceList: priceLists[0] }); }

    const firstCategoryId = defaultCategory && defaultCategory.length > 0 && defaultCategory[0].id;
    if (!this.state.selectedCategoryId) {
      this.setState({ selectedCategoryId: firstCategoryId });
    }
  }

  fetchCategory = id => {
    const { companyId } = this.props;

    this.setState({ fetching: true });
    this.props.fetchCategory(companyId, id).then(() => {
      this.setState({ fetching: false });
    });
  }

  addPriceList = () => this.setState({ visiblePriceListModal: true })

  onCancelPriceList = () => this.setState({ visiblePriceListModal: false })

  onCancelMenuImportModal = () => this.setState({ visibleMenuImport: false })

  onClickSearch = () => {
    this.setState({ fullIdFilter: this.state.idFilter });
  }

  showItemList = (categoryId) => {
    this.setState({ visibleItemList: true, selectedCategoryId: categoryId, onSuccessAddItem: false });
  }

  onCloseItemList = () => this.setState({ visibleItemList: false });

  showCategoryFormDrawer = () => {
    this.setState({ visibleCategoryFormDrawer: true });
  }

  onCloseCategoryFormDrawer = () => this.setState({ visibleCategoryFormDrawer: false });

  onSelectItem = (itemListType, item) => {
    this.setState({ visibleItemList: false });
    this.onAddItemToCategory(item);
  }

  handleEditableCellChangeSave = (record, value) => {
    if (record.children) {
      this.onUpdateCategory(record);
    } else {
      if (record.newPrice) {
        this.onUpdateItemPrice(record, 'menu');
      } else {
        this.onUpdateItem(record, 'menu');
      }
    }
  }

  handleItemEditableCellChangeSave = (record, value) => {
    if (record.newPrice) {
      // this.onUpdateItemPrice(record, , "menu")
    } else {
      this.onUpdateItem(record, 'itemList');
    }
  }

  onAddCategory = (parentCategoryId) => {
    const { company } = this.props;
    const { companyId } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;

    const newCategory = {
      name: 'new categroy',
      businessId: businessId,
      code: '',
      hideFromUsers: false
    };

    this.setState({ submitting: true, loading: true });
    this.props.addCategory(companyId, parentCategoryId, newCategory).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed adding category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategory(companyId, parentCategoryId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ submitting: false, loading: false });
        showSuccessNotification('Category added', 'Category added successfully.');
      }
    });
  }

  onAddItemToCategory = (item) => {
    const { companyId } = this.props;
    this.setState({ loading: true });
    this.props.addItemToCategory(companyId, this.state.selectedCategoryId, { itemId: item.id }).then(response => {
      const { error } = response;
      if (error) {
        this.setState({ loading: false });
        showErrorNotification('Failed adding Item to category', error);
      } else {
        this.props.fetchItemsForCategory(companyId, this.state.selectedCategoryId).then(() => {
          showSuccessNotification('Item Added', 'Item added successfully.');
          this.setState({ loading: false, onSuccessAddItem: true });
        });
        this.setState({ loading: false });
      }
    });
  }

  onUpdateCategory = (updatedObject) => {
    const { companyId } = this.props;
    const category = {
      name: updatedObject.name,
      code: updatedObject.code,
      hideFromUsers: false
    };

    this.setState({ submitting: true, loading: true });
    this.props.updateCategory(companyId, updatedObject.id, category).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategory(companyId, updatedObject.id).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ loading: false });
        showSuccessNotification('Category updated.', 'Category updated successfully.');
      }
    });
  }

  onUpdateItem = (updatedObject, listName) => {
    const { companyId } = this.props;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;

    const item = {
      name: updatedObject.name,
      code: updatedObject.code,
      servings: updatedObject.servings,
      timeToServe: updatedObject.timeToServe
    };

    this.setState({ submitting: true, loading: true });
    this.props.updateItem(companyId, updatedObject.id, item).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating Item', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        if (listName === 'menu') {
          this.props.fetchCategory(companyId, updatedObject.categoryId).then(() => {
            this.setState({ loading: false });
          });
        } else {
          this.props.fetchItem(companyId, updatedObject.id).then(() => {
            this.setState({ loading: false });
          });
        }
        this.setState({ loading: false });
        showSuccessNotification('Item updated.', 'Item updated successfully.');
      }
    });
  }

  onUpdateItemPrice = (updatedObject) => {
    const { companyId } = this.props;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;
    const itemPriceId = this.getItemPriceId(updatedObject.itemPrices);

    if (itemPriceId) {
      this.setState({ submitting: true, loading: true });
      this.props.updateItemPrice(companyId, updatedObject.id, { priceId: itemPriceId, price: parseInt(updatedObject.newPrice) }).then(response => {
        const { error } = response;
        if (error) {
          showErrorNotification('Failed updating Item price', error);
          this.setState({ submitting: false, loading: false });
        } else {
          // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
          this.props.fetchCategory(companyId, updatedObject.categoryId).then(() => {
            this.setState({ loading: false });
          });
          this.setState({ loading: false });
          showSuccessNotification('Item Price updated.', 'Item price updated successfully.');
        }
      });
    } else {
      this.insertNewItemPrice(updatedObject);
    }
  }

  getItemPriceId = itemPricesMap => {
    const { selectedPriceList } = this.state;
    const itemPrices = Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let id;

    itemPrices.forEach(itemPrice => {
      if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) id = itemPrice.id;
    });

    return id;
  }

  insertNewItemPrice = (updatedObject) => {
    const { companyId } = this.props;
    const { selectedPriceList } = this.state;

    this.setState({ loading: true });
    this.props.addItemPriceList(companyId, updatedObject.id,
      { priceListId: selectedPriceList.id, businessId: updatedObject.businessId, price: parseInt(updatedObject.newPrice) }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ submitting: false, loading: false });
        } else {
          this.props.fetchCategory(companyId, updatedObject.categoryId).then(() => {
            this.setState({ loading: false });
          });
          // this.setState({  ingredients :[response.item.ingredients]});
          showSuccessNotification('Added.', 'Item Price Added successfully.');
          this.setState({ submitting: false, loading: false });
        }
      });
  }

  handleDeleteCategory = (categoryId) => {
    const { companyId, history } = this.props;
    this.setState({ submitting: true, loading: true });
    this.props.removeCategory(companyId, categoryId).then(response => {
      const { error, category } = response;
      if (error) {
        showErrorNotification('Failed delete category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategories(companyId).then(() => {
          this.setState({ loading: false, submitting: false, selectedCategoryId: undefined });
          history.replace('/menuConfiguration');
          showSuccessNotification('Category deleted.', 'Category deleted successfully.');
        });
      }
    });
  }

  deleteItem = (row) => {
    const { companyId } = this.props;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;

    this.setState({ submitting: true, loading: true });
    this.props.removeItemFromCategory(companyId, row.categoryId, { itemId: row.id }).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed delete Item from Category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategory(companyId, row.categoryId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ loading: false });
        this.setState({ submitting: false, loading: false });
        showSuccessNotification('Item deleted.', 'Item successfully deleted from category.');
      }
    });
  }

  handlePriceListChange = (value) => {
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ selectedPriceList: priceList[0] });
    }
  }

  handleOnChange = (field, value) => {
    const { company } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;
    // const businessId = item.businessId;
    switch (field) {
      case 'priceList':
        var Obj = { name: value, id: value, businessId: businessId };
        this.setState({ priceList: Obj, priceListName: value, priceListNameStatus: '' });
        break;
      default:
    }
  }

  onAddNewPriceList = () => {
    const { companyId } = this.props;

    if (this.state.priceListName.length > 0) {
      this.setState({ loading: true });
      this.setState({ visiblePriceListModal: false });
      this.props.addPriceList(companyId, this.state.priceList).then(response => {
        const { error, priceLists } = response;
        if (error) {
          this.setState({ loading: false });
          showErrorNotification('Failed adding Price List', error);
        } else {
          showSuccessNotification('Price List added', 'Price List added successfully.');
          this.props.fetchPriceLists(companyId);
          this.setState({ priceListName: '', priceListNameStatus: '', newPriceList: priceLists });
          this.setState({ loading: false });
        }
      });
    } else {
      this.setState({ priceListNameStatus: 'error' });
    }
  }

  handleNavigate = (data, type) => {
    this.setState({ textFilter: undefined });
    if (type === 'CATEGORY') {
      this.props.history.replace({
        pathname: '/menuConfiguration/categories/' + data.id,
        state: { type }
      });
    } else {
      this.props.history.push({
        pathname: '/items/' + this.props.companyId + '/' + data.id,
        state: { type }
      });
    }
  }

  handleTextFilterInputChange = value => this.setState({ textFilter: value })

  onCategorySelect = key => {
    this.fetchCategory(key);
    this.handleNavigate({ id: key }, 'CATEGORY');

    this.setState({ selectedCategoryId: key });
  }

  handleRefreshMenu = () => {
    const { companyId } = this.props;
    this.setState({ loading: true });
    this.props.fetchCategories(companyId).then(() => {
      this.setState({ loading: false });
    });
  }

  renderEmptyMessageComponent = () => {
    const { categoryId } = this.props;

    return <Empty
      image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
      imageStyle={{
        height: 60
      }}
      description={
        <span>
          <h3> Add Category Items</h3>
          <p>Your online menu will show these items. Add your first item to this category</p>
        </span>
      }
    >
      <Button type='primary' onClick={() => this.showItemList(categoryId)}>Add Item</Button>
    </Empty>;
  }

  handleCategoryFormEditable = () => this.setState({ categoryFormEditable: !this.state.categoryFormEditable })

  handleFileChosen = file => {
    const { user: { userId: companyId } } = this.props.authData;
    const fileName = 'temp_' + new Date().getTime() + '_' + companyId;
    if (file.name.includes('.csv')) {
      this.setState({ submitting: true, visibleMenuImport: false });
      fileToJson(file, 'temp/', fileName).then((response) => {
        if (response.error) {
          this.setState({ submitting: false });
          showErrorNotification('error', 'Import Failed. ' + response.error);
        } else {
          const { responseData } = response;
          this.props.uploadBulkData(companyId, { data: responseData }).then(response => {
            const { error } = response;
            if (error) {
              showErrorNotification('error', 'Import Failed. ' + error);
              this.setState({ submitting: false });
            } else {
              this.props.fetchItems(companyId).then(() => {
                this.props.fetchCategories(companyId).then(() => {
                  this.props.fetchPriceLists(companyId).then(() => {
                    this.setState({ submitting: false });
                    showSuccessNotification('success', 'Items imported successfully');
                  });
                });
              });
            }
          });
        }
      }).catch(error => {
        showErrorNotification('error', 'Upload failed: ' + error);
        this.setState({ submitting: false });
      });
    } else {
      showErrorNotification('Error', 'Unsupported file format...');
    }
  }

  handleFileChosenRemove = () => {
    this.setState({ resources: [], invalidResources: [], readPercentage: undefined });
  }

  render() {
    const { visibleItemList, loading, visibleCategoryFormDrawer, selectedCategoryId } = this.state;
    const { categoryId } = this.props.match.params;
    const { companyId, company, defaultCategory } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    // const type = this.props.location && this.props.location.state && this.props.location.state.type
    // const SidebarMenuWidth = defaultCategory && defaultCategory.length > 0 ? 7 : 0;

    return (
      <div className='container'>
        {business && !business.active && <p style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, color: 'red' }}>This business is no longer active.</p>}
        <Row gutter={[10, 10]}>
          <ItemList
            companyId={companyId}
            submitting={loading}
            type='ALL'
            visible={visibleItemList}
            onClose={this.onCloseItemList}
            onItemSearch={this.onItemSearch}
            onSelectItem={this.onSelectItem}
            handleItemEditableCellChangeSave={this.handleItemEditableCellChangeSave}
          />
          <CategoryFormDrawer
            visible={visibleCategoryFormDrawer}
            onClose={this.onCloseCategoryFormDrawer}
            parentCategoryId={selectedCategoryId}
            companyId={companyId}
            company={company}
            editable
          />
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Spin spinning={this.state.submitting}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <ImportMenuModal visible={this.state.visibleMenuImport} onCancel={this.onCancelMenuImportModal} handleFileChosen={this.handleFileChosen} handleFileChosenRemove={this.handleFileChosenRemove} />

                <Col lg={14} md={14} sm={24} xs={24}>
                  <h3 style={{ fontWeight: 400, fontSize: 24 }}>Menu Editor</h3>
                </Col>
                <Col lg={10} md={10} className='text-right'>
                  <Button type='primary' onClick={() => this.setState({ visibleMenuImport: true })} className='btn-orange primary-button' style={{ marginRight: 5, marginLeft: 5 }}>
                  <FiDownload style={{ marginRight : 5 }} /> Import
                  </Button>
                  <CsvDownloader companyId={companyId} company={company} />
                </Col>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <SidebarMenu
                  selectedIdList={[selectedCategoryId]}
                  loading={loading}
                  onClickAddCategory={this.showCategoryFormDrawer}
                  onSelectCategory={this.onCategorySelect}
                  defaultCategory={defaultCategory}
                />
              </Col>
              <Col lg={18} md={18} sm={24} xs={24}>
                <CategoryDetailView onDeleteCategory={this.handleDeleteCategory} categoryId={categoryId || selectedCategoryId} companyId={companyId} onRefreshMenuList={this.handleRefreshMenu} />
                <CategoryItemList categoryId={categoryId || selectedCategoryId} onSuccessAddItem={this.state.onSuccessAddItem} companyId={companyId} onClickAddItem={this.showItemList} />
                {/* <PageHeader */}
                {/*  style={{ margin: 5 }} */}
                {/*  ghost={false} */}
                {/*  title={<MenuBreadcrumb companyId={companyId}/>} */}
                {/* > */}

                {/*  {(type === 'CATEGORY' || !type) && <Card> */}
                {/*    <CategoryFormContainer editButtonComponent={<div> */}
                {/*      <Button */}
                {/*        className={'tableButton'} */}
                {/*        type={'primary'} */}
                {/*        size='small' */}
                {/*        style={{ float: 'right'}} */}
                {/*        icon={!categoryFormEditable ? 'edit' : 'close'} */}
                {/*        onClick={this.handleCategoryFormEditable}>{!categoryFormEditable ? 'Edit' : 'Close'}</Button> */}
                {/*    </div>} editable={categoryFormEditable} categoryId={categoryId} companyId={companyId} company={company}/> */}
                {/*  </Card> } */}
                {/*  <Card style={{ marginTop: 10, borderRadius: 10 }}> */}
                {/*    {menuList && menuList.length > 0 ? <div> */}
                {/*        <div style={{ marginBottom: 5, textAlign: 'end' }}> */}
                {/*          <Button */}
                {/*            className={'tableButton'} */}
                {/*            type={'primary'} */}
                {/*            size='small' */}
                {/*            icon={'plus'} */}
                {/*            onClick={() => this.showItemList(categoryId)}>Add Item</Button> */}
                {/*        </div> */}
                {/*        <MenuListSearchForm */}
                {/*          visiblePriceListModal={visiblePriceListModal} */}
                {/*          onCancelPriceList={this.onCancelPriceList} */}
                {/*          handleOnChange={this.handleOnChange} */}
                {/*          priceListName={this.state.priceListName} */}
                {/*          priceListNameStatus={this.state.priceListNameStatus} */}
                {/*          onAddNewPriceList={this.onAddNewPriceList} */}
                {/*          onSearchTextChange={this.handleTextFilterInputChange} */}
                {/*          textFilter={textFilter} */}
                {/*          selectedPriceList={selectedPriceList} */}
                {/*          handlePriceListChange={this.handlePriceListChange} */}
                {/*          addPriceList={this.addPriceList} */}
                {/*          priceLists={priceLists} */}
                {/*          addItemButtonComp={<Button key="addItem" onClick={() => this.showItemList(categoryId)}>Add Item</Button>}/> */}
                {/*        <Spin spinning={fetching || loading}> */}
                {/*          <MenuList */}
                {/*            priceListId={selectedPriceList && selectedPriceList.id} */}
                {/*            companyId={companyId} */}
                {/*            fetchCategory={this.fetchCategory} */}
                {/*            loader={fetching || loading} */}
                {/*            handleEditableCellChangeSave={this.handleEditableCellChangeSave} */}
                {/*            onItemAddClick={(categoryId) => this.showItemList(categoryId)} */}
                {/*            onAddCategory={(categoryId) => this.onAddCategory(categoryId)} */}
                {/*            onDelete={(isCategory, row) => this.onDelete(isCategory, row)} */}
                {/*            handleChange={this.handleChange} */}
                {/*            onNavigate={this.handleNavigate} */}
                {/*            categoryId={categoryId} */}
                {/*            textFilter={textFilter} */}
                {/*          /> */}
                {/*        </Spin> */}
                {/*    </div> */}
                {/*      : <Spin spinning={fetching || loading}>{this.renderEmptyMessageComponent()}</Spin> } */}
                {/*  </Card> */}
                {/* </PageHeader> */}
              </Col>
            </Spin>
          </Col>
        </Row>

      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { categoryId = 'defaultMenu' } = props.match.params;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return {
    companyId,
    authData,
    company: getCompanyById(state, companyId),
    priceLists: getPriceLists(state),
    familyIdList: getFamilyIdList(state, categoryId, []),
    categories: getCategories(state),
    defaultCategory: getCategoryTree(state),
    menuList: getCategoryData(state, categoryId).subCategories
  };
};

export default withRouter(connect(mapStateToProps, { fetchCategories, fetchCategory, fetchCompany, fetchItems, addCategory, updateCategory, removeCategory, addItemToCategory, updateItem, fetchItem, fetchPriceLists, getPriceLists, removeItemFromCategory, updateItemPrice, addItemPriceList, addPriceList, fetchItemsForCategory, fileToJson, uploadBulkData })(MenuConfiguration));
