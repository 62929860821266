import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card, Select, Form, DatePicker, Button, Icon } from 'antd'
import { connect } from 'react-redux'
import _, { conforms } from 'lodash'
import moment from 'moment'
import ReactToPrint from 'react-to-print'

import {
  getCompanyById,
  getAuthenticity,
  getFilteredOrders,
  getFilteredOrderTotalCount,
  getActiveOrderFilter,
  getReceipts,
  getFilteredCashiers,
  getFilteredReceipts,
  getBusinesses,
  getBranches,
  getFilteredOrderItemsByActiveFilter,
} from '../../reducers'
import {
  fetchOrdersFromAdvancedSearch,
  updateActiveFilter
} from '../../actions/Orders'
import { fetchList } from '../../actions/generic';
import {
  fetchCashiersFromAdvancedSearch,
  updateActiveFilter as updateActiveFilterCashier
} from '../../actions/Cashiers'
import {
  fetchReceiptsFromAdvancedSearch,
  updateActiveFilter as updateActiveFilterReceipt
} from '../../actions/receipts'

import { updateActiveFilter as updateActiveFilterOrderItems } from '../../actions/orderItems';

import SummaryReports from './components/SummaryReports'
import DailyOverViewReports from './components/DailyOverViewReports'

const RangePicker = DatePicker.RangePicker



const summaryCustomerTableColumns = [
  // {
  //   title: 'Customer ID',
  //   dataIndex: 'customerId',
  //   key: 'customerId',
  //   className: 'column-left-align',
  //   indexKey: 'customer'
  // },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
    className: 'column-left-align',
  },
  {
    title: 'NO. Of Invoices',
    dataIndex: 'invoicesCounts',
    key: 'invoicesCounts',
    summable: true,
    noFloat: true
  },
  {
    title: 'Outstanding Invoice Amount',
    dataIndex: 'totalInvoiceAmount',
    key: 'totalInvoiceAmount',
    className: 'column-money',
    summable: true,
    render: text => parseFloat(text).toFixed(2)
  },
  {
    title: 'Paid Amount',
    dataIndex: 'totalPaidAmount',
    key: 'totalPaidAmount',
    className: 'column-money',
    summable: true,
    render: text => parseFloat(text).toFixed(2)
  },
  {
    title: 'Balance To Settle',
    dataIndex: 'balanceToSettle',
    key: 'balanceToSettle',
    className: 'column-money',
    summable: true,
    render: text => parseFloat(text).toFixed(2)
  },
]

const itemTableColumns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    indexKey: 'item',
    className: 'column-left-align column-fixed-width-150',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: 'column-left-align column-fixed-width-250 word-wrap-break',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    className: 'column-left-align',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    className: 'column-money',
    summable: true,
    render: text => parseFloat(text).toFixed(2)
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    className: 'column-money',
    summable: true,
    render: text => parseFloat(text).toFixed(2)
  },
]

const filters = [
  {
    type: 'Sales Summary Report',
    filters: [
      {
        key: 'date',
        text: 'Group by Date'
      },
      {
        key: 'user',
        text: 'Group by User'
      },
      {
        key: 'type',
        text: 'Group by Type'
      },
      {
        key: 'status',
        text: 'Group by Status'
      },
      {
        key: 'N/A',
        text: 'Customer Sales Report'
      },
    ]
  },
  {
    type: 'Item Sales Report',
    filters: [
      {
        key: 'N/A',
        text: 'No Grouping'
      },
      {
        key: 'location',
        text: 'Group by Location'
      },
    ]
  },
  {
    type: 'Customer Outstanding Report',
    filters: []
  },
  // {
  //   type: 'Order Report',
  //   filters: []
  // },

  {
    type: 'Receipt Summary Report',
    filters: [
      {
        key: 'date',
        text: 'Group by Date'
      },
      {
        key: 'user',
        text: 'Group by User'
      },
      {
        key: 'type',
        text: 'Group by Payment Type'
      },
    ]
  },
  {
    type: 'Daily Overview Report',
    filters: []
  },

]

const SalesReports = (props) => { //console.log(props)

  const {
    authData: { user: { userId } },
    updateActiveFilter,
    fetchOrdersFromAdvancedSearch,
    fetchList,
    updateActiveFilterCashier,
    fetchCashiersFromAdvancedSearch,
    updateActiveFilterReceipt,
    fetchReceiptsFromAdvancedSearch,
    updateActiveFilterOrderItems,
    businesses,
    branches
  } = props

  const [filterType, setFilterType] = useState(filters[0].type)
  const [filterGroup, setFilterGroup] = useState(filters[0].filters[0].key)
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "months"),
    moment(),
  ])
  const [filterTempType, setFilterTempType] = useState(filters[0].type)
  const [filterTempGroup, setFilterTempGroup] = useState(filters[0].filters[0].key)
  const [tempDateRange, settempDateRange] = useState([
    moment().subtract(1, "months"),
    moment(),
  ])

  const [searchingOrder, setSearchingOrder] = useState(false)
  const [searchingOrderItems, setSearchingOrderItems] = useState(false)
  const [searchingReceipts, setSearchingReceipts] = useState(false)
  const [searchingCashier, setSearchingCashier] = useState(false)

  const businessIds = businesses.map(b => b.id);
  const branchIds = branches.map(br => br.id);

  const [filter, setFilter] = useState({ ...props.filter, pageSize: undefined, userId: userId, businessIds: businessIds, branchIds: branchIds })

  const printRef = useRef()

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (row.id === '_total_sum_footer_') {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const summaryTableColumns = [
    {
      title: 'Order ID',
      dataIndex: 'number',
      key: 'number',
      className: 'column-left-align',
      indexKey: 'order',
      // render: (text, row, index) => {
      //   if(row.id === '_total_sum_footer_') {
      //     return {
      //       children: text,
      //       props: {
      //         colSpan: 2
      //       }
      //     }
      //   }else{
      //     return text
      //   }
      // }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: text => text ? filterGroup === 'date' ? moment(text).format('hh:mm A') : moment(text).format('DD/MM/YYYY hh:mm A') : '',
      // render: (text, row, index) => {
      //   let _text = text ? filterGroup === 'date' ? moment(text).format('hh:mm A') : moment(text).format('DD/MM/YYYY hh:mm A') : ''
      //   renderContent(_text, row, index)
      // }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      className: 'column-left-align',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'column-left-align',
    },
    {
      title: 'Gross',
      dataIndex: 'subtotal',
      key: 'subtotal',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
    {
      title: 'Service Chg.',
      dataIndex: 'service_charge',
      key: 'service_charge',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
    {
      title: 'Delivery Chg.',
      dataIndex: 'delivery_charge',
      key: 'delivery_charge',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
    {
      title: 'Net',
      dataIndex: 'total',
      key: 'total',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
  ]

  const receiptSummaryTableColumns = [
    {
      title: 'Receipt ID',
      dataIndex: 'number',
      key: 'number',
      className: 'column-left-align',
      indexKey: 'order',
      // render: (text, row, index) => {
      //   if(row.id === '_total_sum_footer_') {
      //     return {
      //       children: text,
      //       props: {
      //         colSpan: 2
      //       }
      //     }
      //   }else{
      //     return text
      //   }
      // }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: text => text ? filterGroup === 'date' ? moment(text).format('hh:mm A') : moment(text).format('DD/MM/YYYY hh:mm A') : '',
      // render: (text, row, index) => {
      //   let _text = text ? filterGroup === 'date' ? moment(text).format('hh:mm A') : moment(text).format('DD/MM/YYYY hh:mm A') : ''
      //   renderContent(_text, row, index)
      // }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      className: 'column-left-align',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'column-left-align',
    },
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      className: 'column-left-align',
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      key: 'total',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
  ]

  const receiptSummaryTableColumnsWithoutUser = [
    {
      title: 'Receipt ID',
      dataIndex: 'number',
      key: 'number',
      className: 'column-left-align',
      indexKey: 'order',
      // render: (text, row, index) => {
      //   if(row.id === '_total_sum_footer_') {
      //     return {
      //       children: text,
      //       props: {
      //         colSpan: 2
      //       }
      //     }
      //   }else{
      //     return text
      //   }
      // }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: text => text ? filterGroup === 'date' ? moment(text).format('hh:mm A') : moment(text).format('DD/MM/YYYY hh:mm A') : '',
      // render: (text, row, index) => {
      //   let _text = text ? filterGroup === 'date' ? moment(text).format('hh:mm A') : moment(text).format('DD/MM/YYYY hh:mm A') : ''
      //   renderContent(_text, row, index)
      // }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      className: 'column-left-align',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'column-left-align',
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      key: 'total',
      className: 'column-money',
      summable: true,
      render: text => parseFloat(text).toFixed(2)
    },
  ]

  const onChangeFilterType = (e) => {
    let _groupFilters = filters.find(filter => filter.type === e).filters
    setFilterTempType(e)
    setFilterTempGroup(
      _groupFilters.length > 0 ? _groupFilters[0].key : ''
    )
  }

  const onChangeFilterGroup = (e) => {
    setFilterTempGroup(e)
  }

  const onChangeDateRange = (e) => {
    if (e[0].format('YYYY/MM/DD') === e[1].format('YYYY/MM/DD')) {
      settempDateRange([
        e[0].startOf('day'),
        e[1].endOf('day'),
      ])
    } else {
      settempDateRange(e)
    }
  }

  const handleOnClickSearch = () => {
    setFilterGroup(filterTempGroup)
    setFilterType(filterTempType)
    setDateRange(tempDateRange)
  }

  const getSummaryFilteredOrders = (orders, groupKey) => {
    if (groupKey === 'user') groupKey = 'user_id';
    let ordersGroupObj = _.groupBy(_.sortBy(orders, groupKey), groupKey)
    return Object.keys(ordersGroupObj).map(key => ({
      key: groupKey === 'user_id' ? (ordersGroupObj[key][0].user_name ? ordersGroupObj[key][0].user_name : key) : key, // fix this line for add user name
      values: _.sortBy(
        ordersGroupObj[key],
        groupKey === 'date' ? 'time' : groupKey
      )
    }))
  }

  const filterSummaryOrders = (orders) => (
    orders.map(o => ({
      ...o,
      date: moment(new Date(o.time)).startOf('day').toDate(),
      _total: o.total,
      total: parseFloat(o.subtotal) + parseFloat(o.tax) + parseFloat(o.service_charge) + parseFloat(o.delivery_charge) - parseFloat(o.discount),
      user: o.user_id ? o.user_id : ''
    }))
  )

  const getSummaryCustomerFilteredOrders = (orders) => {
    let _orders = orders.map(o => ({
      ...o,
      time: moment(new Date(o.time)).startOf('day').toDate()
    }))
    let ordersGroupObj = _.groupBy(_.sortBy(_orders, 'time'), 'customer_id')
    let customerSales = Object.keys(ordersGroupObj).map(key => ({
      id: ordersGroupObj[key][0].customer_id ? ordersGroupObj[key][0].customer_id : 'Cash',
      customerId: ordersGroupObj[key][0].customer_id ? ordersGroupObj[key][0].customer_id : 'Cash',
      customerName: ordersGroupObj[key][0].customer_id ? ordersGroupObj[key][0].customer_name : 'Cash',
      invoicesCounts: ordersGroupObj[key].length,
      totalInvoiceAmount: getSum(ordersGroupObj[key].map(d => d.total)),
      totalPaidAmount: getSum(ordersGroupObj[key].map(d => d.total - d.balance_to_settle)),
      balanceToSettle: getSum(ordersGroupObj[key].map(d => d.balance_to_settle)),
    }))
    return [{
      key: '',
      values: customerSales
    }]
  }

  const getOutStandingCustomerOrders = (orders) => {
    let filteredOrders = orders.filter(order => order.balance_to_settle > 0)
    let _orders = filteredOrders.map(o => ({
      ...o,
      time: moment(new Date(o.time)).startOf('day').toDate()
    }))
    let ordersGroupObj = _.groupBy(_.sortBy(_orders, 'time'), 'customer_id')
    let customerSales = Object.keys(ordersGroupObj).map(key => ({
      id: ordersGroupObj[key][0].customer_id ? ordersGroupObj[key][0].customer_id : '',
      customerId: ordersGroupObj[key][0].customer_id ? ordersGroupObj[key][0].customer_id : '',
      customerName: ordersGroupObj[key][0].customer_id ? ordersGroupObj[key][0].customer_name : 'Cash',
      invoicesCounts: ordersGroupObj[key].length,
      totalInvoiceAmount: getSum(ordersGroupObj[key].filter(d => parseFloat(d.balance_to_settle) > 0).map(d => d.total)),
      totalPaidAmount: getSum(ordersGroupObj[key].filter(d => parseFloat(d.balance_to_settle) > 0).map(d => parseFloat(d.total) - parseFloat(d.balance_to_settle))),
      balanceToSettle: getSum(ordersGroupObj[key].filter(d => parseFloat(d.balance_to_settle) > 0).map(d => d.balance_to_settle)),
    }))
    return [{
      key: '',
      values: customerSales
    }]
  }

  const getAllItemSales = (orders, orderItems) => {
    let itemsGroupObj = getGroupItems(orderItems, 'item_id')
    let itemsGroup = Object.keys(itemsGroupObj).map(key => ({
      id: key,
      code: itemsGroupObj[key][0].code,
      name: itemsGroupObj[key][0].name,
      location: itemsGroupObj[key][0].printer_location_id,
      quantity: getSum(itemsGroupObj[key].map(d => d.quantity)),
      total: getSum(itemsGroupObj[key].map(d => (parseFloat(d.price) - parseFloat(d.discount) * d.quantity)))
    }))
    return [{
      key: '',
      values: itemsGroup
    }]
  }

  const getSummaryFilteredReceipts = (receipts, groupKey) => {
    if (groupKey === 'user') groupKey = 'userId';
    let receiptsGroupObj = _.groupBy(_.sortBy(receipts, groupKey), groupKey)
    return Object.keys(receiptsGroupObj).map(key => ({
      key: groupKey === 'userId' ? receiptsGroupObj[key][0].name : key,
      values: _.sortBy(
        receiptsGroupObj[key],
        groupKey === 'date' ? 'time' : groupKey
      )
    }))
  }

  const filterSummaryReceipts = (receipts) => (
    receipts.map(r => ({
      ...r,
      date: moment(new Date(r.time)).startOf('day').toDate(),
      _total: r.amount,
      total: parseFloat(r.amount),
      user: r.userId ? r.userId : ''
    }))
  )

  const getItemsSalesGroupByLocation = (orders, orderItems) => {
    let itemsGroupObj = getItemsGroupedByPrinterLocation(orderItems)
    return Object.keys(itemsGroupObj).map(locationKey => ({
      key: locationKey,
      values: getGroupItemsInLocation(itemsGroupObj, locationKey)
    }))
  }

  const getGroupItemsInLocation = (arr, lk) => {
    let group = _.groupBy(arr[lk], 'item_id')
    return Object.keys(group).map(itemKey => ({
      id: group[itemKey][0].item_id,
      code: group[itemKey][0].code,
      name: group[itemKey][0].name,
      location: lk,
      quantity: getSum(group[itemKey].map(d => d.quantity)),
      total: getSum(group[itemKey].map(d => parseFloat(d.price) - parseFloat(d.discount)))
    }))
  }

  const getSalesSummany = (orders, columns) => {
    const totalSum = getTotalSum(orders, columns)
    let summary = []
    summary.push({
      id: 'subtotal',
      item: 'Gross Total',
      value: totalSum.values.find(d => d.key === 'subtotal').value
    })
    summary.push({
      id: 'service_charge',
      item: 'Service Charge',
      value: totalSum.values.find(d => d.key === 'service_charge').value
    })
    summary.push({
      id: 'delivery_charge',
      item: 'Delivery Charge',
      value: totalSum.values.find(d => d.key === 'delivery_charge').value
    })
    summary.push({
      id: 'tax',
      item: 'Tax',
      value: totalSum.values.find(d => d.key === 'tax').value
    })
    summary.push({
      id: 'discount',
      item: 'Discount',
      value: totalSum.values.find(d => d.key === 'discount').value
    })
    summary.push({
      id: 'total',
      item: 'Net Total',
      value: totalSum.values.find(d => d.key === 'total').value
    })

    return summary
  }

  const getOrdersSummany = (orders) => {
    const groupOrders = _.groupBy(orders, 'status')
    return Object.keys(groupOrders).map(key => ({
      id: key,
      status: key,
      count: groupOrders[key].length,
      amount: getSum(groupOrders[key].map(d => d.total))
    }))
  }

  const getLocationSummany = (orders, orderItems) => {
    const groups = getItemsSalesGroupByLocation(orders, orderItems)
    return groups.map(group => ({
      id: group.key,
      location: group.key,
      quantity: getSum(group.values.map(d => d.quantity)),
      total: getSum(group.values.map(d => d.total))
    }))
  }

  const getUsersSummary = (orders) => {
    const groups = getSummaryFilteredOrders(orders, 'user')
    return groups.map(group => ({
      id: group.key,
      user: group.key,
      totalSales: getSum(group.values.map(d => d.total)),
      orderCounts: group.values.length,
      serviceCharge: getSum(group.values.map(d => d.service_charge)),
      new: getUserSummaryPerStatus(group.values, 'NEW'),
      confirmed: getUserSummaryPerStatus(group.values, 'CONFIRMED'),
      completed: getUserSummaryPerStatus(group.values, 'COMPLETED'),
      cancelled: getUserSummaryPerStatus(group.values, 'CANCELLED')
    }))
  }

  const getUserSummaryPerStatus = (orders, status) => {
    const group = orders.filter(d => d.status === status)
    return getSum(group.map(d => d.total))
  }

  const getCashiersSummary = (cashiers) => {
    return _.sortBy(cashiers, 'openTime').reverse().map(cashier => ({
      id: cashier.id,
      date: cashier.openTime,
      userName: cashier.uName || cashier.userName,
      openingbalance: cashier.openingBalance || 0,
      systemClosingBalance: cashier.systemClosingBalance || 0,
      actualBalance: cashier.actualClosingBalance || 0,
      excessOrShortage: parseFloat(cashier.actualClosingBalance) - parseFloat(cashier.systemClosingBalance) || 0,
    }))
  }

  const getReceiptsSummary = (receipts) => {
    const groupsObj = _.groupBy(receipts, 'type')
    const _receipts = Object.keys(groupsObj).map(type => ({
      id: type,
      item: type,
      value: getSum(groupsObj[type].map(d => d.amount))
    }))

    return {
      key: '',
      values: _receipts
    }
  }



  const getGroupItems = (arr, key) => {
    let itemsGroupObj = _.groupBy(arr, key)
    return itemsGroupObj
  }

  const getItemsGroupedByPrinterLocation = arr => {
    let itemGroups = {}
    arr.forEach(item => {
      let groupId = item.printer_location_id ? item.printer_location_id : 'No Location';
      let itemGroup = itemGroups[groupId] || []
      itemGroup.push(item)
      itemGroups[groupId] = itemGroup;
    })

    const noLocationGroup = itemGroups['No Location'];
    if (noLocationGroup) {
      delete itemGroups['No Location'];
      itemGroups['No Location'] = noLocationGroup;
    }

    return itemGroups;
  }

  const getNoGroupAllItems = (orders) => {
    let items = []
    orders.forEach(o => items.push(...o.items))
    return items.map(d => ({
      ...d,
      location: d.printer_location_id,
      total: parseFloat(d.price) - parseFloat(d.discount)
    }))
  }

  const getTotalSum = (orders, columns) => {
    let totalSummaries = []
    columns.forEach(col =>
      col.summable &&
      totalSummaries.push({
        key: col.dataIndex,
        value: getSum(orders.map(d => parseFloat(d[col.dataIndex])))
      })
    )

    return {
      length: orders.length,
      values: totalSummaries
    }
  }

  const getSum = values => {
    if (values.length === 0)
      return parseFloat(0).toFixed(2)
    let sum = values.reduce((a, b) => parseFloat(a) + parseFloat(b))
    return parseFloat(sum).toFixed(2)
  }

  const print = () => {
    window.print()
  }

  useEffect(() => {

  }, [])

  useEffect(() => {
    let _filter = {
      ...filter,
      fromDate: dateRange[0].format(),
      toDate: dateRange[1].format()
    }
    setFilter(_filter)
  }, [dateRange])

  useEffect(() => {
    setSearchingOrder(true)
    setSearchingReceipts(true)
    setSearchingOrderItems(true)
    setSearchingCashier(true)

    updateActiveFilter(filter)
    //fetchOrdersFromAdvancedSearch(filter)
    updateActiveFilterOrderItems(filter)
    fetchList('view_user_orders', filter).then(response => {
      setSearchingOrder(false)
    });
    fetchList('order_item', filter).then(response => {
      setSearchingOrderItems(false)
    });
    updateActiveFilterCashier(filter)
    fetchCashiersFromAdvancedSearch(filter).then(response => {
      setSearchingCashier(false)
    })
    updateActiveFilterReceipt(filter)
    fetchReceiptsFromAdvancedSearch(filter).then(response => {
      setSearchingReceipts(false)
    })

  }, [filter])

  return (
    <>
      <Card className='card-container' style={{ marginBottom: 10 }}>
        <Row>
          <Col lg={14} md={14} sm={24} xs={24}>
            <h4 style={{ fontWeight: 400, fontSize: 16 }}>Select your report type</h4>
            <Form layout="inline" >
              <Form.Item
                name="reportType"
              >
                <Select
                  defaultValue={filterTempType}
                  placeholder='Report Type'
                  style={{ minWidth: '240px' }}
                  onChange={onChangeFilterType}
                >
                  {filters.map((filter, intex) => {
                    return <Select.Option key={intex} value={filter.type}>{filter.type}</Select.Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="subReportType"
              >
                {filters.find(filter => filter.type === filterTempType).filters.length > 0 &&
                  <Select
                    value={filterTempGroup}
                    placeholder='Report Type'
                    style={{ minWidth: '200px' }}
                    onChange={onChangeFilterGroup}
                  >
                    {filters.find(filter => filter.type === filterTempType).filters.map((filter, intex) => {
                      return <Select.Option key={intex} value={filter.key}>{filter.text}</Select.Option>
                    })}
                  </Select>
                }
              </Form.Item>

            </Form>
          </Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <h4 style={{ fontWeight: 400, fontSize: 16 }}>Select a date range</h4>
            <Form.Item
              name="dateRange"
              style={{ margin: 0 }}
            >
              <RangePicker defaultValue={tempDateRange} onChange={onChangeDateRange} format={'DD/MM/YYYY'}
              />
            </Form.Item>
          </Col>
          <Col lg={3} md={3} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, height: 99 }}>
            <Button block className='primary-button' type='primary' onClick={() => handleOnClickSearch()}>Search</Button>
            {/* <Button loading={(props.orders && props.orders.length === 0 & searchingOrder) || (props.filteredOrderItems && props.filteredOrderItems.length === 0 & searchingOrderItems) || (props.receipts && props.receipts.length === 0 & searchingReceipts) || (props.cashiers && props.cashiers.length === 0 & searchingCashier)} block className='primary-button' type='primary' onClick={() => handleOnClickSearch()}>Search</Button> */}

          </Col>

        </Row>
      </Card>
      <Card className='card-container'>
        <Row ref={printRef}>
          <Col lg={24}>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <h4 style={{ fontWeight: 200, fontSize: 18 }}>{props.company.businesses[0].name}</h4>
                <p style={{ margin: 0, fontSize: 12 }}>
                  <label style={{ margin: 0, fontWeight: 'bold' }}>{props.company.businesses[0].address}</label><br />
                  <label style={{ margin: 0, fontWeight: 'bold' }}>{props.company.businesses[0].telephone}</label>
                </p>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24} style={{ textAlign: 'right' }}>
                <h4 style={{ fontWeight: 200, fontSize: 18 }}>{filterType}</h4>
                <p style={{ margin: 0, fontSize: 11 }}>
                  <label style={{ margin: 0, fontWeight: 'bold' }}>Print Date/Time:&nbsp;</label>
                  <label style={{ margin: 0, color: '#aaa' }}>{moment().format("DD/MM/YYYY HH:mm A")}</label>
                </p>
                <p style={{ margin: 0, fontSize: 11 }}>
                  <label style={{ margin: 0, fontWeight: 'bold' }}>Selected Date Range:&nbsp;</label>
                  <label style={{ margin: 0, color: '#aaa' }}>
                    {moment(new Date(filter.fromDate)).format('DD/MM/YYYY')} - {moment(new Date(filter.toDate)).format('DD/MM/YYYY')}
                  </label>
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={24}>
                {filterType === 'Sales Summary Report' && filterGroup !== 'N/A' && filterGroup === 'status' &&
                  <SummaryReports
                    loading={searchingOrder}
                    filterGroup={filterGroup}
                    tableColumns={summaryTableColumns}
                    tablesData={getSummaryFilteredOrders(filterSummaryOrders(props.orders), filterGroup)}
                    totalSum={getTotalSum(filterSummaryOrders(props.orders), summaryTableColumns)}
                    visibleTotalSum={
                      filterGroup !== 'N/A' &&
                      filters.find(filter => filter.type === filterType).filters.length !== 0}
                  />}
                {filterType === 'Sales Summary Report' && filterGroup !== 'N/A' && filterGroup !== 'status' &&
                  <SummaryReports
                    loading={searchingOrder}
                    filterGroup={filterGroup}
                    tableColumns={summaryTableColumns}
                    tablesData={getSummaryFilteredOrders(filterSummaryOrders(props.ordersWithoutNewAndCancelledStatus), filterGroup)}
                    totalSum={getTotalSum(filterSummaryOrders(props.ordersWithoutNewAndCancelledStatus), summaryTableColumns)}
                    visibleTotalSum={
                      filterGroup !== 'N/A' &&
                      filters.find(filter => filter.type === filterType).filters.length !== 0}
                  />}
                {filterType === 'Sales Summary Report' && filterGroup === 'N/A' &&
                  <SummaryReports
                    filterGroup={filterGroup}
                    loading={searchingOrder}
                    tableColumns={summaryCustomerTableColumns}
                    tablesData={getSummaryCustomerFilteredOrders(props.orders)}
                    visibleTotalSum={false}
                  />}
                {filterType === 'Item Sales Report' && filterGroup !== 'N/A' &&
                  <SummaryReports
                    filterGroup={filterGroup}
                    loading={searchingOrderItems}
                    tableColumns={itemTableColumns}
                    tablesData={getItemsSalesGroupByLocation(props.orders, props.filteredOrderItems)}
                    totalSum={getTotalSum(getAllItemSales(props.orders, props.filteredOrderItems)[0].values, itemTableColumns)}
                    visibleTotalSum={true}
                  />}
                {filterType === 'Item Sales Report' && filterGroup === 'N/A' &&
                  <SummaryReports
                    filterGroup={filterGroup}
                    loading={searchingOrderItems}
                    tableColumns={itemTableColumns}
                    tablesData={getAllItemSales(props.orders, props.filteredOrderItems)}
                    visibleTotalSum={false}
                  />}
                {filterType === 'Customer Outstanding Report' &&
                  <SummaryReports
                    filterGroup='N/A'
                    loading={searchingOrder}
                    tableColumns={summaryCustomerTableColumns}
                    tablesData={getOutStandingCustomerOrders(props.orders)}
                    visibleTotalSum={false}
                  />}
                {filterType === 'Daily Overview Report' &&
                  <DailyOverViewReports
                    salesSummany={getSalesSummany(filterSummaryOrders(props.orders), summaryTableColumns)}
                    ordersSummany={getOrdersSummany(filterSummaryOrders(props.orders))}
                    locationSummany={getLocationSummany(props.orders, props.filteredOrderItems)}
                    userSummany={getUsersSummary(filterSummaryOrders(props.orders))}
                    cashierSummany={getCashiersSummary(props.cashiers)}
                    receiptsSummary={getReceiptsSummary(props.receipts)}
                    loadingOrders={searchingOrder}
                    loadingOrderItems={searchingOrderItems}
                    loadingCashiers={searchingCashier}
                    loadingReceipts={searchingReceipts}
                  />
                }
                {filterType === 'Receipt Summary Report' && filterGroup !== 'N/A' &&
                  <SummaryReports
                    loading={searchingReceipts}
                    filterGroup={filterGroup}
                    tableColumns={filterGroup !== 'user' ? receiptSummaryTableColumns : receiptSummaryTableColumnsWithoutUser}
                    tablesData={getSummaryFilteredReceipts(filterSummaryReceipts(props.receipts), filterGroup)}
                    totalSum={getTotalSum(filterSummaryReceipts(props.receipts), receiptSummaryTableColumns)}
                    visibleTotalSum={
                      filterGroup !== 'N/A' &&
                      filters.find(filter => filter.type === filterType).filters.length !== 0}
                  />}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col lg={24}>
            <ReactToPrint
              trigger={() =>
                <Button type="primary" style={{ float: 'right' }} onClick={() => print()}>
                  <Icon type="printer" />Print
                </Button>
              }
              content={() => printRef.current}
              bodyClass='print-area'
            />
          </Col>
        </Row>
      </Card>
    </>
  )
}

const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  const orders = getFilteredOrders(state);
  const ordersWithoutNewStatus = orders.filter(o => o.status !== "NEW");
  const ordersWithoutNewAndCancelledStatus = ordersWithoutNewStatus.filter(o => o.status !== "CANCELLED");
  return ({
    companyId,
    company: getCompanyById(state, companyId),
    orders: orders,
    cashiers: getFilteredCashiers(state),
    receipts: getFilteredReceipts(state),
    totalResults: getFilteredOrderTotalCount(state),
    filter: getActiveOrderFilter(state),
    authData,
    businesses: getBusinesses(state),
    branches: getBranches(state),
    filteredOrderItems: getFilteredOrderItemsByActiveFilter(state),
    ordersWithoutNewAndCancelledStatus
    // items: getItems(state),
  })
}

export default connect(mapStateToProps, {
  fetchOrdersFromAdvancedSearch,
  fetchCashiersFromAdvancedSearch,
  fetchReceiptsFromAdvancedSearch,
  updateActiveFilter,
  updateActiveFilterCashier,
  updateActiveFilterReceipt,
  updateActiveFilterOrderItems,
  fetchList
})(SalesReports)