import Api from './consts';
import fetch from './commonFetch';

export const getItems = companyId => {
  const { url, method } = Api.items.getAll;
  return fetch(url(companyId), { method }, 'items');
};

export const get = (companyId, itemId) => {
  const { url, method } = Api.items.get;
  return fetch(url(companyId, itemId), { method }, 'item');
};

export const insertItem = (companyId, item) => {
  const { url, method } = Api.items.add;
  const body = JSON.stringify(item);
  return fetch(url(companyId), { method, body }, 'item');
};

export const modifyItem = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.updateItem;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const modifyItemPrice = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.updateItemPrice;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const insertItemCategories = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.addItemCategories;
  const body = JSON.stringify(data);
  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const insertItemIngredient = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.addItemIngredient;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const insertItemGroupModifire = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.addItemGroupModifire;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const insertItemPriceList = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.insertItemPriceList;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const insertItemPrinterLocation = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.insertItemPrinterLocation;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const deleteItemPrinterLocation = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.deleteItemPrinterLocation;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'item');
};

export const deleteItemPrice = (companyId, itemPriceId, data) => {
  const { url, method, headers } = Api.items.deleteItemPrice;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemPriceId), { method, headers, body }, 'item');
};

export const deleteIngredient = (companyId, ingredientId, data) => {
  const { url, method, headers } = Api.ingredients.delete;
  const body = JSON.stringify(data);

  return fetch(url(companyId, ingredientId), { method, headers, body }, 'item');
};

export const addBulkData = (companyId, data) => {
  const { url, method, headers } = Api.bulkUpload.add;
  const body = JSON.stringify(data);

  return fetch(url(companyId), { method, headers, body }, 'records');
};


export const exportBulkData = (companyId) => {
  const { url, method, headers } = Api.bulkUpload.export;

  return fetch(url(companyId), { method, headers }, 'records');
};


export const removeItems = (companyId, data) => {
  const { url, method, headers } = Api.items.bulkDelete;
  const body = JSON.stringify(data);

  return fetch(url(companyId), { method, headers, body }, 'records');
};

export const getItemsForCategory = (companyId, categoryId) => {
  const { url, method } = Api.items.getForCategory;
  return fetch(url(companyId, categoryId), { method }, 'items');
};

export const getAllItemsForCategory = (companyId, categoryId) => {
  const { url, method } = Api.items.getAllItemsForCategory;
  return fetch(url(companyId, categoryId), { method }, 'items');
};

export const modifyVariantHeaderDetails = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.updateVariantHeaderDetails;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'items');
};

export const updateVariantOptions = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.updateVariantItemOptions;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'items');
};

export const addVariant = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.addVariant;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'variantData');
};

export const removeVariant = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.removeVariant;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'variantData');
};

export const updateVariantDefinition = (companyId, itemId, data) => {
  const { url, method, headers } = Api.items.updateVariantDefinition;
  const body = JSON.stringify(data);

  return fetch(url(companyId, itemId), { method, headers, body }, 'variantData');
};

export const createVariantGroup = (companyId, data) => {
  const { url, method, headers } = Api.items.createVariantGroup;
  const body = JSON.stringify(data);

  return fetch(url(companyId), { method, headers, body }, 'variantData');
};
