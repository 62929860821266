import React, { Component } from 'react';
import './App.css';
import Root from './Root';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { Affix, Layout } from 'antd';
import TopBar from './components/TopBar';
import './styles/theme.css';
const { Header } = Layout;

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Layout>
          <Affix offsetTop={-1}>
            <Header className='header'><TopBar /></Header>
          </Affix>
          <BrowserRouter>
            <div style={{ marginTop: 20 }}>
              <Root />
            </div>
          </BrowserRouter>
        </Layout>
      </Provider>
    );
  }
}

export default App;
