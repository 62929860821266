import React, { Component } from 'react';
import { Button, Form, Input } from 'antd';

class VariantOptionForm extends Component {
  renderFormItems = () => {
    const { variant, handleOnChange } = this.props;
    const formItems = [];
    variant && variant.options && Object.values(variant.options).forEach(option => {
      const errorStatus = (!option.option || (option.option && option.option.length === 0)) ? 'error' : 'success';
      formItems.push(
        <Form.Item
          label={option.variantOptionType.name}
          validateStatus={errorStatus}
        >
          <Input value={option.option} onChange={(event) => handleOnChange('option', { option: { variantOptionType: option.variantOptionType, option: event.target.value }, [option.variantOptionType.name + 'Status']: errorStatus })} />
        </Form.Item>
      );
    });

    return formItems;
  }

  isValidAllFormData = () => {
    const { variant = {} } = this.props;

    let errorCount = variant.options && Object.values(variant.options).length;
    variant.options && Object.values(variant.options).forEach(option => {
      if (option.option && option.option.length !== 0) errorCount--;
    });

    return errorCount === 0;
  }

  render () {
    return (
      <h2>
        <Form layout='horizontal' className='compact-form variant-form'>
          {this.renderFormItems()}
          {!this.props.newVariant && <Form.Item style={{ textAlign: 'end' }}>
            <Button disabled={!this.isValidAllFormData()} type='primary' className='btn-orange' onClick={this.props.onHandleUpdateVariantOptions}>Save</Button>
          </Form.Item>}
        </Form>
      </h2>
    );
  }
}
export default VariantOptionForm;
