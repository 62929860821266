import React, { Component } from 'react';
import { Icon, Row, Col, Divider } from 'antd';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';

class PayoutSummeryDetails extends Component {
  getOrderAmounts = orders => {
    let completedOrderAmount = 0;
    let cancelledOrderAmount = 0;
    let returnOrderAmount = 0;

    let totalInvoiceAmount = 0;// total order amount
    let reconciledOrderAmount = 0;// total reconciled amount
    let totalTransferedOrderAmount = 0;

    let totalCommission = 0;
    let totalSalesCommission = 0;
    let salesCommissionOfTransferedAmount = 0;
    let salesCommissionForCompletedOrders = 0;

    let totalpayableAmountReconciled = 0;
    let totalpayableAmount = 0;
    let totalpayableAmountOfTransferedAmount = 0;
    let totalpayableAmountOfCompletedOrders = 0;

    orders && orders.length > 0 && orders.forEach(order => {
      totalInvoiceAmount = totalInvoiceAmount + order.total;

      if (order.commission) {
        totalCommission = totalCommission + order.commission.value;
      }

      if (order.status === 'COMPLETED') {
        completedOrderAmount = completedOrderAmount + order.total;
        if (order.commission) {
          salesCommissionForCompletedOrders = salesCommissionForCompletedOrders + order.commission.value;
        }
      }
      if (order.status === 'CANCELLED') {
        cancelledOrderAmount = cancelledOrderAmount + order.total;
      }

      if (order.status === 'RETURNED') {
        returnOrderAmount = returnOrderAmount + order.total;
      }

      if (order.platformReconciled) {
        reconciledOrderAmount = reconciledOrderAmount + order.total;
      }

      if (order.commission && order.platformReconciled) {
        totalSalesCommission = totalSalesCommission + order.commission.value;
      }

      if (order.reconcileStatus !== null && order.reconcileStatus === 'CONFIRMED' && order.commission) {
        totalTransferedOrderAmount = totalTransferedOrderAmount + order.total;
        salesCommissionOfTransferedAmount = salesCommissionOfTransferedAmount + order.commission.value;
      }
    });

    totalpayableAmountReconciled = reconciledOrderAmount - totalSalesCommission;
    totalpayableAmountOfTransferedAmount = totalTransferedOrderAmount - salesCommissionOfTransferedAmount;
    totalpayableAmountOfCompletedOrders = completedOrderAmount - salesCommissionForCompletedOrders;
    totalpayableAmount = totalInvoiceAmount - totalCommission;

    return { totalInvoiceAmount, completedOrderAmount, cancelledOrderAmount, reconciledOrderAmount, returnOrderAmount, totalCommission, totalSalesCommission, totalpayableAmount, totalTransferedOrderAmount, salesCommissionOfTransferedAmount, totalpayableAmountOfTransferedAmount, totalpayableAmountReconciled, salesCommissionForCompletedOrders, totalpayableAmountOfCompletedOrders };
  }

  render() {
    const { orders = [], business } = this.props;
    const orderAmounts = this.getOrderAmounts(orders);

    return (
      <Row gutter={10}>
        {/* <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 25 }}><Icon type="calculator" /></td>
                  <td>Total invoice amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalInvoiceAmount, currencyFormat)}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type="dollar" /></td>
                  <td>Total commission</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalCommission, currencyFormat)}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type="dollar" /></td>
                  <td>Completed order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.completedOrderAmount, currencyFormat)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}><Divider /></Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>

                <tr>
                  <td style={{ width: 25 }}><Icon type="minus-square" /></td>
                  <td>Cancelled order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.cancelledOrderAmount, currencyFormat)}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type="minus-square" /></td>
                  <td>Return order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.returnOrderAmount, currencyFormat)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row> */}

        <Row>
          <Col lg={24} md={24} sm={24} xs={24}><Divider /></Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Completed order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.completedOrderAmount, currencyFormat(business))}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type='dollar' /></td>
                  <td>Sales commission</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.salesCommissionForCompletedOrders, currencyFormat(business))}</td>
                </tr>

                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Total receivable amount </td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalpayableAmountOfCompletedOrders, currencyFormat(business))}</td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Reconciled order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.reconciledOrderAmount, currencyFormat(business))}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type='dollar' /></td>
                  <td>Sales commission</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalSalesCommission, currencyFormat(business))}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Total receivable amount </td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalpayableAmount, currencyFormat(business))}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <Col lg={24} md={24} sm={24} xs={24}><Divider /></Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Transfered order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalTransferedOrderAmount, currencyFormat(business))}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type='dollar' /></td>
                  <td>Sales commission (Transfered)</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.salesCommissionOfTransferedAmount, currencyFormat(business))}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Total receivable amount (Transfered)</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalpayableAmountOfTransferedAmount, currencyFormat(business))}</td>
                </tr>
              </tbody>
            </table>
          </Col>

        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}><Divider /></Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Total Order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.totalInvoiceAmount, currencyFormat(business))}</td>
                </tr>
                <tr>
                  <td style={{ width: 25 }}><Icon type='dollar' /></td>
                  <td>Cancelled order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.cancelledOrderAmount, currencyFormat(business))}</td>
                </tr>
              </tbody>
            </table>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <table>
              <tbody>

                <tr>
                  <td style={{ width: 25 }}><Icon type='calculator' /></td>
                  <td>Return order amount</td>
                  <td style={{ width: 25, textAlign: 'center' }}>:</td>
                  <td>{format(orderAmounts.returnOrderAmount, currencyFormat(business))}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

      </Row>
    );
  }
}

export default PayoutSummeryDetails;
