import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Card } from 'antd'
import { connect } from 'react-redux'
import _, { conforms } from 'lodash'
import moment from 'moment'

import AnalyticsCard from './components/AnalyticsCard'
import SalesAnalytics from './components/SalesAnalytics'
import PiePercent from './components/PiePercent'
import moneyImg from '../../image/money.svg'
import tableImg from '../../image/table.svg'
import foodImg from '../../image/food.svg'
import customersImg from '../../image/customers.svg'
import { format } from 'currency-formatter';
import { currencyFormatWithoutSymbol } from '../../helpers/common';
import { withRouter } from 'react-router';

import { getBusinesses, getBranches, getOrderAnalytics, getOrderItemAnalytics, getAuthenticity } from '../../reducers'
import { fetchProcedure } from '../../actions/generic'
import { RiNurseFill } from 'react-icons/ri'

const Cards = [
  {
    id: 'total',
    title: 'TOTAL SALES',
    todayValue: 0,
    predictedValue: 0,
    performanceValue: 0,
    performanceValueText: '+0.00%',
    projectTarget: 0,
    bgColor: '#009688',
    image: moneyImg,
    progressColor: '#01564e',
  },
  {
    id: 'order',
    title: 'ORDERS CREATED',
    todayValue: 0,
    predictedValue: 0,
    performanceValue: 0,
    performanceValueText: '+0.00%',
    projectTarget: 0,
    bgColor: '#4CAF50',
    image: foodImg,
    progressColor: '#52c41a',
  },
  {
    id: 'table',
    title: 'TABLES SERVED',
    todayValue: 0,
    predictedValue: 0,
    performanceValue: 0,
    performanceValueText: '+0.00%',
    projectTarget: 0,
    bgColor: '#3F51B5',
    image: tableImg,
    progressColor: '#2a3780',
  },
  {
    id: 'customer',
    title: 'CUSTOMERS SERVED',
    todayValue: 0,
    predictedValue: 0,
    performanceValue: 0,
    performanceValueText: '+0.00%',
    projectTarget: 0,
    bgColor: '#2196F3',
    image: customersImg,
    progressColor: '#0261ad',
  }
]

const dateAgoOneMonth = moment().subtract(1, 'months').startOf().toDate()

const DashboardContent = (props) => {
  const { authData: { user: { userId } }, fetchProcedure, filter, orderAmountSummary, orderCountSummary, orderTableCountSummary, orderCustomerCountSummary, orderGraph, orderSummaryByType, orderItemBestSelling, orderItemHighIncome, businesses, branches } = props;
  const [cards, setCards] = useState(Cards)
  const businessIds = businesses.map(b => b.id);
  const branchIds = branches.map(br => br.id);

  const [salesAnalytics, setSalesAnalytics] = useState([])
  const [itemsAnalytics, setItemsAnalytics] = useState({
    bestSelling: [], highIncome: [], orderType: []
  })

  useEffect(() => {
    console.log("useeffect 1", props.branches,    businessIds)
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_analytics', { ...filter, businessIds, branchIds, analyticName: 'sales_summary' })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_analytics', { ...filter, businessIds, branchIds, analyticName: "avg_order_count" })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_analytics', { ...filter, businessIds, branchIds, analyticName: "avg_order_table_count" })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_analytics', { ...filter, businessIds, branchIds, analyticName: "avg_order_customer_count" })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_analytics', { ...filter, businessIds, branchIds, analyticName: "sales_status_past_month" })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_analytics', { ...filter, businessIds, branchIds, analyticName: "sales_order_dist_past_month" })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_item_analytics', { ...filter, businessIds, branchIds, analyticName: "sales_top_item_best_sell_past_month" })
    businessIds && businessIds.length > 0 && fetchProcedure('get_dashboard_order_item_analytics', { ...filter, businessIds, branchIds, analyticName: "sales_top_item_high_income_past_month" })
  }, [props.branches])

  useEffect(() => {
    console.log("useeffect 2", props.branches)
    let _data = [];
    _data.push(orderAmountSummary.length > 0 ? orderAmountSummary[0] : null)
    _data.push(orderCountSummary.length > 0 ? orderCountSummary[0] : null)
    _data.push(orderTableCountSummary.length > 0 ? orderTableCountSummary[0] : null)
    _data.push(orderCustomerCountSummary.length > 0 ? orderCustomerCountSummary[0] : null)
    _data.push(orderGraph)
    _data.push(orderItemBestSelling)
    _data.push(orderItemHighIncome)
    _data.push(orderSummaryByType)

    // console.log("Data Array", _data)

    calculateBoxs(_data);
    setSalesAnalytics([...getSalesAnalytics(_data)])
    setItemsAnalytics({ ...getItemsAnalytics(_data) })
  }, [props.orderItemBestSelling])

  /*======= 4 Boxs ========*/
  const calculateBoxs = (data) => {
    let todayValue = 0
    let predictedValue = 0
    let performanceValue = 0
    let performanceValueText = ''
    let projectTarget = 0
    let nowTime = moment()
    let todayStamp = moment(nowTime).endOf('day')
    let prevDayStamp = moment(nowTime).subtract(1, 'days').endOf('day')

    let _cards = [...cards]
    _cards.forEach(card => {
      if (card.id === 'total') {
        todayValue = data[0] && data[0].total ? data[0].total : 0;
        predictedValue = data[0] && data[0].avgTotal ? data[0].avgTotal : 0;
      } else if (card.id === 'order') {
        todayValue = data[1] && data[1].total ? data[1].total : 0;
        predictedValue = data[1] && data[1].avgTotal ? data[1].avgTotal : 0;
      } else if (card.id === 'table') {
        todayValue = data[2] && data[2].total ? data[2].total : 0;
        predictedValue = data[2] && data[2].avgTotal ? data[2].avgTotal : 0;
      } else if (card.id === 'customer') {
        todayValue = data[3] && data[3].total ? data[3].total : 0;
        predictedValue = data[3] && data[3].avgTotal ? data[3].avgTotal : 0;
      } else {
        return
      }

      performanceValue = predictedValue !== 0 ? (todayValue / predictedValue) * 100 : 0
      performanceValueText = `+${performanceValue ? performanceValue.toFixed(2) : '0.00'}%`
      projectTarget = ((todayStamp - prevDayStamp) / (nowTime - prevDayStamp)) * performanceValue

      card.todayValue = format(todayValue, currencyFormatWithoutSymbol())
      card.predictedValue = predictedValue.toFixed(2)
      card.performanceValue = performanceValue.toFixed(2)
      card.performanceValueText = performanceValueText
      card.projectTarget = projectTarget
    })

    setCards(_cards)
  }

  /*======= Line Chart ========*/
  const getSalesAnalytics = (data) => {
    // console.log("Graph data", data[4])
    let _data = data[4].map(o => ({
      ...o,
      date: moment(o.date).endOf('day').toDate(),
    }))

    let statusGroupObj = _.groupBy(_data, 'status')

    // console.log("Group by status", statusGroupObj)
    return Object.keys(statusGroupObj).map(status => {
      let dateGroupObj = _.groupBy(statusGroupObj[status], 'date')
      // console.log("Group by date", dateGroupObj)
      let countsPerday = Object.keys(dateGroupObj).map(date => ([
        getTimeStamp(date),
        getSum(dateGroupObj[date].map(d =>
          d.orderTotal
        ))
      ]))
      return {
        status,
        countsArray: countsPerday
      }
    })
  }

  /*======= Pie Chart ========*/
  const getItemsAnalytics = (analyticData) => {
    let data = { ...itemsAnalytics }

    // console.log("Item Analytics", analyticData[5], analyticData[6], analyticData[7]);

    data.bestSelling = analyticData[5].map(item => ({
      label: item.itemName,
      value: item.lineTotal,
    }))

    data.highIncome = analyticData[6].map(item => ({
      label: item.itemName,
      value: item.lineTotal,
    }))

    data.orderType = analyticData[7].map(item => ({
      label: item.type,
      value: item.orderTotal,
    }))

    return data
  }

  const getSum = values => {
    if (values.length === 0)
      return parseFloat(0)
    let sum = values.reduce((a, b) => parseFloat(a) + parseFloat(b))
    return parseFloat(sum)
  }

  const getTimeStamp = (date) => {
    let d = new Date(date)
    return d.getTime()
  }

  return (
    <Row gutter={[8, 8]}>
      {cards.map(card =>
        <Col lg={6} sm={6} md={12} xs={24} key={card.id}>
          <AnalyticsCard
            {...card} />
        </Col>
      )}
      <Col lg={24}>
        <Card className='card-container'>
          <p className='m-0' style={{ fontSize: '18px', fontWeight: '100' }}>
            <b style={{ fontWeight: '600' }}>Sales</b> Analytics
          </p>
          <p className='m-0' style={{ fontSize: '14px', fontWeight: '100', lineHeight: '30px', color: '#999' }}>
            This graph shows your sales analytics in last 30 days. You can customize the date range if you wish.
          </p>
          <SalesAnalytics chartData={salesAnalytics} />
        </Card>
      </Col>
      <Col lg={8}>
        <Card className='card-container'>
          <div style={{ minHeight: '136px' }}>
            <p className='m-0' style={{ fontSize: '18px', fontWeight: '100' }}>
              <b style={{ fontWeight: '600' }}>Best Selling</b> Items
            </p>
            <p className='mt-1' style={{ fontSize: '14px', fontWeight: '100', color: '#999' }}>
              This donut chart shows best selling items of your restaurant for past 30 days.
            </p>
          </div>
          <PiePercent data={itemsAnalytics.bestSelling} />
        </Card>
      </Col>
      <Col lg={8}>
        <Card className='card-container'>
          <div style={{ minHeight: '136px' }}>
            <p className='m-0' style={{ fontSize: '18px', fontWeight: '100' }}>
              Items Producing <b style={{ fontWeight: '600' }}>Highest Income</b>
            </p>
            <p className='mt-1' style={{ fontSize: '14px', fontWeight: '100', color: '#999' }}>
              This donut chart shows the items that produce highest income for your restaurant for past 30 days.
            </p>
          </div>
          <PiePercent data={itemsAnalytics.highIncome} />
        </Card>
      </Col>
      <Col lg={8}>
        <Card className='card-container'>
          <div style={{ minHeight: '136px' }}>
            <p className='m-0' style={{ fontSize: '18px', fontWeight: '100' }}>
              <b style={{ fontWeight: '600' }}>Orders</b> Distribution
            </p>
            <p className='mt-1' style={{ fontSize: '14px', fontWeight: '100', color: '#999' }}>
              This donut chart shows the distribution of orders by different order types for past 30 days.
            </p>
          </div>
          <PiePercent data={itemsAnalytics.orderType} />
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  const businesses = getBusinesses(state);
  const branches = getBranches(state);
  const businessIds = businesses.map(b => b.id);
  const branchIds = branches.map(br => br.id);

  const filter = {
    businessIds: businessIds,
    branchIds: branchIds,
    analyticName: "",
    date: moment().format('YYYY-MM-DD')
  }

  return ({
    orderAmountSummary: getOrderAnalytics(state, { ...filter, analyticName: 'sales_summary' }),
    orderCountSummary: getOrderAnalytics(state, { ...filter, analyticName: 'avg_order_count' }),
    orderTableCountSummary: getOrderAnalytics(state, { ...filter, analyticName: 'avg_order_table_count' }),
    orderCustomerCountSummary: getOrderAnalytics(state, { ...filter, analyticName: 'avg_order_customer_count' }),
    orderGraph: getOrderAnalytics(state, { ...filter, analyticName: 'sales_status_past_month' }),
    orderSummaryByType: getOrderAnalytics(state, { ...filter, analyticName: 'sales_order_dist_past_month' }),
    orderItemBestSelling: getOrderItemAnalytics(state, { ...filter, analyticName: 'sales_top_item_best_sell_past_month' }),
    orderItemHighIncome: getOrderItemAnalytics(state, { ...filter, analyticName: 'sales_top_item_high_income_past_month' }),
    filter: filter,
    authData,
    businesses,
    branches
  })
}

export default withRouter(connect(mapStateToProps, { fetchProcedure })(DashboardContent));