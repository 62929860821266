import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts'

let colors = ['#8bc34a', '#2196f3', '#ab47bc', '#ec407a', '#7cdddd', '#a64eae', '#8399eb', '#ffaf00', '#2dcb75', '#959fb0', '#48bebc', '#fcb45c', '#4d5360', '#F57C00', '#E64A19', '#5D4037', '#90A4AE']

const PiePercent = (props) => {

  const { data } = props

  const [activeIndex, setActiveIndex] = useState(0)

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <foreignObject x={cx-50} y={cy-20} width="100" height="80" style={{ textAlign: 'center' }}>
          <p
            style={{
              fontWeight: '600',
              color: '#666',
              margin: 0,
              fontSize: payload.label.length < 13 ? '14px' : '12px',
              lineHeight: payload.label.length < 13 ? '18px' : '12px',
            }}
          >
            {payload.label}
          </p>
          <p textAnchor="middle" style={{ fontWeight: '600', color: fill, margin: 0 }}>
            {value}
          </p>
        </foreignObject>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 2}
          startAngle={startAngle + 1}
          endAngle={endAngle - 1}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle + 1}
          endAngle={endAngle - 1}
          innerRadius={outerRadius + 5}
          outerRadius={outerRadius + 7}
          fill={fill}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  }

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  return (
    <div style={{ height: '200px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={80}
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {
              data && data.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]}/>)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PiePercent