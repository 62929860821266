import React, { Component } from 'react';
import { Col, Form, Input, Button, DatePicker, Row, Select, Checkbox } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

class AdvancedSearchForm extends Component {
  constructor () {
    super();
    this.state = {
      formLayout: 'horizontal'
    };
  }

  render () {
    const { query, onDateRangeChange, onInputTextChange, onClickSearch, onStatusSelectionChange, onAlwaysShowWebOrdersChange } = this.props;

    return (
      <Form layout='vertical'>
        <Row gutter={10}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Input placeholder='Order ID' onPressEnter={onClickSearch} onChange={(e) => onInputTextChange(e, 'orderId')} />
              </Form.Item>
            </Col>
            <Col lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Input placeholder='Customer Name, Email, Telephone' onPressEnter={onClickSearch} onChange={(e) => onInputTextChange(e, 'customerFilter')} />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <RangePicker
                  allowClear={false}
                  onPressEnter={onClickSearch}
                  defaultValue={[moment(query && query.fromDate), moment(query && query.toDate)]}
                  format='YYYY-MM-DD'
                  style={{ width: '100%' }}
                  onChange={onDateRangeChange}
                />
              </Form.Item>
            </Col>

            <Col lg={6} md={6} sm={24} xs={24}>
              <Checkbox onChange={e => onAlwaysShowWebOrdersChange(e.target.checked)} checked={query.orderMethod}><b>Only</b> show web orders</Checkbox>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} />
            <Col lg={6} md={6} sm={24} xs={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ width: '100%', marginBottom: 5 }} allowClear
                  onChange={onStatusSelectionChange} mode='multiple'
                  placeholder='Status'
                >
                  <Option value='NEW'>
                    {'NEW'}
                  </Option>
                  <Option value='CONFIRMED'>
                    {'CONFIRMED'}
                  </Option>
                  <Option value='COMPLETED'>
                    {'COMPLEDTED'}
                  </Option>
                  <Option value='CANCELLED'>
                    {'CANCELLED'}
                  </Option>
                  <Option value='REJECTED'>
                    {'REJECTED'}
                  </Option>
                  <Option value='RETURNED'>
                    {'RETURNED'}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Button block className='primary-button' type='primary' onClick={() => onClickSearch()}>Search</Button>
            </Col>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default AdvancedSearchForm;
