import { getPrinterLocations, insertPrinterLocation } from '../api/printerLocations';

export const fetchPrinterLocations = companyId => dispatch => {
  return getPrinterLocations(companyId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_PRINTER_LOCATIONS', printerLocations: response.printerLocations });
    return response;
  });
};

export const addPrinterLocation = (companyId, printerLocation) => dispatch => {
  return insertPrinterLocation(companyId, printerLocation).then(response => {
    if (!response.error) dispatch({ type: 'INSERT_PRINTER_LOCATION', printerLocations: response.printerLocations });
    return response;
  });
};
