import Api from './consts';
import fetch from './commonFetch';

export const advancedSearch = query => {
  const { url, method } = Api.inventory.advancedSearch;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'data');
};


export const getWarehousesByBranchIds= (companyId, data) => {
  const { url, method, headers } = Api.inventory.getWarehousesByBranchIds;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'warehouses');
};

export const getItemCategoriesOfInventoryItem= (companyId, data) => {
  const { url, method, headers } = Api.inventory.getItemCategoriesOfInventoryItemByBranchIds;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'itemCategories');
};

export const getItemBrandsOfInventoryItem= (companyId, data) => {
  const { url, method, headers } = Api.inventory.getItemBrandsOfInventoryItemByBranchIds;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'itemBrands');
};

export const getItemGroupsOfInventoryItem= (companyId, data) => {
  const { url, method, headers } = Api.inventory.getItemGroupsOfInventoryItemByBranchIds;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'itemGroups');
};

export const addInventoryItems= (companyId, data) => {
  const { url, method, headers } = Api.inventory.insertItems;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'inventoryItems');
};

export const modifyInventoryItems= (companyId, data) => {
  const { url, method, headers } = Api.inventory.updateItems;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'inventoryItems');
};

export const removeInventoryItems= (companyId, data) => {
  const { url, method, headers } = Api.inventory.deleteItems;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'records');
};


export const importBulkInventoryItems = (companyId, data) => {
  const { url, method, headers } =  Api.inventory.importBulkInventoryItems;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'records');
};


export const exportInventoryItems = (companyId, data) => {
  const { url, method, headers } = Api.inventory.exportInventoryItems;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'records');
};

export const exportInventoryItemsTemplate = (companyId) => {
  const { url, method, headers } = Api.inventory.exportInventoryItemsTemplate;
  return fetch(url(companyId), { method, headers }, 'records');
};




 