import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Table, Input, Avatar, Form, Spin, Radio, Col, Button } from 'antd';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
import { getItems, getItemsByIsIngredient, isItemsFetching, getAuthenticity } from '../../../reducers';
import EditableCell from './EditableCell.js';
import { fetchItems } from '../../../actions/items';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class ItemList extends Component {
  state = {
    loading: false,
    textFilter: undefined,
    optionValue: 1,
    selectedRowKeys: [],
  }

  componentDidMount = () => {
    const { companyId } = this.props;
    this.props.fetchItems(companyId);
    window.addEventListener('focus', this.handleFocus);
  }

  componentWillUnmount = () => {
    window.removeEventListener('focus', this.handleFocus);
  }

  handleFocus = () => {
    // this.props.fetchItems(this.props.companyId);
  }

  setLoading = loading => {
    this.setState({ loading: loading });
  }

  onItemSearch = value => this.setState({ textFilter: value })

  getItems = () => {
    const { items } = this.props;
    const { textFilter } = this.state;

    return textFilter ? items.filter(item => {
      return item.name.toLowerCase().includes(textFilter.toLowerCase()) ||
        item.code.toLowerCase().includes(textFilter.toLowerCase());
    }) : items;
  }

  // onSelectItem = (itemListType, rawData) => {
  //   console.log(rawData)
  // }

  getColumns = () => {
    const { handleItemEditableCellChangeSave } = this.props;
    const columns = [
      {
        title: 'Image',
        dataIndex: 'imageSrc',
        width: '25%',
        render: (imageSrc, dataRow) => {
          const imgUrl = imageSrc != null ? imageSrc : '/images/empty-image.png';
          return <Avatar src={imgUrl} size='large' />;
        }
      }, {
        title: 'Code',
        dataIndex: 'code',
        editable: false
      },
      {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        editable: false
      },
      // {
      //   title: 'Price',
      //   dataIndex: 'itemPrices',
      //   align: 'right',
      //   editable: false,
      //   render: text => <p>{format(text, currencyFormat)}</p>
      // }
      // {
      //   title: 'Actions',
      //   dataIndex: 'id',
      //   align: 'right',
      //   height: 40,
      //   render: (text, rawData) => {
      //     return (<Button type={"primary"} size={"small"} onClick={() => onSelectItem(this.props.itemListType, rawData)}>Add to Category</Button>
      //     )
      //   },
      // }
    ];

    return columns.map(col => {
      if (!col.editable) return col;
      return {
        ...col,
        onCell: record => (
          {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleItemEditableCellChangeSave,
            Context: EditableContext
          }
        )
      };
    });
  }

  onOptionChange = e => this.setState({ optionValue: e.target.value });

  getRowSelection = () => {
    return {
      selectedRowKeys: this.props.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        const itemIds = selectedRowKeys;
        this.props.onSelectItem(itemIds, selectedRowKeys, selectedRows)
      }
    }
  }

  render() {
    const { onCloseList, onClickAdd, visible, type, companyId, onSelectItem, fetching, selectedRowKeys } = this.props;
    const { loading, textFilter, optionValue } = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const columns = this.getColumns();
    const items = this.getItems();

    // const enableRowSelection = type === 'GROUP_MODIFIER' || type === 'MODIFIER';

    return (
      <div>
        <Drawer
          title='Items'
          placement='right'
          closable={true}
          onClose={onCloseList}
          visible={visible}
          width='100%'
        >
          {/* <Alert
            message="Informational Notes"
            description="In order to add existing item to selected category click on a row.
            To add a new item to the list fill the form and press add new button, it'll create a new item and automatically add to the selected category."
            type="info"
            showIcon
            closable
          /> */}
          {/* <Radio.Group onChange={this.onOptionChange} value={optionValue}>
            Add item to category
            <Radio style={radioStyle} value={1}>
              <span style={{ fontWeight: 600 }}>From existing item list</span>
            </Radio>
            <Radio style={radioStyle} value={2}>
              <span style={{ fontWeight: 600 }}>By adding a new item</span>
            </Radio>
          </Radio.Group> */}

          <div style={{ marginTop: 10 }}>
            <Spin spinning={(loading && items && items.length === 0) || (fetching && items && items.length === 0)}>
              <div style={{ marginBottom: 10 }}>
                <Input placeholder='Search Item' value={textFilter} allowClear onChange={(event) => this.onItemSearch(event.target.value)} />
              </div>
              {selectedRowKeys && selectedRowKeys.length > 0 && <div>
                <span>{selectedRowKeys.length} {selectedRowKeys.length === 1 ? 'item selected.' : 'items selected.'}</span>
                <Button type={'primary'} style={{ marginRight: 5 }} onClick={onClickAdd}> Add</Button>
              </div>}
              <div>
                <Table
                  components={components}
                  scroll={{ y: window.innerHeight - 361 }}
                  style={{ minHeight: window.innerHeight - 260, borderBottom: '1px solid #f1f1f1' }}
                  columns={columns}
                  className='itemList'
                  dataSource={this.getItems()}
                  showHeader
                  pagination
                  rowSelection={this.getRowSelection()}
                  rowKey={"id"}

                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: event => onSelectItem(type, record)
                //   };
                // }}
                />
              </div>
            </Spin>
          </div>


        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  if (props.type === 'ALL') {
    return {
      items: getItems(state),
      fetching: isItemsFetching(state),
      companyId: companyId,
    };
  } else if (props.type === 'MODIFIER' || props.type === 'GROUP_MODIFIRE_INGREDIENT') {
    return {
      items: getItemsByIsIngredient(state),
      fetching: isItemsFetching(state),
      companyId: companyId
    };
  } else {
    console.log(props.type);
  }
};

export default connect(mapStateToProps, { fetchItems })(ItemList);

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};
