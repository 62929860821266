import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS': {
      const newState = {};
      action.items && action.items.forEach(item => { newState[item.id] = item; });
      return newState;
    }
    case 'SUCCEED_FETCH_ITEMS_PARTIALLY': {
      const newState = { ...state };
      action.items && action.items.forEach(item => { newState[item.id] = item; });
      return newState;
    }
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const newState = { ...state };
      action.items && action.items.forEach(item => { newState[item.id] = item; });
      return newState;
    }
    case 'FETCH_ITEM': {
      return { ...state, [action.item.id]: action.item };
    }
    case 'DELETE_ITEM': {
      const newState = { ...state };
      delete newState[action.item.id];
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS': {
      const newState = [];
      action.items && action.items.forEach(item => {
        if (!newState.includes(item.id)) {
          newState.push(item.id);
        }
      });
      return newState;
    }
    case 'SUCCEED_FETCH_ITEMS_PARTIALLY': {
      const newState = [...state];
      action.items && action.items.forEach(item => {
        if (!newState.includes(item.id)) {
          newState.push(item.id);
        }
      });
      return newState;
    }
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const newState = [...state];
      action.items && action.items.forEach(item => {
        if (!newState.includes(item.id)) {
          newState.push(item.id);
        }
      });
      return newState;
    }
    case 'FETCH_ITEM': {
      if (state.includes(action.item.id)) {
        return state;
      } else {
        return [...state, action.item.id];
      }
    }
    case 'DELETE_ITEM': {
      const newState = [...state];
      return newState.filter(id => id !== action.item.id);
    }
    default:
      return state;
  }
};

const byCategory = (state = {}, action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS_PARTIALLY':
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const { items, categoryId } = action;
      const newState = { ...state };
      newState[categoryId] = items && items.length > 0 && items.map(item => item.id);

      return newState;
    }
    default:
      return state;
  }
};

const byOnlyCategory = (state = {}, action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS_PARTIALLY':
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const { items, categoryId } = action;
      const newState = { ...state };
      const filteredItems = items && items.length > 0 && items.filter(item => (item.categories && item.categories.length > 0 && (item.categories.filter((category) => category.id === categoryId)).length > 0));
      newState[categoryId] = filteredItems && filteredItems.length > 0 && filteredItems.map(item => item.id);
      return newState;
    }
    default:
      return state;
  }
};

const byIsIngredient = (state = [], action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS_PARTIALLY':
    case 'FETCH_ITEMS_BY_IS_INGREDIENT': {
      const newState = [...state];
      newState.map(item =>
        newState[item.id] = item && newState[item.id] === true
      );
      return newState;
    }
    default:
      return state;
  }
};

const byVariantGroupId = (state = {}, action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS':
    case 'SUCCEED_FETCH_ITEMS_PARTIALLY':
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const { items } = action;
      const newState = { ...state };
      const arrayOfGroups = [];
      items && items.length > 0 && items.forEach(item => {
        // console.log(item);
        const variant = item.variant;
        const groupId = variant && variant.definition && variant.definition.variantGroupId;
        if (groupId) {
          if (arrayOfGroups.length > 0) {
            const objIndex = arrayOfGroups.findIndex(obj => obj.groupId === groupId);
            if (objIndex >= 0) arrayOfGroups[objIndex].itemIds.push(item.id);

            if (objIndex === -1) {
              const obj1 = {
                groupId: groupId,
                itemIds: [item.id]
              };
              arrayOfGroups.push(obj1);
            }
          } else {
            const obj = {
              groupId: groupId,
              itemIds: [item.id]
            };
            arrayOfGroups.push(obj);
          }
        }
      });
      for (let i = 0; i < arrayOfGroups.length; i++) {
        newState[arrayOfGroups[i].groupId] = arrayOfGroups[i].itemIds;
      }
      // const ids = newState[groupId] || [];
      // if (!ids.includes(item.id)) {
      //   ids.push(item.id);
      // }
      //
      return newState;
    }

    case 'DELETE_ITEM': {
      const newState = { ...state };
      const variant = action.item.variant;
      const groupId = variant && variant.definition && variant.definition.variantGroupId;
      const ids = newState[groupId];
      newState[groupId] = ids && ids.length > 0 && ids.filter(id => id !== action.item.id);

      return newState;
    }
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case 'START_FETCH_ITEMS':
      return true;
    case 'SUCCEED_FETCH_ITEMS':
    case 'FAIL_FETCH_ITEMS':
      return false;
    default:
      return state;
  }
};

const items = combineReducers({
  byId,
  ids,
  byCategory,
  byOnlyCategory,
  byIsIngredient,
  fetching,
  byVariantGroupId
});

export default items;

export const getItems = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getItemsByIsIngredient = state => {
  const items = state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
  return items.filter(item => item && item.isIngredient);
};

export const getItemsByIsModifier = state => {
  const items = state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
  return items.filter(item => item && item.isModifier);
};

export const getItemsByCategory = (state, parentId) => {
  const itemIds = state.byCategory[parentId];

  return itemIds && itemIds.length > 0 ? itemIds.map(id => state.byId[id]) : [];
};

export const getItemsOnlyByCategory = (state, categoryId) => {
  const itemIds = state.byOnlyCategory[categoryId];

  return itemIds && itemIds.length > 0 ? itemIds.map(id => state.byId[id]) : [];
};

export const getItem = (state, itemId) => {
  return state && state.byId && state.byId[itemId];
};

export const isFetching = state => state.fetching;

export const getItemsByVariantGroupId = (state, groupId) => {
  return (state.byVariantGroupId[groupId] || []).map(id => state.byId[id]);
};
