import { getAll, modifyStatus, advancedSearch, reconcileOrderPayments, makeOrderPayment } from '../api/orders';

export const updateActiveFilter = filter => dispatch => {
  dispatch({
    type: 'UPDATE_ACTIVE_FILTER_ORDER',
    filter
  });
};

export const updateFilter = (type, filter) => dispatch => {
  dispatch({
    type: 'UPDATE_FILTER_ORDER',
    filterType: type,
    filter
  });
};

export const fetchOrders = companyId => dispatch => {
  return getAll(companyId).then(response => {
    const orders = [];
    response.orders && Object.keys(response.orders).forEach(key => { orders.push(response.orders[key]); });
    if (!response.error) dispatch({ type: 'FETCH_ORDERS', orders: orders });
    return response;
  });
};

export const updateStatus = (companyId, orderId, data) => dispatch => {
  return modifyStatus(companyId, orderId, data).then(response => {
    //if (!response.error) dispatch({ type: 'FETCH_ORDER', order: response.order });
    return response;
  });
};

export const addOrderPayment = (companyId, orderId, data) => dispatch => {
  return makeOrderPayment(companyId, orderId, data).then(response => {
    //if (!response.error) dispatch({ type: 'FETCH_ORDER', order: response.order });
    return response;
  });
};

export const fetchOrdersFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.data.orderList, totalResults: response.data.totalResults, query });
    }
    return response;
  });
};

export const orderPaymentsReconilation = request => dispatch => {
  return reconcileOrderPayments(request).then(response => {
    if (!response.error) {
      dispatch({ type: 'PARTIALLY_FETCH_ORDERS', orders: response.orders.paymentReconciledOrders });
    }
    return response;
  });
};
