import Api from './consts';
import fetch from './commonFetch';

export const getAll = companyId => {
  const { url, method } = Api.order.getAll;
  return fetch(url(companyId), { method }, 'orders');
};

export const modifyStatus = (companyId, orderId, data) => {
  const { url, method, headers } = Api.order.modifyStatus;
  const body = JSON.stringify(data);
  return fetch(url(companyId, orderId), { method, headers, body }, 'order');
};

export const makeOrderPayment = (companyId, orderId, data) => {
  const { url, method, headers } = Api.order.makeOrderPayment;
  const body = JSON.stringify(data);
  return fetch(url(companyId, orderId), { method, headers, body }, 'order');
};

export const advancedSearch = query => {
  const { url, method } = Api.order.advancedSearch;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'data');
};

export const reconcileOrderPayments = query => {
  const { url, method } = Api.order.reconciledOrderPayment;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'orders');
};
