import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Col, Input, Row, Select, Switch, Tag, Spin, TreeSelect, Checkbox, Tooltip } from 'antd';
import { FaEdit, FaSave, FiEdit2, AiOutlineCloseCircle } from 'react-icons/all';
import image from '../../image/empty-image.png';
import { format } from 'currency-formatter';
import { currencyFormat, generateTitle } from '../../helpers/common';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import { updateItem, updateItemPrice, addItemPriceList, fetchItem, fetchItems, addItemCategories, removeIngredient, fetchItemsForCategory } from '../../actions/items';
import { fetchCategories, addItemToCategory, removeItemFromCategory } from '../../actions/categories';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import { getItem } from '../../reducers';
import ModifierRow from './ModifierRow';
import { Link } from 'react-router-dom';
const { Option } = Select;

class ItemRow extends Component {
  state = {
    loading: false,
    item: {},
    editable: false,
    priceUpdate: false,
    modifierLoading: false,
    parentCategories: undefined
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.item && nextProps.item.ingredients && Object.keys(this.state.item)) {
      if (nextProps.item.ingredients !== this.props.item.ingredients) {
        this.setState({ item: nextProps.item });
      }
    }
  }

  getCheckStatus = () => {
    const { checkedItems } = this.props;
    const checkItem = checkedItems.filter(checkedItem => checkedItem.id === this.props.item.id);
    if (checkItem.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getItemPrice = itemPricesMap => {
    const { selectedPriceList } = this.props;
    const itemPrices = itemPricesMap && Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let price = 0;

    if (selectedPriceList && selectedPriceList.id) {
      itemPrices.forEach(itemPrice => {
        if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) price = itemPrice.price;
      });
    }

    return price;
  }

  getItemPriceId = itemPricesMap => {
    const { selectedPriceList } = this.props;
    const itemPrices = Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let id = '';

    itemPrices.forEach(itemPrice => {
      if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) id = itemPrice.id;
    });
    return id;
  }

  handleOnClickRow = () => {
    // this.setState({ editable: false })
  }

  handleClickEdit = () => {
    const { item } = this.props;
    this.setState({ editable: true, item: item });
    this.props.onExpandRow(item.id);
  }

  handleClickCancel = () => {
    const { item } = this.props;
    this.setState({ editable: false });
    this.props.onCollapesRow(item.id);
  }

  handleImageChange = (item, src) => {
    const newRecord = { ...item, imageSrc: src };
    this.onUpdateItem(newRecord);
  }

  handleOnRemoveMofier = (e, modifier) => {
    e.stopPropagation();
    this.onRemoveIngredient(modifier.id);
  }

  comparer = (otherArray) => {
    return function (current) {
      return otherArray.filter(function (other) {
        return other === current;
      }).length === 0;
    };
  }

  getCategoryIds = (categories) => {
    var categeroyIds = [];
    categories.forEach(category => {
      categeroyIds.push(category.id);
    });

    return categeroyIds;
  }

  onUpdateItem = (updatedObject) => {
    const { companyId } = this.props;
    const { parentCategories } = this.state;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;
    var removedCategories = undefined;
    var addedCategories = undefined;

    if (updatedObject.categories && parentCategories) {
      var categoryIds = this.getCategoryIds(updatedObject.categories);
      removedCategories = categoryIds.filter(this.comparer(parentCategories));
      addedCategories = parentCategories.filter(this.comparer(categoryIds));
    }

    const item = {
      name: updatedObject.name,
      imageSrc: updatedObject.imageSrc,
      //availableQty2: updatedObject.availableQty2 == null ? 0 : parseInt(updatedObject.availableQty2),
      isMeal: updatedObject.isMeal,
      displayInOnlineMenu2: updatedObject.displayInOnlineMenu2,
      onlineStockAvailable: updatedObject.onlineStockAvailable,
      removedCategories: removedCategories,
      addedCategories: addedCategories
    };

    this.setState({ loading: true });
    this.props.updateItem(companyId, updatedObject.id, item).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating Item', error);
        // this.setState({})
        this.setState({ loading: false });
      } else {
        this.props.fetchItem(companyId, updatedObject.id).then(() => {
          this.forceUpdate();
          this.setState({ loading: false, editable: false });
          this.props.onCollapesRow(updatedObject.id);
          showSuccessNotification('Item updated.', 'Item updated successfully.');
        });
      }
    });
  }

  onUpdateItemPrice = (updatedObject) => {
    const { companyId } = this.props;
    const itemPriceId = this.getItemPriceId(updatedObject.itemPrices);

    if (itemPriceId !== 'new') {
      this.setState({ loading: true });
      this.props.updateItemPrice(companyId, updatedObject.id, { priceId: itemPriceId, price: parseInt(updatedObject.newPrice) }).then(response => {
        const { error } = response;
        if (error) {
          showErrorNotification('Failed updating Item price', error);
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, priceUpdate: false });
        }
      });
    } else {
      this.insertNewItemPrice(updatedObject);
    }
  }

  insertNewItemPrice = (updatedObject) => {
    const { companyId } = this.props;
    const { selectedPriceList } = this.props;

    this.setState({ loading: true });
    this.props.addItemPriceList(companyId, updatedObject.id,
      { priceListId: selectedPriceList.id, businessId: updatedObject.businessId, price: parseInt(updatedObject.newPrice) }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ submitting: false, loading: false });
        } else {
          // this.props.fetchItem(companyId, updatedObject.id).then(() => {
          // showSuccessNotification('Added.', 'Item Price Added successfully.');
          this.setState({ loading: false });
          // })
        }
      });
  }

  onAddItemCategories = (categories) => {
    const { companyId } = this.props;
    const { item } = this.state;
    this.setState({ loading: true });
    this.props.addItemCategories(companyId, item.id, { data: categories }).then(response => {
      const { error } = response;
      if (error) {
        this.setState({ loading: false });
        showErrorNotification('Failed adding Item to category', error);
      } else {
        this.props.fetchItem(companyId, item.id).then(() => {
          this.setState({ loading: false });
          // showSuccessNotification("Item Added", "Item added to category successfully.");
        });
        // console.log("response", category)
      }
    });
  }

  onRemoveItemFromCategory = (itemId, categeroyId) => {
    const { companyId } = this.props;

    this.setState({ loading: true });
    this.props.removeItemFromCategory(companyId, categeroyId, { itemId: itemId }).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed delete Item from Category', error);
        this.setState({ loading: false });
      } else {
        this.props.fetchItemsForCategory(companyId, categeroyId).then(() => {
          this.setState({ loading: false });
          showSuccessNotification('Item deleted.', 'Item removed from category successfully.');
        });
      }
    });
  }

  onRemoveIngredient = (ingredientId) => {
    const { companyId } = this.props;
    const { item } = this.state;

    this.setState({ modifierLoading: true });
    this.props.removeIngredient(companyId, ingredientId, {itemId : item.id}).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ modifierLoading: false });
      } else {
        this.props.fetchItem(companyId, item.id).then(() => {
          showSuccessNotification('Delete.', 'Modifier Deleted successfully.');
          this.setState({ modifierLoading: false });
        });
      }
    });
  }

  renderCategories = () => {
    const { itemDetails } = this.props;
    const categories = [];
    itemDetails && itemDetails.categories && itemDetails.categories.forEach(category => {
      categories.push(<Tag style={{ maxWidth: 90, overflow: 'hidden', fontSize: 10 }}>{generateTitle(category.name)}</Tag>);
    });
    return categories;
  }

  renderCategoryOptions = () => {
    const { categories } = this.props;

    const categoryOptions = [];
    categories && categories.forEach(category => {
      category && category.id && category.name && categoryOptions.push(<Option key={category.name} value={category}>{generateTitle(category.name)}</Option>);
    });
    return categoryOptions;
  }

  handleClickSave = () => {
    const { item, priceUpdate } = this.state;
    this.onUpdateItem(item);
    if (priceUpdate) {
      this.onUpdateItemPrice(item);
    }
    if (item.newCategories && item.newCategories.length > 0) {
      this.onAddItemCategories(item.newCategories);
    }
  }

  renderDefaultCategories = () => {
    const { item } = this.state;
    const defaultCategories = [];
    item && item.categories && item.categories.forEach(category => {
      defaultCategories.push(category.id);
    });
    return defaultCategories;
  }

  renderModifiers = () => {
    const { item } = this.state;
    const modifierList = [];
    item && item.ingredients && item.ingredients.length > 0 && item.ingredients.forEach(ingredient => {
      if (ingredient.item) {
        modifierList.push(<ModifierRow modifier={ingredient} onRemoveModifier={(e, modifier) => this.handleOnRemoveMofier(e, modifier)} />);
      }
    });
    return modifierList;
  }

  handleOnChangeCategory = (value) => {
    this.setState({ parentCategories: value });
  }

  handleOnChange = (field, value) => {
    switch (field) {
      case 'name':
        this.setState({ item: { ...this.state.item, name: value } });
        break;
      case 'price':
        this.setState({ item: { ...this.state.item, itemPrices: this.handlePriceUpdate(value), newPrice: value }, priceUpdate: true });
        break;
      case 'availableQty2':
        this.setState({ item: { ...this.state.item, availableQty2: value } });
        break;
      case 'onlineStockAvailable':
        this.setState({ item: { ...this.state.item, onlineStockAvailable: value } });
        break;
      case 'displayInOnlineMenu2':
        this.setState({ item: { ...this.state.item, displayInOnlineMenu2: value } });
        break;
      case 'categories':
        // todo: Implement Category update
        this.setState({ item: { ...this.state.item, categories: value } });
        // this.onAddItemToCategory(value)
        break;
      case 'isMeal':
        this.setState({ item: { ...this.state.item, isMeal: value } });
        break;
      default:
        return null;
    }
  }

  handleCheckedChange = (e) => {
    const { item } = this.props;
    this.props.onChangeCheckedItem(e.target.checked, item);
  }

  handlePriceUpdate = value => {
    const { selectedPriceList } = this.props;
    const { item } = this.state;
    let itemPrices = item.itemPrices;
    let itemPriceIsAvaiable = false;
    itemPrices && Object.keys(itemPrices).forEach(key => {
      if (itemPrices[key].priceList && itemPrices[key].priceList.id === selectedPriceList.id) {
        itemPrices[key].price = value;
        itemPriceIsAvaiable = true;
      }
    });

    if (!itemPriceIsAvaiable) {
      const newItemPrice = {
        id: 'new',
        priceList: selectedPriceList,
        price: value
      };
      itemPrices = { ...{}, new: newItemPrice };
    }
    // todo: Implement Price update when price list is not available

    return itemPrices;
  }

  tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    return (
      <Tag color={value} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  }

  getCategoryTreeNodes = (categoryTree, keyOfValue = 'id') => {
    const categoriesArray = [];
    if (categoryTree && categoryTree.length !== undefined) {
      categoryTree.forEach(category => {
        if (category.id !== 'defaultMenu') {
          const obj = {
            title: category.name,
            key: category.id,
            value: keyOfValue === 'name' ? category.name : category.id,
            children: category.categories && Object.keys(category.categories).length > 0 && this.getCategoryTreeNodes(category.categories, keyOfValue)
          };
          categoriesArray.push(obj);
        }
      });
    } else {
      Object.keys(categoryTree).forEach(key => {
        if (categoryTree[key].id !== 'defaultMenu') {
          const obj = {
            title: categoryTree[key].name,
            key: categoryTree[key].id,
            value: keyOfValue === 'name' ? categoryTree[key].name : categoryTree[key].id,
            children: categoryTree[key].categories && Object.keys(categoryTree[key].categories).length > 0 && this.getCategoryTreeNodes(categoryTree[key].categories, keyOfValue)
          };
          categoriesArray.push(obj);
        }
      });
    }

    return categoriesArray;
  }

  add3Dots(string, limit) {
    let stringCopy = string;
    const dots = '...';
    if (string.length > limit) {
      stringCopy = stringCopy.substring(0, limit) + dots;
      return stringCopy;
    }

    return string;
  }

  renderVariantTag = () => {
    const { item } = this.props;
    let optionTag = '';
    item && item.variant && item.variant.options && Object.values(item.variant.options).forEach(variantOption => {
      optionTag = optionTag + variantOption.option + ' / ';
    });

    return optionTag.substr(0, optionTag.length - 3);
  }

  getEditable = () => {
    var isKeyInList = false;
    if (this.props.expandedRowkeys && this.props.expandedRowkeys.length > 0) {
      for (var i = 0; i < this.props.expandedRowkeys.length; i++) {
        if (this.props.expandedRowkeys[i].toString() === this.props.item.id) {
          isKeyInList = true;
        }
      }
    }

    if (isKeyInList) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { companyId, categoryTree, business } = this.props;
    const { item, loading, parentCategories } = this.state;
    const imagePath = this.props.item && this.props.item.imageSrc ? this.props.item.imageSrc : image;
    const categoryTreeNodesList = this.getCategoryTreeNodes(categoryTree, 'id');
  
    return (
      <div>
        {!this.getEditable() ? (
          <Row className='category-item-row' style={{ marginLeft: 5, marginRight: 5, marginTop: -10, width: '98.8%' }}>
            <Col lg={1}>
              <Checkbox checked={this.getCheckStatus()} onChange={this.handleCheckedChange} />
            </Col>
            <Col lg={2}>
              <Avatar src={imagePath} size={35} />
            </Col>
            <Col lg={6} style={{ maxHeight: 45, overflow: 'hidden' }}>
              <Tooltip title={this.props.item && this.props.item.name}>
                <p style={{ marginBottom: 0 }}>{this.props.item && this.props.item.name && this.add3Dots(generateTitle(this.props.item.name), 23)}</p>
              </Tooltip>
              <span className='disable-text-selection' style={{ fontSize: 12, color: '#006fbb' }}>{this.add3Dots(this.renderVariantTag(), 23)}</span>
            </Col>
            <Col className='text-right' lg={4}>
              {this.props.item && this.props.item.itemPrices && format(this.getItemPrice(this.props.item.itemPrices), currencyFormat(business))}
            </Col>
            <Col className='text-right' lg={2}>
            {(this.props.item && this.props.item.onlineStockAvailable) ? <Tag color='#227D10'>Yes</Tag> : <Tag>No</Tag>}
            </Col>
            {/* <Col className='text-center' lg={3}>
              {(this.props.item && this.props.item.displayInOnlineMenu2) ? <Tag color='#227D10'>Yes</Tag> : <Tag>No</Tag>}
            </Col> */}
            <Col className='text-left' style={{ maxHeight: 22, overflow: 'overlay' }} lg={6}>
              {this.renderCategories()}
            </Col>
            {/* <Col className='text-center' lg={4}>
              {(itemDetails && itemDetails.isMeal) ? <Tag color={'#227D10'}>Yes</Tag> : <Tag>No</Tag>}
            </Col> */}
            <Col className='text-right' lg={3}>

              <Button
                size='small' className='primary-button' type='primary' onClick={() => this.handleClickEdit()}
                style={{ marginRight: 1 }}
              >
                <FiEdit2 style={{ color: '#ffffff' }} />
              </Button>

              <Link to={'/items/' + this.props.item.id}>
                <Button
                  size='small' type='primary' className='primary-button'
                  style={{ marginLeft: 2 }}
                >
                  <FaEdit style={{ color: '#ffffff' }} />
                </Button>
              </Link>

            </Col>

          </Row>) : (
            <Row className='all-item-row' onClick={() => this.handleOnClickRow()} style={{ marginLeft: 5, marginRight: 5, marginTop: -10, width: '98.8%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Col lg={1}>
                  <Checkbox checked={this.getCheckStatus()} onChange={(e) => this.handleCheckedChange(e)} />
                </Col>
                <Col lg={2}>
                  <ImageUploadContainer
                    folder={'/' + companyId + '/items/' + this.props.item.id}
                    src={imagePath}
                    size={50}
                    showButton={false}
                    componentHeight='inherit'
                    onImageChange={(imageSrc) => this.handleImageChange(item, imageSrc)}
                  />
                </Col>
                <Col lg={6} style={{ maxHeight: 45, overflow: 'hidden' }}>
                  <Input onChange={(e) => this.handleOnChange('name', e.target.value)} value={item.name} />
                </Col>
                <Col className='text-right' lg={4}>
                  <Input onChange={(e) => this.handleOnChange('price', e.target.value)} value={this.getItemPrice(item.itemPrices)} />
                </Col>
                <Col className='text-right' lg={2}>
                  <Switch
                    onChange={(e) => this.handleOnChange('onlineStockAvailable', e)}
                    size='small'
                    checked={item.onlineStockAvailable}
                  />
                </Col>
                {/* <Col className='text-center' lg={3}>
                  <Switch
                    onChange={(e) => this.handleOnChange('displayInOnlineMenu2', e)}
                    size='small'
                    checked={item.displayInOnlineMenu2}
                  />
                </Col> */}
                <Col className='text-left' lg={6}>
                  {/* <Select
                    mode='multiple'
                    style={{ width: '100%' }}
                    // tagRender={tagRender}
                    dropdownMatchSelectWidth={true}
                    value={this.renderDefaultCategories()}
                    onChange={(value, options) => this.handleOnChangeCategory('categories', value, options)}
                  >
                    {this.renderCategoryOptions()}
                  </Select> */}
                  <TreeSelect
                    dropdownMatchSelectWidth
                    style={{ width: '100%' }}
                    defaultValue={this.renderDefaultCategories()}
                    value={parentCategories}
                    multiple
                    placeholder='Category'
                    treeData={categoryTreeNodesList}
                    onChange={(value) => this.handleOnChangeCategory(value)}
                  />
                </Col>
                {/* <Col className='text-center' lg={4}>
                  <Switch
                    onChange={(e) => this.handleOnChange('isMeal', e)}
                    size='small'
                    checked={item.isMeal} />
                </Col> */}

                <Col className='text-right' lg={3}>
                  <Spin spinning={loading}>
                    <Button
                      size='small' className='primary-button' type='primary' onClick={() => this.handleClickSave()}
                      style={{ backgroundColor: '#227D10', borderColor: '#227D10' }}
                    >
                      <FaSave style={{ color: '#ffffff' }} />
                    </Button>
                    <Button
                      size='small' className='primary-button' type='danger' onClick={() => this.handleClickCancel()}
                      style={{ marginLeft: 5 }}
                    >
                      <AiOutlineCloseCircle style={{ color: '#ffffff' }} />
                    </Button>
                  </Spin>
                </Col>

              </div>
              {this.renderModifiers().length > 0 && <Col span={24} className='item-modifier'>
                <h3>Modifiers</h3>
                <Spin spinning={this.state.modifierLoading}>
                  <Col span={24} className='item-modifier-header item-table-header' style={{ padding: '5px 0px' }}>
                    <Col span={12}>Name</Col>
                    <Col className='text-center' span={4}>Quantity</Col>
                    <Col className='text-center' span={4}>UOM</Col>
                    <Col className='text-right' span={4}>Actions</Col>
                  </Col>

                  {this.renderModifiers()}
                </Spin>
              </Col>}
            </Row>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { companyId, itemDetails, checkedItems } = props;
  return ({
    companyId: companyId,
    item: getItem(state, itemDetails.id),
    checkedItems: checkedItems
  });
};

export default connect(mapStateToProps, { updateItem, updateItemPrice, addItemPriceList, addItemToCategory, addItemCategories, removeItemFromCategory, fetchCategories, fetchItem, fetchItems, removeIngredient, fetchItemsForCategory })(ItemRow);
