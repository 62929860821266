import React, { useEffect, useState } from 'react'
import { Row, Col, Spin } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import './salesReports.css';

const ViewSummary = (props) => {

  const { data, visible, indexKey, loading = false } = props

  return (
    <div>
      { visible &&
        <Spin spinning={loading}>
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <h4 style={{ fontWeight: 200, fontSize: 14 }}>
                Displaying a total of <b style={{ fontWeight: 'bold' }}>{data.length}</b> {data.length === 1 ? indexKey : indexKey + 's'}
              </h4>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24} className='total_summary'>
              {data.values.map((d, index) => (
                <Row key={index}>
                  <Col span={16}>
                    <h4 style={{ fontWeight: d.key === 'total' ? 'bold' : 200, fontSize: 14, textAlign: 'right' }}>{(d.key.charAt(0).toUpperCase() + d.key.slice(1)).replace("_", " ")}</h4>
                  </Col>
                  <Col span={8}>
                    <h4 style={{ fontWeight: d.key === 'total' ? 'bold' : 200, fontSize: 14, textAlign: 'right' }}>{d.value}</h4>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Spin>
      }
    </div>
  )
}

export default ViewSummary