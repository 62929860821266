import React, { Component } from 'react';
import { Col, Button, Form, Input } from 'antd';
import DistanceRateRow from './DistanceRateRow';

// var data = {
//   additional: 40,
//   rates: [
//     {
//       from: 0,
//       to: 5,
//       value: 100
//     },
//     {
//       from: 6,
//       to: 10,
//       value: 200
//     },
//     {
//       from: 11,
//       to: 15,
//       value: 300
//     }
//   ]
// }

class DeliveryRateCard extends Component {
  state = {
    loading: false,
    data: undefined,
    additionalRateStatus: 'error',
    showNewButton: true
  }

  componentDidMount = () => {
    const { deliveryData } = this.props;
    this.setState({ loading: true });
    if (deliveryData != null && deliveryData.deliveryRates != null && deliveryData.deliveryRates.length > 0) {
      this.validateDistanceTo(deliveryData);
    } else {
      this.setState({ data: deliveryData });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { deliveryData } = nextProps;
    if (deliveryData != null && deliveryData.deliveryRates != null) {
      this.validateDistanceTo(deliveryData);
    } else {
      this.setState({ data: deliveryData });
    }
  }

  validateDistanceTo(deliveryData) {
    var showNew = true;
    var ratesArray = [];
    if (deliveryData && deliveryData.deliveryRates && deliveryData.deliveryRates.length > 0) {
      var returnRate = undefined;
      for (var j = 0; j < deliveryData.deliveryRates.length; j++) {
        if (deliveryData.deliveryRates[j].to > deliveryData.deliveryRates[j].from) {
          returnRate = Object.assign({}, deliveryData.deliveryRates[j], { toStatus: '' });
        } else {
          returnRate = Object.assign({}, deliveryData.deliveryRates[j], { toStatus: 'error' });
          showNew = false;
        }

        if (j === deliveryData.deliveryRates.length - 1) {
          returnRate = Object.assign({}, returnRate, { showDelete: true });
        } else {
          returnRate = Object.assign({}, returnRate, { showDelete: false });
        }
        ratesArray.push(returnRate);
      }
      console.log(this.state.data);
      this.setState({ data: { ...deliveryData, deliveryRates: ratesArray }, showNewButton: showNew });
    }
  }

  validateDelete() {
    const { data } = this.state;
    var ratesArray = [];
    if (data && data.deliveryRates && data.deliveryRates.length > 0) {
      var returnRate = undefined;
      for (var j = 0; j < data.deliveryRates.length; j++) {
        if (j === data.deliveryRates.length - 1) {
          returnRate = Object.assign({}, data.deliveryRates[j], { showDelete: true });
        } else {
          returnRate = Object.assign({}, data.deliveryRates[j], { showDelete: false });
        }

        ratesArray.push(returnRate);
      }

      this.setState({ data: { ...this.state.data, deliveryRates: ratesArray } });
    }
  }

  onClickAddNew = () => {
    const { data } = this.state;
    const { deliveryRates } = data;

    var newRate = { id: 'new', from: 0, to: 0, rate: 0, toStatus: 'error' };
    if (deliveryRates && deliveryRates.length > 0) {
      newRate = { id: 'new', from: deliveryRates[deliveryRates.length - 1].to, to: 0, rate: 0, toStatus: 'error' };
    }

    deliveryRates.push(newRate);
    this.setState({ data: { ...this.state.data, deliveryRates }, showNewButton: false });
    this.validateDelete();
  }

  onDeleteItem = (index) => {
    const { deliveryRates } = this.state.data;
    deliveryRates.splice(index, 1);
    this.setState({ data: { ...this.state.data, deliveryRates } });
    this.props.getData({ ...this.state.data, deliveryRates: deliveryRates });
  }

  onChangeInputValue = (index, feild, value) => {
    const { deliveryRates } = this.state.data;
    const { deliveryData } = this.props;
    var returnRates = undefined;
    if (feild === 'FROM') {
      returnRates = deliveryRates.map((rate, i) => (i === index ? Object.assign({}, rate, { from: parseInt(value) }) : rate));
      this.setState({ data: { ...this.state.data, deliveryRates: returnRates } });
      this.props.getData({ ...deliveryData, deliveryRates: returnRates });
    } else if (feild === 'TO') {
      returnRates = deliveryRates.map((rate, i) => (i === index ? Object.assign({}, rate, { to: parseInt(value) }) : rate));
      if (deliveryRates && deliveryRates.length > 0 && deliveryRates.length - 1 > index) {
        returnRates = returnRates.map((rate, i) => (i === index + 1 ? Object.assign({}, rate, { from: parseInt(value) + 1 }) : rate));
      }
      this.setState({ data: { ...this.state.data, deliveryRates: returnRates } });
      this.props.getData({ ...deliveryData, deliveryRates: returnRates });
    } else if (feild === 'RATE') {
      returnRates = deliveryRates.map((rate, i) => (i === index ? Object.assign({}, rate, { rate: parseInt(value) }) : rate));
      this.setState({ data: { ...this.state.data, deliveryRates: returnRates } });
      this.props.getData({ ...deliveryData, deliveryRates: returnRates });
    } else if (feild === 'ADDITIONAL') {
      this.setState({ data: { ...this.state.data, additionalRate: parseInt(value) } });
      this.props.getData({ ...deliveryData, additionalRate: parseInt(value) });
    }
  }

  render() {
    const { business } = this.props;
    const { data } = this.state;
    // console.log(subscriptions);
    return (
      <div style={{ margin: 5 }}>

        {data && data.deliveryRates && data.deliveryRates.length > 0 && data.deliveryRates.map((rate, index) => (
          <DistanceRateRow
            from={rate.from}
            to={rate.to}
            value={rate.rate}
            id={index}
            onDeleteItem={(index) => this.onDeleteItem(index)}
            showDelete={rate.showDelete}
            toStatus={rate.toStatus}
            business={business}
            onChangeInputValue={(index, feild, value) => this.onChangeInputValue(index, feild, value)}
          />))}
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          {this.state.showNewButton && <Button type='link' className='isoDeleteBtn' onClick={() => this.onClickAddNew()}>Add new rate</Button>}
        </div>
        <div>
          <Col lg={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 73 }}>
            <Col lg={14}>
              <p style={{ fontSize: 15, marginRight: 10, fontWeight: 600, color: '#555652' }}>Rate per additional kilometer </p>
            </Col>
            <Col lg={6}>
              <Form.Item validateStatus={this.state.additionalRateStatus} />
              <Input type='number' min='0' value={data && data.additionalRate} style={{ width: '100%', padding: 4 }} onChange={(e) => { this.onChangeInputValue(-1, 'ADDITIONAL', e.target.value); }} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()} />
            </Col>
          </Col>
        </div>

      </div>
    );
  }
}

export default DeliveryRateCard;
