import Api from './consts';
import commonfetch from './commonFetch';

export const authenticate = credentials => {
  const { url, method, headers } = Api.auth;
  const body = JSON.stringify(credentials);

  return fetch(url(), { method, headers, body })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        return { authenticity: responseJson.data || [] };
      } else {
        return { error: responseJson.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });
};

export const validateEmail = userId => {
  console.log(userId);
  const { url, method, headers } = Api.validateEmail;
  const body = JSON.stringify({ userId: userId });

  return fetch(url(), { method, headers, body })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        return { authenticity: responseJson.data || [] };
      } else {
        return { error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });
};

export const register = credentials => {
  console.log(credentials);
  const { url, method, headers } = Api.register;
  const body = JSON.stringify(credentials);

  return fetch(url(), { method, headers, body })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        return { authenticity: responseJson.data || [] };
      } else {
        return { errorObject: responseJson.error, error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });
};

export const addOtp = credentials => {
  const { url, method, headers } = Api.otp.add;
  const body = JSON.stringify(credentials);

  return fetch(url(), { method, headers, body })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        return { result: responseJson.data || [] };
      } else {
        return { error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });
};

export const verifyOtp = otpToVerified => {
  const { url, method, headers } = Api.otp.verify;
  const body = JSON.stringify(otpToVerified);

  return fetch(url(), { method, headers, body })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        return { verifiedData: responseJson.data || [] };
      } else {
        return { error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });
};

export const resetPassword = credentials => {
  const { url, method, headers } = Api.resetPassword;
  const body = JSON.stringify({ credentials });

  return fetch(url(), { method, headers, body })
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        console.log("Success reset response", responseJson.data);
        return { result: responseJson.data };
      } else {
        console.log("Error reset response", responseJson.error.message);
        return { error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });
};

export const getBranches = (userId, businessId) => {
  const { url, method, headers } = Api.auth.branches;
  return fetch(url(userId, businessId), { method, headers })
  .then(response => response.json())
  .then(responseJson => {
    if (responseJson.success) {
      return { result: responseJson.data };
    } else {
      return { error: responseJson.error.message };
    }
  }).catch(error => {
    return { error: 'An error occured while connecting to the server.' };
  });
}
