import { Button, Col, Form, Input, Modal, Row, Spin, Table, Tag, Tooltip } from 'antd';
import React, { Component } from 'react';
import { fetchCompany, fetchCompanyKioskCount, fetchCompanySubscriptions, updateKioskCount, updateSubscription } from '../../../actions/companies';
import { getAuthenticity, getCompanyById, getSubscriptionsByCompanyId } from '../../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';

import { connect } from 'react-redux';
import { currencyFormat } from '../../../helpers/common';
import { format } from 'currency-formatter';
import { getStatus, getStatusTooltip } from '../../../helpers/utility';
import moment from 'moment';
import { showLoadingMessage } from '../../../helpers/Message';
import withRouter from 'react-router/es/withRouter';

const { confirm } = Modal;

class SubscriptionList extends Component {
  state = {
    loading: false,
    loadingCount: false,
    submitting: false,
    textFilter: undefined,
    graceDays: undefined,
    kioskCount: 0
  }

  componentDidMount = () => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    this.setState({ kioskCount: company.businesses[0].kioskCount });
    if (this.props.subscriptions && this.props.subscriptions.length > 0) {
      this.showLoadingMessage();
      this.props.fetchCompanySubscriptions(companyId).then(() => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ loading: true });
      this.setState({ loadingCount: true });
      this.props.fetchCompanySubscriptions(companyId).then(() => {
        this.setState({ loading: false });
        this.setState({ loadingCount: false });
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  getColumns = () => {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: text => <p>{text}</p>
      },
      {
        title: 'Expire Date',
        dataIndex: 'expiryDate',
        key: 'manufacture',
        align: 'center',
        render: text => <p>{moment(text).format('YYYY-MM-DD')}</p>
      },
      {
        title: 'Code',
        dataIndex: 'subscriptionCode',
        key: 'subscriptionCode',
        align: 'center',
        render: text => <p>{text}</p>
      },
      {
        title: 'Grace Days',
        dataIndex: 'graceDays',
        key: 'graceDays',
        align: 'center',
        render: text => <p>{text}</p>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        render: (_, subscription) => {
          const status = getStatus(subscription);
          return (
            <Tooltip title={getStatusTooltip(status)}>
              <Tag color='#108ee9'>
                {status}
              </Tag>
            </Tooltip>
          );
        }
      },
      {
        title: ' Price(' + currencyFormat(business).symbol + ')',
        dataIndex: 'price',
        key: 'specialPrice',
        // editable: true,
        width: 150,
        align: 'right',
        render: price => <p>{price && price > 0 && format(price, currencyFormat(business))}</p>
      }];

    return columns.map(col => {
      if (!col.editable) return col;
      return {
        ...col,
        onCell: record => (
          {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleEditableCellChangeSave
          }
        )
      };
    });
  }

  handleEditableCellChangeSave = (record, value) => {
    console.log(record);
    this.onUpdateSubscription(record);
  }

  showConfirm = (id) => {
    const context = this;
    confirm({
      title: 'Do you want to extend subscription ?',
      content: <div> <Form.Item validateStatus={this.state.graceDaysStatus}><Input placeholder='Ex: 5' type='number' min='0' onChange={(event) => context.setState({ graceDays: event.target.value, graceDaysStatus: '' })} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()} /></Form.Item></div>,
      onOk() {
        const { companyId } = context.props.match.params;
        const { graceDays } = context.state;
        if (graceDays && graceDays !== '') {
          context.setState({ submitting: true });
          context.props.updateSubscription(companyId, id, { graceDays: parseInt(graceDays) }).then(response => {
            if (response.subscription) {
              context.setState({ failedStatus: 'success', submitting: false, graceDays: undefined });
              showSuccessNotification('Updated', 'Subscription updated successfully.');
            } else {
              context.setState({ failedStatus: 'failed', submitting: false, graceDays: undefined });
              showErrorNotification('Failed to update', response.error);
            }
          });
        }
      },
      onCancel() { }
    });
  }

  onUpdateSubscription = (updateRequest) => {
    const { companyId } = this.props.match.params;

    if (updateRequest.specialPrice === undefined || updateRequest.specialPrice === '') {

    } else {
      var subscriiptionObj = {
        specialPrice: parseInt(updateRequest.specialPrice)
      };
      this.setState({ submitting: true });
      this.props.updateSubscription(companyId, updateRequest.id, subscriiptionObj).then(response => {
        if (response.subscription) {
          this.setState({ submitting: false });
          showSuccessNotification('Updated', 'Subscription updated successfully.');
        } else {
          this.setState({ submitting: false });
          showErrorNotification('Failed to update', response.error);
        }
      });
    }
  }

  onUpdateKioskCount = () => {
    const { company, companyId } = this.props;
    const { kioskCount } = this.state;
    const businessId = company && company.businesses[0] && company.businesses[0].id;

    if (kioskCount.length >= 0 && parseInt(kioskCount)) {
      this.setState({ loadingCount: true });
      this.props.updateKioskCount(companyId, businessId, { kioskCount: parseInt(kioskCount) }).then(response => {
        if (response.error) {
          showErrorNotification('Failed.', response.error);
          this.setState({ loadingCount: false });
        } else {
          // console.log(response)
          if (response.company.code === 400) {
            showErrorNotification('Failed.', response.company.message);
            // this.setState({ kioskCount: response.count.data })
          } else {
            showSuccessNotification('Updated.', 'Kiosk Count updated successfully.');
          }
          // this.setState({  ingredients :[response.item.ingredients]});
          this.setState({ loadingCount: false });
        }
      });
    } else {
      this.setState({ countStatus: 'error' });
    }
  }

  onDateChange = (date, dateString) => this.setState({ expiryDate: moment(date).toDate() })

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Subscriptions.');
  }

  getFilteredDevices = () => {
    const { subscriptions } = this.props;
    const { textFilter } = this.state;

    let filteredDevicesList = subscriptions;

    if (textFilter) {
      const filterText = textFilter.toLowerCase();
      filteredDevicesList = subscriptions && subscriptions.length > 0 && subscriptions.filter(subscription => this.matchStrings(subscription.deviceId, filterText));
    }

    return filteredDevicesList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  getRowClassName = subscription => {
    const now = moment();
    const expiry = moment(subscription.expiryDate);
    const graceEnd = moment(subscription.expiryDate);
    graceEnd.add(subscription.graceDays, 'days');

    if (graceEnd.isBefore(now)) return 'table-row-critical';
    else if (expiry.isBefore(now)) return 'table-row-warning';
    else return '';
  }

  render() {
    const { loading, countStatus, kioskCount, submitting } = this.state;
    const { subscriptions } = this.props;

    // console.log(subscriptions);
    return (
      <div>
        <Row gutter={10}>
          <Col lg={4}>
            <Form.Item required validateStatus={countStatus}>
              <div>
                KIOSK COUNT
              </div>
            </Form.Item>
          </Col>
          <Col lg={16}>
            <Form.Item required validateStatus={countStatus}>
              <div>
                <input
                  className='ant-input'
                  value={kioskCount}
                  type='number'
                  min='0'
                  onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()}
                  onChange={(event) => this.setState({ kioskCount: event.target.value, countStatus: '' })}
                  onFocus={(event) => event.target.select()}
                />

              </div>
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item>
              <Button className='primary-button' loading={this.state.loadingCount} block type='primary' onClick={() => this.onUpdateKioskCount()}>Save</Button>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Spin spinning={(loading && subscriptions && subscriptions.length === 0) || submitting}>
            <Table
              rowClassName={this.getRowClassName}
              columns={this.getColumns()}
              dataSource={this.getFilteredDevices()}
            />
          </Spin>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authenticity = getAuthenticity(state);
  const { user: { userId: companyId } } = authenticity;
  return ({ subscriptions: getSubscriptionsByCompanyId(state, companyId), company: getCompanyById(state, companyId), companyId });
};

export default withRouter(connect(mapStateToProps, { fetchCompanySubscriptions, updateSubscription, fetchCompany, fetchCompanyKioskCount, updateKioskCount })(SubscriptionList));
