import React, { Component } from 'react';
import { Col, Form, Input, Row, Spin, DatePicker, InputNumber, Select, Button } from 'antd';
import moment from 'moment';

const { Option } = Select;

class ChequePaymentForm extends Component {
	amountValidator = amount => {
	  const { invoiceAmount = 0 } = this.props;

	  if (!amount || amount < 0) return 'Invalid receipt amount. Amount should be greater than zero.';
	  else if (amount > invoiceAmount) return "Receipt amount can't exceed invoice amount.";
	}

	textValidator = text => {
	  if (!text || text.length === 0) return false;

	  return true;
	}

	dateValidator = date => {
	  return moment(date).isBefore(moment());
	}

	render () {
	  const { submitting, handleOnChange, bankDepositPayment = {}, handleConfirmPayment, banks = [{ id: 1, bankname: 'RBC Royal Bank' }, { id: 1, bankname: 'Republic Bank' }] } = this.props;
	  const invalidAmount = this.amountValidator(bankDepositPayment.amount);
	  const validBranchName = this.textValidator(bankDepositPayment.branch);
	  const validAccountNumber = this.textValidator(bankDepositPayment.bankAccountNumber);
	  const validBank = this.textValidator(bankDepositPayment.bank);
	  const validDepositDate = this.dateValidator(bankDepositPayment.depositDate);
	  const confirmDisable = invalidAmount || !validAccountNumber || !validBank || !validDepositDate || !validBranchName;

	  return (
	    <Row gutter={10}>
    <Spin spinning={submitting}>
  <Form>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
  <div>Receipt Amount</div>
  <Form.Item
	              required validateStatus={invalidAmount ? 'error' : ''}
            help={invalidAmount}
          >
	              <div>
      <InputNumber
	                  style={{ width: '100%' }} value={bankDepositPayment.amount} autoFocus
  onChange={(value) => handleOnChange('bankDepositPayment', 'amount', value)}
	                />
    </div>
          </Form.Item>
</Col>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
	            <div>Remarks</div>
	            <Form.Item>
            <div>
	                <Input
      value={bankDepositPayment.remarks}
      onChange={(event) => handleOnChange('bankDepositPayment', 'remarks', event.target.value)}
    />
               </div>
          </Form.Item>
    </Col>
	          <Col span={24} lg={24} md={24} sm={24} xs={24}>
      <div>Bank</div>
      <Form.Item
	              required validateStatus={!validBank ? 'error' : ''}
	              help={!validBank && 'Invalid bank.'}
	            >
  <Select value={bankDepositPayment.bank} showSearch placeholder='Select Bank' dropdownMatchSelectWidth={false} onChange={(value) => handleOnChange('bankDepositPayment', 'bank', value)} style={{ width: '100%', maxWidth: '-webkit-fill-available' }}>
  {banks.map(bank => {
	                  return <Option key={bank._id} value={bank.bankname}>{bank.bankname}</Option>;
	                })}
</Select>
	            </Form.Item>
	          </Col>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
	            <div>Branch</div>
	            <Form.Item
	              required validateStatus={!validBranchName ? 'error' : ''}
            help={!validBranchName && 'Invalid branch name'}
          >
            <div>
    <Input
  value={bankDepositPayment.branch}
  onChange={(event) => handleOnChange('bankDepositPayment', 'branch', event.target.value)}
	                />
  </div>
          </Form.Item>
</Col>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
  <div>Account No</div>
  <Form.Item
            required validateStatus={!validAccountNumber ? 'error' : ''}
	              help={!validAccountNumber && 'Invalid account no'}
          >
	              <div>
      <Input
    value={bankDepositPayment.bankAccountNumber}
    onChange={(event) => handleOnChange('bankDepositPayment', 'bankAccountNumber', event.target.value)}
  />
    </div>
          </Form.Item>
	          </Col>
        <Col span={24} lg={24} md={24} sm={24} xs={24}>
  <div>Deposit Date</div>
  <Form.Item
  required validateStatus={!validDepositDate ? 'error' : ''}
  help={!validDepositDate && 'Invalid deposit date'}
	            >
  <div>
  <DatePicker
	                  allowClear={false}
	                  style={{ width: '100%' }}
  value={bankDepositPayment.depositDate}
  onChange={(value) => handleOnChange('bankDepositPayment', 'depositDate', value)}
	                />
	              </div>
	            </Form.Item>
	          </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
  <Button disabled={confirmDisable} type='primary' size='small' style={{ width: '100%', marginBottom: 10 }} onClick={() => handleConfirmPayment('bankDepositPayment')}>Submit Payment</Button>
	          </Col>
        {/* <Col style={{ float: 'right', width: 'fit-content' }} lg={12} md={24} sm={24} xs={24}>
							<h1><span style={{ margin: 5 }}>Amount:</span> {format(bankDepositPayment.amount, currencyFormat)}</h1>
						</Col> */}
	        </Form>
	      </Spin>
  </Row>
	  );
	}
}

export default ChequePaymentForm;
