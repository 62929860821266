import React, { Component } from 'react';
import { Modal, Form, Input } from 'antd';

class PriceListModalForm extends Component {
  render () {
    const { visible, onCancel, onCreate, handleOnChange, priceListName, priceListNameStatus } = this.props;

    return (
      <Modal
        visible={visible}
        title='Add Price List'
        okText='Save'
        okButtonProps={{ className: 'primary-button' }}
        onCancel={onCancel}
        onOk={onCreate}
      >
        <Form layout='vertical'>
          <Form.Item label='Name' validateStatus={priceListNameStatus}>
            <Input value={priceListName} onChange={event => handleOnChange('priceList', event.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default PriceListModalForm;
