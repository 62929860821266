import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Button, Col } from 'antd';
import moment from 'moment';
import AdvancedSearchForm from './AdvancedSearchForm';
import InvoiceListContainer from './InvoiceListContainer';
import { fetchInvoicesFromAdvancedSearch, removeInvoices, sendEmailsForCustomers } from '../../../actions/invoices';
import { getAuthenticity, getInvoices, getInvoicesForCustomer, getCompanyById } from '../../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import SideBar from '../../../components/SideBar';
import { showLoadingMessage } from '../../../helpers/Message';

const defaultQuery = {
  from: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
  to: moment(new Date()).endOf('day'),
  onlyOutstanding: false,
  number: undefined,
  customerName: undefined,
  customerAddress: undefined,
  status: [],
  page: 0,
  pageSize: 0
};

class InvoicesContainer extends Component {
  constructor() {
    super();
    this.state = {
      query: {
        ...defaultQuery
      },
      selectedRowKeys: [],
      invoicesLoading: false,
      selectedRows: [],
      deleteResponse: undefined,
      modalVisible: false,
      emailSending: false,
      emailRequestList: []
    };
  }

  componentDidMount = () => {
    const { authenticity: { user: { userId } } } = this.props;
    const { query } = this.state;

    this.setState({ invoicesLoading: true });
    this.props.fetchInvoicesFromAdvancedSearch({ ...query, agentId: userId }).then(() => {
      this.setState({ invoicesLoading: false });
    });
  }

  componentDidMount = () => {
    const { authenticity: { user: { userId } } } = this.props;
    const { query } = this.state;

    if (this.props.invoices && this.props.invoices.length > 0) {
      this.showLoadingMessage();
      this.props.fetchInvoicesFromAdvancedSearch({ ...query, agentId: userId }).then(() => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ invoicesLoading: true });
      this.props.fetchInvoicesFromAdvancedSearch({ ...query, agentId: userId }).then(() => {
        this.setState({ invoicesLoading: false });
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Invoices.');
  }

  handleFetchInvoices = () => {
    const { authenticity: { user: { userId } } } = this.props;
    const { query } = this.state;

    this.setState({ invoicesLoading: true });
    this.props.fetchInvoicesFromAdvancedSearch({ ...query, agentId: userId }).then(() => {
      this.setState({ invoicesLoading: false });
    });
  }

  handleDateRangeChange = date => {
    const queryData = {
      ...this.state.query,
      from: date[0].startOf('day'),
      to: date[1].endOf('day')
    };
    this.setState({ query: queryData });
  }

  handleInputTextChange = (value, type) => {
    switch (type) {
      case 'number': {
        const queryData = { ...this.state.query, number: value };
        this.setState({ query: queryData });
        break;
      }
      case 'customerName': {
        const queryData = { ...this.state.query, customerName: value };
        this.setState({ query: queryData });
        break;
      }
      case 'customerAddress': {
        const queryData = { ...this.state.query, customerAddress: value };
        this.setState({ query: queryData });
        break;
      }
      default:
        return 'NOT_FOUND';
    }
  }

  handleOnClickSelectInvoices = (invoiceIds, emailRequestList, selectedRowKeys, selectedRows) => {
    this.setState({ invoiceIds, selectedRowKeys, selectedRows, emailRequestList });
  }

  onChangeOutstandingFilter = (e) => {
    this.setState({ query: { ...this.state.query, onlyOutstanding: e.target.checked } });
  }

  handleBranchChange = value => {
    this.setState({ query: { ...this.state.query, branchid: value } });
  }

  handleOnClickBulkDelete = () => {
    console.log('handleOnClickBulkDelete');
  }

  handleClickDeleteInvoice = (invoiceId, customerId) => {
    // const { authenticity, removeInvoices } = this.props;
    // const deleteRequest = [{
    //     invoiceIds: [invoiceId],
    //     userId: authenticity.user.userId,
    //     customerId: customerId
    // }]

    // removeInvoices(deleteRequest).then(response => {
    //     const { deletedInvoiceResponse } = response;
    //     if (!deletedInvoiceResponse.status) {
    //         const dependantReceipts = [];
    //         deletedInvoiceResponse.relatedReceiptIds.forEach(id => {
    //             dependantReceipts.push(<Tag style={{ margin: '2px 0px' }}>{id}</Tag>)
    //         })
    //         const notification = <div>
    //             <p style={{ fontWeight: 600}}>{deletedInvoiceResponse.message}</p>
    //             <p style={{ fontSize: 12, marginBottom: 5 }}>{"Please remove the dependant receipts to continue."}</p>
    //             {dependantReceipts}
    //         </div>

    //         showErrorModal("Failed to delete Invoice ", notification)
    //     } else {
    //         // showSuccessNotification("Successful", deletedInvoiceResponse.message)
    //         showSuccessModal("Invoice Deleted", deletedInvoiceResponse.message)
    //     }
    // })
  }

  handleClickSearch = () => {
    this.handleFetchInvoices();
  }

  handleClickSendEmails = () => {
    const request = {
      subject: 'Monthly Subscription Invoice',
      templateId: 'INCY_POS_SUBSCRIPTION_INVOICE',
      data: this.state.emailRequestList
    };

    this.setState({ emailSending: true });
    this.props.sendEmailsForCustomers(request).then((response) => {
      const { error } = response;
      this.setState({ emailSending: false });
      if (error) {
        showErrorNotification('Failed', "Something wen't wrong. Try again later.");
      } else {
        showSuccessNotification('Successful', 'Email sent successfully.');
      }
    });
  }

  handleStatusSelectionChange = value => {
    this.setState({ query: { ...this.state.query, status: value } });
  }

  handleClickSendEmail = data => {
    const request = {
      subject: 'Monthly Subscription Invoice',
      templateId: 'INCY_POS_SUBSCRIPTION_INVOICE',
      data
    };

    this.setState({ emailSending: data[0].invoiceIds[0] });
    this.props.sendEmailsForCustomers(request).then((response) => {
      const { error } = response;
      this.setState({ emailSending: false });
      if (error) {
        showErrorNotification('Failed', "Something wen't wrong. Try again later.");
      } else {
        showSuccessNotification('Successful', 'Email sent successfully.');
      }
    });
  }

  render() {
    const { query, selectedRowKeys, selectedRows, invoicesLoading, emailSending } = this.state;
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return (
      <div className='container'>

        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Row>
              {/* <Card title={<h1 style={{fontWeight: 300}}>Invoices</h1>} style={{margin: 5}} extra={<Button type="primary" shape="circle" icon="plus" onClick={() => this.props.history.push('/incypos/invoices/1591964501730')}/>}> */}
              <Card
                title={<h4 style={{ fontWeight: 300 }}>Invoices</h4>}
                style={{ margin: 5 }}
                extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
              >
                <AdvancedSearchForm
                  onInputTextChange={this.handleInputTextChange}
                  onDateRangeChange={this.handleDateRangeChange}
                  onChangeOutstandingFilter={this.onChangeOutstandingFilter}
                  handleBranchChange={this.handleBranchChange}
                  onStatusSelectionChange={this.handleStatusSelectionChange}
                  query={query}
                  onClickSearch={this.handleClickSearch}
                />
              </Card>

              <Card style={{ margin: 5 }}>
                <InvoiceListContainer
                  business={business}
                  selectedRows={selectedRows}
                  selectedRowKeys={selectedRowKeys}
                  handleOnClickSelectInvoices={this.handleOnClickSelectInvoices}
                  handleOnClickBulkDelete={this.handleOnClickBulkDelete}
                  handleOnClickDelete={this.handleClickDeleteInvoice}
                  handleOnClickSendEmails={this.handleClickSendEmails}
                  handleOnClickSendEmail={this.handleClickSendEmail}
                  invoicesLoading={invoicesLoading}
                  showTotals
                  query={query}
                  enableRowSelection={false}
                  enableBulkAction
                  enableActions
                  emailSending={emailSending}
                />
              </Card>
            </Row>
          </Col>
        </Row>
      </div>

    );
  }
}

const mapStateToProps = state => {
  const authenticity = getAuthenticity(state);
  const { user: { userId: companyId } } = authenticity;
  return {
    authenticity: getAuthenticity(state), invoices: companyId ? getInvoicesForCustomer(state, companyId) : getInvoices(state),
    company: getCompanyById(state, companyId)
  };
};

export default connect(mapStateToProps, { fetchInvoicesFromAdvancedSearch, removeInvoices, sendEmailsForCustomers })(InvoicesContainer);
