import { authenticate as auth, validateEmail as validate, register, addOtp, verifyOtp, resetPassword, getBranches } from '../api/auth';

export const authenticate = credentials => dispatch => {
  dispatch({ type: 'LOGOUT' });
  return auth(credentials).then(response => {
    const { authenticity, error } = response;
    if (!error) {
      dispatch({ type: 'LOGIN', authenticity });
      if (authenticity && authenticity.user && authenticity.user.customer) { 
        dispatch({ type: 'FETCH_COMPANY', company: authenticity.user.customer, companyId: authenticity.user.userId });
        loadBranches(authenticity, dispatch);
      }
    }
    return response;
  });
};

export const validateEmail = credentials => dispatch => {
  return validate(credentials).then(response => {
    // if (!response.error) dispatch({ type: 'VALIDATE', authenticity: response.authenticity });
    return response;
  });
};

export const signup = credentials => dispatch => {
  dispatch({ type: 'LOGOUT' });
  return register(credentials).then(response => {
    if (!response.error) dispatch({ type: 'LOGIN', authenticity: response.authenticity });
    return response;
  });
};

export const logOut = () => dispatch => dispatch({ type: 'LOGOUT' });

export const generateOtpCode = credentials => dispatch => {
  return addOtp(credentials).then(response => {
    return response;
  });
};

export const verifyOtpCode = otpToVerify => dispatch => {
  return verifyOtp(otpToVerify).then(response => {
    if (!response.error && response.mobileVerified) dispatch({ type: 'MOBILE_VERIFIED', verifiedData: response.verifiedData });
    return response;
  });
};

export const changePassword = credentials => dispatch => {
  return resetPassword(credentials).then(response => {
    return response;
  });
};

const loadBranches = (authenticity, dispatch) => {
  const { userId, customer } = authenticity.user;
  getBranches(userId, customer.businesses[0].id).then(response => {
    if (!response.error) dispatch({ type: 'SET_BRANCHES', branches: response.result });
    else dispatch({ type: 'LOGOUT' });
  }).catch(_ => {
    dispatch({ type: 'LOGOUT' });
  });
}
