import React, { Component } from 'react';
import { Card, Row, Spin, Button, Col } from 'antd';
import { connect } from 'react-redux';
import { fetchPrintReceiptById } from '../../actions/receipts';
import { getAuthenticity, getReceiptById, getCompanyById } from '../../reducers';
import './index.css';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import SideBar from '../../components/SideBar';

class ReceiptPrint extends Component {
  state = {
    loading: false,
    emailSending: false
  }

  componentDidMount() {
    this.handleReceiptFetch();
  }

  handleReceiptFetch = () => {
    const { fetchPrintReceiptById, receiptId, customerId } = this.props;
    this.setState({ loading: true });
    const query = {
      customerId: customerId,
      receiptId: receiptId
    };
    fetchPrintReceiptById(query).then(response => {
      this.setState({ loading: false });
    });
  }

  renderInvoiceData = () => {
    const { receipt, company } = this.props;
    const invoiceDataList = [];
    const business = company && company.businesses && company.businesses['0'];
    receipt && receipt.receiptInvoices && receipt.receiptInvoices.length > 0 && receipt.receiptInvoices.forEach(invoice => {
      invoiceDataList.push(
        <div style={{ display: 'flex', marginTop: 5, fontSize: 12 }}>
          <div style={{ width: '20%' }}>
            <span>{invoice.number}</span>
          </div>
          <div style={{ width: '35%' }}>
            {this.renderInvoiceDetails(invoice.details)}
          </div>
          <div style={{ width: '15%' }}>
            <span>{moment(invoice.date).format('YYYY-MM-DD')}</span>
          </div>
          <div style={{ width: '15%', textAlign: 'right' }}>
            <span>{format(invoice.total, currencyFormat(business))}</span>
          </div>
          <div style={{ width: '15%', textAlign: 'right' }}>
            <span>{format(invoice.total - invoice.balanceToSettle, currencyFormat(business))}</span>
          </div>
        </div>
      );
    });

    return invoiceDataList;
  }

  renderInvoiceDetails = details => {
    const invoiceDetail = [];

    details && details.forEach(detail => {
      invoiceDetail.push(<span>{detail.itemName}</span>);
    });

    return invoiceDetail;
  }

  handleClickSendEmail = () => {
    const { receipt } = this.props;

    const request = {
      subject: 'Payment Receipt',
      templateId: 'INCY_RECEIPT',
      data: [{
        userId: receipt.customerId,
        receiptIds: [receipt.id]
      }]
    };

    this.setState({ emailSending: true });
    this.props.sendEmailsForCustomers(request).then((response) => {
      const { error } = response;
      this.setState({ emailSending: false });
      if (error) {
        showErrorNotification('Failed', "Something wen't wrong. Try again later.");
      } else {
        showSuccessNotification('Successful', 'Email sent successfully.');
      }
    });
  }

  handlePrintClick = () => {
    const content = document.getElementById('receipt-print').innerHTML;
    const printWindow = window.open('', '', 'height=768, width= 1024');
    printWindow.document.write(content);
    const paras = printWindow.document.getElementsByTagName('p');
    console.log('PARAS ', paras);
    for (const x in paras) {
      const pa = paras[x];
      if (pa.style) pa.style.margin = '0px !important';
    }
    printWindow.document.close();
    printWindow.print();
    printWindow.onafterprint = () => {
      printWindow.close();
    };
  }

  render() {
    const { loading } = this.state;
    const { receipt, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    console.log('RECEIPT ', receipt);
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Spin spinning={loading}>
              <Card
                className='print-receipt-view' style={{ margin: 5, lineHeight: 1.7 }}
                extra={
                  <div style={{ display: 'flex', width: 100, justifyContent: 'space-between' }}>
                    <Button size='small' type='primary' onClick={() => this.handlePrintClick()}>Print</Button>
                    <Button size='small' type='primary' onClick={() => this.props.history.goBack()}>Exit</Button>
                  </div>
                }
              >
                <div id='receipt-print'>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                      <h1 style={{ fontSize: 22, fotWeight: 500, fontFamily: 'Roboto' }}>PAYMENT RECEIPT</h1>
                      {receipt && <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Roboto' }}>
                        {receipt.companyName && <span style={{ fontSize: 18, fontWeight: 400 }}>{receipt.companyName}</span>}
                        {receipt.companyAddress && <span style={{ fontSize: 12, fontWeight: 400, maxWidth: 200 }}>{receipt.companyAddress}</span>}
                        {receipt.companyTelephone && <span style={{ fontSize: 12, fontWeight: 400 }}>Telephone: {receipt.companyTelephone}</span>}
                      </div>}
                    </div>
                    <div style={{ width: '30%', fontFamily: 'Roboto', textAlign: 'right', display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                      {receipt && receipt.printCount > 0 && <span>Print Count: {receipt.printCount}</span>}
                      <span>Print Date: {moment().format('YYYY-MM-DD:HH-MM')}</span>
                    </div>
                  </div>
                  {receipt && <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ fontSize: 14, width: '50%', fontFamily: 'Roboto', fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                      <span>Receipt No: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.number}</span></span>
                      <span>Receipt Date: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{moment(receipt.date).format('YYYY-MM-DD')}</span></span>
                      <span>Receipt Amount: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{format(receipt.amount, currencyFormat(business))}</span></span>
                      <span>Receipt Status: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.status}</span></span>
                      <span>Payment Type: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.paymentType}</span></span>
                      {receipt.paymentType === 'CHEQUE' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Bank Name: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.chequeBankName}</span></span>
                        <span>Cheque No: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.chequeNumber}</span></span>
                      </div>}
                      {receipt.paymentType === 'BANK_DEPOSIT' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Bank Name: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.bank}</span></span>
                        <span>Bank Account No: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.bankAccountNumber}</span></span>
                      </div>}
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 500, fontFamily: 'Roboto', width: '50%', display: 'flex', flexDirection: 'column' }}>
                      {receipt.customerName && <span>Customer Name: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.customerName}</span></span>}
                      {receipt.customerAddress && <span>Customer Address: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.customerAddress}</span></span>}
                      {receipt.customerTelephone && <span>Customer Telephone: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.customerTelephone}</span></span>}
                      {receipt.customerEmail && <span>Customer Email: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{receipt.customerEmail}</span></span>}
                    </div>
                  </div>}

                  <div style={{ minHeight: 50, border: '2px solid #d1d1d1', borderRadius: 10, marginTop: 20, padding: '10px 20px' }}>
                    <div style={{ display: 'flex', fontFamily: 'Roboto' }}>
                      <div style={{ width: '20%' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Invoice No</span>
                      </div>
                      <div style={{ width: '35%' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Description</span>
                      </div>
                      <div style={{ width: '15%' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Date</span>
                      </div>
                      <div style={{ width: '15%', textAlign: 'right' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Amount</span>
                      </div>
                      <div style={{ width: '15%', textAlign: 'right' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Payment</span>
                      </div>
                    </div>
                    {this.renderInvoiceData()}
                    <hr style={{ marginTop: 10, opacity: 0.2 }} />
                    {receipt && <div style={{ display: 'flex', fontFamily: 'Roboto', fontSize: 18, fontWeight: 600, marginTop: 10 }}>
                      <div style={{ width: '60%' }}>Total Receipt Amount</div>
                      <div style={{ width: '40%', textAlign: 'right' }}>{format(receipt.amount, currencyFormat(business))}</div>
                    </div>}
                  </div>

                  {receipt && receipt.remarks && receipt.remarks.length > 0 && <div style={{ marginTop: 20, fontFamily: 'Roboto', fontWeight: 500 }}>
                    <span>Remarks: {receipt.remarks}</span>
                  </div>}

                  <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: '50%', display: 'flex', fontFamily: 'Roboto', flexDirection: 'column' }}>
                      <span>Thank you for your payment</span>
                      <span style={{ fontSize: 10 }}>NON TRANSFERABLE AND NON REFUNDABLE</span>
                    </div>
                    <div style={{ width: '50%', fontFamily: 'Roboto' }}>
                      <span>Signature</span>
                    </div>
                  </div>
                </div>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { receiptId, customerId } = props.match.params;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return {
    authenticity: getAuthenticity(state),
    receipt: getReceiptById(state, receiptId), receiptId, customerId,
    company: getCompanyById(state, companyId)
  };
};
export default connect(mapStateToProps, { fetchPrintReceiptById })(ReceiptPrint);
