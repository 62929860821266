import React, { PureComponent } from 'react';
import { Button, List } from 'antd';

import moment from 'moment';
import SpecialDateRow from './SpecialDateRow';

const defaultDateObject = {
  id: 'new',
  dayOfWeek: -1,
  businessOpeningHour: '',
  businessClosingHour: '',
  businessOpeningMinute: '',
  businessClosingMinute: '',
  deliveryStartingHour: '',
  deliveryEndingHour: '',
  deliveryStartingMinute: '',
  deliveryEndingMinute: '',
  delivering: false,
  businessOpened: false,
  updating: false,
  date: '',
  description: '',

  lastBusinessOpeningHour: '',
  lastBusinessClosingHour: '',
  lastBusinessOpeningMinute: '',
  lastBusinessClosingMinute: '',
  lastDeliveryOpeningHour: '',
  lastDeliveryClosingHour: '',
  lastDeliveryOpenningMinute: '',
  lastDeliveryClosingMinute: '',
  lastDateValue: undefined,
  lastDescriptionValue: undefined,

  lastValueDeliveryClosed: false,
  lastValueBusinessClosed: false,
  dateStatus: '',
  businessOpeningStatus: '',
  businessClosingStatus: '',
  deliveryStartingStatus: '',
  deliveryEndingtatus: ''
};

class SpecialDatesCard extends PureComponent {
  state = {
    loading: false,
    data: [],
    isAddNewVisible: true
  }

  componentDidMount = () => {
    if (this.props.generalSettings) {
      console.log(this.props.generalSettings);
      this.initBusinessHours(this.props.generalSettings);
    }
  }

  componentWillReceiveProps (nextProps) {
    console.log(nextProps, this.state.data);

    if (nextProps.generalSettings && nextProps.generalSettings.businessHours) {
      this.initBusinessHours(nextProps.generalSettings);
    }
  }

  initBusinessHours = (generalSettings) => {
    var businessHours = [];
    generalSettings.businessHours && Object.keys(generalSettings.businessHours).forEach(key => {
      businessHours.push(generalSettings.businessHours[key]);
    });
    this.updatePrposWithDefaultValues(businessHours);
  }

  updatePrposWithDefaultValues = (newPropsData) => {
    var filterdData = newPropsData.filter((date) => (date.dayOfWeek === -1));
    var emptyArray = [];

    filterdData.forEach(date => {
      var defaultObject = { ...defaultDateObject };
      var extendedObj = Object.assign(defaultObject, date);
      emptyArray.push(extendedObj);
      return emptyArray;
    });
    this.storeValuesForReset(emptyArray);
  }

  storeValuesForReset = (data) => {
    var updatedData = data.map((rate, i) => {
      return (
        Object.assign({}, rate,
          {
            lastValueDeliveryClosed: rate.delivering
          }, {
            lastValueBusinessClosed: rate.businessOpened
          },
          {
            lastBusinessOpeningHour: rate.businessOpeningHour
          }, {
            lastBusinessClosingHour: rate.businessClosingHour
          }, {
            lastBusinessOpeningMinute: rate.businessOpeningMinute
          }, {
            lastBusinessClosingMinute: rate.businessClosingMinute
          }, {
            lastDeliveryOpeningHour: rate.deliveryStartingHour
          }, {
            lastDeliveryClosingHour: rate.deliveryEndingHour
          }, {
            lastDeliveryOpenningMinute: rate.deliveryStartingMinute
          }, {
            lastDeliveryClosingMinute: rate.deliveryEndingMinute
          },
          {
            lastDateValue: rate.date
          },
          {
            lastDescriptionValue: rate.description
          }
        ));
    });
    const sortedArray = updatedData.sort((a, b) => {
      const date1 = moment(a.date);
      const date2 = moment(b.date);
      return date1 - date2;
    });
    this.setState({ data: sortedArray, isAddNewVisible: true });
  }

  isValid = (index) => {
    const { data } = this.state;
    var valid = true;
    var returnData = undefined;

    returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { updating: false }) : rate));

    if (!returnData[index].date || returnData[index].date === '') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { dateStatus: 'error' }) : rate));
      this.setState({ data: returnData });
      valid = false;
    } else {
      this.setState({ data: returnData });
    }
    if (returnData[index].businessOpened) {
      if (returnData[index].businessOpeningHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpeningStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (returnData[index].businessClosingHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (!this.isValidTime(returnData[index].businessOpeningHour, returnData[index].businessOpeningMinute, returnData[index].businessClosingHour, returnData[index].businessClosingMinute)) {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      }
    }

    if (returnData[index].delivering) {
      if (returnData[index].deliveryStartingHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryStartingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (returnData[index].deliveryEndingHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (!this.isValidTime(returnData[index].deliveryStartingHour, returnData[index].deliveryStartingMinute, returnData[index].deliveryEndingHour, returnData[index].deliveryEndingMinute)) {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      }
    }

    return valid;
  }

  isValidTime (startHour, startMinute, endHour, endMinute) {
    if (endHour > startHour) {
      return true;
    } else if (endHour === startHour) {
      if (endMinute > startMinute) {
        console.log('yes');
        return true;
      } else {
        console.log('no');
        return false;
      }
    } else {
      console.log('no');
      return false;
    }
  }

  onChangeValue = (index, feild, value) => {
    const { data } = this.state;
    const { onUpdate } = this.props;
    var returnData = undefined;

    if (feild === 'businessOpenTime') {
      if (value && value !== '') {
        var hour1 = value.split(':')[0];
        var minute1 = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpeningHour: parseInt(hour1) }, { businessOpeningMinute: parseInt(minute1) }, { updating: true }, { businessOpeningStatus: '' }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpeningHour: value }, { businessOpeningMinute: value }, { updating: true }, { businessOpeningStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'businessCloseTime') {
      if (value && value !== '') {
        var hour2 = value.split(':')[0];
        var minute2 = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingHour: parseInt(hour2) }, { businessClosingMinute: parseInt(minute2) }, { updating: true }, { businessClosingStatus: '' }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingHour: value }, { businessClosingMinute: value }, { updating: true }, { businessClosingStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'deliveryOpenTime') {
      if (value && value !== '') {
        var hour3 = value.split(':')[0];
        var minute3 = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryStartingHour: parseInt(hour3) }, { deliveryStartingMinute: parseInt(minute3) }, { deliveryStartingStatus: '' }, { updating: true }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryStartingHour: value }, { deliveryStartingMinute: value }, { updating: true }, { deliveryStartingStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'deliveryCloseTime') {
      if (value && value !== '') {
        var hour = value.split(':')[0];
        var minute = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingHour: parseInt(hour) }, { deliveryEndingMinute: parseInt(minute) }, { deliveryEndingStatus: '' }, { updating: true }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingHour: value }, { deliveryEndingMinute: value }, { updating: true }, { deliveryEndingStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'businessOpened') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpened: value }, { updating: true }, { businessOpeningStatus: '' }, { businessClosingStatus: '' }) : rate));
      this.setState({ data: returnData });
    } else if (feild === 'delivering') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { delivering: value }, { updating: true }, { deliveryStartingStatus: '' }, { deliveryEndingStatus: '' }) : rate));
      this.setState({ data: returnData });
    } else if (feild === 'description') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { description: value }, { updating: true }) : rate));
      this.setState({ data: returnData });
    } else if (feild === 'save') {
      if (this.isValid(index)) {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { updating: false }) : rate));
        onUpdate(returnData[index]);
      }
    } else if (feild === 'reset') {
      returnData = this.resetData(index);
      this.setState({ data: returnData });
    } else if (feild === 'date') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { date: value }, { dateStatus: '' }, { updating: true }) : rate));
      this.setState({ data: returnData });
    }
  }

  resetData = (index) => {
    var resetedData = this.state.data.map((rate, i) => {
      var lastValueDeliveryClosed = rate.lastValueDeliveryClosed !== undefined ? rate.lastValueDeliveryClosed : rate.delivering;
      var lastValueBusinessClosed = rate.lastValueBusinessClosed !== undefined ? rate.lastValueBusinessClosed : rate.businessOpened;
      var lastBusinessOpeningHour = rate.lastBusinessOpeningHour !== undefined ? rate.lastBusinessOpeningHour : rate.businessOpeningHour;
      var lastBusinessClosingHour = rate.lastBusinessClosingHour !== undefined ? rate.lastBusinessClosingHour : rate.businessClosingHour;
      var lastBusinessOpeningMinute = rate.lastBusinessOpeningMinute !== undefined ? rate.lastBusinessOpeningMinute : rate.businessOpeningMinute;
      var lastBusinessClosingMinute = rate.lastBusinessClosingMinute !== undefined ? rate.lastBusinessClosingMinute : rate.businessClosingMinute;

      var lastDeliveryOpeningHour = rate.lastDeliveryOpeningHour !== undefined ? rate.lastDeliveryOpeningHour : rate.deliveryStartingHour;
      var lastDeliveryClosingHour = rate.lastDeliveryClosingHour !== undefined ? rate.lastDeliveryClosingHour : rate.deliveryEndingHour;
      var lastDeliveryOpenningMinute = rate.lastDeliveryOpenningMinute !== undefined ? rate.lastDeliveryOpenningMinute : rate.deliveryStartingMinute;
      var lastDeliveryClosingMinute = rate.lastDeliveryClosingMinute !== undefined ? rate.lastDeliveryClosingMinute : rate.deliveryEndingMinute;
      var lastDateValue = rate.lastDateValue !== undefined ? rate.lastDateValue : rate.date;
      var lastDescriptionValue = rate.lastDescriptionValue !== undefined ? rate.lastDescriptionValue : rate.description;
      return (
        i === index ? Object.assign({}, rate,
          {
            delivering: lastValueDeliveryClosed
          }, {
            businessOpened: lastValueBusinessClosed
          }, {
            businessOpeningHour: lastBusinessOpeningHour
          }, {
            businessClosingHour: lastBusinessClosingHour
          }, {
            businessOpeningMinute: lastBusinessOpeningMinute
          }, {
            businessClosingMinute: lastBusinessClosingMinute
          }, {
            deliveryStartingHour: lastDeliveryOpeningHour
          }, {
            deliveryEndingHour: lastDeliveryClosingHour
          }, {
            deliveryStartingMinute: lastDeliveryOpenningMinute
          }, {
            deliveryEndingMinute: lastDeliveryClosingMinute
          }, {
            date: lastDateValue
          }, {
            description: lastDescriptionValue
          }, {
            updating: false
          }
        ) : rate);
    });

    return resetedData;
  }

  getDayNameByDayNumber = (dayNo) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[dayNo];
  }

  getFormatedTime = (hour, minute) => {
    var formattdTime = '';
    if (hour !== '' && minute !== '') {
      formattdTime = hour + ':' + minute;
    }
    return formattdTime;
  }

  onClickAddNew = () => {
    this.setState({ data: [...this.state.data, defaultDateObject], isAddNewVisible: false });
  }

  removeItem = (index, id) => {
    console.log(index, id);
    const { data } = this.state;
    const { onDelete } = this.props;
    if (id === 'new') {
      const items = this.state.data.filter(item => item.id !== id);
      this.setState({ data: items, isAddNewVisible: true });
    } else {
      onDelete(data[index]);
    }
  }

  render () {
    const { data } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'right' }}>
          <Button type='link' className='isoDeleteBtn' onClick={this.props.onImportCalender}>Import from holiday calender</Button>
        </div>
        <div style={{ textAlign: 'right' }}>
          {this.state.isAddNewVisible && <Button type='link' className='isoDeleteBtn' onClick={this.onClickAddNew}>Add new</Button>}
        </div>

        <List
          itemLayout='horizontal'
          dataSource={data}
          grid={{ gutter: 5 }}
          loading={this.state.loading}
          size='small'
          pagination={{
            size: 'small',
            pageSize: 10
          }}
          renderItem={(date, index) => (
            <List.Item rowKey={date.id}>
              <SpecialDateRow
                id={date.id}
                data={data}
                dayOfWeek={this.getDayNameByDayNumber(moment(date.date).day())}
                businessOpened={date.businessOpened}
                businessOpenTime={this.getFormatedTime(date.businessOpeningHour, date.businessOpeningMinute)}
                businessCloseTime={this.getFormatedTime(date.businessClosingHour, date.businessClosingMinute)}
                deliveryOpenTime={this.getFormatedTime(date.deliveryStartingHour, date.deliveryStartingMinute)}
                deliveryCloseTime={this.getFormatedTime(date.deliveryEndingHour, date.deliveryEndingMinute)}
                delivering={date.delivering}
                updating={date.updating}
                lastValueOpenTime={this.getFormatedTime(date.lastBusinessOpeningHour, date.lastBusinessOpeningMinute)}
                lastValueCloseTime={this.getFormatedTime(date.lastBusinessClosingHour, date.lastBusinessClosingMinute)}
                lastValueDeliveryOpenTime={this.getFormatedTime(date.lastDeliveryOpeningHour, date.lastDeliveryOpenningMinute)}
                lastValueDeliveryCloseTime={this.getFormatedTime(date.lastDeliveryClosingHour, date.lastDeliveryClosingMinute)}
                lastValueDeliveryClosed={date.lastValueDeliveryClosed}
                lastValueBusinessClosed={date.lastValueBusinessClosed}
                date={date.date === null ? '' : date.date}
                description={date.description}
                onChangeValue={this.onChangeValue}
                removeItem={this.removeItem}
                dateStatus={date.dateStatus}
                businessOpeningStatus={date.businessOpeningStatus}
                businessClosingStatus={date.businessClosingStatus}
                deliveryStartingStatus={date.deliveryStartingStatus}
                deliveryEndingStatus={date.deliveryEndingStatus}
              />
            </List.Item>
          )}
        />

        {/*

        {data && data.length > 0 && data.map((date, index) => (
          <Spin spinning={this.state.loading} >
            <SpecialDateRow
              id={date.id}
              key={index}
              dayOfWeek={this.getDayNameByDayNumber(moment(date.date).day())}
              index={index}
              businessOpened={date.businessOpened}
              businessOpenTime={this.getFormatedTime(date.businessOpeningHour, date.businessOpeningMinute)}
              businessCloseTime={this.getFormatedTime(date.businessClosingHour, date.businessClosingMinute)}
              deliveryOpenTime={this.getFormatedTime(date.deliveryStartingHour, date.deliveryStartingMinute)}
              deliveryCloseTime={this.getFormatedTime(date.deliveryEndingHour, date.deliveryEndingMinute)}
              delivering={date.delivering}
              updating={date.updating}
              lastValueOpenTime={this.getFormatedTime(date.lastBusinessOpeningHour, date.lastBusinessOpeningMinute)}
              lastValueCloseTime={this.getFormatedTime(date.lastBusinessClosingHour, date.lastBusinessClosingMinute)}
              lastValueDeliveryOpenTime={this.getFormatedTime(date.lastDeliveryOpeningHour, date.lastDeliveryOpenningMinute)}
              lastValueDeliveryCloseTime={this.getFormatedTime(date.lastDeliveryClosingHour, date.lastDeliveryClosingMinute)}
              lastValueDeliveryClosed={date.lastValueDeliveryClosed}
              lastValueBusinessClosed={date.lastValueBusinessClosed}
              date={date.date === null ? "" : date.date}
              description={date.description}
              onChangeValue={(index, feild, value) => this.onChangeValue(index, feild, value)}
              removeItem={(index, id) => this.removeItem(index, id)}
            />
          </Spin>
        ))} */}
      </div>
    );
  }
}

export default SpecialDatesCard;
