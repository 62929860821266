import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Form } from 'antd';
import { getAuthenticity } from '../../../reducers';
import '../../../App.css';
import { changePassword, validateEmail, generateOtpCode } from '../../../actions/auth';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';

class SendOtp extends Component {
    state = {
        verificationType: 'EMAIL',
        showEmailForm: true,
        showPhoneNumberForm: false,
        submitting: false,
        email: undefined,
        emailStatus: undefined,
        phoneNumber: undefined,
        phoneNumberStatus: undefined
    };

    showLabel = value => (value && value.length) > 0 ? 'visible' : 'hidden';

    handleOnChange = (type, value) =>{
        switch (type){
            case 'EMAIL' :
                this.setState({email: value, emailStatus: this.getValidationStatus(type, value) });
                // return this.isValidEmail(value) ? 'success' : 'error';
                break;
            
            case 'SMS':
                break;

            default:
                return null;
        }
    }

    getValidationStatus = (type, value) =>{
        switch (type){
            case 'EMAIL' :
                return this.isValidEmail(value) ? 'success' : 'error';
                break;
            
            case 'SMS':
                break;

            default:
                return null;
        }
    }

    isValidEmail = email => {
        const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email && email.length > 0 && emailExpression.test(email.toLowerCase());
    }

    sendOtp = (type) => {
        switch (type) {
            case 'EMAIL':
                this.sendEmail();
                break;
            case 'SMS':
                this.sendSms();
                break;
            default:
                return null;
        }
    }

    sendEmail = () => {
        const { email } = this.state;

        if (this.getValidationStatus('EMAIL', email) !== 'success') {
            this.setState({ emailStatus: 'error' });
            return;
        }

        this.setState({ submitting: true});
        this.props.validateEmail(email).then(response => {
            const { error, data } = response;
            if (!error){
                this.handleVerificationCodeRequest();
            }else{
                showErrorNotification('Failed.', error);
                this.setState({submitting: false});
            }
        })
    }

    sendSms = () => {

    }

    handleVerificationCodeRequest = () => {
        let { email, phoneNumber, verificationType } = this.state;
    
        this.props.generateOtpCode({ username: email, email: email, phoneNumber: phoneNumber, type: verificationType }).then(response => {
            const { result } = response;
          if (result.error) {
            this.setState({ submitting: false });
            showErrorNotification('Request failed.', 'Something went wrong when sending sms. Please try again later or contact fooder support center.');
          } else {
            this.setState({ submitting: false });
            showSuccessNotification('Success', 'Verification code sent.');
            this.props.handleSubmit('Send OTP', result);
          }
        }).catch(error => {
          this.setState({ submitting: false });
          return { error };
        });
      }

      handleBackPress = () => {
          this.props.handleBackPress('Send OTP');
      }

    render () {
        const { email, verificationType } = this.state;

        return (
            <div className='text-center'>
                <h2 className='signUp-form-title'>Reset password </h2>
                <p className='signUp-form-description'>Enter the email associated with your account and we'll send an email with instruction to reset your password.</p>
                <br/>
                <Form className='signUp-form'>
                    <Form.Item colon={false} validateStatus={this.state.emailStatus} label={<span style={{ visibility: this.showLabel(email) }} className='incyInputLabel'>Email</span>}>
                        <Input size='middle' placeholder='Email' onChange={e => this.handleOnChange('EMAIL', e.target.value)} />
                    </Form.Item>
                    <div className='isoInputWrapper'>
                        <Button type='primary' size='middle' onClick={() => this.sendOtp(verificationType)} loading={this.state.submitting}>
                            Send Email
                        </Button>
                        <Button style={{ marginLeft: 10 }} size='middle' onClick={() => this.handleBackPress()} loading={this.state.submitting}>
                            Back
                        </Button>
                    </div>  
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return { authData : getAuthenticity(state) };
}

export default connect(mapStateToProps, {changePassword, validateEmail, generateOtpCode})(SendOtp);