import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Image from '../image/rob.png';
import { Button, Card, Col, Row } from 'antd';
import SideBar from '../components/SideBar';

export default class extends Component {
  render () {
    return (
      <Row gutter={20} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col lg={4} md={4} sm={12} xs={12}>
          <SideBar />
        </Col>
        <Col lg={20} md={20} sm={12} xs={12}>
          <Card>
            <Row className='content-404'>
              <Col span={12} style={{ paddingLeft: 40 }}>
                <h1>
                  404
                </h1>
                <h3>
                  Hey! Thank you for checking out our app.
                </h3>
                <p>
                  It’s not quite ready yet, but we are working hard and it will coming soon
                </p>
                <Button type='primary'>
                  <Link to='/users'>
                    BACK HOME
                  </Link>
                </Button>
              </Col>
              <Col span={12} className='text-center'>
                <img height={400} alt='#' src={Image} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}
