export default class Api { }

const { hostname } = window.location;
const host = hostname === 'localhost' ? 'localhost:8081' : window.location.host;
Api.server = window.location.protocol + '//' + host + '/api';
// Api.server = window.location.protocol + '//' + host;

Api.notificationService = 'https://admin.incypos.com/incyfy';
Api.endpointResources = 'https://services.incylabs.com';
//Api.endpointResources = 'http://localhost:9000';
Api.endpointAuth = Api.server + '/auth';
// Api.endpointAuth = Api.server + '/users/';
Api.endpointValidateEmail = Api.server + '/validateEmail';
Api.endpointRegister = Api.server + '/register';
Api.endpointCompanies = Api.server + '/companies';
Api.endpointItems = Api.server + '/items';
Api.endpointInvoices = Api.server + '/invoices';
Api.endpointReceipts = Api.server + '/receipts';
Api.endpointAgents = Api.server + '/agents';
Api.endpointOrders = Api.server + '/orders';
Api.endpointCashiers = Api.server + '/cashiers';
Api.endpointCustomers = Api.server + '/customers';
Api.endpointTransferNotes = Api.server + '/transferNotes';
Api.endpointInventory = Api.server + '/inventory';
Api.endpointGeneric = Api.server + '/generic';

Api.endpointSMSnotification = Api.notificationService;
Api.calender = 'https://www.googleapis.com/calendar/v3/calendars/en.';

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

Api.auth = {
  url: () => Api.endpointAuth,
  method: 'POST',
  headers: HEADERS,
  branches: {
    url: (userId, businessId) => Api.endpointAuth + '/users/' + userId + '/businesses/' + businessId + '/branches',
    method: 'GET',
    headers: HEADERS,
  },
};

Api.resetPassword = {
  url: () => Api.endpointAuth + '/resetPassword',
  method: 'POST',
  headers: HEADERS
};

Api.otp = {
  add: {
    url: () => Api.endpointAuth + '/otps',
    method: 'POST',
    headers: HEADERS
  },
  verify: {
    url: () => Api.endpointAuth + '/otps/verify',
    method: 'POST',
    headers: HEADERS
  }
};

Api.validateEmail = {
  url: () => Api.endpointValidateEmail,
  method: 'POST',
  headers: HEADERS
};

Api.register = {
  url: () => Api.endpointRegister,
  method: 'POST',
  headers: HEADERS
};

Api.companies = {
  getAll: {
    url: () => Api.endpointCompanies,
    method: 'GET',
    headers: HEADERS
  },
  getDeviceList: {
    url: companyId => Api.endpointCompanies + '/' + companyId + '/companyDevices',
    method: 'GET',
    headers: HEADERS
  },
  get: {
    url: companyId => Api.endpointCompanies + '/' + companyId + '/companyBasicInfo',
    method: 'GET',
    headers: HEADERS
  },
  /* verify client by agent  */
  verifyClient: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/verify',
    method: 'POST',
    headers: HEADERS
  },

  getSubscriptions: {
    url: companyId => Api.endpointCompanies + '/' + companyId + '/subscriptions',
    method: 'GET',
    headers: HEADERS
  },
  updateDeviceStatus: {
    url: (companyId, deviceId) => Api.endpointCompanies + '/' + companyId + '/devices/' + deviceId + '/status',
    method: 'PUT',
    headers: HEADERS
  },
  updateSubscriptionDate: {
    url: (companyId, subscriptionId) => Api.endpointCompanies + '/' + companyId + '/subscriptions/' + subscriptionId,
    method: 'PUT',
    headers: HEADERS
  },

  getSubscriptionDevice: {
    url: (companyId, subscriptionId) => Api.endpointCompanies + '/' + companyId + '/subscription/' + subscriptionId + '/device',
    method: 'GET',
    headers: HEADERS
  },

  addSubscriptionToDevice: {
    url: (companyId, deviceId, subscriptionId) => Api.endpointCompanies + '/' + companyId + '/devices/' + deviceId + '/subscriptions/' + subscriptionId,
    method: 'PUT',
    headers: HEADERS
  },

  deleteSubscriptionFromDevice: {
    url: (companyId, deviceId) => Api.endpointCompanies + '/' + companyId + '/devices/' + deviceId + '/subscriptions/delete',
    method: 'PUT',
    headers: HEADERS
  },

  getKioskCount: {
    url: (companyId, bussinessId) => Api.endpointCompanies + '/' + companyId + '/bussiness/' + bussinessId + '/kioskCount',
    method: 'GET',
    headers: HEADERS
  },

  updateKioskCount: {
    url: (companyId, bussinessId) => Api.endpointCompanies + '/' + companyId + '/bussiness/' + bussinessId + '/kioskCount',
    method: 'PUT',
    headers: HEADERS
  },

  categories: {
    getAll: {
      url: companyId => Api.endpointCompanies + '/' + companyId + '/categories',
      method: 'GET',
      headers: HEADERS
    },
    get: {
      url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/categories/' + id,
      method: 'GET',
      headers: HEADERS
    },
    add: {
      url: (companyId, parentId) => Api.endpointCompanies + '/' + companyId + '/categories/' + parentId,
      method: 'POST',
      headers: HEADERS
    },
    addItem: {
      url: (companyId, parentId) => Api.endpointCompanies + '/' + companyId + '/categories/' + parentId + '/items',
      method: 'POST',
      headers: HEADERS
    },

    update: {
      url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/categories/' + id,
      method: 'PUT',
      headers: HEADERS
    },
    delete: {
      url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/categories/' + id,
      method: 'DELETE',
      headers: HEADERS
    },
    deleteItem: {
      url: (companyId, parentId) => Api.endpointCompanies + '/' + companyId + '/categories/' + parentId + '/items',
      method: 'DELETE',
      headers: HEADERS
    }
  },

  getInvoicesForCustomer: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/invoices',
    method: 'GET',
    headers: HEADERS
  },
  updateDevice: {
    url: (companyId, deviceId) => Api.endpointCompanies + '/' + companyId + '/devices/' + deviceId,
    method: 'PUT',
    headers: HEADERS
  },
  customUrlStatus: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/customUrl/status',
    method: 'POST',
    headers: HEADERS
  },
  updateCustomUrl: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/customUrl/update',
    method: 'POST',
    headers: HEADERS
  },
  updateOnlineAvailability: {
    url: (companyId, businessId) => Api.endpointCompanies + '/' + companyId + '/business/' + businessId + '/allowOnlineOrders',
    method: 'POST',
    headers: HEADERS
  },
  //  Send email verification
  sendContactEmailVerification: {
    url: companyId => Api.endpointCompanies + '/' + companyId + '/sendVerificationEmail/',
    method: 'POST',
    headers: HEADERS
  },
  verifyContactEmail: {
    url: (type, hash) => Api.server + '/verifyHash/' + type + '/' + hash,
    method: 'GET',
    headers: HEADERS
  }
};
Api.items = {
  getAll: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/items',
    method: 'GET',
    headers: HEADERS
  },
  add: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/items',
    method: 'POST',
    headers: HEADERS
  },

  bulkDelete: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/items',
    method: 'DELETE',
    headers: HEADERS
  },
  get: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId,
    method: 'GET',
    headers: HEADERS
  },
  updateItem: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId,
    method: 'PUT',
    headers: HEADERS
  },
  updateItemPrice: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/priceList',
    method: 'PUT',
    headers: HEADERS
  },
  addItemIngredient: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/ingredient',
    method: 'POST',
    headers: HEADERS
  },

  addItemGroupModifire: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/groupModifire',
    method: 'POST',
    headers: HEADERS
  },

  insertItemPriceList: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/priceList',
    method: 'POST',
    headers: HEADERS
  },

  insertItemPrinterLocation: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/printerLocation',
    method: 'POST',
    headers: HEADERS
  },

  deleteItemPrinterLocation: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/printerlocation',
    method: 'DELETE',
    headers: HEADERS
  },

  deleteItemPrice: {
    url: (companyId, itemPriceId) => Api.endpointCompanies + '/' + companyId + '/itemPrice/' + itemPriceId,
    method: 'DELETE',
    headers: HEADERS
  },

  addItemCategories: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/categories',
    method: 'POST',
    headers: HEADERS
  },

  getForCategory: {
    url: (companyId, categoryId) => Api.endpointCompanies + '/' + companyId + '/categories/' + categoryId + '/items',
    method: 'GET',
    headers: HEADERS
  },
  getAllItemsForCategory: {
    url: (companyId, categoryId) => Api.endpointCompanies + '/' + companyId + '/categories/' + categoryId + '/items/all',
    method: 'GET',
    headers: HEADERS
  },
  updateVariantHeaderDetails: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/variantHeaderDetails',
    method: 'PUT',
    headers: HEADERS
  },
  updateVariantItemOptions: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/variant',
    method: 'PUT',
    headers: HEADERS
  },
  addVariant: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/variant',
    method: 'POST',
    headers: HEADERS
  },
  removeVariant: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/variant',
    method: 'DELETE',
    headers: HEADERS
  },
  updateVariantDefinition: {
    url: (companyId, itemId) => Api.endpointCompanies + '/' + companyId + '/items/' + itemId + '/variantDefinition',
    method: 'PUT',
    headers: HEADERS
  },
  createVariantGroup: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/variants',
    method: 'POST',
    headers: HEADERS
  }
};
Api.printerLocations = {
  getAll: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/printerLocations',
    method: 'GET',
    headers: HEADERS
  },
  add: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/printerLocations',
    method: 'POST',
    headers: HEADERS
  }
};
Api.priceLists = {
  getAll: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/priceLists',
    method: 'GET',
    headers: HEADERS
  },
  add: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/priceLists',
    method: 'POST',
    headers: HEADERS
  }
};


Api.inventory = {
  update: {
    url: (companyId, id) => Api.endpointInventory + '/' + companyId + '/inventory/' + id,
    method: 'PUT',
    headers: HEADERS
  },
  delete: {
    url: (companyId, id) => Api.endpointInventory + '/' + companyId + '/inventory/' + id,
    method: 'DELETE',
    headers: HEADERS
  },
  advancedSearch: {
    url: () => Api.endpointInventory + '/advancedSearch',
    method: 'POST',
    headers: HEADERS
  },

  getWarehousesByBranchIds: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/warehouses',
    method: 'POST',
    headers: HEADERS
  },

  getItemCategoriesOfInventoryItemByBranchIds: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventory/itemCategories',
    method: 'POST',
    headers: HEADERS
  },

  getItemBrandsOfInventoryItemByBranchIds: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventory/itemBrands',
    method: 'POST',
    headers: HEADERS
  },

  getItemGroupsOfInventoryItemByBranchIds: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventory/itemGroups',
    method: 'POST',
    headers: HEADERS
  },

  insertItems: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventoryitems',
    method: 'POST',
    headers: HEADERS
  },

  updateItems: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventoryitems',
    method: 'PUT',
    headers: HEADERS
  },

  deleteItems: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventoryitems',
    method: 'DELETE',
    headers: HEADERS
  },

  importBulkInventoryItems: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventory/importBulkInventoryItems',
    method: 'POST',
    headers: HEADERS
  },

  exportInventoryItems: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventory/exportInventoryItems',
    method: 'POST',
    headers: HEADERS
  },

  exportInventoryItemsTemplate: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/inventory/exportInventoryItemsTemplate',
    method: 'POST',
    headers: HEADERS
  },


};

Api.customers = {
  getAll: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/customers',
    method: 'GET',
    headers: HEADERS
  },
  update: {
    url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/customers/' + id,
    method: 'PUT',
    headers: HEADERS
  },
  delete: {
    url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/customers/' + id,
    method: 'DELETE',
    headers: HEADERS
  },
  update: {
    url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/customers/' + id,
    method: 'PUT',
    headers: HEADERS
  },
  advancedSearch: {
    url: () => Api.endpointCustomers + '/advancedSearch',
    method: 'POST',
    headers: HEADERS
  },
};

Api.ingredients = {
  delete: {
    url: (companyId, ingredientId) => Api.endpointCompanies + '/' + companyId + '/ingredients/' + ingredientId,
    method: 'DELETE',
    headers: HEADERS
  }
};

Api.business = {
  insert: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/business',
    method: 'POST',
    headers: HEADERS
  },
  update: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/business',
    method: 'PUT',
    headers: HEADERS
  },
  updateBasicBusinessProfile: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/updateBasicBusinessProfile',
    method: 'PUT',
    headers: HEADERS
  },
  updateBusinessHours: {
    url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/businessHours/' + id,
    method: 'PUT',
    headers: HEADERS
  },

  removeBusinessHours: {
    url: (companyId, id) => Api.endpointCompanies + '/' + companyId + '/businessHours/' + id,
    method: 'DELETE',
    headers: HEADERS
  },

  saveBusinessHours: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/businessHours',
    method: 'POST',
    headers: HEADERS
  }
};

Api.notification = {
  url: () => Api.endpointSMSnotification,
  method: 'POST',
  headers: HEADERS
};

Api.googleCalender = {
  url: (country) => Api.calender + country + '%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCd-XlL5mgW29M5ihIJ3cMXooHi6S3Qhkg',
  method: 'GET',
  headers: HEADERS
};

Api.order = {
  getAll: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/orders',
    method: 'GET',
    headers: HEADERS
  },

  modifyStatus: {
    url: (companyId, orderId) => Api.endpointCompanies + '/' + companyId + '/orders/' + orderId + '/status',
    method: 'PUT',
    headers: HEADERS
  },

  makeOrderPayment: {
    url: (companyId, orderId) => Api.endpointCompanies + '/' + companyId + '/orders/' + orderId + '/payment',
    method: 'POST',
    headers: HEADERS
  },

  advancedSearch: {
    url: () => Api.endpointOrders + '/advancedSearch',
    method: 'POST',
    headers: HEADERS
  },
  reconciledOrderPayment: {
    url: () => Api.endpointOrders + '/reconcileOrderPayment',
    method: 'PUT',
    headers: HEADERS
  }
};

Api.cashier = {
  advancedSearch: {
    url: () => Api.endpointCashiers + '/advancedSearch',
    method: 'POST',
    headers: HEADERS
  },
}

Api.files = {
  filetojson: {
    url: () => Api.endpointResources + '/services/filetojson',
    method: 'POST',
    headers: HEADERS
  },
  jsontofile: {
    url: () => Api.endpointResources + '/services/jsontofile',
    method: 'POST',
    headers: HEADERS
  },
  jsontofileInventory: {
    url: () => Api.endpointResources + '/services/jsontofileinventory',
    method: 'POST',
    headers: HEADERS
  }
};

Api.bulkUpload = {
  add: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/bulkData',
    method: 'POST',
    headers: HEADERS
  },
  export: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/exportData',
    method: 'POST',
    headers: HEADERS
  }
};

Api.invoices = {
  advancedSearch: {
    url: () => Api.endpointInvoices,
    method: 'POST',
    headers: HEADERS
  },
  generateForSubscritptions: {
    url: () => Api.endpointInvoices + '/generate',
    method: 'POST',
    headers: HEADERS
  },
  removeInvoice: {
    url: () => Api.endpointInvoices + '/delete',
    method: 'POST',
    headers: HEADERS
  },
  sendEmails: {
    url: () => Api.endpointInvoices + '/sendEmails',
    method: 'POST',
    headers: HEADERS
  },
  printInvoice: {
    url: () => Api.endpointInvoices + '/print',
    method: 'POST',
    headers: HEADERS
  }
};

Api.receipts = {
  advancedSearch: {
    url: () => Api.endpointOrders + '/advancedSearchOrderReceipts',
    method: 'POST',
    headers: HEADERS
  },
  settleInvoice: {
    url: () => Api.endpointReceipts + '/settlement',
    method: 'POST',
    headers: HEADERS
  },
  deleteReceipt: {
    url: () => Api.endpointReceipts + '/delete',
    method: 'POST',
    headers: HEADERS
  },
  fetchPrintReceipt: {
    url: () => Api.endpointReceipts + '/getPrintReceiptById',
    method: 'POST',
    headers: HEADERS
  }
};

Api.agents = {
  getAgentById: {
    url: () => Api.endpointAgents + '/byId',
    method: 'POST',
    headers: HEADERS
  }
};

Api.transferNote = {
  add: {
    url: (companyId) => Api.endpointCompanies + '/' + companyId + '/transferNotes',
    method: 'POST',
    headers: HEADERS
  },
  get: {
    url: (companyId, transferNoteId) => Api.endpointCompanies + '/' + companyId + '/transferNotes/' + transferNoteId,
    method: 'GET',
    headers: HEADERS
  },
  update: {
    url: (companyId, transferNoteId) => Api.endpointCompanies + '/' + companyId + '/transferNotes/' + transferNoteId,
    method: 'PUT',
    headers: HEADERS
  },
  confirmTransferNote: {
    url: (companyId, transferNoteId) => Api.endpointCompanies + '/' + companyId + '/transferNotes/' + transferNoteId + '/confirm',
    method: 'PUT',
    headers: HEADERS
  },
  verifyTransferNote: {
    url: (companyId, transferNoteId) => Api.endpointCompanies + '/' + companyId + '/transferNotes/' + transferNoteId + '/verify',
    method: 'PUT',
    headers: HEADERS
  },
  completeTransferNote: {
    url: (companyId, transferNoteId) => Api.endpointCompanies + '/' + companyId + '/transferNotes/' + transferNoteId + '/complete',
    method: 'PUT',
    headers: HEADERS
  },
  advancedSearch: {
    url: () => Api.endpointTransferNotes + '/advancedSearch',
    method: 'POST',
    headers: HEADERS
  },
  delete: {
    url: (companyId, transferNoteId) => Api.endpointCompanies + '/' + companyId + '/transferNotes/' + transferNoteId,
    method: 'DELETE',
    headers: HEADERS
  }
};

Api.generic = {
  list: {
    url: (tableName) => Api.endpointGeneric + '/list/' + tableName,
    method: 'POST',
    headers: HEADERS
  },
  procedure: {
    url: (procedureName) => Api.endpointGeneric + '/procedure/' + procedureName,
    method: 'POST',
    headers: HEADERS
  },

}

