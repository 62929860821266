import { combineReducers } from 'redux';
import moment from 'moment';


const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_PERSON_FEEDBACK': {
      const newState = { ...state };
      action.records && action.records.length > 0 && action.records.forEach(record => { newState[record.id] = record; });
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_PERSON_FEEDBACK': {
      const newState = [];
      action.records && action.records.forEach(record => {
        if (!newState.includes(record.id)) {
          newState.push(record.id);
        }
      });
      return newState;
    }

    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_PERSON_FEEDBACK': {
      const newState = { ...state };
      const key = Object.values(action.query).join('');
      const idsForFilter = [];
      action.records && action.records.forEach(record => {
        idsForFilter.push(record.id);
      });
      newState[key] = { ids: idsForFilter, totals: action.totals, grandTotal: Object.keys(action.grandTotal).length !== 0 ? action.grandTotal : undefined };
      return newState;
    }
    default:
      return state;
  }
};



const personFeedback = combineReducers({
  byId,
  ids,
  byFilter,
});

export default personFeedback;

export const getAllPersonFeedback = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getFilteredPersonFeedback = (state, filter) => {// this function is differ than order.getFilteredOrderItems
  const key = Object.values(filter).join('');
  const filterdIds = state.byFilter[key] ? state.byFilter[key].ids : [];
  return filterdIds.map(id => state.byId[id]).filter(record => !!record);
};

export const getFilteredPersonFeedbackTotalCount = (state, filter) => { // this function is differ than order.getFilteredOrderItems
  const key = Object.values(filter).join('');
  return state.byFilter[key] ? state.byFilter[key].grandTotal.count : 0;
};



