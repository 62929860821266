import React, { Component } from 'react';
import { Button, Row, Table, Tag } from 'antd';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
import './tableStyles.css';
import { Link } from 'react-router-dom';

class ReceiptsTable extends Component {
  state = {
    deleteResponse: undefined,
    modalVisible: false,
    receiptsDeleting: false
  }

  renderStatusColor = status => {
    switch (status) {
        case "DELETED":
            return '#ff0000';
        case 'PENDING':
            return '#fa8c16';
        case 'COMPLETED':
            return '#52c41a'
        case 'CONFIRMED': 
            return '#108EE9'
        default:
            return ''
    }
}
  getColumn = () => {
    const { business } = this.props;
    const { columnsEnabled = ['number', 'customerName', 'date', 'amount', 'paymentType', 'status'] } = this.props;
    const columns = [{
      title: 'Payment ID',
      dataIndex: 'number',
      key: 'number',
      width: '15%',
      render: text => <p>{text}</p>
    }, {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: text => <p>{text && moment(text).format('DD-MM-YYYY')}</p>
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      className: 'amountClass',
      width: '15%',
      render: text => <p>{format(text, currencyFormat(business))}</p>
    }, {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
      align: 'center',
      width: '20%',
      render: text => <p>{text}</p>
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '15%',
      render: text => <Tag   color={this.renderStatusColor(text)}>{text}</Tag>
    }];

    const actions = {
      title: 'Actions',
      dataIndex: 'number',
      key: 'number',
      align: 'right',
      width: '20%',
      render: (number, dataRow) => (
        <Row>
          {<Link to={'/printReceipt/' + dataRow.id + '/' + dataRow.customerId}>
            <Button className='tableButtonPrint' size='small' icon='printer' />
          </Link>}

          {/* {<Popconfirm
                        title="Are you sure ?"
                        okText="DELETE"
                        cancelText="NO"
                        onConfirm={() => onDeleteReceipts(dataRow.id, dataRow.customerId)}>
                        <Button className={'tableButton'} type={'danger'} size={'small'} icon="delete" />
                    </Popconfirm>} */}
        </Row>
      )
    };

    columns.push(actions);

    return columns.filter(column => columnsEnabled.includes(column.key));
  }

  redirectToPrint = receiptid => {
    this.props.history.push('/incytute/printReceipt/' + receiptid);
  }

  removeReceipt = receiptid => {
    console.log('removeReceipt');
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.props.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        const receiptIds = selectedRows.map(receipt => receipt.id) || [];
        this.props.onClick(receiptIds, selectedRowKeys, selectedRows);
      }
    };
  }

  getUserNameById = userId => {
    const { users = [] } = this.props;

    const user = users.find(user => user._id === userId);

    return user ? user.profile && user.profile.firstName + ' ' + user.profile.lastName : 'N/A';
  }

  getReceiptTotals = receipts => {
    let totalAmount = 0;

    receipts.forEach(receipt => {
      totalAmount = totalAmount + receipt.amount;
    });
    return { total: totalAmount, count: receipts.length };
  }

  generateTableFooter = (totals) => {
    const { business } = this.props;
    const element = document.getElementsByClassName('amountClass');
    const amountElement = element.item(0);
    const { total, count } = totals;
    return window.innerWidth > 865 ? <div>
      <p style={{ position: 'absolute', padding: 4 }}><b style={{ paddingLeft: 10 }}>Total of {count} Records</b></p>
      <p style={{ marginLeft: amountElement && amountElement.offsetLeft, width: amountElement && amountElement.offsetWidth, textAlign: 'right', padding: '5px 15px' }}>{format(total, currencyFormat(business))}</p>
    </div>
      : <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
        <p>No of Records : {count}</p>
        <p>Total Amount : <b>{format(total, currencyFormat(business))}</b></p>
      </div>;
  }

  handleOk = () => this.setState({ modalVisible: false, deleteResponse: undefined })

  render() {
    const { enableRowSelection, receipts = [], receiptsLoading } = this.props;

    const totals = this.getReceiptTotals(receipts);
    const columns = this.getColumn();

    return (
      <div>
        <Table
          columns={columns}
          dataSource={receipts}
          rowSelection={enableRowSelection && this.getRowSelection()}
          rowKey='id'
          loading={receiptsLoading}
          pagination
          footer={() => this.generateTableFooter(totals)}
        />
      </div>
    );
  }
}

export default ReceiptsTable;
