import { advancedSearch, getItemCategoriesOfInventoryItem, getItemBrandsOfInventoryItem, getItemGroupsOfInventoryItem, addInventoryItems, modifyInventoryItems, removeInventoryItems, importBulkInventoryItems, exportInventoryItems, exportInventoryItemsTemplate } from '../api/inventory';

export const fetchInventoryFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.inventoryItemList, totalResults: response.data.totalResults, query, QIHTotal: response.data.QIHTotal, ROQTotal: response.data.ROQTotal });
    }
    return response;
  });
};

//QIHTotal, ROQTotal, ROLTotal, RESQTotal, balanceTotal 

export const fetchItemCategoriesOfInventoryItem = (companyId, data) => dispatch => {
  return getItemCategoriesOfInventoryItem(companyId, data).then(response => {
    if (!response.error) {
      //dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.itemCategories});
    }
    return response;
  });
};

export const fetchItemBrandsOfInventoryItem = (companyId, data) => dispatch => {
  return getItemBrandsOfInventoryItem(companyId, data).then(response => {
    if (!response.error) {
      //dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.itemCategories});
    }
    return response;
  });
};

export const fetchItemGroupsOfInventoryItem = (companyId, data) => dispatch => {
  return getItemGroupsOfInventoryItem(companyId, data).then(response => {
    if (!response.error) {
      //dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.itemCategories});
    }
    return response;
  });
};

export const insertInventoryItems = (companyId, data) => dispatch => {
  return addInventoryItems(companyId, data).then(response => {
    if (!response.error) {
      //dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.itemCategories});
    }
    return response;
  });
};

export const updateInventoryItems = (companyId, data) => dispatch => {
  return modifyInventoryItems(companyId, data).then(response => {
    if (!response.error) {
      //dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.itemCategories});
    }
    return response;
  });
};

export const deleteInventoryItems = (companyId, data) => dispatch => {
  return removeInventoryItems(companyId, data).then(response => {
    if (!response.error) {
      //dispatch({ type: 'FETCH_INVENTORY_ITEMS', inventoryItems: response.data.itemCategories});
    }
    return response;
  });
};


export const uploadBulkInventoryItems = (companyId, data) => dispatch => {
  return importBulkInventoryItems(companyId, data).then(response => {
    return response;
  });
};

export const downloadInventoryItems = (companyId, data) => dispatch => {
  return exportInventoryItems(companyId, data).then(response => {
    return response;
  });
};

export const downloadInventoryItemsTemplate = (companyId) => dispatch => {
  return exportInventoryItemsTemplate(companyId).then(response => {
    return response;
  });
};





