import { Breadcrumb, Button, Card, Row, Modal, Col } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import SideBar from '../../components/SideBar';
import { connect } from 'react-redux';
import { getCompanyById, getAuthenticity, getLocationsByWarehouseId, getWarehouses, getSelectedBranchId } from '../../reducers';
import { fetchInventoryFromAdvancedSearch, insertInventoryItems, updateInventoryItems, deleteInventoryItems, uploadBulkInventoryItems } from '../../actions/Inventory';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import AdvancedSearchForm from './AdvancedSearchForm';
import { showLoadingMessage, hideMessage } from '../../components/Message';

import InventoryListContainer from './InventoryListContainer';
import DeletedInfo from '../AllItems/DeletedInfo';
import ItemEditModalForm from './components/ItemEditModalForm/ItemEditModalForm';
import { GiTrumpet } from 'react-icons/gi';
import { fileToJson } from '../../api/resources';
import ImportMenuModal from './components/ImportMenuModal';
const PAGE_SIZE = 15;
const today = moment();
const emptyQuery = {
  userId: undefined,
  text: undefined,
  warehouseIds: [],
  locationIds: [],
  branchIds: [],
  itemCategory: undefined,
  itemBrand: undefined,
  itemGroup: undefined,
  itemName: undefined,
  itemCode: undefined,
  itemDescription: undefined,

  status: undefined,
  page: 0,
  pageSize: 0,
  toBeOrderedImmediately: false,
  orderNow: false,

  figuresFilter: {
    QIHRule: undefined,
    QIHValue: 0,
    ROQRule: undefined,
    ROQValue: 0,
    balanceRule: undefined,
    balanceValue: 0
  }

}
const defaultQuery = {
  ...emptyQuery,
  toBeOrderedImmediately: false,
  orderNow: false
}

class AllInventoryContainer extends Component {
  constructor() {
    super();
    this.state = {
      query: {
        ...defaultQuery
      },
      confirmedQuery: {
        ...defaultQuery
      },
      selectedRowKeys: [],
      inventoryLoading: false,
      selectedRows: [],
      deleteResponse: undefined,
      modalVisible: false,
      visibleItemList: false,
      selectedItemIds: [],
      selectedInventoryItemIds: [],
      selectedRowKeysInventoryItems: [],
      showDeleteInfoModal: false,
      deletedInfo: undefined,
      selectedInventoryItem: undefined,
      visibleMenuImport: false
    };
  }

  componentDidMount = () => {
    const { confirmedQuery } = this.state;
    const { selectedBranchId, companyId } = this.props;
    console.log(companyId)
    this.setState({ query: { ...confirmedQuery, pageSize: PAGE_SIZE, page: 1, userId: companyId, branchIds: [selectedBranchId] } }, () => {
      const { query } = this.state;
      this.onClickSearch(query);
    });
  }

  componentWillReceiveProps = (nextProps) => {
    const { confirmedQuery, query } = this.state;
    const { companyId } = nextProps;

    if (JSON.stringify(nextProps.selectedBranchId) !== JSON.stringify(this.props.selectedBranchId)) {
      this.setState({ query: { ...query, warehouseIds: [], locationIds: [], itemCategory: undefined, itemBrand: [], itemGroup: [] } })
    }
  }

  handleInputCheckChange = (e, type) => {
    let queryData = { ...this.state.query }
    switch (type) {
      case 'toBeOrder':
        queryData = { ...this.state.query, toBeOrderedImmediately: e.target.checked }
        this.setState({ query: queryData })
        break;

      case 'orderNow':
        queryData = { ...this.state.query, orderNow: e.target.checked }
        this.setState({ query: queryData })
        break;
    }
  }

  handleInputTextChange = (value, type) => {
    let queryData = { ...this.state.query }
    let figuresFilter = { ...this.state.query.figuresFilter }
    console.log(value)
    switch (type) {
      case 'branch':
        let branchIds = [value === 0 ? "0": value];
        queryData = { ...this.state.query, branchIds: branchIds }
        this.setState({ query: queryData })
        break;

      case 'warehouse':
        let warehouseIds = [value];

        queryData = { ...this.state.query, warehouseIds: warehouseIds }
        this.setState({ query: queryData })
        break;

      case 'location':
        let locationIds = [value];

        queryData = { ...this.state.query, locationIds: locationIds }
        this.setState({ query: queryData })
        break;


      case 'itemDescription':
        queryData = { ...this.state.query, itemDescription: value }
        this.setState({ query: queryData })
        break;

      case 'itemCode':
        queryData = { ...this.state.query, itemCode: value }
        this.setState({ query: queryData })
        break;

      case 'itemCategory':
        queryData = { ...this.state.query, itemCategory: value }
        this.setState({ query: queryData })
        break;

      case 'itemBrand':
        queryData = { ...this.state.query, itemBrand: value }
        this.setState({ query: queryData })
        break;

      case 'itemGroup':
        queryData = { ...this.state.query, itemGroup: value }
        this.setState({ query: queryData })
        break;

      case 'QIHRule':
        figuresFilter = { ...this.state.query.figuresFilter, QIHRule: value }
        queryData = { ...this.state.query, figuresFilter }
        this.setState({ query: queryData })
        break;

      case 'QIHValue':
        figuresFilter = { ...this.state.query.figuresFilter, QIHValue: value }
        queryData = { ...this.state.query, figuresFilter }
        this.setState({ query: queryData })
        break;

      case 'ROQRule':
        figuresFilter = { ...this.state.query.figuresFilter, ROQRule: value }
        queryData = { ...this.state.query, figuresFilter }
        this.setState({ query: queryData })
        break;

      case 'ROQValue':
        figuresFilter = { ...this.state.query.figuresFilter, ROQValue: value }
        queryData = { ...this.state.query, figuresFilter }
        this.setState({ query: queryData })
        break;

      case 'balanceRule':
        figuresFilter = { ...this.state.query.figuresFilter, balanceRule: value }
        queryData = { ...this.state.query, figuresFilter }
        this.setState({ query: queryData })
        break;

      case 'balanceValue':
        figuresFilter = { ...this.state.query.figuresFilter, balanceValue: value }
        queryData = { ...this.state.query, figuresFilter }
        this.setState({ query: queryData })
        break;
    }
  }

  handleStatusChange = (value) => {
    let queryData = { ...this.state.query, status: value }
    this.setState({ query: queryData })
  }

  onChangeExtendedFilter = (value) => {
    let queryData = { ...this.state.query, extended: value }
    this.setState({ query: queryData })
  }

  handleAlwaysShowExpiredChange = checked => this.setState({ query: { ...this.state.query, alwaysShowExpired: checked } });

  handleOnClickBulkDelete = () => {
    console.log("handleOnClickBulkDelete")
  }

  handleClickDeleteInvoice = invoiceId => {
    console.log("handleClickDeleteInvoice")
  }

  handlePaginationChange = (page, pageSize) => {
    this.setState({ query: { ...this.state.confirmedQuery, pageSize: PAGE_SIZE, page: page } }, () => {
      const { query } = this.state;
      this.onClickSearch(query);
    });

  }

  onClickAddItem = () => {
    console.log("sss")
    this.setState({ visibleItemList: true })
  }


  onClickSearch = () => {
    this.setState({ confirmedQuery: this.state.query, selectedRowKeys: [] })
    const { fetchInventoryFromAdvancedSearch } = this.props;


    this.setState({ loading: true, selectedRowKeysInventoryItems: [] });
    if (!hideMessage) showLoadingMessage();
    fetchInventoryFromAdvancedSearch({ ...this.state.query, page: 1 }).then(response => {
      if (hideMessage) hideMessage();
      this.setState({ loading: false });
    });
  }


  handleCloseItemList = () => this.setState({ visibleItemList: false });

  handleClickAdd = () => {
    const { query, selectedRowKeys } = this.state;
    const { selectedBranchId, companyId } = this.props;

    console.log(query)

    this.setState({ visibleItemList: false });
    if (!selectedBranchId || query.warehouseIds.length === 0 || query.locationIds.length === 0) return;
    const data = { branchId: selectedBranchId, warehouseId: query.warehouseIds[0], locationId: query.locationIds[0], itemIds: selectedRowKeys }
    this.props.insertInventoryItems(companyId, data).then(response => {
      const { error } = response;
      this.setState({ loading: false })
      if (!error) {
        this.onClickSearch();
        showSuccessNotification('Items created susccessfully.', '');
      } else {
        showErrorNotification('An error occurred while creating items.', '');
      }
    });
  }

  handleClickItemDelete = () => {
    const { selectedRowKeysInventoryItems, query } = this.state;
    const { companyId } = this.props;
    const data = {
      inventoryItemIds: selectedRowKeysInventoryItems
    }
    this.props.deleteInventoryItems(companyId, data).then(response => {
      const { error, records } = response;
      this.setState({ loading: false })
      if (!error) {
        this.onClickSearch(query);
        showSuccessNotification('Items deleted susccessfully.', '');
        this.setState({ deletedInfo: records });
        this.showDeleteInfoModal();
      } else {
        showErrorNotification('An error occurred while deleting items.', '');
      }
    });
  }

  hideDeleteInfoModal = () => {
    this.setState({ showDeleteInfoModal: false });
  }

  showDeleteInfoModal = () => {
    this.setState({ showDeleteInfoModal: true });
  }

  handleItemSearch = value => this.setState({ itemFilterText: value })

  handleSelectItem = (itemIds, selectedRowKeys, selectedRows) => {
    console.log("selectedRows", selectedRows, itemIds, selectedRowKeys)
    this.setState({ selectedItemIds: itemIds, selectedRowKeys, selectedRows })
  }

  handleSelectInventoryItem = (inventoryItemIds, selectedRowKeys, selectedRows) => {
    console.log("selectedRows inventoryItems", selectedRows, inventoryItemIds, selectedRowKeys)
    this.setState({ selectedInventoryItemIds: inventoryItemIds, selectedRowKeysInventoryItems: selectedRowKeys, selectedRows })
  }

  onCancelEditItemModal = () => this.setState({ selectedInventoryItem: undefined, visibleEditItemModal: false })

  handleEditInventoryItem = (record) => {
    this.setState({ selectedInventoryItem: record, visibleEditItemModal: true })
  }

  onSaveInventoryItemEditModal = () => {
    const { companyId } = this.props;
    var updatedItem = this.state.selectedInventoryItem;
    const data = { updatedItems: [updatedItem] }
    this.props.updateInventoryItems(companyId, data).then(response => {
      const { error } = response;
      this.setState({ loading: false })
      if (!error) {
        this.setState({ visibleEditItemModal: false })
        this.onClickSearch();
        showSuccessNotification('Item updated susccessfully.', '');
      } else {
        showErrorNotification('An error occurred while creating items.', '');
      }
    });
  }

  handleOnChangeModal = (field, value) => {
    const { company } = this.props;
    const { selectedInventoryItem } = this.state

    switch (field) {
      case 'QIH':
        this.setState({ selectedInventoryItem: { ...selectedInventoryItem, quantity: value } });
        break
      case 'ROL':
        this.setState({ selectedInventoryItem: { ...selectedInventoryItem, reorderLevel: value } });
        break
      case 'ROQ':
        this.setState({ selectedInventoryItem: { ...selectedInventoryItem, reorderQuantity: value } });
        break
      case 'RESQ':
        this.setState({ selectedInventoryItem: { ...selectedInventoryItem, reservedQuantity: value } });
        break

      default:
    }
  }

  onClickImportGRN = () => {
    this.setState({ visibleMenuImport: true })
  }

  onCancelMenuImportModal = () => this.setState({ visibleMenuImport: false })

  handleFileChosen = file => {
    const { user: { userId: companyId } } = this.props.authData;
    const fileName = 'temp_' + new Date().getTime() + '_' + companyId;
    if (file.name.includes('.csv')) {
      this.setState({ loading: true, visibleMenuImport: false });
      fileToJson(file, 'temp/', fileName).then((response) => {
        if (response.error) {
          this.setState({ loading: false });
          showErrorNotification('error', 'Import Failed. ' + response.error);
        } else {
          const { responseData } = response;
          this.props.uploadBulkInventoryItems(companyId, { data: responseData }).then(response => {
            const { error } = response;
            if (error) {
              showErrorNotification('error', 'Import Failed. ' + error);
              this.setState({ loading: false });
            } else {
              this.setState({ loading: false });
              showSuccessNotification('success', 'Items imported successfully');
              this.onClickSearch();
            }
          });
        }
      }).catch(error => {
        showErrorNotification('error', 'Upload failed: ' + error);
        this.setState({ submitting: false });
      });
    } else {
      showErrorNotification('Error', 'Unsupported file format...');
    }
  }

  handleFileChosenRemove = () => {
    this.setState({ resources: [], invalidResources: [], readPercentage: undefined });
  }


  render() {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { query, deletedInfo, confirmedQuery, selectedRowKeys, selectedRows, onlyOutstanding, invoicesLoading, alreadyInvoiced, visibleItemList, selectedRowKeysInventoryItems, visibleEditItemModal, selectedInventoryItem } = this.state;
    return (
      <div className='container'>
        {business && !business.active && <p style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, color: 'red' }}>This business is no longer active.</p>}

        <div gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Col lg={5} md={5} sm={24} xs={24}>
              <h3 style={{ fontWeight: 400, fontSize: 24 }}>Inventory</h3>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Card className='card-styles'>
                <AdvancedSearchForm
                  onInputTextChange={this.handleInputTextChange}
                  onInputCheckChange={this.handleInputCheckChange}
                  onClickSearch={this.onClickSearch}
                  onClickAddItem={this.onClickAddItem}
                  onClickImportGRN={this.onClickImportGRN}
                  company={company}
                  companyId={companyId}
                  query={query} />

              </Card>
              <Row>
                {this.state.showDeleteInfoModal && <DeletedInfo records={deletedInfo} onCancel={this.hideDeleteInfoModal} visible={this.state.showDeleteInfoModal} />}
                <ItemEditModalForm visible={visibleEditItemModal} onCancel={this.onCancelEditItemModal} inventoryItem={selectedInventoryItem} onCreate={this.onSaveInventoryItemEditModal} handleOnChange={this.handleOnChangeModal} />
                <ImportMenuModal visible={this.state.visibleMenuImport} onCancel={this.onCancelMenuImportModal} handleFileChosen={this.handleFileChosen} handleFileChosenRemove={this.handleFileChosenRemove} companyId={companyId} company={company} />

                <Col lg={24} md={24} sm={24} xs={24}>
                  <InventoryListContainer
                    selectedRows={selectedRows}
                    selectedRowKeys={selectedRowKeys}
                    selectedRowKeysInventoryItems={selectedRowKeysInventoryItems}
                    showOutstandingInvoices={onlyOutstanding}
                    handleSelectInventoryItem={this.handleSelectInventoryItem}
                    handleOnClickBulkDelete={this.handleOnClickBulkDelete}
                    handleOnClickDelete={this.handleClickDeleteInvoice}
                    invoicesLoading={invoicesLoading}
                    showTotals={true}
                    query={confirmedQuery}
                    enableRowSelection={true}
                    onGenerateInvoiceClick={this.handleGenerateInvoiceClick}
                    handlePaginationChange={this.handlePaginationChange}
                    PAGE_SIZE={PAGE_SIZE}
                    onCloseItemList={this.handleCloseItemList}
                    onItemSearch={this.handleItemSearch}
                    onSelectItem={this.handleSelectItem}
                    visibleItemList={visibleItemList}
                    onClickAdd={this.handleClickAdd}
                    onClickItemDelete={this.handleClickItemDelete}
                    onEditInventoryItem={this.handleEditInventoryItem}
                  />
                </Col>
              </Row>

            </Col>
          </Col>
        </div>
      </div>


    )
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);

  const { user: { userId: companyId } } = authData;
  console.log(companyId)
  return ({
    companyId: companyId,
    company: getCompanyById(state, companyId),
    authData: getAuthenticity(state),
    warehouses: getWarehouses(state),
    selectedBranchId: getSelectedBranchId(state) === 0 ? "0" : getSelectedBranchId(state)
  });
};

export default connect(mapStateToProps, { fetchInventoryFromAdvancedSearch, insertInventoryItems, updateInventoryItems, deleteInventoryItems, uploadBulkInventoryItems })(AllInventoryContainer);