import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_DEVICES': {
      const newState = { ...state };
      action.devices.forEach(device => { newState[device.deviceId] = device; });
      return newState;
    }
    case 'FETCH_DEVICE': {
      const { device } = action;
      return { ...state, [device.deviceId]: device };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_DEVICES':
      const newState = [...state];
      action.devices.forEach(device => {
        if (!newState.includes(device.deviceId)) {
          return newState.push(device.deviceId);
        }
      });
      return newState;
    case 'FETCH_DEVICE':
      if (state.includes(action.device.deviceId)) {
        return state;
      } else {
        return [...state, action.device.deviceId];
      }
    default:
      return state;
  }
};

const byCompanyId = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_DEVICES': {
      const newState = { ...state };
      newState[action.companyId] = action.devices.map(device => device.deviceId);
      return newState;
    }
    case 'FETCH_DEVICE': {
      const newState = { ...state };
      const companyDeviceIds = newState[action.companyId] || [];
      if (companyDeviceIds.includes(action.device.deviceId)) {
        return newState;
      } else {
        newState[action.companyId] = [...companyDeviceIds, action.device.deviceId];
        return newState;
      }
    }
    default:
      return state;
  }
};

const devices = combineReducers({
  byId,
  ids,
  byCompanyId
});

export default devices;

export const getDevices = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getDevicesByCompanyId = (state, companyId) => {
  const deviceIds = state.byCompanyId[companyId];
  return deviceIds && deviceIds.length > 0 ? deviceIds.map(id => state.byId[id]) : [];
};
