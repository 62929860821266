import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Button } from 'antd';
import InvoiceList from './InvoiceList';

import { getInvoices, getInvoicesForCustomer } from '../../../reducers';
import moment from 'moment';

class InvoiceListContainer extends Component {
  filteredInvoices = () => {
    const { query = {}, invoices } = this.props;
    const { from, to, number, customerName, customerAddress, onlyOutstanding } = query;

    let filteredInvoices = invoices && invoices.length > 0 && invoices.filter(invoice => {
      return (this.isIdMatch(invoice.number, number) &&
        this.isTextMatch(invoice.customerName, customerName) &&
        this.isTextMatch(invoice.customerAddress, customerAddress) &&
        ((from ? moment(invoice.date).isAfter(moment(from)) : true) && (to ? moment(invoice.date).isBefore(moment(to)) : true)) &&
        invoice.status !== 'DELETED') &&
        (!query.status || query.status.length === 0 || query.status.includes(invoice.status.toUpperCase()));
    });

    if (onlyOutstanding && filteredInvoices) filteredInvoices = filteredInvoices.filter(invoice => invoice.balanceToSettle > 0);

    return filteredInvoices;
  }

  isIdMatch = (match, searchValue) => {
    if (!searchValue) return true;

    return match === searchValue;
  }

  isTextMatch = (match, searchValue) => {
    if (!searchValue) return true;

    return match.toLowerCase().includes(searchValue.toLowerCase());
  }

  render() {
    const {
      customerId,
      selectedRowKeys,
      invoicesLoading = false,
      handleOnClickSelectInvoices,
      showTotals = false,
      showOutstandingInvoices = false,
      enableRowSelection = false,
      handleOnClickDelete,
      enableBulkAction = false,
      enableActions = false,
      emailSending,
      handleOnClickSendEmails,
      handleOnClickSendEmail,
      fixOutstanding,
      fixAmount,
      business
    } = this.props;

    return (
      <div style={{ margin: 5 }}>
        {
          (enableBulkAction && selectedRowKeys && selectedRowKeys.length > 0) &&
          <Row style={{ padding: 10, textAlign: 'end', backgroundColor: '#fafafa' }}>
            <Button loading={emailSending} className='tableButtonSpecial' size='small' onClick={() => handleOnClickSendEmails()}>Send Emails</Button>
            {/* <Button disabled className={'tableButtonSpecial'} size={'small'}>Download as Excel sheet</Button>
						<Button disabled className={'tableButtonPrint'} size={'small'} icon={'printer'} />
						<Popconfirm
							title="Sure to delete!"
							okText="DELETE"
							cancelText="NO"
						>
							<Button className={'tableButton'} type={'danger'} size={'small'} icon="delete" />
						</Popconfirm> */}
          </Row>
        }
        <InvoiceList
          business={business}
          showOutstandingInvoices={showOutstandingInvoices}
          selectedRowKeys={selectedRowKeys}
          customerId={customerId}
          enableRowSelection={enableRowSelection}
          invoicesLoading={invoicesLoading}
          onClick={handleOnClickSelectInvoices}
          onClickDelete={handleOnClickDelete}
          showTotals={showTotals}
          invoices={this.filteredInvoices()}
          enableActions={enableActions}
          handleOnClickSendEmail={handleOnClickSendEmail}
          emailSending={emailSending}
          fixAmount={fixAmount}
          fixOutstanding={fixOutstanding}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps;

  return { invoices: customerId ? getInvoicesForCustomer(state, customerId) : getInvoices(state) };
};

export default connect(mapStateToProps, null)(InvoiceListContainer);
