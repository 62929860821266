import React, { Component } from 'react';
import { Modal, Form, Input } from 'antd';

class IngredientModalForm extends Component {
  state = {
    uomStatus: '',
    quantityStatus: ''
  }

  render () {
    const { visible, handleOnChange, item = {}, quantity, uom } = this.props;
    const { quantityStatus, uomStatus } = this.state;

    return (
      <Modal
        visible={visible}
        title='Add/Update New Ingredient'
        okText='Save'
        onCancel={this.cancel}
        onOk={this.ok}
      >

        <Form layout='vertical'>
          <Form.Item label='Item'>
            <p style={{ fontSize: 20 }}><b>{item && item.name}</b></p>
          </Form.Item>
          <Form.Item label='Quantiy' validateStatus={quantityStatus}>
            <Input type='number' value={quantity} onChange={event => handleOnChange('quantity', event.target.value)} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) && e.preventDefault()} />
          </Form.Item>
          <Form.Item label='UOM' validateStatus={uomStatus}>
            <Input value={uom} onChange={event => handleOnChange('uom', event.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  ok = () => {
    const { index } = this.props;
    if (this.validateNewIngredientForm()) {
      this.props.onCreate(index);
    } else {
      this.setState({
        quantityStatus: 'error'
      });
    }
  }

    cancel = () => {
      this.setState({ uomStatus: '', quantityStatus: '' });
      this.props.onCancel();
    }

    validateNewIngredientForm = () => {
      const { quantity, uom } = this.props;
      const validForm = this.isValidQuantity(quantity) &&
        this.isValidUom(uom);

      if (!validForm) {
        this.setState({
          quantityStatus: this.getValidationStatus('quantity', quantity),
          uomStatus: this.getValidationStatus('uom', uom)
        });
      }
      return validForm;
    }

    getValidationStatus = (name, value) => {
      switch (name) {
        case 'quantity':
          return this.isValidQuantity(value) ? 'success' : 'error';
        case 'uom':
          return this.isValidUom(value) ? 'success' : 'error';
        default:
          return null;
      }
    }

    isValidQuantity = quantity => quantity && quantity > 0;
    isValidUom = uom => uom && uom.length > 0;
}

export default IngredientModalForm;
