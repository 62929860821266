import { combineReducers } from 'redux';
import moment from 'moment';

export const defaultFilter = {
  text: undefined,
  userId: undefined,
  orderId: undefined,
  fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
  toDate: moment(new Date()).endOf('day'),
  page: 1,
  pageSize: 20,
  status: [],
  method: ''
};


const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ORDER_ITEMS': {
      const newState = { ...state };
      action.orderItems && action.orderItems.length > 0 && action.orderItems.forEach(orderItem => { newState[orderItem.id] = orderItem; });
      return newState;
    }
    case 'FETCH_ORDER_ITEM': {
      return { ...state, [action.orderItem.id]: action.orderItem };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ORDER_ITEMS': {
      const newState = [];
      action.orderItems && action.orderItems.forEach(orderItem => {
        if (!newState.includes(orderItem.id)) {
          newState.push(orderItem.id);
        }
      });
      return newState;
    }
    case 'FETCH_ORDER_ITEM': {
      if (state.includes(action.orderItem.id)) {
        return state;
      } else {
        return [...state, action.orderItem.id];
      }
    }
    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ORDER_ITEMS': {
      const newState = { ...state };
      const key = getActiveFilterJoinedKey(action.query);
      const idsForFilter = [];
      action.orderItems && action.orderItems.forEach(orderItem => {
        idsForFilter.push(orderItem.id);
      });
      newState[key] = { ids: idsForFilter, totals: action.totals, grandTotal: Object.keys(action.grandTotal).length !== 0 ? action.grandTotal : undefined };
      return newState;
    }
    default:
      return state;
  }
};

export const activeFilter = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_ORDER_ITEMS':
      return {
        ...state,
        [action.filterType]: action.filter
      };

    case 'UPDATE_ACTIVE_FILTER_ORDER_ITEMS':
      return {
        ...action.filter
      };

    default:
      return state;
  }
};


const orderItems = combineReducers({
  byId,
  ids,
  byFilter,
  activeFilter
});

export default orderItems;

export const getOrderItems = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getActiveFilter = state => {
  return state.activeFilter;
};


export const getFilteredOrderItems = (state, filter) => {// this function is differ than order.getFilteredOrderItems
  const key = Object.values(filter).join('');
  const filterdOrderItemIds = state.byFilter[key] ? state.byFilter[key].ids : [];
  const orderItemsWithoutModifiers = filterdOrderItemIds.map(id => state.byId[id]).filter(orderItem => !!orderItem && orderItem.parent_id === null);
  const orderItems = filterdOrderItemIds.map(id => state.byId[id]).filter(orderItem => !!orderItem && orderItem.parent_id !== null);

  const groups = orderItems.reduce((groups, orderItem) => ({
    ...groups,
    [orderItem.parent_id]: [...(groups[orderItem.parent_id] || []), orderItem]
  }), {});

  const finalList = [];
  for (var i = 0; i < orderItemsWithoutModifiers.length; i++) {
    const modifierList = groups[orderItemsWithoutModifiers[i].id] || [];
    const updatedModifiersList = [];
    for (var x = 0; x < modifierList.length; x++) {
      const optionModifierList = groups[modifierList[x].id];
      const modifier = { ...modifierList[x], modifiers: optionModifierList ? optionModifierList : [] }
      updatedModifiersList.push(modifier);
    }
    const orderItemInner = { ...orderItemsWithoutModifiers[i], modifiers: updatedModifiersList ? updatedModifiersList : [] }
    finalList.push(orderItemInner);
  }

  return finalList;
};

const getActiveFilterJoinedKey = (filter) => {
  const _filter = { ...filter };
  const fromDate = filter.fromDate;
  const toDate = filter.toDate;
  if (filter.fromDate && filter.toDate) {
    _filter.fromDate = moment(fromDate).format('DD/MM/YYYY');
    _filter.toDate = moment(toDate).format('DD/MM/YYYY');
  }

  return Object.values(_filter).join('');
}

export const getFilteredOrderItemsByActiveFilter = (state) => {
  const key = getActiveFilterJoinedKey(getActiveFilter(state));
  const filterdOrderItemIds = state.byFilter[key] ? state.byFilter[key].ids : [];
  const orderItemsWithoutModifiers = filterdOrderItemIds.map(id => state.byId[id]).filter(orderItem => !!orderItem && orderItem.parent_id === null);
  const orderItems = filterdOrderItemIds.map(id => state.byId[id]).filter(orderItem => !!orderItem && orderItem.parent_id !== null);

  const groups = orderItems.reduce((groups, orderItem) => ({
    ...groups,
    [orderItem.parent_id]: [...(groups[orderItem.parent_id] || []), orderItem]
  }), {});

  const finalList = [];
  for (var i = 0; i < orderItemsWithoutModifiers.length; i++) {
    const modifierList = groups[orderItemsWithoutModifiers[i].id] || [];
    const updatedModifiersList = [];
    for (var x = 0; x < modifierList.length; x++) {
      const optionModifierList = groups[modifierList[x].id];
      const modifier = { ...modifierList[x], modifiers: optionModifierList ? optionModifierList : [] }
      updatedModifiersList.push(modifier);
    }
    const orderItemInner = { ...orderItemsWithoutModifiers[i], modifiers: updatedModifiersList ? updatedModifiersList : [] }
    finalList.push(orderItemInner);
  }

  return finalList;
};

export const getFilteredOrderItemTotalCount = (state, filter) => { // this function is differ than order.getFilteredOrderItems
  const key = getActiveFilterJoinedKey(filter);;
  return state.byFilter[key] ? state.byFilter[key].grandTotal.count : 0;
};


