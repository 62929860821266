import React, { Component } from 'react';
import { Button, Card, Col, Row } from 'antd';
import SideBar from '../../../components/SideBar';
import DeviceList from './DeviceList';
import Input from 'antd/es/input';

class Devices extends Component {
  state = {
    textFilter: ''
  }

  render () {
    const { textFilter } = this.state;
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Row>
              <Card
                title={<h4 style={{ fontWeight: 300 }}>Devices</h4>}
                style={{ margin: 5 }}
                extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
              >
                <Row gutter={10}>
                  <Col lg={20}>
                    <Input style={{ marginBottom: 10 }} value={textFilter} onChange={(event) => this.setState({ textFilter: event.target.value })} />
                  </Col>
                  <Col lg={4}>
                    <Button className='primary-button' type='primary' block icon='search'>Search</Button>
                  </Col>
                </Row>
              </Card>
              <Card
                style={{ margin: 5 }}
              >
                <DeviceList textFilter={textFilter} />
              </Card>
            </Row>

          </Col>
        </Row>
      </div>
    );
  }
}
export default Devices;
