import React, { Component } from 'react';
import { Modal, Form, Col, Row } from 'antd';

class DeletedInfo extends Component {
  renderFaiedItems = () => {
    const { records } = this.props;
    return (
      records.failedItem && records.failedItem.length > 0 && records.failedItem.map((item) => {
        return <Form.Item>
          <Row className='category-item-row' style={{ marginLeft: 5, marginRight: 5, marginTop: -10, width: '98.8%' }}>
            <Col lg={8}>
              <p>{item.name}</p>
            </Col>
            <Col lg={8}>
              <p>{item.reason}</p>
            </Col>
          </Row>
        </Form.Item>;
      })
    );
  }

  render () {
    const { onCancel, records, visible } = this.props;

    return (
      <Modal
        visible={visible}
        title=''
        // okText='Save'
        onCancel={onCancel}
        onOk={onCancel}
      >
        <div>
          <p><b> {records.successCount} deleted successfully</b> </p>
          <p><b>  {records.failedCount} failed to delete </b> </p>
          <p><b>Failed Reasons</b> </p>
          {this.renderFaiedItems()}
        </div>

      </Modal>
    );
  }
}

export default DeletedInfo;
