import React, { Component } from 'react';
import { Avatar, Col, Row, Tag } from 'antd';
import { FaTrash } from 'react-icons/all';

class Item extends Component {
  getItemPrice = itemPricesMap => {
    // console.log(itemPricesMap)
    const { selectedPriceList } = this.props;
    const itemPrices = Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let price = 0;

    if (selectedPriceList && selectedPriceList.id) {
      itemPrices.forEach(itemPrice => {
        if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) price = itemPrice.price;
      });
    }

    // console.log(price)
    return price;
  }

  render () {
    const { item, onRemoveItemFromCategory } = this.props;
    return (
      <Row className='modifier-item-container' style={{ marginTop: -10 }}>
        <Col span={2}>
          <Avatar size={35} src={item.imageSrc} />
        </Col>
        <Col span={8}>
          {item.name ? item.name : ''}
        </Col>
        <Col span={4} className='text-right'>
          {this.getItemPrice(item.itemPrices)}
        </Col>
        <Col span={4} className='text-center'>
          {item.availableQty2 ? item.availableQty2 : 0}
        </Col>
        <Col span={4} className='text-center'>
          <Tag>{item.displayInOnlineMenu2 ? 'Yes' : 'No'}</Tag>
        </Col>
        <Col span={2} className='text-right'>
          <FaTrash style={{ color: 'red' }} className='incy-button' onClick={() => onRemoveItemFromCategory(item.id)} />
        </Col>
      </Row>
    );
  }
}

export default Item;
