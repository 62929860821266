import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button } from 'antd';
import { showErrorNotification, showSuccessNotification } from '../components/Notification';
import { authenticate } from '../actions/auth';
import packageJson from '../../package.json';
import incyposLogo from '../image/incypos-logo.png';
import fooderLogo from '../image/fooder-logo.png';
import bartekaLogo from '../image/barteka-logo.png';
const sha256 = require('js-sha256');


class SignIn extends Component {
  defaultState = {
    username: undefined,
    password: undefined,
    usernameStatus: undefined,
    passwordStatus: undefined,
    failedStatus: undefined,
    loggingIn: false,
    submitting: false,
    resetPassword: false
  }

  state = {
    redirectToReferrer: false,
    ...this.defaultState
  };

  componentDidMount () {
    if (window.location.hostname === 'mydev.fooder.lk') {
      document.getElementById('login-screen-logo').src = fooderLogo;
    } else if (window.location.hostname === 'mydev.incypos.com') {
      document.getElementById('login-screen-logo').src = incyposLogo;
    } else if (window.location.hostname === 'mydevbarteka.inypos.com' || window.location.hostname === 'mysimplykiosk.incylabs.com') {
      document.getElementById('login-screen-logo').src = bartekaLogo;
    } else {
      document.getElementById('login-screen-logo').src = incyposLogo;
    }
  }

  handleLogin = e => {
    e.preventDefault();
    const { form, authenticate } = this.props;
    const { resetPassword } = this.state;

    form.validateFields((err, values) => {
      const formatedValues = {
        username : values.username,
        password : sha256(values.password)
        // password : values.password
      }
      if (!err) {
        this.setState({ submitting: true });

        if (!resetPassword) {
          authenticate(formatedValues).then(response => {
            const { authenticity, error } = response;
            if (authenticity) {
              this.setState({ failedStatus: 'success', submitting: false });
              showSuccessNotification('Welcome', '');
              this.props.history.replace('/dashboard');
            } else {
              this.setState({ failedStatus: 'failed', submitting: false });
              showErrorNotification('Login Failed.', error);
            }
          });
        } else {
          // callResetPassword(values).then(response => {
          //   const newState = { submitting: false };

          //   if (!response.error) {
          //     newState.resetPassword = false;
          //     toastManager.add('Password reset successfully. Please login using your new password.', { autoDismiss: true, appearance: 'success' });
          //   } else {
          //     toastManager.add('' + response.error, { autoDismiss: true, appearance: 'error' });
          //   }

          //   this.setState(newState);
          // })
        }
      }
    });
  };

  handleChange = (field, value) => {
    switch (field) {
      case 'username':
        this.setState({ username: value, usernameStatus: this.getValidationStatus(field, value) });
        break;
      case 'password':
        this.setState({ password: value, passwordStatus: this.getValidationStatus(field, value) });
        break;
      default:
    }
  }

  getValidationStatus = (field, value) => {
    switch (field) {
      case 'username':
        return this.isValidUsername(value) ? 'success' : 'error';
      case 'password':
        return this.isValidPassword(value) ? 'success' : 'error';
      default:
        return 'No Change';
    }
  }

  validateForm = () => {
    const { username, password } = this.state;
    const validForm = this.isValidUsername(username) && this.isValidPassword(password);
    if (!validForm) {
      this.setState({
        usernameStatus: this.getValidationStatus('username', username),
        passwordStatus: this.getValidationStatus('password', password)
      });
    }
    return validForm;
  }

  isValidUsername = value => value && value.length > 0;
  isValidPassword = value => value && value.length > 0;

  render () {
    const { submitting } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={styles.loginPage}>
        <Form onSubmit={this.handleLogin} style={styles.loginForm}>
          <Form.Item className='text-center'>
            <img alt='IncyPOS Logo' id='login-screen-logo' style={{ width: 300, padding: 20 }} />
            <h3>Control Panel</h3>
            <div style={styles.loginFormVersion}>v{packageJson.version}</div>
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: ' ' }]
            })(
              <Input
                prefix={<Icon type='user' style={styles.textInput} />}
                placeholder='Username'
              />
            )}
          </Form.Item>

          {/* {resetPassword && <Form.Item>
            {getFieldDecorator('passcode', {
              rules: [{ required: true, message: 'Please input your passcode!' }],
            })(
              <Input
                prefix={<Icon type="lock" style={styles.textInput} />}
                type="passcode"
                placeholder="Passcode"
              />,
            )}
          </Form.Item>} */}

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: ' ' }]
            })(
              <Input
                prefix={<Icon type='lock' style={styles.textInput} />}
                type='password'
                placeholder='Password'
              />
            )}
          </Form.Item>

          {/* {resetPassword && <Form.Item>
            {getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: 'Please input your password again!' },
                { validator: this.compareToPassword },
              ],
            })(
              <Input
                prefix={<Icon type="lock" style={styles.textInput} />}
                type="password"
                placeholder="Confirm Password"
              />,
            )}
          </Form.Item>} */}

          <Form.Item>
            <Button className='primary-button' type='primary' htmlType='submit' style={styles.loginFormButton} loading={submitting}>
              Log In
            </Button>
            {/* <Divider/> */}
            {/* <Button type="link" style={styles.loginFormForgot} onClick={() => this.setState({ resetPassword: !resetPassword })}>
              {resetPassword ? 'Log in' : 'Reset password' }
            </Button> */}
            <div style={{ textAlign: 'center' }}>
              <Link to='/ForgotPassword'>Forgot password?</Link>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span>Don't have an account? <Link to='/signup'>Sign up</Link></span>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default connect(null, { authenticate })(Form.create({ name: 'login' })(SignIn));

const styles = {
  loginPage: {
    height: '90vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginForm: {
    width: '300px'
  },
  loginFormReset: {
    float: 'right'
  },
  loginFormButton: {
    width: '100%',
    backgroundColor: '#ff5e3a'
  },
  logo: {
    width: 300,
    padding: 20
  },
  textInput: {
    color: 'rgba(0,0,0,.25)'
  },
  loginFormForgot: {
    float: 'right'
  },
  loginFormVersion: {
    width: '300px',
    textAlign: 'center',
    color: '#777',
    marginTop: -20
  },
  signupFormButton: {
    width: '100%',
    backgroundColor: '#787878'
  }
};
