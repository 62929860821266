import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat, currencyFormatWithoutSymbol } from '../../../helpers/common';
import './tableStyles.css';

const dateFormat = 'DD-MM-YYYY';

class PayoutList extends Component {

  getColumns = () => {
    const { business } = this.props;
    return [
      {
        title: 'Order No',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => <p>{text}</p>
      },
      {
        title: 'Order Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: text => <p>{moment(text).format(dateFormat)}</p>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (text, order) => <div style={{ display: 'flex', flexDirection: 'column' }}>{order.consumerFeedback && !order.consumerFeedback.accepted && <Tag style={{ fontSize: 9, marginBottom: 2 }} color='#ff2e2e '>REJECTED</Tag>} <Tag color='#108ee9'>{text}</Tag></div>
      },

      // {
      //   title: 'Payment Status',
      //   dataIndex: 'platformReconciled',
      //   key: 'platformReconciled',
      //   align: 'center',
      //   render: platformReconciled => <Tag color="#108ee9">{platformReconciled ? 'Reconciled' : 'Pending'}</Tag>,
      // },

      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 130,
        className: 'amountClass',
        align: 'right',
        render: total => <p>{total && format(total, currencyFormatWithoutSymbol(business))}</p>
      },
      {
        title: 'Commission',
        dataIndex: 'commission',
        key: 'commission',
        width: 130,
        className: 'amountClass',
        align: 'right',
        render: commission => <p>{commission ? format(commission.value, currencyFormatWithoutSymbol(business)) : '0.00'}</p>
      },
      {
        title: 'Receivable',
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        render: (total, order) => <p>{total && order.commission ? format(this.getTotalPayableAmount(order), currencyFormatWithoutSymbol(business)) : '0.00'}</p>
      }
    ];
  }

  getTotalPayableAmount = (order) => {
    let totalPayableAmount = 0;
    if (order.commission) {
      totalPayableAmount = order.total - order.commission.value;
    }
    return totalPayableAmount;
  }

  renderStatusColor = status => {
    switch (status) {
      case 'DELETED':
        return '#ff0000';
      case 'PENDING':
        return '#fa8c16';
      case 'COMPLETED':
        return '#52c41a';
      default:
        return '';
    }
  }

  getOrderTotals = orders => {
    let totalPayableAmount = 0;
    let totalCommssionAmount = 0;
    let totalAmount = 0;

    orders.forEach(order => {
      totalAmount = totalAmount + order.total;
      if (order.commission) {
        totalCommssionAmount = totalCommssionAmount + order.commission.value;
      }
    });
    totalPayableAmount = totalAmount - totalCommssionAmount;

    return { total: totalAmount, count: orders.length, totalPayableAmount: totalPayableAmount, commission: totalCommssionAmount };
  }

  // generateTableFooter = (totals) => {
  //   const element = document.getElementsByClassName('amountClass');
  //   const amountElement = element.item(0);
  //   const { total, count } = totals
  //   return window.innerWidth > 865 ? <div>
  //     <p style={{ position: 'absolute', padding: 4 }}><b style={{ paddingLeft: 10 }}>Total of {count} Records</b></p>
  //     <p style={{ marginLeft: amountElement && amountElement.offsetLeft, width: amountElement && amountElement.offsetWidth, textAlign: 'right', padding: '5px 15px' }}>{format(total, currencyFormat)}</p>
  //   </div> :
  //     <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
  //       <p>No of Records : {count}</p>
  //       <p>Total Amount : <b>{format(total, currencyFormat)}</b></p>
  //     </div>
  // }
  generateTableFooter = (totals) => {
    const { business } = this.props;
    const element = document.getElementsByClassName('amountClass');
    const amountElement = element.item(0);
    const { total, count, totalPayableAmount, commission } = totals;
    return window.innerWidth > 865 ? <div>
      <p style={{ position: 'absolute', padding: 4 }}><b style={{ paddingLeft: 10 }}>Total of {count} Records</b></p>
      <div style={{ marginLeft: amountElement && amountElement.offsetLeft, display: 'flex', justifyContent: 'space-between', padding: 4 }}>
        <p style={{ paddingLeft: 16, paddingRight: 16, textAlign: 'right', width: 130 }}>{format(total, currencyFormat(business))}</p>
        <p style={{ paddingLeft: 16, paddingRight: 16, textAlign: 'right', width: 130 }}>{format(commission, currencyFormat(business))}</p>
        <p style={{ paddingLeft: 10, paddingRight: 10 }}>{format(totalPayableAmount, currencyFormat(business))}</p>
      </div>
    </div>
      : <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
        <p>No of Records : {count}</p>
        <p>Total Amount : <b>{format(total, currencyFormat)}</b></p>
        <p>Total Commission : <b>{format(commission, currencyFormat(business))}</b></p>
        <p>Total Payable Amount : <b>{format(totalPayableAmount, currencyFormat(business))}</b></p>
      </div>;
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.props.onRowSelection(selectedRowKeys, selectedRows);
  }

  getRowSelection = () => {
    const { orders = [], selectedRowKeys, selectedRows, onRowSelection } = this.props;

    return {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'all-data',
          text: 'Select All Data',
          onSelect: () => {
            const allRowKeys = orders.map(order => order.id);
            const allRows = orders.map(order => order);
            onRowSelection(allRowKeys, allRows);
          }
        }
      ]
    };
  }

  render() {
    const { orders = [], loading, enableRowSelection = true } = this.props;

    const totals = this.getOrderTotals(orders);
    const columns = this.getColumns();

    return (
      <div>
        <Table
          columns={columns}
          dataSource={orders}
          rowKey='id'
          loading={loading && (orders && orders.length === 0)}
          rowSelection={enableRowSelection && this.getRowSelection()}
          pagination
          footer={() => this.generateTableFooter(totals)}
        />
      </div>
    );
  }
}

export default PayoutList;
