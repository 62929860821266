import React, { Component } from 'react';
import { Form, Input, Button, Switch } from 'antd';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

class CategoryEditableForm extends Component {
  render () {
    const { editable, handleOnChange, handleSubmit, category, codeStatus } = this.props;
    const { code, hideFromUsers } = category;
    return (
      <Form
        style={{ width: '100%', marginTop: 66 }}
        {...layout}
        labelAlign='left'
      >
        <Form.Item label='Description' className='category-form'>
          {editable ? <Input onChange={(event) => handleOnChange('description', event.target.value)} /> : <p>Demo discription</p>}
        </Form.Item>

        <Form.Item label='Short Name' validateStatus={codeStatus}>
          {editable ? <Input value={code} onChange={(event) => handleOnChange('code', event.target.value)} /> : <p>{code}</p>}
        </Form.Item>

        <Form.Item label='Row Material'>
          <Switch defaultChecked={false} checked={hideFromUsers} value={hideFromUsers} onChange={value => handleOnChange('hideFromUsers', value)} checkedChildren='Yes' unCheckedChildren='No' />
        </Form.Item>

        {editable && <Form.Item style={{ textAlign: 'end' }} {...tailLayout}>
          <Button className='primary-button' type='primary' onClick={handleSubmit}>Save</Button>
        </Form.Item>}
      </Form>
    );
  }
}

export default CategoryEditableForm;
