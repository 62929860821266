import {
  getItems,
  insertItem,
  get,
  modifyItem,
  insertItemIngredient,
  insertItemGroupModifire,
  removeItems,
  insertItemCategories,
  insertItemPriceList,
  insertItemPrinterLocation,
  deleteItemPrinterLocation,
  deleteItemPrice,
  deleteIngredient,
  modifyItemPrice,
  addBulkData,
  getItemsForCategory,
  getAllItemsForCategory,
  updateVariantOptions,
  addVariant,
  modifyVariantHeaderDetails,
  removeVariant,
  updateVariantDefinition,
  createVariantGroup,
  exportBulkData
} from '../api/items';

export const fetchItems = companyId => dispatch => {
  dispatch({ type: 'START_FETCH_ITEMS' });

  return getItems(companyId).then(response => {
    console.log(response)
    if (!response.error) {
      dispatch({ type: 'SUCCEED_FETCH_ITEMS', items: response.items ? response.items.items : [] });
      dispatch({ type: 'FETCH_PRICE_LISTS', priceLists: response.items ? response.items.priceLists : [] });
      dispatch({ type: 'FETCH_PRINTER_LOCATIONS', printerLocations: response.items ? response.items.printerLocations : [] });
      dispatch({ type: 'FETCH_CATEGORIES', categories: response.items ? response.items.categories : [] });
      dispatch({ type: 'FETCH_CATEGORY_TREE', categories: response.items ? response.items.categories : [] });
    } else dispatch({ type: 'FAIL_FETCH_ITEMS' });
    return response;
  });
};

export const fetchItem = (companyId, itemId) => dispatch => {
  return get(companyId, itemId).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const addItem = (companyId, item) => dispatch => {
  return insertItem(companyId, item).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const updateItem = (companyId, itemId, data) => dispatch => {
  return modifyItem(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const updateItemPrice = (companyId, itemId, data) => dispatch => {
  return modifyItemPrice(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const addItemCategories = (companyId, itemId, data) => dispatch => {
  return insertItemCategories(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'INSERT_CATEGORY', category: response.category });
    return response;
  });
};

export const addItemIngredient = (companyId, itemId, data) => dispatch => {
  return insertItemIngredient(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const addItemGroupModifire = (companyId, itemId, data) => dispatch => {
  return insertItemGroupModifire(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const addItemPriceList = (companyId, itemId, data) => dispatch => {
  return insertItemPriceList(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const addItemPrinterLocation = (companyId, itemId, data) => dispatch => {
  return insertItemPrinterLocation(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const removeItemPrinterLocation = (companyId, itemId, data) => dispatch => {
  return deleteItemPrinterLocation(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const removeItemPrice = (companyId, itemPriceId, data) => dispatch => {
  return deleteItemPrice(companyId, itemPriceId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const removeIngredient = (companyId, ingredientId, data) => dispatch => {
  return deleteIngredient(companyId, ingredientId, data).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item });
    return response;
  });
};

export const uploadBulkData = (companyId, data) => dispatch => {
  return addBulkData(companyId, data).then(response => {
    // if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item});
    return response;
  });
};

export const downloadBulkData = (companyId) => dispatch => {
  return exportBulkData(companyId).then(response => {
    // if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item});
    return response;
  });
};


export const deleteItems = (companyId, data) => dispatch => {
  return removeItems(companyId, data).then(response => {
    // if (!response.error) dispatch({ type: 'FETCH_ITEM', item: response.item});
    return response;
  });
};

export const fetchItemsForCategory = (companyId, categoryId) => dispatch => {
  dispatch({ type: 'START_FETCH_ITEMS_FOR_CATEGORY' });

  return getItemsForCategory(companyId, categoryId).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY', items: response.items, categoryId });
    else dispatch({ type: 'FAIL_FETCH_ITEMS_FOR_CATEGORY', error: response.error });
    return response;
  });
};

export const fetchAllItemsForCategory = (companyId, categoryId) => dispatch => {
  dispatch({ type: 'START_FETCH_ITEMS_FOR_CATEGORY' });

  return getAllItemsForCategory(companyId, categoryId).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY', items: response.items, categoryId });
    else dispatch({ type: 'FAIL_FETCH_ITEMS_FOR_CATEGORY', error: response.error });
    return response;
  });
};

export const updateVariantHeaderDetails = (companyId, itemId, data) => dispatch => {
  return modifyVariantHeaderDetails(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_PARTIALLY', items: response.items });
    return response;
  });
};

export const updateVariantItemOptions = (companyId, itemId, data) => dispatch => {
  return updateVariantOptions(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_PARTIALLY', items: response.items });
    return response;
  });
};

export const addVariantItem = (companyId, itemId, data) => dispatch => {
  return addVariant(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_PARTIALLY', items: response.variantData.items });
    return response;
  });
};

export const deleteVariant = (companyId, itemId, data) => dispatch => {
  return removeVariant(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_PARTIALLY', items: response.variantData.items });
    //if (!response.error) dispatch({ type: 'DELETE_ITEM', item: response.item });
    return response;
  });
};

export const updateDefinition = (companyId, itemId, data) => dispatch => {
  return updateVariantDefinition(companyId, itemId, data).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_PARTIALLY', items: response.variantData.items });
    return response;
  });
};

export const addVariantGroup = (companyId, data) => dispatch => {
  return createVariantGroup(companyId, data).then(response => {
    if (!response.error) dispatch({ type: 'SUCCEED_FETCH_ITEMS_PARTIALLY', items: response.variantData.items });
    return response;
  });
};
