import React, { Component } from 'react';
import { Card, Col, Input, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { FiPlusCircle } from 'react-icons/all';
import CategoryItemRow from './CategoryItemRow';
import { fetchItemsForCategory } from '../../actions/items';
import { getItemsOnlyByCategory, getAuthenticity, getCompanyById } from '../../reducers';
import { fetchCategory, removeItemFromCategory } from '../../actions/categories';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import ItemOptionPopup from './ItemOptionPopup';
import { Scrollbars } from 'react-custom-scrollbars';

const { Search } = Input;

class CategoryItemList extends Component {
  state = {
    textFilter: undefined,
    optionsActive: false,
    loadingItems: false,
    submitting: false,
    selectedItemId: undefined
  }

  componentDidMount() {
    const { categoryId, companyId } = this.props;
    if (categoryId) {
      this.setState({ loadingItems: true });
      this.props.fetchItemsForCategory(companyId, categoryId).then(items => {
        this.setState({ loadingItems: false });
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    const { categoryId, companyId } = nextProps;
    if (this.props.categoryId !== categoryId) {
      if (categoryId) {
        this.setState({ loadingItems: true });
        this.props.fetchItemsForCategory(companyId, categoryId).then(items => {
          this.setState({ loadingItems: false });
        });
      }
    }
    if (this.props.onSuccessAddItem) {
      this.setState({ loadingItems: true });
      this.props.fetchItemsForCategory(companyId, categoryId).then(items => {
        this.setState({ loadingItems: false });
      });
    }
  }

  handleOptionsClick = (itemId, action) => {
    this.setState({ optionsActive: action, selectedItemId: itemId });
  }

  onDelete = () => {
    const { categoryId } = this.props;
    const { selectedItemId } = this.state;
    this.setState({ optionsActive: false });
    this.removeItemFromCategory(categoryId, selectedItemId);
  }

  removeItemFromCategory = (categoryId, selectedItemId) => {
    const { companyId } = this.props;

    this.setState({ submitting: true });
    this.props.removeItemFromCategory(companyId, categoryId, { itemId: selectedItemId }).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed delete Item from Category', error);
        this.setState({ submitting: false });
      } else {
        this.props.fetchItemsForCategory(companyId, categoryId).then(() => {
          this.setState({ submitting: false });
          showSuccessNotification('Item deleted.', 'Item deleted successfully.');
        });
      }
    });
  }

  getFilteredData = () => {
    const { items, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { textFilter } = this.state;
    const subCategoryItemList = items || [];
    const itemList = [];

    if (textFilter) {
      const filteredList = subCategoryItemList.length > 0 && subCategoryItemList.filter(subItem => subItem && subItem.name && subItem.name.toLowerCase().includes(textFilter.toLowerCase()));
      filteredList && filteredList.length > 0 && filteredList.forEach(item => {
        itemList.push(<div><CategoryItemRow business={business} item={item} onClickOptions={this.handleOptionsClick} /></div>);
      });
    } else {
      subCategoryItemList.forEach(item => {
        itemList.push(<div><CategoryItemRow business={business} item={item} onClickOptions={this.handleOptionsClick} /></div>);
      });
    }
    return itemList;
  }

  render() {
    const { optionsActive, loadingItems, submitting } = this.state;
    const { onClickAddItem, categoryId } = this.props;

    const items = this.getFilteredData();
    return (
      <Card
        className='category-item-container'
        style={{ borderRadius: 10, marginTop: 10 }}
        title='Items'
        extra={
          <div className='item-search'>
            <Search onChange={e => this.setState({ textFilter: e.target.value })} style={{ width: '90%' }} />
            <FiPlusCircle className='incy-button' onClick={() => onClickAddItem(categoryId)} style={{ color: '#F95C38', fontSize: 25 }} />
          </div>
        }
      >
        <ItemOptionPopup itemId={this.state.selectedItemId} optionsActive={optionsActive} onClose={this.handleOptionsClick} onDelete={() => this.onDelete()} />
        <Row className='item-table-header' style={{ marginLeft: 5, marginRight: 10 }}>
          <Col lg={4}>Image</Col>
          <Col lg={7}>Name</Col>
          <Col className='text-right' lg={4}>Price</Col>
          <Col className='text-right' lg={5}>Available Qty</Col>
          {/* <Col className='text-center' lg={4}>Online</Col> */}
          <Col className='text-right' lg={3}>Opt</Col>
        </Row>
        <Scrollbars style={{ height: 430 }}>
          <Spin spinning={(loadingItems && items.length === 0) || submitting} style={{ minHeight: 400 }}>
            <div style={{ paddingLeft: 5, marginRight: 10 }}>
              {items}
            </div>
          </Spin>
        </Scrollbars>
      </Card>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { categoryId } = props;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return { items: getItemsOnlyByCategory(state, categoryId), companyId: companyId, company: getCompanyById(state, companyId) }

};
export default connect(mapStateToProps, { fetchItemsForCategory, removeItemFromCategory, fetchCategory })(CategoryItemList);
