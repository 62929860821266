import Api from './consts';
import fetch from './commonFetch';

export const getCompanies = () => {
  const { url, method } = Api.companies.getAll;
  return fetch(url(), { method }, 'companies');
};

export const getCompanyDeviceList = companyId => {
  const { url, method } = Api.companies.getDeviceList;
  return fetch(url(companyId), { method }, 'devices');
};

export const getCompany = companyId => {
  const { url, method } = Api.companies.get;
  return fetch(url(companyId), { method }, 'company');
};

export const getCompanySubscriptions = companyId => {
  const { url, method } = Api.companies.getSubscriptions;
  return fetch(url(companyId), { method }, 'subscriptions');
};

export const getCompanyKioskSubscriptions = companyId => {
  const { url, method } = Api.companies.getSubscriptions;
  return fetch(url(companyId), { method }, 'subscriptions');// need to change
};

export const updateDeviceStatus = (companyId, deviceId, data) => {
  const { url, method, headers } = Api.companies.updateDeviceStatus;
  const body = JSON.stringify(data);

  return fetch(url(companyId, deviceId), { method, headers, body }, 'device');
};

export const updateSubscriptionDate = (companyId, subscriptionId, data) => {
  const { url, method, headers } = Api.companies.updateSubscriptionDate;
  const body = JSON.stringify(data);

  return fetch(url(companyId, subscriptionId), { method, headers, body }, 'subscription');
};

export const getSubscriptionDevice = (companyId, subscriptionId) => {
  const { url, method, headers } = Api.companies.getSubscriptionDevice;
  return fetch(url(companyId, subscriptionId), { method, headers }, 'device');
};

export const addSubscriptionToDevice = (companyId, deviceId, subscriptionId) => {
  const { url, method, headers } = Api.companies.addSubscriptionToDevice;
  return fetch(url(companyId, deviceId, subscriptionId), { method, headers }, 'device');
};

export const deleteSubscriptionFromDevice = (companyId, deviceId) => {
  const { url, method, headers } = Api.companies.deleteSubscriptionFromDevice;
  return fetch(url(companyId, deviceId), { method, headers }, 'device');
};
export const getKioskCount = (companyId, businessId) => {
  const { url, method, headers } = Api.companies.getKioskCount;
  return fetch(url(companyId, businessId), { method, headers }, 'count');
};

export const modifyKioskCount = (companyId, businessId, data) => {
  const { url, method, headers } = Api.companies.updateKioskCount;
  const body = JSON.stringify(data);
  return fetch(url(companyId, businessId), { method, headers, body }, 'company');
};

export const addBusiness = (companyId, data) => {
  const { url, method, headers } = Api.business.insert;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'company');
};

export const modifyBusiness = (companyId, data) => {
  const { url, method, headers } = Api.business.update;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'company');
};

export const updateBasicBusinessProfile = (companyId, data) => {
  const { url, method, headers } = Api.business.updateBasicBusinessProfile;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'company');
};

export const modifyBusinessHours = (companyId, id, data) => {
  const { url, method, headers } = Api.business.updateBusinessHours;
  const body = JSON.stringify(data);
  return fetch(url(companyId, id), { method, headers, body }, 'company');
};

export const removeBusinessHours = (companyId, id) => {
  const { url, method, headers } = Api.business.removeBusinessHours;
  return fetch(url(companyId, id), { method, headers }, 'company');
};

export const addBusinessHours = (companyId, data) => {
  const { url, method, headers } = Api.business.saveBusinessHours;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'company');
};

export const updateDevice = (companyId, deviceId, data) => {
  const { url, method, headers } = Api.companies.updateDevice;
  const body = JSON.stringify(data);

  return fetch(url(companyId, deviceId), { method, headers, body }, 'device');
};

export const addVerificationCode = (companyId, data) => {
  const { url, method, headers } = Api.companies.verifyClient;
  const body = JSON.stringify(data);

  return fetch(url(companyId), { method, headers, body }, 'company');
};

export const getCustomUrlStatus = (companyId, data) => {
  const { url, method, headers } = Api.companies.customUrlStatus;
  const body = JSON.stringify(data);

  return fetch(url(companyId), { method, headers, body }, 'customUrlStatus');
};

export const modifyCustomUrl = (companyId, data) => {
  const { url, method, headers } = Api.companies.updateCustomUrl;
  const body = JSON.stringify(data);

  return fetch(url(companyId), { method, headers, body }, 'customUrlResponse');
};

export const modifyOnlineAvailability = (companyId, businessId, data) => {
  const { url, method, headers } = Api.companies.updateOnlineAvailability;
  const body = JSON.stringify(data);

  return fetch(url(companyId, businessId), { method, headers, body }, 'allowOnlineResponse');
};
