import { notification } from 'antd';

export const showErrorNotification = (message, description) => {
  notification.error({
    message: '' + message,
    description: '' + description
  });
};

export const showErrorHTMLNotification = (message, description) => {
  notification.error({
    message: '' + message,
    description:   description
  });
};

export const showSuccessNotification = (message, description) => {
  notification.success({
    message: '' + message,
    description: '' + description
  });
};

export const showWarningNotification = (message, description) => {
  notification.warning({
    message: '' + message,
    description: '' + description
  });
};

export default { showErrorNotification, showSuccessNotification, showWarningNotification };
