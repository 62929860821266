import { advancedSearch, settleInvoice, deleteReceipt, fetchPrintReceipt } from '../api/receipts';

export const fetchReceiptsFromAdvancedSearch = query => dispatch => {
  return advancedSearch(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'PARTIALLY_FETCH_RECEIPT', receipts: response.data.receiptList, totalResults: response.data.totalResults, query });
    }
    return response;
  });
};

export const updateFilter = (type, filter) => dispatch => {
  dispatch({
    type: 'UPDATE_FILTER_RECEIPT',
    filterType: type,
    filter
  });
};

export const updateActiveFilter = filter => dispatch => {
  dispatch({
    type: 'UPDATE_ACTIVE_FILTER_RECEIPT',
    filter
  });
};

export const addPayment = receipt => dispatch => {
  return settleInvoice(receipt).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_RECEIPT', receipt: response.settleData.receipt });
      dispatch({ type: 'FETCH_INVOICES', invoices: response.settleData.invoices });
    }
    return response;
  });
};

export const removeReceipts = (deleteRequest) => dispatch => {
  return deleteReceipt(deleteRequest).then(response => {
    if (!response.error) {
      dispatch({ type: 'DELETE_RECEIPT', deletedReceipt: response.deletedReceipt });
    }
    return response;
  });
};

export const fetchPrintReceiptById = query => dispatch => {
  return fetchPrintReceipt(query).then(response => {
    if (!response.error) {
      dispatch({ type: 'FETCH_RECEIPT', receipt: response.receipt });
    }
    return response;
  });
};
