import React from 'react';
import { Col, Button, Input, Form } from 'antd';

const DistanceRateRow = ({
  click, from, to, value, id, onDeleteItem, onChangeInputValue, showDelete, toStatus, business
}) => (
  <div onClick={click} style={{ marginTop: 15, marginBottom: 15, color: '#555652' }}>
    <Col lg={6} style={{ paddingRight: 5 }}>
      <div>
        <p style={{ fontSize: 15, fontWeight: 600, color: '#555652' }}>From(km)</p>
        {<Form.Item>
          <Input disabled type='number' min='0' value={from} style={{ width: '100%', padding: 4 }} />
         </Form.Item>}
        {/* {id !== 0 && <Input type="number" min="0" value={from} style={{ width: "100%", padding: 4 }} onChange={(e) => onChangeInputValue(id, "FROM", e.target.value)} />} */}
      </div>
    </Col>
    <Col lg={1} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 73 }}>
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <p> - </p>
      </div>
    </Col>

    <Col lg={6} style={{ paddingRight: 5 }}>
      <div>
        <p style={{ fontSize: 15, fontWeight: 600, color: '#555652' }}>To(km)</p>
        <Form.Item validateStatus={toStatus}>
          <Input type='number' min='0' value={to} style={{ width: '100%', padding: 4 }} onChange={(e) => onChangeInputValue(id, 'TO', e.target.value)} />
        </Form.Item>
      </div>
    </Col>
    {<Col lg={1} />}
    <Col lg={6} style={{ paddingRight: 5 }}>
      <div>
        <p style={{ fontSize: 15, fontWeight: 600, color: '#555652' }}>{business && business.currency ? "Rate(" + business.currency + ")" : "Rate(LKR)" }</p>
        <Form.Item>
          <Input type='number' min='0' value={value} style={{ width: '100%', padding: 4 }} onChange={(e) => onChangeInputValue(id, 'RATE', e.target.value)} />
        </Form.Item>
      </div>
    </Col>
    <Col lg={4} style={{ display: 'block', top: 36 }}>
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginRight: 1 }}>
        {showDelete &&
          <Form.Item validateStatus={toStatus}>
            <Button icon='delete' className='isoDeleteBtn' type='danger' onClick={() => onDeleteItem(id)} />
          </Form.Item>}

      </div>
      {/*
        <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <Button icon="plus" type="button" className="isoDeleteBtn" />
        </div> */}
    </Col>

  </div>
);

export default DistanceRateRow;
