import React, { Component } from 'react';
import { Tabs } from 'antd';
import DeviceList from './DeviceList';
import SubscriptionList from './SubscriptionList';
import OrderList from './OrderList';

const { TabPane } = Tabs;

function callback (key) {

}

class CompanyDataTabView extends Component {
  render () {
    return (
      <Tabs onChange={callback} type='card'>
        <TabPane tab='Devices' key='1'>
          <DeviceList />
        </TabPane>
        <TabPane tab='Subscriptions' key='2'>
          <SubscriptionList />
        </TabPane>
        <TabPane tab='Orders' key='3'>
          <OrderList />
        </TabPane>
      </Tabs>
    );
  }
}

export default CompanyDataTabView;
