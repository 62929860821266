import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Tooltip } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import SelectWarehouse from './components/SelectWarehouse';
import { getLocationsByWarehouseId, getSelectedBranchId } from '../../reducers';
import SelectItemCategories from './components/SelectItemCategories';
import SelectItemGroups from './components/SelectItemGroups';
import SelectItemBrands from './components/SelectItemBrands';
import BranchSelector from '../../components/common/BranchSelector';
import CsvDownloaderInventoryItems from './components/CsvDownloaderInventoryItems';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

class AdvancedSearchForm extends Component {
  constructor() {
    super();
    this.state = {
      formLayout: 'horizontal',
    };
  }

  getOperatorsList = () => {
    return [
      <Option value="<">{"<"}</Option>,
      <Option value="<=">{"<="}</Option>,
      <Option value="==">{"=="}</Option>,
      <Option value=">=">{">="}</Option>,
      <Option value=">">{">"}</Option>
    ]
  }

  render() {
    const { companyId, company, query, locations, onInputTextChange, onInputCheckChange, onClickSearch, warehouseId, onClickAddItem, selectedBranchId, onClickImportGRN } = this.props;
    const { warehouseIds, locationIds, itemCategory, itemBrand, itemGroup } = query;
    const isAddItemEnabled = locationIds && locationIds.length > 0 && warehouseId && selectedBranchId && selectedBranchId != "0";
    return (
      <Form layout={'vertical'}>
        <Row >
          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <div style={{ width: '100%', marginTop: 5, marginBottom: 5, paddingRight: 5 }} >
              <BranchSelector onBanchChange = {(value) =>  onInputTextChange(value, 'branch')}/>
            </div>

          </SearchCol>

          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <SelectWarehouse
              placeholder={'Warehouse'}
              allowClear={true}
              defaultValue={warehouseId && warehouseId.length > 0 ? warehouseId : undefined}
              onChange={(_, value, errorKey) => onInputTextChange(value, 'warehouse')}
              style={{ width: '100%', marginTop: 5, marginBottom: 5, paddingRight: 5 }} />

          </SearchCol>

          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <Select
              dropdownMatchSelectWidth
              style={{ width: '100%', marginTop: 5, marginBottom: 5, paddingRight: 5 }}
              placeholder='Location'
              allowClear={true}
              value={locationIds && locationIds.length > 0 ? locationIds[0] : undefined}
              onChange={(value) => onInputTextChange(value, 'location')}
            >
              {locations.map(location => <Option value={location.id}>{location.name}</Option>)}
            </Select>

          </SearchCol>

          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <SelectItemCategories
              dropdownMatchSelectWidth
              style={{ width: '100%', marginTop: 5, marginBottom: 5, paddingRight: 5 }}
              placeholder='Item Category'
              defaultValue={itemCategory && itemCategory.length > 0 ? itemCategory : undefined}
              onChange={(_, value, errorKey) => onInputTextChange(value, 'itemCategory')}
              allowClear={true}
            >
            </SelectItemCategories>

          </SearchCol>
          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <SelectItemBrands
              dropdownMatchSelectWidth
              style={{ width: '100%', marginTop: 5, marginBottom: 5, paddingRight: 5 }}
              placeholder='Item Brand'
              defaultValue={itemBrand && itemBrand.length > 0 ? itemBrand : undefined}
              onChange={(_, value, errorKey) => onInputTextChange(value, 'itemBrand')}
              allowClear={true}
            />

          </SearchCol>

          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <SelectItemGroups
              dropdownMatchSelectWidth
              style={{ width: '100%', marginTop: 5, marginBottom: 5, paddingRight: 5 }}
              placeholder='Item group'
              defaultValue={itemGroup && itemGroup.length > 0 ? itemGroup : undefined}
              onChange={(_, value, errorKey) => onInputTextChange(value, 'itemGroup')}
              allowClear={true}
            />

          </SearchCol>


          <SearchCol lg={8} md={8} sm={12} xs={12}>
            <Input allowClear placeholder='Item Code' onChange={(e) => onInputTextChange(e.target.value, "itemCode")} />
          </SearchCol>

          <SearchCol lg={1} md={1} sm={0} xs={0}>
          </SearchCol>

          <SearchCol lg={15} md={15} sm={12} xs={12}>
            <Input allowClear placeholder='Description' onChange={(e) => onInputTextChange(e.target.value, "itemDescription")} />
          </SearchCol>

          {/* only for desk top view  */}
          <SearchCol lg={24} md={24} sm={0} xs={0}>
            <Col lg={12} md={12} sm={0} xs={0} style={{ display: 'flex' }}>
              < Col lg={3} md={3} >QIH</ Col>
              <Select
                dropdownMatchSelectWidth
                style={{ width: '100%', paddingRight: 5 }}
                placeholder='~'
                allowClear
                //value={selectedPriceList && selectedPriceList.name}
                onChange={(value) => onInputTextChange(value, 'QIHRule')}
              >
                {this.getOperatorsList()}
              </Select>
              <Input style={{ marginRight: 5 }} allowClear onChange={(e) => onInputTextChange(e.target.value, "QIHValue")} />


            </Col>
            <Col lg={12} md={12} sm={0} xs={0} style={{ display: 'flex' }}>
              < Col lg={3} md={3} > ROI </ Col>

              <Select
                dropdownMatchSelectWidth
                style={{ width: '100%', paddingRight: 5 }}
                placeholder='~'
                allowClear
                //value={selectedPriceList && selectedPriceList.name}
                onChange={(value) => onInputTextChange(value, 'ROQRule')}
              >
                {this.getOperatorsList()}
              </Select>
              <Input style={{ marginRight: 5 }} allowClear onChange={(e) => onInputTextChange(e.target.value, "ROQValue")} />

            </Col>
          </SearchCol>
          {/* end only for desktop view  */}

          {/* only for mobie view  */}
          <SearchCol lg={0} md={0} sm={24} xs={24}>
            <Col lg={0} md={0} sm={24} xs={24} style={{ display: 'flex' }}>
              < Col sm={3} xs={3} >QIH</ Col>
              <Select
                dropdownMatchSelectWidth
                style={{ width: '100%', paddingRight: 5 }}
                placeholder='~'
                allowClear
                //value={selectedPriceList && selectedPriceList.name}
                onChange={(value) => onInputTextChange(value, 'QIHRule')}
              >
                {this.getOperatorsList()}
              </Select>
              <Input style={{ marginRight: 5 }} allowClear onChange={(e) => onInputTextChange(e.target.value, "QIHValue")} />


            </Col>
          </SearchCol>

          <SearchCol lg={0} md={0} sm={24} xs={24}>
            <Col lg={0} md={0} sm={24} xs={24} style={{ display: 'flex' }}>
              < Col sm={3} xs={3} > ROI </ Col>

              <Select
                dropdownMatchSelectWidth
                style={{ width: '100%', paddingRight: 5 }}
                placeholder='~'
                allowClear
                //value={selectedPriceList && selectedPriceList.name}
                onChange={(value) => onInputTextChange(value, 'ROQRule')}
              >
                {this.getOperatorsList()}
              </Select>
              <Input style={{ marginRight: 5 }} allowClear onChange={(e) => onInputTextChange(e.target.value, "ROQValue")} />

            </Col>
          </SearchCol>

          {/* end mobile view part */}


          <SearchCol lg={12} md={12} sm={24} xs={24}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ display: 'flex' }}>
              <Col lg={3} md={3}> Balance </Col>
              <Select
                dropdownMatchSelectWidth
                style={{ width: '100%', paddingRight: 5 }}
                placeholder='~'
                allowClear
                //value={selectedPriceList && selectedPriceList.name}
                onChange={(value) => onInputTextChange(value, 'balanceRule')}
              >
                {this.getOperatorsList()}
              </Select>
              <Input style={{ marginRight: 5 }} allowClear onChange={(e) => onInputTextChange(e.target.value, "balanceValue")} />

            </Col>
          </SearchCol>

          <SearchCol lg={6} md={6} sm={12} xs={12}>
            <Checkbox
              //value={filter.onlineAvailable}
              onChange={(e) => onInputCheckChange(e, "toBeOrder")}
            > To be order immediately
            </Checkbox>


          </SearchCol>

          <SearchCol lg={6} md={6} sm={12} xs={12}>
            <Checkbox
              //value={filter.onlineAvailable}
              onChange={(e) => onInputCheckChange(e, "orderNow")}
            > Order Now
            </Checkbox>
          </SearchCol>

          <SearchCol lg={24} md={24} sm={24} xs={24}>
            <Col lg={3} md={3} sm={8} xs={8} style={{ paddingRight: 3 }} ><Button block onClick={onClickImportGRN}>Import GRN</Button></ Col>
            <Col lg={4} md={4} sm={8} xs={8} style={{ paddingRight: 3 }}> <CsvDownloaderInventoryItems companyId={companyId} company={company} selectedBranchId = {selectedBranchId} /></ Col>
            <Col lg={3} md={3} sm={8} xs={8} style={{ paddingRight: 3 }}><Button block onClick={onClickSearch}>Print</Button></ Col>
            <Col lg={3} md={3} sm={0} xs={0} style={{ paddingRight: 3 }}><Button block onClick={onClickSearch}>Transfer</Button></ Col>
            <Col lg={4} md={4} sm={0} xs={0} style={{ paddingRight: 10 }}><Button disabled={!isAddItemEnabled} block onClick={onClickAddItem}>Add Items</Button></ Col>

            <Col lg={4} md={4} sm={0} xs={0} ><Button block className='primary-button' type="primary" onClick={onClickSearch}>Search</Button></ Col>

            {/* mobile view  */}


          </SearchCol>

          <SearchCol lg={0} md={0} sm={24} xs={24}>
            {/* mobile view only */}
            <Col lg={0} md={0} sm={8} xs={8} style={{ paddingRight: 3 }}><Button block onClick={onClickSearch}>Transfer</Button></ Col>
            <Col lg={0} md={0} sm={8} xs={8} style={{ paddingRight: 3 }}><Button block disabled={!isAddItemEnabled} block onClick={onClickAddItem}>Add Items</Button></ Col>

          </SearchCol>
          <SearchCol lg={0} md={0} sm={24} xs={24}>
            <Col lg={0} md={0} sm={24} xs={24} ><Button block className='primary-button' type="primary" onClick={onClickSearch}>Search</Button></ Col>
          </SearchCol>

        </Row>
      </Form >
    );
  }
}

const mapStateToProps = (state, props) => {
  const { query } = props;
  console.log("branch", getSelectedBranchId(state))
  const warehouseId = query.warehouseIds && query.warehouseIds.length > 0 && query.warehouseIds[0];
  return ({
    locations: getLocationsByWarehouseId(state, warehouseId),
    warehouseId,
    selectedBranchId: getSelectedBranchId(state) === 0 ? "0" : getSelectedBranchId(state)
  });
};

export default connect(mapStateToProps, {})(AdvancedSearchForm);

const SearchCol = props => {
  return (
    <Col lg={props.lg} md={props.md} sm={props.sm} xs={props.xs} style={{ height: 50 }}>
      <Form.Item>
        {props.children}
      </Form.Item>
    </Col>
  )
};