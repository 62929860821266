import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_COMPANIES': {
      action.companies.forEach(company => state[company.userIdentity] = company);
      return state;
    }
    case 'FETCH_COMPANY': {
      const { company, companyId } = action;
      return { ...state, [companyId]: company };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COMPANIES':
      return action.companies.map(company => company.userIdentity);
    case 'FETCH_COMPANY':
      if (action.company) {
        if (state.includes(action.company.userIdentity)) {
          return state;
        } else {
          return [...state, action.company.userIdentity];
        }
      }
      break;
    default:
      return state;
  }
};

const companySubscriptionsByCompanyId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_SUBSCRIPTIONS': {
      const newState = { ...state };
      newState[action.companyId] = action.subscriptions;
      return newState;
    }
    default:
      return state;
  }
};

const selectedBranchId = (state = null, action) => {
  switch (action.type) {
    case 'SET_SELECTED_BRANCH': 
      return action.branchId;
    default:
      return state;
  }
}

const companies = combineReducers({
  byId,
  ids,
  companySubscriptionsByCompanyId,
  selectedBranchId
});

export default companies;

export const getCompanies = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]).filter(company => !!company) : [];
};

export const getCompanyById = (state, companyId) => {
  return state.byId[companyId];
};

export const getSubscriptionsByCompanyId = (state, companyId) => state.companySubscriptionsByCompanyId[companyId];

export const getSelectedBranchId = state => state.selectedBranchId;
