import React, { Component } from 'react';
import { Col, Form, Input, Row, Spin, InputNumber, Button } from 'antd';

class CardPaymentForm extends Component {

 isEmpty = () => {
    const {  cardPayment = {} } = this.props;
    if(cardPayment.amount === 0 || cardPayment.cardNumber.length === 0 || cardPayment.cardExpiry.length === 0 || cardPayment.nameOfCard.length === 0 || cardPayment.cvCode.length === 0 ){
      return true;
    }
    return false;
  }
  // amountValidator = amount => {
  //   const { invoiceAmount = 0 } = this.props;

  //   if (invoiceAmount !== 0 || !amount || amount < 0) return 'Invalid receipt amount. Amount should be greater than zero.';
  //   else if (amount > invoiceAmount) return "Receipt amount can't exceed invoice amount.";
  // }

  // textValidator = text => {
  //   if (!text || text.length === 0) return false;

  //   return true;
  // }

  // dateValidator = date => {
  //   console.log('DATE ', date);
  // }

  render() {
    const { submitting, handleOnChange, cardPayment = {}, handleConfirmPayment, cardAmountError, cardNumberError, cvCodeError, nameOfCardError, expireDateError } = this.props;
    //const invalidAmount = this.amountValidator(cardPayment.amount);
    //const validCardNumber = this.textValidator(cardPayment.cardNumber);
    //const validCvCode = this.textValidator(cardPayment.cvCode);
    //const validNameOfCard = this.textValidator(cardPayment.nameOfCard);
    //const validExpireDate = this.textValidator(cardPayment.cardExpiry);
    const confirmDisable = this.isEmpty() || cardAmountError || cardNumberError || cvCodeError || nameOfCardError || expireDateError;

    return (
      <Row gutter={10}>
        <Spin spinning={submitting}>
          <Form>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <div>Receipt Amount</div>
              <Form.Item
                required validateStatus={cardAmountError ? 'error' : ''}
                help={cardAmountError}
              >
                <div>
                  <InputNumber
                    style={{ width: '100%' }} value={cardPayment.amount} autoFocus
                    onChange={(value) => handleOnChange('cardPayment', 'amount', value)}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <div>Remarks</div>
              <Form.Item>
                <div>
                  <Input
                    value={cardPayment.remarks}
                    onChange={(event) => handleOnChange('cardPayment', 'remarks', event.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <div>Card number</div>
              <Form.Item
                required validateStatus={cardNumberError ? 'error' : ''}
                help={cardNumberError}
              >
                <div>
                  <Input
                    value={cardPayment.cardNumber}
                    onChange={(event) => handleOnChange('cardPayment', 'cardNumber', event.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col>
              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                <div>Expiry</div>
                <Form.Item
                  required validateStatus={expireDateError ? 'error' : ''}
                  help={expireDateError && 'Invalid expire date.'}
                >
                  <div>
                    <Input
                      placeholder='MM/YY'
                      value={cardPayment.cardExpiry}
                      onChange={(event) => handleOnChange('cardPayment', 'cardExpiry', event.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                <div>CV code</div>
                <Form.Item
                  required validateStatus={cvCodeError ? 'error' : ''}
                  help={cvCodeError && 'Invalid cv code.'}
                >
                  <div>
                    <Input
                      placeholder='CVC'
                      value={cardPayment.cvCode}
                      onChange={(event) => handleOnChange('cardPayment', 'cvCode', event.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Col>
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <div>Name of card</div>
              <Form.Item
                required validateStatus={nameOfCardError ? 'error' : ''}
                help={nameOfCardError}
              >
                <div>
                  <Input value={cardPayment.nameOfCard} onChange={(event) => handleOnChange('cardPayment', 'nameOfCard', event.target.value)} />
                </div>
              </Form.Item>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Button disabled={confirmDisable} type='primary' size='small' style={{ width: '100%', marginBottom: 10 }} onClick={() => handleConfirmPayment('cardPayment')}>Submit Payment</Button>
            </Col>
            {/* <Col style={{ float: 'right', width: 'fit-content' }} lg={12} md={24} sm={24} xs={24}>
						<h1><span style={{ margin: 5 }}>Amount:</span> { format(cardPayment.amount, currencyFormat) }</h1>
					  </Col> */}
          </Form>
        </Spin>
      </Row>
    );
  }
}

export default CardPaymentForm;
