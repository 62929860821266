import React, { Component } from 'react';
import { Table, Tag } from 'antd';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat, currencyFormatWithoutSymbol } from '../../helpers/common';
import './tableStyles.css';

const dateFormat = 'DD-MM-YYYY';

class PayoutTable extends Component {

  getColumns = () => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return [
      {
        title: 'Order No',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        render: text => <p>{text}</p>
      },
      {
        title: 'Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: text => <p>{moment(text).format(dateFormat)}</p>
      },
      {
        title: 'Order Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: text => <Tag color='#108ee9' style={{ fontSize: 10, marginBottom: '1em' }}>{text}</Tag>
      },
      {
        title: 'Payment Status',
        dataIndex: 'platformReconciled',
        width: 130,
        key: 'platformReconciled',
        align: 'center',
        render: (platformReconciled, order) => <div style={{ display: 'flex', flexDirection: 'column' }}>{order.reconcileStatus !== null ? <Tag style={{ fontSize: 10, marginBottom: 2 }} color='#ff5e3a'>Transfering</Tag> : <Tag color='#108ee9'>{platformReconciled ? 'Reconciled' : 'Pending'} </Tag>} </div>
      },
      {
        title: 'Type',
        width: 100,
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: text => <p>{text}</p>
      },
      {
        title: 'Customer Feedback Status',
        dataIndex: 'status',
        width: 130,
        key: 'status',
        align: 'center',
        render: (text, order) => <div style={{ display: 'flex', flexDirection: 'column' }}>{!order.consumerFeedback && <Tag color='#108ee9 '>N/A</Tag>}{order.consumerFeedback && !order.consumerFeedback.accepted && <Tag color='#ff2e2e '>REJECTED</Tag>}  {order.consumerFeedback && order.consumerFeedback.accepted && <Tag color='#25a023'>ACCEPTED</Tag>}</div>
      },
      {
        title: 'Customer Feedback',
        dataIndex: 'consumerFeedback',
        width: 130,
        key: 'consumerFeedback',
        align: 'center',
        render: (consumerFeedback, order) => <p>{consumerFeedback && consumerFeedback.comment.length > 0 ? consumerFeedback.comment : '-'}</p>
      },

      {
        title: 'Delivery Feedback status',
        width: 130,
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (text, order) => <div style={{ display: 'flex', flexDirection: 'column' }}>{!order.deliveryPersonFeedback && <Tag color='#108ee9 '>N/A</Tag>}{order.deliveryPersonFeedback && order.deliveryPersonFeedback.status === 'DELIVERED' && <Tag color='#25a023'>DELIVERED</Tag>}  {order.deliveryPersonFeedback && order.deliveryPersonFeedback.status === 'RETURNED' && <Tag color='#ff2e2e'>RETURNED</Tag>}</div>
      },
      {
        title: 'Delivery Person Feedback',
        dataIndex: 'deliveryPersonFeedback',
        width: 130,
        key: 'deliveryPersonFeedback',
        align: 'center',
        render: (deliveryPersonFeedback, order) => <p>{deliveryPersonFeedback && deliveryPersonFeedback.comment.length > 0 ? deliveryPersonFeedback.comment : '-'}</p>
      },

      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        className: 'amountClass',
        align: 'right',
        fixed: 'right',
        render: total => <p>{total && format(total, currencyFormatWithoutSymbol(business))}</p>
      },
      {
        title: 'Commission',
        dataIndex: 'commission',
        key: 'commission',
        width: 100,
        className: 'amountClass',
        align: 'right',
        fixed: 'right',
        render: commission => <p>{commission ? format(commission.value, currencyFormatWithoutSymbol(business)) : '0.00'}</p>
      },
      {
        title: 'Receivable',
        dataIndex: 'total',
        className: 'amountClass',
        key: 'total',
        fixed: 'right',
        align: 'right',
        render: (total, order) => <p>{total && order.commission ? format(this.getTotalPayableAmount(order), currencyFormatWithoutSymbol(business)) : '0.00'}</p>
      }
    ];
  }

  getTotalPayableAmount = (order) => {
    let totalPayableAmount = 0;
    if (order.commission) {
      totalPayableAmount = order.total - order.commission.value;
    }
    return totalPayableAmount;
  }

  renderStatusColor = status => {
    switch (status) {
      case 'DELETED':
        return '#ff0000';
      case 'PENDING':
        return '#fa8c16';
      case 'COMPLETED':
        return '#52c41a';
      default:
        return '';
    }
  }

  getOrderTotals = orders => {
    let totalPayableAmount = 0;
    let totalCommssionAmount = 0;
    let totalAmount = 0;

    orders.forEach(order => {
      totalAmount = totalAmount + order.total;
      if (order.commission) {
        totalCommssionAmount = totalCommssionAmount + order.commission.value;
      }
    });
    totalPayableAmount = totalAmount - totalCommssionAmount;

    return { total: totalAmount, count: orders.length, totalPayableAmount: totalPayableAmount, commission: totalCommssionAmount };
  }

  generateTableFooter = (totals) => {
    const { business } = this.props;
    const element = document.getElementsByClassName('amountClass');
    const amountElement = element.item(0);
    const { total, count, totalPayableAmount, commission } = totals;
    return window.innerWidth > 865 ? <div>
      <p style={{ position: 'absolute', padding: 4 }}>
        <b style={{ paddingLeft: 10 }}>Total of {count} Records</b>
      </p>
      <div style={{ marginLeft: amountElement && amountElement.offsetLeft - 490, display: 'flex', justifyContent: 'space-between', padding: 4 }}>
        <p style={{ paddingLeft: 30, paddingRight: 10 }}>{format(total, currencyFormat(business))}</p>
        <p style={{ paddingLeft: 10, paddingRight: 10 }}>{format(commission, currencyFormat(business))}</p>
        <p style={{ paddingLeft: 10, paddingRight: 10 }}>{format(totalPayableAmount, currencyFormat(business))}</p>
      </div>
    </div>
      : <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
        <p>No of Records : {count}</p>
        <p>Total Amount : <b>{format(total, currencyFormat(business))}</b></p>
        <p>Total Commission : <b>{format(commission, currencyFormat(business))}</b></p>
        <p>Total Receivable Amount : <b>{format(totalPayableAmount, currencyFormat(business))}</b></p>
      </div>;
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.props.onRowSelection(selectedRowKeys, selectedRows);
  }

  getRowSelection = () => {
    const { orders = [], selectedRowKeys, selectedRows, onRowSelection } = this.props;

    return {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'all-data',
          text: 'Select All Data',
          onSelect: () => {
            const allRowKeys = orders.map(order => order.id);
            const allRows = orders.map(order => order);
            onRowSelection(allRowKeys, allRows);
          }
        }
      ]
    };
  }

  render() {
    const { orders = [], loading, enableRowSelection = true } = this.props;

    const totals = this.getOrderTotals(orders);
    const columns = this.getColumns();

    return (
      <div>
        <Table
          columns={columns}
          dataSource={orders}
          rowKey='id'
          loading={loading}
          scroll={{ x: 1300 }}
          rowSelection={enableRowSelection && this.getRowSelection()}
          pagination
          footer={() => this.generateTableFooter(totals)}
        />
      </div>
    );
  }
}

export default PayoutTable;
