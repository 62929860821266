import Api from './consts';
import fetch from './commonFetch';

const { receipts } = Api;

export const advancedSearch = query => {
  const { url, method } = receipts.advancedSearch;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'data');
};

export const settleInvoice = receipt => {
  const { url, method } = receipts.settleInvoice;
  const body = JSON.stringify(receipt);

  return fetch(url(), { body, method }, 'settleData');
};

export const deleteReceipt = deleteRequest => {
  const { url, method } = receipts.deleteReceipt;
  const body = JSON.stringify(deleteRequest);
  return fetch(url(), { body, method }, 'deletedReceipt');
};

export const fetchPrintReceipt = query => {
  const { url, method } = receipts.fetchPrintReceipt;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'receipt');
};
