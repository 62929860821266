import Api from './consts';
import fetch from './commonFetch';

const { invoices, companies } = Api;

export const advancedSearch = query => {
  const { url, method } = invoices.advancedSearch;
  const body = JSON.stringify(query);

  return fetch(url(), { body, method }, 'invoices');
};

export const getInvoicesForCustomer = customerId => {
  const { url, method } = companies.getInvoicesForCustomer;

  return fetch(url(customerId), { method }, 'invoices');
};

export const generateForSubscriptions = subscriptionMetas => {
  const { url, method } = invoices.generateForSubscritptions;
  return fetch(url(), { method, body: JSON.stringify({ subscriptionMetas: subscriptionMetas }) }, 'invoices');
};

export const removeInvoice = deleteRequest => {
  const { url, method } = invoices.removeInvoice;
  const body = JSON.stringify(deleteRequest);
  return fetch(url(), { body, method }, 'deletedInvoiceResponse');
};

export const sendEmails = emailRequest => {
  const { url, method } = invoices.sendEmails;
  const body = JSON.stringify(emailRequest);
  return fetch(url(), { body, method }, 'invoices');
};

export const fetchPrintInvoice = request => {
  const { url, method } = invoices.printInvoice;
  const body = JSON.stringify(request);
  return fetch(url(), { body, method }, 'invoice');
};
