import { getList, getProcedure } from '../api/generic';
import { query } from './queryBuilder';

export const fetchList = (tableName, filter) => dispatch => {
  // console.log("action", tableName, filter)
  const finalQuery = query(tableName, filter);
  return getList(tableName, finalQuery).then(response => {
    switch (tableName) {
      case 'order':
        if (!response.error) dispatch({ type: 'FETCH_ORDERS', orders: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
        case 'view_user_orders':
        if (!response.error) dispatch({ type: 'FETCH_ORDERS', orders: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
      case 'order_item':
        if (!response.error) dispatch({ type: 'FETCH_ORDER_ITEMS', orderItems: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
      case 'view_order_receipt_transaction':
        if (!response.error) dispatch({ type: 'FETCH_RECEIPTS_TRNSACTIONS', records: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
      case 'view_customer_order_summary'://customers list
        if (!response.error) dispatch({ type: 'FETCH_CUSTOMERS', customers: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
      case 'person_address':
        if (!response.error) dispatch({ type: 'FETCH_PERSON_ADDRESSES', records: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
      case 'person_feedback':
        if (!response.error) dispatch({ type: 'FETCH_PERSON_FEEDBACK', records: response.result.records, totals: response.result.totals, grandTotal: response.result.grandTotal, query: filter });
        break;
    }
    return response;
  });
};

export const fetchProcedure = (procedureName, filter) => dispatch => {
  // console.log("action", procedureName, filter)
  const values = Object.values(filter);
  return getProcedure(procedureName, { values }).then(response => {
    if (!response.error) dispatch({ type: 'FETCH_ANALYTICS', analytics: response.result.records, query: filter, procedureName });
    return response;
  });
};