import React, { } from 'react'
import { Row, Col, Card, Progress } from 'antd'

const AnalyticsCard = (props) => {
  const { title, todayValue, predictedValue, performanceValue, projectTarget, performanceValueText, bgColor, image, progressColor } = props
  return (
    <Card className='card-container analysis_card_container' style={{ backgroundColor: bgColor }}>
      <div className='d-flex mb-2'>
        <img
          src={image}
          style={{
            height: '46px',
            opacity: '.7'
          }}
        />
        <div className='ml-3'>
          <p className='m-0' style={{ fontSize: '11px', fontWeight: '100', textTransform: 'uppercase' }}>{title}</p>
          <p className='m-0' style={{ fontSize: '26px', fontWeight: '300', lineHeight: '30px' }}>{todayValue}</p>
        </div>
      </div>
      <Row>
        <Col span={12}>
          <p className='m-0' style={{ fontSize: '12px', fontWeight: '100' }}>Predicted</p>
          <p className='m-0' style={{ fontSize: '18px', fontWeight: '600', lineHeight: '22px' }}>{predictedValue}</p>
        </Col>
        <Col span={12}>
          <p className='m-0' style={{ fontSize: '12px', fontWeight: '100' }}>Performance</p>
          <p className='m-0' style={{ fontSize: '18px', fontWeight: '600', lineHeight: '22px' }}>{performanceValueText}</p>
        </Col>
      </Row>
      <div className='mt-2'>
        <p className='m-0' style={{ fontSize: '12px', fontWeight: '100' }}>Projected Target</p>
        <Progress strokeWidth={5} showInfo={false} percent={projectTarget} strokeColor={progressColor} />
      </div>
    </Card>
  )
}

export default AnalyticsCard