import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_AGENT': {
      const newState = {};
      newState[action.agent.id] = action.agent;
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_AGENT': {
      const newState = [];
      if (!newState.includes(action.agent.id)) {
        newState.push(action.agent.id);
      }
      return newState;
    }
    default:
      return state;
  }
};

const agent = combineReducers({
  byId,
  ids
});
export default agent;

export const getAgent = state => {
  const agentDetails = state && state.ids && state.ids.length > 0 ? state.byId[state.ids[0]] : [];
  return agentDetails;
};
