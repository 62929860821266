import React, { Component } from 'react';
import { Button, Card, Col, Row, Spin } from 'antd';
import { fetchCompany } from '../../actions/companies';
import { withRouter } from 'react-router';
import { fetchItem, updateVariantItemOptions, addVariantItem } from '../../actions/items';
import { connect } from 'react-redux';
import SideBar from '../../components/SideBar';
import {
  getAuthenticity,
  getCompanyById,
  getItem,
  getItemsByVariantGroupId
} from '../../reducers';
import ItemForm from '../ItemForm/VariantItemForm';
import VariantItemList from './VariantItemList';
import VariantOptionForm from './VariantOptionForm';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';

class EditFormContainer extends Component {
  state = {
    itemId: '',
    name: '',
    imageSrc: '',
    nameStatus: '',
    description: '',
    variant: undefined,
    loading: false,
    checkForItemUpdate: false,
    selectedVariantId: undefined
  }

  componentDidMount = () => {
    const { companyId, itemId, company, item } = this.props;

    if (!company || !company.businesses) {
      this.props.fetchCompany(companyId);
    }

    if (!item) {
      this.setState({ loading: true });
      this.props.fetchItem(companyId, itemId).then(response => {
        this.setItemDetails(this.props);
        this.setState({ loading: false });
      });
    } else {
      this.setItemDetails(this.props);
    }
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps) this.setItemDetails(nextProps);
  }

  setItemDetails = props => {
    const { item, newVariant } = props;
    // const { name, description, imageSrc, variant } = item;

    let newVariantObject;
    if (item && newVariant) {
      newVariantObject = { definition: item.variant.definition, options: {} };
      Object.values(item.variant.options).forEach((optionType, index) => {
        const optionCopy = { ...optionType, option: '' };
        newVariantObject.options[index] = optionCopy;
      });
    }

    item && this.setState({
      name: item.name, description: item.description, imageSrc: item.imageSrc, variant: item.variant, newVariantObject
    });
  }

  handleClickAddOption = () => {
    const { variantOptionTypes } = this.state;
    this.setState({ variantOptionTypes: [...variantOptionTypes, { id: '', name: '', options: [] }] });
  }

  renderVariantOptionTypes = object => {
    const optionTypes = [];
    Object.values(object).forEach(value => {
      optionTypes.push(value);
    });

    return optionTypes;
  }

  handleOnChange = (field, value) => {
    switch (field) {
      case 'name':
        this.setState({ name: value, nameStatus: this.getValidationStatus(field, value) });
        break;
      case 'description':
        this.setState({ description: value });
        break;
      case 'option': {
        const { newVariant } = this.props;
        const { variant, newVariantObject } = this.state;

        const variantCopy = newVariant ? { ...newVariantObject } : { ...variant };
        const { option: optionCopy, ...validationStatus } = value;

        let variantOptions = Object.values(variantCopy.options);
        const variantOptionIndex = variantOptions.findIndex(variantOption => variantOption.variantOptionType.id === optionCopy.variantOptionType.id);
        variantOptions[variantOptionIndex] = optionCopy;
        variantOptions = variantOptions.reduce((newObject, optionValue, index) => ({ ...newObject, [index]: optionValue }), {});
        variantCopy.options = variantOptions;

        if (newVariant) this.setState({ newVariantObject: variantCopy, validationStatus });
        else this.setState({ variant: variantCopy, validationStatus });

        break;
      }
      default:
    }
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      case 'option':
        return (!value || (value && value.length === 0)) ? 'error' : 'success';
      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;

  handleVariantDefinitionChange = (id, name, value) => {
    switch (name) {
      case 'type':
        console.log('TYPE CHANGED ', id, name, value);
        break;
      case 'options':
        console.log('OPTION CHANGED ', id, name, value);
        break;
      default:
        return null;
    }
  }

  handleSubmit = () => {
    const { newVariant } = this.props;

    if (newVariant) {
      this.handleInsertVariant();
    } else {
      this.handleUpdateVariantOptions();
    }
  }

  handleInsertVariant = () => {
    const { itemId, companyId, addVariantItem } = this.props;
    const { newVariantObject } = this.state;

    const newItems = Object.values(newVariantObject.options);

    this.setState({ loading: true });
    addVariantItem(companyId, itemId, newItems).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        showSuccessNotification('Updated.', 'Variant added successfully.');
        this.setState({ loading: false });
      }
    });
  }

  handleUpdateVariantOptions = () => {
    const { itemId, companyId, updateVariantItemOptions } = this.props;
    const { variant } = this.state;

    const updatedItems = Object.values(variant.options);

    this.setState({ loading: true });
    updateVariantItemOptions(companyId, itemId, updatedItems).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        showSuccessNotification('Updated.', 'Variant option updated successfully.');
        this.setState({ loading: false });
      }
    });
  }

  getVariantOptionBreadcumbTitle = () => {
    const { item = {} } = this.props;
    const variant = item.variant;
    let breadcumbTitle = '';

    const options = variant && variant.options && Object.values(variant.options);
    options && options.forEach(option => {
      breadcumbTitle = breadcumbTitle + option.option + ' / ';
    });

    return breadcumbTitle.substr(0, breadcumbTitle.length - 3);
  }

  handleClickVariant = variantId => {
    this.setState({ checkForItemUpdate: true, selectedVariantId: variantId });
    // const path = '/items/' + variantId + '/variant';
    // this.props.history.replace(path);
  }

  onUpdateFinished = variantId => {
    this.setState({ checkForItemUpdate: false, selectedVariantId: undefined });
    const path = '/items/' + variantId + '/variant';
    this.props.history.replace(path);
  }

  // handleOnAddNewVariant = () => {
  //   this.setState({
  //     name: item.name, description: item.description, imageSrc: item.imageSrc, variant: item.variant, newVariantObject
  //   });
  // }

  render () {
    const { variants, match, newVariant } = this.props;
    const { variant, loading, newVariantObject } = this.state;
    const { itemId, variantId } = match.params;

    return (
      <div className='container'>
        <Row gutter={[5, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ fontWeight: 400, fontSize: 24 }} />
                <Button style={{ textAlign: 'right', fontSize: 18 }} type='link' onClick={() => this.props.history.goBack()}>Back</Button>
              </div>
            </Col>
            <Spin spinning={loading}>
              <Col lg={8} md={8} sm={8} xs={24}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Card className='card-container'>
                    {/* <h5>{item.name}</h5> */}
                    {/* <h6 className='variant-count' style={{ fontSize: 14 }}>{variants.length} variants</h6> */}
                    <h6 style={{ paddingBottom: 10, borderBottom: '1px solid rgb(245, 245, 245)', marginBottom: 10 }}>Options</h6>
                    <VariantOptionForm
                      newVariant={newVariant}
                      variant={newVariant ? newVariantObject : variant}
                      handleOnChange={this.handleOnChange}
                      onHandleUpdateVariantOptions={this.handleSubmit}
                      onAddNewVariant={this.handleOnAddNewVariant}
                    />
                  </Card>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Card className='card-container'>
                    <h6 style={{ paddingBottom: 10, borderBottom: '1px solid rgb(245, 245, 245)', marginBottom: 20 }}>Variants</h6>
                    <VariantItemList variantItems={variants} showHeader={false} extendedView={false} onClickVariant={this.handleClickVariant} itemId={itemId} />
                  </Card>
                </Col>
              </Col>
              <Col lg={16} md={16} sm={16} xs={24}>
                <ItemForm selectedVariantId={this.state.selectedVariantId} checkForItemUpdate={this.state.checkForItemUpdate} onUpdateFinished={this.onUpdateFinished} itemId={variantId || itemId} variantId={variantId} newVariantObject={newVariantObject} />
              </Col>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { itemId } = props.match.params;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  const item = getItem(state, itemId);
  const variants = item && item.variant && item.variant.definition && getItemsByVariantGroupId(state, item.variant.definition.variantGroupId).filter(item => !!item);

  return {
    itemId: itemId,
    companyId: companyId,
    item,
    variants,
    company: getCompanyById(state, companyId)
  };
};

export default withRouter(connect(mapStateToProps, { fetchCompany, fetchItem, updateVariantItemOptions, addVariantItem })(EditFormContainer));
