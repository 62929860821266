import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Collapse, Icon, Statistic, Button, Modal, Spin } from 'antd';
import { withRouter } from 'react-router';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';

// import ReceiptTemplate from '../ReceiptView/receiptTemplate';
import moment from 'moment';
import CashPaymentForm from './cashPaymentForm';
import CardPaymentForm from './cardPaymentForm';
import ChequePaymentForm from './chequePaymentForm';
import BankDepositPaymentForm from './bankDepositPaymentForm';
import { addOrderPayment } from '../../../actions/Orders';
//import { fetchInvoicesForCustomer } from '../../actions/invoices';
import { showSuccessNotification, showErrorNotification } from '../../../components/Notification';

const { Panel } = Collapse;

class OrderPaymentSettlementModal extends Component {
  defaultReceipt = {
    received: 0.0,
    amount: 0.0,
    balanceToSettle: 0.0,
    type: "CASH", // CASH, CARD, CHEQUE, FRIMI, OTHER
    typeDescription: "",
    chequeNumber: '',
    chequeBankName: "",
    chequeDate: undefined,
    cardNumber: "",
    frimiNumber: "",
    cardExpiryDate: undefined,
    cardExpiry: "",
    remarks: '',
  }

  state = {
    receipt: {
      ...this.defaultReceipt
    },
    paymentDetails: {
      cashPayment: {
        type: 'CASH',
        remarks: '',
        amount: 0
      },
      cardPayment: {
        type: 'CARD',
        cardNumber: '',
        cardExpiry: '',
        cvCode: '',
        nameOfCard: '',
        remarks: '',
        amount: 0
      },
      chequePayment: {
        type: 'CHEQUE',
        chequeBankName: undefined,
        chequeNumber: '',
        chequeDate: moment().add(1, 'days'),
        remarks: '',
        amount: 0
      },

      // bankDepositPayment: {
      //   type: 'OTHER',
      //   bank: undefined,
      //   branch: undefined,
      //   bankAccountNumber: undefined,
      //   depositDate: moment(),
      //   remarks: '',
      //   amount: 0
      // }
      cashAmountError: undefined,

      cardAmountError: undefined,
      cardNumberError: undefined,
      cvCodeError: undefined,
      nameOfCardError: undefined,
      expireDateError: undefined,

      chequeAmountError: undefined,
      chequeBankNamerError: undefined,
      chequeNumberError: undefined,
      chequeDateError: undefined,
    },

    errorReceiptAmount: undefined,
    submitting: false,
    activePanelKey: 'cashPayment'
  }

  amountValidator = amount => {
    const { order } = this.props;
    const { balance_to_settle = 0 } = order;
    const re = /^\d+(\.\d{1,5})?$/;
    if (!amount || amount < 0 || !re.test(amount)) return 'Receipt amount is Invalid or amount is not grater than zero';
    else if (amount > balance_to_settle) return "Receipt amount can't exceed invoice amount.";
  }


  cardNumbertValidator = text => {
     const re = /^[0-9\b]+$/;
    if (!text || text.length === 0 || !re.test(text)) return 'Invalid Card Number.';
  }

  cvCodeValidator = text => {
    const re = /^[0-9\b]+$/;
    if (!text || text.length === 0|| !re.test(text)) return 'Invalid cv code.';
  }

  expiryDateValidator = text => {
    if (!text || text.length === 0) return 'Invalid expire date.';
  }

  nameOfCardValidator = text => {
    if (!text || text.length === 0) return 'Invalid name of card.';
  }
  chequeBankNameValidator = text => {
    if (!text || text.length === 0) return 'Invalid bank.';
  }

  chequeNumberValidator = text => {
    const re = /^[0-9\b]+$/;
    if (!text || text.length === 0 ||  !re.test(text)) return 'Invalid cheque number.';
  }

  chequeDateValidator = date => {
    if (moment(date).isAfter(moment())) return 'Invalid cheque date.';
  }

  handleOnChange = (key, field, value) => {
    const { paymentDetails } = this.state;
    let cardAmountError = '';

    let cashAmountError = '';
    let cardNumberError = '';
    let expireDateError = '';
    let cvCodeError = '';
    let nameOfCardError = '';

    let chequeAmountError = '';
    let chequeBankNamerError = '';
    let chequeNumberError = '';
    let chequeDateError = '';

    this.resetStatusErrors();

    switch (key) {
      case 'cashPayment':
        cashAmountError = this.amountValidator(value);
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            cashPayment: { ...paymentDetails.cashPayment, [field]: value }
          },
          cashAmountError: cashAmountError
        });
        break;
      case 'cardPayment':
        if (field === 'amount') {
          cardAmountError = this.amountValidator(value);
          this.setState({ cardAmountError: cardAmountError })
        } else if (field === 'cardNumber') {
          cardNumberError = this.cardNumbertValidator(value);
          this.setState({ cardNumberError: cardNumberError })
        } else if (field === 'cardExpiry') {
          expireDateError = this.expiryDateValidator(value);
          this.setState({ expireDateError: expireDateError })
        } else if (field === 'cvCode') {
          cvCodeError = this.cvCodeValidator(value)
          this.setState({ cvCodeError: cvCodeError })
        } else if (field === 'nameOfCard') {
          nameOfCardError = this.nameOfCardValidator(value)
          this.setState({ nameOfCardError: nameOfCardError })
        }
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            cardPayment: { ...paymentDetails.cardPayment, [field]: value }
          },
        });
        break;
      case 'chequePayment':
        if (field === 'amount') {
          chequeAmountError = this.amountValidator(value);
          this.setState({ chequeAmountError: chequeAmountError })
        } else if (field === 'chequeBankName') {
          chequeBankNamerError = this.chequeBankNameValidator(value);
          this.setState({ chequeBankNamerError: chequeBankNamerError })
        } else if (field === 'chequeNumber') {
          chequeNumberError = this.chequeNumberValidator(value);
          this.setState({ chequeNumberError: chequeNumberError })
        } else if (field === 'chequeDate') {
          chequeDateError = this.chequeDateValidator(value)
          this.setState({ chequeDateError: chequeDateError })
        }
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            chequePayment: { ...paymentDetails.chequePayment, [field]: value }
          }
        });
        break;
      case 'bankDepositPayment':
        this.setState({
          paymentDetails: {
            ...paymentDetails,
            bankDepositPayment: { ...paymentDetails.bankDepositPayment, [field]: value }
          }
        });
        break;
      default:
        console.log('No change');
    }
  }

  handleConfirmPayment = type => {
    const { onCancel, companyId, order } = this.props;
    const request = this.createReceiptRequest(type);

    if (!this.validateSubmission(request)) return;

    this.setState({ submitting: true });
    this.props.addOrderPayment(companyId, order.id, request).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({
          submitting: false,
        });
      } else {
        const defaultReceipt = { ...this.defaultReceipt };
        this.setState({
          receipt: defaultReceipt,
          submitting: false,
          paymentSelectStatus: false,

        });
        showSuccessNotification('Transaction completed.', 'Payment settled successfully.');
        onCancel();
        this.resetPaymentDetails();
        this.props.handlePaymentDone();
      }
    });
  }

  redirectToPrint = receipt => this.props.history.replace('/printReceipt/' + receipt.id + '/' + receipt.customerId)

  createReceiptRequest = type => {
    const { receipt, paymentDetails } = this.state;
    const { order } = this.props;

    return {
      ...receipt,
      ...paymentDetails[type],
      orderId: order.id
    };
  }

  resetPaymentDetails = () => {
    this.setState({
      paymentDetails: {
        cashPayment: {
          type: 'CASH',
          remarks: '',
          amount: 0
        },
        cardPayment: {
          type: 'CARD',
          cardNumber: '',
          cardExpiry: '',
          cvCode: '',
          nameOfCard: '',
          remarks: '',
          amount: 0
        },
        chequePayment: {
          type: 'CHEQUE',
          chequeBankName: undefined,
          chequeNumber: '',
          chequeDate: moment().add(1, 'days'),
          remarks: '',
          amount: 0
        },

        // bankDepositPayment: {
        //   type: 'OTHER',
        //   bank: undefined,
        //   branch: undefined,
        //   bankAccountNumber: undefined,
        //   depositDate: moment(),
        //   remarks: '',
        //   amount: 0
        // }
      },
    })
  }

  resetStatusErrors = () => {
    this.setState({
      cashAmountError: undefined,

      cardAmountError: undefined,
      cardNumberError: undefined,
      cvCodeError: undefined,
      nameOfCardError: undefined,
      expireDateError: undefined,

      chequeAmountError: undefined,
      chequeBankNamerError: undefined,
      chequeNumberError: undefined,
      chequeDateError: undefined,
    })
  }

  validateSubmission = request => {
    const { amount } = request;
    const validForm = amount > 0;

    if (!validForm) {
      this.setState({
        paymentSelectStatus: amount > 0,
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'invoice':
        return !!this.isValidInvoiceSelection(value);
      case 'payment':
        return !!this.isValidPaymentSelection(value);
      case 'amount':
        return this.isValidPaymentAmountSelection(value);
      default:
        return null;
    }
  }

  isValidInvoiceSelection = invoices => invoices && invoices.length > 0;
  isValidPaymentSelection = receipt => receipt && receipt.receiptamount > 0;
  // isValidPaymentAmountSelection = receipt => {
  // 	const { selectedRows = [] } = this.state;
  // 	const receiptTotal = receipt && receipt.amount
  // 	let settleTotal = 0;
  // 	selectedRows.length > 0 && selectedRows.forEach(row => {
  // 		settleTotal = settleTotal + row.balancetosettle;
  // 	})

  // 	return receiptTotal && receiptTotal > 0 && receiptTotal <= settleTotal;
  // }

  isValidReceiptAmount = amount => {
    const { selectedRows = [] } = this.state;
    let settleTotal = 0;
    selectedRows.length > 0 && selectedRows.forEach(row => {
      settleTotal = settleTotal + row.balancetosettle;
    });

    return amount && amount > 0 && amount <= settleTotal;
  }

  ok = () => {

  }

  cancel = () => {
    this.props.onCancel();
  }


  renderPaymentSection = () => {
    const { order, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const {
      paymentDetails = {},
      activePanelKey
    } = this.state;

    const customerName = order && order.customer && order.customer.name ? order.customer.name : '';
    const orderAmountToBePaid = order ? order.balance_to_settle : 0.0;
    const receiptAmount = paymentDetails[activePanelKey] && paymentDetails[activePanelKey].amount;
    const outstandingAmount = orderAmountToBePaid - receiptAmount;

    return (
      <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: 25 }}>
        <Col lg={24} md={24} sm={24} xs={24} style={{ backgroundColor: 'white' }}>
          <Statistic valueStyle={{ fontSize: 16 }} title={<span style={{ fontSize: 14, fontWeight: 500, color: 'black' }}>Customer</span>} value={customerName} />
          <Statistic valueStyle={{ fontSize: 16 }} title={<span style={{ fontSize: 14, fontWeight: 500, color: 'black' }}>Receipt Date</span>} value={moment().format('YYYY-MM-DD')} />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} style={{ backgroundColor: 'white' }}>
          <Row style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 150, fontSize: 14, fontWeight: 500, color: 'black' }}>Order Amount</div>
              <div style={{ width: 100, textAlign: 'end' }}>{format(orderAmountToBePaid, currencyFormat(business))}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 150, fontSize: 14, fontWeight: 500, color: 'black' }}>Receipt Amount</div>
              <div style={{ width: 100, textAlign: 'end' }}>{format(receiptAmount, currencyFormat(business))}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid gainsboro' }}>
              <div style={{ width: 150, fontSize: 14, fontWeight: 500, color: 'black' }}>Outstanding</div>
              <div style={{ width: 100, textAlign: 'end' }}>{format(outstandingAmount, currencyFormat(business))}</div>
            </div>
          </Row>
        </Col>
      </Col>
    );
  }

  render() {
    const { banks, match, company, visible, onCancel, order } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const orderAmountToBePaid = order ? order.balance_to_settle : 0.0;
    const {
      submitting,
      paymentDetails = {},
      cashAmountError,

      cardAmountError,
      cardNumberError,
      cvCodeError,
      nameOfCardError,
      expireDateError,

      chequeAmountError,
      chequeNumberError,
      chequeDateError,
      chequeBankNamerError,
    } = this.state;

    return (
      <Modal
        style={{ padding: 0 }}
        visible={visible}
        title='New Receipt'
        okText='Save'
        onCancel={onCancel}
        onOk={this.ok}
        cancelButtonProps={{ style: { color: 'white', border: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ padding: 0 }}
      >
        <div >
          <Col lg={24} md={24}>
            <Row>
              <Col>
                <Col lg={24} md={24} sm={24} xs={24} style={{ padding: 5 }}>
                  <Collapse
                    style={{ padding: 10 }}
                    defaultActiveKey='cashPayment'
                    bordered={false}
                    accordion
                    onChange={(key) => this.setState({ activePanelKey: key })}
                  >
                    <Panel
                      showArrow={false}
                      header={<h5>Cash Payment</h5>}
                      key='cashPayment'
                      extra={<Icon className='icon-money' />}
                    >
                      {this.renderPaymentSection()}
                      <CashPaymentForm
                        cashPayment={paymentDetails.cashPayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={orderAmountToBePaid}
                        cashAmountError={cashAmountError}
                        handleConfirmPayment={this.handleConfirmPayment}
                      />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={<h5>Card Payment</h5>}
                      key='cardPayment'
                    >
                      {this.renderPaymentSection()}
                      <CardPaymentForm
                        cardPayment={paymentDetails.cardPayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={orderAmountToBePaid}
                        handleConfirmPayment={this.handleConfirmPayment}
                        cardAmountError={cardAmountError}
                        cardNumberError={cardNumberError}
                        cvCodeError={cvCodeError}
                        nameOfCardError={nameOfCardError}
                        expireDateError={expireDateError}
                      />
                    </Panel>
                    <Panel
                      showArrow={false}
                      header={<h5>Cheque Payment</h5>}
                      key='chequePayment'
                    >
                      {this.renderPaymentSection()}
                      <ChequePaymentForm
                        banks={banks}
                        chequePayment={paymentDetails.chequePayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={orderAmountToBePaid}
                        handleConfirmPayment={this.handleConfirmPayment}
                        chequeAmountError={chequeAmountError}
                        chequeNumberError={chequeNumberError}
                        chequeDateError={chequeDateError}
                        chequeBankNamerError={chequeBankNamerError}
                      />
                    </Panel>
                    {/* <Panel
                      showArrow={false}
                      header={<h5>Bank Deposit</h5>}
                      key='bankDepositPayment'
                    >
                      {this.renderPaymentSection()}
                      <BankDepositPaymentForm
                        banks={banks}
                        bankDepositPayment={paymentDetails.bankDepositPayment}
                        submitting={submitting}
                        handleOnChange={this.handleOnChange}
                        invoiceAmount={orderAmountToBePaid}
                        handleConfirmPayment={this.handleConfirmPayment}
                      />
                    </Panel> */}
                  </Collapse>
                </Col>
              </Col>
              {/* {newReciptId && <Col lg={24} md={24} sm={24} xs={24} style={{ padding: 5 }}>
								<ReceiptTemplate receiptId={newReciptId} />
							</Col>} */}
              <Col lg={24} md={24} sm={24} xs={24} style={{ float: 'right', marginTop: 5, marginBottom: 5 }}>
                <Col lg={5} md={5} sm={24} xs={24} style={{ float: 'right', marginTop: 5, marginBottom: 10 }}>
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Button
                      className='primary-button' onClick={() => onCancel()} block type='primary'
                      size='small'
                    >Exit
  </Button>
                  </div>
                </Col>
              </Col>
            </Row>
          </Col>

        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(null, { addOrderPayment })(OrderPaymentSettlementModal));

