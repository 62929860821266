import { loadState } from '../localStorage';

const commonFetch = (url, data, responseType) => {
  setHeaders(data);
  return doFetch(url, data, responseType);
};

export const uploadFile = (url, data, responseType) => {
  setHeaders(data, true);
  return doFetch(url, data, responseType);
};

export const downalodFile = (url, data, responseType) => {
  setHeaders(data);
  return doFetchDownload(url, data, responseType);
};

const doFetchDownload = (url, data, responseType) =>
  fetch(url, data)
    .then(response =>  response.blob())
    .then(blob => {
      if (blob) {
          return { [responseType]: blob };
      } else {
        return { error: "Download Failed" };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' + error };
    });

const doFetch = (url, data, responseType) =>
  fetch(url, data)
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.success) {
        if (responseType) {
          return { [responseType]: responseJson.data };
        } else {
          return { response: responseJson.data };
        }
      } else {
        return { error: responseJson.error.message };
      }
    }).catch(error => {
      return { error: 'An error occured while connecting to the server.' };
    });

const setHeaders = (data, fileUpload = false) => {
  const auth = loadState('authenticity');
  const headers = data.headers || {};
  headers.Accept = 'application/json';
  headers['auth-token'] = auth.authToken;

  if (!fileUpload) headers['Content-Type'] = 'application/json';

  data.headers = headers;
};

export default commonFetch;
