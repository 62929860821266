import React, { Component } from 'react';
import OpeningHoursRow from './OpeningHoursRow';

const defaultDateObject = {
  id: 'new',
  dayOfWeek: 0,
  businessOpeningHour: '',
  businessClosingHour: '',
  businessOpeningMinute: '',
  businessClosingMinute: '',
  deliveryStartingHour: '',
  deliveryEndingHour: '',
  deliveryStartingMinute: '',
  deliveryEndingMinute: '',
  delivering: false,
  businessOpened: false,
  updating: false,

  lastBusinessOpeningHour: '',
  lastBusinessClosingHour: '',
  lastBusinessOpeningMinute: '',
  lastBusinessClosingMinute: '',
  lastDeliveryOpeningHour: '',
  lastDeliveryClosingHour: '',
  lastDeliveryOpenningMinute: '',
  lastDeliveryClosingMinute: '',

  lastValueDeliveryClosed: false,
  lastValueBusinessClosed: false,

  businessOpeningStatus: '',
  businessClosingStatus: '',
  deliveryEndingStatus: '',
  deliveryStartingStatus: ''
};

class OpeningHoursCard extends Component {
  state = {
    loading: false,
    data: undefined

  }

  componentDidMount = () => {
    if (this.props.generalSettings) {
      this.initBusinessHours(this.props.generalSettings);
    } else {
      var defaultDateArray = [];
      for (var i = 0; i < 7; i++) {
        var obj = { ...defaultDateObject, dayOfWeek: i };
        defaultDateArray.push(obj);
      }
      // const { deliveryData } = this.props;
      this.setState({ data: defaultDateArray });
    }
  }

  componentWillReceiveProps (nextProps) {
    console.log(nextProps);
    if (nextProps.generalSettings) {
      this.initBusinessHours(nextProps.generalSettings);
    }
  }

  initBusinessHours = (generalSettings) => {
    var businessHours = [];
    generalSettings.businessHours && Object.keys(generalSettings.businessHours).forEach(key => {
      businessHours.push(generalSettings.businessHours[key]);
    });

    this.updateDefaultArrayWithProps(businessHours);
    console.log(businessHours.length);
  }

  updateDefaultArrayWithProps = (newPropsData) => {
    var filterdData = newPropsData.filter((date) => (date.dayOfWeek !== -1));
    var defaultDataSet = this.generateDefaultDataSet();

    for (var i = 0; i < filterdData.length; i++) {
      defaultDataSet[filterdData[i].dayOfWeek] = filterdData[i];
    }
    this.storeValuesForReset(defaultDataSet);
  }

  generateDefaultDataSet = () => { // Note: Only a shallow copy, nested objects will not be copy if used
    var defaultDataSet = [];
    for (var i = 0; i < 7; i++) {
      var temp = Object.assign({}, defaultDateObject);
      temp.dayOfWeek = i;
      defaultDataSet[i] = temp;
    }
    return defaultDataSet;
  }

  storeValuesForReset = (data) => {
    var updatedData = data.map((rate, i) => {
      return (
        Object.assign({}, rate,
          {
            lastValueDeliveryClosed: rate.delivering
          }, {
            lastValueBusinessClosed: rate.businessOpened
          },
          {
            lastBusinessOpeningHour: rate.businessOpeningHour
          }, {
            lastBusinessClosingHour: rate.businessClosingHour
          }, {
            lastBusinessOpeningMinute: rate.businessOpeningMinute
          }, {
            lastBusinessClosingMinute: rate.businessClosingMinute
          }, {
            lastDeliveryOpeningHour: rate.deliveryStartingHour
          }, {
            lastDeliveryClosingHour: rate.deliveryEndingHour
          }, {
            lastDeliveryOpenningMinute: rate.deliveryStartingMinute
          }, {
            lastDeliveryClosingMinute: rate.deliveryEndingMinute
          }
        ));
    });
    this.setState({ data: updatedData });
  }

  isValid = (index) => {
    const { data } = this.state;
    var valid = true;
    var returnData = undefined;

    returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { updating: false }) : rate));

    if (returnData[index].businessOpened) {
      if (returnData[index].businessOpeningHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpeningStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (returnData[index].businessClosingHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (!this.isValidTime(returnData[index].businessOpeningHour, returnData[index].businessOpeningMinute, returnData[index].businessClosingHour, returnData[index].businessClosingMinute)) {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      }
    }

    if (returnData[index].delivering) {
      if (returnData[index].deliveryStartingHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryStartingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (returnData[index].deliveryEndingHour === '') {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      } else if (!this.isValidTime(returnData[index].deliveryStartingHour, returnData[index].deliveryStartingMinute, returnData[index].deliveryEndingHour, returnData[index].deliveryEndingMinute)) {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingStatus: 'error' }) : rate));
        this.setState({ data: returnData });
        valid = false;
      }
    }

    return valid;
  }

  isValidTime (startHour, startMinute, endHour, endMinute) {
    if (endHour > startHour) {
      return true;
    } else if (endHour === startHour) {
      if (endMinute > startMinute) {
        console.log('yes');
        return true;
      } else {
        console.log('no');
        return false;
      }
    } else {
      console.log('no');
      return false;
    }
  }

  onChangeValue = (index, feild, value) => {
    const { data } = this.state;
    const { onUpdate } = this.props;
    var returnData = undefined;

    if (feild === 'businessOpenTime') {
      if (value && value !== '') {
        var hourOpenTime = value.split(':')[0];
        var minuteOpenTIme = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpeningHour: parseInt(hourOpenTime) }, { businessOpeningMinute: parseInt(minuteOpenTIme) }, { businessOpeningStatus: '' }, { updating: true }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpeningHour: value }, { businessOpeningMinute: value }, { updating: true }, { businessOpeningStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'businessCloseTime') {
      if (value && value !== '') {
        var hourCloseTime = value.split(':')[0];
        var minuteCloseTime = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingHour: parseInt(hourCloseTime) }, { businessClosingMinute: parseInt(minuteCloseTime) }, { updating: true }, { businessClosingStatus: '' }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessClosingHour: value }, { businessClosingMinute: value }, { updating: true }, { businessClosingStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'deliveryOpenTime') {
      if (value && value !== '') {
        var hourDeliveryOpen = value.split(':')[0];
        var minuteDeliveryOpen = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryStartingHour: parseInt(hourDeliveryOpen) }, { deliveryStartingMinute: parseInt(minuteDeliveryOpen) }, { deliveryStartingStatus: '' }, { updating: true }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryStartingHour: value }, { deliveryStartingMinute: value }, { updating: true }, { deliveryStartingStatus: '' }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'deliveryCloseTime') {
      if (value && value !== '') {
        var hour = value.split(':')[0];
        var minute = value.split(':')[1];
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingHour: parseInt(hour) }, { deliveryEndingMinute: parseInt(minute) }, { deliveryEndingStatus: '' }, { updating: true }) : rate));
      } else {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { deliveryEndingHour: value }, { deliveryEndingMinute: value }, { deliveryEndingStatus: '' }, { updating: true }) : rate));
      }
      this.setState({ data: returnData });
    } else if (feild === 'businessOpened') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { businessOpened: value }, { updating: true }, { businessOpeningStatus: '' }, { businessClosingStatus: '' }) : rate));
      this.setState({ data: returnData });
    } else if (feild === 'delivering') {
      returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { delivering: value }, { updating: true }, { deliveryStartingStatus: '' }, { deliveryEndingStatus: '' }) : rate));
      this.setState({ data: returnData });
    } else if (feild === 'save') {
      // save or update line then called below line
      if (this.isValid(index)) {
        returnData = data.map((rate, i) => (i === index ? Object.assign({}, rate, { updating: false }) : rate));
        onUpdate(returnData[index]);
      }
    } else if (feild === 'reset') {
      returnData = this.resetData(index);
      this.setState({ data: returnData });
    }
  }

  resetData = (index) => {
    var resetedData = this.state.data.map((rate, i) => {
      var lastValueDeliveryClosed = rate.lastValueDeliveryClosed !== undefined ? rate.lastValueDeliveryClosed : rate.delivering;
      var lastValueBusinessClosed = rate.lastValueBusinessClosed !== undefined ? rate.lastValueBusinessClosed : rate.businessOpened;
      var lastBusinessOpeningHour = rate.lastBusinessOpeningHour !== undefined ? rate.lastBusinessOpeningHour : rate.businessOpeningHour;
      var lastBusinessClosingHour = rate.lastBusinessClosingHour !== undefined ? rate.lastBusinessClosingHour : rate.businessClosingHour;
      var lastBusinessOpeningMinute = rate.lastBusinessOpeningMinute !== undefined ? rate.lastBusinessOpeningMinute : rate.businessOpeningMinute;
      var lastBusinessClosingMinute = rate.lastBusinessClosingMinute !== undefined ? rate.lastBusinessClosingMinute : rate.businessClosingMinute;

      var lastDeliveryOpeningHour = rate.lastDeliveryOpeningHour !== undefined ? rate.lastDeliveryOpeningHour : rate.deliveryStartingHour;
      var lastDeliveryClosingHour = rate.lastDeliveryClosingHour !== undefined ? rate.lastDeliveryClosingHour : rate.deliveryEndingHour;
      var lastDeliveryOpenningMinute = rate.lastDeliveryOpenningMinute !== undefined ? rate.lastDeliveryOpenningMinute : rate.deliveryStartingMinute;
      var lastDeliveryClosingMinute = rate.lastDeliveryClosingMinute !== undefined ? rate.lastDeliveryClosingMinute : rate.deliveryEndingMinute;

      return (
        i === index ? Object.assign({}, rate,
          {
            delivering: lastValueDeliveryClosed
          }, {
            businessOpened: lastValueBusinessClosed
          }, {
            businessOpeningHour: lastBusinessOpeningHour
          }, {
            businessClosingHour: lastBusinessClosingHour
          }, {
            businessOpeningMinute: lastBusinessOpeningMinute
          }, {
            businessClosingMinute: lastBusinessClosingMinute
          }, {
            deliveryStartingHour: lastDeliveryOpeningHour
          }, {
            deliveryEndingHour: lastDeliveryClosingHour
          }, {
            deliveryStartingMinute: lastDeliveryOpenningMinute
          }, {
            deliveryEndingMinute: lastDeliveryClosingMinute
          }, {
            updating: false
          }
        ) : rate);
    });

    return resetedData;
  }

  getDayNameByDayNumber = (dayNo) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[dayNo];
  }

  getFormatedTime = (hour, minute) => {
    var formattdTime = '';
    if (hour !== '' && minute !== '') {
      formattdTime = hour + ':' + minute;
    }
    return formattdTime;
  }

  render () {
    const { data } = this.state;
    // console.log(subscriptions);
    return (
      <div style={{ width: '100%' }}>

        {data && data.map((date, index) => (
          <OpeningHoursRow
            dayOfWeek={this.getDayNameByDayNumber(date.dayOfWeek)}
            index={index}
            businessOpened={date.businessOpened}
            businessOpenTime={this.getFormatedTime(date.businessOpeningHour, date.businessOpeningMinute)}
            businessCloseTime={this.getFormatedTime(date.businessClosingHour, date.businessClosingMinute)}
            deliveryOpenTime={this.getFormatedTime(date.deliveryStartingHour, date.deliveryStartingMinute)}
            deliveryCloseTime={this.getFormatedTime(date.deliveryEndingHour, date.deliveryEndingMinute)}
            delivering={date.delivering}
            updating={date.updating}
            lastValueOpenTime={this.getFormatedTime(date.lastBusinessOpeningHour, date.lastBusinessOpeningMinute)}
            lastValueCloseTime={this.getFormatedTime(date.lastBusinessClosingHour, date.lastBusinessClosingMinute)}
            lastValueDeliveryOpenTime={this.getFormatedTime(date.lastDeliveryOpeningHour, date.lastDeliveryOpenningMinute)}
            lastValueDeliveryCloseTime={this.getFormatedTime(date.lastDeliveryClosingHour, date.lastDeliveryClosingMinute)}
            lastValueDeliveryClosed={date.lastValueDeliveryClosed}
            lastValueBusinessClosed={date.lastValueBusinessClosed}
            onChangeValue={(index, feild, value) => this.onChangeValue(index, feild, value)}
            businessOpeningStatus={date.businessOpeningStatus}
            businessClosingStatus={date.businessClosingStatus}
            deliveryStartingStatus={date.deliveryStartingStatus}
            deliveryEndingStatus={date.deliveryEndingStatus}
          />
        ))}
      </div>
    );
  }
}
export default OpeningHoursCard;
