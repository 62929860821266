import React, { Component } from 'react';
import { Drawer } from 'antd';
import CategoryFormContainer from './CategoryFormContainer';

class CategoryFormDrawer extends Component {
  render () {
    const { onClose, visible, parentCategoryId, companyId, company, editable } = this.props;

    return (
      <div>
        <Drawer
          title='New Category'
          placement='right'
          closable={false}
          onClose={onClose}
          visible={visible}
          width='60%'
        >
          <CategoryFormContainer editable={editable} parentCategoryId={parentCategoryId} companyId={companyId} company={company} onClose={onClose} />
        </Drawer>
      </div>
    );
  }
}

export default CategoryFormDrawer;
