import React from 'react';
import { message } from 'antd';
import styled from 'styled-components';

const Message = styled.p`
  display: inline-block;
  font-size: 13px;
`;

export default Message;

export const showLoadingMessage = text => message.loading(<Message>{'' + text}</Message>, 0);
export const showErrorMessage = text => message.error(<Message>{'' + text}</Message>, 10);
