import { combineReducers } from 'redux';
import moment from 'moment';

export const defaultFilter = {
  page: 1,
  pageSize: 20,
  fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
  toDate: moment(new Date()).endOf('day'),
};


const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ORDERS': {
      const newState = { ...state };
      action.orders && action.orders.length > 0 && action.orders.forEach(order => { newState[order.id] = order; });
      return newState;
    }
    case 'FETCH_ORDER': {
      return { ...state, [action.order.id]: action.order };
    }
    case 'PARTIALLY_FETCH_ORDERS': {
      const newState = { ...state };
      action.orders && action.orders.forEach(order => { newState[order.id] = order; });
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ORDERS': {
      const newState = [];
      action.orders && action.orders.forEach(order => {
        if (!newState.includes(order.id)) {
          newState.push(order.id);
        }
      });
      return newState;
    }
    case 'FETCH_ORDER': {
      if (state.includes(action.order.id)) {
        return state;
      } else {
        return [...state, action.order.id];
      }
    }
    case 'PARTIALLY_FETCH_ORDERS': {
      const newState = [];
      action.orders && action.orders.forEach(order => {
        if (!newState.includes(order.id)) {
          newState.push(order.id);
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

const byTransferNoteIds = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_TRANSFER_NOTE_ORDERS': {
      const newState = { ...state };

      newState[action.transferNoteId] = action.orders.map(order => order.id);

      return newState;
    }
    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ORDERS': {
      const newState = { ...state };
      const key = getActiveFilterJoinedKey(action.query);
      const idsForFilter = [];
      action.orders && action.orders.forEach(order => {
        idsForFilter.push(order.id);
      });
      newState[key] = { ids: idsForFilter, totals: action.totals, grandTotal: Object.keys(action.grandTotal).length !== 0 ? action.grandTotal : undefined };
      return newState;
    }
    default:
      return state;
  }
};

export const activeFilter = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_ORDER':
      return {
        ...state,
        [action.filterType]: action.filter
      };

    case 'UPDATE_ACTIVE_FILTER_ORDER':
      return {
        ...action.filter
      };

    default:
      return state;
  }
};

const orders = combineReducers({
  byId,
  ids,
  byTransferNoteIds,
  byFilter,
  activeFilter
});

export default orders;

export const getOrders = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getFirstOrder = state => {
  const ordersArray = state && state.byId && Object.values(state.byId).length > 0 ? Object.values(state.byId) : [];
  ordersArray.sort(function (a, b) { return new Date(b.createdDate) - new Date(a.createdDate) })
  return ordersArray.length > 0 ? ordersArray[0] : undefined;
};

export const getOrdersByTransferNoteId = (state, transferNoteId) => {
  return state && state.byTransferNoteIds[transferNoteId] && state.byTransferNoteIds[transferNoteId].map(id => state.byId[id]);
};

export const getEligibleOrdersForTransferNote = state => {
  return (
    state && state.ids && state.ids.length > 0
      ? state.ids
        .map(id => state.byId[id])
        .filter(order => !!order)
        .filter(order => (order.platformReconciled && order.reconcileStatus === null)) : []
  );
};

export const getActiveFilter = state => {
  return state.activeFilter;
};

const getActiveFilterJoinedKey = (filter) => {
  const _filter = { ...filter };
  const fromDate = filter.fromDate;
  const toDate = filter.toDate;
  if (filter.fromDate && filter.toDate) {
    _filter.fromDate = moment(fromDate).format('DD/MM/YYYY');
    _filter.toDate = moment(toDate).format('DD/MM/YYYY');
  }

  return Object.values(_filter).join('');
}

export const getFilteredOrders = state => {
  const key = getActiveFilterJoinedKey(getActiveFilter(state));
  const filterdOrderIds = state.byFilter[key] ? state.byFilter[key].ids : [];

  return filterdOrderIds.map(id => state.byId[id]).filter(order => !!order);
};

export const getFilteredOrderTotalCount = state => {
  const key = getActiveFilterJoinedKey(getActiveFilter(state));
  return state.byFilter[key] ? state.byFilter[key].grandTotal.count : 0;
};


