import React, { Component } from 'react';
import { Avatar, Button, Col, Row, Popconfirm, Tag } from 'antd';
import image from '../../image/empty-image.png';
import { format } from 'currency-formatter';
import { currencyFormat, generateTitle } from '../../helpers/common';
import { FaEdit, FaTrash } from 'react-icons/all';
import { Link } from 'react-router-dom';

class VariantItemRow extends Component {
  getItemPrice = itemPricesMap => {
    const { selectedPriceList } = this.props;
    const itemPrices = Object.keys(itemPricesMap).map(key => itemPricesMap[key]);
    let price = 0;

    if (selectedPriceList && selectedPriceList.id) {
      itemPrices.forEach(itemPrice => {
        if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) price = itemPrice.price;
      });
    }
    return price;
  }

  renderVariantTag = () => {
    const { itemDetails } = this.props;
    let optionTag = '';
    itemDetails && itemDetails.variant && itemDetails.variant.options && Object.values(itemDetails.variant.options).forEach(variantOption => {
      optionTag = optionTag + variantOption.option + ' / ';
    });

    return optionTag.substr(0, optionTag.length - 3);
  }

  renderCategories = () => {
    const { itemDetails } = this.props;
    const categories = [];
    itemDetails && itemDetails.categories && itemDetails.categories.forEach(category => {
      categories.push(<Tag style={{ maxWidth: 90, overflow: 'hidden', fontSize: 10 }}>{generateTitle(category.name)}</Tag>);
    });
    return categories;
  }

  render() {
    const { itemDetails, extendedView, selectedId, showEditAction, business } = this.props;
    const imagePath = itemDetails && itemDetails.imageSrc ? itemDetails.imageSrc : image;
    return (
      <Row className='all-item-row' id={itemDetails.id === selectedId && 'selected-variant-container'} style={{ marginLeft: 5, marginRight: 5, marginTop: -10, width: '98.8%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {extendedView && <Col lg={2}>
            <Avatar size={50} src={imagePath} />
          </Col>}
          <Col lg={extendedView ? 6 : 24} style={{ overflow: 'hidden' }}>
            {extendedView && <p style={{ marginBottom: 0 }}>{itemDetails.name}</p>}
            <span className='disable-text-selection' style={{ fontSize: extendedView ? 12 : 14, color: extendedView ? '#006fbb' : '' }}>{this.renderVariantTag()}</span>
          </Col>
          {extendedView && <Col className='text-left' style={{ maxHeight: 22, overflow: 'overlay' }} lg={6}>
            {this.renderCategories()}
          </Col>}
          {extendedView && <Col className='text-right' lg={4}>
            {itemDetails && itemDetails.itemPrices && format(this.getItemPrice(itemDetails.itemPrices), currencyFormat(business))}
          </Col>}
          {extendedView && <Col className='text-center' lg={3}>
            <p>{itemDetails.availableQty2 ? itemDetails.availableQty2 : 0}</p>
          </Col>}
          {extendedView && <Col className='text-right' lg={3}>
            {showEditAction && <Link to={'/items/' + itemDetails.id + '/variant'}>
              <Button
                size='small' type='primary'
                style={(selectedId === itemDetails.id) ? { backgroundColor: '#ffffff', borderColor: '#ffffff', marginLeft: 2 } : { backgroundColor: '#F95C38', borderColor: '#F95C38', marginLeft: 2 }}
              >
                <FaEdit style={(selectedId !== itemDetails.id) ? { color: '#ffffff' } : { color: '#ff5e3a' }} />
              </Button>
            </Link>}
            <Popconfirm
              placement='top'
              title='Are sure you want to delete this?'
              onConfirm={() => this.props.onConfirmDeleteItem(itemDetails.id)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                size='small' type='primary'
                style={(selectedId === itemDetails.id) ? { backgroundColor: '#ffffff', borderColor: '#ffffff', marginLeft: 2 } : { backgroundColor: '#F95C38', borderColor: '#F95C38', marginLeft: 2 }}
              >
                <FaTrash style={(selectedId !== itemDetails.id) ? { color: '#ffffff' } : { color: '#ff5e3a' }} />
              </Button>
            </Popconfirm>
          </Col>}
        </div>
      </Row>
    );
  }
}

export default VariantItemRow;
