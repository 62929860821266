import React, { Component } from 'react';
import { Col, Input, Row, Select, Button } from 'antd';
import { FaTrash } from 'react-icons/all';
import deepcopy from 'deepcopy';

class VariantOption extends Component {
  renderOptions = object => {
    const options = [];
    Object.values(object).forEach(value => {
      options.push(value);
    });
    return options;
  }

  getInitialStateVariantOptionTypeLength = () => {
    const { item, variantOptionType } = this.props;
    let optionsLength = 0;

    const variant = item && item.variant && deepcopy(item.variant);
    const variantOptionTypes = variant && variant.definition && variant.definition.variantOptionTypes && Object.keys(variant.definition.variantOptionTypes).length > 0 &&
      Object.values(variant.definition.variantOptionTypes).filter(option => option.id === variantOptionType.id);

    if (variantOptionTypes && variantOptionTypes.length > 0 && variantOptionTypes[0].options) {
      optionsLength = Object.keys(variantOptionTypes[0].options).length;
    }
    return optionsLength;
  }

  render () {
    const { variantOptionType, onChangeDefinition, onRemoveVariantOptionType } = this.props;
    const { id, name } = variantOptionType;
    const optionList = variantOptionType && variantOptionType.options && Object.values(variantOptionType.options);
    const optionLengthDiff = this.getInitialStateVariantOptionTypeLength() - optionList.length;
    console.log(this.getInitialStateVariantOptionTypeLength(), name, optionList, optionLengthDiff);
    return (
      <Row gutter={10} style={{ padding: '10px 0px', marginTop: 10, borderTop: '1px solid #F5F5F5' }}>
        <p style={styles.optionTitle}>Option Type</p>
        <Col span={5}>
          <Input placeholder='Type' value={name} onChange={(value) => onChangeDefinition(id, 'type', value.target.value)} />
        </Col>
        <Col span={optionList && optionList.length === 1 ? 18 : 18}>
          {optionLengthDiff === 1 && <Select
            removeIcon={<div />}
            mode='tags'
            style={{ width: '100%' }}
            value={this.renderOptions(variantOptionType.options)}
            dropdownStyle={{ display: 'none' }}
            tokenSeparators={[',']}
            placeholder='Variants separated with a comma'
            onChange={null}
            onDeselect={null}
          />}
          {optionLengthDiff !== 1 && <Select
            // removeIcon={optionList && optionList.length === 1 && <div></div>}
            mode='tags'
            style={{ width: '100%' }}
            value={this.renderOptions(variantOptionType.options)}
            dropdownStyle={{ display: 'none' }}
            tokenSeparators={[',']}
            placeholder='Variants separated with a comma'
            onChange={(value) => { onChangeDefinition(id, 'options', value, 'change'); }}
            onDeselect={(value) => { onChangeDefinition(id, 'options', value, 'remove'); }}
                                     />}

        </Col>
        {this.getInitialStateVariantOptionTypeLength() === 1 && <Col span={1}>
          <Button
            size='small'
            type='primary'
            style={{ backgroundColor: '#F95C38', borderColor: '#F95C38', marginLeft: 2 }}
            onClick={() => onRemoveVariantOptionType(id)}
          >
            <FaTrash style={{ color: '#ffffff' }} />
          </Button>
        </Col>}
        {(name === '') && <Col span={1}>
          <Button
            size='small'
            type='primary'
            style={{ backgroundColor: '#F95C38', borderColor: '#F95C38', marginLeft: 2 }}
            onClick={() => onRemoveVariantOptionType(id)}
          >
            <FaTrash style={{ color: '#ffffff' }} />
          </Button>
        </Col>}
      </Row>
    );
  }
}
const styles = {
  optionTitle: {
    fontWeight: 500,
    fontSize: 14,
    padding: 5,
    marginBottom: 0
  }
};
export default VariantOption;
