import Api from './consts';
import fetch from './commonFetch';

export const add = (companyId, data) => {
  const { url, method, headers } = Api.transferNote.add;
  const body = JSON.stringify(data);
  return fetch(url(companyId), { method, headers, body }, 'transferNoteData');
};

export const get = (companyId, transferNoteId) => {
  const { url, method, headers } = Api.transferNote.get;

  return fetch(url(companyId, transferNoteId), { method, headers }, 'transferNote');
};

export const update = (companyId, transferNoteId, data) => {
  const { url, method, headers } = Api.transferNote.update;
  const body = JSON.stringify(data);
  return fetch(url(companyId, transferNoteId), { method, headers, body }, 'transferNoteData');
};

export const confirm = (companyId, transferNoteId, data) => {
  const { url, method, headers } = Api.transferNote.confirmTransferNote;
  const body = JSON.stringify(data);
  return fetch(url(companyId, transferNoteId), { method, headers, body }, 'transferNoteData');
};

export const verify = (companyId, transferNoteId, data) => {
  const { url, method, headers } = Api.transferNote.verifyTransferNote;
  const body = JSON.stringify(data);
  return fetch(url(companyId, transferNoteId), { method, headers, body }, 'transferNoteData');
};

export const complete = (companyId, transferNoteId) => {
  const { url, method, headers } = Api.transferNote.completeTransferNote;

  return fetch(url(companyId, transferNoteId), { method, headers }, 'transferNoteData');
};

export const advancedSearch = query => {
  const { url, method } = Api.transferNote.advancedSearch;
  const body = JSON.stringify(query);
  return fetch(url(), { body, method }, 'transferNoteData');
};

export const removeTransferNote = (companyId, transferNoteId) => {
  const { url, method, headers } = Api.transferNote.delete;

  return fetch(url(companyId, transferNoteId), { method, headers }, 'transferNoteData');
};
