import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Spin } from 'antd';
import { updateCategory, fetchCategory } from '../../actions/categories';
import { getCategory, getCompanyById } from '../../reducers';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import closeIcon from '../../image/close-icon.png';
const { TextArea } = Input;

class EditCategoryPopup extends Component {
  state = {
    name: '',
    description: '',
    code: '',
    imageSrc: undefined,
    isRawMaterial: undefined,
    displayInOnlineMenu2: undefined,
    nameStatus: undefined,
    descriptionStatus: undefined,
    submitting: false
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { category } = nextProps;
    this.setCategoryDetails(category);
  }

  setCategoryDetails = category => {
    console.log('DATA ', category);
    category && this.setState({
      name: category.name,
      code: category.code,
      description: category.description ? category.description : '',
      imageSrc: category.imageSrc,
      isRawMaterial: category.hideFromUsers,
      displayInOnlineMenu2: category.displayInOnlineMenu2
    });
  }

  handleImageChange = src => {
    this.setState({ imageSrc: src });
  }

  handleOnChange = (field, value) => {
    switch (field) {
      case 'hideFromUsers':
        this.setState({ isRawMaterial: value });
        break;
      case 'name':
        this.setState({ name: value, nameStatus: this.getValidationStatus('name', value) });
        break;
      case 'code':
        this.setState({ code: value, codeStatus: this.getValidationStatus('code', value) });
        break;
      case 'description':
        this.setState({ description: value, descriptionStatus: this.getValidationStatus('description', value) });
        break;
      case 'displayInOnlineMenu2':
        this.setState({ displayInOnlineMenu2: value });
        break;
      default:
    }
  }

  handleSubmit = () => {
    if (!this.validateForm()) return;

    const { name, code, description, isRawMaterial, displayInOnlineMenu2 } = this.state;
    let obj = {
      name: name,
      code: code,
      description: description,
      hideFromUsers: isRawMaterial,
      displayInOnlineMenu2: displayInOnlineMenu2
    };

    if (this.state.imageSrc !== undefined) {
      obj = {
        ...obj,
        imageSrc: this.state.imageSrc
      };
    } else {
      obj = {
        ...obj,
        imageSrc: ''
      };
    }

    const { categoryId } = this.props;
    if (categoryId) {
      this.handleUpdateCategory(obj);
    } else {
      this.handleAddCategory(obj);
    }
  }

  handleUpdateCategory = (obj) => {
    const { companyId, categoryId, onModelActions, onRefreshMenuList } = this.props;

    this.setState({ submitting: true });
    this.props.updateCategory(companyId, categoryId, obj).then(response => {
      console.log('Response ', response);
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating category', error);
        this.setState({ submitting: false });
        onModelActions(false);
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategory(companyId, categoryId).then(() => {
          this.setState({ submitting: false });
        });
        this.setState({ submitting: false });
        showSuccessNotification('Category updated.', 'Category updated successfully.');
        onModelActions(false);
        onRefreshMenuList();
      }
    });
  }

  handleAddCategory = (obj) => {
    const { companyId, parentCategoryId, company, onClose, onModelActions } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;

    const newCategory = {
      ...obj,
      businessId: businessId
    };

    this.setState({ submitting: true, loading: true });
    this.props.addCategory(companyId, parentCategoryId, newCategory).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed adding category', error);
        this.setState({ submitting: false, loading: false });
        onModelActions(false);
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        this.props.fetchCategories(companyId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ submitting: false, loading: false });
        showSuccessNotification('Category added', 'Category added successfully.');
        onClose();
        onModelActions(false);
      }
    });
  }

  validateForm = () => {
    const { name, code } = this.state;
    const validForm = this.isValidName(name) &&
      this.isValidCode(code);

    if (!validForm) {
      this.setState({
        nameStatus: this.getValidationStatus('name', name),
        codeStatus: this.getValidationStatus('code', code)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      case 'code':
        return this.isValidCode(value) ? 'success' : 'error';

      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;
  isValidCode = code => code && code.length > 0;

  render() {
    const { modelVisible, onModelActions, companyId, categoryId } = this.props;
    const { imageSrc, isRawMaterial, name, description, code, codeStatus, nameStatus, descriptionStatus, submitting, displayInOnlineMenu2 } = this.state;
    return (
      <Modal
        title='Edit Category'
        centered
        width={600}
        visible={modelVisible}
        onOk={() => console.log('Save')}
        closeIcon={<img alt='fooder close icon' src={closeIcon} width={42} />}
        onCancel={() => onModelActions(false)}
        footer={
          <div>
            <Button type='primary' className='btn-orange' loading={submitting} onClick={() => this.handleSubmit()}>Save</Button>
            <Button type='default' loading={submitting} onClick={() => onModelActions(false)}>Cancel</Button>
          </div>
        }
      >
        <Row>
          <Spin spinning={submitting}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <ImageUploadContainer
                size={150}
                folder={'/' + companyId + '/categories/' + categoryId}
                src={imageSrc}
                onImageChange={this.handleImageChange}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form layout='vertical' labelAlign='left'>
                <Form.Item label='Name' validateStatus={nameStatus}>
                  <Input value={name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                </Form.Item>
                <Form.Item label='Code' className='category-form' validateStatus={codeStatus}>
                  <Input value={code} onChange={(event) => this.handleOnChange('code', event.target.value)} />
                </Form.Item>
                <Form.Item label='Description' className='category-form' validateStatus={descriptionStatus}>
                  <TextArea rows={4} value={description} onChange={(event) => this.handleOnChange('description', event.target.value)} />
                </Form.Item>
                <Form.Item>
                  <Checkbox checked={isRawMaterial} onChange={(e) => this.handleOnChange('hideFromUsers', e.target.checked)}>Raw Material</Checkbox>
                </Form.Item>
                {/* <Form.Item>
                  <Checkbox checked={displayInOnlineMenu2 !== null ? displayInOnlineMenu2 : false} onChange={(e) => this.handleOnChange('displayInOnlineMenu2', e.target.checked)}>Visible online</Checkbox>
                </Form.Item> */}
              </Form>
            </Col>
          </Spin>
        </Row>
      </Modal>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { categoryId, companyId } = props;

  return {
    category: getCategory(state, categoryId),
    company: getCompanyById(state, companyId)
  };
};
export default connect(mapStateToProps, { updateCategory, fetchCategory })(EditCategoryPopup);
