import React, { Component } from 'react';
import { Table, Tag, Row, Col, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
import './tableStyles.css';

const dateFormat = 'DD-MM-YYYY';

class TranferNoteList extends Component {
  getColumns = () => {
    const { handleDownloadReportClick, business } = this.props;
    const columns = [
      {
        title: 'Transfer Note No.',
        dataIndex: 'number',
        key: 'number',
        render: text => <p>{text}</p>
      },
      {
        title: 'Transfer Date',
        dataIndex: 'transferDate',
        key: 'transferDate',
        render: text => <p>{moment(text).format(dateFormat)}</p>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: text => <Tag color='#108ee9'>{text}</Tag>
      },
      {
        title: 'No of orders',
        dataIndex: 'orders',
        key: 'orders',
        align: 'center',
        render: orders => <p>{orders ? orders.length : 0}</p>
      },
      {
        title: 'Reference No.',
        dataIndex: 'transferReferenceNumber',
        key: 'transferReferenceNumber',
        render: (transferReferenceNumber) => <p>{transferReferenceNumber || ''}</p>
      },
      {
        title: 'Bank Account No.',
        dataIndex: 'bankAccountNumber',
        key: 'bankAccountNumber',
        align: 'center',
        render: (bankAccountNumber) => <p>{bankAccountNumber || ''}</p>
      },
      {
        title: 'Account Holder Name',
        dataIndex: 'bankAccountHolderName',
        key: 'bankAccountHolderName',
        align: 'center',
        render: (bankAccountHolderName) => <p>{bankAccountHolderName || ''}</p>
      },
      {
        title: 'Bank',
        dataIndex: 'bank',
        key: 'bank',
        align: 'center',
        render: (bank) => <p>{bank || ''}</p>
      },
      {
        title: 'Bank Branch',
        dataIndex: 'bankBranch',
        key: 'bankBranch',
        align: 'center',
        render: (bankBranch) => <p>{bankBranch || ''}</p>
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        className: 'amountClass',
        fixed: 'right',
        align: 'right',
        render: total => <p>{total && format(total, currencyFormat(business))}</p>
      }
    ];

    const actions = {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      width: 140,
      fixed: 'right',
      align: 'right',
      render: (id, dataRow) => {
        const isConfirmed = (dataRow.status === 'CONFIRMED');
        return <Row>
          <Col span={24}>
            {(isConfirmed) && <Link to={dataRow && dataRow.id ? '/transferNotes/' + dataRow.id : '/transferNotes/'}>
              <Tooltip placement='top' title='Edit Transfer Note'>
                <Button style={{ marginRight: 2, backgroundColor: 'rgb(255, 94, 58)', borderColor: 'rgb(255, 94, 58)' }} className='tableButton' type='primary' size='small' icon='edit' />
              </Tooltip>
            </Link>}
            {!isConfirmed && <Link to={dataRow && dataRow.id ? '/transferNotes/' + dataRow.id : '/transferNotes/'}>
              <Tooltip placement='top' title='View Transfer Note'>
                <Button style={{ marginRight: 2 }} className='tableButton' type='primary' size='small' icon='eye' />
              </Tooltip>
            </Link>}
            <Tooltip placement='top' title='Download as Excel'>
              <Button style={{ marginRight: 2 }} onClick={() => handleDownloadReportClick(dataRow)} className='tableButton primary-button' type='primary' size='small' icon='download' />
            </Tooltip>
          </Col>
        </Row>;
      }
    };
    columns.push(actions);

    return columns;
  }

  renderStatusColor = status => {
    switch (status) {
      case 'DELETED':
        return '#ff0000';
      case 'PENDING':
        return '#fa8c16';
      case 'COMPLETED':
        return '#52c41a';
      default:
        return '';
    }
  }

  getReceiptTotals = receipts => {
    let totalAmount = 0;

    receipts.forEach(receipt => {
      totalAmount = totalAmount + receipt.amount;
    });
    return { total: totalAmount, count: receipts.length };
  }

  getCommissionTotal = transferNotes => {
    let totalAmount = 0;

    transferNotes.forEach(order => {
      if (order.commission) {
        totalAmount = totalAmount + order.commission.value;
      }
    });
    return totalAmount;
  }

  generateTableFooter = (totals, commissionTotal) => {
    const { business, companyId } = this.props;
    // const element = document.getElementsByClassName('amountClass');
    // const amountElement = element.item(0);
    const { total, count } = totals;
    return window.innerWidth > 865 ? <div>
      <p style={{ position: 'absolute', padding: 4 }}><b style={{ paddingLeft: 10 }}>Total of {count} Records</b></p>
      <p style={{ float: 'right', marginRight: 140, textAlign: 'right', padding: '5px 20px' }}>{format(total, currencyFormat(business))}</p>
    </div>
      : <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
        <p>No of Records : {count}</p>
        <p>Total Amount : <b>{format(total, currencyFormat(business))}</b></p>
      </div>;
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.props.onRowSelection(selectedRowKeys, selectedRows);
  }

  getRowSelection = () => {
    const { transferNotes = [], selectedRowKeys, selectedRows, onRowSelection } = this.props;

    return {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'all-data',
          text: 'Select All Data',
          onSelect: () => {
            const allRowKeys = transferNotes.map(order => order.id);
            const allRows = transferNotes.map(order => order);
            onRowSelection(allRowKeys, allRows);
          }
        }
      ]
    };
  }

  render() {
    const { transferNotes = [], loading } = this.props;

    const totals = this.getReceiptTotals(transferNotes);
    const commissionTotal = this.getCommissionTotal(transferNotes);
    const columns = this.getColumns();

    return (
      <div>
        <Table
          columns={columns}
          dataSource={transferNotes}
          rowKey='id'
          loading={loading}
          // rowSelection={enableRowSelection && this.getRowSelection()}
          pagination
          footer={() => this.generateTableFooter(totals, commissionTotal)}
          scroll={{ x: 1800 }}
        />
      </div>
    );
  }
}

export default TranferNoteList;
