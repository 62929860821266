import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Spin, Row, Button, Table, Empty, Col, Tooltip } from 'antd';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
import './index.css';

class InvoiceListTable extends Component {

  getColumn = () => {
    const { business } = this.props;
    const { fixAmount = false, fixOutstanding = false, enableActions } = this.props;
    const columns = [{
      title: 'Invoice ID',
      dataIndex: 'number',
      key: 'number',
      width: '10%',
      render: text => <p>{text}</p>
    }, {
      title: 'Invoice Date',
      dataIndex: 'date',
      key: 'date',
      width: '12%',
      render: date => <p>{date && moment(date).format('DD-MM-YYYY')}</p>
    }, {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '12%',
      render: dueDate => <p>{dueDate && moment(dueDate).format('DD-MM-YYYY')}</p>
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '12%',
      render: text => text && <Tag style={{ fontSize: 10 }} color={this.renderStatusColor(text)}>{text}</Tag>
    }, {
      title: 'Amount',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: (!enableActions && fixAmount) ? 120 : '12%',
      className: 'amountClass',
      fixed: fixAmount && 'right',
      render: total => <p>{format(total, currencyFormat(business))}</p>
    }, {
      title: 'Outstanding',
      dataIndex: 'balanceToSettle',
      key: 'balanceToSettle',
      align: 'right',
      width: (!enableActions && fixOutstanding) ? 130 : '12%',
      fixed: fixOutstanding && 'right',
      className: 'outstandingClass',
      render: balanceToSettle => <p>{format(balanceToSettle, currencyFormat(business))}</p>
    }
    ];

    const actions = {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      width: '12%',
      render: (id, dataRow) => {
        const isDeleted = (dataRow.status === 'DELETED');
        const outstandingAvailable = (dataRow.balanceToSettle > 0);
        return <Row>
          <Col span={24}>
            {!isDeleted && outstandingAvailable && <Link to='/payments/invoiceSettlement'>
              <Tooltip placement='top' title='Payments'>
                <Button className='tableButton' size='small' icon='dollar' style={{ backgroundColor: '#409a40', color: 'white' }} />
              </Tooltip>
            </Link>}
            <Link to={'/invoice/print/' + dataRow.id + '/' + dataRow.customerId}>
              <Button className='tableButtonPrint' size='small' icon='printer' />
            </Link>
            {/* {dataRow.emailSentCount > 0 &&
                            <Tooltip placement="top" title={"Resend Email"}>
                                <Button
                                className={'tableButton'}
                                size={'small'}
                                icon={'mail'}
                                style={{backgroundColor: '#409a40', color: 'white'}}
                                onClick={() => this.props.handleOnClickSendEmail([{ userId: dataRow.customerId, invoiceIds: [dataRow.id] }])}
                                loading={this.props.emailSending === id}/>
                            </Tooltip>}
                        {!isDeleted && <Link to={'#'}>
                            <Tooltip placement="top" title={"Edit"}>
                                <Button className={'tableButton'} type={'primary'} size='small' icon={'edit'}/>
                            </Tooltip>
                        </Link>}
                        {!isDeleted && <Popconfirm
                          title="Are you sure ?"
                          okText="DELETE"
                          cancelText="NO"
                          onConfirm={() => onClickDelete(dataRow.id, dataRow.customerId)}
                        >
                            <Tooltip placement="top" title={"Delete"}>
                                <Button className={'tableButton'} type={'danger'} size={'small'} icon="delete"/>
                            </Tooltip>
                        </Popconfirm>} */}
          </Col>
        </Row>;
      }
    };

    this.props.enableActions && columns.push(actions);

    return columns;
  }

  renderStatusColor = status => {
    switch (status) {
      case 'DELETED':
        return '#ff0000';
      case 'PENDING':
        return '#fa8c16';
      case 'COMPLETED':
        return '#52c41a';
      default:
        return '';
    }
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.props.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        const invoiceIds = selectedRowKeys;
        const emailRequestList = [];

        selectedRows.forEach(invoice => {
          const customerId = invoice.customerId;
          const ids = [invoice.id];

          let emailRequest;
          let emailRequestIndex;
          emailRequestList.forEach((request, index) => {
            if (request.userId === customerId) {
              emailRequest = request;
              emailRequestIndex = index;
            }
          });

          if (emailRequestIndex && emailRequest) {
            emailRequest.invoiceIds.push(invoice.id);
            emailRequestList[emailRequestIndex] = emailRequest;
          } else {
            emailRequestList.push({ userId: customerId, invoiceIds: ids });
          }
        });

        this.props.onClick(invoiceIds, emailRequestList, selectedRowKeys, selectedRows);
      }
    };
  }

  getInvoiceTotals = invoiceList => {
    let totalAmount = 0;
    let totalOutstanding = 0;

    invoiceList && invoiceList.forEach(invoice => {
      if (invoice) {
        totalAmount = totalAmount + invoice.total;
        totalOutstanding = totalOutstanding + invoice.balanceToSettle;
      }
    });
    return { totalAmount, totalOutstanding, count: invoiceList && invoiceList.length };
  }

  generateTableFooter = (totals) => {
    const { business } = this.props;
    const amountelements = document.getElementsByClassName('amountClass');
    const outstandingElements = document.getElementsByClassName('outstandingClass');
    const amountElement = amountelements.item(0);
    const outstandingElement = outstandingElements.item(0);
    const { totalAmount, totalOutstanding, count } = totals;
    return window.innerWidth > 865 ? <div>
      <p style={{ position: 'absolute', padding: 2 }}><b style={{ paddingLeft: 10 }}>Total of {count} Records</b></p>
      <div style={{ marginLeft: amountElement && amountElement.offsetLeft, width: amountElement && outstandingElement && outstandingElement.offsetWidth + amountElement.offsetWidth, display: 'flex' }}>
        <p style={{ width: outstandingElement && outstandingElement.offsetWidth, textAlign: 'right', paddingRight: 30 }}>{format(totalAmount, currencyFormat(business))}</p>
        <p style={{ width: amountElement && amountElement.offsetWidth, textAlign: 'right', paddingRight: 30 }}>{format(totalOutstanding, currencyFormat(business))}</p>
      </div>
      {/* <p style={{marginLeft: amountElement && amountElement.offsetLeft, width: amountElement && amountElement.offsetWidth, textAlign: 'right', padding: '5px 15px'}}>{format(totalAmount, currencyFormat)}</p> */}
    </div>
      : <div style={{ textAlign: 'right', fontSize: '0.9em', paddingRight: 10 }}>
        <p>No of Records : {count}</p>
        <p>Total Amount : <b>{format(totalAmount, currencyFormat(business))}</b></p>
        <p>Total Outstanding : <b>{format(totalOutstanding, currencyFormat(business))}</b></p>
      </div>;
  }

  // generateTableFooter = (invoiceList, totals, columns) => {
  //     let totalAlignment = 0;
  //     for (let i = 0; i <= 6; i++) {
  //         totalAlignment = totalAlignment + columns[i].width
  //     }

  //     if (columns.find(column => column.title === 'Actions')) {
  //         return window.innerWidth > 360 ? <p><b>Total of {invoiceList.length} Records</b><span style={{ position: 'absolute', left: totalAlignment - 120, width: 'fit-content' }}><b>{format(totals.totalAmount, currencyFormat)}</b></span><span style={{ position: 'absolute', left: totalAlignment - 10, width: 'fit-content' }}><b>{format(totals.totalOutstanding, currencyFormat)}</b></span></p> : <p><b><span>Total Amount : {format(totals.totalAmount, currencyFormat)}</span><br /><span>Total Outstanding : {format(totals.totalOutstanding, currencyFormat)}</span></b></p>
  //     } else {
  //         return <p><b>Total of {invoiceList.length} Records</b><span style={{ position: 'absolute', left: totalAlignment - 120, width: 'fit-content' }}><b>{format(totals.totalAmount, currencyFormat)}</b></span><span style={{ position: 'absolute', left: totalAlignment - 10, width: 'fit-content' }}><b>{format(totals.totalOutstanding, currencyFormat)}</b></span></p>
  //     }
  // }

  render() {
    const { enableRowSelection, invoices, invoicesLoading, showTotals } = this.props;
    // const invoiceList = showOutstandingInvoices ? invoices.filter(invoice => invoice.balancetosettle > 0) : invoices
    const totals = this.getInvoiceTotals(invoices);
    const columns = this.getColumn();
    // const showLoader = (!invoices && invoicesLoading) || (invoicesLoading && (invoices && invoices.length === 0));

    return (
      (!invoicesLoading && (invoices && invoices.length === 0)) ? <Empty />
        : <Spin spinning={invoicesLoading} tip='Loading...'>
          <Table
            columns={columns}
            dataSource={invoices}
            rowKey='id'
            rowSelection={enableRowSelection && this.getRowSelection()}
            pagination
            scroll={{ x: 800 }}
            style={{ backgroundColor: 'white' }}
            footer={() => showTotals && this.generateTableFooter(totals)}
          />
        </Spin>
    );
  }
}

export default InvoiceListTable;
