import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import SideBar from '../../components/SideBar';
import OrderList from './OrderList';
import { fetchCompany } from '../../actions/companies';
import { getAuthenticity, getCompanyById } from '../../reducers';

class Orders extends Component {
  render () {
    const { company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return (
      <div className='container'>
        {business && !business.active && <p style={{ display: 'flex', justifyContent: 'center', margin: 0, padding: 0, color: 'red' }}>This business is no longer active.</p>}
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Col lg={3} md={3} sm={24} xs={24}>
              <h3 style={{ fontWeight: 400, fontSize: 24 }}>Orders</h3>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <OrderList />
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  return ({
    companyId: companyId,
    company: getCompanyById(state, companyId)
  });
};
export default connect(mapStateToProps, { fetchCompany })(Orders);
