import React, { Component } from 'react';
import { Row, Spin, Table, Modal, Form, Tag } from 'antd';
import { connect } from 'react-redux';
import { fetchCompanyDeviceList, activeDeactiveDevice, fetchCompanySubscriptions, fetchSubscriptionDevice, updateDeviceSubscription, removeDeviceSubscription, modifyDevice } from '../../../actions/companies';
import { getAuthenticity, getDevicesByCompanyId, getSubscriptionsByCompanyId } from '../../../reducers';
import { showLoadingMessage } from '../../../helpers/Message';
import { showErrorNotification, showSuccessNotification } from '../../../components/Notification';
import moment from 'moment';
import withRouter from 'react-router/es/withRouter';
import EditableCellDevice from './EditableCellDevice.js';
import { fetchOrders } from '../../../actions/Orders';
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class DeviceList extends Component {
  state = {
    loading: false,
    textFilter: undefined,
    devices: undefined,
    device: undefined,
    modalVisible: false,
    selectedSubscriptionId: undefined,
    selectedRawDeviceId: undefined
  }

  componentDidMount = () => {
    const { companyId } = this.props;
    if (this.props.devices && this.props.devices.length > 0) {
      this.showLoadingMessage();
      this.props.fetchCompanyDeviceList(companyId).then(() => {
        this.props.fetchCompanySubscriptions(companyId).then(() => {
          this.hideLoadingMessage();
        });
      });
    } else {
      this.setState({ loading: true });
      this.props.fetchCompanyDeviceList(companyId).then(() => {
        this.props.fetchCompanySubscriptions(companyId).then(() => {
          this.setState({ loading: false });
        });
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Devices.');
  }

  getColumns = () => {
    const columns = [
      {
        title: 'Device ID',
        dataIndex: 'deviceId',
        key: 'deviceId',
        render: text => <p>{text}</p>
      },
      {
        title: 'Display Name',
        dataIndex: 'displayName',
        key: 'displayName',
        editable: true
      },
      // {
      //   title: 'Android ID',
      //   dataIndex: 'androidId',
      //   key: 'androidId',
      //   render: text => <p>{text}</p>,
      // },
      {
        title: 'Manufacture',
        dataIndex: 'manufacture',
        key: 'manufacture',
        render: text => <p>{text}</p>
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        render: text => <p>{text}</p>
      },
      // {
      //   title: 'Initial Activated Date',
      //   dataIndex: 'initiallyActivatedDate',
      //   key: 'initiallyActivatedDate',
      //   render: date => <p>{date && moment(date).format('DD-MM-YYYY')}</p>,
      // },
      {
        title: 'Last Activated Date',
        dataIndex: 'lastActivatedDate',
        key: 'lastActivatedDate',
        render: date => <p>{date && moment(date).format('DD-MM-YYYY')}</p>
      },
      {
        title: 'Subscription',
        dataIndex: 'subscriptions2',
        key: 'subscriptions2',
        render: (subscriptions, rawData) => this.renderSubscriptions(subscriptions, rawData)
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        render: flag => <Tag style={{ backgroundColor: flag ? 'green' : 'red', color: '#FFFFFF' }}>{flag ? 'ACTIVE' : 'INACTIVE'}</Tag>
      }
      // {
      //   title: 'Actions',
      //   dataIndex: 'isActive',
      //   key: 'isActive',
      //   align: 'right',
      //   render: (isActive, rowData) => (
      //     <Row>
      //       <Popconfirm
      //         title={isActive ? "Deactivate ?" : "Activate ?"}
      //         okText="YES"
      //         cancelText="NO"
      //         onConfirm={() => this.onActiveDeactiveDevice(rowData.deviceId, !isActive)}
      //       >
      //         <Button className={'tableButton'} type={isActive ? 'danger' : 'primary'} size={'small'}>{isActive ? "Deactivate" : "Activate"}</Button>
      //       </Popconfirm>
      //     </Row>
      //   ),
      // }
    ];
    return columns.map(col => {
      if (!col.editable) return col;
      return {
        ...col,
        onCell: record => (
          {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleEditableCellChangeSave,
            Context: EditableContext
          }
        )
      };
    });
  }

  handleEditableCellChangeSave = (record, value) => {
    console.log(record);
    this.onUpdateDevice(record);
  }

  onUpdateDevice = (updatedObject) => {
    const { companyId } = this.props;
    // const businessId = company && company.businesses[0] && company.businesses[0].id;
    var deviceObj = {
      deviceId: updatedObject.id,
      displayName: updatedObject.displayName,
      isActive: updatedObject.isActive
    };
    console.log(deviceObj);

    this.setState({ submitting: true, loading: true });
    this.props.modifyDevice(companyId, updatedObject.id, deviceObj).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating Device', error);
        this.setState({ submitting: false, loading: false });
      } else {
        this.setState({ loading: false });
        showSuccessNotification('Device updated.', 'Device updated successfully.');
        this.props.fetchOrders(companyId).then((response) => {
          console.log(response);
        });
      }
    });
  }

  handleChange = (value, rawData) => {
    const { companyId } = this.props;
    // console.log(`selected ${value}`, rawData);
    this.setState({ loading: true });
    this.props.fetchSubscriptionDevice(companyId, value).then(response => {
      if (response.device) {
        // console.log(response.device[0]);
        this.setState({ failedStatus: 'success', loading: false });
        if (response.device.length > 0) {
          this.setState({ modalVisible: true, device: response.device[0], selectedRawDeviceId: rawData.id, selectedSubscriptionId: value });
        } else {
          this.addSubscriptionToDevice(value, rawData.deviceId);
        }
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
      }
    });
    // get the current subscription
    // show confirmation dialog with current device
  }

  addSubscriptionToDevice = (subscriptionId, deviceId) => {
    const { companyId } = this.props.match.params;
    // console.log(" data " + companyId, deviceId, subscriptionId);
    this.setState({ loading: true });
    this.props.updateDeviceSubscription(companyId, deviceId, subscriptionId).then(response => {
      if (response.device) {
        this.setState({ failedStatus: 'success', loading: false });
        showSuccessNotification('Updated', 'Device subscription updated successfully.');
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
        showErrorNotification('Failed to update', response.error);
      }
    });
  }

  handleModalOk = e => {
    const { companyId } = this.props.match.params;
    // console.log(" data " + companyId, this.state.selectedRawDeviceId, this.state.selectedSubscriptionId);
    this.setState({ loading: true, modalVisible: false });
    this.props.removeDeviceSubscription(companyId, this.state.device.deviceId).then(response => {
      if (response.device) {
        this.setState({ failedStatus: 'success', loading: false });
        this.addSubscriptionToDevice(this.state.selectedSubscriptionId, this.state.selectedRawDeviceId);
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
        showErrorNotification('Failed to update Device Subscription.', response.error);
      }
    });
  };

  handleModalCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  onActiveDeactiveDevice = (deviceId, status) => {
    const { companyId } = this.props;

    this.setState({ loading: true });
    this.props.activeDeactiveDevice(companyId, deviceId, { status }).then(response => {
      if (response.device) {
        this.setState({ failedStatus: 'success', loading: false });
        showSuccessNotification('Updated', 'Device status updated successfully.');
      } else {
        this.setState({ failedStatus: 'failed', loading: false });
        showErrorNotification('Failed to update', response.error);
      }
    });
  }

  getFilteredDevices = () => {
    const { devices, textFilter } = this.props;

    let filteredDevicesList = devices;

    if (textFilter) {
      const filterText = textFilter.toLowerCase();
      filteredDevicesList = devices && devices.length > 0 && devices.filter((device) => {
        const subscriptionObj = Object.values(device.subscriptions2)[0];
        if (this.matchStrings(device.deviceId, filterText) || (device.model &&  this.matchStrings(device.model, filterText))
          || (device.displayName && this.matchStrings(device.displayName, filterText)) || (device.manufacture && this.matchStrings(device.manufacture, filterText))
        ) {
          return true;
        } else {
          if (subscriptionObj) {
            // console.log(" subscription code 2 ",subscriptionObj.subscriptionCode)
            return (this.matchStrings(subscriptionObj.subscriptionCode, filterText));
          } else {
            return false;
          }
        }
      });
    }

    return filteredDevicesList;
  }

  matchStrings = (string1, string2) => string1.toLowerCase().includes(string2);

  renderModal = () => {
    const { device, modalVisible } = this.state;
    if (device === undefined) {
      return null;
    } else {
      return (
        <Modal
          title='Basic Modal'
          visible={modalVisible}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
        >
          <p>This subscription is already used by  Device {device.deviceId}, {device.manufacture} {device.model}. It will be deactivated if you continue. Do you want to continue?</p>
        </Modal>
      );
    }
  }

  renderSubscriptions = (currentSubscriptions, rawData) => {
    // const { subscriptions } = this.props;
    var currentSubscription = {};
    currentSubscriptions && Object.keys(currentSubscriptions).map(function (key) {
      return currentSubscription = currentSubscriptions[key];
    });

    var currentId = currentSubscription.subscriptionCode;
    // var allSubscriptions = Object.keys(subscriptions).map(key => subscriptions[key]).filter(subscription => subscription.type === "KIOSK").map(function (subscription) {
    //   return (
    //     <option value={subscription.id}>{subscription.subscriptionCode}</option>
    //   )
    // });

    return (
      // <Select value={currentId} style={{ width: 80 }} onSelect={(value) => this.handleChange(value, rawData)}>
      //   {allSubscriptions}
      // </Select>
      <p>{currentId}</p>
    );
  }

  render() {
    const { loading } = this.state;
    const { devices } = this.props;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCellDevice
      }
    };

    return (
      <Spin spinning={loading && devices && devices.length === 0}>
        {this.renderModal()}
        <Row>
          <Spin spinning={loading && devices && devices.length === 0}>
            <Table columns={this.getColumns()} dataSource={this.getFilteredDevices()} components={components} />
          </Spin>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authenticity = getAuthenticity(state);
  const { user: { userId: companyId } } = authenticity;
  return ({ devices: getDevicesByCompanyId(state, companyId), subscriptions: getSubscriptionsByCompanyId(state, companyId), companyId });
};

export default withRouter(connect(mapStateToProps, { fetchCompanyDeviceList, activeDeactiveDevice, fetchCompanySubscriptions, fetchSubscriptionDevice, updateDeviceSubscription, removeDeviceSubscription, modifyDevice, fetchOrders })(DeviceList));
