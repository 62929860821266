import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTIONS': {
      const newState = {};
      action.subscriptions.forEach(subscription => {
        newState[subscription.id] = subscription;
      });
      return newState;
    }
    case 'FETCH_SUBSCRIPTION': {
      const { subscription } = action;
      return { ...state, [subscription.id]: subscription };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTIONS':
      return action.subscriptions.map(subscription => subscription.id);
    case 'FETCH_SUBSCRIPTION':
      if (state.includes(action.subscription.id)) {
        return state;
      } else {
        return [...state, action.subscription.id];
      }
    default:
      return state;
  }
};

const byCompanyId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_SUBSCRIPTIONS': {
      const newState = { ...state };
      newState[action.companyId] = action.subscriptions.map(subscription => subscription.id);
      return newState;
    }

    case 'FETCH_SUBSCRIPTION': {
      const newState = { ...state };
      const companySubscriptionIds = newState[action.companyId];
      if (companySubscriptionIds.includes(action.subscription.id)) {
        return newState;
      } else {
        newState[action.companyId] = [...companySubscriptionIds, action.subscription.id];
        return newState;
      }
    }
    default:
      return state;
  }
};

const devices = combineReducers({
  byId,
  ids,
  byCompanyId
});

export default devices;

export const getSubscriptions = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getSubscriptionsByCompanyId = (state, companyId) => {
  const subscriptionIds = state.byCompanyId[companyId];
  return subscriptionIds && subscriptionIds.length > 0 ? subscriptionIds.map(id => state.byId[id]) : [];
};
