import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import './salesReports.css'
import ViewTable from './ViewTable'

const ViewTables = (props) => {

  const { tableColumns, tablesData, filterGroup, loading } = props

  return (
    <div>
      {tablesData.map((tableData, index) =>
        <ViewTable
          loading={loading}
          key={index}
          tableColumns={tableColumns}
          tableData={tableData}
          filterGroup={filterGroup}
        />
      )}
    </div>
  )
}

export default ViewTables