import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES': {
      const newState = {};
      action.categories && action.categories.length > 0 && action.categories.forEach(category => { newState[category.id] = { ...category, loading: false }; });

      return newState;
    }
    case 'FETCH_CATEGORY': {
      const { category } = action;
      const existingCategory = state[category.id] && { ...state[category.id] };
      category && category.items && category.items.forEach(item => item.categoryId = category.id);
      category && category.categories && category.categories.forEach(child => child.categoryId = category.id);

      return { ...state, [category.id]: { ...category, categoryId: existingCategory && existingCategory.categoryId && existingCategory.categoryId, loading: false } };
    }
    case 'REQUEST_CATEGORY': {
      const { categoryId } = action;
      const category = { ...state[categoryId], loading: true } || { loading: false };
      return { ...state, [categoryId]: category };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES': {
      const newState = [];
      action.categories && action.categories.length > 0 && action.categories.forEach(category => {
        if (!newState.includes(category.id)) {
          newState.push(category.id);
        }
      });
      return newState;
    }
    case 'FETCH_CATEGORY':
      if (state.includes(action.category.id)) {
        return state;
      } else {
        return [...state, action.category.id];
      }
    default:
      return state;
  }
};

const byParentId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORY': {
      const { id, categories } = action.category;
      const newState = { ...state };
      newState[id] = categories && categories.length > 0 ? categories.map(category => category.id) : [];

      return newState;
    }
    default:
      return state;
  }
};

const categoryTreeById = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORY_TREE': {
      const newState = {};
      action.categories && action.categories.length > 0 && action.categories.forEach(category => { newState[category.id] = { ...category, loading: false }; });

      return { ...{}, ...newState };
    }
    case 'FETCH_CATEGORY': {
      const { items, categories, ...rest } = action;
      const existingCategory = state[rest.id] && { ...state[rest.id] };

      return { ...state, [rest.id]: { ...rest, categoryId: existingCategory && existingCategory.categoryId && existingCategory.categoryId, loading: false } };
    }
    default:
      return state;
  }
};

const categoryTreeIds = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CATEGORY_TREE': {
      const newState = [];
      action.categories && action.categories.length > 0 && action.categories.forEach(category => {
        if (!newState.includes(category.id)) {
          newState.push(category.id);
        }
      });
      return newState;
    }
    case 'FETCH_CATEGORY':
      if (state.includes(action.category.id)) {
        return state;
      } else {
        return [...state, action.category.id];
      }
    default:
      return state;
  }
};

const categories = combineReducers({
  byId,
  ids,
  byParentId,
  categoryTreeById,
  categoryTreeIds
});

export default categories;

export const getCategories = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getCategoriesByParent = (state, parentId) => {
  const categoryIds = state.byParentId[parentId];
  return categoryIds && categoryIds.length > 0 ? categoryIds.map(id => state.byId[id]) : [];
};

export const getCategoryTree = (state, categoryId = 'defaultMenu') => {
  const category = state.byId[categoryId];
  if (!category || !category.categories) {
    return [];
  }

  const categoriesOfCategory = Array.isArray(category.categories) ? category.categories : Object.values(category.categories);
  const categoryList = [];
  categoriesOfCategory.forEach(category => {
    categoryList.push(category);
  });
  return categoryList;
};

export const getCategoryData = (state, categoryId = 'defaultMenu') => {
  let category = state.byId[categoryId];

  if (!category) return { subCategories: [] };

  const items = category.items ? Object.values(category.items) : [];
  category = { subCategories: [...items] };
  injectSubcategories(state, category);
  return category;
};

const injectSubcategories = (state, category) => {
  (state.byParentId[category.id] || []).forEach(id => {
    category.subCategories.push(getCategoryData(state, id));
  });
};

export const getCategory = (state, categoryId) => {
  return state && state.byId && state.byId[categoryId];
};

export const getFamilyIdList = (state, categoryId, familyIdList) => {
  const category = state.byId && state.byId[categoryId];

  if (!category || (category && !category.categoryId)) return familyIdList;

  familyIdList.push(category.categoryId);
  getFamilyIdList(state, category.categoryId, familyIdList);
  return familyIdList;
};
