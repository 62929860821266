import React, { Component } from 'react';
import { Button, Card, Col, Form, Icon, Input, List, Row, Select, Spin, Tooltip } from 'antd';
import { withRouter } from 'react-router';
import { fetchCompany } from '../../actions/companies';
import { fetchItem, updateVariantHeaderDetails, deleteVariant, updateDefinition, fetchItems } from '../../actions/items';
import { connect } from 'react-redux';
import SideBar from '../../components/SideBar';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import {
  getAuthenticity,
  getCompanyById,
  getItem,
  getItemsByVariantGroupId, getPriceLists
} from '../../reducers';
import VariantOption from '../ItemForm/VariantOption';
import VariantItemList from './VariantItemList';
import ItemList from './ItemList';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import { fetchPriceLists } from '../../actions/priceLists';
import { v1 as uuidv1 } from 'uuid';
import deepcopy from 'deepcopy';

const { TextArea } = Input;
const { Option } = Select;

class ItemVariants extends Component {
  state = {
    itemId: '',
    name: '',
    imageSrc: '',
    nameStatus: '',
    description: '',
    variant: undefined,
    loading: false,
    selectedPriceList: undefined,
    visibleItemList: false,
    enableResetVariant: false
  }

  componentDidMount = () => {
    const { companyId, itemId, company, item } = this.props;

    if (!company || !company.businesses) {
      this.props.fetchCompany(companyId);
    }

    if (itemId !== 'new') {
      if (!item) {
        this.setState({ loading: true });
        this.props.fetchItem(companyId, itemId).then(response => {
          this.setItemDetails(this.props);
          this.setState({ loading: false });
        });
      } else {
        this.setItemDetails(this.props);
      }
    }

    companyId && this.props.fetchPriceLists(companyId);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { priceLists } = nextProps;
    if (priceLists.length > 0) {
      if (!this.state.selectedPriceList) { this.setState({ selectedPriceList: priceLists[0] }); }
    }
  }

  setItemDetails = nextProps => {
    const { item } = nextProps;
    const { name, description, imageSrc, variant } = item;
    // const variantOptionTypes = variant && Object.values(variant.definition.variantOptionTypes).map(type => ({ id: type.id, name: type.name, options: [...type.options] }))

    item && this.setState({
      name: name,
      description: description,
      imageSrc: imageSrc,
      variant: deepcopy(variant)
    });
  }

  handleAddVariantOptionType = () => {
    const { variant } = this.state;
    let variantOptionTypes = Object.keys(variant.definition.variantOptionTypes).map(key => variant.definition.variantOptionTypes[key]);
    variantOptionTypes.push({ id: uuidv1(), name: '', options: [] });
    variantOptionTypes = variantOptionTypes.reduce((newObject, variantOptionType, index) => ({ ...newObject, [index]: variantOptionType }), {});
    variant.definition.variantOptionTypes = variantOptionTypes;

    this.setState({ variant });
  }

  handleRemoveVariantOptionType = optionTypeId => {
    const { variant } = this.state;
    Object.keys(variant.definition.variantOptionTypes).forEach(key => {
      if (variant.definition.variantOptionTypes[key].id === optionTypeId) {
        delete variant.definition.variantOptionTypes[key];
      }
    });

    this.setState({ variant, enableResetVariant: true });
  }

  renderVariantOptionTypes = object => {
    console.log('object', object);
    const optionTypes = [];
    Object.values(object).forEach(value => {
      optionTypes.push(value);
    });

    return optionTypes;
  }

  handleOnChange = (field, value) => {
    switch (field) {
      case 'name':
        this.setState({ name: value, nameStatus: this.getValidationStatus(field, value) });
        break;
      case 'description':
        this.setState({ description: value });
        break;
      default:
    }
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;

  handleVariantDefinitionChange = (id, name, value, action) => {
    switch (name) {
      case 'type': {
        const { variant } = this.state;
        const variantOptionTypes = Object.keys(variant.definition.variantOptionTypes).map(key => variant.definition.variantOptionTypes[key]);
        variantOptionTypes.forEach(optionType => {
          if (optionType.id === id) {
            optionType.name = value;
          }
        });
        this.setState({ variant, enableResetVariant: true });
        break;
      }
      case 'options': {
        const { variant } = this.state;
        const variantOptionTypes = Object.keys(variant.definition.variantOptionTypes).map(key => variant.definition.variantOptionTypes[key]);
        if (action === 'remove') {
          variantOptionTypes.forEach(optionType => {
            if (optionType.id === id) {
              optionType.options = Object.values(optionType.options).filter(option => option !== value);
            }
          });
          this.setState({ variant: variant, enableResetVariant: true });
        } else {
          variantOptionTypes.forEach(optionType => {
            if ((optionType.id === id) && optionType.options.length < 1) {
              optionType.options = value;
            }
          });
          this.setState({ variant: variant });
        }
        break;
      }
      default:
        return null;
    }
  }

  handleSubmit = () => {
    const { itemId } = this.props;
    if (!this.validateForm()) return;
    if (itemId !== 'new') {
      this.handleUpdateVariant();
    } else {
      this.handleInsertVariant();
    }
  }

  handleUpdateVariantDefinition = () => {
    const { variant } = this.state;
    const { companyId, itemId, updateDefinition } = this.props;

    console.log('variant === definitin', variant.definition);
    const newVarientDefinitionObject = {
      variantGroupId: variant.definition.variantGroupId,
      variantOptionTypes: this.removeVariantOptionTypesWithoutOptions(variant.definition.variantOptionTypes)
    };
    console.log('variant === new definitin', newVarientDefinitionObject);
    this.setState({ loading: true });
    updateDefinition(companyId, itemId, newVarientDefinitionObject).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        if (!response) {
          this.setState({ loading: false });
          showErrorNotification('Failed.', 'Variant definition updated failed.');
        } else {
          if (response && response.variantData && response.variantData.items && response.variantData.items.length > 0) {
            this.props.fetchItem(companyId, response.variantData.items[0].id).then((responseData) => {
              if (responseData.code === 400) {
                // this.props.fetchItems(companyId).then(() =>    {
                showSuccessNotification('Updated.', 'Variant definition updated successfully.');
                this.setState({ loading: false });
                this.props.history.goBack();
                // })
              } else {
                this.props.history.replace('/items/' + response.variantData.items[0].id);
                this.props.fetchItems(companyId).then(() => {
                  showSuccessNotification('Updated.', 'Variant definition updated successfully.');
                  this.setState({ loading: false });
                });
              }
            });
          } else {
            this.props.fetchItems(companyId).then(() => {
              showSuccessNotification('Updated.', 'Variant definition updated successfully.');
              this.setState({ loading: false });
              this.props.history.goBack();
            });
          }
        }
      }
    });
  }

  removeVariantOptionTypesWithoutOptions = (variantOptionTypes) => {
    const variantOptionTypesList = variantOptionTypes && Object.keys(variantOptionTypes).length > 0 && Object.values(variantOptionTypes).filter(
      variantOptionType => Object.keys(variantOptionType.options).length !== 0);
    return variantOptionTypesList;
  }

  validateForm = () => {
    const { name } = this.state;
    const validForm = this.isValidName(name);

    if (!validForm) {
      this.setState({
        nameStatus: this.getValidationStatus('name', name)
      });
    }
    return validForm;
  }

  handleUpdateVariant = () => {
    const { itemId, companyId } = this.props;
    const { name, description, imageSrc } = this.state;

    const updatedItem = { name, description, imageSrc };

    this.setState({ loading: true });
    this.props.updateVariantHeaderDetails(companyId, itemId, updatedItem).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        showSuccessNotification('Updated.', 'Variant updated successfully.');
        this.setState({ loading: false });
      }
    });
  }

  renderPriceLists = () => {
    const { priceLists } = this.props;
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(<Option value={priceList.name} key={priceList}>{priceList.name}</Option>);
    });
    return priceListOptions;
  }

  handlePriceListChange = (value) => {
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ selectedPriceList: priceList[0] });
    }
  }

  handleInsertVariant = () => {
    alert('Insert Variant');
  }

  handleConfirmDeleteItem = id => {
    const { companyId, itemId, variantItems } = this.props;
    this.setState({ loading: true });
    this.props.deleteVariant(companyId, id, { deleteItemUseAsIngredient: false }).then(response => {
      if (response.error) {
        showErrorNotification('Failed.', response.error);
        this.setState({ loading: false });
      } else {
        showSuccessNotification('Deleted.', 'Variant deleted successfully.');
        this.setState({ loading: false });
        if (id === itemId) {
          const restOfVariants = variantItems.filter(variant => variant.id !== id);
          if (restOfVariants.length > 0) {
            this.props.history.replace('/items/' + restOfVariants[0].id);
          } else {
            this.props.history.goBack();
          }
        }
      }
    });
  }

  // showItemList = () => {
  //   this.setState({ visibleItemList: true });
  // }
  showItemList = (itemListType) => {
    this.setState({ visibleItemList: true });
    if (itemListType === 'MODIFIER') {
      this.setState({ visibleItemList: true, itemListType });
    } else if (itemListType === 'GROUP_MODIFIER') {
      this.setState({ itemListType, visibleModifireGroupModal: true });
    } else if (itemListType === 'GROUP_MODIFIRE_INGREDIENT') {
      this.setState({ itemListType, visibleModifireGroupModal: false, visibleItemList: true });
    } else {
      this.setState({ visibleItemList: true, itemListType: 'ALL' });
    }
  }

  onCloseItemList = () => this.setState({ visibleItemList: false });

  onSelectItem = (itemListType, id) => {
    const { item } = this.props;

    this.setState({ visibleItemList: false, itemListType: itemListType });
    if (itemListType === 'ALL') {
      if (id) {
        this.props.history.push('/items/' + item.id + '/variant/new/' + id);
      } else {
        this.props.history.push('/items/' + item.id + '/variant/new');
      }
    } else {
      if (item) {
        this.setState({ visibleAddIngredientModal: true, selectedIngredientItem: item });
      }
    }
  }

  handleResetDefinitionForm = () => {
    const { item } = this.props;

    // const variantOptionTypes = Object.values(item.variant.definition.variantOptionTypes).map(type => ({ id: type.id, name: type.name, options: [...type.options] }))
    this.setState({ variant: deepcopy(item.variant), enableResetVariant: false });
  }

  handleImageChange = src => {
    this.setState({ imageSrc: src });
  }

  render() {
    const { name, imageSrc, nameStatus, description, variant, loading, selectedPriceList, visibleItemList, enableResetVariant } = this.state;
    const { companyId, variantItems, item, match, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { itemId } = match.params;

    return (
      <div className='container'>
        <ItemList
          business={business}
          type={this.state.itemListType}
          visible={visibleItemList}
          onClose={this.onCloseItemList}
          onSelectItem={this.onSelectItem}
          priceListId={selectedPriceList && selectedPriceList.id}
        />
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h3 style={{ fontWeight: 400, fontSize: 24 }}>{item && item.name}</h3>
                <Button style={{ textAlign: 'right', fontSize: 18 }} type='link' onClick={() => this.props.history.goBack()}>Back</Button>
              </div>
            </Col>
            <Spin spinning={loading}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Card className='card-container'>
                  <Form className='compact-form'>
                    <Col lg={6} md={6} sm={24} xs={24}>
                      <ImageUploadContainer
                        folder={'/' + companyId + '/items/' + itemId}
                        src={imageSrc}
                        size={150}
                        componentHeight='inherit'
                        onImageChange={this.handleImageChange}
                      />
                    </Col>
                    <Col lg={18} md={18} sm={24} xs={24}>
                      <Form.Item label='Name' validateStatus={nameStatus}>
                        <Input value={name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                      </Form.Item>
                      <Form.Item label='Description'>
                        <TextArea rows={5} value={description} onChange={(event) => this.handleOnChange('description', event.target.value)} />
                      </Form.Item>
                    </Col>
                  </Form>
                  <Col span={24} className='text-right'>
                    <div>
                      {itemId === 'new' && <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={this.handleSubmit}>
                        <Icon type='save' /> Save
                                           </Button>}
                      {itemId !== 'new' && <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={this.handleSubmit}>
                        Update
                                           </Button>}
                    </div>
                  </Col>
                </Card>
              </Col>
              {variant && variant.definition && <Col lg={24} md={24} sm={24} xs={24}>
                <Card className='card-container' style={{ marginTop: 10 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h6>Variant Definitions</h6>
                    {enableResetVariant && <Tooltip title='Reset'>
                      <Button
                        type='default'
                        size='small'
                        icon='undo'
                        style={{ backgroundColor: '#F95C38', borderColor: '#F95C38', color: '#ffffff', marginLeft: 2 }}
                        onClick={this.handleResetDefinitionForm}
                      />
                    </Tooltip>}
                  </div>
                  <List
                    itemLayout='horizontal'
                    dataSource={this.renderVariantOptionTypes(variant.definition.variantOptionTypes)}
                    renderItem={variantOptionType => (
                      <VariantOption
                        item={this.props.item}
                        variantOptionType={variantOptionType}
                        onChangeDefinition={this.handleVariantDefinitionChange}
                        onRemoveVariantOptionType={this.handleRemoveVariantOptionType}
                      />)}
                  />
                  <Row style={{ marginTop: 20 }}>
                    <Col className='text-left' span={12}>
                      <Button onClick={() => this.handleAddVariantOptionType()}>Add another option</Button>
                    </Col>
                    <Col className='text-right' span={12}>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={this.handleUpdateVariantDefinition}>
                          Update
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>}
              <Col lg={24} md={24} sm={24} xs={24}>
                <Card className='card-container' style={{ marginTop: 10 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10, borderBottom: '1px solid rgb(245, 245, 245)', marginBottom: 10 }}>
                    <h6>Variants</h6>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                      <Select
                        dropdownMatchSelectWidth
                        style={{ width: '30%' }}
                        placeholder='Price list'
                        value={selectedPriceList && selectedPriceList.name}
                        onChange={this.handlePriceListChange}
                      >
                        {this.renderPriceLists()}
                      </Select>
                      <Button type='link' onClick={this.showItemList}>Add variant</Button>
                    </div>
                  </div>
                  <VariantItemList
                    business={business}
                    itemId={itemId}
                    variantItems={variantItems}
                    selectedPriceList={selectedPriceList}
                    onConfirmDeleteItem={this.handleConfirmDeleteItem}
                    showEditAction
                  />
                </Card>
              </Col>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { itemId } = props;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  const item = getItem(state, itemId);
  const variantItems = item && item.variant && item.variant.definition && getItemsByVariantGroupId(state, item.variant.definition.variantGroupId).filter(item => !!item);

  return {
    itemId: itemId,
    companyId: companyId,
    item,
    variantItems,
    company: getCompanyById(state, companyId),
    priceLists: getPriceLists(state)
  };
};

export default withRouter(connect(mapStateToProps, { fetchCompany, fetchItem, fetchItems, updateVariantHeaderDetails, fetchPriceLists, deleteVariant, updateDefinition })(ItemVariants));
