import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Collapse, Icon, Statistic, Button, Modal, Spin } from 'antd';
import { withRouter } from 'react-router';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../../helpers/common';
// import ReceiptTemplate from '../ReceiptView/receiptTemplate';
import { updateActiveFilter } from '../../../actions/orderItems';
import moment from 'moment';

import { fetchList } from '../../../actions/generic';
import { getCustomerById, getFilteredOrderItems, getAddressById } from '../../../reducers';
//import { fetchInvoicesForCustomer } from '../../actions/invoices';

class OrderDetailView extends Component {
  state = {
    loading: false,
    filter: {
      businessIds: [],
      branchId: [],
      orderId: undefined,
    }
  }

  componentDidMount() {
    const filter = {
      orderId: this.props.orderId,
      businessIds: this.props.businessIds,
      branchIds: this.props.branchIds
    }

    const customerfilter = {
      id: this.props.customerId,
      businessIds: this.props.businessIds
    }

    const deliveryPersonfilter = {
      id: this.props.deliveryPersonId,
      businessIds: this.props.businessIds
    }

    const deliveryPersonAddressFilter = {
      id: this.props.deliveryAddressId,
      businessIds: this.props.businessIds
    }

    this.setState({ loading: true });
    this.props.fetchList('order_item', filter).then(response => {
      this.setState({ loading: false });
    });


    this.props.customerId && this.props.fetchList('view_customer_order_summary', customerfilter);
    this.props.deliveryPersonId && this.props.fetchList('view_customer_order_summary', deliveryPersonfilter);
    this.props.deliveryAddressId && this.props.fetchList('person_address', deliveryPersonAddressFilter);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.expanded != this.props.expanded && nextProps.expanded){
      this.componentDidMount();
    }
  }

  render() {
    const { orderItemList, customer, deliveryPerson, deliveryAddress, orderNo, date, type } = this.props;
    const { loading } = this.state;

    let formattedAddress = '';
    let formattedCity = '';
    let deliveryToAddress = '';

    if (deliveryAddress) {
      const { address, city } = deliveryAddress;
      formattedAddress = address !== '' ? 'Delivery address : ' + address : '';
      formattedCity = city  ? ', ' + city : '';
    }

    
    if (type === 'TO_GO') {
      deliveryToAddress = 'Pickup on store ';
    } else {
      deliveryToAddress = formattedAddress + formattedCity;
    }


    return (
      <Spin spinning={loading}>
        <Row gutter={5}>
          <Col md={12} sm={6} xs={6} lg={12} style={{ textAlign: 'left' }}>
            <span style={{ fontSize: 'medium' }}>Customer : {customer ? customer.name : ''}</span>
            <br />
            {deliveryPerson && deliveryPerson.name && <span style={{ fontSize: 'medium', textAlign: 'left' }}>Delivery Person : {deliveryPerson.name}</span>}
            <br />
            <span style={{ fontSize: 'medium', textAlign: 'left' }}>{deliveryToAddress}</span>
          </Col>
          <Col md={12} sm={6} xs={6} lg={12}>
            <p style={{ fontSize: 'medium', textAlign: 'right' }}>{orderNo}{date}</p>
          </Col>
        </Row>
        <Row gutter={5}>
          <h6 style={{ marginTop: 20 }}><u>Order Items</u></h6>
          <h6 >Item count : {orderItemList.length}</h6>

          <Col lg={24} style={{ marginBottom: 25, marginTop: 10 }}>
            <table style={{ width: '100%', textAlign: 'center' }}>
              <tr>
                <td style={{ width: '20%', textAlign: 'left', fontSize: 'medium' }}>Description</td>
                <td style={{ width: '20%', textAlign: 'right', fontSize: 'medium' }}>Qty</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Unit Price</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Discount</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Net Price</td>
                <td style={{ width: '15%', textAlign: 'right', fontSize: 'medium' }}>Total</td>
              </tr>
            </table>
            <hr style={{ margin: 0 }} />
            {orderItemList.map(orderItem => this.renderOrderItem(orderItem))}
            <hr style={{ marginTop: 4, marginBottom: 0 }} />
          </Col>
        </Row>
      </Spin>
    );
  }

  renderOrderItem = (orderItem) => {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { name, quantity, modifiers = [], price, discount } = orderItem;
    const modifierList = Array.isArray(modifiers) ? modifiers : Object.values(modifiers);
    const netUnitPrice = (price - discount);
    return (
      <div style={{ display: 'flex', width: '100%', textAlign: 'center', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <span style={{ width: '20%', textAlign: 'left', fontSize: 'medium', }}>  {name}</span>
          <span style={{ width: '20%', textAlign: 'right', fontSize: 'medium', }}>{quantity}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format(price, currencyFormat(business))}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format(discount, currencyFormat(business))}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format(netUnitPrice, currencyFormat(business))}</span>
          <span style={{ width: '15%', textAlign: 'right', fontSize: 'medium', }}>{format((netUnitPrice * quantity), currencyFormat(business))}</span>
        </div>

        {modifierList.map(orderModifier => {
          const { modifiers = [] } = orderModifier;
          const optionModifiers = Array.isArray(modifiers) ? modifiers : Object.values(modifiers);
          return <div style={{ display: 'flex', width: '100%', }} key={orderModifier.id}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}  >

              <div style={{ display: 'flex', width: '100%', }}>
                <span style={{ textAlign: 'left', width: '20%', fontSize: 'small' }}> &nbsp; &nbsp; &nbsp; {orderModifier && ' ' + orderModifier.name}</span>
                <span style={{ textAlign: 'right', width: '20%' }}>{orderModifier.quantity}{quantity > 1 ? ' * ' + quantity : ''}</span>
                <span style={{ textAlign: 'right', width: '15%' }}>{format(orderModifier.price, currencyFormat(business))}</span>
                <span style={{ textAlign: 'right', width: '15%' }}></span>
                <span style={{ textAlign: 'right', width: '15%' }}></span>
                <span style={{ textAlign: 'right', width: '15%', }}>{format(orderModifier.price * orderModifier.quantity * quantity, currencyFormat(business))}</span>
              </div>

              {optionModifiers.map(orderModifier => {
                return <div style={{ display: 'flex', width: '100%', }} key={orderModifier.id}>
                  <div style={{ display: 'flex', width: '100%', }}>
                    <div style={{ display: 'flex', width: '100%' }} >
                      <span style={{ textAlign: 'left', width: '20%', fontSize: 'small', }}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {orderModifier && ' ' + orderModifier.name}</span>
                      <span style={{ textAlign: 'right', width: '20%', }}>{orderModifier.quantity}{quantity > 1 ? ' * ' + quantity : ''}</span>
                      <span style={{ textAlign: 'right', width: '15%', }}>{format(orderModifier.price, currencyFormat(business))}</span>
                      <span style={{ textAlign: 'right', width: '15%' }}></span>
                      <span style={{ textAlign: 'right', width: '15%' }}></span>
                      <span style={{ textAlign: 'right', width: '15%', }}>{format(orderModifier.price * orderModifier.quantity * quantity, currencyFormat(business))}</span>
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const filter = {
    orderId: ownProps.orderId,
    businessIds: ownProps.businessIds,
    branchIds: ownProps.branchIds
  }

  return ({
    orderItemList: getFilteredOrderItems(state, filter),
    customer:  getCustomerById(state, ownProps.customerId),
    deliveryPerson:  getCustomerById(state, ownProps.deliveryPersonId),
    deliveryAddress: getAddressById(state, ownProps.deliveryAddressId,)
  });
};

export default withRouter(connect(mapStateToProps, { fetchList })(OrderDetailView));

