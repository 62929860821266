import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

class AdvancedSearchForm extends Component {
  constructor () {
    super();
    this.state = {
      formLayout: 'horizontal'
    };
  }

  getBranches = () => {
    const options = [];

    return options;
  }

  render () {
    const { query, onDateRangeChange, onInputTextChange, onClickSearch, onChangeOutstandingFilter, onStatusSelectionChange } = this.props;

    return (
      <Form layout='vertical'>
        <Row gutter={[10, 10]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Col lg={4} md={4} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Input
                  placeholder='Invoice ID' onPressEnter={onClickSearch}
                  onChange={(e) => onInputTextChange(e.target.value, 'number')}
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Form.Item style={{ marginBottom: 0 }}>
                <RangePicker
                  allowClear={false}
                  onPressEnter={onClickSearch}
                  defaultValue={[moment(query && query.from), moment(query && query.to)]}
                  format='YYYY-MM-DD'
                  value={[moment(query && query.from), moment(query && query.to)]}
                  onChange={onDateRangeChange}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col lg={7} md={7} sm={24} xs={24}>
              <Select
                showSearch
                dropdownMatchSelectWidth
                style={{ width: '100%' }}
                mode='multiple'
                placeholder='Status'
                defaultValue='All'
                value={query && query.status ? query.status : 'All'}
                onChange={onStatusSelectionChange}
              >
                <Option value='PENDING' key='PENDING'>PENDING</Option>
                <Option value='CONFIRMED' key='CONFIRMED'>CONFIRMED</Option>
                <Option value='COMPLETED' key='COMPLETED'>COMPLETED</Option>
              </Select>
            </Col>
            <Col lg={5} md={5} sm={24} xs={24}>
              <Checkbox onChange={onChangeOutstandingFilter}>Only Outstanding</Checkbox>
            </Col>
            <Col lg={20} md={20} sm={0} xs={0} />
            <Col lg={4} md={4} sm={24} xs={24}>
              <Button className='primary-button' block type='primary' onClick={onClickSearch}>Search</Button>
            </Col>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default AdvancedSearchForm;
