import { combineReducers } from 'redux';
import moment from 'moment';

export const defaultFilter = {
  page: 1,
  pageSize: 20,
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMERS': {
      const newState = { ...state };
      action.customers && action.customers.forEach(customer => { newState[customer.id] = customer; });
      return newState;
    }
    case 'DELETE_CUSTOMER': {
      const newState = { ...state };
      delete newState[action.customerId];
      return newState;
    }
    case 'FETCH_CUSTOMER': {
      return { ...state, [action.customer.id]: action.customer };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMERS': {
      const newState = [];
      action.customers && action.customers.forEach(customer => {
        if (!newState.includes(customer.id)) {
          newState.push(customer.id);
        }
      });
      return newState;
    }

    case 'DELETE_CUSTOMER': {
      const newState = [...state];
      return newState ? newState.length > 0 && newState.filter(f => !f) : []
    }

    case 'FETCH_CUSTOMER': {
      if (state.includes(action.customer.id)) {
        return state;
      } else {
        return [...state, action.customer.id];
      }
    }
    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CUSTOMERS': {
      const newState = { ...state };
      const key = Object.values(action.query).join('');
      const idsForFilter = [];
      action.customers && action.customers.forEach(customer => {
        if (!idsForFilter.includes(customer.id)) idsForFilter.push(customer.id);
      });
      newState[key] = { ids: idsForFilter, totals: action.totals, grandTotal: Object.keys(action.grandTotal).length !== 0 ? action.grandTotal : undefined };
      return newState;
    }
    // case 'DELETE_CUSTOMER': {
    //   const newState = { ...state };
    //   Object.keys(newState).forEach(key => {
    //     const ids = newState[key] || [];
    //     console.log("ids", ids);
    //     newState[key] = ids && ids.length > 0 ? ids.filter(id => id !== action.customerId) : []
    //   });
    //   return newState;
    // }

    default:
      return state;
  }
};

export const activeFilter = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_CUSTOMER':
      return {
        ...state,
        [action.filterType]: action.filter
      };

    case 'UPDATE_ACTIVE_FILTER_CUSTOMER':
      return {
        ...action.filter
      };

    default:
      return state;
  }
};

const customers = combineReducers({
  byId,
  ids,
  byFilter,
  activeFilter
});
export default customers;

export const getCustomers = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getActiveFilter = state => {
  return state.activeFilter;
};

export const getFilteredCustomers = state => {
  const key = Object.values(getActiveFilter(state)).join('');
  const filterdCustomerIds = state.byFilter[key] ? state.byFilter[key].ids : [];

  return filterdCustomerIds.map(id => state.byId[id]).filter(customer => !!customer);
};

export const getFilteredCustomerTotalCount = state => {
  const key = Object.values(getActiveFilter(state)).join('');
  return state.byFilter[key] ? state.byFilter[key].grandTotal.count : 0;
};

export const getCustomerById = (state, id) => {
  return state && state.byId[id] ? state.byId[id] : null;
};
