import React from 'react';
import { Col, Button, Row, Checkbox, TimePicker, Form } from 'antd';
import moment from 'moment';

// dayOfWeek: 0,
// businessOpeningHour: 8,
// businessClosingHour: 12,
// businessOpenningMinute : 34,
// businessClosingMinute : 50,
// deliveryOpeningHour: 8,
// deliveryClosingHour: 12,
// deliveryOpenningMinute : 34,
// deliveryClosingMinute : 50,
// delivering : true,
// businessOpened : true,
// update : false,

// lastBusinessOpeningHour: 8,
// lastBusinessClosingHour: 12,
// lastBusinessOpenningMinute : 34,
// lastBusinessClosingMinute : 50,
// lastDeliveryOpeningHour: 8,
// lastDeliveryClosingHour: 12,
// lastDeliveryOpenningMinute : 34,
// lastDeliveryClosingMinute : 50,

// lastUpdateDeliveryClosed : true,
// lastUpdateBusinessClosed : true
const OpeningHoursRow = ({
  index, updating, dayOfWeek, businessOpened, delivering, businessOpenTime, businessCloseTime, deliveryOpenTime, deliveryCloseTime, onChangeValue, onChangeCheckBox, businessOpeningStatus, businessClosingStatus,
  deliveryEndingStatus, deliveryStartingStatus
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Row>
      <Col span={2}>
        {index === 0 && <p style={{ marginTop: 10, marginBottom: 5, height: 30 }}> </p>}
        <Col sm={8} md={8} lg={24}>
          <p style={{ height: 42 }}><b>{dayOfWeek}</b></p>
        </Col>
      </Col>

      <Col span={10}>
        {index === 0 && <p style={{ marginTop: 10, textAlign: 'center', marginBottom: 5, height: 30 }}><b>Restaurant Opening hours</b></p>}
        <Col style={{ textAlign: 'right' }} sx={8} sm={8} md={8} lg={6}>
          <Checkbox checked={businessOpened} onChange={(e) => onChangeValue(index, 'businessOpened', e.target.checked)}>Opened</Checkbox>
        </Col>
        <Col sx={8} sm={8} md={8} lg={9}>
          <Form.Item validateStatus={businessOpeningStatus}>
            <TimePicker style={{ marginBottom: 10 }} disabled={!businessOpened} value={businessOpenTime !== '' ? moment(businessOpenTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'businessOpenTime', timeString)} />
          </Form.Item>
        </Col>
        <Col sx={8} sm={8} md={8} lg={9}>
          <Form.Item validateStatus={businessClosingStatus}>
            <TimePicker style={{ marginBottom: 10 }} disabled={!businessOpened} value={businessCloseTime !== '' ? moment(businessCloseTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'businessCloseTime', timeString)} />
          </Form.Item>
        </Col>

      </Col>

      <Col span={10}>
        {index === 0 && <p style={{ marginTop: 10, height: 30, marginBottom: 5, textAlign: 'center' }}><b>Delivering hours</b></p>}
        <Col style={{ textAlign: 'right' }} sx={8} sm={8} md={8} lg={6}>
          <Checkbox checked={delivering} onChange={(e) => onChangeValue(index, 'delivering', e.target.checked)}>Delivering</Checkbox>
        </Col>
        <Col sx={8} sm={8} md={8} lg={9}>
          <Form.Item validateStatus={deliveryStartingStatus}>
            <TimePicker style={{ marginBottom: 10 }} disabled={!delivering} value={deliveryOpenTime !== '' ? moment(deliveryOpenTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'deliveryOpenTime', timeString)} />
          </Form.Item>
        </Col>
        <Col sx={8} sm={8} md={8} lg={9}>
          <Form.Item validateStatus={deliveryEndingStatus}>
            <TimePicker style={{ marginBottom: 10 }} disabled={!delivering} value={deliveryCloseTime !== '' ? moment(deliveryCloseTime, 'HH:mm') : ''} format='HH:mm' onChange={(time, timeString) => onChangeValue(index, 'deliveryCloseTime', timeString)} />
          </Form.Item>
        </Col>
      </Col>

      <Col sm={2} md={2} lg={2}>

        {index === 0 && <p style={{ marginTop: 10, marginBottom: 5, height: 30 }}> </p>}
        <Col style={{ textAlign: 'center', paddingLeft: 1 }} sm={8} md={8} lg={8}>
          {updating && <Button className='primary-button' style={{ width: 32, paddingLeft: 9 }} type='primary' icon='undo' onClick={() => onChangeValue(index, 'reset', '')}> </Button>}
        </Col>
        <Col style={{ textAlign: 'right' }} sx={2} sm={8} md={8} lg={8}>
          {updating && <Button className='primary-button' style={{ width: 32, paddingLeft: 9 }} type='primary' icon='save' onClick={() => onChangeValue(index, 'save', '')}> </Button>}
        </Col>

      </Col>

    </Row>
  </div>
);
export default OpeningHoursRow;
