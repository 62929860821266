import React, { Component } from 'react';
import { Col, List, Row } from 'antd';
import VariantItemRow from './VariantItemRow';

class VariantItemList extends Component {
  render() {
    const { variantItems = [], showHeader = true, extendedView = true, itemId, selectedPriceList, onConfirmDeleteItem, showEditAction = false } = this.props;
    const { business } = this.props;
    return (
      <div>
        {showHeader && <Row className='item-table-header' style={{ marginLeft: 5, marginRight: 5, marginBottom: 15 }}>
          <Col lg={2}>Image</Col>
          <Col lg={6}>Name</Col>
          <Col lg={6}>Categories</Col>
          <Col className='text-right' lg={4}>Price</Col>
          {<Col className='text-center' lg={3}>Available</Col>}
          <Col className='text-right' lg={3}>Actions</Col>
        </Row>}
        <List
          itemLayout='horizontal'
          dataSource={variantItems}
          grid={{ gutter: 5 }}
          size='small'
          pagination={extendedView ? ({
            size: 'small',
            pageSize: 15
          }) : (null)}
          renderItem={item => (
            <List.Item rowKey={item.id} className={!extendedView && 'incy-button'} onClick={() => !extendedView ? this.props.onClickVariant(item.id) : null}>
              <VariantItemRow
                business={business}
                itemDetails={item}
                extendedView={extendedView}
                selectedId={itemId}
                selectedPriceList={selectedPriceList}
                onConfirmDeleteItem={onConfirmDeleteItem}
                showEditAction={showEditAction}
              />
            </List.Item>
          )}
        />
      </div>
    );
  }
}
export default VariantItemList;
