import React, { Component } from 'react';
import { Card, Checkbox, Col, Form, Input, List, Row, Select, Spin, TreeSelect, Button, Icon } from 'antd';
import SideBar from '../../components/SideBar';
import ImageUploadContainer from '../ImageUploader/ImageUploadContainer';
import { FiPlusCircle } from 'react-icons/all';
import { getCategory, getCategories, getCategoryTree, getCompanyById, getPriceLists, getAuthenticity } from '../../reducers';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import { fetchCategory, updateCategory, addCategory, fetchCategories } from '../../actions/categories';
import { fetchItem } from '../../actions/items';
import { fetchPriceLists } from '../../actions/priceLists';
import { connect } from 'react-redux';
import { fetchCompany } from '../../actions/companies';
import Item from './Item';
import ItemListHeader from './ItemListHeader';
import ItemList from '../MenuItemComponents/ItemList';

const { TextArea } = Input;
const { Option } = Select;

const defaultCategory = {
  name: '',
  code: '',
  hideFromUsers: false,
  imageSrc: undefined,
  description: '',
  items: [],
  displayInOnlineMenu2: false
};

class CategoryForm extends Component {
  state = {
    category: {
      ...defaultCategory
    },
    nameStatus: '',
    codeStatus: '',
    descriptionStatus: '',
    loading: false,
    selectedPriceList: '',
    visibleItemList: false,
    parentCategoryId: undefined,
    parentCategoryStatus: ''
  }

  componentDidMount = () => {
    const { companyId, categoryId } = this.props;
    if (categoryId && categoryId !== 'new') {
      this.setState({ loading: true });
      this.props.fetchCompany(companyId);
      this.props.fetchCategories(companyId).then(response => {
        this.props.fetchCategory(companyId, categoryId).then(response => {
          this.setState({ loading: false });
          this.props.fetchPriceLists(companyId).then((response) => {
          });
        });
      });
    } else {
      this.props.fetchCompany(companyId);
      this.props.fetchCategories(companyId).then(response => {
        this.props.fetchPriceLists(companyId).then((response) => {
        });
      });
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.category !== this.props.category) {
      this.setCategoryDetails(nextProps);
    }
    const { priceLists } = nextProps;
    if (priceLists.length > 0) {
      if (!this.state.selectedPriceList) { this.setState({ selectedPriceList: priceLists[0] }); }
    }
  }

  setCategoryDetails = nextProps => {
    const { category } = nextProps;
    category && this.setState({
      category: category
    });

    category.parentCategory && this.setState({
      parentCategoryId: category.parentCategory.id
    });
  }

  handleOnChange = (field, value) => {
    switch (field) {
      case 'hideFromUsers':
        this.setState({ category: { ...this.state.category, hideFromUsers: value } });
        break;
      case 'name':
        this.setState({ category: { ...this.state.category, name: value }, nameStatus: '' });
        break;
      case 'description':
        this.setState({ category: { ...this.state.category, description: value }, descriptionStatus: '' });
        break;
      case 'code':
        this.setState({ category: { ...this.state.category, code: value }, codeStatus: '' });
        break;
      case 'displayInOnlineMenu2':
        this.setState({ category: { ...this.state.category, displayInOnlineMenu2: value } });
        break;
      default:
    }
  }

  handlePriceListChange = (value) => {
    const priceList = this.props.priceLists.filter(priceList => priceList.name === value);
    if (priceList.length > 0) {
      this.setState({ selectedPriceList: priceList[0] });
    }
  }

  handleCategoryChange = (value) => {
    this.setState({ parentCategoryId: value, parentCategoryStatus: '' });
  }

  showItemList = () => {
    this.setState({ visibleItemList: true });
  }

  onCloseItemList = () => this.setState({ visibleItemList: false });

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }

    if (!this.state.parentCategoryId) {
      this.setState({ parentCategoryStatus: 'error' });
      return;
    }

    const { category, parentCategoryId } = this.state;
    var obj = {
      name: category.name,
      code: category.code,
      hideFromUsers: category.hideFromUsers,
      items: category.items,
      description: category.description,
      displayInOnlineMenu2: category.displayInOnlineMenu2,
      imageSrc: category.imageSrc
    };

    if (this.state.category.imageSrc !== undefined) {
      obj = {
        ...obj

      };
    } else {
      obj = {
        ...obj,
        imageSrc: ''
      };
    }

    if (parentCategoryId) {
      obj = {
        ...obj,
        parentCategoryId: parentCategoryId
      };
    } else {
      obj = {
        ...obj,
        parentCategoryId: undefined
      };
    }

    const { categoryId } = this.props;
    if (categoryId !== 'new') {
      this.handleUpdateCategory(obj);
    } else {
      this.handleAddCategory(obj);
    }
  }

  handleUpdateCategory = (obj) => {
    const { companyId, categoryId } = this.props;

    this.setState({ submitting: true, loading: true });
    this.props.updateCategory(companyId, categoryId, obj).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed updating category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        // this.setState({ submitting: false, loading: false, ...defaultItem, ingredients: [] });
        // this.props.fetchCategory(companyId, categoryId).then(() => {
        //   this.setState({ loading: false });
        // });
        this.props.fetchCategories(companyId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ loading: false });
        showSuccessNotification('Category updated.', 'Category updated successfully.');
      }
    });
  }

  handleAddCategory = (obj) => {
    const { companyId, company } = this.props;
    const businessId = company && company.businesses[0] && company.businesses[0].id;

    const newCategory = {
      ...obj,
      businessId: businessId
    };

    const parentCategoryId = this.state.parentCategoryId;

    this.setState({ submitting: true, loading: true });
    this.props.addCategory(companyId, parentCategoryId, newCategory).then(response => {
      const { error } = response;
      if (error) {
        showErrorNotification('Failed adding category', error);
        this.setState({ submitting: false, loading: false });
      } else {
        this.props.fetchCategories(companyId).then(() => {
          this.setState({ loading: false });
        });
        this.setState({ submitting: false, loading: false });
        showSuccessNotification('Category added', 'Category added successfully.');
        this.resetForm();
        this.props.history.goBack();
      }
    });
  }

  resetForm = () => {
    this.setState({
      category: { ...defaultCategory },
      nameStatus: '',
      codeStatus: '',
      descriptionStatus: '',
      loading: false,
      selectedPriceList: '',
      visibleItemList: false,
      parentCategoryId: undefined,
      parentCategoryStatus: ''
    });
  }

  handleRemoveItemFromCategory = (itemId) => {
    const { category } = this.state;
    if (category.items.length > 0) {
      this.setState({ category: { ...this.state.category, items: category.items.filter(item => item.id !== itemId) } });
    }
  }

  onSelectItem = (itemListType, item) => {
    this.setState({ visibleItemList: false });
    const { category } = this.state;
    if (item) {
      this.setState({ category: { ...category, items: [...category.items, item] } });
    }
  }

  onSaveItem = (itemId) => {
    const { companyId } = this.props;
    this.setState({ visibleItemList: false });
    const { category } = this.state;
    if (itemId) {
      this.setState({ loading: true });
      this.props.fetchItem(companyId, itemId).then(response => {
        this.setState({ loading: false });
        this.setState({ category: { ...category, items: [...category.items, response.item] } });
      });
    }
  }

  validateForm = () => {
    const { category } = this.state;
    const { name, code } = category;
    const validForm = this.isValidName(name) &&
      this.isValidCode(code);

    if (!validForm) {
      this.setState({
        nameStatus: this.getValidationStatus('name', name),
        codeStatus: this.getValidationStatus('code', code)
      });
    }
    return validForm;
  }

  getValidationStatus = (name, value) => {
    switch (name) {
      case 'name':
        return this.isValidName(value) ? 'success' : 'error';
      case 'code':
        return this.isValidCode(value) ? 'success' : 'error';

      default:
        return null;
    }
  }

  isValidName = name => name && name.length > 0;
  isValidCode = code => code && code.length > 0;

  handleImageChange = src => {
    this.setState({ category: { ...this.state.category, imageSrc: src } });
  }

  getCategoryTreeNodes = (categoryTree, keyOfValue = 'id') => {
    var categoriesArray = [];
    if (categoryTree && categoryTree.length !== undefined) {
      categoryTree.forEach(category => {
        const obj = {
          title: category.name,
          key: category.id,
          value: keyOfValue === 'name' ? category.name : category.id,
          children: category.categories && Object.keys(category.categories).length > 0 && this.getCategoryTreeNodes(category.categories, keyOfValue)
        };
        categoriesArray.push(obj);
      });
    } else {
      Object.keys(categoryTree).forEach(key => {
        const obj = {
          title: categoryTree[key].name,
          key: categoryTree[key].id,
          value: keyOfValue === 'name' ? categoryTree[key].name : categoryTree[key].id,
          children: categoryTree[key].categories && Object.keys(categoryTree[key].categories).length > 0 && this.getCategoryTreeNodes(categoryTree[key].categories, keyOfValue)
        };
        categoriesArray.push(obj);
      });
    }

    return categoriesArray;
  }

  renderDefaultParentCategory = () => {
    var defaultCategory = undefined;
    if (this.props.category !== undefined && this.props.category.parentCategory !== undefined) {
      const { parentCategory } = this.props.category;
      defaultCategory = parentCategory.id;
    }

    return defaultCategory;
  }

  renderPriceLists = () => {
    const { priceLists } = this.props;
    const priceListOptions = [];
    priceLists && priceLists.forEach(priceList => {
      priceListOptions.push(<Option value={priceList.name} key={priceList}>{priceList.name}</Option>);
    });
    return priceListOptions;
  }

  render () {
    const { companyId, categoryId, categoryTree } = this.props;
    const { nameStatus, codeStatus, category, descriptionStatus, parentCategoryId, selectedPriceList, loading, visibleItemList, parentCategoryStatus } = this.state;
    const { name, code, hideFromUsers, imageSrc, description, items, displayInOnlineMenu2 } = category;

    var categoryTreeNodesList = [];
    const defaultCategory = {
      title: 'Menu',
      key: 'defaultMenu',
      value: 'defaultMenu',
      children: []
    };

    categoryTreeNodesList = this.getCategoryTreeNodes(categoryTree, 'id');
    categoryTreeNodesList.splice(0, 0, defaultCategory);

    return (
      <Spin spinning={this.state.loading}>
        <div className='container'>
          <Row gutter={[10, 10]}>
            <ItemList
              companyId={companyId}
              submitting={loading}
              type='ALL'
              visible={visibleItemList}
              onClose={this.onCloseItemList}
              onSelectItem={this.onSelectItem}
              onSaveItem={this.onSaveItem}
            />
            <Col lg={4} md={4} sm={24} xs={24}>
              <SideBar />
            </Col>
            <Col lg={20} md={20}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 400, fontSize: 24 }}>Add Category</h3>
                  <Button style={{ textAlign: 'right', fontSize: 18 }} type='link' onClick={() => this.props.history.goBack()}>Back</Button>
                </div>
              </Col>
              <Col lg={16} md={16} sm={24} xs={24}>
                <Card className='card-container'>
                  <Form className='compact-form'>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <ImageUploadContainer
                        folder={'/' + companyId + '/categories/' + categoryId}
                        src={imageSrc}
                        size={150}
                        componentHeight='inherit'
                        onImageChange={(imageSrc) => this.handleImageChange(imageSrc)}
                      />
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                      <Form.Item label='Name' validateStatus={nameStatus}>
                        <Input value={name} onChange={(event) => this.handleOnChange('name', event.target.value)} />
                      </Form.Item>
                      <Form.Item label='Short Name' validateStatus={codeStatus}>
                        <Input value={code} onChange={(event) => this.handleOnChange('code', event.target.value)} />
                      </Form.Item>
                      <Form.Item label='Description' style={{ marginBottom: 8 }} validateStatus={descriptionStatus}>
                        <TextArea rows={4} value={description} onChange={(event) => this.handleOnChange('description', event.target.value)} />
                      </Form.Item>
                    </Col>
                  </Form>
                </Card>
              </Col>
              <Col lg={8} md={8} sm={24} xs={24}>
                <Card className='card-container'>
                  <h6>Parent Category</h6>
                  {/* <Select
                    style={styles.categorySelect}
                    placeholder='Search categories'
                    defaultValue={'top'}
                  > */}
                  <Form.Item validateStatus={parentCategoryStatus}>
                    <TreeSelect
                      dropdownMatchSelectWidth
                      style={{ width: '100%' }}
                      value={parentCategoryId}
                      placeholder='Category'
                      treeData={categoryTreeNodesList}
                      onChange={(value) => this.handleCategoryChange(value)}
                    />
                  </Form.Item>
                </Card>
                <Card className='card-container' style={{ marginTop: 10 }}>
                  <h6>Options</h6>
                  <Checkbox
                    style={styles.checkBox}
                    onChange={(e) => this.handleOnChange('hideFromUsers', e.target.checked)}
                    size='small'
                    checked={hideFromUsers}
                  >Is Raw Material
                  </Checkbox>

                  {/* <Checkbox style={styles.checkBox} checked={displayInOnlineMenu2 !== null ? displayInOnlineMenu2 : false} onChange={(e) => this.handleOnChange('displayInOnlineMenu2', e.target.checked)}>Visible online</Checkbox>
                */}
                </Card>
                <Card className='card-container' style={{ marginTop: 10 }}>
                  <h6>Price List</h6>
                  <Select
                    style={styles.categorySelect}
                    value={selectedPriceList && selectedPriceList.name}
                    onChange={this.handlePriceListChange}
                  >
                    {this.renderPriceLists()}
                  </Select>
                </Card>
              </Col>
              <Col span={24}>
                <Card className='card-container'>
                  <div style={styles.horizontalContainer}>
                    <h6>Items</h6>
                    {/* <Link to={'/itemForm'}> */}
                    <FiPlusCircle className='incy-button' style={styles.iconButton} onClick={() => this.showItemList()} />
                    {/* </Link> */}
                  </div>
                  <ItemListHeader />
                  <List
                    itemLayout='horizontal'
                    dataSource={items}
                    grid={{ gutter: 5 }}
                    size='small'
                    pagination={{
                      size: 'small',
                      pageSize: 15,
                      hideOnSinglePage: true
                    }}
                    renderItem={item => (
                      <List.Item>
                        <Item item={item} selectedPriceList={this.state.selectedPriceList} onRemoveItemFromCategory={(itemId) => this.handleRemoveItemFromCategory(itemId)} />
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
              <Col span={24}>
                <div style={{
                  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'
                }}
                >
                  {categoryId === 'new' && <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={() => this.handleSubmit()}>
                    <Icon type='save' /> Save
                  </Button>}
                  {categoryId !== 'new' && <Button type='primary' className='btn-orange' style={{ marginRight: 5, marginLeft: 5, width: 120 }} onClick={() => this.handleSubmit()}>
                    Update
                  </Button>}
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { categoryId } = props.match.params;
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return {
    categoryId: categoryId,
    companyId: companyId,
    category: getCategory(state, categoryId),
    categories: getCategories(state),
    priceLists: getPriceLists(state),
    categoryTree: getCategoryTree(state),
    company: getCompanyById(state, companyId)
  };
};

const styles = {
  checkBox: {
    width: '100%', marginLeft: 0
  },
  categorySelect: {
    width: '100%'
  },
  iconButton: {
    color: '#F95C38',
    fontSize: 20
  },
  horizontalContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  optionTitle: {
    fontWeight: 500,
    fontSize: 14,
    padding: 5,
    marginBottom: 0
  }
};

export default connect(mapStateToProps, { fetchCategory, fetchCompany, updateCategory, addCategory, fetchCategories, fetchPriceLists, fetchItem })(CategoryForm);
