import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, Spin } from 'antd';
import { fetchInvoiceForPrint } from '../../actions/invoices';
import { getInvoiceForPrint, getCompanyById, getAuthenticity } from '../../reducers/';
import moment from 'moment';
import { format } from 'currency-formatter';
import { currencyFormat } from '../../helpers/common';
import SideBar from '../../components/SideBar';

class InvoicePrint extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const { fetchInvoiceForPrint, customerId, invoiceId } = this.props;
    this.setState({ loading: true });
    const request = {
      invoiceId: invoiceId,
      customerId: customerId
    };
    fetchInvoiceForPrint(request).then(res => {
      this.setState({ loading: false });
    });
  }

  renderInvoiceData = () => {
    const { invoice, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const invoiceDataList = [];
    invoice && invoice.details && invoice.details.forEach(detail => {
      invoiceDataList.push(
        <div style={{ display: 'flex', marginTop: 5 }}>
          <div style={{ width: '25%' }}>
            <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{detail.itemName}</span>
          </div>
          <div style={{ width: '35%' }}>
            <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{detail.itemDescription}</span>
          </div>
          <div style={{ width: '10%', textAlign: 'center' }}>
            <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{detail.quantity}</span>
          </div>
          <div style={{ width: '15%', textAlign: 'right' }}>
            <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{format(detail.itemPrice, currencyFormat(business))}</span>
          </div>
          <div style={{ width: '15%', textAlign: 'right' }}>
            <span style={{ fontSize: 12, lineHeight: '18px', fontWeight: 400 }}>{format(detail.lineTotal, currencyFormat(business))}</span>
          </div>
        </div>
      );
    });

    return invoiceDataList;
  }

  handlePrintClick = () => {
    const content = document.getElementById('invoice-print').innerHTML;
    const printWindow = window.open('', '', 'height=768, width= 1024');
    printWindow.document.write(content);
    const paras = printWindow.document.getElementsByTagName('p');
    console.log('PARAS ', paras);
    for (const x in paras) {
      const pa = paras[x];
      if (pa.style) pa.style.margin = '0px !important';
    }
    printWindow.document.close();
    printWindow.print();
    printWindow.onafterprint = () => {
      printWindow.close();
    };
  }

  render() {
    const { loading } = this.state;
    const { invoice, company } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Spin spinning={loading}>
              <Card
                className='print-invoice-view' style={{ margin: 5 }}
                extra={
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: 100 }}>
                    <Button className='primary-button' size='small' type='primary' onClick={() => this.handlePrintClick()}>Print</Button>
                    {/* <Button size='small' type='primary' style={{ marginLeft: 5, marginRight: 5 }}>Send as email</Button> */}
                    <Button size='small' type='primary' onClick={() => this.props.history.goBack()}>Exit</Button>
                  </div>
                }
              >
                <div id='invoice-print' style={{ fontFamily: 'Roboto' }}>
                  <div style={{ display: 'flex', fontFamily: 'Roboto' }}>
                    <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                      <h1 style={{ fontSize: 22, fotWeight: 500, fontFamily: 'Roboto' }}>INVOICE</h1>
                      {invoice && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {invoice.companyName && <span style={{ fontSize: 18, fontWeight: 400, maxWidth: 200 }}>{invoice.companyName}</span>}
                        {invoice.companyAddress && <span style={{ fontSize: 12, fontWeight: 400, maxWidth: 200 }}>{invoice.companyAddress}</span>}
                        {invoice.companyTelephone && <span style={{ fontSize: 12, fontWeight: 400 }}>Telephone: {invoice.companyTelephone}</span>}
                        {invoice.companyEmail && <span style={{ fontSize: 12, fontWeight: 400 }}>Email: {invoice.companyEmail}</span>}
                      </div>}
                    </div>
                    <div style={{ width: '30%', textAlign: 'right', fontSize: 14, display: 'flex', flexDirection: 'column' }}>
                      {invoice && invoice.printCount && <span>Print Count: {invoice.printCount}</span>}
                      <span>Print Date: {moment().format('YYYY-MM-DD:HH-MM')}</span>
                    </div>
                  </div>
                  {invoice && <div style={{ marginTop: 20, display: 'flex', fontFamily: 'Roboto' }}>
                    <div style={{ fontSize: 14, width: '50%', fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                      <span>Invoice No: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{invoice.number}</span></span>
                      <span>Invoice Date: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{moment(invoice.date).format('YYYY-MM-DD')}</span></span>
                      <span>Invoice Amount: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{format(invoice.total, currencyFormat(business))}</span></span>
                      <span>Invoice Status: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{invoice.status}</span></span>
                    </div>
                    <div style={{ fontSize: 14, width: '50%', fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                      {invoice.customerName && <span>Customer Name: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{invoice.customerName}</span></span>}
                      {invoice.customerAddress && <span>Customer Address: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{invoice.customerAddress}</span></span>}
                      {invoice.customerTelephone && <span>Customer Telephone: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{invoice.customerTelephone}</span></span>}
                      {invoice.customerEmail && <span>Customer Email: <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>{invoice.customerEmail}</span></span>}
                    </div>
                  </div>}

                  <div style={{ minHeight: 50, fontFamily: 'Roboto', border: '2px solid #d1d1d1', borderRadius: 10, marginTop: 20, padding: '10px 20px' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '25%' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Item Name</span>
                      </div>
                      <div style={{ width: '35%' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Item Description</span>
                      </div>
                      <div style={{ width: '10%', textAlign: 'center' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Quantity</span>
                      </div>
                      <div style={{ width: '15%', textAlign: 'right' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Unit Price</span>
                      </div>
                      <div style={{ width: '15%', textAlign: 'right' }}>
                        <span style={{ fontSize: 14, fontWeight: 600 }}>Line Total</span>
                      </div>
                    </div>
                    {this.renderInvoiceData()}

                    <hr style={{ marginTop: 10, opacity: 0.2 }} />

                    {invoice && <div style={{ display: 'flex', marginTop: 20 }}>
                      <div style={{ width: '70%' }} />
                      <div style={{ width: '30%' }}>
                        <div style={{ display: 'flex', fontSize: 14, fontWeight: 600 }}>
                          <div style={{ width: '60%' }}>Gross Amount</div>
                          <div style={{ width: '40%', textAlign: 'right' }}>{format(invoice.subtotal, currencyFormat(business))}</div>
                        </div>
                        <div style={{ display: 'flex', fontSize: 14, fontWeight: 600 }}>
                          <div style={{ width: '60%' }}>Discount</div>
                          <div style={{ width: '40%', textAlign: 'right' }}>{format(invoice.discount, currencyFormat(business))}</div>
                        </div>
                        <div style={{ display: 'flex', fontSize: 16, fontWeight: 600 }}>
                          <div style={{ width: '60%' }}>Net Amount</div>
                          <div style={{ width: '40%', textAlign: 'right' }}>{format(invoice.total, currencyFormat(business))}</div>
                        </div>
                      </div>
                    </div>}
                  </div>

                  {invoice && invoice.remarks && invoice.remarks.length > 0 && <div style={{ marginTop: 20, fontFamily: 'Roboto', fontWeight: 500 }}>
                    <span>Remarks: {invoice.remarks}</span>
                  </div>}

                  <div style={{ marginTop: 20, display: 'flex', fontFamily: 'Roboto' }}>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                      <span>Thank you for your payment</span>
                      <span style={{ fontSize: 10 }}>NON TRANSFERABLE AND NON REFUNDABLE</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <span>Signature</span>
                    </div>
                  </div>
                </div>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;
  const { invoiceId, customerId } = props.match.params;
  return {
    invoiceId,
    customerId,
    invoice: getInvoiceForPrint(state, invoiceId),
    company: getCompanyById(state, companyId)
  }

};

export default connect(mapStateToProps, { fetchInvoiceForPrint })(InvoicePrint);
