import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Button, Col } from 'antd';
import AdvancedSearchForm from './AdvancedSearchForm';
import PayoutTable from './PayoutTable';
import { fetchOrdersFromAdvancedSearch } from '../../actions/Orders';
import { getAuthenticity, getOrders, getCompanyById } from '../../reducers';
import moment from 'moment';
import { showLoadingMessage } from '../../helpers/Message';
import OrderSummeryDetails from './PayoutSummeryDetails';
import { showErrorNotification, showSuccessNotification } from '../../components/Notification';
import SideBar from '../../components/SideBar';

class OrdersContainer extends Component {
  constructor() {
    super();
    this.state = {
      query: {
        userId: undefined,
        orderId: undefined,
        filterText: undefined,
        branchId: undefined,
        fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
        toDate: moment(new Date()).endOf('day'),
        page: 0,
        pageSize: 0,
        status: [],
        orderMethod: 'WEB',
        orderPaymentType: 'ONLINE',
        clientFilter: undefined,
        customerFilter: undefined
      },
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      orderIds: undefined,
      onlyOutstanding: false,
      actionProcessing: false
    };
  }

  componentDidMount() {
    this.handleFetchOrders();
  }

  componentWillUnmount() {
    if (typeof this.hideLoadingMessage === 'function') {
      this.hideLoadingMessage();
    }
  }

  handleFetchOrders = () => {
    const { authenticity: { user: { userId } }, fetchOrdersFromAdvancedSearch, orders } = this.props;
    const { query } = this.state;

    if (orders && orders.length > 0) {
      this.showLoadingMessage();
      fetchOrdersFromAdvancedSearch({ ...query, userId }).then(response => {
        this.hideLoadingMessage();
      });
    } else {
      this.setState({ loading: true });
      fetchOrdersFromAdvancedSearch({ ...query, userId }).then(response => {
        this.setState({ loading: false });
      });
    }
  }

  showLoadingMessage = () => {
    this.hideLoadingMessage = showLoadingMessage('Refreshing Payouts.');
  }

  handleDateRangeChange = date => {
    const queryData = {
      ...this.state.query,
      fromDate: date[0].startOf('day'),
      toDate: date[1].endOf('day')
    };

    this.setState({ query: queryData });
  }

  handleInputTextChange = (e, type) => {
    const value = e.target.value;

    this.setState({ query: { ...this.state.query, [type]: value } });
  }

  handleStatusSelectionChange = value => {
    this.setState({ query: { ...this.state.query, status: value } });
  }

  onAlwaysShowWebOrdersChange = checked => this.setState({ query: { ...this.state.query, orderMethod: checked && 'WEB' } });

  handleOnClickSearch = () => {
    const { authenticity: { user: { userId } }, fetchOrdersFromAdvancedSearch } = this.props;
    const { query } = this.state;
    this.setState({ loading: true });
    fetchOrdersFromAdvancedSearch({ ...query, userId }).then(response => {
      this.setState({ loading: false });
    });
  }



  onChangeOutstandingFilter = (e) => {
    console.log(e.target.checked);
    this.setState({ onlyOutstanding: e.target.checked });
  }

  getFilteredOrders = () => {
    const { orders } = this.props;
    const { query } = this.state;
    const { orderId, customerFilter, clientFilter, fromDate, toDate, status } = query;

    return orders.filter(order => {
      return (orderId ? (order.orderNumber.toLowerCase() === orderId.toLowerCase()) : true) &&
        (customerFilter
          ? (order.customer.name.toLowerCase().includes(customerFilter.toLowerCase()) ||
            order.customer.telephone.toLowerCase().includes(customerFilter.toLowerCase()) ||
            order.customer.email.toLowerCase().includes(customerFilter.toLowerCase())) : true) &&
        (clientFilter
          ? (order.client.name.toLowerCase().includes(clientFilter.toLowerCase()) ||
            order.client.email.toLowerCase().includes(clientFilter.toLowerCase())) : true) &&
        (moment(order.createdDate).isAfter(moment(fromDate)) && moment(order.createdDate).isBefore(moment(toDate))) &&
        (!status || status.length === 0 || status.includes(order.status.toUpperCase()) || (status.includes('REJECTED') && order.consumerFeedback && !order.consumerFeedback.accepted));
    });
  }

  handleRowSelection = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  }

  handleReconciledOrders = () => {
    const { selectedRows } = this.state;

    const groupedOrdersByCustomer = this.getOrdersGroupedByCustomer(selectedRows);

    this.setState({ actionProcessing: true });
    this.props.orderPaymentsReconilation(groupedOrdersByCustomer).then((response) => {
      const { error } = response;
      this.setState({ actionProcessing: false });
      if (error) {
        showErrorNotification('Failed', "Something wen't wrong. Try again later.");
      } else {
        showSuccessNotification('Successful', 'Successfully reconciled.');
      }
    });
  }

  getOrdersGroupedByCustomer(orders) {
    const groupedOrdersByCustomer = orders.reduce((groupedOrdersList, order) => {
      const groupData = groupedOrdersList[order.client.id] || {};
      groupData.clientId = groupData.clientId || order.client.id;
      const orderIds = groupData.orderIds || [];

      if (!orderIds.includes(order.id)) orderIds.push(order.id);
      groupData.orderIds = orderIds;

      groupedOrdersList[order.client.id] = groupData;
      return groupedOrdersList;
    }, {});

    return Object.values(groupedOrdersByCustomer);
  }

  render() {
    const { company, companyId } = this.props;
    const business = company && company.businesses && company.businesses['0'];
    const { query, loading, selectedRowKeys, selectedRows, actionProcessing } = this.state;
    const filteredOrders = this.getFilteredOrders();

    return (
      <div className='container'>
        <Row gutter={[10, 10]}>
          <Col lg={4} md={4} sm={24} xs={24}>
            <SideBar />
          </Col>
          <Col lg={20} md={20} sm={24} xs={24}>
            <Card
              title={<h4 style={{ fontWeight: 300 }}>Payouts</h4>} style={{ margin: 5 }}
              extra={<Button style={{ textAlign: 'right', fontSize: 18 }} type='link' className='isoDeleteBtn' onClick={() => this.props.history.goBack()}>Back</Button>}
            >
              <AdvancedSearchForm
                business={business}
                onClickSearch={this.handleOnClickSearch}
                onInputTextChange={this.handleInputTextChange}
                onDateRangeChange={this.handleDateRangeChange}
                onChangeOutstandingFilter={this.onChangeOutstandingFilter}
                onStatusSelectionChange={this.handleStatusSelectionChange}
                onAlwaysShowWebOrdersChange={this.onAlwaysShowWebOrdersChange}
                query={query}
              />
            </Card>

            <Card style={{ margin: 5 }}>
              <OrderSummeryDetails business={business} orders={filteredOrders.length > 0 ? filteredOrders : []} />
            </Card>

            <Card
              style={{ margin: 5 }} extra={
                (selectedRowKeys && selectedRowKeys.length > 0) &&
                <Row style={{ padding: 10, textAlign: 'end', backgroundColor: '#fafafa' }}>
                  <Button loading={actionProcessing} className='tableButtonSpecial' size='small' onClick={this.handleReconciledOrders}>Reconcile</Button>
                </Row>
              }
            >
              <PayoutTable
                business={business}
                orders={filteredOrders.length > 0 ? filteredOrders : []}
                loading={loading}
                selectedRowKeys={selectedRowKeys}
                enableRowSelection={false}
                selectedRows={selectedRows}
                onRowSelection={this.handleRowSelection}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const authData = getAuthenticity(state);
  const { user: { userId: companyId } } = authData;

  return {
    authenticity: getAuthenticity(state),
    orders: getOrders(state),
    company: getCompanyById(state, companyId),
  };
};
export default connect(mapStateToProps, { fetchOrdersFromAdvancedSearch })(OrdersContainer);
