import React, { Component } from 'react';
import { Col, Input, Row, Select } from 'antd';

const { Option } = Select;

class EditableItemRow extends Component {
  getItemPrice = itemPrices => {
    console.log(itemPrices);
    const { selectedPriceList } = this.props;
    let price = 0;

    if (selectedPriceList && selectedPriceList.id) {
      itemPrices && itemPrices.length > 0 && itemPrices.forEach(itemPrice => {
        if (itemPrice.priceList && (itemPrice.priceList.id === selectedPriceList.id)) price = itemPrice.price;
      });
    }

    return price;
  }

  renderVariantTag = () => {
    const { itemDetails } = this.props;
    let optionTag = '';
    itemDetails && itemDetails.variant && itemDetails.variant.options && Object.values(itemDetails.variant.options).forEach(variantOption => {
      optionTag = optionTag + variantOption.option + ' / ';
    });

    return optionTag.substr(0, optionTag.length - 3);
  }

  render () {
    const { itemDetails, onUpdateVariantItem, selectedPriceList, onOptionChange, selectedOptions } = this.props;
    console.log(itemDetails);
    const selectedOption = selectedOptions.find(option => option.key === itemDetails.key);
    return (
      <div>
        <Row className='all-item-row' style={{ marginLeft: 5, marginRight: 5, marginTop: -10, width: '98.8%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Col lg={6} style={{ maxHeight: 70, overflow: 'hidden' }}>
              {itemDetails.name}
              <div className='disable-text-selection' style={{ fontSize: 12, color: '#006fbb' }}>{this.renderVariantTag()}</div>
            </Col>
            <Col className='text-right' lg={5}>
              <Input disabled={!itemDetails.combinationAvailable} onChange={(e) => onUpdateVariantItem(itemDetails.key, 'code', e.target.value)} value={itemDetails.code} />
            </Col>
            <Col className='text-right' lg={5}>
              <input class='ant-input' type='number' disabled={!itemDetails.combinationAvailable} onChange={(e) => onUpdateVariantItem(itemDetails.key, 'itemPrices', { value: e.target.value, selectedPriceList, itemPriceList: itemDetails.itemPrices })} value={this.getItemPrice(itemDetails.itemPrices)} />
            </Col>
            <Col className='text-right' lg={8}>
              <Select disabled={itemDetails.disabled} value={selectedOption && selectedOption.value} defaultValue='NEW_ITEM' style={{ width: '100%' }} onChange={(value) => onOptionChange(value, itemDetails.key)}>
                <Option value='NEW_ITEM'>Create as a new item</Option>
                <Option value='SELECT_EXISTING_ITEM'>Select an existing item</Option>
                <Option value='SKIP_COMBINATION'>Skip combination</Option>
              </Select>
            </Col>
          </div>
        </Row>
      </div>
    );
  }
}

export default EditableItemRow;
