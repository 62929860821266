import { combineReducers } from 'redux';
import moment from 'moment';

export const defaultFilter = {
  text: undefined,
  userId: undefined,
  orderId: undefined,
  fromDate: moment(new Date().setMonth(new Date().getMonth() - 1)).startOf('day'),
  toDate: moment(new Date()).endOf('day'),
  page: 1,
  pageSize: 20,
  status: [],
  method: ''
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_RECEIPTS': {
      const newState = {};
      action.receipts && action.receipts.length > 0 && action.receipts.forEach(receipt => { newState[receipt.id] = { ...receipt, loading: false }; });

      return { ...state, ...newState };
    }
    case 'FETCH_RECEIPT': {
      const { receipt } = action;
      return { ...state, [receipt.id]: receipt };
    }
    case 'DELETE_RECEIPT': {
      delete state[action.deletedReceipt.id];
      return state;
    }
    case 'PARTIALLY_FETCH_RECEIPT': {
      const newState = { ...state };
      action.receipts && action.receipts.forEach(receipt => { newState[receipt.id] = receipt; });
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_RECEIPTS': {
      const newState = [...state];
      action.receipts && action.receipts.length > 0 && action.receipts.forEach(receipt => {
        if (!newState.includes(receipt.id)) {
          newState.push(receipt.id);
        }
      });
      return newState;
    }
    case 'FETCH_RECEIPT': {
      if (!state.includes(action.receipt.id)) {
        return [...state, action.receipt.id];
      }
      return state;
    }
    case 'DELETE_RECEIPT': {
      return state.filter(receiptId => receiptId !== action.deletedReceipt.id);
    }
    case 'PARTIALLY_FETCH_RECEIPT': {
      const newState = [];
      action.receipts && action.receipts.forEach(receipt => {
        if (!newState.includes(receipt.id)) {
          newState.push(receipt.id);
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

const byFilter = (state = {}, action) => {
  switch (action.type) {
    case 'PARTIALLY_FETCH_RECEIPT': {
      const newState = { ...state };
      const key = Object.values(action.query).join('');
      const idsForFilter = [];
      action.receipts && action.receipts.forEach(receipt => {
        idsForFilter.push(receipt.id);
      });
      newState[key] = { ids: idsForFilter, totalResults: action.totalResults };
      return newState;
    }
    default:
      return state;
  }
};

export const activeFilter = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER_RECEIPT':
      return {
        ...state,
        [action.filterType]: action.filter
      };

    case 'UPDATE_ACTIVE_FILTER_RECEIPT':
      return {
        ...action.filter
      };

    default:
      return state;
  }
};

const receipts = combineReducers({
  byId,
  ids,
  byFilter,
  activeFilter
});

export default receipts;

export const getReceipts = state => {
  const receipts = state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
  console.log('RETURN DATA ', receipts);
  return receipts;
};

export const getReceiptById = (state, id) => {
  console.log('STATE ', state, state.byId[id]);
  const receipt = state && state.byId[id];
  return receipt;
};

export const getActiveFilter = state => {
  return state.activeFilter;
};

export const getFilteredReceipts = state => {
  const key = Object.values(getActiveFilter(state)).join('');
  const filterdReceiptsIds = state.byFilter[key] ? state.byFilter[key].ids : [];

  return filterdReceiptsIds.map(id => state.byId[id]).filter(receipt => !!receipt);
};
