import React, { Component } from 'react';
import { Col } from 'antd';
import { FaTrash } from 'react-icons/all';

class ModifierRow extends Component {
  render () {
    const { modifier, onRemoveModifier } = this.props;

    return (
      <Col span={24} className='item-modifier-header item-table-row' style={{ padding: '5px 0px', marginTop: 5, marginBottom: 10 }}>
        {
          !modifier.hasOptions && modifier.item &&
            <Col span={12}>{modifier.item.name}</Col>
        }
        {
          modifier.options &&
           Object.keys(modifier.options).length > 0 &&
           Object.values(modifier.options)[0].item &&
             <Col span={12}>{'(G)' + modifier.commonName + '-' + Object.values(modifier.options)[0].item.name}</Col>
        }
        <Col className='text-center' span={4}>{modifier.quantity}</Col>
        <Col className='text-center' span={4}>{modifier.uom}</Col>
        <Col className='text-right' span={4}><FaTrash style={{ color: 'red' }} onClick={(e) => onRemoveModifier(e, modifier)} /></Col>
      </Col>
    );
  }
}
export default ModifierRow;
